import Vue from 'vue';
import BetActivitySummary from '../BetActivitySummary/BetActivitySummary';
const sportColor = '#467dc6';
const casinoColor = '#f87979';
import { gql } from 'apollo-boost';
import { VCard, VSkeletonLoader } from 'vuetify/lib';
import './PlayerBetActivity.less';

export type DefaultChartValue = Record<string, DailyActivity>;

export interface ProductSummary {
  turnover: string;
  numberBets: string;
  averageBetSize: string;
  margin: string;
  pnl: string;
  lastTransactionDate: string;
}

export interface DailyActivity {
  revenue: string;
  date: string;
}

export interface PlayerBetActivityProps {
  casino: ProductSummary;
  sports: ProductSummary;
  sportsBetsDailyActivity?: DailyActivity[];
  casinoBetsDailyActivity?: DailyActivity[];
}

// Todo fix backend to return array of dates
const daysSinceToday = 90; // 3 months, in days
const displayedCurrency = 'EUR';

export const formatSummaryValues = (
  productSummary: ProductSummary,
  decimalPlaces: number,
) => {
  const truncatedSummary = { ...productSummary };
  const keys = Object.keys(productSummary);
  keys.forEach((key) => {
    if (key === 'margin') {
      // @ts-ignore
      truncatedSummary[key] = `${(
        parseFloat(truncatedSummary[key]) * 100
      ).toFixed(decimalPlaces)}%`;
      return;
    }
    if (key === 'lastTransactionDate') {
      // when no bets, date returned is "0001-01-01T00:00:00Z"
      // @ts-ignore
      if (truncatedSummary[key] == null || truncatedSummary[key].split('-')[0].charAt(0) === '0') {
        truncatedSummary[key] = '0';
        return;
      }
      truncatedSummary[key] = new Date(truncatedSummary[key]).toUTCString();
      return;
    }
    if (key === 'numberBets') {
      // @ts-ignore
      truncatedSummary[key] = parseFloat(truncatedSummary[key] || 0).toFixed(0);
      return;
    } else {
      // @ts-ignore
      truncatedSummary[key] = formatNumber(truncatedSummary[key]);
      return;
    }
  });
  return truncatedSummary;
};

/** @description Adds decimals and limits decimal places */
export const formatNumber = (dataValue: string) => {
  const numberData = parseFloat(dataValue || '0');
  const truncated = numberData.toFixed(2);
  const truncatedNumber = parseFloat(truncated);
  const formattedNumber = new Intl.NumberFormat().format(truncatedNumber);
  return formattedNumber;
};

export const generateChartDates = () => {
  const from = new Date();
  let start = 0;
  from.setDate(from.getDate() - daysSinceToday);
  const dateStrings: string[] = [];

  while (start <= daysSinceToday) {
    dateStrings.push(from.toISOString().split('T')[0]);
    from.setDate(from.getDate() + 1);
    start++;
  }
  return dateStrings;
};

export const generateDefaultChartValues = () => {
  const defaultValue = {} as DefaultChartValue;
  const chartDates = generateChartDates();
  chartDates.forEach((date) => (defaultValue[date] = { revenue: '0', date }));
  return defaultValue;
};

export const mapIncomingChartData = (dailyActivity: DailyActivity[]) => {
  const defaultValues = generateDefaultChartValues();
  dailyActivity.forEach((activity) => {
    const date = activity.date.split('T')[0];
    if (!defaultValues[date]) {
      defaultValues[date] = { revenue: '0', date };
    }
    defaultValues[date].revenue = activity?.revenue || '0';
  });
  return defaultValues;
};

// Accumulated daily values
export const getDailyAccumulatedRevenue = (
  dailyActivity: DailyActivity[],
  returnNumber?: boolean,
) => {
  const mappedData = dailyActivity && mapIncomingChartData(dailyActivity);
  const chartDates = generateChartDates();
  let sum = 0;
  return chartDates.map((date) => {
    const revenueInt = parseFloat(mappedData[date].revenue);
    if (returnNumber) {
      return (sum += revenueInt);
    }
    return (sum += revenueInt).toFixed(2);
  });
};

// transforms incoming data to be used with Chart.js
export const transformToChartData = (
  playerBetActivity: PlayerBetActivityProps,
) => {
  const sportsBetsDailyActivity = playerBetActivity?.sportsBetsDailyActivity
    ? getDailyAccumulatedRevenue(
        playerBetActivity.sportsBetsDailyActivity as DailyActivity[],
      )
    : [];

  const casinoBetsDailyActivity = playerBetActivity?.casinoBetsDailyActivity
    ? getDailyAccumulatedRevenue(
        playerBetActivity.casinoBetsDailyActivity as DailyActivity[],
      )
    : [];

  const chartData = {
    labels: generateChartDates(),
    datasets: [
      {
        label: 'Sports',
        backgroundColor: sportColor,
        data: sportsBetsDailyActivity,
        borderWidth: 2,
        yAxisID: 'y-axis-0',
      },
      {
        label: 'Casino',
        backgroundColor: casinoColor,
        data: casinoBetsDailyActivity,
        yAxisID: 'y-axis-0',
      },
    ],
  };
  return chartData;
};

export default Vue.extend({
  props: {
    playerUUID: String,
    playerNickname: String,
  },
  data() {
    return {
      playerBetActivity: {} as PlayerBetActivityProps,
      isLoading: true,
      hasError: false,
      errorValue: {} as Record<string, any>,
    };
  },
  apollo: {
    playerBetActivity: {
      query: gql`
        query($uuid: String!) {
          playerBetActivity(uuid: $uuid) {
            sports {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
              lastTransactionDate
            }
            casino {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
              lastTransactionDate
            }
          }
        }
      `,
      variables(): { uuid: string } {
        return { uuid: this.playerUUID };
      },
      update(data) {
        if (data.playerBetActivity) {
          this.isLoading = false;
          return data.playerBetActivity;
        }
      },
      error(error) {
        this.errorValue = error.graphQLErrors[0]?.extensions?.exception;
      },
      result({ errors }) {
        if (errors) {
          this.hasError = true;
          this.isLoading = false;
        }
      },
    },
  },
  methods: {
    errorComponent(errorCode: number) {
      if (errorCode === 404) {
        return (
          <VCard
            class='card pa-2'
            elevation={3}
            outlined
          >{` No recent activity from UUID: ${this.playerUUID}`}</VCard>
        );
      }
    },
  },
  render() {
    return (
      <div class='player-bet-activity-container'>
        <div class='ma-2'>
          {this.errorValue?.code ? (
            this.errorComponent(this.errorValue.code)
          ) : (
            <VCard elevation={3} outlined>
              {this.isLoading ? (
                <VSkeletonLoader type='image' width='750' />
              ) : (
                <div>
                  <div class='h6 ml-4 pa-4'>Bet Activity Summary</div>
                  <BetActivitySummary
                    displayedCurrency={displayedCurrency}
                    sportSummary={formatSummaryValues(
                      this.playerBetActivity.sports,
                      2,
                    )}
                    casinoSummary={formatSummaryValues(
                      this.playerBetActivity.casino,
                      2,
                    )}
                    playerNickname={this.playerNickname}
                  />
                </div>
              )}
            </VCard>
          )}
        </div>
      </div>
    );
  },
});
