import Vue from 'vue';
import { VSelect, VTextField } from 'vuetify/lib';
import CompetitorTranslationWidget from '@/components/sports/TranslationsWidget/CompetitorTranslationWidget';
import './CompetitorTranslationTab.less';
export const CompetitorTranslationTab = Vue.extend({
  props: {
    competitionId: Number,
  },
  data() {
    return {
      locales: this.$store.state.sportsConsts.locales,
      locale: 'en',
      name: '',
    };
  },
  methods: {
    handleChange(val: string) {
      this.name = val;
    },
  },
  render() {
    return (
      <div>
        <div class='competitor-translation-filter-container ma-4'>
          <VSelect
            items={this.locales}
            label='Locale'
            return-object={true}
            v-model={this.locale}
            outlined
            dense
            class='competitor-translation-select'
          ></VSelect>
          <VTextField
            label='Search name'
            outlined
            class='competitor-translation-search ml-4'
            dense
            onchange={(val: any) => {
              this.name = val;
            }}
          />
        </div>
        <CompetitorTranslationWidget
          competitionId={Number(this.competitionId)}
          locale={this.locale}
          name={this.name}
          disableNameLimitSearch={true}
        />
      </div>
    );
  },
});
