import { PlayerBetSettings } from '@/components/sports/PlayerBetSettings/PlayerBetSettings';
import { formatCurrency, round } from '@/filters';
import gql from 'graphql-tag';
import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCol,
  VContainer,
  VDataTable,
  VDatePicker,
  VDialog,
  VIcon,
  VMenu,
  VRow,
  VTextField,
  VToolbar,
  VToolbarTitle,
} from 'vuetify/lib';

interface SubCustomer {
  reference: string;
  turnover: string;
  pnl: string;
  avgBetSize: string;
  betsCount: number;
  dialogIsOpen: boolean;
}

function fistDayOfCurrentMonth() {
  const now = new Date();
  now.setUTCDate(1);
  return now.toISOString().substr(0, 10);
}

function formatCash(value: string) {
  return formatCurrency(parseFloat(value), 2);
}

const LIMIT = 32;

export const SubCustomers = Vue.extend({
  props: {
    playerUUID: String,
  },
  data() {
    return {
      reload: false,
      allLoaded: false,
      subCustomers: [] as SubCustomer[],
      headers: [
        {
          text: 'Reference',
          align: 'start',
          sortable: false,
          value: 'reference',
        },
        {
          text: '#bets',
          sortable: false,
          align: 'start',
          value: 'betsCount',
        },
        {
          text: 'P&L',
          align: 'start',
          sortable: false,
          value: 'pnl',
        },
        {
          text: 'Turnover',
          align: 'start',
          sortable: false,
          value: 'turnover',
        },
        {
          text: 'Margin',
          align: 'start',
          sortable: false,
          value: 'margin',
        },
        {
          text: 'Avg. bet size',
          align: 'start',
          sortable: false,
          value: 'avgBetSize',
        },
        {
          text: 'Bet settings',
          align: 'center',
          sortable: false,
          value: 'betSettings',
        },
      ],
      fromTo: [fistDayOfCurrentMonth(), new Date().toISOString().substr(0, 10)],
      currentFromTo: [
        fistDayOfCurrentMonth(),
        new Date().toISOString().substr(0, 10),
      ],
      fromToIsOpen: false,
    };
  },
  computed: {
    fromToText(): string {
      return this.$data.currentFromTo.join(' ~ ');
    },
  },
  apollo: {
    subCustomersQuery: {
      query: gql`
        query(
          $playerUUID: String!
          $from: String!
          $to: String!
          $limit: Int!
          $offset: Int
        ) {
          subCustomers(
            playerUUID: $playerUUID
            from: $from
            to: $to
            limit: $limit
            offset: $offset
          ) {
            reference
            turnover
            pnl
            avgBetSize
            betsCount
          }
        }
      `,
      update(data: { subCustomers: SubCustomer[] }) {
        if (!data.subCustomers) {
          return;
        }

        if (data.subCustomers.length < LIMIT) {
          this.allLoaded = true;
        }

        this.subCustomers = [...this.subCustomers, ...data.subCustomers];
      },
      variables(): {
        playerUUID: string;
        from: string;
        to: string;
        limit: number;
        offset: number;
      } {
        return {
          playerUUID: this.playerUUID,
          from: new Date(this.currentFromTo[0]).toISOString(),
          to: new Date(this.currentFromTo[1])
            .toISOString()
            .replaceAll('00:00:00.000Z', '23:59:59.999999Z'),
          limit: LIMIT,
          offset: this.subCustomers.length,
        };
      },
      skip(): boolean {
        return this.allLoaded;
      },
      debounce: 500,
      fetchPolicy: 'network-only',
    },
  },
  watch: {
    fromTo: {
      handler() {
        if (
          this.fromTo[0] &&
          this.fromTo[1] &&
          this.fromTo !== this.currentFromTo
        ) {
          this.reload = true;
        }
      },
      deep: true,
    },
    fromToIsOpen: {
      handler() {
        if (!this.fromToIsOpen) {
          if (this.fromTo[0] && this.fromTo[1]) {
            this.currentFromTo = this.fromTo;
          } else {
            this.fromTo = this.currentFromTo;
          }
        }

        if (this.reload) {
          this.allLoaded = false;
          this.reload = false;
          this.subCustomers = [];
          this.$apollo.queries.subCustomersQuery.refresh();
        }
      },
    },
  },
  render() {
    return (
      <VContainer class='pa-0'>
        <VRow>
          <VCol lg={3} md={6} sm={12} class='mt-6 ml-3 mr-3'>
            <VMenu
              vModel={this.fromToIsOpen}
              closeOnContentClick={false}
              nudgeRight={40}
              transition='scale-transition'
              offsetY
              minWidth='auto'
              scopedSlots={{
                activator: ({ on, attrs }: any) => {
                  return (
                    <VTextField
                      vModel={this.fromToText}
                      label='Time range (UTC)'
                      readonly
                      dense
                      outlined
                      bind={attrs}
                      on={on}
                    ></VTextField>
                  );
                },
              }}
            >
              <VDatePicker range vModel={this.fromTo} />
            </VMenu>
          </VCol>
        </VRow>
        <VDataTable
          headers={this.headers}
          items={this.subCustomers}
          itemKey='reference'
          loading={this.$apollo.queries.subCustomersQuery.loading}
          loadingText='Loading... Please wait'
          scopedSlots={{
            'item.pnl': ({ item }: { item: SubCustomer }) => {
              return formatCash(item.pnl);
            },
            'item.turnover': ({ item }: { item: SubCustomer }) => {
              return formatCash(item.turnover);
            },
            'item.avgBetSize': ({ item }: { item: SubCustomer }) => {
              return formatCash(item.avgBetSize);
            },
            'item.margin': ({ item }: { item: SubCustomer }) => {
              return (
                round(
                  (parseFloat(item.pnl) / parseFloat(item.turnover)) * 100,
                  2,
                ) + '%'
              );
            },
            'item.betSettings': ({ item }: { item: SubCustomer }) => {
              return (
                <VDialog
                  vModel={item.dialogIsOpen}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => (
                      <VBtn icon on={on} attrs={attrs}>
                        <VIcon>edit</VIcon>
                      </VBtn>
                    ),
                  }}
                >
                  {item.dialogIsOpen && (
                    <VCard>
                      <VToolbar dark color='primary'>
                        <VBtn
                          icon
                          dark
                          onClick={() => (item.dialogIsOpen = false)}
                        >
                          <VIcon>close</VIcon>
                        </VBtn>
                        <VToolbarTitle>Betting config</VToolbarTitle>
                      </VToolbar>
                      <PlayerBetSettings
                        playerUUID={this.playerUUID}
                        subCustomerReference={
                          this.playerUUID === item.reference
                            ? undefined
                            : item.reference
                        }
                      />
                    </VCard>
                  )}
                </VDialog>
              );
            },
          }}
        ></VDataTable>
      </VContainer>
    );
  },
});
