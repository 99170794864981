import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCol,
  VContainer,
  VDataTable,
  VRow,
  VSelect,
} from 'vuetify/lib';
import AwardCasinoFreespinsOfferDialog from './AwardCasinoFreespinsOfferDialog';
import ConfirmationDialog from './ConfirmationDialog';

import {
  CancelCasinoFreespinsOfferInput,
  CasinoFreespinsOffer,
  GetPlayerCasinoFreespinsOffersInput,
  GetPlayerCasinoFreespinsOffersOutput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { env } from '@/env';
import { unixToDateTimeUTCString } from '@/utils/date';

const FREESPINS_OFFER_STATES = [
  { text: 'All', value: null },
  { text: 'Active', value: 'active' },
  { text: 'Cancelled by Admin', value: 'admin_cancelled' },
  { text: 'Exhausted', value: 'exhausted' },
  { text: 'Expired', value: 'expired' },
  { text: 'Failed', value: 'failed' },
];

interface VueData {
  playerCasinoFreespinsOffers: GetPlayerCasinoFreespinsOffersOutput;
  awardFreespinsOfferDialogOpen: boolean;
  cancelFreespinsOfferDialogOpen: boolean;
  cancelFreespinsOfferId: string;
  cancelFreespinsOfferLoading: boolean;
  freespinsOffersStateSelectVal: string | null;
  apiError: ApiError | null;
}

const ITEMS_PER_PAGE = 10;

const PlayerCasinoFreespinsOffers = Vue.extend({
  props: {
    playerUUID: String,
  },
  data(): VueData {
    return {
      playerCasinoFreespinsOffers: {
        records: [],
        pagination: {
          page: '1',
          totalRecords: '0',
          perPage: `${ITEMS_PER_PAGE}`,
        },
      },
      awardFreespinsOfferDialogOpen: false,
      cancelFreespinsOfferDialogOpen: false,
      cancelFreespinsOfferId: '',
      cancelFreespinsOfferLoading: false,
      freespinsOffersStateSelectVal: null,
      apiError: null,
    };
  },
  created() {
    this.loadPlayerCasinoFreespinsOffers('1', this.freespinsOffersStateSelectVal);
  },
  methods: {
    async loadPlayerCasinoFreespinsOffers(page: string, state: string | null) {
      const input: GetPlayerCasinoFreespinsOffersInput = {
        playerUUID: this.playerUUID,
        page,
      };

      if (state != null) {
        input.state = state;
      }

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetPlayerCasinoFreespinsOffersInput!) {
            getPlayerCasinoFreespinsOffers(input: $input) {
              records {
                id
                uuid
                playerUuid
                promocode
                distributorId
                studioId
                casinoGameId
                spinsAwarded
                distributorSpinValue
                casinoCurrency
                distributorCurrency
                expiresAt
                distributorFreespinsOfferIdentifier
                rolloverRequirement
                adminCancelledAt
                cancelledBy
                cancelReason
                exhaustedAt
                exhaustedAmount
                createdAt
                updatedAt
                rolloverExpiresInDays
                freespinsType
                rewardType
                sourceId
                sourceType
                gameUuid
                gameName
                casinoHubGameId
              }
              pagination {
                page
                totalRecords
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetPlayerCasinoFreespinsOffersOutput = response.data.getPlayerCasinoFreespinsOffers;
      this.playerCasinoFreespinsOffers = output;
    },
    onPageUpdate(page: number) {
      this.loadPlayerCasinoFreespinsOffers(`${page}`, this.freespinsOffersStateSelectVal);
    },
    onFreespinsOfferCancel(freespinsOfferId: string) {
      this.cancelFreespinsOfferId = freespinsOfferId;
      this.cancelFreespinsOfferDialogOpen = true;
    },
    async onFreespinsOfferCancelConfirm(freespinsOfferId: string, reason: string) {
      try {
        this.cancelFreespinsOfferLoading = true;

        const input: CancelCasinoFreespinsOfferInput = { freespinsOfferId, reason };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: CancelCasinoFreespinsOfferInput!) {
              cancelCasinoFreespinsOffer(input: $input) {
                freespinsOfferId
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.apiError = null;
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        if (errResponse != null) {
          this.apiError = {
            url: errResponse.url,
            status: errResponse.status,
            body: errResponse.body,
          };
        }
      } finally {
        await this.loadPlayerCasinoFreespinsOffers('1', this.freespinsOffersStateSelectVal);
        this.cancelFreespinsOfferLoading = false;
        this.cancelFreespinsOfferDialogOpen = false;
      }
    },

    formatTime(time: string): string {
      const str = unixToDateTimeUTCString(time);
      return str.substring(0, str.length - 4);
    },
  },
  computed: {
    headers() {
      return [
        { text: 'Id', value: 'id', width: '150px' },
        { text: 'Freespins type', value: 'freespinsType' },
        { text: 'Reward type', value: 'rewardType' },
        { text: 'Source', value: 'source' },
        { text: 'Promocode', value: 'promocode' },
        { text: 'Distributor id', value: 'distributorId' },
        { text: 'Studio id', value: 'studioId' },
        { text: 'Game', value: 'game', width: '250px' },
        { text: 'Spins awarded', value: 'spinsAwarded' },
        { text: 'Distributor spin value', value: 'distributorSpinValue' },
        { text: 'Casino currency', value: 'casinoCurrency' },
        { text: 'Distributor currency', value: 'distributorCurrency' },
        { text: 'Distributor freespins offer identifier', value: 'distributorFreespinsOfferIdentifier' },
        { text: 'Rollover requirement', value: 'rolloverRequirement' },
        { text: 'Rollover expires in days', value: 'rolloverExpiresInDays' },
        { text: 'Expires at', value: 'expiresAt', width: '170px' },
        { text: 'Admin cancelled at', value: 'adminCancelledAt', width: '170px' },
        { text: 'Cancelled by', value: 'cancelledBy' },
        { text: 'Cancel reason', value: 'cancelReason', width: '170px' },
        { text: 'Exhausted at', value: 'exhaustedAt', width: '170px' },
        { text: 'Exhausted amount (distributor currency)', value: 'exhaustedAmount' },
        { text: 'Created at', value: 'createdAt', width: '170px' },
        { text: 'Updated at', value: 'updatedAt', width: '170px' },
        { text: 'Actions', value: 'actions' },
      ];
    },
    scopedSlots() {
      return {
        'item.id': ({ item }: { item: CasinoFreespinsOffer }) => {
          return <p>
            <p style='margin-top:1em; margin-bottom:0.5em'>{item.id}</p>
            <p style='font-size:0.8em;color:gray'>{item.uuid}</p>
          </p>;
        },
        'item.source': ({ item }: { item: CasinoFreespinsOffer }) => {
          if (!(item.sourceType && item.sourceId)) {
            return;
          }
          return [item.sourceType, item.sourceId].join('|');
        },
        'item.game': ({ item }: { item: CasinoFreespinsOffer }) => {
          return <a href={`${env.casino.hub_game_url}/${item.casinoHubGameId}`} target='_blank' style='text-decoration:none'>
            <p style='margin-top:1em'>{item.gameName}</p>
            <p style='font-size:0.8em;color:gray'>{item.gameUuid}</p>
            </a>;
        },
        'item.expiresAt': ({ item }: { item: CasinoFreespinsOffer }) => {
          return this.formatTime(item.expiresAt);
        },
        'item.adminCancelledAt': ({ item }: { item: CasinoFreespinsOffer }) => {
          if (item.adminCancelledAt) {
            return this.formatTime(item.adminCancelledAt);
          }
        },
        'item.exhaustedAt': ({ item }: { item: CasinoFreespinsOffer }) => {
          if (item.exhaustedAt) {
            return this.formatTime(item.exhaustedAt);
          }
        },
        'item.exhaustedAmount': ({ item }: { item: CasinoFreespinsOffer }) => {
          if (item.exhaustedAmount) {
            return item.exhaustedAmount + ' ' + item.distributorCurrency;
          }
        },
        'item.createdAt': ({ item }: { item: CasinoFreespinsOffer }) => {
          return this.formatTime(item.createdAt);
        },
        'item.updatedAt': ({ item }: { item: CasinoFreespinsOffer }) => {
          return this.formatTime(item.updatedAt);
        },
        'item.actions': ({ item }: { item: CasinoFreespinsOffer }) => {
          if (
            item.exhaustedAt == null &&
            item.adminCancelledAt == null &&
            (new Date(parseInt(item.expiresAt, 10) * 1000) > new Date())
          ) {
            return (
              <VBtn
                depressed
                color='error'
                onClick={() => { this.onFreespinsOfferCancel(item.uuid); }}
              >
                Cancel
              </VBtn>
            );
          }
        },
      };
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            {apiErrorDisplay()}
            <h1>Casino Freespins Offers</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol align='end'>
            <VBtn
              color='primary'
              onClick={() => { this.awardFreespinsOfferDialogOpen = true; }}
            >
              Award Freespins Offer
            </VBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <div style={{ maxWidth: '300px' }}>
              <VSelect
                v-model={this.freespinsOffersStateSelectVal}
                label='Filter by state'
                items={FREESPINS_OFFER_STATES}
                outlined
                onChange={() => this.loadPlayerCasinoFreespinsOffers('1', this.freespinsOffersStateSelectVal)}
              />
            </div>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VDataTable
              headers={this.headers}
              items={this.playerCasinoFreespinsOffers.records}
              scopedSlots={this.scopedSlots}
              footerProps={{
                'items-per-page-options': [ITEMS_PER_PAGE],
              }}
              disableSort={true}
              loadingText='Loading... Please wait'
              loading={false}
              page={parseInt((this.playerCasinoFreespinsOffers?.pagination?.page || '1'), 10)}
              itemsPerPage={ITEMS_PER_PAGE}
              serverItemsLength={parseInt((this.playerCasinoFreespinsOffers?.pagination?.totalRecords || '0'), 10)}
              on={{
                'update:page': (page: number) => this.onPageUpdate(page),
              }}
            />
          </VCol>
        </VRow>
        <AwardCasinoFreespinsOfferDialog
          playerUUID={this.playerUUID}
          open={this.awardFreespinsOfferDialogOpen}
          on={{
            open: (open: boolean) => { this.awardFreespinsOfferDialogOpen = open; },
            submit: (apiError: ApiError | null) => {
              this.apiError = apiError;
              this.loadPlayerCasinoFreespinsOffers('1', this.freespinsOffersStateSelectVal);
              this.awardFreespinsOfferDialogOpen = false;
            },
          }}
        />
        <ConfirmationDialog
          open={this.cancelFreespinsOfferDialogOpen}
          title='Cancel freespins offer confirmation'
          loading={this.cancelFreespinsOfferLoading}
          on={{
            submit: (reason: string) => {
              this.onFreespinsOfferCancelConfirm(this.cancelFreespinsOfferId, reason);
            },
            cancel: () => {
              this.cancelFreespinsOfferDialogOpen = false;
            },
          }}
        />
      </VContainer>
    );
  },
});

export default PlayerCasinoFreespinsOffers;
