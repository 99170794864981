import Vue from 'vue';
import { VSnackbar } from 'vuetify/lib';

export default Vue.extend({
  props: {
    show: Boolean,
    error: String,
  },

  render() {
    return (
      <VSnackbar timeout={4000} v-model={this.show} top color='red darken-1' elevation='24'>
        <div class='text-center'>Failed: {this.error}</div>
      </VSnackbar>
    );
  },
});
