import Vue from 'vue';
import { hasRolesDirective } from './v-has-roles';
import { numericInput } from './numeric-input';
import { Resize } from 'vuetify/lib/directives';

export function initializeDirectives() {
  Vue.directive('has-roles', {
    bind: hasRolesDirective,
    inserted: hasRolesDirective,
    update: hasRolesDirective,
  });

  Vue.directive('numeric-input', numericInput);
  Vue.directive('resize', Resize);
}
