import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCol,
  VContainer,
  VDialog,
  VRow,
  VSimpleTable,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

import { ApiError } from '@/types/casino/apiError';

interface GetLoyaltyTiersOutput {
  loyaltyTiers: LoyaltyTier[];
}

interface LoyaltyTier {
  id: number;
  levels: number;
  pointsPerLevel: number;
}

interface LoyaltyTiers {
  [id: string]: LoyaltyTier;
}

interface CreateOrUpdateLoyaltyTiersValidationErrorsResponse {
  loyalty_tiers: Array<{
    tier_id: number;

    id: number;
    levels: number;
    points_per_level: number;
  }>;
}

interface CreateOrUpdateLoyaltyTiersValidationErrors {
  [id: string]: LoyaltyTier;
}

interface VueData {
  loyaltyTiers: LoyaltyTiers;
  editMode: boolean;
  confirmationDialogOpen: boolean;
  createOrUpdateLoyaltyTiersValidationErrors?: CreateOrUpdateLoyaltyTiersValidationErrors;
  apiError: ApiError | null;
}

const defaultCreateOrUpdateLoyaltyTiersValidationErrors = undefined;

export default Vue.extend({
  name: 'casino-loyalty-tiers',
  data(): VueData {
    return {
      loyaltyTiers: {},
      editMode: false,
      confirmationDialogOpen: false,
      createOrUpdateLoyaltyTiersValidationErrors: defaultCreateOrUpdateLoyaltyTiersValidationErrors,
      apiError: null,
    };
  },
  created() {
    this.loadLoyaltyTiers();
  },
  methods: {
    async loadLoyaltyTiers() {
      const response = await this.$apollo.query({
        query: gql`
          query {
            getCasinoLoyaltyTiers {
              loyaltyTiers {
                id
                levels
                pointsPerLevel
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });

      const loyaltyTiers: LoyaltyTiers = {};

      const data: GetLoyaltyTiersOutput = response.data.getCasinoLoyaltyTiers;
      data.loyaltyTiers.forEach((loyaltyTier: LoyaltyTier) => {
        loyaltyTiers[`${loyaltyTier.id}`] = loyaltyTier;
      });

      this.loyaltyTiers = loyaltyTiers;
    },
    async createOrUpdateLoyaltyTiers() {
      try {
        const loyaltyTiersInput: LoyaltyTier[] = Object.keys(this.loyaltyTiers).map((id) => {
          const loyaltyTier: LoyaltyTier = {
            id: this.loyaltyTiers[id].id,
            levels: parseInt(`${this.loyaltyTiers[id].levels}`, 10),
            pointsPerLevel: parseInt(`${this.loyaltyTiers[id].pointsPerLevel}`, 10),
          };

          return loyaltyTier;
        });

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: CreateOrUpdateCasinoLoyaltyTiersInput!) {
              createOrUpdateCasinoLoyaltyTiers(input: $input)
            }
          `,
          variables: {
            input: {
              loyaltyTiers: loyaltyTiersInput,
            },
          },
        });

        this.loadLoyaltyTiers();
        this.confirmationDialogOpen = false;
        this.editMode = false;
      } catch (err) {
        const errResponse = err?.graphQLErrors[0]?.extensions?.response;
        const errURL = errResponse?.url;
        const errStatus = errResponse?.status;
        const errBody = errResponse?.body;

        if (errBody != null) {
          const validationErrors: CreateOrUpdateLoyaltyTiersValidationErrorsResponse = errBody.validation_errors;

          if (validationErrors != null) {
            const createOrUpdateLoyaltyTiersValidationErrors: CreateOrUpdateLoyaltyTiersValidationErrors = {};
            validationErrors.loyalty_tiers.forEach((loyaltyTierValidationErrors) => {
              createOrUpdateLoyaltyTiersValidationErrors[loyaltyTierValidationErrors.tier_id] = {
                id: loyaltyTierValidationErrors.id,
                levels: loyaltyTierValidationErrors.levels,
                pointsPerLevel: loyaltyTierValidationErrors.points_per_level,
              };
            });
            this.createOrUpdateLoyaltyTiersValidationErrors = createOrUpdateLoyaltyTiersValidationErrors;

            this.confirmationDialogOpen = false;
            this.editMode = true;
          } else {
            const apiError: ApiError = {
              url: errURL,
              status: errStatus,
              body: errBody,
            };

            this.apiError = apiError;
            this.loadLoyaltyTiers();
            this.confirmationDialogOpen = false;
            this.editMode = false;
          }
        }
      }
    },
    onEdit() {
      this.apiError = null;
      this.createOrUpdateLoyaltyTiersValidationErrors = defaultCreateOrUpdateLoyaltyTiersValidationErrors;
      this.editMode = true;
    },
    onUpdate() {
      this.confirmationDialogOpen = true;
    },
    onCancel() {
      this.loadLoyaltyTiers();
      this.editMode = false;
    },
    onConfirmationDialogConfirm() {
      this.createOrUpdateLoyaltyTiers();
    },
    onConfirmationDialogCancel() {
      this.confirmationDialogOpen = false;
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    const confirmationDialog = () => {
      return (
        <VDialog
          v-model={this.confirmationDialogOpen}
          on={{
            'click:outside': () => { this.onConfirmationDialogCancel(); },
          }}
          maxWidth='800'
        >
          <VCard>
            <VCardTitle>
              Loyalty Tiers Config Change
            </VCardTitle>
            <VCardText>
              Please confirm to update loyalty tiers config.
            </VCardText>
            <VCardActions>
              <VSpacer />
              <VBtn onClick={() => { this.onConfirmationDialogCancel(); }}>Cancel</VBtn>
              <VBtn color='primary' onClick={() => { this.onConfirmationDialogConfirm(); }}>Confirm</VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      );
    };

    const actions = () => {
      if (this.editMode) {
        return (
          <div class='mb-5'>
            <VBtn
              color='primary'
              onClick={() => this.onUpdate() }
            >
              Update
            </VBtn>
            <VBtn
              class='ml-3'
              color='error'
              onClick={() => this.onCancel() }
            >
              Cancel
            </VBtn>
          </div>
        );
      }

      return (
        <VBtn
          color='primary'
          onClick={() => this.onEdit() }
        >
          Edit
        </VBtn>
      );
    };

    const tableInViewMode = () => {
      return (
        <VSimpleTable dense>
          <template slot='default'>
            <thead>
              <tr>
                <th>Tier</th>
                <th>Levels</th>
                <th>Points Per Level</th>
                <th>Points In Tier</th>
              </tr>
            </thead>
            <tbody>
            {Object.keys(this.loyaltyTiers).map((id) => {
              const pointsInTier = this.loyaltyTiers[id].levels * this.loyaltyTiers[id].pointsPerLevel;

              return (
                <tr>
                  <td>{this.loyaltyTiers[id].id}</td>
                  <td>{this.loyaltyTiers[id].levels}</td>
                  <td>{this.loyaltyTiers[id].pointsPerLevel}</td>
                  <td>{pointsInTier}</td>
                </tr>
              );
            })}
          </tbody>
          </template>
        </VSimpleTable>
      );
    };

    const tableInEditMode = () => {
      return (
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              <th>Tier</th>
              <th>Levels</th>
              <th>Points Per Level</th>
              <th>Points In Tier</th>
            </tr>
          </thead>
          <tbody>
          {Object.keys(this.loyaltyTiers).map((id, index) => {
            const pointsInTier = this.loyaltyTiers[id].levels * this.loyaltyTiers[id].pointsPerLevel;

            return (
              <tr>
                <td>
                  <VTextField
                    readonly
                    disabled
                    dense
                    outlined
                    value={this.loyaltyTiers[id].id}
                  />
                </td>
                <td>
                  <VTextField
                    dense
                    outlined
                    type='number'
                    v-model={this.loyaltyTiers[id].levels}
                    error={this.createOrUpdateLoyaltyTiersValidationErrors?.[id]?.levels != null}
                    errorMessages={this.createOrUpdateLoyaltyTiersValidationErrors?.[id]?.levels || []}
                    errorCount={5}
                  />
                </td>
                <td>
                  <VTextField
                    dense
                    outlined
                    type='number'
                    v-model={this.loyaltyTiers[id].pointsPerLevel}
                    error={this.createOrUpdateLoyaltyTiersValidationErrors?.[id]?.pointsPerLevel != null}
                    errorMessages={this.createOrUpdateLoyaltyTiersValidationErrors?.[id]?.pointsPerLevel || []}
                    errorCount={5}
                  />
                </td>
                <td>
                  <VTextField
                    readonly
                    disabled
                    dense
                    outlined
                    value={pointsInTier}
                  />
                </td>
              </tr>
            );
          })}
          </tbody>
        </table>
      );
    };

    const loyaltyTiersTable = () => {
      if (this.editMode) { return tableInEditMode(); }
      return tableInViewMode();
    };

    return (
      <div class='ma-5'>
        <VContainer fluid>
          <VRow>
            <VCol>
              {apiErrorDisplay()}
              <h1>Casino Loyalty Tiers</h1>
            </VCol>
          </VRow>
          <VRow>
            <VCol align='end'>
              {actions()}
            </VCol>
          </VRow>
          <VRow>
            <VCol>
              {loyaltyTiersTable()}
            </VCol>
          </VRow>
        </VContainer>
        {confirmationDialog()}
      </div>
    );
  },
});
