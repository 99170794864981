import Vue from 'vue';
import { VCard, VTab, VTabItem, VTabs } from 'vuetify/lib';

import { PendingDepositsTab } from './resync/PendingDepositsTab';
import MissingDeposit from './MissingDeposit';


import { hasRoles } from '@/utils/auth';

export const CryptoDepositResyncWidget = Vue.extend({
  name: 'cryptoDepositResyncWidget',
  data() {
    return {
      tab: 'views',
    } as {
      tab: string,
    };
  },
  methods: {
    tabs() {
      const tabs = [];

      tabs.push({
        requiresRoles: ['cs:operator', 'cs:user', 'payments:operator', 'payments:user'],
        key: 'resync-pending',
        name: 'Pending Deposits',
        content: <PendingDepositsTab />,
      });
      tabs.push({
        requiresRoles: ['cs:operator', 'cs:user', 'payments:operator', 'payments:user'],
        key: 'resync-missing',
        name: 'Fetch Missing',
        content: <MissingDeposit />,
      });

      return tabs;
    },
  },
  render() {
    return (
      <div>
        <VCard elevation={4} class='pa-2'>
          <VTabs v-model={this.tab} show-arrows>
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTab to={'#' + tab.key} href={'#' + tab.key}>
                  {tab.name}
                </VTab>
              ))}
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTabItem value={tab.key}>{tab.content}</VTabItem>
              ))}
          </VTabs>
        </VCard>
      </div>
    );
  },
});
