import Vue, { VNode } from 'vue';
import {
  VBtn,
  VDialog,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VCard,
  VTextarea,
  VProgressLinear,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { SportsBet } from './return-fields';

export default Vue.extend({
  props: {
    bets: Array as () => SportsBet[],
    returnBetFields: String,
  },

  data() {
    return {
      dialog: true,
      comment: '',
      showProgress: false,
    };
  },

  methods: {
    async onAgree() {
      this.showProgress = true;
      try {
        const responses = await this.cancelBets();
        this.dialog = false;
        this.$emit('agree', responses.map((resp) => {
          if (resp.status === 'fulfilled') {
            return [resp.value.data.cancelSportsBet, null];
          } else {
            return [null, resp.reason];
          }
        }));
      } catch (e) {
        throw e;
      } finally {
        this.showProgress = false;
      }
    },

    onDisagree() {
      this.dialog = false;
      this.$emit('disagree');
    },

    async cancelBets() {
      const mutations = this.bets.map((bet) => {
        return this.$apollo.mutate({
          mutation: gql`mutation ($uuid: String!, $comment: String!) {
            cancelSportsBet(uuid: $uuid, comment: $comment) {
              ${this.returnBetFields}
            }
          }`,
          variables: {
            uuid: bet.uuid,
            comment: this.comment,
          },
          fetchPolicy: 'no-cache',
        });
      });

      return await Promise.allSettled(mutations);
    },
  },

  computed: {
    disableAgreeButton(): boolean {
      return this.comment === '';
    },

    infoText(): VNode {
      if (this.bets.length === 1) {
        if (this.bets[0].isCashoutBet) {
          return (
            <span>
              You are going to cancel a cashout <span class='error--text'>together with all related bets</span>.
            </span>
          );
        } else if (this.bets[0].exhaustedPotentialReturnAmount) {
          return (
            <span>
              You are going to cancel a bet <span class='error--text'>together with all related cashouts</span>.
            </span>
          );
        } else {
          return <span>You are going to cancel a bet.</span>;
        }
      }
      return (
        <span>
          You are going to cancel {this.bets.length} bet(s) <span class='error--text'>
            together with all related bets and cashouts.</span>.
        </span>
      );
    },
  },

  render() {
    return (
      <VDialog vModel={this.dialog} max-width='500' onInput={this.onDisagree}>
        <VCard>
          <VCardTitle class='headline'>
            Bet cancelling
          </VCardTitle>
          <VCardText>
            {this.infoText}<br />
            Add a comment below:
            <VTextarea vModel={this.comment} outlined></VTextarea>
            <VProgressLinear
              indeterminate={this.showProgress}
              active={this.showProgress}
              striped
              height={10} />
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='green darken-1' text onClick={this.onDisagree}>
              Disagree
              </VBtn>
            <VBtn color='green darken-1' text
              onClick={this.onAgree}
              disabled={this.disableAgreeButton}
              loading={this.showProgress}>
              Agree
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog >
    );
  },
});
