import Vue from 'vue';
import { Pie } from 'vue-chartjs';

interface DataSet {
  data: number[];
  backgroundColor?: string[];
}

export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}

const pallet = [
  '#0074D9',
  '#FF4136',
  '#2ECC40',
  '#FF851B',
  '#7FDBFF',
  '#B10DC9',
  '#FFDC00',
  '#001f3f',
  '#39CCCC',
  '#01FF70',
  '#85144b',
  '#F012BE',
  '#3D9970',
  '#111111',
  '#AAAAAA',
];

const getColors = (length: number) =>
  Array.from({ length }, (_, i) => pallet[i % pallet.length]);

export const PieChart = Vue.extend({
  extends: Pie,
  props: {
    chartData: Object as () => ChartData,
  },
  data: () => ({
    options: {
      animation: false,
    },
  }),
  mounted() {
    this.reloadChart();
  },
  watch: {
    chartData(newValue, oldValue) {
      this.reloadChart();
    },
  },
  methods: {
    reloadChart() {
      this.setupColors();
      // @ts-ignore
      this.renderChart(this.chartData, this.options);
    },
    setupColors() {
      const length = this.chartData.datasets[0].data.length;
      this.chartData.datasets[0].backgroundColor = getColors(length);
    },
  },
});
