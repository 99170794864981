import Vue from 'vue';
import {
  VDataTable,
  VIcon,
  VBtn,
} from 'vuetify/lib';
import { iso8601ToDateTimeUTCString } from '@/utils/date';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import { UnmatchedEvent, requiresMatchingStatus } from '@/store/modules/sports/unmatched_events';
import { mapActions } from 'vuex';


export const eventTypeText = (eventType: string) => {
  switch (eventType) {
    case 'EVENT_TYPE_EVENT':
      return 'EVENT';
    case 'EVENT_TYPE_MULTI_COMPETITOR_EVENT':
      return 'MULTI_COMPETITOR_EVENT (Racing)';
    case 'EVENT_TYPE_OUTRIGHT':
      return 'OUTRIGHT';
    default:
      return 'UNKNOWN';
  }
};

export default Vue.extend({
  props: {
    pageSize: {
      type: Number,
      default: 25,
    },
    events: {
      type: Array as () => UnmatchedEvent[],
      default: () => [],
    },
    loadedPages: {
      type: Number,
    },
    totalPages: {
      type: Number,
    },
    loading: {
      type: Boolean,
    },
  },

  data(): { currentPage: number } {
    return {
      currentPage: 1,
    };
  },

  watch: {
    loadedPages(newValue, oldValue) {
      if (newValue > oldValue) {
        this.currentPage = newValue;
      }
    },
  },

  methods: {
    ...mapActions({
      unmatchEvent: 'sports/unmatchedEvents/unmatchEvent',
      loadEvents: 'sports/unmatchedEvents/loadEvents',
    }),

    eventSelected(event: UnmatchedEvent) {
      this.$emit('eventSelected', event);
    },

    eventUnmatched(event: UnmatchedEvent) {
      this.$emit('eventUnmatched', event);
    },

    loadNextPage() {
      this.loadEvents();
    },
  },

  render() {
    return (
      <VDataTable
        headers={[
          { value: 'homeName', text: 'Home' },
          { value: 'awayName', text: 'Away' },
          { value: 'teamSwapped', text: 'Team Swapped' },
          { value: 'competitionName', text: 'Competition' },
          { value: 'cutoff', text: 'Start Time' },
          { value: 'provider', text: 'Provider' },
          { value: 'sportKey', text: 'Sport' },
          { value: 'eventType', text: 'Type' },
          { value: 'actions', align: 'center', sortable: false },
        ]}
        items={this.events}
        scopedSlots={{
          'item.actions': ({ item }: { item: UnmatchedEvent }) => {
            const showMatchBtn = requiresMatchingStatus.includes(item.status);
            const statusText = item.status?.replace('PROVIDER_EVENT_REGISTRY_STATUS_', '');
            const status = <small class='grey--text'>{statusText}</small>;
            return (
              <div class='mt-2'>
                <div>
                  {showMatchBtn ?
                    <VBtn small onClick={() => this.eventSelected(item)}>Match</VBtn>
                    :
                    <VBtn small color='error'
                      onClick={async () => {
                        await this.unmatchEvent(item);
                        this.eventUnmatched(item);
                      }}>
                      Unmatch
                    </VBtn>
                  }
                </div>
                <div>{status}</div>
              </div>
            );
          },
          'item.homeName': ({ item }: { item: UnmatchedEvent }) => {
            return item.homeName || item.eventName;
          },
          'item.teamSwapped': ({ item }: { item: UnmatchedEvent }) => {
            return item.teamSwapped ? <VIcon>done</VIcon> : <div></div>;
          },
          'item.cutoff': ({ item }: { item: UnmatchedEvent }) => {
            return iso8601ToDateTimeUTCString(item.cutoff);
          },
          'item.sportKey': ({ item }: { item: UnmatchedEvent }) => {
            return getSportsName(item.sportKey, Locale.en);
          },
          'item.eventType': ({ item }: { item: UnmatchedEvent }) => {
            return eventTypeText(item.eventType);
          },
          'item.provider': ({ item }: { item: UnmatchedEvent }) => {
            return (
              <div>
                <div>{item.provider}</div>
                <div><small class='grey--text'>{item.providerEventKey}</small></div>
              </div>
            );
          },
          'item.competitionName': ({ item }: { item: UnmatchedEvent }) => {
            return (
              <div>
                <div>{item.competitionName}</div>
                <div><small class='grey--text'>{item.categoryName}</small></div>
              </div>
            );
          },
          'footer': () => {
            return (
              <div class='v-data-footer py-2'>
                <div class='v-data-footer__select'></div>
                <div class='v-data-footer__pagination'>{this.currentPage} page of {this.totalPages || '...'}</div>
                <div class='v-data-footer__icons-before'>
                  <VBtn icon onClick={() => { this.currentPage--; }} disabled={this.currentPage <= 1}>
                    <VIcon>chevron_left</VIcon>
                  </VBtn>
                </div>
                <div class='v-data-footer__icons-after'>
                  <VBtn icon onClick={() => {
                    if (this.currentPage >= this.loadedPages) {
                      this.loadNextPage();
                    } else {
                      this.currentPage++;
                    }
                  }}
                    disabled={!!this.totalPages && this.currentPage === this.totalPages}>
                    <VIcon>chevron_right</VIcon>
                  </VBtn>
                </div>
              </div>
            );
          },
        }
        }
        loading={this.loading}
        itemsPerPage={this.pageSize}
        hideDefaultFooter={true}
        page={this.currentPage}
      />
    );
  },
});
