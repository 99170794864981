import { APIMarketData, Markets, Submarkets, Market } from '@/types/sports';

/** @description mock return value from zero-gateway getEventMarkets query */
export const mockMarketData = [
  {
    marketKey: 'soccer.asian_handicap',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'home',
            params: 'handicap=1',
            price: 1.881,
            minStake: 0,
            probability: 0.506,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1994.59,
          },
          {
            outcome: 'away',
            params: 'handicap=1',
            price: 1.924,
            minStake: 0,
            probability: 0.494,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1903.63,
          },
          {
            outcome: 'home',
            params: 'handicap=0.5',
            price: 2.535,
            minStake: 0,
            probability: 0.373,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'away',
            params: 'handicap=0.5',
            price: 1.505,
            minStake: 0,
            probability: 0.627,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3451.44,
          },
          {
            outcome: 'home',
            params: 'handicap=0.75',
            price: 2.208,
            minStake: 0,
            probability: 0.428,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'away',
            params: 'handicap=0.75',
            price: 1.655,
            minStake: 0,
            probability: 0.572,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 2675.14,
          },
          {
            outcome: 'home',
            params: 'handicap=1.25',
            price: 1.629,
            minStake: 0,
            probability: 0.581,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 2782.85,
          },
          {
            outcome: 'away',
            params: 'handicap=1.25',
            price: 2.258,
            minStake: 0,
            probability: 0.419,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'home',
            params: 'handicap=1.5',
            price: 1.485,
            minStake: 0,
            probability: 0.635,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3590.26,
          },
          {
            outcome: 'away',
            params: 'handicap=1.5',
            price: 2.585,
            minStake: 0,
            probability: 0.365,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.asian_handicap_period_first_half',
    submarkets: [
      {
        submarketKey: 'period=1h',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'home',
            params: 'handicap=0.25',
            price: 1.795,
            minStake: 0,
            probability: 0.527,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 551.3,
          },
          {
            outcome: 'away',
            params: 'handicap=0.25',
            price: 2,
            minStake: 0,
            probability: 0.473,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'home',
            params: 'handicap=-0.25',
            price: 5.448,
            minStake: 0,
            probability: 0.172,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'away',
            params: 'handicap=-0.25',
            price: 1.129,
            minStake: 0,
            probability: 0.828,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3202.51,
          },
          {
            outcome: 'home',
            params: 'handicap=0',
            price: 3.576,
            minStake: 0,
            probability: 0.261,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'away',
            params: 'handicap=0',
            price: 1.266,
            minStake: 0,
            probability: 0.739,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1612.42,
          },
          {
            outcome: 'home',
            params: 'handicap=0.5',
            price: 1.44,
            minStake: 0,
            probability: 0.652,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 986.92,
          },
          {
            outcome: 'away',
            params: 'handicap=0.5',
            price: 2.694,
            minStake: 0,
            probability: 0.348,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'home',
            params: 'handicap=0.75',
            price: 1.25,
            minStake: 0,
            probability: 0.748,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1711.35,
          },
          {
            outcome: 'away',
            params: 'handicap=0.75',
            price: 3.714,
            minStake: 0,
            probability: 0.252,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.both_teams_to_score',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'yes',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.591,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'no',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.409,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.correct_score',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'score=0:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.042,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.083,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.09,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.069,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:4',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.041,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:5',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.022,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.038,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.08,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.093,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.069,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:4',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.045,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:5',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.025,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.025,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.042,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.055,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.048,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:4',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.031,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:5',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.019,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=3:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.02,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=3:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.023,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=3:3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.022,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=3:4',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.017,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.correct_score_period_1st_half',
    submarkets: [
      {
        submarketKey: 'period=ft&period=1h&period=2h',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'score=0:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.206,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.21,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.122,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=0:3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.052,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.122,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.126,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=1:2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.074,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.043,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'score=2:1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.045,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.double_chance',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'home_draw',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.175,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'draw_away',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.424,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'home_away',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.401,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.draw_no_bet',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'home',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.191,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'away',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.809,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.exact_total_goals_period_first_half',
    submarkets: [
      {
        submarketKey: 'period=1h',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'goals=0',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.216,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'goals=1',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.316,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'goals=2',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.253,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'goals=3',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.136,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
          {
            outcome: 'goals=4%2B',
            params: '',
            price: 0,
            minStake: 17.1,
            probability: 0.08,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 79.78,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.goal_nr',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'home',
            params: 'goal=1',
            price: 3.2,
            minStake: 0,
            probability: 0.284,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 400.9,
          },
          {
            outcome: 'none',
            params: 'goal=1',
            price: 10,
            minStake: 0,
            probability: 0.091,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 98,
          },
          {
            outcome: 'away',
            params: 'goal=1',
            price: 1.45,
            minStake: 0,
            probability: 0.626,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1960,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.halftime_fulltime_result',
    submarkets: [
      {
        submarketKey: 'period=1h&period=ft',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'home_home',
            params: '',
            price: 13,
            minStake: 0,
            probability: 0.059,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 73.5,
          },
          {
            outcome: 'home_draw',
            params: '',
            price: 23,
            minStake: 0,
            probability: 0.033,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 40.09,
          },
          {
            outcome: 'home_away',
            params: '',
            price: 28,
            minStake: 0,
            probability: 0.027,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 32.66,
          },
          {
            outcome: 'draw_home',
            params: '',
            price: 8.75,
            minStake: 0,
            probability: 0.088,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 113.8,
          },
          {
            outcome: 'draw_draw',
            params: '',
            price: 4.65,
            minStake: 0,
            probability: 0.165,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 241.64,
          },
          {
            outcome: 'draw_away',
            params: '',
            price: 2.9,
            minStake: 0,
            probability: 0.265,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 464.21,
          },
          {
            outcome: 'away_home',
            params: '',
            price: 60,
            minStake: 0,
            probability: 0.013,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 14.94,
          },
          {
            outcome: 'away_draw',
            params: '',
            price: 18,
            minStake: 0,
            probability: 0.043,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 51.88,
          },
          {
            outcome: 'away_away',
            params: '',
            price: 2.5,
            minStake: 0,
            probability: 0.307,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 588,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.match_odds',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'home',
            params: '',
            price: 5.865,
            minStake: 0,
            probability: 0.158,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'draw',
            params: '',
            price: 4.121,
            minStake: 0,
            probability: 0.225,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'away',
            params: '',
            price: 1.498,
            minStake: 0,
            probability: 0.618,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3505.69,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.match_odds_period_first_half',
    submarkets: [
      {
        submarketKey: 'period=1h',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'home',
            params: '',
            price: 7.123,
            minStake: 0,
            probability: 0.13,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'draw',
            params: '',
            price: 1.758,
            minStake: 0,
            probability: 0.525,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 578.43,
          },
          {
            outcome: 'away',
            params: '',
            price: 2.675,
            minStake: 0,
            probability: 0.345,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.match_odds_period_second_half',
    submarkets: [
      {
        submarketKey: 'period=ft&period=2h&period=1h',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'home',
            params: '',
            price: 5.2,
            minStake: 0,
            probability: 0.173,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 210,
          },
          {
            outcome: 'draw',
            params: '',
            price: 2.9,
            minStake: 0,
            probability: 0.311,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 464.21,
          },
          {
            outcome: 'away',
            params: '',
            price: 1.75,
            minStake: 0,
            probability: 0.515,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1176,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.team_clean_sheet',
    submarkets: [
      {
        submarketKey: 'period=ft&team=away',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'yes',
            params: 'team=away',
            price: 1.9,
            minStake: 0,
            probability: 0.479,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 980,
          },
          {
            outcome: 'no',
            params: 'team=away',
            price: 1.75,
            minStake: 0,
            probability: 0.521,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1176,
          },
        ],
      },
      {
        submarketKey: 'period=ft&team=home',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'yes',
            params: 'team=home',
            price: 4.5,
            minStake: 0,
            probability: 0.204,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 252,
          },
          {
            outcome: 'no',
            params: 'team=home',
            price: 1.15,
            minStake: 0,
            probability: 0.796,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 5880,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.team_total_goals',
    submarkets: [
      {
        submarketKey: 'period=ft&team=away',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'over',
            params: 'team=away&total=1.5',
            price: 1.75,
            minStake: 0,
            probability: 0.521,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1176,
          },
          {
            outcome: 'under',
            params: 'team=away&total=1.5',
            price: 1.9,
            minStake: 0,
            probability: 0.479,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 980,
          },
          {
            outcome: 'over',
            params: 'team=away&total=0.5',
            price: 1.15,
            minStake: 0,
            probability: 0.796,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 5880,
          },
          {
            outcome: 'under',
            params: 'team=away&total=0.5',
            price: 4.5,
            minStake: 0,
            probability: 0.204,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 252,
          },
          {
            outcome: 'over',
            params: 'team=away&total=2.5',
            price: 3.25,
            minStake: 0,
            probability: 0.281,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 392,
          },
          {
            outcome: 'under',
            params: 'team=away&total=2.5',
            price: 1.27,
            minStake: 0,
            probability: 0.719,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3266.66,
          },
        ],
      },
      {
        submarketKey: 'period=ft&team=home',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'over',
            params: 'team=home&total=0.5',
            price: 1.75,
            minStake: 0,
            probability: 0.521,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1176,
          },
          {
            outcome: 'under',
            params: 'team=home&total=0.5',
            price: 1.9,
            minStake: 0,
            probability: 0.479,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 980,
          },
          {
            outcome: 'over',
            params: 'team=home&total=1.5',
            price: 4.55,
            minStake: 0,
            probability: 0.202,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 248.45,
          },
          {
            outcome: 'under',
            params: 'team=home&total=1.5',
            price: 1.15,
            minStake: 0,
            probability: 0.798,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 5880,
          },
          {
            outcome: 'over',
            params: 'team=home&total=2.5',
            price: 9.5,
            minStake: 0,
            probability: 0.096,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 103.76,
          },
          {
            outcome: 'under',
            params: 'team=home&total=2.5',
            price: 1.01,
            minStake: 0,
            probability: 0.904,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 88199.99,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.team_total_goals_period_first_half',
    submarkets: [
      {
        submarketKey: 'period=1h&team=away',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'over',
            params: 'team=away&total=0.5',
            price: 2.1,
            minStake: 0,
            probability: 0.44,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 801.81,
          },
          {
            outcome: 'under',
            params: 'team=away&total=0.5',
            price: 1.65,
            minStake: 0,
            probability: 0.56,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1356.92,
          },
        ],
      },
      {
        submarketKey: 'period=1h&team=home',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'over',
            params: 'team=home&total=0.5',
            price: 4.2,
            minStake: 0,
            probability: 0.219,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 275.62,
          },
          {
            outcome: 'under',
            params: 'team=home&total=0.5',
            price: 1.18,
            minStake: 0,
            probability: 0.781,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 4900,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.team_win_to_nil',
    submarkets: [
      {
        submarketKey: 'period=ft&team=away',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'yes',
            params: 'team=away',
            price: 0,
            minStake: 17.1,
            probability: 0.318,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'no',
            params: 'team=away',
            price: 0,
            minStake: 17.1,
            probability: 0.682,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
        ],
      },
      {
        submarketKey: 'period=ft&team=home',
        blank: false,
        sequence: 434,
        selections: [
          {
            outcome: 'yes',
            params: 'team=home',
            price: 0,
            minStake: 17.1,
            probability: 0.08,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
          {
            outcome: 'no',
            params: 'team=home',
            price: 0,
            minStake: 17.1,
            probability: 0.92,
            status: 'SELECTION_DISABLED',
            side: 'BACK',
            maxStake: 159.56,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.total_goals',
    submarkets: [
      {
        submarketKey: 'period=ft',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'over',
            params: 'total=2.25',
            price: 1.815,
            minStake: 0,
            probability: 0.523,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 2154.95,
          },
          {
            outcome: 'under',
            params: 'total=2.25',
            price: 1.989,
            minStake: 0,
            probability: 0.477,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'over',
            params: 'total=1.75',
            price: 1.402,
            minStake: 0,
            probability: 0.672,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 4314.7,
          },
          {
            outcome: 'under',
            params: 'total=1.75',
            price: 2.872,
            minStake: 0,
            probability: 0.328,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'over',
            params: 'total=2',
            price: 1.537,
            minStake: 0,
            probability: 0.615,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3251.98,
          },
          {
            outcome: 'under',
            params: 'total=2',
            price: 2.456,
            minStake: 0,
            probability: 0.385,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'over',
            params: 'total=2.5',
            price: 2.08,
            minStake: 0,
            probability: 0.456,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'under',
            params: 'total=2.5',
            price: 1.74,
            minStake: 0,
            probability: 0.544,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 2371.17,
          },
          {
            outcome: 'over',
            params: 'total=2.75',
            price: 2.416,
            minStake: 0,
            probability: 0.391,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1795.13,
          },
          {
            outcome: 'under',
            params: 'total=2.75',
            price: 1.553,
            minStake: 0,
            probability: 0.609,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3160.23,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.total_goals_period_first_half',
    submarkets: [
      {
        submarketKey: 'period=1h',
        blank: false,
        sequence: 620,
        selections: [
          {
            outcome: 'over',
            params: 'total=0.5',
            price: 1.815,
            minStake: 0,
            probability: 0.522,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 537.74,
          },
          {
            outcome: 'under',
            params: 'total=0.5',
            price: 1.981,
            minStake: 0,
            probability: 0.478,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'over',
            params: 'total=0.75',
            price: 2.238,
            minStake: 0,
            probability: 0.421,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'under',
            params: 'total=0.75',
            price: 1.625,
            minStake: 0,
            probability: 0.579,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 698.9,
          },
          {
            outcome: 'over',
            params: 'total=1',
            price: 3.496,
            minStake: 0,
            probability: 0.267,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 448.38,
          },
          {
            outcome: 'under',
            params: 'total=1',
            price: 1.276,
            minStake: 0,
            probability: 0.733,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1550.99,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.total_goals_period_second_half',
    submarkets: [
      {
        submarketKey: 'period=2h&period=ft&period=1h',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'over',
            params: 'total=1.5',
            price: 1.8,
            minStake: 0,
            probability: 0.514,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1102.5,
          },
          {
            outcome: 'under',
            params: 'total=1.5',
            price: 1.9,
            minStake: 0,
            probability: 0.486,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 980,
          },
          {
            outcome: 'over',
            params: 'total=2.5',
            price: 3.4,
            minStake: 0,
            probability: 0.269,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 367.5,
          },
          {
            outcome: 'under',
            params: 'total=2.5',
            price: 1.25,
            minStake: 0,
            probability: 0.731,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 3528,
          },
        ],
      },
    ],
  },
  {
    marketKey: 'soccer.which_team_wins_the_rest_of_the_match',
    submarkets: [
      {
        submarketKey: 'period=ft&score=0:0',
        blank: false,
        sequence: 618,
        selections: [
          {
            outcome: 'home',
            params: 'score=0:0',
            price: 5.7,
            minStake: 0,
            probability: 0.159,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 187.65,
          },
          {
            outcome: 'draw',
            params: 'score=0:0',
            price: 3.75,
            minStake: 0,
            probability: 0.242,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 320.72,
          },
          {
            outcome: 'away',
            params: 'score=0:0',
            price: 1.52,
            minStake: 0,
            probability: 0.598,
            status: 'SELECTION_ENABLED',
            side: 'BACK',
            maxStake: 1696.15,
          },
        ],
      },
    ],
  },
];

export const revertMarketDataShape = (marketData: APIMarketData): Record<string, Market> => {
  const resultMarket = {} as Markets;
  marketData.forEach((market) => {
    const submarkets = {} as Submarkets;
    market.submarkets.forEach((submarketData) => {
      const { submarketKey, ...rest } = submarketData;
      submarkets[submarketKey] = {
        ...rest,
      };
    });
    resultMarket[market.marketKey] = { submarkets };
  });
  return resultMarket;
};
