import Vue from 'vue';
import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VBtn,
  VContainer,
  VAutocomplete,
  VTextField,
  VForm,
} from 'vuetify/lib';

import isoCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { createNamespacedHelpers } from 'vuex';
import { CompetitionPriorityGroup } from '@/types/sports';

isoCountries.registerLocale(en);
const { mapMutations, mapGetters } = createNamespacedHelpers('sports/competitionsPriorities');

type CountriesList = Array<{ value: string, text: string }>;

type DialogMode = 'copy' | 'add' | 'edit';

export default Vue.extend({
  props: {
    editedGroup: Object as () => CompetitionPriorityGroup | undefined,
    mode: String as () => DialogMode,
  },

  data() {
    return {
      dialog: true,
      countries: [] as string[],
      name: '',
      countriesList: [] as CountriesList,
    };
  },

  methods: {
    ...mapMutations([
      'ADD_COMPETITION_PRIORITY_GROUP',
      'UPDATE_COMPETITION_PRIORITY_GROUP',
      'COPY_COMPETITION_PRIORITY_GROUP',
    ]),

    onClose() {
      this.$emit('dialogClosed');
    },

    onKeydown(e: KeyboardEvent) {
      if (e.code === 'Escape') {
        this.onClose();
      }
    },

    async onAcceptClick() {
      if (this.mode === 'copy') {
        this.COPY_COMPETITION_PRIORITY_GROUP({
          ...this.editedGroup,
          name: this.name,
          countries: this.countries,
        });
      } else if (this.mode === 'edit') {
        this.UPDATE_COMPETITION_PRIORITY_GROUP({
          ...this.editedGroup,
          name: this.name,
          countries: this.countries,
        });

      } else if (this.mode === 'add') {
        this.ADD_COMPETITION_PRIORITY_GROUP({
          name: this.name,
          countries: this.countries,
        });
      }
      this.onClose();
    },

    uniqueNameValidation(value: string) {
      return !this.unavailableNamesSet.has(value) || 'Name must be unique';
    },

    requiredNameValidation(value: string) {
      return !!value || 'Name is required';
    },
  },

  computed: {
    ...mapGetters(['assignedCountries', 'assignedNames']),

    disableAcceptButton(): boolean {
      return this.name === '';
    },

    availableCountries(): CountriesList {
      return this.countriesList.filter((country) => !this.unavailableCountriesSet.has(country.value));
    },

    unavailableCountriesSet(): Set<string> {
      if (this.mode === 'edit') {
        const set = new Set<string>(this.assignedCountries);
        this.editedGroup?.countries.forEach((country) => set.delete(country));
        return set;
      }
      return this.assignedCountries;
    },

    unavailableNamesSet(): Set<string> {
      if (this.mode === 'edit') {
        const set = new Set<string>(this.assignedNames);
        set.delete(this.editedGroup?.name || '');
        return set;
      }
      return this.assignedNames;
    },

    modalTitle(): string | undefined {
      if (this.mode === 'add') {
        return 'Add group';
      } else if (this.mode === 'edit') {
        return `Edit group`;
      } else if (this.mode === 'copy') {
        return 'Copy group';
      }
    },
  },

  mounted() {
    const isoCountriesList =
      Object.entries(isoCountries.getNames('en', { select: 'official' }))
        .map(([value, text]) => ({ value, text }));
    this.countriesList = [...isoCountriesList, { value: 'default', text: 'Default' }];

    if (this.editedGroup) {
      this.name = this.editedGroup.name;
      this.countries = this.editedGroup.countries;
    }
  },

  render() {
    return (
      <VDialog v-model={this.dialog} max-width='400' persistent
        on={{ 'click:outside': this.onClose, 'keydown': this.onKeydown }}>
        <VCard>
          <VCardTitle class='headline'>
            {this.modalTitle}
          </VCardTitle>

          <VForm>
            <VCardText>
              <VContainer>
                <VTextField
                  autofocus
                  dense
                  vModel={this.name}
                  rules={[this.uniqueNameValidation, this.requiredNameValidation]}
                  label='Name'
                />

                <VAutocomplete
                  class='mt-6'
                  dense
                  chips
                  small-chips
                  multiple
                  vModel={this.countries}
                  items={this.availableCountries}
                  label='Countries' />
              </VContainer>
            </VCardText>
          </VForm>

          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='green darken-1' text onClick={this.onClose}>
              Cancel
            </VBtn>
            <VBtn color='green darken-1' text
              onClick={this.onAcceptClick}
              disabled={this.disableAcceptButton}>
              Save
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});

