import Vue from 'vue';
import SearchEventDataTable from '@/components/GlobalSearch/SearchEventDataTable';
import SearchCompetitorDataTable from '@/components/GlobalSearch/SearchCompetitorDataTable';
import SearchCompetitionDataTable from '@/components/GlobalSearch/SearchCompetitionDataTable';
import CompetitionTranslationWidget from '@/components/sports/TranslationsWidget/CompetitionTranslationsWidget';
import CompetitorTranslationWidget from '@/components/sports/TranslationsWidget/CompetitorTranslationWidget';
import OutrightTranslationWidget from '@/components/sports/TranslationsWidget/OutrightTranslationWidget';
import { SearchNicknameDataTable } from '@/components/GlobalSearch/SearchNicknameDataTable';
import SearchUUIDDataTable from '@/components/GlobalSearch/SearchUUIDDataTable';
import SearchDepositAddress from '@/components/GlobalSearch/SearchDepositAddress';

export default Vue.extend({
  props: {
    searchText: String,
    sportId: Number,
    categoryId: Number,
    entityType: String,
    locale: String,
    property: String,
  },

  render() {
    const sportId =
      this.sportId || parseInt(this.$route.query.sportId as string, 10) || 0;
    const categoryId =
      this.categoryId ||
      parseInt(this.$route.query.categoryId as string, 10) ||
      0;
    const locale = this.$route.query.locale || this.locale;
    const entityType = this.$route.query.entityType || this.entityType;
    const property = this.$route.query.property || this.property;

    if (entityType === 'nickname' || entityType === 'player') {
      return (
        <SearchNicknameDataTable
          searchText={this.$route.query.acceptedSearchText || this.searchText}
        />
      );
    }

    if (property === 'translations' && this.entityType === 'competitor') {
      return (
        <CompetitorTranslationWidget
          name={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
          locale={locale}
        />
      );
    }
    if (property === 'translations' && this.entityType === 'competition') {
      return (
        <CompetitionTranslationWidget
          name={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
          categoryId={categoryId}
          locale={locale}
        />
      );
    }
    if (property === 'translations' && this.entityType === 'outrights') {
      return (
        <OutrightTranslationWidget
          name={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
          locale={locale}
        />
      );
    }

    if (entityType === 'uuid') {
      return (
        <SearchUUIDDataTable
          searchText={this.$route.query.acceptedSearchText || this.searchText}
        />
      );
    }

    if (entityType === 'address') {
      return (
        <SearchDepositAddress
          address={this.$route.query.acceptedSearchText || this.searchText}
        />
      );
    }

    if (!entityType || !sportId || this.searchText.length < 3) {
      return (
          <div>Entity Type, eg 'events', and sport required.</div>
      );
    }

    if (entityType === 'event') {
      return (
        <SearchEventDataTable
          searchText={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
          categoryId={categoryId}
        />
      );
    }

    if (entityType === 'competitor') {
      return (
        <SearchCompetitorDataTable
          searchText={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
        />
      );
    }

    if (entityType === 'competition') {
      return (
        <SearchCompetitionDataTable
          searchText={this.$route.query.acceptedSearchText || this.searchText}
          sportId={sportId}
          categoryId={categoryId}
        />
      );
    }

    return <div></div>;
  },
});
