<template>
  <v-snackbar
    v-model="showSnackbar"
    :color="snackbarColor"
    :top="top"
    :timeout="timeout"
    class="snackbar-message"
  >
    {{ snackbarMessage }}
    <v-btn text @click.native="closeSnackbar()">Close</v-btn>
  </v-snackbar>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
export default {
  data() {
    return {
      showSnackbar: false,
      snackbarMessage: '',
      snackbarColor: '',
      timeout: 5000,
      top: true,
    };
  },
  computed: {
    ...mapGetters({
      error: 'snackbar/error',
    }),
  },
  methods: {
    ...mapActions({
      showErrorSnackbar: 'snackbar/showErrorSnackbar',
      clearErrorSnackbar: 'snackbar/clearErrorSnackbar',
    }),
    closeSnackbar() {
      this.showSnackbar = false;
      this.snackbarColor = '';
      this.clearErrorSnackbar();
    },
  },
  created() {
    this.$store.watch(
      (state) => state.snackbar.error,
      (newError) => {
        if (newError) {
          this.showSnackbar = true;
          this.snackbarMessage = newError;
          this.snackbarColor = 'error';
          setTimeout(this.closeSnackbar, this.timeout);
        }
      },
    );
  },
};
</script>
<style>
.snackbar-message {
  white-space: pre-wrap;
}
</style>
