import Vue from 'vue';
import SearchPanel from './SearchPanel';
import SearchEventDataTable from './SearchEventDataTable';

export default Vue.extend({
  props: {
    searchText: String,
    sportId: Number,
    categoryId: Number,
    activator: String,
  },

  render() {
    return (
      <SearchPanel activator={this.activator}>
        <SearchEventDataTable
          searchText={this.searchText}
          sportId={this.sportId}
          categoryId={this.categoryId} />
      </SearchPanel>
    );
  },
});
