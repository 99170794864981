import Vue from 'vue';
import { LoyaltySchedules } from '@/components/sports/LoyaltySchedules';

export default Vue.extend({
  name: 'LoyaltySchedules',
  render() {
    return (
      <LoyaltySchedules></LoyaltySchedules>
    );
  },
});
