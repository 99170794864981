import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCol,
  VContainer,
  VDialog,
  VDivider,
  VForm,
  VIcon,
  VRow,
  VSpacer,
  VTextarea,
} from 'vuetify/lib';
import { validateRequired } from '@/utils/validate';

export default Vue.extend({
  name: 'DoubleConfirmWithCommentBtn',
  props: {
    btnMsg: String!,
    warningMsg: String!,
    confirmAction: Function!,
    btnColor: String,
    autoDisplay: Boolean,
  },
  data() {
    return {
      processing: false,
      failedAction: false,
      dialogOpen: this.autoDisplay || false,
      comment: '',
    };
  },
  computed: {
  },
  methods: {
    toggleDialogDisplay() {
      this.dialogOpen = !this.dialogOpen;
    },
    async save() {
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }

      this.processing = true;
      const actionResult = await this.confirmAction(this.comment);
      if (actionResult) {
        this.reset();
      } else {
        this.failedAction = true;
        this.processing = false;
      }
    },
    reset() {
      this.processing = false;
      this.comment = '';
      this.failedAction = false;
      this.dialogOpen = false;
      this.$emit('cancel');
    },
  },
  render() {
    return (
      <div>
        <VDialog vModel={this.dialogOpen} persistent max- width='600px' >
          <VCard>
            <VCardTitle>
              {this.btnMsg}
            </VCardTitle>
            <VDivider />
            <VCardText>
              <VContainer>
                <VRow>
                  <VCol>
                    Are you sure?
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    {this.warningMsg}
                  </VCol>
                </VRow>
                <VRow>
                  <VCol>
                    <VForm ref='form'>
                      <VTextarea
                        label='Comment'
                        outlined
                        v-model={this.comment}
                        rules={[validateRequired]}
                      />
                    </VForm>
                  </VCol>
                </VRow>
              </VContainer>
            </VCardText>
            <VCardActions>
              <VSpacer />
              <VBtn
                onClick={this.reset}
              >
                CANCEL
              </VBtn>
              <VBtn
                color='warning'
                loading={this.processing}
                onClick={this.save}
              >
                CONFIRM
                {
                  this.failedAction && <VIcon right dark>
                    warning
                  </VIcon>
                }
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog >
        <span onClick={this.toggleDialogDisplay}>
          {this.$slots.default ?
            this.$slots.default
            :
            <VBtn color={this.btnColor || 'normal'}>
              {this.btnMsg}
            </VBtn>}
        </span>
      </div>
    );
  },
});
