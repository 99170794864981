import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { googleSignIn, firebaseSignOut } from '@/firebase';
import router from '@/router';

export type FinancialLimitType = 'manual_credit_limit' | 'casino_rewards_limit';

export interface FinancialLimits {
  cumulative?: {
    '24h'?: number;
  };
  'casino-rewards'?: {
    '24h'?: number;
  };
}
export interface User {
  idToken: string | null;
  username: string | null;
  userId: string | null;
  email: string | null;
  photoUrl: string | null;
  roles: string[] | null;
  limits: FinancialLimits | null;
  tenants: string[] | null;
  isViewByRoleActive?: boolean | null;
  pseudoRoles?: string[] | null;
  // TODO:
  // role
  // timestamp
  // error
}

const getters: GetterTree<User, any> = {
  isAuthenticated(state): boolean {
    if (state.idToken) {
      return true;
    }
    return false;
  },
  idToken(state): string {
    return state.idToken || '';
  },
  name(state): string {
    return state.username || '';
  },
  avatar(state): string {
    return state.photoUrl || '';
  },
  email(state): string {
    return state.email || '';
  },
  roles(state): string[] {
    return state.roles || [];
  },
  limits(state): FinancialLimits | null {
    return state.limits || null;
  },
  tenants(state): string[] | null {
    return state.tenants || [];
  },
  userId(state): string | null {
    return state.userId;
  },
};

const mutations: MutationTree<User> = {
  UPDATE_USER(state, user) {
    if (user.idToken) {
      state.idToken = user.idToken;
    }
    if (user.username) {
      state.username = user.username;
    }
    if (user.userId) {
      state.userId = user.userId;
    }
    if (user.email) {
      state.email = user.email;
    }
    if (user.photoUrl) {
      state.photoUrl = user.photoUrl;
    }
    if (user.roles) {
      state.roles = user.roles;
    }
    if (user.limits) {
      state.limits = user.limits;
    }
    if (user.tenants) {
      state.tenants = user.tenants;
    }
  },
  CLEAR_USER(state) {
    state.idToken = null;
    state.username = null;
    state.userId = null;
    state.email = null;
    state.photoUrl = null;
    state.roles = null;
    state.limits = null;
    state.tenants = null;
  },
};

const actions: ActionTree<User, any> = {
  async googleSignIn({ commit }) {
    const result = await googleSignIn();
    if (result.user) {
      commit('UPDATE_USER', result.user);
      router.push('/');
    }
    return result;
  },
  async signout({ commit }) {
    commit('CLEAR_USER');
    await firebaseSignOut();
    router.go(0);
  },
};

const Auth = {
  namespaced: true,
  state: {
    idToken: null,
    username: null,
    userId: null,
    email: null,
    photoUrl: null,
    roles: null,
    tenants: null,
  } as User,
  getters,
  mutations,
  actions,
};

export default Auth;
