import Vue from 'vue';
import {
  VCard,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VExpansionPanels,
  VBtn,
  VIcon,
  VDivider,
  VChip,
  VSnackbar,
  VSwitch,
} from 'vuetify/lib';
import './Announcement.less';
import { mapActions } from 'vuex';
import { AnnouncementMessageItem } from './AnnouncementMessageItem';
import './AnnouncementMessageItem.less';
import { hasRoles } from '@/utils/auth';
import { typeColorMap, langKeys } from '@/config/announcement';
import { MessageKey, Message, Scope } from '@/types/announcements';

export const Announcement = Vue.extend({
  props: {
    type: String,
    name: String,
    uuid: String,
    messages: Array,
    messagesUpdatedAt: Array,
    enable: Boolean,
    scope: String,
  },
  data() {
    return {
      isClicked: false,
      enableConfirmation: false,
      hasDeleteError: false,
      hasUpdateError: false,
      showConfirmation: false,
    };
  },
  methods: {
    ...mapActions({
      updateAnnouncement: 'announcements/updateAnnouncement',
      deleteAnnouncement: 'announcements/deleteAnnouncement',
    }),
    onDelete() {
      this.enableConfirmation = true;
    },
    async onDeleteConfirmation() {
      try {
        await this.deleteAnnouncement({
          uuid: this.uuid,
        });
        this.hasDeleteError = false;
      } catch (err) {
        this.hasDeleteError = true;
      } finally {
        this.toggleConfirmation();
      }
    },
    async onUpdate(e: any) {
      e.stopPropagation();
      this.messagesUpdatedAt.forEach((message: any) => {
        delete message.__typename;
      });
      this.messages.forEach((message: any) => {
        delete message.__typename;
      });
      try {
        await this.updateAnnouncement({
          uuid: this.uuid,
          name: this.name,
          type: this.type,
          messages: this.messages,
          messagesUpdatedAt: this.messagesUpdatedAt,
          enable: this.enable,
          scope: this.scope,
        });
        this.hasUpdateError = false;
      } catch (err) {
        this.hasUpdateError = true;
      } finally {
        this.toggleConfirmation();
      }
    },
    async addNewAnnouncementMessageItem() {
      const usedKeys = this.messages.map((item: any) => item.key);
      const freeKeys =  langKeys.filter(((langKey: string) => !usedKeys.includes(langKey)));
      if (freeKeys.length > 0) {
        this.messages.push({ key: '', value: ''});
        this.hasUpdateError = false;
      } else {
        this.hasUpdateError = true;
        this.toggleConfirmation();
      }
    },
    onCancel() {
      this.enableConfirmation = false;
    },
    toggleConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },
  },

  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const announcementContent = this.messages.map((item: any) => {
      return (
        <AnnouncementMessageItem
          key={item.key}
          langKey={item.key}
          uuid={this.uuid}
          message={item.value}
          name={this.name}
          type={this.type}
          messages={this.messages}
          enable={this.enable}
          messagesUpdatedAt={this.messagesUpdatedAt}
          scope={this.scope}
        />
      );
    });
    const enMessage = this.messages.find((item: any) => item.key === MessageKey.en) as Message;
    const textClassName = `text-h7 announcement-message ${mobile ? 'text-truncate' : ''}`;
    const headerClassName = `announcement-header ${mobile ? 'announcement-header-mobile' : ''}`;
    return (
      <VCard class='announcement'>
        <VExpansionPanels>
          <VExpansionPanel >
            <VExpansionPanelHeader class={headerClassName}>
              <div class='announcement-type'>
                <VChip class='announcement-type-chip' label color={typeColorMap[this.type]} >
                  {this.type}
                </VChip>
              </div>
              <div class='text-h7 announcement-scope'> {this.scope}</div>
              <div class={textClassName}>{enMessage.value}</div>
              <div class='text-h7 announcement-status'>
                <VSwitch
                  vModel={this.enable}
                  onClick={this.onUpdate}
                />
              </div>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <div>
                <div class='announcement-item-grid'>
                  <div class='sparkline text-caption'>Lang Key</div>
                  <div class='item-nickname text-caption'>Message</div>
                </div>
                {announcementContent}
              </div>
              <div class='item-row mb-1 mt-1'>
                <VDivider />
              </div>
              {(hasRoles(['ops:operator']) || (hasRoles(['casino:operator']) && this.scope === Scope.casino)) &&
                (!this.enableConfirmation ? (
                  <div class='actions-container mt-4'>
                    <VBtn
                      color='green'
                      class='add-button mr-4'
                      icon
                      onClick={this.addNewAnnouncementMessageItem}
                    >
                      <VIcon>add</VIcon>
                    </VBtn>
                    <VBtn
                      color='red'
                      class='delete-button mr-4'
                      icon
                      onClick={this.onDelete}
                    >
                      <VIcon>delete</VIcon>
                    </VBtn>
                  </div>
                ) : this.hasDeleteError ? (
                  <div class='insufficient-permissions'>
                    Insufficient permissions
                  </div>
                ) : (
                  <div class='delete-confirmation mt-4'>
                    <VBtn
                      color='green'
                      class='delete-confirmation mr-4'
                      onClick={this.onDeleteConfirmation}
                    >
                      confirm
                    </VBtn>
                    <VBtn onClick={this.onCancel}>cancel</VBtn>
                  </div>
                ))}
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
        <VSnackbar
          color={this.hasUpdateError ? 'red' : 'green'}
          vModel={this.showConfirmation}
          timeout='1000'
        >
          <div class='text-center'>
          {this.hasDeleteError
              ? 'Failed to delete announcement item' : this.hasUpdateError ? 'Messages for every langauge keys exists'
              : 'Updated successfully'}
          </div>
        </VSnackbar>
      </VCard>
    );
  },
});
