import Vue from 'vue';
import { ExchangeSelection, ExchangeMarket, OrderBookState, ChosenSelection } from './types';
import {
  VBtn,
  VSimpleTable,
  VRow,
  VCol,
  VSnackbar,
  VTab,
  VTabs,
  VTabsItems,
  VTabItem,
  VTooltip,
} from 'vuetify/lib';
import OfferDetails, { detailsColor } from './OfferDetails';
import { Event } from '@/types/sports';
import { gql } from 'apollo-boost';
import { failureSnackbarConfig, successSnackbarConfig, formatSize } from './helpers';
import './MarketTable.less';
import OrderForm from './OrderForm';
import { Socket, Channel } from 'phoenix';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { EntityState } from './EntityState';
import Orders from './Orders';
import MarketCreator from './MarketCreator';
import { unixToDateTimeUTCString } from '@/utils/date';
import { hasRoles } from '@/utils/auth';
import { camelise } from '@/utils/objects';
import { selectionName } from '../markets-names';

interface OrderBookEntry {
  price: number;
  size: number;
}

const maxOrderBookEntries = 3;

const tabs = {
  placeBet: {
    key: 'placeBet',
    index: 0,
    title: 'Place bet',
  },
  openBets: {
    key: 'openBets',
    index: 1,
    title: 'Open bets',
  },
};

const selectionPriority = (name: string) => {
  switch (name) {
    case 'home': return 3;
    case 'draw': return 2;
    case 'away': return 1;
    default: return 0;
  }
};

export default Vue.extend({
  props: {
    marketKey: String,
    marketParams: String,
    event: Object as () => Event,
    socket: Socket,
  },

  data() {
    return {
      tabsIndex: 0,
      chosenSelection: null as ChosenSelection | null,
      failureMessage: null as JSX.Element | null,
      failureSnackbar: false,
      successMessage: '',
      successSnackbar: false,
      orderBookState: {} as OrderBookState,
      marketState: '',
      channel: null as Channel | null,
      showMarketCreator: false,
      market: null as ExchangeMarket | null,
    };
  },

  methods: {
    addSelectionIfNotExists(newSelection: ExchangeSelection) {
      if (this.market) {
        if (!_.some(this.market.selections, (s) => s.id === newSelection.id)) {
          this.market.selections.push(newSelection);
        }
      }
    },

    showFailure(message: string | string[]) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;
    },

    showSuccess(message: string) {
      this.successMessage = message;
      this.successSnackbar = true;
    },

    displayOrderForm(selection: ExchangeSelection, oppositeSide: string, price?: number) {
      this.chosenSelection = { selection, oppositeSide, price: price?.toString() };
    },

    selectionCell(selection: ExchangeSelection, side: string, prices?: OrderBookEntry, highlight?: boolean) {
      return (
        <td class={'offer text-center ' + (highlight ? side : '')}
          onClick={() => { this.displayOrderForm(selection, side, prices?.price); }}>
          <VTooltip bottom color={detailsColor(this.isNightMode)} scopedSlots={{
            activator: ({ on }: any) => (
              <div on={on}>
                {prices &&
                  <div>
                    <div><strong>{prices?.price}</strong></div>
                    <div><small>{formatSize(prices?.size)}</small></div>
                  </div>
                }
              </div>
            ),
          }}>
            {prices &&
              <OfferDetails orderBookState={this.orderBookState}
                selectionId={selection.id}
                side={side}
                price={prices.price.toString()} />
            }
          </VTooltip>
        </td>
      );
    },

    selectionRow(selection: ExchangeSelection) {
      const backPrices = this.backs(selection.id);
      const layPrices = this.lays(selection.id);

      return (
        <tr class={{ 'chosen-selection': this.chosenSelection?.selection.id === selection.id }}>
          <td>{selectionName({
            marketKey: this.market?.marketKey || '',
            marketParams: this.market?.marketParams || '',
            selectionName: selection.selectionName,
          }, this.event, this.market?.translations)}</td>
          {this.selectionCell(selection, 'back', layPrices[0])}
          {this.selectionCell(selection, 'back', layPrices[1])}
          {this.selectionCell(selection, 'back', layPrices[2], true)}
          {this.selectionCell(selection, 'lay', backPrices[0], true)}
          {this.selectionCell(selection, 'lay', backPrices[1])}
          {this.selectionCell(selection, 'lay', backPrices[2])}
        </tr>
      );
    },

    backs(marketId: number): OrderBookEntry[] {
      return _
        .chain(this.orderBookState.selections[marketId]?.backs)
        .toPairs()
        .map(([price, userSize]) => {
          return {
            price: parseFloat(price),
            size: Object.values(userSize).reduce((acc, size) => acc + parseFloat(size), 0),
          };
        })
        .orderBy(['price'], ['asc'])
        .take(maxOrderBookEntries)
        .concat(new Array(maxOrderBookEntries).fill(null))
        .take(maxOrderBookEntries)
        .value();
    },

    lays(marketId: number): OrderBookEntry[] {
      return _
        .chain(this.orderBookState.selections[marketId]?.lays)
        .toPairs()
        .map(([price, userSize]) => {
          return {
            price: parseFloat(price),
            size: Object.values(userSize).reduce((acc, size) => acc + parseFloat(size), 0),
          };
        })
        .orderBy(['price'], ['desc'])
        .take(maxOrderBookEntries)
        .concat(new Array(maxOrderBookEntries).fill(null))
        .reverse()
        .takeRight(maxOrderBookEntries)
        .value();
    },

    async openMarket() {
      if (await this.changeMarketState('open')) {
        this.showSuccess('Market has been opened');
      }
    },

    async suspendMarket() {
      if (await this.changeMarketState('suspended')) {
        this.showSuccess('Market has been suspended');
      }
    },

    async changeMarketState(state: string) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
            $eventId: Int!, $marketKey: String!, $marketParams: String!, $state: String!
          )
          {
            sportsExchangeChangeMarketState(
              eventId: $eventId,
              marketKey: $marketKey,
              marketParams: $marketParams,
              state: $state)
            {
              state
            }
          }`,
          variables: {
            eventId: Number(this.event.id),
            marketKey: this.marketKey,
            marketParams: this.marketParams,
            state,
          },
        });

        this.marketState = result.data.sportsExchangeChangeMarketState.state;
        return true;
      } catch (e) {
        this.failureSnackbar = true;
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body?.errors) {
          this.showFailure(e.graphQLErrors[0].extensions.response.body.errors);
        } else {
          this.showFailure(e.message);
          throw e;
        }
      }
    },

    onMarketUpdated(market: ExchangeMarket) {
      this.showMarketCreator = false;
      this.showSuccess('Market has been updated');
      this.market = market;
    },
  },

  computed: {
    ...mapGetters({ token: 'auth/idToken', isNightMode: 'preferences/isNightMode' }),

    marketDisabled(): boolean {
      return this.marketState === '' || this.marketState === 'disabled';
    },

    sortedSelections(): ExchangeSelection[] {
      return (this.market?.selections || []).sort((x, y) => {
        return selectionPriority(y.selectionName) - selectionPriority(x.selectionName);
      });
    },

    orderForm() {
      if (this.chosenSelection) {
        return (
          <OrderForm
            mode='add'
            market={this.market}
            selection={this.chosenSelection.selection}
            event={this.event}
            setSide={this.chosenSelection.oppositeSide}
            setPrice={this.chosenSelection.price}
            onCancelled={() => this.chosenSelection = null}
            onSaved={() => this.tabsIndex = tabs.openBets.index}
            disabled={this.marketState !== 'open'} />
        );
      }
    },

    orders() {
      if (this.tabsIndex === tabs.openBets.index && this.chosenSelection) {
        return (
          <Orders event={this.event} market={this.market} selection={this.chosenSelection.selection} />
        );
      }
    },

    manageMarketButtons() {
      if (this.marketState === 'suspended') {
        return (
          <VBtn
            color='primary'
            small
            onClick={this.openMarket}>
            Open Market
          </VBtn>
        );
      } else if (this.marketState === 'open') {
        return (
          <VBtn
            color='primary'
            small
            onClick={this.suspendMarket}>
            Suspend Market
          </VBtn>
        );
      }
    },

    marketCreator() {
      if (!this.showMarketCreator || !this.market) {
        return;
      }

      return (
        <MarketCreator
          event={this.event}
          marketPreset={this.market}
          onSaved={this.onMarketUpdated}
          onCancelled={() => this.showMarketCreator = false} />
      );
    },
  },

  apollo: {
    market: {
      query: gql`query($eventId: Int!, $marketKey: String!, $marketParams: String!) {
          sportsExchangeMarket(eventId: $eventId, marketKey: $marketKey, marketParams: $marketParams) {
          description
          effectiveTo
          eventId
          marketKey
          marketParams
          selections {
            id
            selectionName
            state
          }
          state
          translations
        }
      }`,

      variables(): { eventId: number, marketKey: string, marketParams: string } {
        return {
          eventId: Number(this.event.id),
          marketKey: this.marketKey,
          marketParams: this.marketParams,
        };
      },

      update(data) {
        const market = data.sportsExchangeMarket;
        return {
          ...market,
          translations: Object.fromEntries(market.translations),
        };
      },

      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    this.channel = this.socket.channel(
      `market:${this.event.id}:${this.marketKey}:${this.marketParams}`,
      { token: this.token },
    );
    this.channel.onError((reason) => {
      this.showFailure(`Market channel error: ${reason}`);
    });

    this.channel.on('prices-changed', (msg) => {
      this.orderBookState = msg;
    });

    this.channel.on('market-changed', (msg) => {
      this.marketState = msg.data?.state || 'disabled';
      this.market = { ...camelise(msg.data), selections: msg.data.selections.map(camelise) };
    });

    this.channel.on('selection-added', (msg) => {
      const newSelection = camelise(msg.data);
      this.addSelectionIfNotExists(newSelection);
    });

    this.channel.on('selection-changed', (msg) => {
      const changedSelection = camelise(msg.data);
      if (this.market) {
        this.market.selections = this.market.selections.map((s) => {
          return s.id === changedSelection.id ? changedSelection : s;
        });
      }
    });

    this.channel.join()
      .receive('ok', (msg) => {
        this.marketState = msg.data?.state || 'suspended';
        this.channel?.push('get-prices', {})
          .receive('ok', (prices) => { this.orderBookState = prices; })
          .receive('error', ({ reason }) => {
            this.showFailure(`Getting prices error: ${reason}`);
          })
          .receive('timeout', () => {
            this.showFailure('Getting prices timeout. Still waiting...');
          });
      })
      .receive('error', ({ reason }) => {
        this.showFailure(`Joining market channel error: ${reason}`);
      })
      .receive('timeout', () => {
        this.showFailure('Joining market channel timeout. Still waiting...');
      });
  },

  destroyed() {
    this.channel?.leave();
  },

  render() {
    return (
      <div class='market-table'>
        {this.marketCreator}
        <VSnackbar {...failureSnackbarConfig(this.failureMessage)} vModel={this.failureSnackbar} />
        <VSnackbar {...successSnackbarConfig(this.successMessage)} vModel={this.successSnackbar} />
        <VRow>
          <VCol cols='9'>
            <VRow>
              <VCol>
                <EntityState state={this.marketState} />
                <div class='mb-2'>
                  <span>Effective to: </span>
                  <span>
                    {this.market && this.market.effectiveTo ?
                      unixToDateTimeUTCString(this.market.effectiveTo) : 'Not defined'
                    }
                  </span>
                </div>
              </VCol>
              <VCol class='text-right'>
                {hasRoles(['sports:operator']) &&
                  <div>
                    <VBtn class='mr-2' small
                      onClick={() => this.showMarketCreator = !this.showMarketCreator}>
                      Edit market
                    </VBtn>
                    {this.manageMarketButtons}
                  </div>
                }
              </VCol>
            </VRow>
            <VSimpleTable>
              <tbody>
                {this.sortedSelections.map(this.selectionRow)}
              </tbody>
            </VSimpleTable>
          </VCol>
          <VCol cols='3'>
            <VTabs fixed-tabs height='36' vModel={this.tabsIndex}>
              <VTab key={tabs.placeBet.key}>
                {tabs.placeBet.title}
              </VTab>
              <VTab key={tabs.openBets.key}>
                {tabs.openBets.title}
              </VTab>
            </VTabs>
            <VTabsItems vModel={this.tabsIndex}>
              <VTabItem key={tabs.placeBet.key} class='pt-2'>{this.orderForm}</VTabItem>
              <VTabItem key={tabs.openBets.key} class='pt-2'>{this.orders}</VTabItem>
            </VTabsItems>
          </VCol>
        </VRow>
      </div >
    );
  },
});
