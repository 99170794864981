import Vue from 'vue';
import {
  VTabs,
  VTab,
  VTabItem,
  VTabsItems,
  VMenu,
  VBtn,
  VIcon,
  VList,
  VListItem,
} from 'vuetify/lib';
import { hasLimits, hasRoles } from '@/utils/auth';
import _ from 'lodash';
import './ResponsiveTabs.less';
import { FinancialLimitType } from '@/store/modules/auth';

export interface Tab {
  requiresRoles: string[];
  requiresLimits?: FinancialLimitType[];
  key: string;
  name: string;
  content: JSX.Element;
  disabled?: boolean;
}

const randomID = () => {
  return (Math.random() + 1).toString(36).substring(2);
};

const minTabsItemWidth = 160;

export default Vue.extend({
  props: {
    tabs: {
      type: Array as () => Tab[],
      default: () => [],
    },
    tabsOrder: {
      type: Array as () => string[],
      default: () => [],
    },
  },

  data() {
    return {
      tab: this.tabsOrder[0],
      moreTabs: [] as Tab[],
      visibleTabs: [] as Tab[],
      containerID: `tabs-${randomID()}`,
      containerWidth: 0,
    };
  },

  computed: {
    availableTabs(): Tab[] {
      return this.tabs.filter(
        (tab) =>
          // Check if tab is disabled
          !tab.disabled &&
          // Check if user has required roles
          (hasRoles(tab.requiresRoles) ||
            (tab.hasOwnProperty('requiresLimits')
              ? hasLimits(tab.requiresLimits)
              : false)),
      );
    },
  },

  mounted() {
    const tabs = document.querySelector(`#${this.containerID}`);
    if (tabs) {
      new ResizeObserver((roe: ResizeObserverEntry[]) => {
        const clientWidth = roe[0].target.clientWidth;
        if (clientWidth !== 0 && this.containerWidth !== clientWidth) {
          this.containerWidth = clientWidth;
          this.onResize(this.containerWidth);
        }
      }).observe(tabs);
    }
  },

  methods: {
    addTab(tab: Tab) {
      const removed = this.visibleTabs.splice(this.visibleTabs.length - 1, 1);
      this.visibleTabs.push(
        ...this.moreTabs.splice(this.moreTabs.indexOf(tab), 1),
      );
      this.moreTabs.push(...removed);
      this.$router.push({ hash: `#${tab.key}` });
    },

    onResize(width: number) {
      if (this.$vuetify.breakpoint.mobile) {
        this.visibleTabs = _.orderBy(this.availableTabs, this.sortTabsFun);
        this.moreTabs = [];
        return;
      }

      const visibleCount = width / minTabsItemWidth - 1;
      const tabKey = location.hash.replace('#', '') || this.tab;

      const sortedTabs = _.orderBy(this.availableTabs, this.sortTabsFun);
      let visibleTabs = sortedTabs.slice(0, visibleCount);
      let moreTabs = sortedTabs.slice(visibleCount);
      if (!visibleTabs.some((t) => t.key === tabKey)) {
        const selectedTab = sortedTabs.find((t) => t.key === tabKey);
        if (selectedTab) {
          moreTabs = moreTabs.filter((t) => t.key !== selectedTab.key);
          moreTabs.push(visibleTabs[visibleTabs.length - 1]);
          visibleTabs = visibleTabs.slice(0, visibleCount - 1);
          visibleTabs.push(selectedTab);
        }
      }

      this.visibleTabs = _.orderBy(visibleTabs, this.sortTabsFun);
      this.moreTabs = _.orderBy(moreTabs, this.sortTabsFun);
    },

    sortTabsFun(tab: Tab) {
      // The tabs which order is set by tabsOrder prop will be displayed first
      const orderIndex = this.tabsOrder.indexOf(tab.key);
      if (orderIndex !== -1) {
        return `${'a'.repeat(2)}${String.fromCharCode(
          'a'.charCodeAt(0) + orderIndex,
        )}`;
      } else {
        return tab.name.toLowerCase();
      }
    },
  },

  render() {
    return (
      <div id={this.containerID} class='responsive-tabs'>
        <VTabs
          vModel={this.tab}
          fixedTabs={!this.$vuetify.breakpoint.mobile}
          showArrows={this.$vuetify.breakpoint.mobile}
        >
          {this.visibleTabs.map((tab) => (
            <VTab key={tab.key} to={'#' + tab.key}>
              {tab.name}
            </VTab>
          ))}

          {this.moreTabs.length > 0 && (
            <VMenu
              scopedSlots={{
                activator: ({ on }: any) => {
                  return (
                    <VBtn text class='align-self-center mr-4' on={on}>
                      more
                      <VIcon right>arrow_drop_down</VIcon>
                    </VBtn>
                  );
                },
              }}
            >
              <VList>
                {this.moreTabs.map((tab) => (
                  <VListItem
                    key={tab.key}
                    onClick={() => this.addTab(tab)}
                    class='text-uppercase responsive-tabs-hidden-item'
                  >
                    {tab.name}
                  </VListItem>
                ))}
              </VList>
            </VMenu>
          )}
        </VTabs>

        <VTabsItems vModel={this.tab} touchless={true}>
          {this.availableTabs.map((tab) => (
            <VTabItem key={tab.key} value={tab.key}>
              {tab.content}
            </VTabItem>
          ))}
        </VTabsItems>
      </div>
    );
  },
});
