import Vue, { VueConstructor } from 'vue';
import errorHandlerMixin from '../errorHandlerMixin';
import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VBtn,
  VTextarea,
  VSelect,
  VTextField,
  VRow,
  VCol,
  VForm,
} from 'vuetify/lib';
import {
  GradingReviewAction,
  GradingResultType,
  gradingResultTypes,
} from '@/store/modules/sports/in_context_help_grading_results';
import { validateRequired, validateInteger } from '@/utils/validate';
import { mapActions } from 'vuex';

const splitLinks = (links: string) => {
  links = links.trim();
  if (links.length === 0) {
    return [];
  }
  return links.split(/[\n;]/);
};

export interface Result {
  eventId: number;
  marketKey: string;
  outcome: string;
  params: string;
  id?: string;
  result?: GradingResultType;
  numberOfPayouts?: number;
  numberOfTieWinners?: number;
}

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],
  props: {
    result: Object as () => Result,
    action: {
      type: String as () => GradingReviewAction,
      default: () => 'REVIEW_ACTION_REJECT',
    },
  },

  data() {
    return {
      dialog: true,
      updating: false,
      valid: false,
      comment: '',
      links: '',
      resultType: this.result.result,
      numberOfPayouts: this.result.numberOfPayouts || 1,
      numberOfTieWinners: this.result.numberOfTieWinners || 1,
    };
  },

  computed: {
    resultItems() {
      return gradingResultTypes.map((value) => {
        return {
          text: value.replace('RESULT_TYPE_', ''),
          value,
        };
      });
    },

    reviewMode(): boolean {
      return !!this.result.id;
    },

    resultFields() {
      return (
        <div>
          <VSelect autofocus outlined dense label='Result'
            items={this.resultItems}
            vModel={this.resultType}
            disabled={this.reviewMode}
            rules={[validateRequired]} />
          {this.resultType === 'RESULT_TYPE_WIN' &&
            <VRow>
              <VCol>
                <VTextField outlined dense v-numeric-input
                  label='Payouts'
                  vModel={this.numberOfPayouts}
                  disabled={this.reviewMode}
                  rules={[validateInteger]} />
              </VCol>
              <VCol>
                <VTextField outlined dense v-numeric-input
                  label='Tie winners'
                  vModel={this.numberOfTieWinners}
                  disabled={this.reviewMode}
                  rules={[validateInteger]} />
              </VCol>
            </VRow>
          }
        </div>
      );
    },

    dialogTitle(): string {
      if (this.reviewMode) {
        return this.action === 'REVIEW_ACTION_APPROVE' ? 'Approve result' : 'Reject result';
      } else {
        return 'Add result';
      }
    },
  },

  methods: {
    ...mapActions({
      storeReview: 'sports/inContextHelpGradingResults/addReview',
      storeResult: 'sports/inContextHelpGradingResults/addResult',
    }),

    onCancel() {
      this.$emit('cancelled');
    },

    async addReview() {
      try {
        this.updating = true;
        await this.storeReview({
          resultId: this.result.id,
          input: {
            action: this.action,
            comment: this.comment,
            links: splitLinks(this.links),
          },
        });

        this.$emit('reviewAdded');
      } catch (e) {
        this.showFailureMessage(['Couldn\'t add a review:', e.message]);
        throw e;
      } finally {
        this.updating = false;
      }
    },

    async addResult() {
      try {
        this.updating = true;
        await this.storeResult({
          comment: this.comment,
          eventId: this.result.eventId,
          links: splitLinks(this.links),
          marketKey: this.result.marketKey,
          numberOfPayouts: this.numberOfPayouts,
          numberOfTieWinners: this.numberOfTieWinners,
          outcome: this.result.outcome,
          params: this.result.params,
          requestId: '',
          result: this.resultType,
        });

        this.$emit('resultAdded');
      } catch (e) {
        this.showFailureMessage(['Couldn\'t add a result:', e.message]);
        throw e;
      } finally {
        this.updating = false;
      }
    },
  },

  render() {
    return (
      <VDialog vModel={this.dialog} persistent max-width='500'>
        {this.failureSnackbar}
        <VForm vModel={this.valid}>
          <VCard>
            <VCardTitle>
              {this.dialogTitle}
            </VCardTitle>
            <VCardText>
              {this.resultFields}
              <VTextarea autofocus={this.reviewMode} outlined
                label='Comment'
                vModel={this.comment}
                rules={[validateRequired]} />
              <VTextarea outlined label='Links (add a link in a new line)' vModel={this.links} />
            </VCardText>
            <VCardActions>
              <VBtn
                color='success'
                onClick={this.reviewMode ? this.addReview : this.addResult}
                disabled={!this.valid}
                loading={this.updating}>
                Save
            </VBtn>
              <VBtn onClick={this.onCancel}>Cancel</VBtn>
            </VCardActions>
          </VCard>
        </VForm>
      </VDialog>
    );
  },
});
