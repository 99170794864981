import './utility.less';
import Vue from 'vue';
import { validateNumber } from '@/utils/validate';
import {
  VBtn,
  VCol,
  VIcon,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VSwitch,
  VContainer,
  VDataTable,
  VDialog,
  VTooltip,
  VDivider,
  VRow,
  VSimpleTable,
  VSpacer,
  VTextField,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import {
  getMarketName,
  getOutcome,
  Locale,
  MarketType,
} from '@cloudbet/market-helper/sports-core';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('sports/events');
import { hasRoles } from '@/utils/auth';
import { iso8601ToDateTimeUTCString } from '@/utils/date';

export default Vue.extend({
  name: 'eventResultWidget',
  props: {
    eventId: String,
  },
  data() {
    return {
      expanded: [],
      results: [] as any,
      saving: false,
      dialogOpen: false,
      selectedSelection: {} as any,
    };
  },
  computed: {
    ...mapState(['currentEvent']),
  },
  methods: {
    expandedItem({ headers, item }: any) {
      return (
        <td colSpan={headers.length}>
          <VSimpleTable>
            <tbody>
              {item.items.map((child: any) => {
                let className = '';
                if (child.disabled && child.effective) {
                  className = 'bg-red';
                }
                if (!child.effective) {
                  className = 'bg-gray';
                }

                return (
                  <tr class={className}>
                    <td></td>
                    <td>{child.timestamp}</td>
                    <td>{this.getMarketName(child)}</td>
                    <td>{this.getOutcome(child)}</td>
                    <td>{this.getNumberOfPayouts(child)}</td>
                    <td>{this.getNumberOfTieWinners(child)}</td>
                    <td>{child.result}</td>
                    <td>{child.adminId || child.provider}</td>
                    <td>{child.comment}</td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </VSimpleTable>
        </td >
      );
    },
    async updateResult(selection: any) {
      let input = {
        marketKey: selection.marketKey,
        outcome: selection.outcome,
        params: selection.params,
        result: selection.result,
        comment: selection.comment,
      };

      if (selection.enableNumberOfParams) {
        input = {
          ...input, ...{
            numberOfPayouts: Number(selection.numberOfPayouts),
            numberOfTieWinners: Number(selection.numberOfTieWinners),
          },
        };
      }

      const result = await this.$apollo.mutate({
        mutation: gql`mutation SubmitEventResults($eventId: String!, $input: EventResultsList) {
            submitEventResults(eventID: $eventId, input: $input) {
              success
            }
          }`,
        variables: {
          eventId: this.eventId,
          input: {
            results: [input],
          },
        },
      });
    },
    getMarketName(item: any): string {
      if (!item.marketKey) { return ''; }
      const marketParser = getMarketName(
        Locale.en,
        { params: item.params },
        item.marketKey as MarketType,
        {
          home: {
            name: this.currentEvent?.competitors?.[0]?.name || 'home',
            key: 'home',
            abbreviation: 'home',
          },
          away: {
            name: this.currentEvent?.competitors?.[1]?.name || 'away',
            key: 'away',
            abbreviation: 'away',
          },
        } as any,
      );

      if (marketParser[0]) {
        return marketParser[0];
      }

      if (item.params) {
        return `${item.marketKey}?${item.params}`;
      }

      return item.marketKey;
    },
    getOutcome(item: any): string {
      const result = getOutcome(Locale.en,
        {
          home: {
            name: this.currentEvent?.competitors?.[0]?.name || 'home',
            key: 'home',
            abbreviation: 'home',
          },
          away: {
            name: this.currentEvent?.competitors?.[1]?.name || 'away',
            key: 'away',
            abbreviation: 'away',
          },
        },
        item.marketKey as MarketType,
        {
          params: item.params,
          outcome: item.outcome,
        });
      return result[0]?.name || item.outcome;
    },
    getNumberOfPayouts(item: any): string {
      if (item.numberOfPayouts <= 1 && item.numberOfTieWinners <= 1) {
        return '';
      }
      return item.numberOfPayouts.toString();
    },
    getNumberOfTieWinners(item: any): string {
      if (item.numberOfPayouts <= 1 && item.numberOfTieWinners <= 1) {
        return '';
      }
      return item.numberOfTieWinners.toString();
    },
    numberOfPayouts({ item }: any): any {
      return (<span>{this.getNumberOfPayouts(item)}</span>);
    },
    numberOfTieWinners({ item }: any): any {
      return (<span>{this.getNumberOfTieWinners(item)}</span>);
    },
    action({ item }: any): any {
      if (!hasRoles(['sports:operator'])) {
        return <div></div>;
      }

      return (<VBtn onClick={() => {
        this.selectedSelection = Object.assign({}, item);
        if (!(item.numberOfPayouts <= 1 && item.numberOfTieWinners <= 1)) {
          this.selectedSelection.enableNumberOfParams = true;
        }
        this.dialogOpen = true;
      }} primary>Edit as new</VBtn>);
    },
    source({ item }: any): any {
      return (<span>{item.adminId || item.provider}</span>);
    },
    comment({ item }: any): any {
      return (<span>
        {item.comment}
      </span>);
    },
    market({ item }: any): any {
      return (<span>{this.getMarketName(item)}</span>);
    },
    outcome({ item }: any): any {
      return (<span>{this.getOutcome(item)}</span>);
    },
    timestamp({ item }: { item: { timestamp: string } }) {
      return iso8601ToDateTimeUTCString(item.timestamp);
    },
  },
  apollo: {
    results: {
      query: gql`query ($eventId: String!) {
        results: eventResults(eventId: $eventId) {
          results {
            timestamp
            marketKey
            outcome
            params
            provider
            adminId
            effective
            result
            disabled
            comment
            numberOfPayouts
            numberOfTieWinners
          }
        }
      }`,
      update(data) {
        const results = data.results.results.map((s: any) => {
          return Object.assign({}, s, { key: s.marketKey + '|' + s.params + '|' + s.outcome });
        });
        const groupByKey = {} as any;
        results.forEach((result: any) => {
          if (!groupByKey[result.key]) {
            result.items = [];
            groupByKey[result.key] = result;
          } else {
            groupByKey[result.key].items.push(result);
          }
        });
        const finalObjects = [];
        for (const key of Object.keys(groupByKey)) {
          groupByKey[key].items.sort((a: any, b: any) => b.timestamp.localeCompare(a.timestamp));
          finalObjects.push(groupByKey[key]);
        }
        return finalObjects;
      },
      variables(): any {
        return { eventId: this.eventId };
      },
    },
  },
  render() {
    return (
      <VContainer fluid>
        <VDialog vModel={this.dialogOpen} persistent max-width='600px'>
          <VCard>
            <VCardTitle>
              <h2>{this.getMarketName(this.selectedSelection)}</h2>
            </VCardTitle>
            <VDivider />
            <VCardText>
              <VContainer fluid>
                <VRow>
                  <h2>Outcome: {this.getOutcome(this.selectedSelection)}</h2>
                </VRow>
                <VRow>
                  <VBtnToggle
                    vModel={this.selectedSelection.result}
                    mandatory={true}>
                    <VBtn value='WIN'>WIN</VBtn>
                    <VBtn value='LOSS'>LOSS</VBtn>
                    <VBtn value='PUSH'>PUSH</VBtn>
                    <VBtn value='HALF_WIN'>HALF WIN</VBtn>
                    <VBtn value='HALF_LOSS'>HALF LOSS</VBtn>
                    <VBtn value='PENDING'>PENDING</VBtn>
                  </VBtnToggle>
                </VRow>
                <VRow>
                  <VCol md='2'>
                    <VTooltip
                      top
                      scopedSlots={{
                        activator: ({ on, attrs }: any) => (
                          <div on={on} >
                            <VSwitch
                              v-model={this.selectedSelection.enableNumberOfParams}
                            ></VSwitch>
                          </div>
                        ),
                      }}
                    >
                      <span>Used for deadheat cases or multiple payouts and winners.  </span>
                      <span>Simple deadheat: 1 payout, 2 tie winners</span>
                    </VTooltip>
                  </VCol>
                  <VCol md='8'>
                    <VRow>
                      <VTextField
                        disabled={!this.selectedSelection.enableNumberOfParams}
                        vModel={this.selectedSelection.numberOfPayouts}
                        rules={[validateNumber]}
                        label='No. Payouts'
                      >
                      </VTextField>
                      <VTextField
                        disabled={!this.selectedSelection.enableNumberOfParams}
                        vModel={this.selectedSelection.numberOfTieWinners}
                        rules={[validateNumber]}
                        label='No. Tie Winners'
                      >
                      </VTextField>
                    </VRow>
                    <VRow>
                      <div>
                        Detailed example from betfair: {'https://support.betfair.com/app/answers/detail/a_id/403'}
                      </div>
                    </VRow>
                  </VCol>

                </VRow>
                <VRow>
                  <VTextField
                    vModel={this.selectedSelection.comment}
                    label='Comment'
                  >
                  </VTextField>
                </VRow>
              </VContainer>
            </VCardText>
            <VCardActions>
              <VSpacer />
              <VBtn text color='primary' onClick={async () => {
                await this.updateResult(this.selectedSelection);
                this.$apollo.queries.results.refetch();
                this.dialogOpen = false;
              }}>Save</VBtn>
              <VBtn text onClick={() => { this.dialogOpen = false; }}>
                Close
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
        <VDataTable
          showExpand
          itemKey='key'
          singleExpand={true}
          class='elevation-1'
          itemClass={(item: any) => {
            if (item.disabled && item.effective) {
              return 'bg-red';
            }
            if (!item.effective) {
              return 'bg-gray';
            }
            return '';
          }}
          headers={[
            { text: 'Time', value: 'timestamp' },
            { text: 'Market', value: 'key' },
            { text: 'Outcome', value: 'outcome', sortable: false },
            { text: 'No. Payouts', value: 'numberOfPayouts', sortable: false },
            { text: 'No. Tie Winners', value: 'numberOfTieWinners', sortable: false },
            { text: 'Result', value: 'result', sortable: false },
            { text: 'Source', value: 'source', sortable: false },
            { text: 'Comment', value: 'comment', sortable: false },
            { text: 'Action', value: 'action', sortable: false },
          ]}
          scopedSlots={
            {
              'expanded-item': this.expandedItem,
              'item.action': this.action,
              'item.key': this.market,
              'item.outcome': this.outcome,
              'item.numberOfPayouts': this.numberOfPayouts,
              'item.numberOfTieWinners': this.numberOfTieWinners,
              'item.source': this.source,
              'item.comment': this.comment,
              'item.timestamp': this.timestamp,
            }}
          items={this.results || []}
          multiSort
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.results.loading}
        />
      </VContainer >
    );
  },
});
