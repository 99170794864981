import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VDataTable, VIcon, VSnackbar } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import { unixToDateTimeUTCString } from '@/utils/date';
import OkSnackbar from '@/components/OkSnackbar';
import FailSnackbar from '@/components/FailSnackbar';

interface DepositAddress {
  address: string;
  cloudbet_currency: string;
  tag_or_memo: string;
  is_legacy: boolean;
  source: string;
  state: string;
  vault_account_id: string;
  is_sync: boolean;
  created_at: string;
  blockchain: string;
}

export default Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      isLoading: true,
      isLoadFailed: false,
      loadError: '',
      depositAddresses: [] as DepositAddress[],
      isGenMissingOK: false,
      headers: [
        { text: 'Created At', align: 'start', value: 'created_at', width: '170px' },
        { text: 'Address', align: 'start', value: 'address' },
        { text: 'Currency', align: 'start', value: 'cloudbet_currency' },
        { text: 'Tag/Memo', align: 'start', value: 'tag_or_memo' },
        { text: 'State', align: 'start', value: 'state' },
        { text: 'Source', align: 'start', value: 'source' },
        { text: 'Vault Account ID', align: 'start', value: 'vault_account_id' },
        { text: 'Blockchain', align: 'start', value: 'blockchain' },
        { text: 'Is Legacy', align: 'start', value: 'is_legacy' },
        { text: 'Is Sync', align: 'start', value: 'is_sync' },
      ],
    };
  },

  mounted() {
    this.fetchDepositAddresses();
  },

  methods: {
    async fetchDepositAddresses(): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($playerUUID: String!) {
              playerDepositAddresses(playerUUID: $playerUUID) {
                address
                cloudbet_currency
                tag_or_memo
                is_legacy
                source
                state
                vault_account_id
                is_sync
                created_at
                blockchain
              }
            }`,
          variables: { playerUUID: this.playerUUID },
          fetchPolicy: 'network-only',
        });
        this.depositAddresses = response.data.playerDepositAddresses;
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
    },

    async genMissingAddresses(): Promise<any> {
      this.isGenMissingOK = false;
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        await this.$apollo.query({
          query: gql`
            query($playerUUID: String!) {
              playerMissingAddresses(playerUUID: $playerUUID)
            }`,
          variables: { playerUUID: this.playerUUID },
          fetchPolicy: 'network-only',
        });
        this.isGenMissingOK = true;
        this.fetchDepositAddresses();
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
    },

    formatTime(time: string): string {
      const str = unixToDateTimeUTCString(Date.parse(time) / 1000);
      return str.substring(0, str.length - 4);
    },
  },

  render() {
    if (!hasRoles(
      ['cs:operator', 'cs:user', 'finance:operator', 'finance:user', 'payments:operator', 'payments:user', 'pii:operator', 'pii:user'])) {
      return (
        <div class='container'>
          Unauthorized
        </div>
      );
    }

    return (
      <div class='container'>
        <OkSnackbar show={this.isGenMissingOK} />
        <FailSnackbar show={this.isLoadFailed} error={this.loadError} />
        <div class='item-action'>
            {hasRoles(['payments:operator']) && (
              <div>
                <VBtn color='green' onClick={this.genMissingAddresses} disabled={this.isLoading}>
                  Generate Missing Addresses
                </VBtn>
              </div>
            )}
          </div>
        <VDataTable
          headers={this.headers}
          items={this.depositAddresses}
          loading={this.isLoading}
          loading-text='Loading... Please wait'
          items-per-page={10}
          footerProps={{'items-per-page-options': [10, -1]}}
          scopedSlots={{
            'item.created_at': ({ item }: { item: DepositAddress }) => (
              this.formatTime(item.created_at)
            ),
            'item.address': ({ item }: { item: DepositAddress }) => (
              <p class='text-truncate'>{item.address}</p>
            ),
            'item.is_legacy': ({ item }: { item: DepositAddress }) => (
              <VIcon>{item.is_legacy ? 'done' : 'remove'}</VIcon>
            ),
            'item.is_sync': ({ item }: { item: DepositAddress }) => (
              <VIcon>{item.is_sync ? 'done' : 'remove'}</VIcon>
            ),
          }}
        />
      </div>
    );
  },
});
