// views/payments/CryptoDepositResync.tsx
import Vue from 'vue';


import { CryptoDepositResyncWidget } from '@/components/payments/CryptoDepositResyncWidget';
import { hasRoles } from '@/utils/auth';

export default Vue.extend({
  name: 'cryptoDepositResyncWidget',
  render() {
    return (
      <div class='ma-4'>
        {hasRoles(['cs:operator', 'cs:user', 'payments:operator', 'payments:user'])}
        <div class='resync-container'>
          <CryptoDepositResyncWidget />
        </div>
      </div>
    );
  },
});
