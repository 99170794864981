import Vue from 'vue';

interface EntityStateProps {
  state: string;
}

export const EntityState = Vue.extend<EntityStateProps>({
  name: 'EntityState',
  functional: true,
  render(_, { props }) {
    const className = props.state === 'open' ? 'success--text' : 'warning--text';
    return (
      <span class={`text-uppercase ${className}`}>
        {props.state}
      </span>);
  },
});




