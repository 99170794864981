import Vue, { VNode, VueConstructor } from 'vue';
import RepublishEventBetsTrigger from '@/components/sports/RepublishEventBetsTrigger.vue';
import { eventPageUri } from '@/utils/external-uris';
import {
  VListItemContent,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VIcon,
  VTooltip,
  VBtn,
} from 'vuetify/lib';
import './EventHeadline.less';
import { createNamespacedHelpers } from 'vuex';
import { unixToDateTimeUTCString } from '@/utils/date';
import { RouterLink } from '@/router';
import { env } from '@/env';
import EventEditor from './EventEditor';
import { hasRoles } from '@/utils/auth';
import { Event } from '@/types/sports';
import errorHandlerMixin from '../errorHandlerMixin';
import MatchingEditor from './MatchingEditor';
import ProviderPriceControl from './ProviderPriceControl';

const { mapGetters, mapActions, mapState } = createNamespacedHelpers('sports/events');
const { mapState: mapConstsState, mapActions: mapConstsActions } = createNamespacedHelpers('sportsConsts');

const EVENT_DISABLED_STATE = 'EVENT_DISABLED';

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],
  props: {
    showRepublishEventBetsTrigger: Boolean,
    eventId: Number,
  },
  data() {
    return {
      editMode: false,
      editRegistry: false,
      stateUpdating: false,
      priceControlMode: false,
    };
  },
  methods: {
    ...mapActions(['loadCurrentEvent', 'refreshCurrentEvent']),
    ...mapConstsActions(['loadSports']),
    eventPageUri,
  },
  computed: {
    ...mapGetters(['eventName', 'competitionName', 'eventSportKey']),
    ...mapState(['currentEvent']),
    ...mapConstsState(['sportsKeysMap']),
    redashLink(): string {
      return `${env.redashUri}/dashboards/80-fd-dashboards---event-players?p_event_id=${this.eventId}`;
    },
    sportName(): string | undefined {
      return this.sportsKeysMap[this.eventSportKey];
    },

    eventEditor() {
      if (this.editMode) {
        return (
          <EventEditor
            event={this.currentEvent}
            onCancelled={() => this.editMode = false}
            onSaved={() => {
              // Close dialog an refresh the current event
              this.editMode = false;
              this.refreshCurrentEvent(this.eventId);
            }} />
        );
      }
    },

    eventRegistry() {
      if (this.currentEvent && this.editRegistry) {
        return (
          <MatchingEditor
            event={this.currentEvent}
            onClose={() => {
              this.editRegistry = false;
              this.refreshCurrentEvent(this.eventId);
            }}
          />
        );
      }
    },

    providersKeys(): VNode {
      const event = this.currentEvent as Event;

      const keysByProviders = event?.providersData?.reduce((acc, pd) => {
        let keys = acc[pd.provider] || [];
        keys = [...keys, ...pd.keys];
        acc[pd.provider] = keys;
        return acc;
      }, {} as Record<string, string[]>);

      return (
        <span class='providers'>
          {Object.entries(keysByProviders).map(([provider, keys]) => {
            return (
              <span><span class='font-weight-bold'>{provider}:</span> {keys.join(', ')}&nbsp;</span>
            );
          })}
        </span>
      );
    },

    disabled(): boolean {
      return this.currentEvent?.status === EVENT_DISABLED_STATE;
    },

    providerPriceControl() {
      if (this.priceControlMode) {
        return (
          <ProviderPriceControl
            eventId={this.currentEvent.id}
            onCancelled={() => this.priceControlMode = false}
            onSaved={() => {
              // Close dialog an refresh the current event
              this.priceControlMode = false;
              this.refreshCurrentEvent(this.eventId);
              this.showSuccessMessage('Configuration has been updated');
            }} />
        );
      }
    },
  },

  mounted() {
    this.loadSports();
    this.loadCurrentEvent(this.eventId);
  },

  watch: {
    eventId() {
      this.loadCurrentEvent(this.eventId);
    },
  },

  render() {
    return (
      <VListItem class='event-headline' two-line>
        {this.successSnackbar}
        {this.failureSnackbar}
        {(this.currentEvent) && (
          <VListItemContent>
            <VListItemTitle class='headline'>

              <VTooltip
                right
                scopedSlots={{
                  activator: ({ on, attrs }: any) => (
                    <span on={on}>
                      <VBtn icon href={this.redashLink} target='_blank'>
                        <VIcon>
                          people
                        </VIcon>
                      </VBtn>
                    </span>

                  ),
                }}
              >
                <span>Open Redash Event Players Analysis</span>
              </VTooltip>
              {this.eventName || 'unknown event'}
              <span class='ml-4'>
                {(this.showRepublishEventBetsTrigger) &&
                  (<RepublishEventBetsTrigger eventId={this.eventId} />)
                }
              </span>
              <a class='event-link icon' href={this.eventPageUri(this.eventId.toString())} target='_blank'>
                <VIcon>launch</VIcon>
              </a>
            </VListItemTitle>
            <VListItemSubtitle>
              <RouterLink
                class='text-decoration-none'
                to={{
                  name: 'sport',
                  params: { sportKey: this.eventSportKey },
                }}
              >{this.sportName || 'unknown sport'}
              </RouterLink> |&nbsp;
              <RouterLink
                class='text-decoration-none'
                to={{
                  name: 'competition',
                  params: { competitionId: this.currentEvent?.competition?.id },
                }}
              >{this.competitionName || 'unknown competition'}
              </RouterLink> |
              Starts at: {unixToDateTimeUTCString(this.currentEvent?.startsAt)} |
              Status: {this.currentEvent?.status}
              {hasRoles(['sports:operator', 'data:operator']) &&
                <span>
                  &nbsp;|
                  <VBtn text small color='primary' onClick={() => this.editMode = true}>Edit</VBtn>
                  |&nbsp;
                  <VBtn text small color='primary' onClick={() => this.priceControlMode = true}>Offering Control</VBtn>
                </span>
              }
              {this.eventEditor}
              {this.providerPriceControl}
            </VListItemSubtitle>
            <VListItemSubtitle>
              {this.providersKeys}
              {hasRoles(['sports:operator', 'data:operator']) &&
                <span>
                  &nbsp;|
                  <VBtn text small color='primary' onClick={() => this.editRegistry = !this.editRegistry}>Edit</VBtn>
                </span>
              }
              {this.eventRegistry}
            </VListItemSubtitle>
          </VListItemContent>
        )}
      </VListItem>
    );
  },
});

