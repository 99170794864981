export const daysElapsed = (startDate: Date, endDate: Date) => {
  const oneDay = 1000 * 60 * 60 * 24;

  // A day in UTC always lasts 24 hours (unlike in other time formats)
  const start = Date.UTC(
    endDate.getFullYear(),
    endDate.getMonth(),
    endDate.getDate(),
  );
  const end = Date.UTC(
    startDate.getFullYear(),
    startDate.getMonth(),
    startDate.getDate(),
  );
  const days = Math.floor((end - start) / oneDay);
  // so it's safe to divide by 24 hours
  return days;
};
