export const betSettingsPresetKeys = [
  'sharpPiggyback',
  'sharp',
  'normal',
  'square',
  'squareX2',
  'squareX3',
];

export interface BetSettingsProps {
  accountType: string;
  stakeFactor: string;
}

export interface BetPresetProps {
  [key: string]: {
    name: string;
    settings: BetSettingsProps;
  };
}
export const betPresets: BetPresetProps = {
  sharpPiggyback: {
    name: 'Sharp Piggyback',
    settings: {
      accountType: 'SHARP',
      stakeFactor: '0.25',
    },
  },
  sharp: {
    name: 'Sharp',
    settings: {
      accountType: 'SHARP',
      stakeFactor: '0.5',
    },
  },
  normal: {
    name: 'Normal',
    settings: {
      accountType: 'NORMAL',
      stakeFactor: '0.5',
    },
  },
  square: {
    name: 'Square',
    settings: {
      accountType: 'NORMAL',
      stakeFactor: '1.0',
    },
  },
  squareX2: {
    name: 'Square x2',
    settings: {
      accountType: 'NORMAL',
      stakeFactor: '2.0',
    },
  },
  squareX3: {
    name: 'Square x3',
    settings: {
      accountType: 'NORMAL',
      stakeFactor: '3.0',
    },
  },
};
