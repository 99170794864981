import Vue from 'vue';
import { VBtn, VDataTable, VIcon, VContainer, VRow, VCol } from 'vuetify/lib';
import { SportsBonus } from '../SportsBonusControl/SportsBonusControl';
import { round, formatCurrency, fixWidth } from '@/filters';
import { unixToDateTimeUTCString } from '@/utils/date';

import { PlaythroughDialog } from './PlaythroughDialog';


function formatTime(time: string): string {
  return unixToDateTimeUTCString(Date.parse(time) / 1000);
}

const editableStates = ['ACTIVE', 'PENDING_DEPOSIT_TURNOVER', 'AVAILABLE', 'AWARDED'];

export const SportsBonusTable = Vue.extend({
  props: {
    isLoading: Boolean,
    playerUUID: String,
    sportsBonuses: Array as () => SportsBonus[],
    canOperate: Boolean,
  },
  computed: {
    headers() {
      return [
        {
          text: 'Type',
          value: 'type',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Progress',
          value: 'progress',
        },
        {
          text: 'Expires at',
          value: 'offerExpiresAt',
        },
        {
          text: 'Awarded at',
          value: 'awardedAt',
        },
        {
          text: '',
          value: 'actions',
        },
        {
          text: '',
          value: 'data-table-expand',
        },
      ];
    },
  },
  methods: {
    onEdit(bonus: SportsBonus) {
      this.$emit('edit', bonus);
    },

    details({ item, headers }: { item: SportsBonus, headers: any }) {
      return (
        <td colspan={headers.length}>
          <VContainer>
            <VRow>
              <VCol>
                <h3>Version {item.version}</h3>
                <div>{item.uuid}</div>
                <br></br>
                <div>Rollover requirment: {item.rolloverRequirement}x</div>
                <div>Minimum bet price: {fixWidth(item.minimumBetPrice, 2)}</div>
                <div>Payout currency: {item.payoutCurrency}</div>
                <br></br>
                <h3>Rollover</h3>
                <div>Playthrough: {formatCurrency(item.playthrough || 0, 2, { name: 'EUR' })}</div>
                <div>Pending playthrough: {formatCurrency(item.pendingPlaythrough || 0, 2, { name: 'EUR' })}</div>
                <div>Turnover: {formatCurrency(item.turnover || 0, 2, { name: 'EUR' })}</div>
                <PlaythroughDialog sportsBonus={item}></PlaythroughDialog>
                {item.type === 'DEPOSIT' ?
                  <div>
                    <br></br>
                    <h3>Deposit</h3>
                    <div>Award minimum: {
                      item.version >= 3 ?
                        formatCurrency(item.depositMinimum, 2, { name: 'EUR' }) :
                        `${item.awardMinimum} ${item.currency}`}
                    </div>
                    <div>Award maximum: {
                      item.version >= 3 ?
                        formatCurrency(item.depositMaximum, 2, { name: 'EUR' }) :
                        `${item.awardMaximum} ${item.currency}`}
                    </div>
                    <div>Award percentage: {item.awardPercentage}%</div>
                    <div>Amount: {
                      item.version >= 3 ?
                        formatCurrency(item.depositAmount, 2, { name: 'EUR' }) :
                        `${item.depositAmount} ${item.currency}`}
                    </div>
                    <div>Turnover: {
                      item.version >= 3 ?
                        formatCurrency(item.depositTurnover, 2, { name: 'EUR' }) :
                        `${item.depositTurnover} ${item.currency}`}
                    </div>
                    <div>Pending turnover: {
                      item.version >= 3 ?
                        formatCurrency(item.pendingDepositTurnover, 2, { name: 'EUR' }) :
                        `${item.pendingDepositTurnover} ${item.currency}`}
                    </div>
                  </div> :
                  null}
                {item.affiliateCurrency ?
                  <div>
                    <br></br>
                    <h3>Affiliate charge</h3>
                    <div>Scheme: {item.affiliateCommission}% of {item.affiliateScheme}</div>
                    <div>Charged: {item.affiliateDebitedAmount} {item.affiliateCurrency}
                      <div>Exchange rate: {item.affiliateExchangeRate}@{item.affiliateCurrency}EUR</div>
                    </div>
                  </div> :
                  null}
              </VCol>
              <VCol>
                <h3>Timeline</h3>
                {item.expiredAt ?
                  <div>{formatTime(item.expiredAt)} - expired</div>
                  : null}
                {item.exhaustedAt ?
                  <div>{formatTime(item.exhaustedAt)} - exhausted</div>
                  : null}
                {item.cancelledAt ?
                  <div>{formatTime(item.cancelledAt!)} - cancelled by {item.cancelledBy}</div>
                  : null}
                {item.rolledOverAt ?
                  <div>
                    {formatTime(item.rolledOverAt)}
                    {` - rolled over - paid out ${item.payoutAmount} ${item.payoutCurrency}`}
                  </div>
                  : null}
                {item.activatedAt ?
                  <div>{formatTime(item.activatedAt)} - activated</div>
                  : null}
                {item.acceptedAt ?
                  <div>
                    {formatTime(item.acceptedAt)} - accepted with {item.activationExchangeRate}@{item.currency}EUR
                   </div>
                  : null}
                {item.awardedAt ?
                  <div>{formatTime(item.awardedAt)} - awarded by {item.awardedBy}</div>
                  : null}
                <br></br>
                <h3>Description</h3>
                {item.description || '-'}
              </VCol>
            </VRow>
          </VContainer>
        </td>
      );
    },

    progress({ item }: { item: SportsBonus }) {
      if (item.amount == null || !item.playthrough || !item.activationExchangeRate) {
        return null;
      }
      const requiredPlaythrough = item.amount * item.activationExchangeRate * item.rolloverRequirement;
      const value = round(item.playthrough / requiredPlaythrough * 100, 2);
      return `${value}%`;
    },

    actions({ item }: { item: SportsBonus }) {
      if (!this.canOperate || !editableStates.includes(item.state)) {
        return null;
      }

      return (
        <div>
          <VBtn
            small
            icon
            depressed
            onClick={() => this.onEdit(item)}
          >
            <VIcon>edit</VIcon>
          </VBtn>
        </div>
      );
    },

    expiresAt({ item }: { item: SportsBonus }) {
      return formatTime(item.rolloverExpiresAt || item.offerExpiresAt);
    },

    awardedAt({ item }: { item: SportsBonus }) {
      return formatTime(item.awardedAt);
    },
  },
  render() {
    return (
      <VDataTable
        hideDefaultFooter
        headers={this.headers}
        items={this.sportsBonuses}
        itemKey='uuid'
        disableSort={true}
        loadingText='Loading... Please wait'
        loading={this.isLoading}
        class='pa-2'
        showExpand
        singleExpand={true}
        scopedSlots={{
          'item.progress': this.progress,
          'item.actions': this.actions,
          'item.offerExpiresAt': this.expiresAt,
          'item.awardedAt': this.awardedAt,
          'expanded-item': this.details,
        }}
      />
    );
  },
});
