import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { gql } from 'apollo-boost';
import { ProviderStatus } from '@/types/sports';

interface SportsProvidersStatus {
  providers: [ProviderStatus];
}

const getters: GetterTree<SportsProvidersStatus, any> = {
  providers(state: SportsProvidersStatus): [ProviderStatus] {
    return state.providers;
  },
};

const mutations: MutationTree<SportsProvidersStatus> = {
  UPDATE_PROVIDERS_STATE(state, providers) {
    providers.sort(
      (item1: ProviderStatus, item2: ProviderStatus) => {
        item1.providerName.localeCompare(item2.providerName);
      },
    );
    state.providers = providers;
  },
};

async function getProvidersStatus(client: any) {
  return await client.query({
    query: gql`
        query SportsProvidersStatus {
          sportsProvidersStatus {
            providerName
            isDisabled
          }
        }
        `,
  });
}
async function postProviderStatus(client: any, providerName: string, isDisabled: boolean) {
  return await client.mutate({
    mutation: gql`
        mutation UpdateSportsProvidersStatus($input: SportsProviderStatusInput!) {
          updateSportsProvidersStatus(input: $input) {
                providerName
                isDisabled
            }
          }
        `,
    variables: {
      input: {
        providerName,
        isDisabled,
      },
    },
    fetchPolicy: 'no-cache',
  });
}

const actions: ActionTree<SportsProvidersStatus, any> = {
  async fetchProvidersStatus(
    { commit, rootState },
  ) {
    try {
      const response = await getProvidersStatus(rootState.apolloClient());
      commit('UPDATE_PROVIDERS_STATE', response.data.sportsProvidersStatus);
    } catch (err) {
      throw err;
    }
  },
  async updateProviderStatus(
    { commit, rootState },
    { providerName, isDisabled },
  ) {
    try {
      const response = await postProviderStatus(rootState.apolloClient(), providerName, isDisabled);
      commit('UPDATE_PROVIDERS_STATE', response.data.updateSportsProvidersStatus);
    } catch (err) {
      throw err;
    }
  },
};


const SportsProvidersStatusStore = {
  namespaced: true,
  state: {
    providers: [] as ProviderStatus[],
  } as SportsProvidersStatus,
  getters,
  actions,
  mutations,
};

export default SportsProvidersStatusStore;
