import { gql } from 'apollo-boost';
import Vue from 'vue';
import { DocumentNode } from 'graphql';
import { VContainer, VTabs, VTab, VTabItem, VTabsSlider } from 'vuetify/lib';

import { hasRoles } from '@/utils/auth';
import EntityMediaWidget from '@/components/sports/EntityMediaWidget/EntityMediaWidget.tsx';
import { Competitor } from '@/types/sports';

export default Vue.extend({
  name: 'competitor',
  computed: {
    competitorId(): string {
      return this.$route.params.competitorId.toString();
    },
  },
  data() {
    return {
      tab: 'media',
      entity: {} as Competitor,
    };
  },
  apollo: {
    entity: {
      query():
        DocumentNode {
        return gql`
          query($id: String!) {
            entity: competitor(id: $id) {
              name
            }
          }
        `;
      },
      variables(): any {
        return { id: this.competitorId };
      },
    },
  },
  methods: {
    tabs() {
      return [
        {
          requiresRoles: ['sports:operator', 'dataentry:operator'],
          key: 'media',
          name: 'Media',
          content: (
            <EntityMediaWidget entityType='competitor' entityKey={this.competitorId} />
          ),
        },
      ];
    },
  },
  render() {
    return (
      <VContainer fluid>
        <h1>{this.entity.name}</h1>
        <VTabs v-model={this.tab}>
          <VTabsSlider></VTabsSlider>
          {this.tabs().filter((tab) => hasRoles(tab.requiresRoles)).map((tab: any) =>
            <VTab to={'#' + tab.key} href={'#' + tab.key}>{tab.name}</VTab>,
          )}
          {this.tabs().filter((tab) => hasRoles(tab.requiresRoles)).map((tab: any) =>
            <VTabItem value={tab.key}>{tab.content}</VTabItem>,
          )}
        </VTabs>
      </VContainer>
    );
  },
});

