import Vue from 'vue';
import { hasRoles } from '@/utils/auth';
import { VContainer, VRow } from 'vuetify/lib';
import PlayerBetActivity from '@/components/PlayerBetActivity/PlayerBetActivity';
import TotalTransaction from '@/components/payments/TotalTransaction';

export const PlayerGeneralTab = Vue.extend({
  props: {
    playerUUID: String,
    playerNickname: String,
  },
  render() {
    return (
      <VContainer fluid>
        <VRow>
          <TotalTransaction playerUUID={this.playerUUID} />
        </VRow>
        {
          hasRoles(['finance:operator', 'finance:user']) && (
            <VRow>
              <PlayerBetActivity playerUUID={this.playerUUID} playerNickname={this.playerNickname} />
            </VRow>
          )
        }
      </VContainer >
    );
  },
});
