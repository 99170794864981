// https://github.com/bitgaming/message-bus-types/blob/master/const/v1/types.pb.go
/*
  mapped using ruby:

  Currency_value
    .group_by{ |k, v| v }
    .map{ |k, v| {
      'text' => v.map{ |x| x[0] }
                 .sort_by{ |x| x }
                 .join(' / ')
                 .gsub('REWARD_CASH', 'REWARDS EUR')
                 .gsub('_', ' '),
      'value' => v[0][0].to_s
    }}
    .sort_by{ |x| x['value'] }
*/

export const cryptoCurrencies = [
  { text: 'ADA', value: 'ADA' },
  { text: 'ALG / ALGO', value: 'ALG' },
  { text: 'ATM / ATOM', value: 'ATM' },
  { text: 'AU1 / PAXG', value: 'AU1' },
  { text: 'AU2 / XAUT', value: 'AU2' },
  { text: 'AVA / AVAX', value: 'AVA' },
  { text: 'AVE / AAVE', value: 'AVE' },
  { text: 'BCH', value: 'BCH' },
  { text: 'BNB', value: 'BNB' },
  { text: 'BRT / BRETT', value: 'BRT'},
  { text: 'BSV', value: 'BSV' },
  { text: 'BTC', value: 'BTC' },
  { text: 'CAK / CAKE', value: 'CAK' },
  { text: 'CRO', value: 'CRO' },
  { text: 'DOG / DOGE', value: 'DOG' },
  { text: 'DGS / DOGS', value: 'DGS' },
  { text: 'DOT', value: 'DOT' },
  { text: 'DSH / DASH', value: 'DSH' },
  { text: 'DEG / DEGEN', value: 'DEG' },
  { text: 'ENA', value: 'ENA' },
  { text: 'EOS', value: 'EOS' },
  { text: 'ETC', value: 'ETC' },
  { text: 'ETH', value: 'ETH' },
  { text: 'FTM', value: 'FTM' },
  { text: 'FTT', value: 'FTT' },
  { text: 'HMS / HMSTR', value: 'HMS' },
  { text: 'LI1 / LINK', value: 'LI1' },
  { text: 'LTC', value: 'LTC' },
  { text: 'MAT / MATIC', value: 'MAT' },
  { text: 'PNK / PONKE', value: 'PNK' },
  { text: 'SDE / SUSDE', value: 'SDE' },
  { text: 'SHI / SHIB', value: 'SHI' },
  { text: 'SOL', value: 'SOL' },
  { text: 'STE / LIDO_STAKED_ETHER', value: 'STE' },
  { text: 'STM / LIDO_STAKED_MATIC', value: 'STM' },
  { text: 'STS / LIDO_STAKED_SOLANA', value: 'STS' },
  { text: 'SUS / SUSHI', value: 'SUS' },
  { text: 'TON', value: 'TON'},
  { text: 'TOS / TOSHI', value: 'TOS'},
  { text: 'TRX / Tron', value: 'TRX' },
  { text: 'UNI', value: 'UNI' },
  { text: 'USE / USDE', value: 'USE' },
  { text: 'US2 / USDT', value: 'US2' },
  { text: 'US3 / USDC', value: 'US3' },
  { text: 'TUSD / US4', value: 'US4' },
  { text: 'PAX / US5 / USDP', value: 'US5' },
  { text: 'GUSD / US6', value: 'US6' },
  { text: 'DAI / US7', value: 'US7' },
  { text: 'US8 / UST', value: 'US8' },
  { text: 'BUSD / US9', value: 'US9' },
  { text: 'XLM', value: 'XLM' },
  { text: 'XRP', value: 'XRP' },
  { text: 'XTZ', value: 'XTZ' },
  { text: 'ZEC / ZCash', value: 'ZEC' },
];

export const fiatCurrencies = [
  { text: 'AED', value: 'AED' },
  { text: 'ARS', value: 'ARS' },
  { text: 'AUD', value: 'AUD' },
  { text: 'BRL', value: 'BRL' },
  { text: 'CAD', value: 'CAD' },
  { text: 'CHF', value: 'CHF' },
  { text: 'CNY', value: 'CNY' },
  { text: 'CZK', value: 'CZK' },
  { text: 'DKK', value: 'DKK' },
  { text: 'EUR', value: 'EUR' },
  { text: 'GBP', value: 'GBP' },
  { text: 'HKD', value: 'HKD' },
  { text: 'HUF', value: 'HUF' },
  { text: 'IDR', value: 'IDR' },
  { text: 'ILS', value: 'ILS' },
  { text: 'INR', value: 'INR' },
  { text: 'JPY', value: 'JPY' },
  { text: 'KRW', value: 'KRW' },
  { text: 'MXN', value: 'MXN' },
  { text: 'MYR', value: 'MYR' },
  { text: 'NOK', value: 'NOK' },
  { text: 'NZD', value: 'NZD' },
  { text: 'PHP', value: 'PHP' },
  { text: 'PTS', value: 'PTS' },
  { text: 'RSD', value: 'RSD' },
  { text: 'RUB', value: 'RUB' },
  { text: 'SAR', value: 'SAR' },
  { text: 'SEK', value: 'SEK' },
  { text: 'SGD', value: 'SGD' },
  { text: 'THB', value: 'THB' },
  { text: 'TRY', value: 'TRY' },
  { text: 'USD', value: 'USD' },
  { text: 'VND', value: 'VND' },
];

export const loyaltyCurrencies = [
  { text: 'RC1 / REWARDS EUR', value: 'RC1' },
];

export const sportsCurrencies = [
  { text: 'BE1 / BONUS EUR', value: 'BE1' },
  { text: 'PE1 / PLAY EUR', value: 'PE1' },
];

function compare(a: { text: string; }, b: { text: string; }) {
  if (a.text < b.text) {
    return -1;
  }
  if (a.text > b.text) {
    return 1;
  }
  return 0;
}

export const currencies = [...cryptoCurrencies, ...fiatCurrencies, ...loyaltyCurrencies, ...sportsCurrencies];
currencies.sort(compare);

const aliases: Record<string, string> = Object.fromEntries(currencies.map((c) => [c.value, c.text]));

export function currencyAlias(value: string): string {
  return aliases[value] || value;
}

export const blockchains = [
  {text: 'Algorand', value: 'algorand'},
  {text: 'Avalanche', value: 'avalanche'},
  {text: 'BNB Smart Chain', value: 'binance_smart_chain'},
  {text: 'Bitcoin', value: 'bitcoin'},
  {text: 'Bitcoin Cash', value: 'bitcoin_cash'},
  {text: 'Bitcoin SV', value: 'bitcoin_sv'},
  {text: 'Cardano', value: 'cardano'},
  {text: 'Dash', value: 'dash'},
  {text: 'Doge', value: 'doge'},
  {text: 'Eosio', value: 'eosio'},
  {text: 'Ethereum', value: 'ethereum'},
  {text: 'Ethereum Classic', value: 'ethereum_classic'},
  {text: 'Litecoin', value: 'litecoin'},
  {text: 'Polygon', value: 'polygon'},
  {text: 'Ripple', value: 'ripple'},
  {text: 'Solana', value: 'solana'},
  {text: 'Stellar', value: 'stellar'},
  {text: 'Terra Classic', value: 'terra'},
  {text: 'Tezos', value: 'tezos'},
  {text: 'Ton', value: 'ton'},
  {text: 'Tron', value: 'tron'},
  {text: 'Zcash', value: 'zcash'},
];
