import { Stream, SOURCES } from '.';

export function querify(stream: Stream): string {
  // get all attrs for now
  const attrGroups = SOURCES[stream.sourceKey].attrGroups;
  const attrs: string[] = attrGroups.reduce((groupAttrs: any, attrGroup: any) => {
    return [...groupAttrs, ...attrGroup.attrs.map((attr: any) => attr.key)];
  }, []);

  let queryAttrsStr = '';
  for (const attr of attrs) {
    const splitted = attr.split('.');
    let attrString = splitted.pop();

    while (splitted.length > 0) {
      const nextStr = splitted.pop();
      attrString = `${nextStr} { ${attrString} }`;
    }

    queryAttrsStr += ` ${attrString}`;
  }


  return `subscription($filters: String) { ${stream.sourceKey} (filters: $filters) { ${queryAttrsStr} } }`;
}
