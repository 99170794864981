import Vue from 'vue';
import { mapGetters } from 'vuex';
import {
  VBtn,
  VSnackbar,
  VRow,
  VCol,
  VAutocomplete,
  VCard,
  VTextField,
  VCardTitle,
  VCardText,
  VCardActions,
  VDivider,
  VDialog,
  VDatePicker,
  VMenu,
  VLabel,
  VTextarea,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { failureSnackbarConfig } from './helpers';
import _, { range, partial } from 'lodash';
import { getAllMarketsBySport, SportType } from '@cloudbet/market-helper';
import { Locale, getMarketDefinitions, MarketDefinition } from '@cloudbet/market-helper/sports-core';
import { Event } from '@/types/sports';
import { dateTimeISOStringToUnix, unixToDateTimeUTCString } from '@/utils/date';
import { ExchangeMarket } from './types';
import { marketName, selectionName } from '../markets-names';

interface MarketDefinitionWithKey extends MarketDefinition {
  MarketKey: string;
}
const marketDefinitions = getMarketDefinitions(Locale.en);

const paramRegex = /{{[a-zA-Z0-9_-]+}}/;

const dynamicOrKeyOutcome = (name: string, value: string) => {
  return name.includes('dynamic') && (value === '' || paramRegex.test(value));
};

const fieldIsRequired = (value: string) => {
  return !!value || 'Field is required';
};

const numberToFixWidthString = (maxLen: number, val: number) => {
  return val.toString().padStart(maxLen, '0');
};

interface Outcome {
  id?: number;
  value: string;
}

export default Vue.extend({
  props: {
    event: Object as () => Event,
    marketPreset: Object as () => ExchangeMarket,
  },

  data() {
    return {
      dialog: true,
      failureMessage: null as JSX.Element | null,
      failureSnackbar: false,
      marketDef: null as MarketDefinitionWithKey | null,
      variablesValues: {} as Record<string, string>,
      outcomesValues: {} as Record<string, Outcome[]>,
      dateMenu: false,
      effectiveToDate: '',
      effectiveToHour: '00',
      effectiveToMinute: '00',
      description: '',
    };
  },

  methods: {

    showFailure(message: string | string[]) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;
    },

    addOutcome(name: string) {
      let outcomes = [{ value: '' }] as Outcome[];
      if (name === 'dynamic.player') {
        outcomes = (this.event.players || []).map((p) => ({ value: p.key }));
      }

      const newOutcomes = _.uniqBy(this.outcomesValues[name].concat(...outcomes), (o) => o.value);
      this.outcomesValues = { ...this.outcomesValues, [name]: newOutcomes };
      const lastIdx = newOutcomes.length - 1;

      this.$nextTick(() => {
        (this.$refs[`${name}${lastIdx}`] as HTMLElement)?.focus();
      });
    },

    initVariables() {
      if (this.marketDef) {
        this.variablesValues = this.marketDef.Variables.reduce((acc, name) => {
          return { ...acc, [name]: '' };
        }, {});

        this.outcomesValues = Object.entries(this.marketDef.Outcomes || {}).reduce((acc, [name, value]) => {
          if (dynamicOrKeyOutcome(name, value)) {
            return { ...acc, [name]: [] };
          } else {
            return { ...acc, [name]: [{ value: name }] };
          }
        }, {} as Record<string, Outcome[]>);
      } else {
        this.variablesValues = {};
        this.outcomesValues = {};
      }
    },

    async createSelections() {
      try {
        const selections = [] as Array<{ selectionName: string }>;

        Object.entries(this.outcomesValues).forEach(([name, values]) => {
          if (name === 'dynamic') {
            // key outcome
            values.filter((v) => !v.id).map((v) => v.value).forEach((v) => {
              if (v) {
                selections.push({ selectionName: v });
              }
            });
          } else if (name.includes('dynamic')) {
            const shortName = name.replace('dynamic.', '');
            values.filter((v) => !v.id).map((v) => v.value).forEach((v) => {
              if (v) {
                selections.push({ selectionName: `${shortName}=${encodeURIComponent(v)}` });
              }
            });
          } else {
            if (!values[0].id) {
              selections.push({ selectionName: values[0].value });
            }
          }
        });

        // When a new market is created require outcomes to be defined
        if (this.newMarketMode && selections.length === 0) {
          throw new Error('Missing outcomes.');
        }

        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($input: SportsExchangeUpdateMarketInput) {
            sportsExchangeUpdateMarket(input: $input) {
              description
              effectiveTo
              eventId
              marketKey
              marketParams
              selections {
                id
                selectionName
                state
              }
              state
              translations
            }
          }`,
          variables: {
            input: {
              description: this.description,
              eventId: this.event.id,
              marketKey: this.marketDef?.MarketKey,
              marketParams: this.marketParams,
              effectiveTo: dateTimeISOStringToUnix(
                `${this.effectiveToDate}T${this.effectiveToHour}:${this.effectiveToMinute}Z`),
              selections,
            },
          },
        });

        this.onMarketUpdated({
          ...result.data.sportsExchangeUpdateMarket,
          translations: Object.fromEntries(result.data.sportsExchangeUpdateMarket.translations),
        });
      } catch (e) {
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body?.errors) {
          this.showFailure(e.graphQLErrors[0].extensions.response.body.errors);
        } else {
          this.showFailure(e.message);
          throw e;
        }
      }
    },

    onMarketUpdated(market: ExchangeMarket) {
      if (!this.marketPreset) {
        this.marketDef = null;
      }

      this.$emit('saved', market);
    },

    onCancel() {
      this.marketDef = null;
      this.$emit('cancelled');
    },
  },

  computed: {
    ...mapGetters({ token: 'auth/idToken' }),

    sportsbookMarkets(): MarketDefinitionWithKey[] {
      if (this.event) {
        return getAllMarketsBySport(this.event.sportKey as SportType)
          // Freetext markets are excluded for outrights.
          // Outright markets are excluded for events.
          .filter((marketKey) =>
            (this.event.type === 'EVENT' && !marketKey.includes('outright')) ||
            (this.event.type === 'OUTRIGHT' && marketKey.includes('outright')))
          .map((marketKey) => {
            const marketDef = marketDefinitions[marketKey];
            const name = marketDef.Name ? marketDef.Name : this.event.name;
            return {
              ...marketDef,
              Name: name,
              MarketKey: marketKey,
            };
          })
          .filter((marketDef) => !marketDef.Deprecated);
      } else {
        return [];
      }
    },

    marketParams(): string {
      return Object.entries(this.variablesValues)
        .filter(([name, value]) => {
          return !!value;
        })
        .map(([name, val]) => {
          name = name.replace('optional.', '');
          return `${name}=${encodeURIComponent(val)}`;
        })
        .join('&');
    },

    variables() {
      if (!this.marketDef) {
        return;
      }

      return (
        <VRow>
          <VCol>
            {this.marketDef.Variables.map((variable) => {
              return (
                <VTextField
                  label={variable}
                  dense
                  outlined
                  required
                  disabled={!!this.marketPreset}
                  rules={variable.includes('optional.') ? [] : [fieldIsRequired]}
                  vModel={this.variablesValues[variable]} />
              );
            })}
          </VCol>
        </VRow>
      );
    },

    isValid(): boolean {
      return Boolean(this.marketDef?.MarketKey) &&
        Object.entries(this.variablesValues)
          .filter(([name, v]) => !name.includes('optional.'))
          .every(([n, value]) => !!value) &&
        this.effectiveToDate !== '' &&
        this.effectiveToHour !== '' &&
        this.effectiveToMinute !== '';
    },

    outcomes() {
      if (!this.marketDef) {
        return;
      }

      return (
        <VRow>
          <VCol>
            {Object.entries(this.outcomesValues).map(([name, values]) => {
              const dynamicOrKey = name.includes('dynamic');
              if (dynamicOrKey) {
                const outcomes = (values).map((outcome, index) => {
                  return (
                    <VTextField
                      dense
                      outlined
                      disabled={!!this.outcomesValues[name][index].id}
                      vModel={this.outcomesValues[name][index].value}
                      ref={`${name}${index}`} />
                  );
                });
                const shortName = name === 'dynamic' ? 'outcome' : name.replace('dynamic.', '');

                return (
                  <div>
                    {outcomes}
                    <VBtn small onClick={() => this.addOutcome(name)}>Add {shortName}</VBtn>
                  </div>
                );
              } else {
                const outcomeName = selectionName({
                  marketKey: this.marketDef?.MarketKey || '',
                  marketParams: this.marketParams,
                  selectionName: values[0].value,
                }, this.event);

                return <VTextField dense outlined vModel={outcomeName} disabled />;
              }
            })}
          </VCol>
        </VRow >
      );
    },

    effectiveToDateTimePicker() {
      return (
        <VRow>
          <VCol cols='4'>
            <VMenu
              vModel={this.dateMenu}
              closeOnContentClick={true}
              nudgeRight={40}
              transition='scale-transition'
              offsetY
              minWidth='auto'
              scopedSlots={{
                activator: ({ on }: any) => {
                  return (
                    <VTextField
                      vModel={this.effectiveToDate}
                      label='Date'
                      readonly
                      dense
                      outlined
                      on={on}
                      rules={[fieldIsRequired]}
                    />
                  );
                },
              }}>
              <VDatePicker
                noTitle
                scrollable
                vModel={this.effectiveToDate}
                allowedDates={(val: string) => {
                  const today = new Date();
                  today.setUTCHours(0, 0, 0, 0);
                  const date = new Date(val);
                  return date >= today;
                }} />
            </VMenu>
          </VCol>
          <VCol cols='3'>
            <VAutocomplete dense outlined
              items={range(0, 24).map(partial(numberToFixWidthString, 2))}
              label='Hour'
              vModel={this.effectiveToHour}
              rules={[fieldIsRequired]} />
          </VCol>
          <VCol cols='3'>
            <VAutocomplete dense outlined
              items={range(0, 60).map(partial(numberToFixWidthString, 2))}
              label='Minute'
              vModel={this.effectiveToMinute}
              rules={[fieldIsRequired]} />
          </VCol>
          <VCol cols='2' class='pt-5'>
            <VLabel>UTC</VLabel>
          </VCol>
        </VRow>
      );
    },

    newMarketMode(): boolean {
      return !this.marketPreset;
    },
  },

  mounted() {
    if (this.marketPreset) {
      const marketDef = this.sportsbookMarkets.find((m) => m.MarketKey === this.marketPreset.marketKey);
      if (marketDef) {
        this.marketDef = marketDef;
        this.initVariables();
        this.variablesValues = Object.fromEntries(new URLSearchParams(this.marketPreset.marketParams));

        this.outcomesValues = this.marketPreset.selections.reduce((acc, s) => {
          const outcome = s.selectionName.split('=');
          if (Object.keys(this.marketDef?.Outcomes || {})[0] === 'dynamic') {
            // key outcomes
            const name = 'dynamic';
            const keyOutcomes = acc[name] || [];
            const translatedOutcome = this.marketPreset.translations[outcome[0]] || outcome[0];
            return { ...acc, [name]: [...keyOutcomes, { id: s.id, value: translatedOutcome }] };
          } else if (outcome.length === 1) {
            // schema-defined outcomes
            return { ...acc, [outcome[0]]: [{ id: s.id, value: outcome[0] }] };
          } else if (outcome.length === 2) {
            // dynamic outcomes
            const name = `dynamic.${outcome[0]}`;
            const dynamicOutcomes = acc[name] || [];
            return { ...acc, [name]: [...dynamicOutcomes, { id: s.id, value: decodeURIComponent(outcome[1]) }] };
          }
          return acc;
        }, {} as Record<string, Outcome[]>);
      }
      if (this.marketPreset.effectiveTo) {
        const dateString = unixToDateTimeUTCString(this.marketPreset.effectiveTo);
        this.effectiveToDate = dateString.substr(0, 10);
        this.effectiveToHour = dateString.substr(11, 2);
        this.effectiveToMinute = dateString.substr(14, 2);
      }
      this.description = this.marketPreset.description || '';
    }
  },

  render() {
    return (

      <VDialog vModel={this.dialog} persistent max-width='500' class='market-creator'>
        <VSnackbar {...failureSnackbarConfig(<div>{this.failureMessage}</div>)} vModel={this.failureSnackbar} />
        <VCard>
          <VCardTitle>
            Market creator
            </VCardTitle>
          <VCardText>
            <VAutocomplete
              disabled={!!this.marketPreset}
              dense
              items={this.sportsbookMarkets}
              item-text='Name'
              item-value='MarketKey'
              vModel={this.marketDef}
              return-object={true}
              label='Markets'
              onInput={this.initVariables}
              outlined />

            {this.marketDef &&
              <div>
                <div class='text-subtitle-1 mb-6'>
                  <strong>
                    {marketName({
                      marketKey: this.marketDef?.MarketKey || '',
                      marketParams: this.marketParams,
                    }, this.event)}
                  </strong>
                </div>
                <VDivider />
                <div class='text-subtitle-1'>Effective to</div>
                {this.effectiveToDateTimePicker}
                {(this.marketDef?.MarketKey?.includes('freetext') || this.marketDef?.MarketKey?.includes('outright')) &&
                  <div>
                    <div class='text-subtitle-1'>Description</div>
                    <VRow>
                      <VCol>
                        <VTextarea
                          label='This description will be shown to a player'
                          dense
                          outlined
                          rows={3}
                          vModel={this.description} />
                      </VCol>
                    </VRow>
                  </div>
                }
                {this.marketDef.Variables.length !== 0 && <div>
                  <VDivider />
                  <div class='text-subtitle-1'>Variables</div>
                  {this.variables}
                </div>}

                <VDivider />
                <div class='text-subtitle-1'>Outcomes</div>
                {this.outcomes}
              </div>
            }
          </VCardText>
          <VCardActions>
            <VBtn color='success' disabled={!this.isValid} onClick={this.createSelections}>Save</VBtn>
            <VBtn onClick={this.onCancel}>Cancel</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
