/** @description existing roles for gateway, temporary list in frontend */
export const userRoles: Record<string, Record<string, string>> = {
  sports: {
    operator: 'sports:operator',
    user: 'sports:user',
  },
  casino: {
    operator: 'casino:operator',
    user: 'casino:user',
  },
  dataentry: {
    operator: 'dataentry:operator',
    user: 'dataentry:user',
  },
  finance: {
    operator: 'finance:operator',
    user: 'finance:user',
  },
  ops: {
    operator: 'ops:operator',
    user: 'ops:user',
  },
  cs: {
    operator: 'cs:operator',
    user: 'cs:user',
  },
  pii: {
    operator: 'pii:operator',
    user: 'pii:user',
  },
  payments: {
    operator: 'payments:operator',
    user: 'payments:user',
  },
  localization: {
    operator: 'localization:operator',
  },
};
