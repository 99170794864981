import { ApolloClient, ApolloLink, InMemoryCache } from 'apollo-boost';
import { from, split } from 'apollo-link';
import { createHttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { getMainDefinition } from 'apollo-utilities';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import tokenLink from './apollo_links/token-link';
import { env } from './env';
import store from './store';


Vue.use(VueApollo);

/*************************
 * Helpers *
 *************************/
const getBearer = (): string => {
  const token = store.getters['auth/idToken'];
  return token ? `Bearer ${token}` : '';
};

const authLink = new ApolloLink((operation, forward) => {
  const previousContext = operation.getContext();
  operation.setContext({
    ...previousContext,
    headers: {
      ...previousContext.headers,
      authorization: getBearer(),
    },
  });
  return forward(operation);
});


/*************************
 * Gateway *
 *************************/
const gatewayHTTPLink = createUploadLink({
  uri: env.gateway.httpUri,
});

/** @description Websocket client for subscription */
export const gatewayWSClient = new SubscriptionClient(`${env.gateway.wsUri}`, {
  lazy: true,
  reconnect: true,
});
// Keep Here to show how it can be used
//   wsClient.onConnected(() => {
//     console.log('connected f client f onConnected');
//   });
//   wsClient.onReconnected(() => {
//     console.log('connected f client f reconnected');
//   });
//   wsClient.onReconnecting(() => {
//     console.log('connected f client  f reconnecting');
//   });
//   wsClient.onDisconnected(() => {
//     console.log('connected f client  f onDisconnected');
//   });
const subscriptionMiddleware = {
  applyMiddleware(options: any, next: any) {
    options.authorization = getBearer();
    next();
  },
};

gatewayWSClient.use([subscriptionMiddleware]);

const gatewayWsLink = new WebSocketLink(gatewayWSClient);
const gatewayLink = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  gatewayWsLink,
  // @ts-ignore
  from([tokenLink, authLink, gatewayHTTPLink]),
);

const gatewayClient = new ApolloClient({
  link: gatewayLink,
  cache: new InMemoryCache(),
});


/*************************
 * Sports Loyalty Service *
 *************************/
const sportsLoyaltyHTTPLink = from([tokenLink, authLink, createHttpLink({
  uri: env.sportsLoyaltyService.uri,
})]);
const sportsLoyaltyClient = new ApolloClient({
  link: sportsLoyaltyHTTPLink,
  cache: new InMemoryCache(),
});

/*************************
 * Provider *
 *************************/
export const apolloProvider = new VueApollo({
  defaultClient: gatewayClient,
  clients: {
    gateway: gatewayClient,
    sportsLoyaltyService: sportsLoyaltyClient,
  },
});

/***********************
 * End of Apollo Setup *
 ***********************/
