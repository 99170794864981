<template>
  <v-app-bar
    v-if="isAuthenticated"
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
    dark
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
    <v-toolbar-title style="width: 100px" class="ml-0 pl-4">
      <span class="hidden-sm-and-down">Zero</span>
    </v-toolbar-title>
    <span class="hidden-sm-and-down">Press "/" or click the icon to search</span
    >
    <v-btn icon @click="triggerSearch()">
      <v-icon>search</v-icon>
    </v-btn>
    <v-spacer />
    <ConnectionStatus />
    <v-btn icon>
      <v-icon>notifications</v-icon>
    </v-btn>
    <v-btn to="/profile" icon large>
      <v-avatar size="32px" item>
        <v-img :src="avatar" />
      </v-avatar>
    </v-btn>
  </v-app-bar>
  <v-app-bar
    v-else
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
  >
    <v-app-bar-nav-icon @click.stop="toggleDrawer()" />
    <v-toolbar-title style="width: 300px" class="ml-0 pl-4">
      <span class="hidden-sm-and-down">Zero</span>
    </v-toolbar-title>
  </v-app-bar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import ConnectionStatus from './ConnectionStatus';

export default {
  props: {
    toggleDrawer: Function,
  },
  components: {
    ConnectionStatus,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      avatar: 'auth/avatar',
      isViewByRoleActive: 'viewByRole/isViewByRoleActive',
    }),
  },

  methods: {
    triggerSearch() {
      const event = new KeyboardEvent('keydown', {
        key: '/',
        bubbles: true,
      });
      window.dispatchEvent(event);
    },
  },
};
</script>
