import { unixToDateTimeISOString } from '@/utils/date';
import { gql } from 'apollo-boost';

const nullIfEqual = <T>(value: T, original: T) => {
  return value === original ? null : value;
};

const safeUnixToISO = (timestamp?: string | null) => {
  return timestamp ? unixToDateTimeISOString(timestamp) : null;
};


export {
  nullIfEqual,
  safeUnixToISO,
};
