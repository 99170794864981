import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDatePicker,
  VDialog,
  VMenu,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

import {
  AwardCasinoDepositBonusInput,
  AwardCasinoFreebonusBonusInput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { awardableCasinoCurrencies, buildCurrenciesSelectList } from '@/utils/casino/currency';
import { BonusType, BonusVersion, BonusUsageStrategy } from '@/types/casino/bonus';

const BONUS_USAGE_STRATEGIES = [
  { text: 'Real then Bonus', value: BonusUsageStrategy.REAL_THEN_BONUS },
  { text: 'Bonus then Real', value: BonusUsageStrategy.BONUS_THEN_REAL },
];

interface AwardCasinoFreeBonusBonusForm {
  bonusUsageStrategy: string;
  currency: string;
  amount: string;
  rolloverRequirement: string;
  rolloverDaysDuration: string;
  expiresAt: string;
  casinoCampaignCode: string;

  expiresAtDatePickerMenuOpen: boolean;
}

interface AwardCasinoFreebonusBonusValidationErrors {
  playerUUID?: string[];
  bonusUsageStrategy?: string[];
  currency?: string[];
  amount?: string[];
  rolloverRequirement?: string[];
  rolloverDaysDuration?: string[];
  expiresAt?: string[];
  casinoCampaignCode?: string[];
}

interface AwardCasinoDepositBonusForm {
  lockDeposit: boolean;
  bonusUsageStrategy: string;
  currency: string;
  awardPercentage: string;
  awardMinimum: string;
  awardMaximum: string;
  rolloverRequirement: string;
  rolloverDaysDuration: string;
  expiresAt: string;
  casinoCampaignCode: string;

  expiresAtDatePickerMenuOpen: boolean;
}

interface AwardCasinoDepositBonusValidationErrors {
  playerUUID?: string[];
  lockDeposit?: string[];
  bonusUsageStrategy?: string[];
  currency?: string[];
  awardPercentage?: string[];
  awardMinimum?: string[];
  awardMaximum?: string[];
  rolloverRequirement?: string[];
  rolloverDaysDuration?: string[];
  expiresAt?: string[];
  casinoCampaignCode?: string[];
}

interface VueData {
  bonusType: BonusType;
  bonusVersion: BonusVersion;
  awardCasinoFreeBonusBonusForm: AwardCasinoFreeBonusBonusForm;
  awardCasinoFreebonusBonusValidationErrors?: AwardCasinoFreebonusBonusValidationErrors;
  awardCasinoDepositBonusForm: AwardCasinoDepositBonusForm;
  awardCasinoDepositBonusValidationErrors?: AwardCasinoDepositBonusValidationErrors;
  loading: boolean;
}

const defaultAwardCasinoFreebonusBonusValidationErrors = undefined;
const defaultAwardCasinoDepositBonusValidationErrors = undefined;

export default Vue.extend({
  props: {
    playerUUID: String,
    open: Boolean,
  },
  data(): VueData {
    return {
      bonusType: BonusType.CASINO_FREEBONUS,
      bonusVersion: BonusVersion.VERSION_2,
      awardCasinoFreeBonusBonusForm: {
        bonusUsageStrategy: BonusUsageStrategy.BONUS_THEN_REAL,
        currency: '',
        amount: '',
        rolloverRequirement: '',
        rolloverDaysDuration: '',
        expiresAt: '',
        casinoCampaignCode: '',

        expiresAtDatePickerMenuOpen: false,
      },
      awardCasinoFreebonusBonusValidationErrors: defaultAwardCasinoFreebonusBonusValidationErrors,
      awardCasinoDepositBonusForm: {
        lockDeposit: false,
        bonusUsageStrategy: BonusUsageStrategy.BONUS_THEN_REAL,
        currency: '',
        awardPercentage: '',
        awardMinimum: '',
        awardMaximum: '',
        rolloverRequirement: '',
        rolloverDaysDuration: '',
        expiresAt: '',
        casinoCampaignCode: '',

        expiresAtDatePickerMenuOpen: false,
      },
      awardCasinoDepositBonusValidationErrors: defaultAwardCasinoDepositBonusValidationErrors,
      loading: false,
    };
  },
  watch: {
    bonusType: {
      immediate: true,
      handler() {
        this.onBonusTypeOrVersionChange();
      },
    },
    bonusVersion: {
      immediate: true,
      handler() {
        this.onBonusTypeOrVersionChange();
      },
    },
  },
  methods: {
    clearForms() {
      this.bonusVersion = BonusVersion.VERSION_2;

      // Deposit bonus form
      this.awardCasinoDepositBonusForm.lockDeposit = false;
      this.awardCasinoDepositBonusForm.bonusUsageStrategy = BonusUsageStrategy.BONUS_THEN_REAL;
      this.awardCasinoDepositBonusForm.currency = '';
      this.awardCasinoDepositBonusForm.awardPercentage = '';
      this.awardCasinoDepositBonusForm.awardMinimum = '';
      this.awardCasinoDepositBonusForm.awardMaximum = '';
      this.awardCasinoDepositBonusForm.rolloverRequirement = '';
      this.awardCasinoDepositBonusForm.rolloverDaysDuration = '';
      this.awardCasinoDepositBonusForm.expiresAt = '';
      this.awardCasinoDepositBonusForm.casinoCampaignCode = '';
      this.awardCasinoDepositBonusValidationErrors = defaultAwardCasinoDepositBonusValidationErrors;

      // Freebonus bonus form
      this.awardCasinoFreeBonusBonusForm.bonusUsageStrategy = BonusUsageStrategy.BONUS_THEN_REAL;
      this.awardCasinoFreeBonusBonusForm.currency = '';
      this.awardCasinoFreeBonusBonusForm.amount = '';
      this.awardCasinoFreeBonusBonusForm.rolloverRequirement = '';
      this.awardCasinoFreeBonusBonusForm.rolloverDaysDuration = '';
      this.awardCasinoFreeBonusBonusForm.expiresAt = '';
      this.awardCasinoFreeBonusBonusForm.casinoCampaignCode = '';
      this.awardCasinoFreebonusBonusValidationErrors = defaultAwardCasinoFreebonusBonusValidationErrors;
    },
    async onAwardBonusSubmit() {
      if (this.bonusType === BonusType.CASINO_DEPOSIT) {
        try {
          this.loading = true;

          const input: AwardCasinoDepositBonusInput = {
            playerUUID: this.playerUUID,
            lockDeposit: this.awardCasinoDepositBonusForm.lockDeposit,
            bonusUsageStrategy: this.awardCasinoDepositBonusForm.bonusUsageStrategy,
            currency: this.awardCasinoDepositBonusForm.currency,
            awardPercentage: this.awardCasinoDepositBonusForm.awardPercentage,
            awardMinimum: this.awardCasinoDepositBonusForm.awardMinimum,
            awardMaximum: this.awardCasinoDepositBonusForm.awardMaximum,
            rolloverRequirement: this.awardCasinoDepositBonusForm.rolloverRequirement,
            rolloverDaysDuration: this.awardCasinoDepositBonusForm.rolloverDaysDuration,
            expiresAt: this.awardCasinoDepositBonusForm.expiresAt,
            casinoCampaignCode: this.awardCasinoDepositBonusForm.casinoCampaignCode,
          };

          await this.$apollo.mutate({
            mutation: gql`
              mutation ($input: AwardCasinoDepositBonusInput!) {
                awardCasinoDepositBonus(input: $input) {
                  bonusID
                }
              }
            `,
            variables: {
              input,
            },
          });

          this.clearForms();
          this.$emit('submit', null);
        } catch (e) {
          const err = e as ApolloError;

          const errResponse = err.graphQLErrors[0]?.extensions?.response;
          const errURL = errResponse?.url;
          const errStatus = errResponse?.status;
          const errBody = errResponse?.body;

          if (errBody != null) {
            const validationErrors = errBody.validation_errors;

            if (validationErrors != null) {
              this.awardCasinoDepositBonusValidationErrors = {
                playerUUID: validationErrors.player_uuid,
                lockDeposit: validationErrors.lock_deposit,
                bonusUsageStrategy: validationErrors.bonus_usage_strategy,
                currency: validationErrors.currency,
                awardPercentage: validationErrors.award_percentage,
                awardMinimum: validationErrors.award_minimum,
                awardMaximum: validationErrors.award_maximum,
                rolloverRequirement: validationErrors.rollover_requirement,
                rolloverDaysDuration: validationErrors.rollover_days_duration,
                expiresAt: validationErrors.expires_at,
                casinoCampaignCode: validationErrors.casino_campaign_code,
              };
            } else {
              const apiError: ApiError = {
                url: errURL,
                status: errStatus,
                body: errBody,
              };

              this.$emit('submit', apiError);
            }
          }
        } finally {
          this.loading = false;
        }
      } else if (this.bonusType === BonusType.CASINO_FREEBONUS) {
        try {
          this.loading = true;

          const input: AwardCasinoFreebonusBonusInput = {
            playerUUID: this.playerUUID,
            bonusUsageStrategy: this.awardCasinoFreeBonusBonusForm.bonusUsageStrategy,
            currency: this.awardCasinoFreeBonusBonusForm.currency,
            amount: this.awardCasinoFreeBonusBonusForm.amount,
            rolloverRequirement: this.awardCasinoFreeBonusBonusForm.rolloverRequirement,
            rolloverDaysDuration: this.awardCasinoFreeBonusBonusForm.rolloverDaysDuration,
            expiresAt: this.awardCasinoFreeBonusBonusForm.expiresAt,
            casinoCampaignCode: this.awardCasinoFreeBonusBonusForm.casinoCampaignCode,
          };

          await this.$apollo.mutate({
            mutation: gql`
              mutation ($input: AwardCasinoFreebonusBonusInput!) {
                awardCasinoFreebonusBonus(input: $input) {
                  bonusID
                }
              }
            `,
            variables: {
              input,
            },
          });

          this.clearForms();
          this.$emit('submit', null);
        } catch (e) {
          const err = e as ApolloError;

          const errResponse = err.graphQLErrors[0]?.extensions?.response;
          const errURL = errResponse?.url;
          const errStatus = errResponse?.status;
          const errBody = errResponse?.body;

          if (errBody != null) {
            const validationErrors = errBody.validation_errors;

            if (validationErrors != null) {
              this.awardCasinoFreebonusBonusValidationErrors = {
                playerUUID: validationErrors.player_uuid,
                bonusUsageStrategy: validationErrors.bonus_usage_strategy,
                currency: validationErrors.currency,
                amount: validationErrors.amount,
                rolloverRequirement: validationErrors.rollover_requirement,
                rolloverDaysDuration: validationErrors.rollover_days_duration,
                expiresAt: validationErrors.expires_at,
                casinoCampaignCode: validationErrors.casino_campaign_code,
              };
            } else {
              const apiError: ApiError = {
                url: errURL,
                status: errStatus,
                body: errBody,
              };

              this.$emit('submit', apiError);
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    onBonusTypeOrVersionChange() {
      if (this.bonusVersion === BonusVersion.VERSION_1) {
        if (this.bonusType === BonusType.CASINO_DEPOSIT) {
          this.awardCasinoDepositBonusForm.lockDeposit = false;
          this.awardCasinoDepositBonusForm.bonusUsageStrategy = BonusUsageStrategy.REAL_THEN_BONUS;
        } else if (this.bonusType === BonusType.CASINO_FREEBONUS) {
          this.awardCasinoFreeBonusBonusForm.bonusUsageStrategy = BonusUsageStrategy.REAL_THEN_BONUS;
        }
      } else if (this.bonusVersion === BonusVersion.VERSION_2) {
        if (this.bonusType === BonusType.CASINO_DEPOSIT) {
          this.awardCasinoDepositBonusForm.lockDeposit = true;
          this.awardCasinoDepositBonusForm.bonusUsageStrategy = BonusUsageStrategy.BONUS_THEN_REAL;
        } else if (this.bonusType === BonusType.CASINO_FREEBONUS) {
          this.awardCasinoFreeBonusBonusForm.bonusUsageStrategy = BonusUsageStrategy.BONUS_THEN_REAL;
        }
      }
    },
  },
  render() {
    const bonusUsageStrategyDescription = () => {
      if (this.bonusVersion === BonusVersion.VERSION_1) {
        return (
          <p>
            <b>Real then Bonus</b>: When placing a bet, player's real balance is used first before the bonus balance.
            Bets with mixed funds (real & bonus together) will be approved.
          </p>
        );
      } else if (this.bonusVersion === BonusVersion.VERSION_2) {
        return (
          <p>
            <b>Bonus then Real</b>: When placing a bet, player's bonus balance is used first before the real balance.
            Bets with mixed funds (real & bonus together) will be rejected.
          </p>
        );
      }
    };

    const casinoCurrenciesSelectList = () => {
      return buildCurrenciesSelectList(awardableCasinoCurrencies());
    };

    const freeBonusBonusForm = () => {
      return (
        <div>
          <div style={{ marginBottom: '3rem' }}>
            <VSelect
              v-model={this.awardCasinoFreeBonusBonusForm.bonusUsageStrategy}
              label='Bonus Usage Strategy'
              items={BONUS_USAGE_STRATEGIES}
              error={this.awardCasinoFreebonusBonusValidationErrors?.bonusUsageStrategy != null}
              errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.bonusUsageStrategy || []}
              errorCount={5}
              readonly
              disabled
            />
            {bonusUsageStrategyDescription()}
          </div>

          <VSelect
            v-model={this.awardCasinoFreeBonusBonusForm.currency}
            label='Currency'
            items={casinoCurrenciesSelectList()}
            error={this.awardCasinoFreebonusBonusValidationErrors?.currency != null}
            errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.currency || []}
            errorCount={5}
            messages={[
              'Currency in which the player will be awarded the bonus.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoFreeBonusBonusForm.amount}
            label='Amount'
            error={this.awardCasinoFreebonusBonusValidationErrors?.amount != null}
            errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.amount || []}
            errorCount={5}
            messages={[
              'Value of bonus (in the Currency) to be awarded.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoFreeBonusBonusForm.rolloverRequirement}
            label='Rollover Requirement'
            error={this.awardCasinoFreebonusBonusValidationErrors?.rolloverRequirement != null}
            errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.rolloverRequirement || []}
            errorCount={5}
            messages={[
              'Number of times that the player needs to play through his initial bonus balance before he can withdraw any winnings without being penalized.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoFreeBonusBonusForm.rolloverDaysDuration}
            label='Rollover Days Duration'
            error={this.awardCasinoFreebonusBonusValidationErrors?.rolloverDaysDuration != null}
            errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.rolloverDaysDuration || []}
            errorCount={5}
            messages={[
              'Number of days for the player to play through his bonus once bonus has been activated.',
            ]}
          />
          <VMenu
            v-model={this.awardCasinoFreeBonusBonusForm.expiresAtDatePickerMenuOpen}
            closeOnContentClick={true}
            nudgeRight={150}
            transition='scale-transition'
            minWidth='auto'
            scopedSlots={{
              activator: ({ on, attrs }: any) => {
                return (
                  <VTextField
                    v-model={this.awardCasinoFreeBonusBonusForm.expiresAt}
                    label='Offer Expires At'
                    readonly
                    error={this.awardCasinoFreebonusBonusValidationErrors?.expiresAt != null}
                    errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.expiresAt || []}
                    errorCount={5}
                    messages={[
                      'Date by which the player has to activate the bonus offer.',
                    ]}
                    bind={attrs}
                    on={on}
                  />
                );
              },
            }}
          >
            <VDatePicker v-model={this.awardCasinoFreeBonusBonusForm.expiresAt} />
          </VMenu>
          <VTextField
            v-model={this.awardCasinoFreeBonusBonusForm.casinoCampaignCode}
            label='Campaign Code'
            error={this.awardCasinoFreebonusBonusValidationErrors?.casinoCampaignCode != null}
            errorMessages={this.awardCasinoFreebonusBonusValidationErrors?.casinoCampaignCode || []}
            errorCount={5}
            messages={[
              'Used for identifying which marketing campaign this bonus to be awarded references.',
            ]}
          />
        </div>
      );
    };

    const depositBonusForm = () => {
      return (
        <div>
          <div style={{ marginBottom: '3rem' }}>
            <VCheckbox
              v-model={this.awardCasinoDepositBonusForm.lockDeposit}
              label='Lock Deposit?'
              error={this.awardCasinoDepositBonusValidationErrors?.lockDeposit != null}
              errorMessages={this.awardCasinoDepositBonusValidationErrors?.lockDeposit || []}
              errorCount={5}
              readonly
              disabled
            />
            <p>
              <b>Lock Deposit:</b> When player activates a locked deposit bonus via depositing,
              {' '}the deposit will be fully or partially locked and would only be released when
              {' '}the locked deposit bonus is successfully rolled over or cancelled / expired / exhausted.
            </p>
          </div>
          <div style={{ marginBottom: '3rem' }}>
            <VSelect
              v-model={this.awardCasinoDepositBonusForm.bonusUsageStrategy}
              label='Bonus Usage Strategy'
              items={BONUS_USAGE_STRATEGIES}
              error={this.awardCasinoDepositBonusValidationErrors?.bonusUsageStrategy != null}
              errorMessages={this.awardCasinoDepositBonusValidationErrors?.bonusUsageStrategy || []}
              errorCount={5}
              readonly
              disabled
            />
            {bonusUsageStrategyDescription()}
          </div>

          <VSelect
            v-model={this.awardCasinoDepositBonusForm.currency}
            label='Currency'
            items={casinoCurrenciesSelectList()}
            error={this.awardCasinoDepositBonusValidationErrors?.currency != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.currency || []}
            errorCount={5}
            messages={[
              'Currency in which the player will be awarded the bonus.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.awardPercentage}
            label='Award Percentage'
            suffix='%'
            error={this.awardCasinoDepositBonusValidationErrors?.awardPercentage != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.awardPercentage || []}
            errorCount={5}
            messages={[
              'The percentage of the next qualifying deposit to be awarded as a bonus. E.g. A 50% award percentage for a 1 ETH deposit will award 0.5 ETH as a bonus.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.awardMinimum}
            label='Award Minimum'
            error={this.awardCasinoDepositBonusValidationErrors?.awardMinimum != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.awardMinimum || []}
            errorCount={5}
            messages={[
              'The minimum value (in the Currency) a player has to deposit in order to qualify for the bonus.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.awardMaximum}
            label='Award Maximum'
            error={this.awardCasinoDepositBonusValidationErrors?.awardMaximum != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.awardMaximum || []}
            errorCount={5}
            messages={[
              'The maximum bonus value (in the Currency) a player can be awarded. E.g. If a player has a 100% award percentage with an award maximum of 0.1 BTC, then deposits 0.2 BTC, the player will only receive 0.1 BTC in bonus.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.rolloverRequirement}
            label='Rollover Requirement'
            error={this.awardCasinoDepositBonusValidationErrors?.rolloverRequirement != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.rolloverRequirement || []}
            errorCount={5}
            messages={[
              'Number of times that the player needs to play through his initial bonus balance before he can withdraw any winnings without being penalized.',
            ]}
          />
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.rolloverDaysDuration}
            label='Rollover Days Duration'
            error={this.awardCasinoDepositBonusValidationErrors?.rolloverDaysDuration != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.rolloverDaysDuration || []}
            errorCount={5}
            messages={[
              'Number of days for the player to play through his bonus once bonus has been activated.',
            ]}
          />
          <VMenu
            v-model={this.awardCasinoDepositBonusForm.expiresAtDatePickerMenuOpen}
            closeOnContentClick={true}
            nudgeRight={150}
            transition='scale-transition'
            minWidth='auto'
            scopedSlots={{
              activator: ({ on, attrs }: any) => {
                return (
                  <VTextField
                    v-model={this.awardCasinoDepositBonusForm.expiresAt}
                    label='Offer Expires At'
                    readonly
                    error={this.awardCasinoDepositBonusValidationErrors?.expiresAt != null}
                    errorMessages={this.awardCasinoDepositBonusValidationErrors?.expiresAt || []}
                    errorCount={5}
                    messages={[
                      'Date by which the player has to activate the bonus offer.',
                    ]}
                    bind={attrs}
                    on={on}
                  />
                );
              },
            }}
          >
            <VDatePicker v-model={this.awardCasinoDepositBonusForm.expiresAt} />
          </VMenu>
          <VTextField
            v-model={this.awardCasinoDepositBonusForm.casinoCampaignCode}
            label='Campaign Code'
            error={this.awardCasinoDepositBonusValidationErrors?.casinoCampaignCode != null}
            errorMessages={this.awardCasinoDepositBonusValidationErrors?.casinoCampaignCode || []}
            errorCount={5}
            messages={[
              'Used for identifying which marketing campaign this bonus to be awarded references.',
            ]}
          />
        </div>
      );
    };

    const awardBonusForm = () => {
      if (this.bonusType === BonusType.CASINO_FREEBONUS) {
        return freeBonusBonusForm();
      } else if (this.bonusType === BonusType.CASINO_DEPOSIT) {
        return depositBonusForm();
      }
    };

    return (
      <VDialog
        v-model={this.open}
        on={{
          'click:outside': () => { this.$emit('open', false); },
        }}
        maxWidth='700'
      >
        <VCard>
          <VCardTitle>
            Award Casino Bonus Form
          </VCardTitle>
          <VCardText>
            <VContainer>
              <VRow>
                <VCol>
                  <VRadioGroup v-model={this.bonusType} row label='Bonus Type'>
                    <VRadio label='Non-Deposit' value={BonusType.CASINO_FREEBONUS} />
                    <VRadio label='Deposit' value={BonusType.CASINO_DEPOSIT} />
                  </VRadioGroup>
                  {/* <VRadioGroup
                    v-model={this.bonusVersion}
                    row
                    label='Bonus Version'
                  >
                    <VRadio label='V1' value={BonusVersion.VERSION_1} />
                    <VRadio label='V2' value={BonusVersion.VERSION_2} />
                  </VRadioGroup> */}
                  {awardBonusForm()}
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn onClick={() => { this.$emit('open', false); }}>Close</VBtn>
            <VBtn
              color='primary'
              onClick={() => { this.onAwardBonusSubmit(); }}
              loading={this.loading}
              disabled={this.loading}
            >
              Award Bonus
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
