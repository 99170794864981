import { formatCurrency } from '@/filters';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VContainer, VDataTable } from 'vuetify/lib';
import { RouterLink } from '@/router';

interface Player {
  uuid: string;
  nickname: string;
  exposure: number;
  eventsCount: number;
}

export default Vue.extend({
  data() {
    return {
      dataLoaded: false,
      players: [] as Player[],
      subscription: null as any,
      headers: [
        { text: 'Player', value: 'player', sortable: false },
        { text: '#events', value: 'eventsCount', sortable: false },
        { text: 'Exposure', value: 'exposure', sortable: false, align: 'end' },
      ],
      dialogs: {} as any,
    };
  },
  methods: {
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription {
              playersUpdated {
                uuid
                nickname
                exposure
                eventsCount
              }
            }
          `,
        })
        .subscribe({
          next: ({ data }) => {
            this.players = data.playersUpdated;
            this.dataLoaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  render() {
    return (
      <VContainer class='pa-0'>
        <VDataTable
          headers={this.headers}
          items={this.players}
          disablePagination={true}
          hideDefaultFooter={true}
          loading={!this.dataLoaded}
          scopedSlots={{
            'item.player': ({ item }: { item: Player }) => (
              <RouterLink class='text-decoration-none'
                          to={{ name: 'player', params: { playerUUID: item.uuid } }}>
                { item.nickname || item.uuid }
              </RouterLink>
            ),
            'item.exposure': ({ item }: { item: Player }) => (
              <span>{formatCurrency(item.exposure, 0)}</span>
            ),
          }}>
        </VDataTable>
      </VContainer>
    );
  },
});
