import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAutocomplete,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDialog,
  VDivider,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSpacer,
  VTextField,
} from 'vuetify/lib';
import { env } from '@/env';

import {
  AwardCasinoEvolutionVoucherInput,
} from '@/types/casino/generated/casino.gql';
import { awardableCasinoCurrencies, buildCurrenciesSelectList } from '@/utils/casino/currency';
import { ApiError } from '@/types/casino/apiError';
import { BonusType } from '@/types/casino/bonus';
import { humanizeShortStudioKey } from '@/types/casino/studio';

import algoliasearch from 'algoliasearch/lite';

const algoliaSearchClient = algoliasearch(env.casino.algoliaClient.appId, env.casino.algoliaClient.apiKey);
const algoliaSearchIndex = algoliaSearchClient.initIndex(env.casino.algoliaClient.indexNameCasinoGame);

interface AwardFreebetsForm {
  gameUUID: string;
  gameToLaunchUUID: string;
  casinoCurrency: string;
  distributorCurrency: string;
  casinoCampaignCode: string;
  evolutionCampaignId: string;
  evolutionCurrency: string;
  evolutionExpirationDuration: string;
  evolutionInitialBalance: string;
  evolutionMaxWinnings: string;
  rewardBonusType: string;
  rolloverRequirement: string;
  rolloverDaysDuration: string;
  depositBonusAwardPercentage?: string;
  lockDepositBonus?: boolean;
}

interface AwardFreebetsValidationErrors {
  playerUUID?: string[];
  gameUUID?: string[];
  gameToLaunchUUID?: string[];
  casinoCurrency?: string[];
  distributorCurrency?: string[];
  casinoCampaignCode?: string[];
  evolutionCampaignId?: string[];
  evolutionCurrency?: string[];
  evolutionExpirationDuration?: string[];
  evolutionInitialBalance?: string[];
  evolutionMaxWinnings?: string[];
  rewardBonusType?: string[];
  rolloverRequirement?: string[];
  rolloverDaysDuration?: string[];
  depositBonusAwardPercentage?: string[];
  lockDepositBonus?: string[];
}

interface AlgoliaCasinoGameObject {
  id: string;
  name: {
    en: string;
  };
  providerKey: string;
  images: {
    desktop: string;
    mobile: string;
  };
}

interface GameSelectRecord {
  text: string;
  value: AlgoliaCasinoGameObject;
}

interface GameSelection {
  algolia: {
    searchKey: string;
    searchResults: AlgoliaCasinoGameObject[];
  };
  selectedGame: GameSelectRecord | null;
}

interface VueData {
  form: AwardFreebetsForm;
  validationErrors?: AwardFreebetsValidationErrors;
  gameSelection: GameSelection;
  gameToLaunchSelection: GameSelection;
  loading: boolean;
}

const styles = {
  inputField: 'mb-3',
};

const AwardCasinoEvolutionVoucherDialog = Vue.extend({
  props: {
    playerUUID: String,
    open: Boolean,
  },
  data(): VueData {
    return {
      form: {
        gameUUID: '',
        gameToLaunchUUID: '',
        casinoCurrency: '',
        distributorCurrency: 'EUR',
        casinoCampaignCode: '',
        evolutionCampaignId: '',
        evolutionCurrency: 'EUR',
        evolutionExpirationDuration: '',
        evolutionInitialBalance: '',
        evolutionMaxWinnings: '',
        rewardBonusType: BonusType.CASINO_FREEBONUS,
        rolloverRequirement: '',
        rolloverDaysDuration: '',
        depositBonusAwardPercentage: '',
        lockDepositBonus: true,
      },
      validationErrors: undefined,
      gameSelection: {
        algolia: {
          searchKey: '',
          searchResults: [],
        },
        selectedGame: null,
      },
      gameToLaunchSelection: {
        algolia: {
          searchKey: '',
          searchResults: [],
        },
        selectedGame: null,
      },
      loading: false,
    };
  },
  methods: {
    clearForm() {
      this.form.gameUUID = '';
      this.form.gameToLaunchUUID = '';
      this.form.casinoCurrency = '';
      this.form.distributorCurrency = 'EUR';
      this.form.casinoCampaignCode = '';
      this.form.evolutionCampaignId = '';
      this.form.evolutionCurrency = 'EUR';
      this.form.evolutionExpirationDuration = '';
      this.form.evolutionInitialBalance = '';
      this.form.evolutionMaxWinnings = '';
      this.form.rewardBonusType = BonusType.CASINO_FREEBONUS;
      this.form.rolloverRequirement = '';
      this.form.rolloverDaysDuration = '';
      this.form.depositBonusAwardPercentage = '';
      this.form.lockDepositBonus = true;

      this.validationErrors = undefined;

      this.gameSelection.algolia.searchKey = '';
      this.gameSelection.selectedGame = null;

      this.gameToLaunchSelection.algolia.searchKey = '';
      this.gameToLaunchSelection.selectedGame = null;
    },
    async onAwardFreebetsFormSubmit() {
      try {
        this.loading = true;

        const evolutionExpirationDuration = `P${this.form.evolutionExpirationDuration}D`;

        const input: AwardCasinoEvolutionVoucherInput = {
          playerUUID: this.playerUUID,
          gameUUID: this.form.gameUUID,
          gameToLaunchUUID: this.form.gameToLaunchUUID,
          casinoCurrency: this.form.casinoCurrency,
          distributorCurrency: this.form.distributorCurrency,
          casinoCampaignCode: this.form.casinoCampaignCode,
          evolutionCampaignId: this.form.evolutionCampaignId,
          evolutionCurrency: this.form.evolutionCurrency,
          evolutionExpirationDuration,
          evolutionInitialBalance: this.form.evolutionInitialBalance,
          evolutionMaxWinnings: this.form.evolutionMaxWinnings,
          rewardBonusType: this.form.rewardBonusType,
          rolloverRequirement: this.form.rolloverRequirement,
          rolloverDaysDuration: this.form.rolloverDaysDuration,
          depositBonusAwardPercentage: this.form.depositBonusAwardPercentage,
          lockDepositBonus: this.form.lockDepositBonus,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: AwardCasinoEvolutionVoucherInput!) {
              awardCasinoEvolutionVoucher(input: $input) {
                ID
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.clearForm();
        this.$emit('submit', null);
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        const errURL = errResponse?.url;
        const errStatus = errResponse?.status;
        const errBody = errResponse?.body;

        if (errBody != null) {
          const validationErrors = errBody.validation_errors;

          if (validationErrors != null) {
            this.validationErrors = {
              playerUUID: validationErrors.player_uuid,
              gameUUID: validationErrors.game_uuid,
              gameToLaunchUUID: validationErrors.game_to_launch_uuid,
              casinoCurrency: validationErrors.casino_currency,
              distributorCurrency: validationErrors.distributor_currency,
              casinoCampaignCode: validationErrors.casino_campaign_code,
              evolutionCampaignId: validationErrors.evolution_campaign_id,
              evolutionCurrency: validationErrors.evolution_currency,
              evolutionExpirationDuration: validationErrors.evolution_expiration_duration,
              evolutionInitialBalance: validationErrors.evolution_initial_balance,
              evolutionMaxWinnings: validationErrors.evolution_max_winnings,
              rewardBonusType: validationErrors.reward_bonus_type,
              rolloverRequirement: validationErrors.rollover_requirement,
              rolloverDaysDuration: validationErrors.rollover_days_duration,
              depositBonusAwardPercentage: validationErrors.deposit_bonus_award_percentage,
              lockDepositBonus: validationErrors.lock_deposit_bonus,
            };
          } else {
            const apiError: ApiError = {
              url: errURL,
              status: errStatus,
              body: errBody,
            };

            this.$emit('submit', apiError);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  watch: {
    'gameSelection.algolia.searchKey'(newVal: string, oldVal: string) {
      if (newVal === '') {
        this.gameSelection.algolia.searchResults = [];
      } else {
        if (newVal !== oldVal) {
          algoliaSearchIndex.search(newVal, {}).then(({ hits }) => {
            this.gameSelection.algolia.searchResults = hits as any[];
          });
        }
      }
    },
    'gameSelection.selectedGame'(newVal: GameSelectRecord | null, oldVal: GameSelectRecord | null) {
      if (newVal == null) {
        this.form.gameUUID = '';
      } else {
        this.form.gameUUID = newVal.value.id;
      }
    },
    'gameToLaunchSelection.algolia.searchKey'(newVal: string, oldVal: string) {
      if (newVal === '') {
        this.gameToLaunchSelection.algolia.searchResults = [];
      } else {
        if (newVal !== oldVal) {
          algoliaSearchIndex.search(newVal, {}).then(({ hits }) => {
            this.gameToLaunchSelection.algolia.searchResults = hits as any[];
          });
        }
      }
    },
    'gameToLaunchSelection.selectedGame'(newVal: GameSelectRecord | null, oldVal: GameSelectRecord | null) {
      if (newVal == null) {
        this.form.gameToLaunchUUID = '';
      } else {
        this.form.gameToLaunchUUID = newVal.value.id;
      }
    },
  },
  render() {
    const casinoCurrenciesSelectList = () => {
      return buildCurrenciesSelectList(awardableCasinoCurrencies());
    };

    const gameSelectionList = () => {
      return this.gameSelection.algolia.searchResults.map((algoliaCasinoGameObject: AlgoliaCasinoGameObject) => {
        const record: GameSelectRecord =  {
          text: `${algoliaCasinoGameObject.name.en} (id: ${algoliaCasinoGameObject.id})`,
          value: algoliaCasinoGameObject,
        };

        return record;
      });
    };

    const gameToLaunchSelectionList = () => {
      return this.gameToLaunchSelection.algolia.searchResults.map(
        (algoliaCasinoGameObject: AlgoliaCasinoGameObject) => {
          const record: GameSelectRecord =  {
            text: `${algoliaCasinoGameObject.name.en} (id: ${algoliaCasinoGameObject.id})`,
            value: algoliaCasinoGameObject,
          };

          return record;
        },
      );
    };

    const awardFreebetsForm = () => {
      return (
        <div>
          <VAutocomplete
            v-model={this.gameSelection.selectedGame}
            return-object
            on={{
              'update:search-input': (value: string) => {
                this.gameSelection.algolia.searchKey = value;
              },
            }}
            items={gameSelectionList()}
            error={this.validationErrors?.gameUUID != null}
            errorMessages={this.validationErrors?.gameUUID || []}
            errorCount={5}
            label='Game'
            messages={[
              'Casino game to play the freebets. E.g. "Evolution Lightning Roulette"',
            ]}
            outlined
            single-line
            class={styles.inputField}
            scopedSlots={{
              item: ({ item, on }: { item: GameSelectRecord, on: object }) => {
                const gameImagePath = item.value.images.desktop;
                const gameId = item.value.id;
                const gameStudioName = humanizeShortStudioKey(item.value.providerKey);

                return (
                  <VListItem on={on}>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{item.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
              selection: () => {
                const gameImagePath = this.gameSelection.selectedGame?.value.images.desktop;
                const gameId = this.gameSelection.selectedGame?.value.id;
                const gameStudioName =
                  humanizeShortStudioKey(this.gameSelection.selectedGame?.value.providerKey as string);

                return (
                  <VListItem>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{this.gameSelection.selectedGame?.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
            }}
          />
          <VAutocomplete
            v-model={this.gameToLaunchSelection.selectedGame}
            return-object
            on={{
              'update:search-input': (value: string) => {
                this.gameToLaunchSelection.algolia.searchKey = value;
              },
            }}
            items={gameToLaunchSelectionList()}
            error={this.validationErrors?.gameToLaunchUUID != null}
            errorMessages={this.validationErrors?.gameToLaunchUUID || []}
            errorCount={5}
            label='Game To Launch'
            messages={[
              'Casino game to launch the freebets. E.g. the "Reward Games" version of the direct table game',
            ]}
            outlined
            single-line
            class={styles.inputField}
            scopedSlots={{
              item: ({ item, on }: { item: GameSelectRecord, on: object }) => {
                const gameImagePath = item.value.images.desktop;
                const gameId = item.value.id;
                const gameStudioName = humanizeShortStudioKey(item.value.providerKey);

                return (
                  <VListItem on={on}>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{item.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
              selection: () => {
                const gameImagePath = this.gameToLaunchSelection.selectedGame?.value.images.desktop;
                const gameId = this.gameToLaunchSelection.selectedGame?.value.id;
                const gameStudioName =
                  humanizeShortStudioKey(this.gameToLaunchSelection.selectedGame?.value.providerKey as string);

                return (
                  <VListItem>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{this.gameToLaunchSelection.selectedGame?.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
            }}
          />
          <VSelect
            v-model={this.form.casinoCurrency}
            label='Casino Currency'
            items={casinoCurrenciesSelectList()}
            error={this.validationErrors?.casinoCurrency != null}
            errorMessages={this.validationErrors?.casinoCurrency || []}
            errorCount={5}
            messages={[
              'Player currency in which the freebets will be awarded in. This is also the reward currency.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VSelect
            disabled
            v-model={this.form.distributorCurrency}
            label='Distributor Currency'
            items={['EUR']}
            error={this.validationErrors?.distributorCurrency != null}
            errorMessages={this.validationErrors?.distributorCurrency || []}
            errorCount={5}
            messages={[
              'Game currency in which the freebets will be played in. Currently we only support EUR.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.form.casinoCampaignCode}
            label='Casino Campaign Code'
            error={this.validationErrors?.casinoCampaignCode != null}
            errorMessages={this.validationErrors?.casinoCampaignCode || []}
            errorCount={5}
            messages={[
              'Used for identifying which marketing campaign this freebets to be awarded references.',
              'E.g. "Evolution-GameX-PromotionY-2022-06-21".',
            ]}
            outlined
            class={styles.inputField}
          />
          <div style={{ marginTop: '2rem' }}>
            <p>
              <b>Evolution Campaign ID</b> can be found in{' '}
              <a
                href='https://www.notion.so/chaos-theory/Evolution-Campaign-IDs-a0120708159d4e57b7e84440c2dadba1'
                target='_blank'
              >
                Notion
              </a>{' '}
              or{' '}
              <a href={env.casino.evolution.backoffice_url} target='_blank'>
                Evolution's Backoffice
              </a>.
            </p>
            <VTextField
              v-model={this.form.evolutionCampaignId}
              label='Evolution Campaign ID (UUID format)'
              error={this.validationErrors?.evolutionCampaignId != null}
              errorMessages={this.validationErrors?.evolutionCampaignId || []}
              errorCount={5}
              messages={[]}
              outlined
            />
          </div>
          <VTextField
            v-model={this.form.evolutionInitialBalance}
            label='Evolution Initial Balance'
            error={this.validationErrors?.evolutionInitialBalance != null}
            errorMessages={this.validationErrors?.evolutionInitialBalance || []}
            errorCount={5}
            messages={[
              'Value of freebets in Distributor Currency.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.form.evolutionMaxWinnings}
            label='Evolution Max Winnings'
            error={this.validationErrors?.evolutionMaxWinnings != null}
            errorMessages={this.validationErrors?.evolutionMaxWinnings || []}
            errorCount={5}
            messages={[
              'Max total winnings from freebets in Distributor Currency.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.form.evolutionExpirationDuration}
            label='Freebets Expiration Duration (Days)'
            error={this.validationErrors?.evolutionExpirationDuration != null}
            errorMessages={this.validationErrors?.evolutionExpirationDuration || []}
            errorCount={5}
            messages={[
              'Number of days for the player to play through his freebets before it expires.',
            ]}
            outlined
            class={styles.inputField}
          />

          <div class='my-5'>
            <VDivider class='my-5' />
            <h2>Reward Configurations</h2>

            <VRadioGroup
              v-model={this.form.rewardBonusType}
              row
              label='Reward Bonus Type'
              error={this.validationErrors?.rewardBonusType != null}
              errorMessages={this.validationErrors?.rewardBonusType || []}
              errorCount={5}
              messages={[
                'Type of bonus rewarded to player when player exhausts the freebets.',
              ]}
              class='pb-5'
            >
              <VRadio label='Non-deposit bonus' value={BonusType.CASINO_FREEBONUS} />
              <VRadio label='Deposit bonus' value={BonusType.CASINO_DEPOSIT} />
            </VRadioGroup>
          </div>

          <VTextField
            v-model={this.form.rolloverRequirement}
            label='Rollover Requirement'
            error={this.validationErrors?.rolloverRequirement != null}
            errorMessages={this.validationErrors?.rolloverRequirement || []}
            errorCount={5}
            messages={[
              'Number of times that the player needs to play through his initial freebets bonus balance before he can withdraw any winnings without being penalized.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.form.rolloverDaysDuration}
            label='Rollover Duration (Days)'
            error={this.validationErrors?.rolloverDaysDuration != null}
            errorMessages={this.validationErrors?.rolloverDaysDuration || []}
            errorCount={5}
            messages={[
              'Number of days for the player to play through his freebets bonus once it has been activated.',
            ]}
            outlined
            class={styles.inputField}
          />
          {(() => {
            if (this.form.rewardBonusType === BonusType.CASINO_DEPOSIT) {
              return (
                <div>
                  <VTextField
                    v-model={this.form.depositBonusAwardPercentage}
                    label='Deposit Bonus Award Percentage'
                    suffix='%'
                    error={this.validationErrors?.depositBonusAwardPercentage != null}
                    errorMessages={this.validationErrors?.depositBonusAwardPercentage || []}
                    errorCount={5}
                    messages={[
                      'The percentage of the next qualifying deposit to be awarded as an active bonus.',
                      'E.g. A 50% award percentage for a 1 ETH deposit will award 0.5 ETH as a bonus.',
                    ]}
                    outlined
                    class={styles.inputField}
                  />
                  <div style={{ marginBottom: '3rem' }}>
                    <VCheckbox
                      v-model={this.form.lockDepositBonus}
                      label='Lock Deposit?'
                      error={this.validationErrors?.lockDepositBonus != null}
                      errorMessages={this.validationErrors?.lockDepositBonus || []}
                      errorCount={5}
                    />
                    <p>
                      <b>Lock Deposit:</b> When player activates a locked deposit bonus via depositing,
                      {' '}the deposit will be fully or partially locked and would only be released when
                      {' '}the locked deposit bonus is successfully rolled over or cancelled / expired.
                    </p>
                  </div>
                </div>
              );
            }
          })()}
        </div>
      );
    };

    return (
      <VDialog
        v-model={this.open}
        on={{
          'click:outside': () => { this.$emit('open', false); },
        }}
        maxWidth='700'
      >
        <VCard>
          <VCardTitle>
            Award Casino Freebets Form
          </VCardTitle>
          <VCardText>
            <VContainer>
              <VRow>
                <VCol>
                  {awardFreebetsForm()}
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn onClick={() => { this.$emit('open', false); }}>Close</VBtn>
            <VBtn
              color='primary'
              onClick={() => { this.onAwardFreebetsFormSubmit(); }}
              loading={this.loading}
              disabled={this.loading}
            >
              Award Freebets
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});

export default AwardCasinoEvolutionVoucherDialog;
