import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VContainer,
  VExpandTransition,
  VIcon,
  VSimpleTable,
  VSkeletonLoader,
  VSnackbar,
  VSpacer,
  VTextField,
} from 'vuetify/lib';
import { EventScore } from '@/types/sports';
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { cloneOriginalValues, valuesChanged } from '@/utils/object-values-tracker';

export default Vue.extend({
  name: 'eventScoresWidget',
  props: {
    eventId: String,
    sportKey: String,
  },
  data() {
    return {
      text: '',
      color: '',
      snackbar: false,
      isLoading: true,
      hide: false,
      headers: [
        { text: 'Type', value: 'type' },
        { text: 'Home', value: 'home' },
        { text: 'Away', value: 'away' },
      ],
      scoresEntry: {} as any,
    };
  },
  watch: {
    async eventId() {
      await this.getEventScores({ eventId: this.eventId, sportKey: this.sportKey });
    },
    changeScoreResult() {
      this.snackbar = true;
      this.color = 'red darken-1';
      this.text = 'Failed to apply changed';
      if (this.changeScoreResult.success) {
        this.text = 'Changes applied successfully';
        this.color = 'green darken-1';
      }
    },
    scores() {
      this.scoresEntry = {};
      (this.scores[this.eventId] || []).map((score: EventScore) => {
        this.scoresEntry[score.type] = score;
      });
      this.scoresEntry = cloneOriginalValues(this.scoresEntry);
    },
  },
  async mounted() {
    await this.getEventScores({ eventId: this.eventId, sportKey: this.sportKey });
  },
  computed: {
    ...mapGetters({
      changeScoreResult: 'pendingEvents/changeScoreResult',
      scores: 'pendingEvents/scores',
      loadingScore: 'pendingEvents/loadingScore',
      submittingScore: 'pendingEvents/submittingScore',
    }),

    scoresChanged(): boolean {
      return valuesChanged(this.scoresEntry);
    },
  },
  methods: {
    ...mapActions({
      getEventScoresAction: 'pendingEvents/getEventScores',
      submitEventScores: 'pendingEvents/submitEventScores',
    }),
    async getEventScores({ eventId, sportKey }: { eventId: string, sportKey: string }) {
      this.isLoading = true;
      try {
        await this.getEventScoresAction({ eventId, sportKey });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },

    async saveScores() {
      const scoresSubmission = Object.keys(this.scoresEntry).filter((key) => {
        return this.scoresEntry[key].effective;
      }).map((key) => {
        return {
          type: key,
          home: parseInt(this.scoresEntry[key].home, 10),
          away: parseInt(this.scoresEntry[key].away, 10),
        };
      }).filter((s) => _.isFinite(s.away) && _.isFinite(s.home));

      if (scoresSubmission.length > 0) {
        await this.submitEventScores({ eventId: this.eventId, scores: scoresSubmission });
      }
    },

    scoreTypeChanged(type: string): boolean {
      return valuesChanged(this.scoresEntry[type]);
    },
  },
  render() {
    const scores = this.scores[this.eventId] as EventScore[];
    return (
      <VContainer fluid>
        <VCard>
          <VCardActions>
            <VCardSubtitle>
              Update latest scores will help settle most of bets
            </VCardSubtitle>
            <VSpacer></VSpacer>
            <VIcon onClick={async () => { this.hide = !this.hide; }}>
              {this.hide ? 'expand_less' : 'expand_more'}
            </VIcon>
          </VCardActions>

          <VExpandTransition>
            {this.hide || (!scores || scores.length === 0) ?
              <div>
                {this.loadingScore && <VSkeletonLoader type='table-tbody' />}
                {!this.loadingScore && <span>This event has no scores</span>}
              </div>
              :
              <div>
                <VSnackbar
                  top={true}
                  color={this.color}
                  elevation={'24'}
                  vModel={this.snackbar} timeout={2000}>
                  {this.text}
                </VSnackbar>
                <VSimpleTable>
                  <thead>
                    <tr>
                      <th>Type</th>
                      <th>Home</th>
                      <th>Away</th>
                    </tr>
                  </thead>
                  <tbody>
                    {[...scores].sort((a, b) => a.type.localeCompare(b.type)).map((score: EventScore) => {

                      let className = '';
                      if (!score.effective) {
                        className = 'bg-gray';
                      } else if (score.disabled && !this.scoreTypeChanged(score.type)) {
                        className = 'bg-red';
                      }
                      return <tr class={className}>
                        <td>{score.name} <span class='grey--text'>({score.type})</span></td>
                        <td><VTextField
                          disabled={!score.effective}
                          type='number'
                          dense
                          vModel={this.scoresEntry[score.type].home} /></td>
                        <td><VTextField
                          disabled={!score.effective}
                          type='number'
                          dense
                          vModel={this.scoresEntry[score.type].away} /></td>
                      </tr>;
                    })}
                  </tbody>
                </VSimpleTable>
                <div class='text-right'>
                  <VBtn
                    class='mr-2 mb-2'
                    loading={this.submittingScore}
                    onClick={this.saveScores}
                    disabled={!this.scoresChanged}>Save</VBtn>
                </div>
              </div>
            }
          </VExpandTransition>
        </VCard>
      </VContainer>
    );
  },
});
