import Vue from 'vue';

import { VContainer, VRow, VSelect, VCol } from 'vuetify/lib';
import { eventTypeOptions, EventTypeFilter } from '@/static';

import EventsWidget from '@/components/sports/EventsWidget';

export default Vue.extend({
  data() {
    return {
      eventType: 'all' as keyof typeof EventTypeFilter,
    };
  },
  computed: {
    sportKey() {
      return this.$route.query.sportKey;
    },
    competitionKey() {
      return this.$route.query.competitionKey;
    },
    filterBar() {
      return (
        <VRow class='d-flex flex-row-reverse'>
          <VCol
            class='ma-4 pb-0 mb-0 text-right'
            cols='12'
            sm='2'>
          <VSelect
            items={eventTypeOptions}
            label='Type'
            v-model={this.eventType}
            dense
          ></VSelect>
          </VCol>
        </VRow>
      );
    },
  },
  methods: {
    onFiltersChanged(filters: any) {
      if (!filters.competitionKey) { delete filters.competitionKey; }
      if (!filters.sportKey) { delete filters.sportKey; }
      if (filters.eventType === 'all') { delete filters.eventType; }

      this.$router.replace({ query: filters }).catch((err) => { return; });
    },
  },
  created() {
    if (this.$route.query.eventType) {
      this.eventType = this.$route.query.eventType as keyof typeof EventTypeFilter;
    }
  },
  render() {
    return (
      <VContainer fluid>
        {this.filterBar}
        <EventsWidget filterable
                      onFiltersChanged={this.onFiltersChanged}
                      sportKey={this.sportKey}
                      competitionKey={this.competitionKey}
                      eventType={this.eventType} />
      </VContainer>
    );
  },
});
