import Vue from 'vue';
import {
  VContainer, VRow, VCol, VCard, VTextField, VDialog, VCardTitle,
  VCardText, VCardActions, VSpacer, VBtn, VSelect, VMessages,
} from 'vuetify/lib';
import { Stream } from '@/types/sports';
import { minLength, required } from 'vuelidate/lib/validators';

function normaliseYoutubeUrl(url: string) {
  // Standard youtube url
  let matches = /www\.youtube\.com\/watch\?v=([-_A-Za-z0-9]+)/.exec(url);
  if (matches) {
    return `https://www.youtube.com/embed/${matches[1]}`;
  }

  // Abbreviated youtube url
  matches = /youtu\.be\/([_A-Za-z0-9]+)/.exec(url);
  if (matches) {
    return `https://www.youtube.com/embed/${matches[1]}`;
  }

  return url;
}

function embedableYotubeUrl(url: string) {
  return /www\.youtube\.com\/embed\/[-_A-Za-z0-9]+/.test(url);
}

function normaliseTwitchUrl(url: string) {
  const matches = /twitch\.tv\/([-_A-Za-z0-9]+)/.exec(url);
  if (matches) {
    return `https://player.twitch.tv/?channel=${matches[1]}`;
  }

  return url;
}

function embedableTwitchUrl(url: string) {
  return /player\.twitch\.tv\/\?channel=[-_A-Za-z0-9]+/.test(url);
}

function providerByUrl(url: string) {
  if (url.includes('youtu')) {
    return 'youtube';
  } else if (url.includes('twitch.tv')) {
    return 'twitch';
  }

  return null;
}

export default Vue.extend({
  name: 'editDialog',
  props: {
    stream: Object as () => Stream,
    validationErrors: Object,
  },

  data() {
    return {
      languages: [
        'de',
        'el',
        'en',
        'es',
        'hi',
        'hu',
        'id',
        'it',
        'ja',
        'ko',
        'lv',
        'mt',
        'nl',
        'pl',
        'pt',
        'pt-BR',
        'ru',
        'tr',
        'zh',
        'zh-CN',
        'zh-TW',
      ],
      providers: [
        'twitch',
        'youtube',
      ],
      open: true,
    };
  },

  computed: {
    headline() {
      return 'Edit Stream';
    },

    actionButtons() {
      return (
        <VCardActions>
          <VSpacer></VSpacer>
          <VBtn color='blue darken-1' text onClick={this.onClose}>Close</VBtn>
          <VBtn color='blue darken-1' text onClick={this.onSave}>Save</VBtn>
        </VCardActions>
      );
    },

    providerErrors() {
      const errors: string[] = [];
      if (!this.$v.stream.provider!.$dirty) { return errors; }
      if (!this.$v.stream.provider!.required) { errors.push('Provider must be delivered'); }
      return errors;
    },

    languageErrors() {
      const errors: string[] = [];
      if (!this.$v.stream.language!.$dirty) { return errors; }
      if (!this.$v.stream.language!.required) { errors.push('Language must be delivered'); }
      if (!this.$v.stream.language!.minLength) {
        errors.push('Language must have at least 2 characters');
      }
      return errors;
    },

    urlErrors() {
      const errors: string[] = [];
      if (!this.$v.stream.url!.$dirty) { return errors; }
      if (!this.$v.stream.url!.required) { errors.push('Url must be delivered'); }
      return errors;
    },

    streamPreview() {
      let stream = null;
      if (this.stream.provider === 'youtube' && embedableYotubeUrl(this.stream.url)) {
        stream = this.youtubeStream;
      } else if (this.stream.provider === 'twitch' && embedableTwitchUrl(this.stream.url)) {
        stream = this.twitchStream;
      }

      if (stream) {
        return (
          <VContainer>
            <VRow>
              <VCol cols='12'>
                {stream}
              </VCol>
            </VRow>
          </VContainer>
        );
      }
    },

    youtubeStream() {
      return (
        <iframe width='530' height='300'
          src={this.stream.url}
          frameborder='0'
          allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
          allowfullscreen>
        </iframe>
      );
    },

    twitchStream() {
      return (
        <iframe width='530' height='300'
          src={this.stream.url + `&parent=${window.location.hostname}`}
          frameborder='0'
          allowfullscreen>
        </iframe>
      );
    },

    validationErrorsMessages() {
      if (this.validationErrors) {
        const invalidArgs = this.validationErrors.extensions.invalidArgs;
        const errors = Object.values(invalidArgs).map((value) => {
          return (
            <VMessages value={value} role='alert' color='error'></VMessages>
          );
        });

        return (
          <VContainer>
            <VRow>
              <VCol cols='12'>
                {errors}
              </VCol>
            </VRow>
          </VContainer>
        );
      }
    },
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSave() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit('save');
    },

    onUrlPaste(event: any) {
      event.preventDefault();
      const paste = event.clipboardData.getData('text');

      this.setProvider(paste);
      this.normaliseUrl(paste);
    },

    onUrlBlur() {
      this.setProvider(this.stream.url);
      this.normaliseUrl(this.stream.url);
    },

    normaliseUrl(url: string) {
      if (this.stream.provider === 'youtube') {
        this.stream.url = normaliseYoutubeUrl(url);
      } else if (this.stream.provider === 'twitch') {
        this.stream.url = normaliseTwitchUrl(url);
      }
    },

    setProvider(url: string) {
      const provider = providerByUrl(url);
      if (provider) {
        this.stream.provider = provider;
      }
    },
  },

  validations: {
    stream: {
      provider: {
        required,
      },
      language: {
        required,
        minLength: minLength(2),
      },
      url: {
        required,
      },
    },
  },

  watch: {
    open(value) {
      if (value) { this.$v.$reset(); }
    },
  },

  render() {
    return (
      <VDialog v-model={this.open} persistent max-width='600px'>
        <VCard>
          <VCardTitle><span class='headline'>{this.headline}</span></VCardTitle>
          <VCardText>
            <VContainer>
              <VRow>
                <VCol cols='6'>
                  <VSelect label='Provider*' required dense
                    items={this.providers}
                    v-model={this.$v.stream.provider!.$model}
                    errorMessages={this.providerErrors}>
                  </VSelect>
                </VCol>
                <VCol cols='3'>
                  <VSelect label='Language*' required dense
                    items={this.languages}
                    v-model={this.$v.stream.language!.$model}
                    errorMessages={this.languageErrors}>
                  </VSelect>
                </VCol>
                <VCol cols='12'>
                  <VTextField label='Url*' required
                    v-model={this.$v.stream.url!.$model}
                    errorMessages={this.urlErrors}
                    onBlur={this.onUrlBlur}
                    onPaste={this.onUrlPaste} >
                  </VTextField>
                </VCol>
              </VRow>
            </VContainer>
            {this.validationErrorsMessages}
            <small>*indicates required field</small>
            {this.streamPreview}
          </VCardText>
          {this.actionButtons}
        </VCard>
      </VDialog>
    );
  },
});
