export const financeValueClass = (value: string | number) => {
  let input = value;
  if (typeof value === 'string') {
    input = parseFloat(value);
  }

  if (input !== 0) {
    return input < 0 ? 'negative-finance-value' : 'positive-finance-value';
  }
  // no styles applied
  return '';
};
