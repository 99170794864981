<template>
  <v-container class="dashboard-widget-subform">
    <v-select
      v-model="stream.variant"
      :items="availableVariants"
      label="Variant"
    ></v-select>
    <v-select
      v-model="stream.sourceKey"
      :items="availableSources"
      label="Source"
    ></v-select>
    <v-label v-if="stream.sourceKey">Filters</v-label>
    <v-list-group
      v-for="attrGroup in (source || {}).attrGroups"
      :key="attrGroup.text"
      class="attr-group"
    >
      <template v-slot:activator>
        <v-list-item-title>{{attrGroup.text}}</v-list-item-title>
        <div class="attr-chips-container">
          <v-chip
            :key="`chip-${attrGroup.text}-${grpAttr.text}`"
            v-if="filters[grpAttr.key] && filters[grpAttr.key].operator && filters[grpAttr.key].value"
            v-for="grpAttr in attrGroup.attrs"
            close
            @click:close="clearFilter(grpAttr.key)"
            class="attr-chip"
          >{{ grpAttr.text + ' ' + filters[grpAttr.key].operator + ' ' + filters[grpAttr.key].value }}</v-chip>
        </div>
      </template>
      <template v-for="grpAttr in attrGroup.attrs" v-if="filters[grpAttr.key]">
        <v-list-item :key="`${attrGroup.text}-${grpAttr.text}`" class="attr-list-item">
          <template>
            <v-list-item-content>{{ grpAttr.text }}</v-list-item-content>
            <v-list-item-content class="filter-input-field filter-input-field--small">
              <v-select
                v-if="grpAttr.type == 'string'"
                v-model="filters[grpAttr.key].operator"
                :items="['==', '!=', 'includes']"
                label="filter cond."
              ></v-select>
              <v-select
                v-if="grpAttr.type == 'number'"
                v-model="filters[grpAttr.key].operator"
                :items="['==', '!=', '>', '<', '>=', '<=']"
                label="filter cond."
              ></v-select>
              <v-select
                v-if="grpAttr.type == 'static'"
                v-model="filters[grpAttr.key].operator"
                :items="['==', '!=']"
                label="filter cond."
              ></v-select>
              <v-select
                v-if="grpAttr.type == 'boolean'"
                v-model="filters[grpAttr.key].operator"
                :items="['==']"
                label="filter cond."
              ></v-select>
            </v-list-item-content>
            <v-list-item-content class="filter-input-field">
              <v-text-field
                v-if="grpAttr.type == 'string'"
                v-model="filters[grpAttr.key].value"
                label="filter string"
              ></v-text-field>
              <v-text-field
                v-if="grpAttr.type == 'number'"
                v-model="filters[grpAttr.key].value"
                type="number"
                label="filter number"
              ></v-text-field>
              <v-select
                v-if="grpAttr.type == 'static'"
                v-model="filters[grpAttr.key].value"
                :items="grpAttr.values"
                label="filter value"
              ></v-select>
              <v-select
                v-if="grpAttr.type == 'boolean'"
                v-model="filters[grpAttr.key].value"
                :items="booleanValues"
                item-value='value'
                item-text='name'
                label="filter value"
              ></v-select>
            </v-list-item-content>
          </template>
        </v-list-item>
      </template>
    </v-list-group>
  </v-container>
</template>

<script>
import { SOURCES } from '@/streams';
import { mapActions } from 'vuex';
import { find } from 'lodash';

export default {
  props: ['stream'],
  data: () => ({
    availableSources: Object.keys(SOURCES).map((key) => ({
      text: SOURCES[key].text,
      value: key,
    })),
    availableVariants: ['card', 'terminal'],
    filters: {},
    booleanValues: [
      { value: true, name: 'yes' },
      { value: false, name: 'no' },
    ],
  }),
  computed: {
    source() {
      return SOURCES[this.stream.sourceKey];
    },
  },
  methods: {
    clearFilter(attrKey) {
      this.filters[attrKey] = {
        operator: null,
        value: null,
      };
    },
    prepareFilters() {
      const filters = {};
      for (const attrGroup of this.source.attrGroups) {
        for (const attr of attrGroup.attrs) {
          const filter = find(this.stream.filters.filters, (f) => f.column === attr.key);

          if (filter) {
            filters[attr.key] = {
              operator: filter.operator,
              value: filter.value,
            };
          } else {
            filters[attr.key] = {
              operator: null,
              value: null,
            };
          }
        }
      }

      this.filters = filters;
    },
    beforeSave() {
      this.stream.filters = {
        operator: 'and',
        filters: [],
      };

      Object.keys(this.filters).forEach((filterKey) => {
        const filter = this.filters[filterKey];
        if (filter.operator && filter.value) {
          this.stream.filters.filters.push({
            column: filterKey,
            operator: filter.operator,
            value: filter.value,
          });
        }
      });
    },
  },
  created() {
    if (!this.source) {
      this.stream.filters = {
        operator: 'and',
        filters: [],
      };

      return;
    }

    this.prepareFilters();
  },
  watch: {
    source: {
      handler(newSource, oldSource) {
        if (newSource !== oldSource) {
          this.stream.filters = {
            operator: 'and',
            filters: [],
          };
        }
        this.prepareFilters();
      },
    },
  },
};
</script>

<style lang="less">
.attr-group {
  & > .v-list-group__header {
    flex-wrap: wrap;
  }
  & > .v-list-group__header > .v-list-item__title {
    flex: auto;
  }
}
.attr-chips-container {
  order: 3;
  width: 100%;
}
.attr-chip {
  margin: 4px;
}
.attr-list-item {
  & > .v-list-item__content {
    margin-top: 12px;
  }
  & > .v-list-item__action {
    padding-top: 12px;
  }
}
.filter-input-field {
  margin-top: 0px !important;
  padding: 0px 5px 0px 5px !important;
  & .v-input__slot {
    margin: 0px;
  }

  & .v-text-field__details {
    display: none;
  }

  &.filter-input-field--small {
    width: 60px;
  }
}
</style>
