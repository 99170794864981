import Vue from 'vue';
import {
  VListItemContent,
  VListItem,
  VListItemTitle,
  VListItemSubtitle,
  VIcon,
} from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import { RouterLink } from '@/router';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import { CompetitionTranslationModal } from '@/views/sports/CompetitionTranslationPanel/CompetitionTranslationPanel';

import './CompetitionHeadline.less';

const { mapActions, mapState } = createNamespacedHelpers('sports/competitions');

export default Vue.extend({
  props: {
    competitionId: Number,
  },

  data() {
    return {
      showTranslations: false,
    };
  },

  methods: {
    ...mapActions({
      loadCurrentCompetition: 'loadCurrentCompetition',
    }),

    toggleTranslation() {
      this.showTranslations = !this.showTranslations;
    },
  },

  computed: {
    ...mapState({
      currentCompetition: 'currentCompetition',
    }),

    sportName(): string | undefined {
      return getSportsName(this.currentCompetition.sportKey, Locale.en);
    },

    showName(): boolean {
      return !this.showTranslations || this.$vuetify.breakpoint.smAndUp;
    },
  },

  mounted() {
    this.loadCurrentCompetition(this.competitionId);
  },

  watch: {
    eventId() {
      this.loadCurrentCompetition(this.competitionId);
    },
  },

  render() {
    return (
      <VListItem two-line class='competition-headline'>
        {(this.currentCompetition) && (
          <VListItemContent>
            <VListItemTitle class='headline'>
              <div class='mt-1'>
                <span vShow={this.showName}>{this.currentCompetition?.name || 'unknown competition'}</span>
                <VIcon
                  color='primary'
                  class='ml-2'
                  onClick={this.toggleTranslation}
                  outlined={!this.showTranslations}
                  contained={this.showTranslations}>
                  {this.showTranslations ? 'close' : 'edit'}
                </VIcon>
                {this.showTranslations &&
                  <div class='competition-translation-modal'>
                    <CompetitionTranslationModal
                      competitionId={this.$route.params.competitionId.toString()}
                      entityType={'competition'}
                    />
                  </div>
                }
              </div>
            </VListItemTitle>
            <VListItemSubtitle>
              <RouterLink
                class='text-decoration-none'
                to={{
                  name: 'sport',
                  params: { sportKey: this.currentCompetition.sportKey },
                }}>
                {this.sportName || 'unknown sport'}
              </RouterLink>
            </VListItemSubtitle>
          </VListItemContent>
        )}
      </VListItem>
    );
  },
});

