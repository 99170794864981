import Vue from 'vue';
import { VContainer } from 'vuetify/lib';
import { PlayerList } from '@/components/sports/PlayerList/PlayerList';

export default Vue.extend({
  render() {
    return (
      <VContainer fluid>
        <PlayerList/>
      </VContainer>
    );
  },
});
