import Vue from 'vue';
import base from './base';
import { env } from '@/env';

export default Vue.extend({
  ...base,
  computed: {
    messageData(): any {
      return this.message.data;
    },
    stateClass(): string {
      if (this.messageData.state === 'CONFIRMED') {
        return 'summary--success';
      }

      return '';
    },
    stateColor(): string {
      const classes = ['transactions-state', 'font-weight-black', 'v-chip', 'v-chip--label', 'v-size--x-small'];

      if (this.$vuetify.theme.dark) {
        classes.push('theme--dark');
      } else {
        classes.push('theme--light');
      }

      if (this.messageData.state === 'CONFIRMED') {
        classes.push('success');
      } else {
        classes.push('default');
      }

      return classes.join(' ');
    },
    txUri(): string {
      const explorer = env.coinExplorers[this.messageData.currency as string] as string;
      if (explorer) {
        return `${explorer}/${this.messageData.hash}`;
      }
      return `https://blockchair.com/search?q=${this.messageData.hash}`;
    },
  },
});
