export enum BonusType {
  CASINO_DEPOSIT = 'casino_deposit',
  CASINO_FREEBONUS = 'casino_freebonus',
}

export enum BonusVersion {
  VERSION_1 = 1,
  VERSION_2 = 2,
}

export enum BonusUsageStrategy {
  REAL_THEN_BONUS = 'real_then_bonus',
  BONUS_THEN_REAL = 'bonus_then_real',
}
