import './EntityMediaWidget.less';
import { gql } from 'apollo-boost';
import Vue from 'vue';
import { DocumentNode } from 'graphql';
import { VBtn, VDataTable, VIcon, VSpacer, VToolbar, VToolbarTitle } from 'vuetify/lib';
import EditDialog from './EditDialog';
import { Media, Competitor, Event } from '@/types/sports';
import { RouterLink } from '@/router';


export default Vue.extend({
  name: 'entityMediaWidget',
  props: {
    entityType: String,
    entityKey: String,
  },
  data: () => ({
    mode: 'create' as string,
    media: {} as Media,
    open: false as boolean,
    entity: {} as (Competitor | Event),
    headers: [
      {
        text: 'Media Type',
        value: 'type',
      },
      {
        text: 'Media URL',
        value: 'url',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  computed: {
    entityName() {
      if (this.entityType === 'event') {
        const event = (this.entity as Event);
        if (event?.competitors?.length >= 2) {
          return (<span>(
            <RouterLink
              class='text-decoration-none'
              to={{
                name: 'eventMedia',
                params: { entityType: 'competitor', entityKey: event.competitors[0].id?.toString() },
              }}
            >{event.competitors[0]?.name}</RouterLink>&nbsp;v&nbsp;
            <RouterLink
              class='text-decoration-none'
              to={{
                name: 'eventMedia',
                params: { entityType: 'competitor', entityKey: event.competitors[1].id?.toString() },
              }}
            >{event.competitors[1]?.name}</RouterLink>)</span>);
        }
      }
    },
    mediaList() {
      if (this.entityType === 'competitor') {
        return (this.entity as Competitor).media || [];
      } else if (this.entityType === 'event') {
        return (this.entity as Event).eventMedia || [];
      }
      return [];
    },
    editDialog() {
      if (this.open) {
        return (
          <EditDialog
            mode={this.mode}
            entityType={this.entityType}
            entityKey={this.entityKey}
            media={this.media}
            open={this.open}
            closeDialog={() => {
              this.open = false;
            }}
            afterSave={() => {
              this.$apollo.queries.entity.refetch();
              this.open = false;
            }}
          />
        );
      }
    },
  },
  methods: {
    editMedia(item: Media): void {
      this.mode = 'modify';
      this.open = true;
      this.media = item;
    },
    async deleteMedia(item: Media): Promise<void> {
      await this.$apollo
        .mutate({
          mutation: gql`mutation ($input: EntityMediaInput!) {
              updateEntityMedia(input: $input) {
                success
              }
            }`,
          variables: {
            input: {
              entityType: this.entityType,
              entityKey: this.entityKey,
              mediaType: item.type,
              mediaUrl: '',
            },
          },
        });
      this.$apollo.queries.entity.refetch();
    },
    actions({ item }: { item: Media }): JSX.Element {
      return (
        <div>
          <VIcon small onClick={() => this.editMedia(item)}>
            edit
          </VIcon>
          <VIcon small onClick={() => this.deleteMedia(item)}>
            delete
          </VIcon>
        </div>
      );
    },
    mediaURL({ item }: { item: Media }): JSX.Element {
      return (
        <div>
          <div>{item.url}</div>
          <img class='media-image' src={this.cloudinary(item.type, item.url)} />
        </div>
      );
    },
    cloudinary(type: string, url: string) {
      // hacky handling on stg url as it is not public
      url = url.replace('stg.cloudbet.com', 'bitgamelabs:olympic@stg.cloudbet.com');
      if (type === 'LOGO') {
        return `https://res.cloudinary.com/betengine/image/fetch/w_140,h_140,c_fill,r_20,e_saturation:50,f_auto/${url}`;
      }
      return `https://res.cloudinary.com/betengine/image/fetch/w_470,h_80,c_fill,g_auto:face/${url}`;
    },
    toolbar() {
      return (
        <VToolbar flat>
          <VToolbarTitle>Entity Media {this.entityName}</VToolbarTitle>
          <VSpacer></VSpacer>
          <VBtn
            onClick={() => {
              this.mode = 'create';
              this.open = true;
            }}
            color='primary'>
            New Media
          </VBtn>
        </VToolbar>
      );
    },
  },
  apollo: {
    entity: {
      query():
        DocumentNode {
        if (this.entityType === 'competitor') {
          return gql`
            query($entityKey: String!) {
                entity: competitor(id: $entityKey) {
                    name
                    media {
                        type
                        url
                    }
                }
            }
          `;
        }
        return gql`
        query($entityKey: String!) {
            entity: event(eventId: $entityKey) {
                competitors {
                  id
                  name
                }
                eventMedia {
                    type
                    url
                }
            }
          }
        `;
      },
      variables() {
        return { entityKey: this.entityKey };
      },
    },
  },
  render() {
    return (
      <div>
        {this.editDialog}
        <VDataTable
          headers={this.headers}
          items={this.mediaList}
          sortBy={'type'}
          scopedSlots={{
            'item.actions': this.actions,
            'item.url': this.mediaURL,
            'top': this.toolbar,
          }}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.entity.loading}
        />
      </div >
    );
  },
});
