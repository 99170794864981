import competitions from './sports/competitions';
import events from './sports/events';
import competitionsPriorities from './sports/competitions_priorities';
import liabilitySettings from './sports/liability_settings';
import unmatchedEvents from './sports/unmatched_events';
import competitors from './sports/competitors';
import inContextHelpGradingResults from './sports/in_context_help_grading_results';
import providerPriceControl from './sports/provider_price_control';

export default {
  namespaced: true,
  modules: {
    competitions,
    competitors,
    events,
    competitionsPriorities,
    liabilitySettings,
    unmatchedEvents,
    inContextHelpGradingResults,
    providerPriceControl,
  },
};
