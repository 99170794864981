import './CompetitionTranslationsWidget.less';
import { RouterLink } from '@/router';
import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VDataTable, VTextField, VIcon } from 'vuetify/lib';

export default Vue.extend({
  name: 'competitionTranslationsWidget',
  props: {
    name: String,
    sportId: Number,
    categoryId: Number,
    locale: String,
  },
  data() {
    return {
      competitions: [],
      headers: [
        { text: 'Id', value: 'id', width: 100 },
        { text: 'Name', value: 'name', sortable: false },
        { text: 'Translation', value: 'translation', sortable: false },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
    };
  },
  methods: {
    entityLink(props: any): JSX.Element {
      return (
        <RouterLink
          class='text-decoration-none'
          to={{ name: 'competition', params: { competitionId: props.item.id } }}
        >
          {props.item.id}
        </RouterLink>
      );
    },
    editTranslation(props: any): JSX.Element {
      return <VTextField vModel={props.item.translation} />;
    },
    actions(props: any): JSX.Element {
      return (
        <VIcon
          disabled={
            props.item.translation.length === 0 ||
            props.item.currentTranslation === props.item.translation
          }
          onClick={async () => {
            const res = await this.$apollo.mutate({
              mutation: gql`
                mutation($input: TranslationInput!) {
                  updateTranslation(input: $input) {
                    success
                  }
                }
              `,
              variables: {
                input: {
                  entityType: 'competition',
                  id: props.item.id,
                  locale: this.locale,
                  value: props.item.translation,
                },
              },
              context: {
                hasUpload: true,
              },
            });
            if (res.data.updateTranslation.success) {
              props.item.currentTranslation = props.item.translation;
            }
          }}
        >
          save
        </VIcon>
      );
    },
    itemRowBackground(item: any): string {
      return item.currentTranslation !== item.translation ? 'bg-yellow' : '';
    },
  },
  computed: {
    sportsKeysMap(): any {
      return this.$store.state.sportsConsts.sportsKeysMap;
    },
    categoriesMap(): any {
      return this.$store.state.sportsConsts.categoriesMap;
    },
  },
  apollo: {
    competitions: {
      skip(): boolean {
        return this.name.length < 3;
      },
      query: gql`
        query(
          $name: String!
          $sportId: Int
          $categoryId: Int
          $locale: String!
        ) {
          searchCompetitions(
            name: $name
            sportId: $sportId
            categoryId: $categoryId
            locale: $locale
          ) {
            competitions {
              id
              name
              key
              sportKey
              categoryId
              translation
            }
          }
        }
      `,
      variables(): {
        name: string;
        sportId: number;
        categoryId: number;
        locale: string;
      } {
        return {
          name: this.name,
          sportId: this.sportId,
          categoryId: this.categoryId,
          locale: this.locale,
        };
      },
      update(data) {
        return data.searchCompetitions.competitions.map((c: any) => {
          c.currentTranslation = c.translation;
          return c;
        });
      },
      fetchPolicy: 'network-only',
    },
  },
  render() {
    return (
      <div>
        {this.name.length < 3 ? (
          <div class='px-4'>Please enter name with at least 3 characters</div>
        ) : (
          <VDataTable
            headers={this.headers}
            items={this.competitions || []}
            itemClass={this.itemRowBackground}
            sortBy={'id'}
            scopedSlots={{
              'item.id': this.entityLink,
              'item.translation': this.editTranslation,
              'item.actions': this.actions,
            }}
            loading-text='Loading... Please wait'
            loading={this.$apollo.queries.competitions.loading}
          />
        )}
      </div>
    );
  },
});
