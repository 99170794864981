import Vue from 'vue';
import { VTextField, VChip } from 'vuetify/lib';
import './GlobalSearchNew.less';
import SearchResults from '@/views/sports/SearchResults';

import { SuggestionsCard } from '../SuggestionsCard/SuggestionsCard';
import { Sport, Category } from '@/store/modules/sports_consts';
import { parseInput } from './parseSearchInput';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('sportsConsts');

const filterStringCollection = (collection: string[], text?: string) => {
  if (!text) {
    return collection;
  }

  return collection.filter((item) => {
    return item.toLowerCase().indexOf(text.toLowerCase()) !== -1;
  });
};

const defaultLocale = 'en';

export default Vue.extend({
  data() {
    return {
      inputString: '',
      selectedSuggestionIndex: 0,
      locale: defaultLocale,
      property: '',
      showSuggestionPanel: false,
      enterPressed: false,
      searchParams: {} as {
        categoryId?: number,
        categoryName?: string,
        entityType: string,
        id?: number,
        sportId?: number,
        sportName?: string,
        text?: string,
        uuid?: string,
      },
    };
  },
  props: {
    enableEscOverride: Function,
    unBlockEsc: Function,
  },
  watch: {
    enterPressed() {
      this.hideSuggestionPanel();
    },

    $route() {
      this.fireBackslash();
    },

    suggestionsList() {
      this.selectedSuggestionIndex = 0;
    },

    inputString(val: string) {
      if (val.startsWith('/')) {
        this.$nextTick(() => {
          this.inputString = this.inputString.slice(1);
        });
        return;
      }

      this.searchParams = parseInput(val, this.sportsRecord, this.categoriesRecord);
      this.enterPressed = false;

      if (!val) {
        this.resetSearch();
      }

      this.showSuggestionPanel = true;
    },

    showSuggestionPanel(val: boolean) {
      if (!val) {
        // We don't block esc anymore since the suggestion panel is going to be hidden
        this.unBlockEsc();
      }
    },
  },
  methods: {
    ...mapActions(['loadSportsConsts']),

    fireBackslash() {
      const el = document.getElementById('search-bar');
      const event = new KeyboardEvent('keydown', {
        key: '/',
        bubbles: true,
      });
      el?.dispatchEvent(event);
    },

    hideSuggestionPanel() {
      this.showSuggestionPanel = false;
    },

    resetSearch() {
      this.selectedSuggestionIndex = 0;
      this.enterPressed = false;
    },

    redirectToEntityPage(entityType: string, searchText: string | number) {
      const params = {
        [`${entityType}Id`]: searchText,
        [`${entityType}UUID`]: searchText,
      } as Record<string, any>;

      this.$router.push({ name: entityType, params });
    },

    applySuggestion() {
      const suggestion = this.currentSuggestions[this.selectedSuggestionIndex];
      const input = document.querySelector('#search-bar') as HTMLInputElement;
      const cursorPos = input.selectionEnd || 0;
      const textBeforeCursor = this.inputString.slice(0, cursorPos);


      let i = cursorPos;
      for (; i >= 0; --i) {
        const editedText = textBeforeCursor.slice(i, cursorPos).toLowerCase();
        if (!suggestion.toLowerCase().includes(editedText)) {
          break;
        }
      }
      const beforeEditedText = this.inputString.slice(0, i + 1).trim();
      const afterEditedText = this.inputString.slice(cursorPos).trim();

      let newInput = beforeEditedText;
      if (afterEditedText.length > 0) {
        newInput += ' ' + afterEditedText;
      }
      if (newInput.length > 0) {
        newInput += ' ';
      }
      newInput += suggestion + ' ';

      this.inputString = newInput;
      this.selectedSuggestionIndex = 0;
    },

    onKeydown(e: KeyboardEvent) {
      if (e.key === 'Tab' || e.keyCode === 9) {
        if (this.showSuggestionPanel) {
          e.preventDefault();
          if (this.currentSuggestions.length > 0) {
            this.applySuggestion();
          }
        }
      }

      if (e.key === 'Enter' || e.code === 'Enter') {
        this.searchParams = parseInput(this.inputString, this.sportsRecord, this.categoriesRecord);
        this.enterPressed = true;

        const searchResultEl = document.getElementById('search-results');
        if (document.activeElement === searchResultEl) {
          this.fireBackslash();
          return;
        }

        if (this.searchParams.id || this.searchParams.uuid) {
          // We can't simply redirect if we search for uuid,
          // since we don't know which entity type we're looking for.
          if (this.searchParams.entityType !== 'uuid') {
            this.redirectToEntityPage(
              this.searchParams.entityType,
              (this.searchParams.id || this.searchParams.uuid) as number | string,
            );
            // Exit overlay so entity page is visible.
            this.fireBackslash();
            return;
          }
        }

        this.hideSuggestionPanel();
      }

      if (e.key === 'ArrowDown') {
        if (
          this.selectedSuggestionIndex >= 0 &&
          this.selectedSuggestionIndex < this.currentSuggestions.length - 1
        ) {
          this.selectedSuggestionIndex = this.selectedSuggestionIndex + 1;
        }
      }
      if (e.key === 'ArrowUp') {
        if (this.selectedSuggestionIndex > 0) {
          this.selectedSuggestionIndex = this.selectedSuggestionIndex - 1;
        }
      }
    },

    chipIfValueExists(value?: string | number, color = 'secondary') {
      if (!value) {
        return;
      }

      return (
        <VChip label class='mr-2' color={color} >
          {value}
        </VChip>
      );
    },

    windowKeyUp(event: KeyboardEvent) {
      if (event.key === '/') {
        document.getElementById('search-bar')?.focus();
        event.preventDefault();

      }
    },

    windowKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape' && this.showSuggestionPanel) {
        event.preventDefault();
        this.showSuggestionPanel = false;
      }
    },
  },
  computed: {
    ...mapState(['sports', 'categories']),

    sportsRecord(): Record<string, Sport> {
      return this.$store.state.sportsConsts.sports
        .reduce((acc: Record<string, Sport>, sport: Sport) => {
          acc[sport.name.toLowerCase()] = sport;
          return acc;
        }, {});
    },

    categoriesRecord(): Record<string, Category> {
      return this.$store.state.sportsConsts.categories
        .reduce((acc: Record<string, Category>, category: Category) => {
          acc[category.name.toLowerCase()] = category;
          return acc;
        }, {});
    },

    currentSuggestions(): string[] {
      const suggestions = [];

      if (!this.searchParams.entityType) {
        suggestions.push(...Object.keys(this.suggestionsDefinition));
      }

      const entitySuggestions = this.suggestionsDefinition[this.searchParams.entityType];
      if (entitySuggestions) {
        if (entitySuggestions.sportId && !this.searchParams.sportId) {
          suggestions.push(...entitySuggestions.sportId);
        }

        if (entitySuggestions.categoryId && !this.searchParams.categoryId) {
          suggestions.push(...entitySuggestions.categoryId);
        }
      }

      return filterStringCollection(suggestions, this.searchParams.text);
    },

    suggestionsDefinition(): Record<string, { sportId?: string[], categoryId?: string[] }> {
      const sports = this.sports.map((s: { name: string; }) => s.name);
      const categories = this.categories.map((c: { name: string; }) => c.name);

      return {
        event: { sportId: sports, categoryId: categories },
        competition: { sportId: sports, categoryId: categories },
        competitor: { sportId: sports },
        uuid: {},
        player: {},
        nickname: {},
        address: {},
      };
    },
  },

  mounted() {
    this.loadSportsConsts();

    window.addEventListener('keyup', this.windowKeyUp);
    window.addEventListener('keydown', this.windowKeyDown);
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.windowKeyUp);
    window.removeEventListener('keydown', this.windowKeyDown);
  },

  render() {
    return (
      <div>
        <VTextField
          autofocus={true}
          id='search-bar'
          dense
          placeholder={'Type "event" or  a sport to start searching'}
          onKeydown={this.onKeydown}
          vModel={this.inputString}
          outlined
          messages={this.inputString.length ? ['searching'] : undefined}
        >
          <template slot='message'>
            {this.inputString.length > 0 &&
              this.showSuggestionPanel &&
              this.currentSuggestions.length > 0 ? (
                <SuggestionsCard
                  suggestions={this.currentSuggestions}
                  selectedIndex={this.selectedSuggestionIndex}
                  onHide={() => {
                    this.hideSuggestionPanel();
                  }}
                  onDisableEscOverlay={() => {
                    this.enableEscOverride();
                  }}
                />
              ) : null}
          </template>
        </VTextField>
        {this.chipIfValueExists(this.searchParams.entityType, 'primary')}
        {this.chipIfValueExists(this.searchParams.id)}
        {this.chipIfValueExists(this.searchParams.uuid)}
        {this.chipIfValueExists(this.searchParams.sportName, 'primary')}
        {this.chipIfValueExists(this.searchParams.categoryName, 'primary')}
        {this.chipIfValueExists(this.searchParams.text)}
        <div class='search-results-page'>
          {this.enterPressed && <SearchResults
            id={'search-results'}
            searchText={this.searchParams.text || this.searchParams.uuid || ''}
            sportId={this.searchParams.sportId || 0}
            categoryId={this.searchParams.categoryId || 0}
            entityType={this.searchParams.entityType || ''}
            property={this.property}
            locale={this.locale}
            showSearchPage={true}
          />}
        </div>
      </div>
    );
  },
});
