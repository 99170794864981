import Vue from 'vue';
import { formatCurrency, FormatCurrencyOptions } from './currency';
import { currencyAlias } from '@/static/currencies';
import { fixWidth } from './fix-width';
import { round } from './round';

function initializeFilters() {
  Vue.filter('currency', formatCurrency);
  Vue.filter('currencyAlias', currencyAlias);
  Vue.filter('fixWidth', fixWidth);
  Vue.filter('round', round);
}

export { formatCurrency, fixWidth, round, initializeFilters, currencyAlias, FormatCurrencyOptions };
