import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VChip,
  VChipGroup,
  VCol,
  VContainer,
  VDataTable,
  VRow,
  VSelect,
} from 'vuetify/lib';
import ConfirmationDialog from './ConfirmationDialog';

import {
  CasinoMarketplacePurchase,
  GetPlayerCasinoMarketplaceEligibilityInput,
  GetPlayerCasinoMarketplaceEligibilityOutput,
  GetPlayerCasinoMarketplacePurchasesInput,
  GetPlayerCasinoMarketplacePurchasesOutput,
  RefundCasinoMarketplacePurchaseInput,
  UpdatePlayerCasinoMarketplaceEligibilityInput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';

const MARKETPLACE_ELIGIBILITY_OPTIONS = [
  { text: 'default', value: 'default' },
  { text: 'enabled', value: 'enabled' },
  { text: 'disabled', value: 'disabled' },
];

interface VueData {
  apiError: ApiError | null;
  casinoMarketplaceEnabled: string;
  playerCasinoMarketplacePurchases: GetPlayerCasinoMarketplacePurchasesOutput;
  refundPurchaseDialogOpen: boolean;
  refundPurchaseID: string;
  refundPurchaseLoading: boolean;
}

const ITEMS_PER_PAGE = 10;

const PlayerCasinoMarketplacePurchases = Vue.extend({
  props: {
    playerUUID: String,
  },
  data(): VueData {
    return {
      casinoMarketplaceEnabled: 'default',
      playerCasinoMarketplacePurchases: {
        records: [],
        pagination: {
          page: '1',
          totalRecords: '0',
          perPage: `${ITEMS_PER_PAGE}`,
        },
      },
      refundPurchaseDialogOpen: false,
      refundPurchaseID: '',
      refundPurchaseLoading: false,
      apiError: null,
    };
  },
  created() {
    this.loadMarketplaceEligibility();
    this.loadPlayerCasinoMarketplacePurchases('1');
  },
  methods: {
    async loadMarketplaceEligibility() {
      const input: GetPlayerCasinoMarketplaceEligibilityInput = {
        playerUUID: this.playerUUID,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetPlayerCasinoMarketplaceEligibilityInput!) {
            getPlayerCasinoMarketplaceEligibility(input: $input) {
              marketplaceEnabled
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetPlayerCasinoMarketplaceEligibilityOutput = response.data.getPlayerCasinoMarketplaceEligibility;
      this.casinoMarketplaceEnabled = output.marketplaceEnabled;
    },

    async loadPlayerCasinoMarketplacePurchases(page: string) {
      const input: GetPlayerCasinoMarketplacePurchasesInput = {
        playerUUID: this.playerUUID,
        page,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetPlayerCasinoMarketplacePurchasesInput!) {
            getPlayerCasinoMarketplacePurchases(input: $input) {
              records {
                id
                purchaseId
                itemType
                price
                discountedPrice
                details
                createdAt
                state
              }
              pagination {
                page
                totalRecords
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetPlayerCasinoMarketplacePurchasesOutput = response.data.getPlayerCasinoMarketplacePurchases;
      this.playerCasinoMarketplacePurchases = output;
    },
    onPageUpdate(page: number) {
      this.loadPlayerCasinoMarketplacePurchases(`${page}`);
    },
    onRefundPurchase(purchaseID: string) {
      this.refundPurchaseID = purchaseID;
      this.refundPurchaseDialogOpen = true;
    },
    async onRefundPurchaseConfirm(purchaseID: string) {
      try {
        this.refundPurchaseLoading = true;

        const input: RefundCasinoMarketplacePurchaseInput = {
          casinoMarketplacePurchaseId: purchaseID,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: RefundCasinoMarketplacePurchaseInput!) {
              refundCasinoMarketplacePurchase(input: $input) {
                casinoMarketplacePurchaseId
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.apiError = null;
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        if (errResponse != null) {
          this.apiError = {
            url: errResponse.url,
            status: errResponse.status,
            body: errResponse.body,
          };
        }
      } finally {
        await this.loadPlayerCasinoMarketplacePurchases('1');
        this.refundPurchaseLoading = false;
        this.refundPurchaseDialogOpen = false;
      }
    },

    async onUpdateMarketplaceEligibilitySubmit() {
      try {
        const input: UpdatePlayerCasinoMarketplaceEligibilityInput = {
          playerUUID: this.playerUUID,
          casinoMarketplaceEnabled: this.casinoMarketplaceEnabled,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: UpdatePlayerCasinoMarketplaceEligibilityInput!) {
              updatePlayerCasinoMarketplaceEligibility(input: $input) {
                casinoMarketplaceEnabled
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.apiError = null;
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        if (errResponse != null) {
          this.apiError = {
            url: errResponse.errURL,
            status: errResponse.errStatus,
            body: errResponse.errBody,
          };
        }
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Purchase ID',
          value: 'purchaseId',
        },
        {
          text: 'Item Type',
          value: 'itemType',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Discounted Price',
          value: 'discountedPrice',
        },
        {
          text: 'Details',
          value: 'details',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
    scopedSlots() {
      return {
        'item.details': ({ item }: { item: CasinoMarketplacePurchase }) => {
          if (item.details == null) {
            return '';
          }

          const parsed: object = JSON.parse(item.details);

          return (
            <VChipGroup showArrows>
              {
                Object.entries(parsed).map(([key, value]) => {
                  if (typeof value === 'object') {
                    value = JSON.stringify(value);
                  }

                  return (<VChip label>{key}: {value}</VChip>);
                })
              }
            </VChipGroup>
          );
        },
        'item.createdAt': ({ item }: { item: CasinoMarketplacePurchase }) => {
          return new Date(parseInt(item.createdAt, 10) * 1000).toISOString();
        },
        'item.actions': ({ item }: { item: CasinoMarketplacePurchase }) => {
          if (item.state === 'pending' || item.state === 'paid') {
            return (
              <VBtn
                depressed
                color='error'
                onClick={() => { this.onRefundPurchase(item.id); }}
              >
                Refund
              </VBtn>
            );
          }
        },
      };
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    return (
      <VContainer fluid>
        <VRow>
          <VCard>
            <VCardTitle>
              Marketplace Eligibility
            </VCardTitle>
            <VCardText>
              <VRow>
                <VCol align='left'>
                  <h3> {this.casinoMarketplaceEnabled} </h3>
                </VCol>
                <VCol align='right'>
                  <VSelect
                    v-model={this.casinoMarketplaceEnabled}
                    label='Edit Eligibility'
                    items={MARKETPLACE_ELIGIBILITY_OPTIONS}
                    dense
                    outlined
                    onChange={() => this.onUpdateMarketplaceEligibilitySubmit()}
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VRow>
        <VRow>
          <VCol>
            {apiErrorDisplay()}
            <h1>Casino Marketplace Purchases</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VDataTable
                headers={this.headers}
                items={this.playerCasinoMarketplacePurchases.records}
                scopedSlots={this.scopedSlots}
                footerProps={{
                  'items-per-page-options': [ITEMS_PER_PAGE],
                }}
                disableSort={true}
                loadingText='Loading... Please wait'
                loading={false}
                page={parseInt((this.playerCasinoMarketplacePurchases?.pagination?.page || '1'), 10)}
                itemsPerPage={ITEMS_PER_PAGE}
                serverItemsLength={
                  parseInt((this.playerCasinoMarketplacePurchases?.pagination?.totalRecords || '0'), 10)
                }
                on={{
                  'update:page': (page: number) => this.onPageUpdate(page),
                }}
              />
          </VCol>
        </VRow>
        <ConfirmationDialog
          open={this.refundPurchaseDialogOpen}
          title='Refund purchase confirmation'
          loading={this.refundPurchaseLoading}
          on={{
            submit: () => {
              this.onRefundPurchaseConfirm(this.refundPurchaseID);
            },
            cancel: () => {
              this.refundPurchaseDialogOpen = false;
            },
          }}
        />
      </VContainer>
    );
  },
});

export default PlayerCasinoMarketplacePurchases;
