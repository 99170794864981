import Vue from 'vue';
import { VChip, VCard, VIcon } from 'vuetify/lib';
import { SearchNavigationInstructions } from '../SearchNavigationInstructions/SearchNavigationInstructions';
import './SuggestionsCard.less';
export const SuggestionsCard = Vue.extend({
  props: {
    suggestions: Array as () => string[],
    selectedIndex: Number,
  },
  methods: {
    onclose() {
      this.$emit('hide');
    },
  },
  mounted() {
    this.$emit('disableEscOverlay');
  },
  render() {
    const suggestionsChips = this.suggestions.map(
      (suggestionItem, i) => {
        return (
          <div>
            <VChip
              label
              color={i === this.selectedIndex ? 'primary' : '#2c2c2c'}
              class={`${
                i === this.selectedIndex ? 'auto-suggest-selected' : ''
              } auto-suggest-default`}
              key={suggestionItem}
            >
              {suggestionItem}
            </VChip>
          </div>
        );
      },
    );
    return (
      <VCard class='auto-suggest-container' ref='search-suggestion-container'>
        <div class='suggestions'>
          <div class='suggestions-text'>Suggestions</div>

          {suggestionsChips}
        </div>
        <SearchNavigationInstructions />
        <div class='suggestion-close-button'>
          <VIcon onClick={this.onclose} color='red'>
            close
          </VIcon>
        </div>
      </VCard>
    );
  },
});
