import Vue from 'vue';
import { VRow, VSnackbar, VSkeletonLoader, VSimpleTable, VBtn, VCard, VCardTitle, VContainer } from 'vuetify/lib';
import { PendingMarket } from '@/types/sports';
import { mapActions, mapGetters, createNamespacedHelpers } from 'vuex';
import GradeMarket from '@/components/sports/PendingEventsWidget/GradeMarketWidget';
import { marketName } from '../markets-names';

const { mapState: mapEventsState, mapActions: mapEventsActions } = createNamespacedHelpers('sports/events');

export default Vue.extend({
  name: 'pendingMarketsWidget',
  props: {
    eventId: String,
    eventName: String,
  },
  data() {
    return {
      text: '',
      color: '',
      snackbar: false,
      gradingMarket: {} as PendingMarket,
      grading: false,
      isLoading: true,
      headers: [
        { text: 'Market Key', value: 'marketKey' },
        { text: 'Params', value: 'params' },
        { text: 'Pending Selections', value: 'totalNumberOfPendingSelections' },
      ],
    };
  },
  watch: {
    changeResultResult() {
      this.snackbar = true;
      this.color = 'red darken-1';
      this.text = 'Failed to apply changed';
      if (this.changeResultResult.success) {
        this.text = 'Changes applied successfully';
        this.color = 'green darken-1';
      }
    },
    async eventId() {
      await this.getPendingMarkets({ eventId: this.eventId });
      this.loadCurrentEvent(this.eventId);
    },
  },
  mounted() {
    this.getPendingMarkets({ eventId: this.eventId });
    this.loadCurrentEvent(this.eventId);
  },
  methods: {
    ...mapActions({
      getPendingMarketsAction: 'pendingEvents/getPendingMarkets',
    }),
    ...mapEventsActions(['loadCurrentEvent']),
    async getPendingMarkets({ eventId }: { eventId: string }) {
      this.isLoading = true;
      try {
        await this.getPendingMarketsAction({ eventId });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
  },
  computed: {
    ...mapGetters({
      changeResultResult: 'pendingEvents/changeResultResult',
      markets: 'pendingEvents/markets',
      loadingMarket: 'pendingEvents/loadingMarket',
    }),
    ...mapEventsState(['currentEvent']),
  },
  render() {
    const markets = this.markets[this.eventId] as PendingMarket[];
    return (
      <VContainer fluid>
        <VCard>
          <VRow align='center'>
            <VCardTitle>Pending Markets</VCardTitle>
            <VBtn onClick={() => { this.getPendingMarkets({ eventId: this.eventId }); }} primary>
              Refresh
            </VBtn>
          </VRow>
          {!markets || markets.length === 0 ?
            <div>
              {this.loadingMarket && <VSkeletonLoader type='table-tbody' />}
              {!this.loadingMarket && <span>This event has no markets</span>}
            </div>
            :
            <div>
              <VSnackbar
                top={true}
                color={this.color}
                elevation={'24'}
                vModel={this.snackbar} timeout={2000}>
                {this.text}
              </VSnackbar>
              <GradeMarket
                market={this.gradingMarket}
                eventId={this.eventId}
                eventName={this.eventName || ''}
                open={this.grading}
                closeDialog={async () => {
                  this.grading = false;
                  await this.getPendingMarkets({ eventId: this.eventId });
                }}
              />
              <VSimpleTable>
                <thead>
                  <tr>
                    <th>Market</th>
                    <th>Pending Selections</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {[...markets]
                    .sort((a, b) => a.marketKey.localeCompare(b.marketKey))
                    .map((market: PendingMarket) => {
                      const name = this.currentEvent && marketName({
                        marketKey: market.marketKey,
                        marketParams: market.pendingLines[0].params,
                      }, this.currentEvent);

                      return (
                        <tr>
                          <td>{name || market.marketKey}</td>
                          <td>{market.totalNumberOfPendingSelections}</td>
                          <td>
                            <VBtn onClick={() => {
                              this.gradingMarket = market;
                              this.grading = true;
                            }} primary>
                              Grade
                          </VBtn>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </VSimpleTable>
            </div >
          }
        </VCard>
      </VContainer>
    );
  },
});
