import Vue from 'vue';
import { VForm, VTextField, VBtn, VSnackbar } from 'vuetify/lib';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { validateRequired, validateNumber, validateInteger } from '@/utils/validate';

interface PinnacleOverlay {
  lowMargin: string;
  highMargin: string;
  liveMargin: string;
  timeFrame: string;
  placeFactor: string;
}

const validatePlaceFactor = (value: string) => {
  const placeFactor = parseInt(value, 10);
  return placeFactor >= 0 && placeFactor <= 1 || 'must be between 0 and 1';
};

type EntityType = 'event' | 'competition' | 'sport';

export default Vue.extend({
  name: 'PinnacleOverlay',
  props: {
    entityId: Number,
    entityType: String as () => EntityType,
  },

  data() {
    return {
      overlay: null as null | PinnacleOverlay,
      originalOverlay: null as null | PinnacleOverlay,
      failureMessage: null as JSX.Element | null,
      failureSnackbar: false,
      updating: false,
      formValid: false,
    };
  },

  computed: {
    dataChanged(): boolean {
      return !_.isEqual(this.overlay, this.originalOverlay);
    },

    enableSaveButton(): boolean {
      return this.formValid && this.dataChanged;
    },
  },

  methods: {
    async updateOverlay() {
      try {
        this.updating = true;
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($entityType: String!, $entityId: Int!, $pinnacleOverlay: PinnacleOverlayInput) {
            updatePinnacleOverlay(entityType: $entityType, entityId: $entityId, pinnacleOverlay: $pinnacleOverlay) {
              lowMargin
              highMargin
              liveMargin
              timeFrame
              placeFactor
            }
          }`,
          variables: {
            entityType: this.entityType,
            entityId: this.entityId,
            pinnacleOverlay: {
              ..._.pick(this.overlay, ['lowMargin', 'highMargin', 'liveMargin', 'placeFactor']),
              timeFrame: parseInt(this.overlay?.timeFrame as string, 10),
            },
          },
        });

        this.overlay = result.data.updatePinnacleOverlay;
        this.storeOriginalOverlay();
      } catch (e) {
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body) {
          this.showFailure(e.graphQLErrors[0].extensions.response.body);
        } else {
          this.showFailure(e.message);
          throw e;
        }
      } finally {
        this.updating = false;
      }
    },

    showFailure(message: string | string[]) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;
    },

    storeOriginalOverlay() {
      this.originalOverlay = _.clone(this.overlay);
    },
  },

  apollo: {
    overlay: {
      query: gql`query ($entityType: String!, $entityId: Int!) {
        getPinnacleOverlay(entityType: $entityType, entityId: $entityId) {
          lowMargin
          highMargin
          liveMargin
          timeFrame
          placeFactor
        }
      }`,

      variables(): { entityType: string, entityId: number } {
        return {
          entityType: this.entityType,
          entityId: this.entityId,
        };
      },

      update(data) {
        return data.getPinnacleOverlay;
      },

      result() {
        this.storeOriginalOverlay();
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <div>{this.overlay &&
        <VForm class='pa-2' vModel={this.formValid} >
          <VSnackbar timeout='5000' top={true} color='red darken-1' elevation='24' vModel={this.failureSnackbar}>
            {this.failureMessage}
          </VSnackbar>
          <VTextField
            vModel={this.overlay.lowMargin}
            label='Low Margin'
            required
            dense
            outlined
            v-numeric-input
            rules={[validateRequired, validateNumber]}
          />
          <VTextField
            vModel={this.overlay.highMargin}
            label='High Margin'
            required
            dense
            outlined
            v-numeric-input
            rules={[validateRequired, validateNumber]}
          />
          <VTextField
            vModel={this.overlay.liveMargin}
            label='Live Margin'
            required
            dense
            outlined
            v-numeric-input
            rules={[validateRequired, validateNumber]}
          />
          <VTextField
            vModel={this.overlay.timeFrame}
            label='Time Frame'
            required
            dense
            outlined
            v-numeric-input
            rules={[validateRequired, validateNumber, validateInteger]}
          />
          <VTextField
            vModel={this.overlay.placeFactor}
            label='Place Factor'
            required
            dense
            outlined
            v-numeric-input
            rules={[validateRequired, validateNumber, validatePlaceFactor]}
          />
          <VBtn color='primary'
            disabled={!this.enableSaveButton}
            onClick={this.updateOverlay}
            loading={this.updating}>
            Save
          </VBtn>
          <VBtn class='ml-2'
            disabled={!this.dataChanged}
            onClick={() => { this.overlay = _.clone(this.originalOverlay); }} >
            Clear
          </VBtn>
        </VForm>
      }
      </div>
    );
  },
});
