import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { Stream } from '@/streams';

export interface Widget {
  x: number;
  y: number;
  i: number;
  w: number;
  h: number;
  name: string;
  type: 'Stream' | 'Events' | 'EventLiability' | 'EventPlayers' | 'EventCloudbetOpinion' | 'Players' | 'PlayerEvents';
  props: any;
}

export interface Dashboard {
  widgets: Widget[];
}

const getters: GetterTree<Dashboard, any> = {
  dashboard(state: Dashboard): Dashboard {
    return state;
  },
};

const mutations: MutationTree<Dashboard> = {
  UPDATE_DASHBOARD(state: Dashboard, updatedDashboard: Dashboard) {
    state.widgets.length = 0;
    for (const widget of updatedDashboard.widgets) {
      state.widgets.push(widget);
    }
  },

  UPDATE_WIDGET(state: Dashboard, updatedWidget: Widget) {
    state.widgets = state.widgets.map((w) => w.i === updatedWidget.i ? updatedWidget : w);
  },
};

const actions: ActionTree<Dashboard, any> = {
  async getDashboardFromDB({ commit, rootState, rootGetters }) {
    const db: firebase.firestore.Firestore = rootState.db();
    let result = await db.collection('users')
      .doc(rootGetters['auth/userId'])
      .collection('dashboards')
      .get();

    if (result.docs.length === 0) {
      const streamsRef = await db.collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('streams')
        .get();

      let streams: Stream[] = [];

      for (const doc of streamsRef.docs) {
        const data = doc.data();
        streams.push({
          key: doc.id,
          name: data.name,
          sourceKey: data.sourceKey,
          filters: data.filters,
          order: data.order,
        });
      }

      streams = streams.sort((a, b) => a.order! - b.order!);

      const newDashboard: Dashboard = {
        widgets: [],
      };

      for (const i in streams) {
        if (!streams[i].name) { continue; }

        const numberIndex = parseInt(i, 10);
        const stream = streams[i];

        newDashboard.widgets.push({
          i: numberIndex,
          w: 16,
          h: 6,
          x: 0,
          y: numberIndex * 6,
          name: stream.name,
          type: 'Stream',
          props: {
            sourceKey: stream.sourceKey,
            filters: stream.filters,
            variant: 'terminal',
          },
        });
      }

      await rootState
        .db()
        .collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('dashboards')
        .add(newDashboard);

      result = await db.collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('dashboards')
        .get();
    }

    commit('UPDATE_DASHBOARD', result.docs[0].data());
  },
  async updateDashboard({ commit, rootState, rootGetters }, updatedDashboard: Dashboard) {
    const cleanedDashboard: Dashboard = { widgets: [] };
    for (const widget of updatedDashboard.widgets) {
      const { x, y, i, w, h, name, type, props } = widget;
      cleanedDashboard.widgets.push({ x, y, i, w, h, name, type, props } as Widget);
    }

    const db: firebase.firestore.Firestore = rootState.db();
    const result = await db.collection('users')
      .doc(rootGetters['auth/userId'])
      .collection('dashboards')
      .get();

    await result.docs[0].ref.update(cleanedDashboard);

    commit('UPDATE_DASHBOARD', cleanedDashboard);
  },
};

const Dashboard = {
  namespaced: true,
  state: { widgets: [] } as Dashboard,
  getters,
  mutations,
  actions,
};

export default Dashboard;
