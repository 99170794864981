import Vue from 'vue';
import './AddToWatchlist.less';
import {
  VIcon,
  VMenu,
  VListItem,
  VList,
  VCard,
  VBtn,
  VTextField,
  VSnackbar,
} from 'vuetify/lib';
import { mapGetters, mapActions } from 'vuex';
import { DEFAULT_TENNANT, SPORT, CASINO } from '@/config/watchlist';
import { hasRoles } from '@/utils/auth';

export const AddToWatchlist = Vue.extend({
  props: {
    nickname: String,
    playerUUID: String,
  },
  data() {
    return {
      sportWatchlistNames: [] as string[],
      isClicked: false,
      newWatchlistName: '',
      showAddConfirmation: false,
      clickedWatchlist: '',
      hasAddWatchlistError: false,
      hasAddUserError: false,
      product: hasRoles(['sports:operator'])
        ? SPORT
        : hasRoles(['casino:operator'])
        ? CASINO
        : '',
    };
  },
  computed: {
    ...mapGetters({
      watchlists: 'watchlists/watchlists',
    }),
  },
  methods: {
    ...mapActions({
      addNewWatchlist: 'watchlists/addNewWatchlist',
      addUserToWatchlist: 'watchlists/addUserToWatchlist',
      getWatchlists: 'watchlists/getWatchlists',
    }),
    toggleAdd() {
      this.isClicked = !this.isClicked;
    },
    toggleAddConfirmation() {
      this.showAddConfirmation = !this.showAddConfirmation;
    },
    async handleAdd() {
      try {
        await this.addNewWatchlist({
          tennant: DEFAULT_TENNANT,
          watchlistName: this.newWatchlistName,
          product: this.product,
        });
      } catch (err) {
        this.hasAddWatchlistError = true;
      }
    },
  },
  async mounted() {
    await this.getWatchlists({
      tennant: DEFAULT_TENNANT,
      product: this.product,
    });
  },
  render() {
    const newWatchlistInput = (
      <div class='watchlist-input-container pa-4'>
        <VTextField
          vModel={this.newWatchlistName}
          placeholder='Create new watchlist'
          dense
          outlined
          activator='#add'
          error={this.hasAddWatchlistError}
          messages={
            this.hasAddWatchlistError ? 'Insufficient permissions' : null
          }
        />
        <VBtn
          onClick={this.handleAdd}
          icon
          disabled={!this.newWatchlistName.length}
        >
          <VIcon>add</VIcon>
        </VBtn>
      </div>
    );
    return (
      <div>
        <VBtn onClick={this.toggleAdd} small>
          <VIcon id='add'>add</VIcon>
          <div class='text-caption'>Watchlist</div>
        </VBtn>
        {this.isClicked && (
          <VMenu
            class='menu'
            closeOnContentClick={false}
            value={this.isClicked}
            activator='#add'
            closeOnClick
          >
            <VCard class='add-to-watchlist-list-wrapper'>
              {newWatchlistInput}
              <VList>
                {this.watchlists.watchlists?.map((watchlist: any) => {
                  return (
                    <VListItem key={watchlist.watchlistName}>
                      <VBtn
                        onClick={async () => {
                          try {
                            await this.addUserToWatchlist({
                              uuid: this.playerUUID,
                              nickname: this.nickname,
                              tennant: DEFAULT_TENNANT,
                              product: this.product,
                              watchlistName: watchlist.watchlistName,
                            });
                            this.clickedWatchlist = watchlist.watchlistName;
                            this.toggleAddConfirmation();

                            this.isClicked = false;
                          } catch (err) {
                            this.hasAddUserError = true;
                            this.toggleAddConfirmation();
                          }
                        }}
                      >
                        {watchlist.watchlistName}
                      </VBtn>
                    </VListItem>
                  );
                })}
              </VList>
              <VBtn
                onClick={() => {
                  this.isClicked = false;
                }}
                color='gray'
                class='watchlist-card-close ml-4 mr-4'
              >
                Close
              </VBtn>
            </VCard>
          </VMenu>
        )}
        <VSnackbar
          color={this.hasAddUserError ? 'red' : 'green'}
          vModel={this.showAddConfirmation}
        >
          {this.hasAddUserError
            ? 'Insufficient permissions to edit watchlists'
            : `User Added to ${this.clickedWatchlist}`}
        </VSnackbar>
      </div>
    );
  },
});
