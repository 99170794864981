import Vue from 'vue';
import {
  VRow,
  VCol,
  VAutocomplete,
  VBtn,
  VTextField,
  VCardTitle,
  VCardSubtitle,
  VCard,
  VCardText,
  VDataTable,
  VTabsItems,
  VTabs,
  VTab,
  VTabItem,
} from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import {
  SEARCH_EVENTS_QUERY,
  EVENT_QUERY,
  GenericEvent, GenericCompetition,
  COMPETIITON_BY_PROVIDER_PROVIDER_KEY,
  COMPETITOR_BY_PROVIDER_PROVIDER_KEY,
  GenericCompetitor,
} from './helpers';
import {
  iso8601ToDateTimeUTCString,
  dateTimeISOStringToUnix,
  unixToDateTimeUTCString,
} from '@/utils/date';
import _ from 'lodash';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import DateTimePicker from '../EventHeadline/DateTimePicker';
import { EventCreatorCard } from '../EventCreator/EventCreatorCard';
import { hasRoles } from '@/utils/auth';

const { mapActions, mapState } = createNamespacedHelpers('sportsConsts');

export default Vue.extend({
  props: {
    unmatchedEvent: Object as () => UnmatchedEvent,
  },

  data() {
    const dayInSeconds = 24 * 60 * 60;

    return {
      tab: 'matching',
      eventId: '',
      categoryId: 0,
      eventName: '',
      searchEvents: [] as GenericEvent[],
      searchByIDEvents: [] as GenericEvent[],
      searched: true,
      fromTs: (dateTimeISOStringToUnix(this.unmatchedEvent.cutoff) - dayInSeconds).toString(),
      toTs: (dateTimeISOStringToUnix(this.unmatchedEvent.cutoff) + dayInSeconds).toString(),
      eventCreatedID: '',
      createdEvent: null as GenericEvent | null,
      initialCreatorCompetition: null as GenericCompetition | null,
      initialCreatorHomeCompetitor: null as GenericCompetitor | null,
      initialCreatorAwayCompetitor: null as GenericCompetitor | null,
    };
  },

  methods: {
    ...mapActions(['loadSportsConsts']),

    onEventSelected(item: GenericEvent) {
      this.$emit('eventSelected', item);
    },

    onEventCreated(id: string) {
      this.eventCreatedID = id;
    },
  },

  computed: {
    ...mapState(['categories', 'categoriesMap', 'sportsKeysSportsMap', 'categoriesNamesCategoriesMap']),

    shortenEventType(): string {
      return this.unmatchedEvent.eventType.replace('EVENT_TYPE_', '');
    },

    searchByID(): boolean {
      return !!this.eventId;
    },

    searchEnabled(): boolean {
      if (this.searchByID) {
        return true;
      }

      return !!this.eventName;
    },

    events(): GenericEvent[] {
      return this.searchByID ? this.searchByIDEvents : this.searchEvents;
    },

    eventsLoading(): boolean {
      return this.searchByID ?
        this.$apollo.queries.searchByIDEvents.loading :
        this.$apollo.queries.searchEvents.loading;
    },

    filters() {
      return (
        <div class='pa-2'>
          <VRow dense>
            <VCol>
              <VTextField v-numeric-input outlined dense clearable label='ID' vModel={this.eventId} />
            </VCol>
            <VCol>
              <VTextField outlined dense
                label='Sport'
                value={getSportsName(this.unmatchedEvent.sportKey, Locale.en)}
                disabled={true} />
            </VCol>
          </VRow>
          <VRow dense>
            <VCol>
              <VAutocomplete outlined dense chips small-chips deletable-chips
                item-text='name'
                item-value='id'
                label='Category'
                items={this.categories}
                vModel={this.categoryId}
                disabled={this.searchByID} />
            </VCol>
            <VCol>
              <VTextField outlined dense
                label='Name'
                vModel={this.eventName}
                disabled={this.searchByID} />
            </VCol>
          </VRow>
          <VRow dense align='start' justify='start'>
            <VCol cols='1'>
              <strong>From:</strong>
            </VCol>
            <VCol>
              <DateTimePicker dateFormat='unix' vModel={this.fromTs} label='Date from' disabled={this.searchByID} />
            </VCol>
          </VRow>
          <VRow dense align='start' justify='start'>
            <VCol cols='1'>
              <strong>To:</strong>
            </VCol>
            <VCol>
              <DateTimePicker dateFormat='unix' vModel={this.toTs} label='Date to' disabled={this.searchByID} />
            </VCol>
          </VRow>
          <VRow dense>
            <VCol class='text-right'>
              <VBtn
                color='primary'
                onClick={() => this.searched = false}
                disabled={!this.searchEnabled}>Search
              </VBtn>
            </VCol>
          </VRow>
        </div>);
    },

    unmatchedEventSummary() {
      const rows = [];
      if (this.unmatchedEvent.eventType === 'EVENT_TYPE_EVENT') {
        rows.push(
          [<strong>Home:</strong>, this.unmatchedEvent.homeName],
          [<strong>Away:</strong>, this.unmatchedEvent.awayName],
        );
      } else {
        rows.push(
          [<strong>Name:</strong>, this.unmatchedEvent.eventName],
        );
      }
      rows.push(
        [<strong> Competition:</strong>, this.unmatchedEvent.competitionName],
        [<strong>Category:</strong>, this.unmatchedEvent.categoryName],
        [<strong>Start Time:</strong>, iso8601ToDateTimeUTCString(this.unmatchedEvent.cutoff)],
        [<strong>Provider:</strong>, this.unmatchedEvent.provider],
        [<strong>Sport:</strong>, getSportsName(this.unmatchedEvent.sportKey, Locale.en)],
        [<strong>Type:</strong>, this.unmatchedEvent.eventType.replace('EVENT_TYPE_', '')],
      );

      return (
        <div>
          {rows.map((row) => (<VRow dense>
            <VCol cols='4'>{row[0]}</VCol>
            <VCol cols='8'>{row[1]}</VCol>
          </VRow>))}
        </div>
      );
    },

    eventMatch() {
      const nameColumns = this.unmatchedEvent.eventType === 'EVENT_TYPE_EVENT' ? [
        { value: 'homeName', text: 'Home' },
        { value: 'awayName', text: 'Away' }] :
        [{ value: 'name', text: 'Name' }];

      return (
        <VCard elevation={3} class='mt-1'>
          <VCardTitle>
            Generic Events
          </VCardTitle>
          <VCardSubtitle>
            Search and match a generic event
          </VCardSubtitle>
          <VCardText>
            <VRow>
              <VCol>{this.filters}</VCol>
            </VRow>

            <VDataTable
              headers={[
                { value: 'id', text: 'ID' },
                ...nameColumns,
                { value: 'competition', text: 'Competition' },
                { value: 'startsAt', text: 'Start Time' },
                { value: 'sportName', text: 'Sport' },
                { value: 'status', text: 'Status' },
                { value: 'creationProvider', text: 'Creation Provider' },
                { value: 'type', text: 'Type' },
                { value: 'actions', sortable: false },
              ]}
              items={this.events}
              loading={this.eventsLoading}
              scopedSlots={{
                'item.actions': ({ item }: { item: GenericEvent }) => {
                  return <VBtn onClick={() => this.onEventSelected(item)}>Match</VBtn>;
                },
                'item.competition': ({ item }: { item: GenericEvent }) => {
                  return (
                    <div>
                      <div>{item.competition.name}</div>
                      <div><small class='grey--text'>{this.categoriesMap[item.competition.categoryId]}</small></div>
                    </div>
                  );
                },
                'item.startsAt': ({ item }: { item: GenericEvent }) => {
                  return unixToDateTimeUTCString(item.startsAt);
                },
                'item.sportName': ({ item }: { item: GenericEvent }) => {
                  return getSportsName(item.sportKey, Locale.en);
                },
                'item.homeName': ({ item }: { item: GenericEvent }) => {
                  return item.competitors[0]?.name;
                },
                'item.awayName': ({ item }: { item: GenericEvent }) => {
                  return item.competitors[1]?.name;
                },
                'item.creationProvider': ({ item }: { item: GenericEvent }) => {
                  return item.providersData[0]?.provider;
                },
              }} />
          </VCardText>
        </VCard>
      );
    },

    tabs() {
      return [
        {
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'matching',
          name: 'Event Matching',
          content: <div>{this.eventMatch}</div>,
        },
        {
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'creator',
          name: 'Event Creator',
          content: (
            <EventCreatorCard
              initialCompetition={this.initialCreatorCompetition}
              initialHomeCompetitor={this.initialCreatorHomeCompetitor}
              initialAwayCompetitor={this.initialCreatorAwayCompetitor}
              initialSport={this.sportsKeysSportsMap[this.unmatchedEvent.sportKey]}
              initialCategory={this.categoriesNamesCategoriesMap[this.unmatchedEvent.categoryName.toLowerCase()]}
              onEventCreated={
                (eventCreatedID: number) => {
                  this.onEventCreated(eventCreatedID.toString());
                }
              } />
          ),
        },
      ];
    },
  },

  apollo: {
    searchEvents: {
      query: SEARCH_EVENTS_QUERY,
      variables(): { name: string, sportKey: string, categoryId: number, fromTs: number, toTs: number } {
        return {
          name: this.eventName,
          sportKey: this.unmatchedEvent.sportKey,
          categoryId: this.categoryId,
          fromTs: parseInt(this.fromTs, 10),
          toTs: parseInt(this.toTs, 10),
        };
      },
      update(data) {
        const events = data.searchEvents.events.
          filter((event: GenericEvent) => event.type === this.shortenEventType);
        return _.orderBy(events, ['startsAt'], ['desc']);
      },

      result() {
        this.searchByIDEvents = [];
        this.searched = true;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return this.searchByID || this.searched;
      },
    },

    searchByIDEvents: {
      query: EVENT_QUERY,
      variables(): { eventId: string } {
        return { eventId: this.eventId };
      },
      update(data) {
        return [data.event];
      },

      result() {
        this.searchEvents = [];
        this.searched = true;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return !this.searchByID || this.searched;
      },
    },

    createdEvent: {
      query: EVENT_QUERY,
      variables(): { eventId: string } {
        return { eventId: this.eventCreatedID };
      },

      update(data) {
        this.$emit('eventCreated', data.event);
        return data.event;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return !this.eventCreatedID;
      },
    },

    initialCreatorCompetition: {
      query: COMPETIITON_BY_PROVIDER_PROVIDER_KEY,
      variables(): { provider: string, providerKey: string } {
        return { provider: this.unmatchedEvent.provider, providerKey: this.unmatchedEvent.providerCompetitionKey };
      },

      fetchPolicy: 'network-only',

      update(data) {
        return data.getCompetitionByProviderProviderKey;
      },

      error() {
        // do not handle error
      },
    },

    initialCreatorHomeCompetitor: {
      query: COMPETITOR_BY_PROVIDER_PROVIDER_KEY,
      variables(): { provider: string, providerKey: string } {
        return { provider: this.unmatchedEvent.provider, providerKey: this.unmatchedEvent.providerHomeKey };
      },

      fetchPolicy: 'network-only',

      update(data) {
        return data.getCompetitorByProviderProviderKey;
      },

      error() {
        // do not handle error
      },
    },

    initialCreatorAwayCompetitor: {
      query: COMPETITOR_BY_PROVIDER_PROVIDER_KEY,
      variables(): { provider: string, providerKey: string } {
        return { provider: this.unmatchedEvent.provider, providerKey: this.unmatchedEvent.providerAwayKey };
      },

      fetchPolicy: 'network-only',

      update(data) {
        return data.getCompetitorByProviderProviderKey;
      },

      error() {
        // do not handle error
      },
    },
  },

  mounted() {
    this.loadSportsConsts();
  },

  render() {
    return (
      <VRow>
        <VCol cols='3'>
          <VCard elevation={3} class='mt-1 mb-2'>
            <VCardTitle>
              Event Summary
            </VCardTitle>
            <VCardText>
              {this.unmatchedEventSummary}
            </VCardText>
          </VCard>
        </VCol>
        <VCol cols='9'>
          <VTabs vModel={this.tab}>
            {this.tabs
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab: any) => (
                <VTab key={tab.key}>
                  {tab.name}
                </VTab>
              ))}
          </VTabs>
          <VTabsItems vModel={this.tab}>
            {this.tabs
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab: any) => (
                <VTabItem key={tab.key}>{tab.content}</VTabItem>
              ))}
          </VTabsItems>
        </VCol>
      </VRow>
    );
  },
});
