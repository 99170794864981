// Languages supported by front end
export enum MessageKey {
  de = 'de',
  el = 'el',
  en = 'en',
  es = 'es',
  fr = 'fr',
  hi = 'hi',
  hu = 'hu',
  id = 'id',
  it = 'it',
  ja = 'ja',
  ko = 'ko',
  lv = 'lv',
  mt = 'mt',
  nl = 'nl',
  pl = 'pl',
  pt_br = 'pt_br',
  pt = 'pt',
  ru = 'ru',
  sv = 'sv',
  th = 'th',
  tr = 'tr',
  zh_cn = 'zh_cn',
  zh_tw = 'zh_tw',
}

export enum Scope {
  all = 'all',
  casino = 'casino',
  sports = 'sports',
}
export interface Message {
  __typename: string;
  key: MessageKey;
  value: string;
}

export interface MessageUpdatedAt {
  __typename: string;
  key: MessageKey;
  value: string;
}

export enum AnnouncementType {
  success = 'success',
  info = 'info',
  warn = 'warn',
  error = 'error',
}

export interface Announcement {
  __typename: string;
  uuid: string;
  type: AnnouncementType;
  name: string;
  messages: Message[];
  enable: boolean;
  messagesUpdatedAt: MessageUpdatedAt[];
  scope: Scope;
}
