import Vue from 'vue';
import { VContainer, VTextField } from 'vuetify/lib';

interface Props {
  playerUUID?: string;
}

export default Vue.extend({
  props: {
    props: Object as () => Props,
  },
  render() {
    return (
      <VContainer class='dashboard-widget-subform'>
        <VTextField v-model={this.props.playerUUID} label='Player UUID' />
      </VContainer>
    );
  },
});
