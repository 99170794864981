import { CompetitionPriorityGroup } from '@/types/sports';

const MIN_PRIORITY = 0;
const MAX_PRIORITY = 100;

const countriesTextList = (group: CompetitionPriorityGroup) => {
  return group.countries.join(', ');
};

const backgroundColor = (record: Record<string, any>, field: string, colour = 'warning') => {
  const originalField = '_' + field;
  if (!record.hasOwnProperty(originalField) || record[field] !== record[originalField]) {
    return colour;
  }
};

const successSnackbarConfig = {
  props: {
    timeout: '1000',
    top: true,
    color: 'green darken-1',
    elevation: '24',
  },
  scopedSlots: {
    default: () => 'Priorities have been updated.',
  },
};

const failureSnackbarConfig = {
  props: {
    timeout: '2000',
    top: true,
    color: 'red darken-1',
    elevation: '24',
  },
  scopedSlots: {
    default: () => 'Priorities have not been updated.',
  },
};

export {
  MIN_PRIORITY,
  MAX_PRIORITY,
  countriesTextList,
  backgroundColor,
  failureSnackbarConfig,
  successSnackbarConfig,
};
