import Vue from 'vue';

export default Vue.extend({
  name: 'eventMedia',
  mounted() {
    this.$router.replace({
      name: this.$route.params.entityType,
      params: { [`${this.$route.params.entityType}Id`]: this.$route.params.entityKey },
    });
  },
});
