import Vue from 'vue';
import { gql } from 'apollo-boost';
import SearchDataTable from './SearchDataTable';
import { VCard, VDataTable, VChip } from 'vuetify/lib';
import { RouterLink } from '@/router';
import { playerTagConfig } from '@/config/playerTagConfig';

export const SearchNicknameDataTable = Vue.extend({
  props: {
    searchText: String,
  },

  data() {
    return {
      hasError: false,
      playersResult: [],
      headers: [
        { text: 'Nickname', value: 'nickname' },
        { text: 'Id', value: 'id', width: 100 },
        { text: 'UUID', value: 'uuid' },
        { text: 'Tags', value: 'tags' },
      ],
    };
  },

  methods: {
    links(props: { item: { uuid: string } }): JSX.Element {
      return (
        <RouterLink
          class='text-decoration-none'
          to={{ name: 'player', params: { playerUUID: props.item.uuid } }}
        >
          {props.item.uuid}
        </RouterLink>
      );
    },
    tags(props: { item: { tags: string[] } }): JSX.Element {
      return (
        <div>
          {props.item.tags?.map((tag) => (
            <VChip class={`ma-1 ${playerTagConfig[tag]?.color}`} small label>
              {tag}
            </VChip>
          ))}
        </div>
      );
    },
  },

  computed: {
    effectiveSlots(): object {
      return {
        'item.uuid': this.links,
        'item.tags': this.tags,
      };
    },
  },

  apollo: {
    playersResult: {
      query: gql`
        query($nickname: String!) {
          searchPlayerByNickname(nickname: $nickname) {
            players {
              uuid
              id
              nickname
              tags
            }
          }
        }
      `,
      error(error) {
        if (error) {
          this.hasError = true;
        }
      },
      variables(): { nickname: string } {
        return { nickname: this.searchText || '' };
      },
      update(data) {
        return data.searchPlayerByNickname.players;
      },
      skip() {
        return !this.searchText;
      },
      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <div>
        <VDataTable
          class='elevation-1'
          items={this.playersResult || []}
          headers={this.headers}
          loading-text='Searching... Please wait'
          loading={this.$apollo.queries.playersResult.loading}
          scopedSlots={this.effectiveSlots}
        />
      </div>
    );
  },
});
