import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { CompetitionWithProviders } from '@/types/sports';
import { gql } from 'apollo-boost';


export enum CompetitionMatchingStatus {
  manualMatched = 'MANUAL_MATCHED',
  unmatched = 'UNMATCHED',
  autoMatched = 'AUTO_MATCHED',
  autoCreated = 'AUTO_CREATED',
}

interface CompetitionsState {
  currentCompetition?: CompetitionWithProviders;
  isLoadingID?: number;
}

const getters: GetterTree<CompetitionsState, any> = {
  isLoading(state) {
    return state.isLoadingID ? true : false;
  },
};

const mutations: MutationTree<CompetitionsState> = {
  SET_CURRENT_COMPETITION(state, data: CompetitionWithProviders) {
    state.currentCompetition = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoadingID = data;
  },
};

const actions: ActionTree<CompetitionsState, any> = {
  async loadCurrentCompetition({ commit, state, rootState }, competitionId: number) {
    if (state.currentCompetition?.id === competitionId || state.isLoadingID === competitionId) {
      return;
    }

    commit('SET_IS_LOADING', competitionId);

    try {
      const client = rootState.apolloClient();
      const response = await client.query({
        query: gql`query Competition($competitionId: String!) {
          competition(id: $competitionId, withProvider: true) {
            categoryId
            id
            seoKey
            key
            providers {
              keys
              liveAvailable
              matchedAt
              provider
              providerName
              matchingStatus
            }
            name
            sportId
            sportKey
            streams {
              provider
              url
              language
            }
          }
        }`,
        variables: { competitionId: competitionId.toString() },
      });

      commit('SET_CURRENT_COMPETITION', response.data.competition);
    } finally {
      commit('SET_IS_LOADING', undefined);
    }
  },
  async matchCompetition(
    { rootState, dispatch },
    { competitionId, provider, providerKey }) {
    const client = rootState.apolloClient();

    await client.mutate({
      mutation: gql`mutation MatchProviderCompetition($input: GenericEntityToProviderEntityIdentifierInput!) {
          matchProviderCompetition(input: $input)
        }`,
      variables: {
        input: {
          genericEntityId: competitionId,
          provider,
          providerKey,
        },
      },
    });
  },
  async unmatchCompetition(
    { rootState, dispatch },
    { competitionId, provider, providerKey }) {
    const client = rootState.apolloClient();

    await client.mutate({
      mutation: gql`mutation UnmatchProviderCompetition($input: GenericEntityToProviderEntityIdentifierInput!) {
        unmatchProviderCompetition(input: $input)
      }`,
      variables: {
        input: {
          genericEntityId: competitionId,
          provider,
          providerKey,
        },
      },
    });
  },
};


export default {
  namespaced: true,
  state: {
    currentCompetition: undefined,
    isLoadingID: undefined,
  } as CompetitionsState,
  getters,
  mutations,
  actions,
};
