// views/payments/FiatDeposits.tsx
import Vue from 'vue';
import { VList, VListItem, VListItemTitle, VSelect, VTextField } from 'vuetify/lib';
import { FiatDepositsWidget } from '@/components/payments/FiatDepositsWidget';
import './FiatDeposits.less';

const PAGE_SIZE_OPTIONS = [10, 20, 50];
const STATE_OPTIONS = ['pending', 'confirmed', 'cancelled', 'rejected', 'all'];

export default Vue.extend({
  name: 'fiatDepositsWidget',
  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || PAGE_SIZE_OPTIONS[0],
      state: this.$route.query.state || STATE_OPTIONS[0],
      playerUUID: this.$route.query.playerUUID || '',
    };
  },
  watch: {
    playerUUID(val, _) {
      this.onPlayerUUIDChange(val);
    },
  },
  methods: {
    onStateChangeHandler() {
      if (this.$route.query.state !== this.state) {
        this.$router.push({
          query: {
            page: '1',
            pageSize: this.pageSize.toString(),
            state: this.state,
            playerUUID: this.playerUUID,
          },
        });
        this.page = 1;
        window.scrollTo(0, 0);
      }
    },
    onPlayerUUIDChange(playerUUID: string) {
      if (this.$route.query.playerUUID !== playerUUID) {
        this.$router.push({
          query: {
            page: '1',
            pageSize: this.pageSize.toString(),
            state: this.state,
            playerUUID: this.playerUUID,
          },
        });
        this.page = 1;
        window.scrollTo(0, 0);
      }
    },
    handlePagination(page: number, pageSize: number): any {
      if (this.$route.query.page !== String(page) || this.$route.query.pageSize !== String(pageSize)) {
        this.$router.push({
          query: {
            page: page.toString(),
            pageSize: pageSize.toString(),
            state: this.state,
            playerUUID: this.playerUUID,
          },
        });
        this.page = page;
        this.pageSize = pageSize;
        window.scrollTo(0, 0);
      }
    },
  },
  render() {
    return (
      <div>
        <VList>
          <VListItem>
            <VListItemTitle>Fiat Deposits</VListItemTitle>
            <div class='uuid-filter-container'>
              <VTextField
                hide-details
                v-model={this.playerUUID}
                placeholder='Player UUID'
                class='input'
                dense
                outlined
              />
            </div>
            <VSelect
              items={STATE_OPTIONS}
              hint='state'
              dense persistent-hint={true}
              return-object={true}
              v-model={this.state}
              onInput={this.onStateChangeHandler}
            />
          </VListItem>
        </VList>
        <FiatDepositsWidget
          page = {this.page}
          pageSize = {this.pageSize}
          onPageUpdate = {this.handlePagination}
          footerProps = {{'items-per-page-options': PAGE_SIZE_OPTIONS}}
          state = {this.state}
          playerUUID = {this.playerUUID}
        />
      </div>
    );
  },
});
