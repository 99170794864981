import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { gql } from 'apollo-boost';

export interface UnmatchedEvent {
  awayName: string;
  categoryName: string;
  competitionName: string;
  cutoff: string;
  eventId: string;
  eventName: string;
  eventType: string;
  homeName: string;
  teamSwapped: boolean;
  provider: string;
  providerEventKey: string;
  providerCompetitionKey: string;
  providerHomeKey: string;
  providerAwayKey: string;
  sportKey: string;
  status: UnmatchedEventStatus;
}

export enum UnmatchedEventStatus {
  unmatched = 'PROVIDER_EVENT_REGISTRY_STATUS_UNMATCHED',
  registered = 'PROVIDER_EVENT_REGISTRY_STATUS_REGISTERED',
  manualMatched = 'PROVIDER_EVENT_REGISTRY_STATUS_MANUAL_MATCHED',
  manualUnmatched = 'PROVIDER_EVENT_REGISTRY_STATUS_MANUAL_UNMATCHED',
  autoMatched = 'PROVIDER_EVENT_REGISTRY_STATUS_AUTO_MATCHED',
  autoCreated = 'PROVIDER_EVENT_REGISTRY_STATUS_AUTO_CREATED',
}

export const requiresMatchingStatus = [
  UnmatchedEventStatus.unmatched,
  UnmatchedEventStatus.registered,
  UnmatchedEventStatus.manualUnmatched,
];

export interface UnmatchedEventsFilters {
  sports: string[];
  providers: string[];
  status: UnmatchedEventStatus[];
  type?: string;
  fromTs: number;
  toTs: number;
  isWorkflowProcessed?: boolean;
}

interface GetRegistryEventsInput extends UnmatchedEventsFilters {
  matched: boolean;
  offset: number;
  limit: number;
}

interface MatchProviderEventInput {
  eventId: number;
  provider: string;
  providerEventKey: string;
  teamSwapped: boolean;
}

interface UnmatchedEventsStore {
  events: UnmatchedEvent[];
  loadedPages: number;
  loading: boolean;
  offset: number;
  totalPages: null | number;
  unmatchedEventFilters: null | UnmatchedEventsFilters;
}

const pageSize = 25;

const getters: GetterTree<UnmatchedEventsStore, any> = {
  events(state): UnmatchedEvent[] {
    return state.events;
  },

  loading(state): boolean {
    return state.loading;
  },

  totalPages(state): number | null {
    return state.totalPages;
  },

  loadedPages(state): number {
    return state.loadedPages;
  },

  unmatchedEventFilters(state): null | UnmatchedEventsFilters {
    return state.unmatchedEventFilters;
  },
};

const mutations: MutationTree<UnmatchedEventsStore> = {
  LOADING(state, loading: boolean) {
    state.loading = loading;
  },

  CLEAR_EVENTS(state) {
    state.events = [];
    state.offset = 0;
    state.loading = false;
    state.loadedPages = 0;
    state.totalPages = 0;
  },

  ADD_EVENTS(state, events: UnmatchedEvent[]) {
    if (events.length === 0) {
      state.totalPages = state.loadedPages;
      return;
    }

    state.events = [...state.events, ...events];
    state.loadedPages++;
    if (events.length < pageSize) {
      state.totalPages = state.loadedPages;
    }
    state.offset = state.events.length;
  },

  REMOVE_EVENT(state, { provider, providerEventKey }) {
    state.events = state.events.filter((e) =>
      e.provider !== provider || e.providerEventKey !== providerEventKey);
  },

  ADD_EVENT(state, event: UnmatchedEvent) {
    state.events = [event, ...state.events];
  },

  UPDATE_FILTERS(state, filters: UnmatchedEventsFilters) {
    state.unmatchedEventFilters = { ...state.unmatchedEventFilters, ...filters };
  },
};

const actions: ActionTree<UnmatchedEventsStore, any> = {
  async loadEvents({ commit, rootState, state }) {
    commit('LOADING', true);

    try {
      const client = rootState.apolloClient();
      const response = await client.query({
        query: gql`query ($params: GetRegistryEventsInput!) {
          getRegistryEvents(params: $params) {
            events {
              awayName
              categoryName
              competitionName
              cutoff
              eventId
              eventName
              eventType
              homeName
              teamSwapped
              provider
              providerEventKey
              providerCompetitionKey
              providerHomeKey
              providerAwayKey
              sportKey
              status
            }
          }
        }`,
        variables: {
          params: {
            ...state.unmatchedEventFilters,
            matched: false,
            isWorkflowProcessed: true,
            limit: pageSize,
            offset: state.offset,
          } as GetRegistryEventsInput,
        },
      });

      commit('ADD_EVENTS', response.data.getRegistryEvents.events);
    } catch (e) {
      throw e;
    } finally {
      commit('LOADING', false);
    }
  },

  async matchEvents({ commit, rootState }, input: MatchProviderEventInput) {
    try {
      const client = rootState.apolloClient();
      await client.mutate({
        mutation: gql`
          mutation ($input: MatchProviderEventInput!) {
            matchProviderEvent(input: $input)
          }`,
        variables: {
          input,
        },
      });

      commit('REMOVE_EVENT', { provider: input.provider, providerEventKey: input.providerEventKey });
    } catch (e) {
      throw e;
    }
  },

  async unmatchEvent({ commit, rootState }, event: UnmatchedEvent) {
    try {
      const client = rootState.apolloClient();
      await client.mutate({
        mutation: gql`
          mutation ($input: UnmatchProviderEventInput!) {
            unmatchProviderEvent(input: $input)
          }`,
        variables: {
          input: {
            eventId: event.eventId,
            provider: event.provider,
            providerEventKey: event.providerEventKey,
          },
        },
      });

      commit('ADD_EVENT', event);
    } catch (e) {
      throw e;
    }
  },
};

const unmatchedEventsStore = {
  namespaced: true,
  state: {
    events: [],
    loadedPages: 0,
    loading: false,
    offset: 0,
    totalPages: null,
    unmatchedEventFilters: null,
  } as UnmatchedEventsStore,
  getters,
  actions,
  mutations,
};

export default unmatchedEventsStore;
