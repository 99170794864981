<template>
  <v-row align="center" justify="center">
    <v-col cols="12" sm="8" md="4">
      <v-card outlined>
        <v-card-title>Sign in to Zero</v-card-title>
        <v-btn light class="ma-2" outlined color="primary" @click="signIn()">
          <GoogleIcon class="icon" />Sign in with Google
        </v-btn>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import Vue from 'vue';
import { mapActions } from 'vuex';
import GoogleIcon from '@/components/GoogleIcon.vue';
import JokerIcon from '@/components/JokerIcon.vue';

import store from '@/store';
export default Vue.extend({
  components: {
    GoogleIcon,
    JokerIcon,
  },
  data: () => ({}),
  methods: {
    ...mapActions({
      googleSignIn: 'auth/googleSignIn',
      showErrorSnackbar: 'snackbar/showErrorSnackbar',
    }),
    async signIn() {
      const result = await this.googleSignIn();
      if (result.error) {
        this.showErrorSnackbar(`Failed to sign in \n ${result.error}`);
      }
    },
  },
});
</script>

<style>
.icon {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
</style>
