import Vue from 'vue';
import { VBtn, VIcon, VDivider, VChip } from 'vuetify/lib';
import './WatchlistItem.less';
import { mapActions } from 'vuex';
import { RouterLink } from '@/router';
import { gql } from 'apollo-boost';
import {
  PlayerBetActivityProps,
  formatSummaryValues,
} from '../PlayerBetActivity/PlayerBetActivity';
import { DEFAULT_TENNANT, SPORT, CASINO } from '@/config/watchlist';
import { hasRoles } from '@/utils/auth';
import { BetActivitySparkLine } from '../sports/BetActivitySparkline/BetActivitySparkline';
import { financeValueClass } from '@/utils/financeValueClass';
import { daysElapsed } from '@/utils/daysElapsed';

export const WatchlistItem = Vue.extend({
  props: {
    playerUUID: String,
    playerName: String,
    watchlistName: String,
  },
  data() {
    return {
      dataError: {},
      isActivityLoading: true,
      playerBetActivity: {} as PlayerBetActivityProps,
      hasDeleteUserError: false,
      product: hasRoles(['sports:operator'])
        ? SPORT
        : hasRoles(['casino:operator'])
        ? CASINO
        : '',
    };
  },
  computed: {
    sportSummary(): {
      turnover: string;
      numberBets: string;
      averageBetSize: string;
      margin: string;
      pnl: string;
      lastTransactionDate: string;
    } {
      if (this.playerBetActivity?.sports) {
        return formatSummaryValues(this.playerBetActivity.sports, 2);
      }
      return {
        turnover: '',
        numberBets: '',
        averageBetSize: '',
        margin: '',
        pnl: '',
        lastTransactionDate: '',
      };
    },
  },
  methods: {
    ...mapActions({
      deleteUser: 'watchlists/deleteUserFromWatchlist',
    }),
    async onDelete() {
      try {
        await this.deleteUser({
          tennant: DEFAULT_TENNANT,
          product: this.product,
          playerUUID: this.playerUUID,
          watchlistName: this.watchlistName,
        });
      } catch (err) {
        this.hasDeleteUserError = true;
      }
    },
  },
  apollo: {
    playerBetActivity: {
      query: gql`
        query($uuid: String!) {
          playerBetActivity(uuid: $uuid) {
            sports {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
              lastTransactionDate
            }
            casino {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
              lastTransactionDate
            }
            sportsBetsDailyActivity {
              revenue
              date
            }
            casinoBetsDailyActivity {
              revenue
              date
            }
          }
        }
      `,
      variables(): { uuid: string } {
        return { uuid: this.playerUUID };
      },
      update(data) {
        if (data.playerBetActivity) {
          this.isActivityLoading = false;
          return data.playerBetActivity;
        }
      },
      error(error) {
        this.dataError = error;
      },
      watchLoading(isLoading) {
        this.isActivityLoading = isLoading;
      },
    },
  },
  render() {
    if (this.playerUUID && this.playerName && this.watchlistName) {
      return (
        <div class='watchlist-item-grid'>
          <div class='sparkline mr-4'>
            <BetActivitySparkLine playerUUID={this.playerUUID} />
          </div>
          <RouterLink
            class='text-decoration-none mr-1 item-nickname'
            to={{ name: 'player', params: { playerUUID: this.playerUUID } }}
          >
            {this.playerName || this.playerUUID}
          </RouterLink>

          <VChip
            small
            label
            outlined
            color={
              daysElapsed(
                new Date(),
                new Date(this.sportSummary?.lastTransactionDate),
              ) <= 7
                ? 'green'
                : daysElapsed(
                    new Date(),
                    new Date(this.sportSummary?.lastTransactionDate),
                  ) <= 13
                ? 'orange'
                : 'red'
            }
            class={`mr-1 item-last-active text-caption`}
          >
            {this.sportSummary?.lastTransactionDate &&
              new Date(
                this.sportSummary?.lastTransactionDate,
              ).toLocaleDateString()}
          </VChip>
          <div class='mr-1 item-averagebetsize'>
            {this.sportSummary?.averageBetSize}
          </div>
          <div class='mr-1 item-turnover'>{this.sportSummary.turnover}</div>
          <div
            class={`mr-1 item-pnl ${financeValueClass(this.sportSummary.pnl)}`}
          >
            {this.sportSummary.pnl}
          </div>
          <div
            class={`mr-1 item-margin ${financeValueClass(
              this.sportSummary.margin,
            )}`}
          >
            {this.sportSummary.margin}
          </div>
          <div class={`mr-1 item-totalbets`}>
            {this.sportSummary?.numberBets}
          </div>
          <div class='item-delete'>
            {hasRoles(['sports:operator', 'casino:operator']) && (
              <VBtn icon onClick={this.onDelete}>
                {
                  <VIcon color='red' size='medium'>
                    delete
                  </VIcon>
                }
              </VBtn>
            )}
          </div>
          <div class='item-row mb-1 mt-1'>
            <VDivider />
          </div>
        </div>
      );
    }
    return <div class='watchlist-item-container'>no user</div>;
  },
});
