<template>
  <v-app id="inspire">
    <Snackbar />
    <NavigationDrawer :items="items" :drawer="drawer" />
    <AppBar :toggleDrawer="toggleDrawer" />
    <v-content>
      <v-container class="fill-height" fluid>
        <router-view />
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import NavigationDrawer from '@/components/NavigationDrawer';
import AppBar from '@/components/AppBar';
import Snackbar from '@/components/Snackbar';
export default {
  props: {
    source: String,
  },
  components: {
    NavigationDrawer,
    AppBar,
    Snackbar,
  },
  data: () => ({
    drawer: false,
    items: [{ icon: 'lock_open', text: 'Signin', link: '/signin' }],
  }),
  methods: {
    toggleDrawer() {
      this.drawer = !this.drawer;
    },
  },
};
</script>
