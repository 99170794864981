import { Stream } from '@/streams';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

const getters: GetterTree<Stream[], any> = {
  streams: (state) => {
    return state;
  },
};

const mutations: MutationTree<Stream[]> = {
  ADD_STREAM: (state, payload) => {
    state.push(payload);
  },
  UPDATE_STREAM: (state, payload) => {
    const index = state.findIndex((stream) => stream.key === payload.key);
    if (index > -1) {
      state.splice(index, 1, payload);
    }
  },
  REMOVE_STREAM: (state, key) => {
    const index = state.findIndex((stream) => stream.key === key);
    if (index > -1) {
      state.splice(index, 1);
    }
  },
  CLEAR: (state) => {
    state.length = 0;
  },
};

const actions: ActionTree<Stream[], any> = {
  async getFromDb({ commit, rootState, rootGetters }) {
    const db: firebase.firestore.Firestore = rootState.db();
    const result = await db.collection('users')
      .doc(rootGetters['auth/userId'])
      .collection('streams')
      .get();

    commit('CLEAR');

    let streams: Stream[] = [];

    // order backfill. Remove it later.
    let order = 0;
    const backfillOrder = result.docs[0] && result.docs[0].data().order === undefined;
    // end of backfill

    for (const doc of result.docs) {
      const data = doc.data();

      // order backfill. Remove it later.
      if (backfillOrder) {
        await doc.ref.update({ order: order++ });
      }
      // end of backfill

      streams.push({
        key: doc.id,
        name: data.name,
        sourceKey: data.sourceKey,
        filters: data.filters,
        order: data.order,
      });
    }

    streams = streams.sort((a, b) => a.order! - b.order!);

    for (const stream of streams) {
      commit('ADD_STREAM', stream);
    }
  },
  async save({ commit, rootState, rootGetters }, stream: Stream) {
    // we don't want to save document keys in document itself, but we want to deepCopy payload
    stream = JSON.parse(JSON.stringify(stream)); // deepCopy

    const docKey = stream.key;
    delete stream.key;

    if (docKey) {
      await rootState
        .db()
        .collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('streams')
        .doc(docKey)
        .set(stream);
      commit('UPDATE_STREAM', { ...stream, key: docKey });
    } else {
      stream.order = 0;

      const latestStreamQuery = await rootState
        .db().collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('streams')
        .orderBy('order', 'desc')
        .limit(1)
        .get();

      if (latestStreamQuery.docs[0]) {
        stream.order = latestStreamQuery.docs[0].data().order! + 1;
      }

      const result = await rootState
        .db()
        .collection('users')
        .doc(rootGetters['auth/userId'])
        .collection('streams')
        .add(stream);

      stream.key = result.id;
      commit('ADD_STREAM', stream);
    }
  },
  async remove({ commit, rootState, rootGetters }, stream: Stream) {
    await rootState
      .db()
      .collection('users')
      .doc(rootGetters['auth/userId'])
      .collection('streams')
      .doc(stream.key)
      .delete();
    commit('REMOVE_STREAM', stream.key);
  },
};

const Streams = {
  namespaced: true,
  state: [] as Stream[],
  getters,
  mutations,
  actions,
};

export default Streams;
