import { validateNumber, validateRequired, validateInteger } from '@/utils/validate';
import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VCol,
  VDialog,
  VForm,
  VRow,
  VSelect,
  VTextField,
  VAlert,
  VSwitch,
  VOverlay,
  VProgressCircular,
  VDatePicker,
  VMenu,
} from 'vuetify/lib';
import { FixedSchedule, RewardsScheme } from './LoyaltySchedules';
import { required, numeric } from 'vuelidate/lib/validators';

function newFormInput(schedule: FixedSchedule) {
  return {
    id: schedule.id,
    name: schedule.name,
    createdAt: schedule.createdAt,
    playerCount: schedule.playerCount,
    minPrice: schedule.minPrice || '1.7',
    percentage: schedule.percentage || '10',
    rolloverRequirement: schedule.rolloverRequirement || '5',
    scheme: schedule.scheme || RewardsScheme.Revenue,
    scheduleInDays: schedule.scheduleInDays || 7,
    disabled: schedule.disabled || false,
    scheduleStartDate: (
      schedule.scheduleStartDate ?
        schedule.scheduleStartDate :
        (new Date()).toISOString()
    ).substr(0, 10),
  };
}

export const LoyaltyScheduleDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    error: String,
    schedule: Object as () => FixedSchedule,
    schedules: Array as () => FixedSchedule[],
  },

  data() {
    return {
      input: newFormInput(this.schedule),
      loading: false,
      scheduleStartDateIsOpen: false,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSave() {
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit('save', this.input);
      }
    },

    validateAlreadyTaken(input: string) {
      return !this.schedules.filter((s) => s.id !== this.schedule.id)
                            .filter((s) => s.name === input).length || 'already taken';
    },
  },

  computed: {
    form() {
      return <VForm ref='form'>
        <VTextField
          label='Name'
          dense
          outlined
          vModel={this.input.name}
          rules={[validateRequired, this.validateAlreadyTaken]}
          disabled={!!this.input.id}
        />

        <VMenu
          vModel={ this.scheduleStartDateIsOpen }
          closeOnContentClick={ false }
          nudgeRight={ 40 }
          transition='scale-transition'
          offsetY
          minWidth='auto'
          scopedSlots={{
            activator: ({ on, attrs }: any) => {
              return <VTextField
                vModel={ this.input.scheduleStartDate }
                label='Start date'
                readonly
                dense
                outlined
                bind={ attrs }
                on={ on }
              ></VTextField>;
            },
          }}
        >
          <VDatePicker
            vModel={ this.input.scheduleStartDate }
            onInput={ () => this.scheduleStartDateIsOpen = false }
          ></VDatePicker>
        </VMenu>

        <VTextField
          label='Every'
          suffix={'days'}
          dense
          outlined
          rules={[validateInteger]}
          vModel={this.input.scheduleInDays}
        />

        <VSelect
          items={Object.values(RewardsScheme)}
          label='Scheme'
          dense
          outlined
          vModel={this.input.scheme}
        />

        <VTextField
          label='Percentage'
          suffix={'%'}
          dense
          outlined
          rules={[validateNumber]}
          vModel={this.input.percentage}
        />

        <VTextField
          vModel={this.input.minPrice}
          label={'Minimum Odds'}
          dense
          outlined
          rules={[validateNumber]}
        />

        <VTextField
          suffix={'x'}
          vModel={this.input.rolloverRequirement}
          label='Rollover requirement'
          dense
          outlined
          rules={[validateNumber]}
        />

        <VSwitch
          vModel={this.input.disabled}
          label='Disabled'
        />

        { this.error ? <VRow class='pa-4'><VAlert type='warning'>{this.error}</VAlert></VRow> : null }
        <VRow>
          <VCol align='end'>
            <VBtn onClick={this.onSave} color='primary'>
              Save
            </VBtn>
          </VCol>
        </VRow>
      </VForm>;
    },
  },

  watch: {
    error() {
      this.loading = false;
    },
  },

  render() {
    return (
      <VDialog
        width='unset'
        value={this.isOpen}
        onInput={this.onClose}>
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>

          <VCardTitle>
            Loyalty schedule
          </VCardTitle>

          <VCardText>
            {this.form}
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
