import Vue, { VueConstructor, VNode } from 'vue';
import {
  VContainer,
  VRow,
  VCol,
  VDataTable,
  VAutocomplete,
  VBtn,
  VBtnToggle,
  VIcon,
} from 'vuetify/lib';
import { mapActions, mapGetters } from 'vuex';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import errorHandlerMixin from '../errorHandlerMixin';
import { RouterLink } from '@/router';

interface Grade {
  grade: number;
  v2key: string;
  name: string;
  id: number;
  _grade: number;
}

const saveOriginalGrade = (grade: Grade) => {
  return { ...grade, _grade: grade.grade };
};

interface SearchCompetitionsParamsV3 {
  name?: string;
  category?: string;
  sport?: string;
  active?: boolean;
  merged?: boolean;
  locale?: string;
  offset?: number;
  limit?: number;
}

const searchCompetitionsQuery =
  gql`query($params: SearchCompetitionsInputV3) {
        grades: searchCompetitionsV3(params: $params) {
          competitions {
            grade
            v2key
            name
            id
          }
        }
      }`;

const getCompetitionsQuery =
  gql`query($keys: [String!]!) {
        grades: getCompetitions(keys: $keys) {
          competitions {
            grade
            v2key
            name
            id
          }
        }
      }`;


const pageSize = 15;
const pagesPerBatch = 6;
const batchSize = pageSize * pagesPerBatch;

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],

  props: {
    sportKey: String,
    competitionKey: String, // optional
  },

  data() {
    return {
      competitionsGrades: [] as Grade[],
      category: '',
      offset: 0,
      totalPages: 0,
      currentPage: 0,
    };
  },

  watch: {
    sport(value: string) {
      this.reinit();
    },

    category(value: string) {
      this.reinit();
    },
  },

  methods: {
    ...mapActions({
      loadSportsConsts: 'sportsConsts/loadSportsConsts',
    }),

    gradesWidget({ item }: { item: Grade }) {
      return (
        <div class='d-flex justify-start'>
          <VBtnToggle color='primary' class='flex-wrap' dense mandatory vModel={item.grade}>
            {
              _.range(0, 11).map((n) => {
                const text = n === 0 ? 'disable' : n;
                return <VBtn value={n}>{text}</VBtn>;
              })
            }
          </VBtnToggle>
          <div>
            <VBtn small icon class='ml-1 mt-1' tabindex={-1}
              disabled={item.grade === item._grade}
              onClick={() => item.grade = item._grade}>
              <VIcon color='error'>change_circle</VIcon>
            </VBtn>
            <VBtn small icon class='ml-1 mt-1' tabindex={-1}
              disabled={item.grade === item._grade}
              onClick={() => this.saveGrade(item)}>
              <VIcon color='warning'>save</VIcon>
            </VBtn>
          </div>
        </div>
      );
    },

    async saveGrade(grade: Grade) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($key: String!, $grade: Int!) {
            updateCompetitionGrade(key: $key, grade: $grade) {
              grade
              key
            }
          }`,
          variables: {
            key: grade.v2key,
            grade: grade.grade,
          },
        });

        const newGrade = result.data.updateCompetitionGrade;

        this.competitionsGrades =
          this.competitionsGrades.map((g) => {
            return g.v2key === newGrade.key ? saveOriginalGrade({ ...g, ...newGrade }) : g;
          });
        this.showSuccessMessage('Grade updated');
      } catch (e) {
        this.showFailureMessage(['Grade update failure:', e.message]);
        throw e;
      }
    },

    loadNextPage() {
      this.offset = this.competitionsGrades.length;
    },

    reinit() {
      this.competitionsGrades = [];
      this.offset = 0;
      this.totalPages = 0;
      this.currentPage = 0;
    },
  },

  computed: {
    ...mapGetters({
      categories: 'sportsConsts/categories',
    }),

    toolbar() {
      return (
        <VRow dense>
          <VCol md='3'>
            <VAutocomplete outlined dense clearable
              item-text='name'
              item-value='key'
              label='Categories'
              items={this.categories}
              vModel={this.category} />
          </VCol>
        </VRow>
      );
    },

    specificCompetitionMode(): boolean {
      return !!this.competitionKey;
    },

    tableView(): VNode {
      return (
        <div>
          {this.toolbar}
          <VDataTable
            items={this.competitionsGrades}
            headers={
              [
                { value: 'v2key', text: 'Key', width: '50%' },
                { value: 'name', text: 'Name', width: '50%' },
                { value: 'grade', text: 'Grade', width: '660px' },
              ]}
            loading={this.$apollo.queries.competitionsGrades.loading}
            itemsPerPage={pageSize}
            hideDefaultFooter={true}
            page={this.currentPage}
            scopedSlots={{
              'item.grade': this.gradesWidget,
              'item.name': ({ item }: { item: Grade }) => {
                return (
                  <RouterLink to={{ name: 'competition', params: { competitionId: item.id } }}>
                    {item.name}
                  </RouterLink>
                );
              },
              'footer': () => {
                return (
                  <div class='v-data-footer py-2'>
                    <div class='v-data-footer__select'></div>
                    <div class='v-data-footer__pagination'>{this.currentPage} page of {this.totalPages || '...'}</div>
                    <div class='v-data-footer__icons-before'>
                      <VBtn icon onClick={() => { this.currentPage--; }} disabled={this.currentPage <= 1}>
                        <VIcon>chevron_left</VIcon>
                      </VBtn>
                    </div>
                    <div class='v-data-footer__icons-after'>
                      <VBtn icon onClick={() => {
                        if (this.currentPage >= this.loadedPages) {
                          this.loadNextPage();
                        } else {
                          this.currentPage++;
                        }
                      }}
                        disabled={!!this.totalPages && this.currentPage >= this.totalPages}>
                        <VIcon>chevron_right</VIcon>
                      </VBtn>
                    </div>
                  </div>
                );
              },
            }} />
        </div>
      );
    },

    simpleView(): VNode {
      if (this.competitionsGrades.length > 0) {
        return this.gradesWidget({ item: this.competitionsGrades[0] });
      } else {
        return <div></div>;
      }
    },

    loadedPages(): number {
      return Math.ceil(this.competitionsGrades.length / pageSize);
    },
  },

  apollo: {
    competitionsGrades: {
      query() { return this.specificCompetitionMode ? getCompetitionsQuery : searchCompetitionsQuery; },

      variables(): { params: SearchCompetitionsParamsV3 } | { keys: string[] } {
        if (this.specificCompetitionMode) {
          return { keys: [this.competitionKey] };
        } else {
          return {
            params: {
              sport: this.sportKey,
              category: this.category,
              limit: batchSize,
              offset: this.offset,
            },
          };
        }
      },

      update(data): Grade[] {
        const grades = data.grades.competitions.map(saveOriginalGrade);
        const competitions = [...this.competitionsGrades, ...grades];
        if (grades.length < batchSize) {
          this.totalPages = Math.ceil(competitions.length / pageSize);
        }
        this.currentPage++;
        return competitions;
      },

      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    this.loadSportsConsts();
  },

  render() {
    return (
      <VContainer fluid >
        {this.successSnackbar}
        {this.failureSnackbar}

        {this.specificCompetitionMode ? this.simpleView : this.tableView}
      </VContainer>
    );
  },
});
