import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
  VSpacer,
  VTextarea,
  VTextField,
} from 'vuetify/lib';

const ConfirmationDialog = Vue.extend({
  props: {
    open: Boolean,
    title: String,
    loading: Boolean,
  },

  data() {
    return {
      reason: '',
    };
  },

  methods: {
    onSubmit() {
      if (this.reason.trim() === '') {
        return;
      }

      this.$emit('submit', this.reason.trim());
    },
    onCancel() {
      this.reason = '';
      this.$emit('cancel');
    },
  },

  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const InputType = mobile ? VTextarea : VTextField;
    return (
      <VDialog
        v-model={this.open}
        on={{
          'click:outside': () => { this.onCancel(); },
        }}
        max-width='500'
      >
        <VCard>
          <VCardTitle class='headline'>
            {this.title}
          </VCardTitle>
          <VCardText>
            <InputType
              vModel={this.reason}
              placeholder='Cancel reason'
              error={this.reason.trim() === ''}
              errorMessages={this.reason.trim() === '' ? 'Cancel reason cannot be empty.' : ''}
              dense
              outlined
            />
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn onClick={this.onCancel}>
              Cancel
              </VBtn>
            <VBtn
              color='primary'
              onClick={this.onSubmit}
              loading={this.loading}
              disabled={this.loading}
            >
              Confirm
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog >
    );
  },
});

export default ConfirmationDialog;
