import Vue from 'vue';
import { VCard, VTab, VTabItem, VTabs } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import CryptoTransactions from './transactions/CryptoTransactions';
import FiatTransactions from './transactions/FiatTransactions';
import './Transactions.less';

export default Vue.extend({
  name: 'Transaction',
  props: {
    playerUUID: String,
  },

  data() {
    return {
      tab: 'views',
    };
  },

  methods: {
    tabs() {
      const tabs = [];

      tabs.push({
        requiresRoles: ['cs:operator', 'cs:user', 'finance:operator', 'finance:user', 'payments:operator', 'payments:user', 'pii:operator', 'pii:user'],
        key: 'crypto-transactions',
        name: 'Crypto',
        content: <CryptoTransactions playerUUID = {this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['cs:operator', 'cs:user', 'finance:operator', 'finance:user', 'payments:operator', 'payments:user', 'pii:operator', 'pii:user'],
        key: 'fiat-transactions',
        name: 'Fiat',
        content: <FiatTransactions playerUUID = {this.playerUUID} />,
      });

      return tabs;
    },
  },

  render() {
    return (
      <div>
        <VCard elevation={4} class='pa-2'>
          <VTabs v-model={this.tab} show-arrows>
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTab key={tab.key}>
                  {tab.name}
                </VTab>
              ))}
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTabItem key={tab.key}>{tab.content}</VTabItem>
              ))}
          </VTabs>
        </VCard>
      </div>
    );
  },
});
