import { GetterTree, MutationTree, ActionTree } from 'vuex';
import axios from 'axios';
import { env } from '@/env';


export interface Sport {
  id: number;
  key: string;
  name: string;
}

export interface Category {
  id: number;
  key: string;
  name: string;
}

export interface SportsConsts {
  sports: Sport[];
  sportsKeysSportsMap: Record<string, Sport>;
  sportsIdsMap: Record<number, string>;
  sportsKeysMap: Record<string, string>;
  sportsKeysIdsMap: Record<string, number>;
  categories: Category[];
  categoriesMap: Record<number, string>;
  categoriesNamesCategoriesMap: Record<string, Category>;
}


const getters: GetterTree<SportsConsts, any> = {
  sports(state): Sport[] {
    return state.sports || [];
  },
  categories(state): Category[] {
    return state.categories || [];
  },

  categoriesMap(state): Record<number, string> {
    return state.categoriesMap || {};
  },
};

const mutations: MutationTree<SportsConsts> = {
  UPDATE_SPORTS(state, data) {
    state.sports = data;
  },

  UPDATE_SPORTS_KEYS_SPORTS_MAP(state, data) {
    state.sportsKeysSportsMap = data;
  },

  UPDATE_SPORTS_IDS_MAP(state, data) {
    state.sportsIdsMap = data;
  },

  UPDATE_SPORTS_KEYS_MAP(state, data) {
    state.sportsKeysMap = data;
  },

  UPDATE_SPORTS_KEYS_IDS_MAP(state, data) {
    state.sportsKeysIdsMap = data;
  },

  UPDATE_CATEGORIES(state, data) {
    state.categories = data;
  },

  UPDATE_CATEGORIES_MAP(state, data) {
    state.categoriesMap = data;
  },

  UPDATE_CATEGORIES_NAMES_CATEGORIES_MAP(state, data) {
    state.categoriesNamesCategoriesMap = data;
  },
};

const actions: ActionTree<SportsConsts, any> = {
  async loadSportsConsts({ dispatch }) {
    dispatch('loadSports');
    dispatch('loadCategories');
  },

  async loadSports({ commit, state }) {
    if (state.sports.length === 0) {
      const [keyNames, keyIds] = await Promise.all([
        axios.get(`${env.sportsCoreBucketUri}/en-sports.json`),
        axios.get(`${env.sportsCoreBucketUri}/sport-ids.json`),
      ]);

      const sports = [] as Sport[];
      const sportsIdsMap = {} as Record<number, string>;
      const sportsKeysMap = {} as Record<string, string>;
      const sportsKeysIdsMap = {} as Record<string, number>;
      const sportsKeysSportsMap = {} as Record<string, Sport>;
      for (const key of Object.keys(keyNames.data)) {
        const name = keyNames.data[key];
        const id = keyIds.data[key];
        const sport = { key, name, id };

        sports.push(sport);
        sportsKeysSportsMap[key] = sport;

        sportsIdsMap[id] = name;
        sportsIdsMap[id - 100] = name; // legacy sport ids

        sportsKeysMap[key] = name;
        sportsKeysIdsMap[key] = id;
      }

      commit('UPDATE_SPORTS', sports);
      commit('UPDATE_SPORTS_KEYS_SPORTS_MAP', sportsKeysSportsMap);
      commit('UPDATE_SPORTS_IDS_MAP', sportsIdsMap);
      commit('UPDATE_SPORTS_KEYS_MAP', sportsKeysMap);
      commit('UPDATE_SPORTS_KEYS_IDS_MAP', sportsKeysIdsMap);
    }
  },

  async loadCategories({ commit, state }) {
    if (state.categories.length === 0) {
      const [keyNames, keyIds] = await Promise.all([
        axios.get(`${env.sportsCoreBucketUri}/en-categories.json`),
        axios.get(`${env.sportsCoreBucketUri}/category-ids.json`),
      ]);

      const categories = [] as Category[];
      const categoriesMap = {} as any;
      const categoriesNamesCategoriesMap = {} as Record<string, Category>;
      for (const key of Object.keys(keyNames.data)) {
        const name = keyNames.data[key];
        const id = keyIds.data[key];
        const category = { key, name, id };

        categories.push(category);
        categoriesMap[id] = name;
        categoriesNamesCategoriesMap[key] = category;
      }

      commit('UPDATE_CATEGORIES_NAMES_CATEGORIES_MAP', categoriesNamesCategoriesMap);
      commit('UPDATE_CATEGORIES', categories);
      commit('UPDATE_CATEGORIES_MAP', categoriesMap);
    }
  },
};


export default {
  namespaced: true,
  state: {
    sports: [],
    sportsKeysSportsMap: {},
    sportsMap: {},
    sportsIdsMap: {},
    sportsKeysMap: {},
    sportsKeysIdsMap: {},
    categories: [],
    categoriesMap: {},
    categoriesNamesCategoriesMap: {},
    locales: ['de', 'el', 'en', 'es', 'fr', 'hu', 'id', 'it', 'ja', 'ko', 'lv', 'nl', 'pl', 'pt', 'pt-BR', 'ru', 'tr', 'zh', 'zh-CN', 'zh-TW'],
  } as SportsConsts,
  getters,
  mutations,
  actions,
};
