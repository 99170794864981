<template>
  <v-container>
    <v-text-field v-model="widget.name" label="Name" class="v-list-item"></v-text-field>
    <v-select
      v-model="widget.type"
      :items="availableWidgetTypes"
      label="Type"
      class="v-list-item"
      :disabled="!!edit"
    ></v-select>
    <StreamForm ref="subform" v-if="widget.type === 'Stream'" :stream="widget.props"></StreamForm>
    <EventLiabilityForm ref="subform" v-else-if="widget.type === 'EventLiability'" :props="widget.props"></EventLiabilityForm>
    <EventIdForm ref="subform" v-else-if="widget.type === 'EventPlayers'" :props="widget.props"></EventIdForm>
    <EventIdForm ref="subform" v-else-if="widget.type === 'EventCloudbetOpinion'" :props="widget.props"></EventIdForm>
    <EventsForm ref="subform" v-else-if="widget.type === 'Events'" v-model="widget.props"></EventsForm>
    <PlayerEventsForm ref="subform" v-else-if="widget.type === 'PlayerEvents'" :props="widget.props"></PlayerEventsForm>
    <v-btn
      v-on:click="save()"
      color="primary"
      :disabled="!isValid"
      :loading="loading"
      block
      class="dashboard-widget-save-button"
    >Save</v-btn>
  </v-container>
</template>

<script>
import EventIdForm from '@/components/Dashboard/forms/EventIdForm';
import EventLiabilityForm from '@/components/Dashboard/forms/EventLiabilityForm';
import StreamForm from '@/components/Dashboard/forms/StreamForm';
import EventsForm from '@/components/Dashboard/forms/EventsForm';
import PlayerEventsForm from '@/components/Dashboard/forms/PlayerEventsForm';
import { filter } from 'lodash';

export default {
  components: {
    StreamForm,
    EventLiabilityForm,
    EventIdForm,
    EventsForm,
    PlayerEventsForm,
  },
  props: ['edit', 'show', 'hasAccess', 'loading'],
  data: () => ({
    widget: {},
  }),
  computed: {
    availableWidgetTypes() {
      return filter([
        'Stream',
        'Events',
        'EventLiability',
        'EventPlayers',
        'EventCloudbetOpinion',
        'Players',
        'PlayerEvents',
      ], (widgetType) => this.hasAccess(widgetType));
    },
    isValid() {
      return !!this.widget.name && !!this.widget.type && this.isSubformValid;
    },
    isSubformValid() {
      switch (this.widget.type) {
        case 'Stream':
          return !!this.widget.props.sourceKey && !!this.widget.props.variant;
        case 'EventLiability':
          return !!this.widget.props.eventId;
        case 'EventPlayers':
          return !!this.widget.props.eventId;
        case 'EventCloudbetOpinion':
          return !!this.widget.props.eventId;
        case 'PlayerEvents':
          return !!this.widget.props.playerUUID;
        case 'Events':
          return true;
        case 'Players':
          return true;
        default:
          return false;
      }
    },
  },
  methods: {
    save() {
      if (this.$refs.subform?.beforeSave) { this.$refs.subform.beforeSave(); }
      this.$emit('onSaved', this.widget);
    },
    clear() {
      if (this.edit) {
        this.widget = JSON.parse(JSON.stringify(this.edit));
      } else {
        this.widget = {
          name: '',
          type: '',
          props: {},
        };
      }
    },
  },
  created() {
    this.clear();
  },
  watch: {
    show(newShow) {
      if (newShow) { this.clear(); }
    },
  },
};
</script>

<style>
  .dashboard-widget-subform {
    padding: 0 16px 0 16px;
  }
  .dashboard-widget-save-button {
    margin-top: 8px;
  }
</style>
