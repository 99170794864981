import Vue, { VueConstructor, VNode } from 'vue';
import {
  VContainer,
  VRow,
  VCol,
  VDataTable,
  VBtn,
  VIcon,
  VSimpleTable,
  VTextField,
  VCard,
  VCardTitle,
  VCardSubtitle,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import errorHandlerMixin from '../errorHandlerMixin';
import Draggable from 'vuedraggable';
import { hasRoles } from '@/utils/auth';
import { DataTableHeader } from 'vuetify';

interface Outright {
  id: number;
  key: string;
  name: string;
}

interface OutrightsPriorities {
  prioritisedIds: number[];
  featureCount: number;
}

const defaultPriorities = { prioritisedIds: [], featureCount: 0 } as OutrightsPriorities;

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],

  props: {
    competitionKey: String,
  },

  data() {
    return {
      outrights: [] as Outright[],
      priorities: defaultPriorities,
      originalPriorities: defaultPriorities,
      search: '',
      updating: false,
    };
  },

  methods: {
    async updatePriorities() {
      try {
        this.updating = true;
        await this.$apollo.mutate({
          mutation: gql`mutation ($competitionKey: String!, $eventsPriorities: EventsPrioritiesInput!) {
            updateEventsPriorities(competitionKey: $competitionKey, eventsPriorities: $eventsPriorities)
          }`,
          variables: {
            competitionKey: this.competitionKey,
            eventsPriorities: {
              prioritisedIds: this.priorities.prioritisedIds,
              featureCount: Number(this.priorities.featureCount),
            },
          },
        });

        this.originalPriorities = { ... this.priorities };
        this.showSuccessMessage('Priorities have been updated');
      } catch (e) {
        this.showFailureMessage(['Priorities update failure:', e.message]);
        throw e;
      } finally {
        this.updating = false;
      }
    },
  },

  computed: {
    prioritised(): Record<number, Outright> {
      const val = this.outrights.filter((o) => this.priorities.prioritisedIds.includes(o.id))
        .map((o) => ([o.id, o.name]));

      return Object.fromEntries(val);
    },

    unprioritesed(): Outright[] {
      return this.outrights.filter((o) => !this.priorities.prioritisedIds.includes(o.id));
    },

    prioritiesChanged(): boolean {
      return !_.isEqual(
        { ...this.priorities, featureCount: Number(this.priorities.featureCount) },
        { ...this.originalPriorities, featureCount: Number(this.originalPriorities.featureCount) });
    },

    prioritisedCard() {
      return (
        <VCard elevation={6} loading={this.$apollo.queries.priorities.loading || this.updating}>
          <VCardTitle>Priorities</VCardTitle>
          <VCardSubtitle>
            List of the outrights with the assigned priorities. Drag an outright an move it to an expeced position.
          </VCardSubtitle>

          <VRow dense>
            <VCol cols='2'>
              <VTextField
                vModel={this.priorities.featureCount}
                v-numeric-input
                label='Featured outrights'
                step='1'
                outlined
                dense
                class='mx-4' />
            </VCol>
            {hasRoles(['sports:operator']) &&
              <VCol cols='2' offset='8' class='text-right'>
                <VBtn class='mx-4' color='warning'
                  disabled={!this.prioritiesChanged || this.updating} onClick={this.updatePriorities}>
                  Save
              </VBtn>
              </VCol>
            }
          </VRow>

          <VSimpleTable>
            {this.priorities.prioritisedIds.length > 0 ?
              <Draggable vModel={this.priorities.prioritisedIds} tag='tbody'>
                {this.priorities.prioritisedIds.map((id, index) => {
                  return (
                    <tr class={{ 'light-blue lighten-5 black--text': index < this.priorities.featureCount }}>
                      <td>{this.prioritised[id]}</td>
                      {hasRoles(['sports:operator']) &&
                        <td style='width: 100px;'>
                          <VBtn color='red' fab x-small onClick={() => {
                            this.priorities = {
                              ...this.priorities,
                              prioritisedIds: this.priorities.prioritisedIds.filter((i) => i !== id),
                            };
                          }}>
                            <VIcon x-small>remove</VIcon>
                          </VBtn>
                        </td>
                      }
                    </tr>
                  );
                })}
              </Draggable>
              :
              <tbody>
                <tr>
                  <td class='text-center' colspan='2'>
                    No outrights have been prioritised yet. Select an outright and add it to the list.
                  </td>
                </tr>
              </tbody>
            }
          </VSimpleTable>
        </VCard>
      );
    },

    unprioritisedCard(): VNode {
      const headers: DataTableHeader[] = [{ value: 'name', text: 'Name' }];
      if (hasRoles(['sports:operator'])) {
        headers.push({ value: 'actions', text: '', width: '100px' });
      }
      return (
        <VCard elevation={6} class='mt-4'>
          <VCardTitle>Outrights</VCardTitle>
          <VCardSubtitle>
            List of the outrights without assigned priorities. Search an outright and add it to the priority list.
          </VCardSubtitle>
          <VDataTable
            items={this.unprioritesed}
            loading={this.$apollo.queries.outrights.loading}
            itemsPerPage={-1}
            sortBy={'name'}
            disablePagination={true}
            hideDefaultFooter={true}
            headers={headers}
            scopedSlots={{
              'top': () => {
                return (
                  <div class='my-1 mx-4'>
                    <VTextField
                      vModel={this.search}
                      appendIcon='search'
                      label='Search'
                      clearable
                      dense />
                  </div>
                );
              },
              'item.actions': ({ item }: { item: Outright }) => {
                return (
                  <VBtn color='green' fab x-small onClick={() => {
                    this.priorities = {
                      ...this.priorities,
                      prioritisedIds: [...this.priorities.prioritisedIds, item.id],
                    };
                  }}>
                    <VIcon x-small>add</VIcon>
                  </VBtn>
                );
              },
            }}
            search={this.search}
          />
        </VCard>
      );
    },
  },

  apollo: {
    outrights: {
      query:
        gql`query($competitionKey: String!, $marketsKeys: [String!]!) {
          getPublicApiCompetitionEvents(competitionKey: $competitionKey, marketsKeys: $marketsKeys) {
            events {
              id
              key
              name
              type
            }
          }
        }`,

      variables(): { competitionKey: string, marketsKeys: string[] } {
        return {
          competitionKey: this.competitionKey,
          marketsKeys: [],
        };
      },

      update(data): Outright[] {
        return data.getPublicApiCompetitionEvents.events
          .filter((i: { type: string }) => i.type === 'EVENT_TYPE_OUTRIGHT')
          .map((i: { id: number; key: string; name: string }) => ({ id: i.id, key: i.key, name: i.name }));
      },

      fetchPolicy: 'network-only',
    },

    priorities: {
      query:
        gql`query($competitionKey: String!) {
          getEventsPriorities(competitionKey: $competitionKey) {
            prioritisedIds
            featureCount
          }
        }`,

      variables(): { competitionKey: string, marketsKeys: string[] } {
        return {
          competitionKey: this.competitionKey,
          marketsKeys: [],
        };
      },

      update(data): OutrightsPriorities {
        this.originalPriorities = data.getEventsPriorities;
        return { ...this.originalPriorities };
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <VContainer fluid>
        {this.successSnackbar}
        {this.failureSnackbar}
        {this.prioritisedCard}
        {this.unprioritisedCard}
      </VContainer >
    );
  },
});
