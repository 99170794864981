import Vue from 'vue';
import {
  VDialog,
  VCard,
  VCardTitle,
  VForm,
  VTextField,
  VBtn,
  VSelect,
  VDatePicker,
  VMenu,
  VAlert,
  VOverlay,
  VProgressCircular,
  VTextarea,
} from 'vuetify/lib';
import './DirectBonusForm.less';
import { SportsBonus } from '../SportsBonusControl/SportsBonusControl';
import { validateNumber } from '@/utils/validate';
import { currencies } from '@/static';
import { createNamespacedHelpers } from 'vuex';
import { formatCurrency } from '@/filters';

const { mapActions, mapState } = createNamespacedHelpers('exchangeRates');

const DEFAULT_EXPIRES_DAYS = 7;

const availableCurrencies = [
  { text: 'EUR', value: 'EUR' },
  ...currencies,
];

function newFormInput(sportsBonus: SportsBonus) {
  return {
    minimumBetPrice: sportsBonus?.minimumBetPrice || 1.7,
    rolloverRequirement: sportsBonus?.rolloverRequirement || 5.0,
    rolloverDaysDuration: sportsBonus?.rolloverDaysDuration || DEFAULT_EXPIRES_DAYS,
    offerExpiresAt: (
      sportsBonus?.offerExpiresAt ?
      sportsBonus?.offerExpiresAt :
      new Date((new Date()).setDate((new Date()).getDate() + DEFAULT_EXPIRES_DAYS))
        .toISOString()
    ).substr(0, 10),
    type: 'DIRECT',
    currency: sportsBonus?.currency || 'EUR',
    amount: sportsBonus?.amount || 1.0,
    description: sportsBonus?.description || '',
  };
}

export const DirectBonusForm = Vue.extend({
  props: {
    isOpen: Boolean,
    sportsBonus: Object as () => SportsBonus,
    error: String,
    loading: Boolean,
  },
  data() {
    return {
      input: newFormInput(this.sportsBonus),
      offerExpiresAtIsOpen: false,
      sportsBonusExists: !!this.sportsBonus,
    };
  },
  computed: {
    ...mapState(['rates']),
  },
  methods: {
    ...mapActions(['loadRates']),
    onClose() {
      this.$emit('closeDialog');
    },
    onCancel() {
      this.$emit('cancel');
    },
    onSave() {
      this.$emit('save', this.input);
    },
    toEur(value: string) {
      return parseFloat(value) * this.rates[this.input.currency];
    },
    eurHint(value: string) {
      return '~ ' + formatCurrency(this.toEur(value), 2, { name: 'EUR' });
    },
  },
  created() {
    this.loadRates();
  },
  watch: {
    sportsBonus: {
      deep: true,
      handler() {
        this.input = newFormInput(this.sportsBonus);
        this.sportsBonusExists = !!this.sportsBonus;
      },
    },
  },
  render() {
    return (
      <VDialog
        onInput={ this.onClose }
        max-width='500'
        max-height='700'
        value={this.isOpen}
      >
        <VCard class='direct-bonus-form'>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>
        <VCardTitle>Direct Bonus</VCardTitle>
          { this.sportsBonusExists ?
              <div class='ml-4'>{`${this.sportsBonus.uuid}`}</div> : null }
          { this.sportsBonusExists ?
            <div class='ml-4'>{`State: ${this.sportsBonus.state}`}</div> : null }
          <VForm class='pa-4'>
            <VMenu
              vModel={ this.offerExpiresAtIsOpen }
              closeOnContentClick={ false }
              nudgeRight={ 40 }
              transition='scale-transition'
              offsetY
              minWidth='auto'
              scopedSlots={{
                activator: ({ on, attrs }: any) => {
                  return <VTextField
                    vModel={ this.input.offerExpiresAt }
                    label='Expires at'
                    readonly
                    dense
                    outlined
                    bind={ attrs }
                    on={ on }
                  ></VTextField>;
                },
              }}
            >
              <VDatePicker
                vModel={ this.input.offerExpiresAt }
                onInput={ () => this.offerExpiresAtIsOpen = false }
              ></VDatePicker>
            </VMenu>
            { !this.sportsBonusExists || ['AWARDED', 'AVAILABLE'].includes(this.sportsBonus.state) ?
              <VTextField
                label='Rollover duration'
                class='input-field'
                suffix={'days'}
                dense
                outlined
                vModel={this.input.rolloverDaysDuration}
                rules={[validateNumber]}
              /> : null }
            <VTextField
              vModel={this.input.minimumBetPrice}
              label={'Minimum Odds'}
              dense
              outlined
              class='input-field'
              rules={[validateNumber]}
            />
            <VTextField
              class='input-field'
              suffix={'x'}
              vModel={this.input.rolloverRequirement}
              label='Rollover requirement'
              dense
              outlined
              rules={[validateNumber]}
            />
            <VSelect
              class='input-field'
              items={availableCurrencies}
              vModel={this.input.currency}
              label='Currency'
              dense
              outlined
              disabled={this.sportsBonusExists}
            />
            <VTextField
              vModel={this.input.amount}
              label='amount'
              dense
              outlined
              class='input-field'
              persistent-hint
              hint={ this.eurHint(this.input.amount.toString()) }
              disabled={this.sportsBonusExists}
            />
            <VTextarea
              vModel={this.input.description}
              label='Description'
              dense
              outlined
              class='input-field'
            />
          </VForm>
          { this.sportsBonusExists ?
            <div class='save-discard-container ma-2'>
              <VBtn onClick={this.onSave} class='ma-2 button' color='primary'>
                Save
              </VBtn>
              <VBtn onClick={this.onClose} class='ma-2 button'>
                Discard
              </VBtn>
            </div> :
            <VBtn onClick={this.onSave} class='button ma-4' color='primary'>
              Award
            </VBtn>
          }
          { this.sportsBonusExists ?
            <VBtn onClick={this.onCancel} color='red'>
              Cancel bonus
            </VBtn> : null
          }
          { this.error ? <VAlert type='warning'>{this.error}</VAlert> : null }
        </VCard>
      </VDialog>
    );
  },
});
