import Vue from 'vue';
import {
  PlayerBetActivityProps,
  transformToChartData,
} from '@/components/PlayerBetActivity/PlayerBetActivity';
import './BetActivitySparkline.less';

import { ApolloError, gql } from 'apollo-boost';
import { VSheet, VSkeletonLoader } from 'vuetify/lib';
import { SparklineCustom } from './SparklineCustom';

export const BetActivitySparkLine = Vue.extend({
  props: {
    playerUUID: String,
  },
  data() {
    return {
      playerBetActivity: {} as PlayerBetActivityProps,
      isLoading: true,
      hasError: false,
      errorValue: {} as ApolloError,
    };
  },
  apollo: {
    playerBetActivity: {
      query: gql`
        query($uuid: String!) {
          playerBetActivity(uuid: $uuid) {
            sports {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
            }
            casino {
              turnover
              numberBets
              averageBetSize
              margin
              pnl
            }
            sportsBetsDailyActivity {
              revenue
              date
            }
            casinoBetsDailyActivity {
              revenue
              date
            }
          }
        }
      `,
      variables(): { uuid: string } {
        return { uuid: this.playerUUID };
      },
      update(data) {
        if (data.playerBetActivity) {
          this.isLoading = false;
          return data.playerBetActivity;
        }
      },
      error(error) {
        this.isLoading = false;
        this.errorValue = error;
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      fetchPolicy: 'no-cache',
      result({ errors }) {
        if (errors) {
          this.hasError = true;
        }
      },
    },
  },

  render() {
    if (this.isLoading) {
      return <VSkeletonLoader type='image' height='40' maxWidth='200' />;
    }

    if (!this.playerBetActivity?.sportsBetsDailyActivity || this.hasError) {
      return <div>No data available</div>;
    }
    return (
      <VSheet color='transparent' maxWidth='200'>
        <SparklineCustom
          height={150}
          chartData={transformToChartData(this.playerBetActivity)}
        />
      </VSheet>
    );
  },
});
