export const SOURCES: Record<string, any> = {
  sportsBetUpdated: {
    text: 'Sports Bets Updates',
    key: 'sportsBetUpdated',
    attrGroups: [
      {
        text: 'Bet',
        attrs: [
          { key: 'uuid', text: 'uuid', type: 'string' },
          { key: 'price', text: 'price', type: 'number' },
          { key: 'stakeWithBonus', text: 'stake', type: 'number' },
          { key: 'stakeWithBonusInEur', text: 'stake (EUR)', type: 'number' },
          { key: 'returnAmountWithBonus', text: 'return amount', type: 'number' },
          { key: 'returnAmountWithBonusInEur', text: 'return amount (EUR)', type: 'number' },
          { key: 'exchangeRate', text: 'exchange rate', type: 'number' },
          { key: 'baseCurrency', text: 'currency', type: 'string' },
          { key: 'customerReference', text: 'customer reference', type: 'string' },
          { key: 'maxStake', text: 'Max stake', type: 'number' },
          {
            key: 'state',
            text: 'state',
            type: 'static',
            values: ['ACCEPTED', 'COMPLETED', 'REJECTED'],
          },
          {
            key: 'result',
            text: 'result',
            type: 'static',
            values: ['PENDING', 'WIN', 'LOSS', 'PUSH', 'HALF_WIN', 'HALF_LOSS', 'PARTIAL'],
          },
          {
            key: 'side',
            text: 'side',
            type: 'static',
            values: ['BACK', 'LAY'],
          },
          { key: 'rejectionCode', text: 'rejection code', type: 'string' },
          { key: 'isCashoutBet', text: 'isCashoutBet', type: 'boolean' },
          { key: 'playerTags', text: 'playerTags', type: 'array' },
        ],
      },
      {
        text: 'Selection',
        attrs: [
          { key: 'selections.sportKey', text: 'sport key', type: 'string' },
          { key: 'selections.categoryKey', text: 'category key', type: 'string' },
          { key: 'selections.competitionKey', text: 'competition key', type: 'string' },
          { key: 'selections.eventId', text: 'event id', type: 'string' },
          { key: 'selections.marketKey', text: 'market key', type: 'string' },
          { key: 'selections.params', text: 'params', type: 'string' },
          { key: 'selections.outcome', text: 'outcome', type: 'string' },
          { key: 'selections.provider', text: 'provider', type: 'string' },
          { key: 'selections.isLive', text: 'isLive', type: 'boolean' },
          { key: 'selections.price', text: 'price', type: 'string' },
          { key: 'selections.closingPrice', text: 'closingPrice', type: 'string' },
        ],
      },
      {
        text: 'Selection details',
        attrs: [
          { key: 'selections.details.sportName', text: 'sport name', type: 'string' },
          { key: 'selections.details.categoryName', text: 'category name', type: 'string' },
          { key: 'selections.details.competitionName', text: 'competition name', type: 'string' },
          { key: 'selections.details.competitionGrade', text: 'competition grade', type: 'number' },
          { key: 'selections.details.eventName', text: 'event name', type: 'string' },
          { key: 'selections.details.marketName', text: 'market name', type: 'string' },
          { key: 'selections.details.selectionName', text: 'selection name', type: 'string' },
        ],
      },
      {
        text: 'Player',
        attrs: [
          { key: 'player.uuid', text: 'uuid', type: 'string' },
          { key: 'player.nickname', text: 'nickname', type: 'string' },
        ],
      },
      {
        text: 'Metadata',
        attrs: [
          { key: 'metadata.country', text: 'country', type: 'string' },
          {
            key: 'metadata.channel', text: 'channel', type: 'static',
            values: ['WEB', 'API', 'LEGACY'],
          },
          { key: 'metadata.tenant', text: 'tenant', type: 'string' },
        ],
      },
    ],
  },
  casinoRoundUpdated: {
    text: 'Casino Rounds Updates',
    key: 'casinoRoundUpdated',
    attrGroups: [
      {
        text: 'Round',
        attrs: [
          { key: 'uuid', text: 'uuid', type: 'string' },
          { key: 'stakeWithBonus', text: 'stake', type: 'number' },
          { key: 'stakeWithBonusInEur', text: 'stake (EUR)', type: 'number' },
          { key: 'returnAmountWithBonus', text: 'return amount', type: 'number' },
          { key: 'returnAmountWithBonusInEur', text: 'return amount (EUR)', type: 'number' },
          { key: 'exchangeRate', text: 'exchange rate', type: 'number' },
          { key: 'baseCurrency', text: 'currency', type: 'string' },
          { key: 'distributorKey', text: 'distributor key', type: 'string' },
          { key: 'studioKey', text: 'studio key', type: 'string' },
          { key: 'gameKey', text: 'game key', type: 'string' },
          { key: 'gameSessionId', text: 'game session id', type: 'string' },
          { key: 'rtp', text: 'rtp', type: 'string' },
          { key: 'isLive', text: 'is live', type: 'boolean' },
        ],
      },
      {
        text: 'Round details',
        attrs: [
          { key: 'details.distributorName', text: 'distributor name', type: 'string' },
          { key: 'details.studioName', text: 'studio name', type: 'string' },
          { key: 'details.gameName', text: 'game name', type: 'string' },
          { key: 'details.gameType', text: 'game type', type: 'string' },
        ],
      },
      {
        text: 'Player',
        attrs: [
          { key: 'player.uuid', text: 'uuid', type: 'string' },
          { key: 'player.nickname', text: 'nickname', type: 'string' },
        ],
      },
      {
        text: 'Metadata',
        attrs: [
          { key: 'metadata.country', text: 'country', type: 'string' },
          { key: 'metadata.channel', text: 'channel', type: 'string' },
          { key: 'metadata.tenant', text: 'tenant', type: 'string' },
        ],
      },
    ],
  },
  depositUpdated: {
    text: 'Deposits Updates',
    key: 'depositUpdated',
    attrGroups: [
      {
        text: 'Deposit',
        attrs: [
          { key: 'uuid', text: 'uuid', type: 'string' },
          { key: 'createTime', text: 'createTime', type: 'string' },
          { key: 'currency', text: 'currency', type: 'string' },
          { key: 'hash', text: 'hash', type: 'string' },
          { key: 'addressTo', text: 'addressTo', type: 'string' },
          { key: 'value', text: 'value', type: 'string' },
          { key: 'fee', text: 'fee', type: 'string' },
          { key: 'externalCreateTime', text: 'externalCreateTime', type: 'string' },
          { key: 'state', text: 'state', type: 'string' },
          { key: 'txType', text: 'txType', type: 'string' },
        ],
      },
      {
        text: 'Player',
        attrs: [
          { key: 'player.uuid', text: 'uuid', type: 'string' },
          { key: 'player.nickname', text: 'nickname', type: 'string' },
        ],
      },
      {
        text: 'Metadata',
        attrs: [
          { key: 'metadata.country', text: 'country', type: 'string' },
          { key: 'metadata.channel', text: 'channel', type: 'string' },
          { key: 'metadata.tenant', text: 'tenant', type: 'string' },
        ],
      },
    ],
  },
  withdrawalUpdated: {
    text: 'Withdrawal Updates',
    key: 'withdrawalUpdated',
    attrGroups: [
      {
        text: 'Withdrawal',
        attrs: [
          { key: 'uuid', text: 'uuid', type: 'string' },
          { key: 'createTime', text: 'createTime', type: 'string' },
          { key: 'currency', text: 'currency', type: 'string' },
          { key: 'hash', text: 'hash', type: 'string' },
          { key: 'addressTo', text: 'addressTo', type: 'string' },
          { key: 'value', text: 'value', type: 'string' },
          { key: 'fee', text: 'fee', type: 'string' },
          { key: 'externalCreateTime', text: 'externalCreateTime', type: 'string' },
          { key: 'state', text: 'state', type: 'string' },
          { key: 'txType', text: 'txType', type: 'string' },
        ],
      },
      {
        text: 'Player',
        attrs: [
          { key: 'player.uuid', text: 'uuid', type: 'string' },
          { key: 'player.nickname', text: 'nickname', type: 'string' },
        ],
      },
      {
        text: 'Metadata',
        attrs: [
          { key: 'metadata.country', text: 'country', type: 'string' },
          { key: 'metadata.channel', text: 'channel', type: 'string' },
          { key: 'metadata.tenant', text: 'tenant', type: 'string' },
        ],
      },
    ],
  },
};
