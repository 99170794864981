import Vue from 'vue';
import { VSnackbar, VBtn } from 'vuetify/lib';
import _ from 'lodash';

interface SnackbarConfig {
  timeout?: number;
  color?: string;
  closeButtonColor?: string;
}

const snackbarConfig = (content: JSX.Element | null, action: JSX.Element, config: SnackbarConfig) => {
  return {
    props: {
      timeout: config.timeout || -1,
      top: true,
      color: config.color || '',
      elevation: '24',
    },
    scopedSlots: {
      default: () => content,
      action: () => action,
    },
  };
};

export default Vue.extend({
  data() {
    return {
      successMessage: null as JSX.Element | null,
      showSuccessSnackbar: false,
      failureMessage: null as JSX.Element | null,
      showFailureSnackbar: false,
    };
  },

  methods: {
    _normaliseMessage(message: string | string[] | JSX.Element) {
      if (_.isArray(message)) {
        return <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else if (_.isString(message)) {
        return (<div>{message}</div>);
      } else {
        return message;
      }
    },

    showFailureMessage(message: string | string[] | JSX.Element) {
      this.failureMessage = this._normaliseMessage(message);
      this.showFailureSnackbar = true;
    },

    showSuccessMessage(message: string | string[] | JSX.Element) {
      this.successMessage = this._normaliseMessage(message);
      this.showSuccessSnackbar = true;
    },

    failureSnackbarWithConfig(config: SnackbarConfig) {
      config = { ...config };
      config.timeout = config.timeout || 5000;
      config.color = _.isNil(config.color) ? 'red darken-1' : config.color;
      const closeButtonColor = config.closeButtonColor || '';
      const action =
        <VBtn
          color={closeButtonColor}
          onClick={() => this.showFailureSnackbar = false}>
          Close
        </VBtn>;

      return (
        <VSnackbar {...snackbarConfig(this.failureMessage, action, config)}
          vModel={this.showFailureSnackbar} />
      );
    },

    successSnackbarWithConfig(config: SnackbarConfig) {
      config = { ...config };
      config.timeout = config.timeout || 2000;
      config.color = _.isNil(config.color) ? 'green darken-1' : config.color;
      const closeButtonColor = config.closeButtonColor || '';
      const action =
        <VBtn
          color={closeButtonColor}
          onClick={() => this.showSuccessSnackbar = false}>
          Close
        </VBtn>;

      return (
        <VSnackbar {...snackbarConfig(this.successMessage, action, config)}
          vModel={this.showSuccessSnackbar} />
      );
    },
  },

  computed: {
    failureSnackbar(): JSX.Element {
      return this.failureSnackbarWithConfig({});
    },

    successSnackbar(): JSX.Element {
      return this.successSnackbarWithConfig({});
    },
  },
});
