import { groupBy } from 'lodash';
import Vue from 'vue';
import moment from 'moment';

import EventLiabilityCell from './EventLiabilityCell.vue';
import EventPlayersWidget from '@/components/sports/EventPlayersWidget';
import { selectionName } from '../markets-names';
import { createNamespacedHelpers } from 'vuex';
import { Event as EventEntity } from '@/types/sports';
import { gql } from 'apollo-boost';

const { mapState, mapActions } = createNamespacedHelpers('sports/events');

interface Event {
  eventId: number;
  eventName: string;
  sportKey: string;
  competitionKey: string;
  sportName: string;
  competitionName: string;
  exposure: number;
  turnover: number;
  betsCount: number;
  playersCount: number;
  startsAt: string | Date;
  isOutright: boolean;
  turnoverWithUntracked: number;
  betsCountWithUntracked: number;
  playersCountWithUntracked: number;
  marketKey: string;
}

interface EventLiabilityGridUpdate extends Event {
  grid: EventLiabilityGridCell[];
}
interface EventLiabilityGridCell {
  homeScore?: number;
  awayScore?: number;
  outcome?: string;
  profitAndLoss: string;
}

interface EventLiabilityGrid extends Event {
  grid: Record<string, EventLiabilityGridCell[]>;
}

export default Vue.extend({
  components: {
    EventLiabilityCell,
    EventPlayersWidget,
  },
  props: {
    eventId: String,
    eventLiabilityGridUpdated: Object as () => EventLiabilityGridUpdate,
    loading: Boolean,
  },
  data() {
    return {
      subscription: null as any | null,
      eventLiabilityGrid: null as any | null,
      homeScores: [] as string[],
      awayScores: [] as string[],
      maxProfit: 0 as number | undefined,
      maxLoss: 0 as number | undefined,
      noDataAvailable: false,
      keysTranslations: {} as Record<string, string>,
      singleOutcomeGrid: false,
    };
  },
  methods: {
    ...mapActions(['loadCurrentEvent']),

    getMaxProfitAndLoss: (liabilities: EventLiabilityGridCell[]) => {
      let profit: number | undefined;
      let loss: number | undefined;
      liabilities.forEach((liability) => {
        const profitAndLoss = parseFloat(liability.profitAndLoss);
        if (profit === undefined || profitAndLoss > profit) {
          profit = profitAndLoss;
        }
        if (loss === undefined || profitAndLoss < loss) {
          loss = profitAndLoss;
        }
      });
      return {
        profit: profit ? Math.floor(profit) : 0,
        loss: loss ? Math.floor(loss) : 0,
      };
    },

    eventLiabilityGridUpdatedWatchHandler() {

      if (!this.eventLiabilityGridUpdated) { return; }

      const { grid: liabilities } = this.eventLiabilityGridUpdated;

      if (liabilities.length === 0) {
        this.eventLiabilityGrid = {};
        this.noDataAvailable = true;
        return;
      }

      this.noDataAvailable = false;

      let newGrid: any = [];
      if (liabilities[0].outcome) {
        this.singleOutcomeGrid = true;

        liabilities.forEach((cell) => {
          if (cell.outcome) {
            if (!this.keysTranslations[cell.outcome]) {
              this.keysTranslations[cell.outcome] = '';
            }
          }
        });
        newGrid = liabilities.map((cell: EventLiabilityGridCell) => {
          cell.profitAndLoss = `${parseFloat(cell.profitAndLoss) * -1}`;
          cell.outcome = selectionName({
            selectionName: cell.outcome || '',
            marketKey: this.eventLiabilityGridUpdated.marketKey,
            marketParams: '',
          }, {
            ...this.currentEvent as EventEntity,
          }, this.keysTranslations) || '';
          return cell;
        });
      } else {
        newGrid = groupBy(
          liabilities,
          (cell: EventLiabilityGridCell) => {
            cell.profitAndLoss = `${parseFloat(cell.profitAndLoss) * -1}`;
            return cell.homeScore;
          },
        );
        this.homeScores = Object.keys(newGrid);
        this.awayScores = newGrid[this.homeScores[0]].map((cell: any) =>
          cell.awayScore.toString(),
        );
      }

      const { profit, loss } = this.getMaxProfitAndLoss(liabilities);
      this.maxProfit = profit;
      this.maxLoss = loss;

      this.eventLiabilityGrid = {
        ...this.eventLiabilityGridUpdated,
        startsAt: moment(new Date(this.eventLiabilityGridUpdated.startsAt)).format('LLL'),
        grid: newGrid,
      };
    },
  },
  computed: {
    ...mapState(['currentEvent']),

    missingTranslations(): string[] {
      return Object.entries(this.keysTranslations)
        .filter(([key, translation]) => !translation)
        .map(([key, translation]) => key);
    },
  },
  watch: {
    eventLiabilityGridUpdated: {
      immediate: true,
      handler() {
        this.eventLiabilityGridUpdatedWatchHandler();
      },
    },
  },

  mounted() {
    this.loadCurrentEvent(parseInt(this.eventId, 10));
  },

  apollo: {
    keysTranslations: {
      query: gql`query ($locale: String!, $keys: [String]!) {
        getKeysTransations(locale: $locale, keys: $keys)
      }`,

      variables(): { locale: string, keys: string[] } {
        return {
          locale: 'en',
          keys: this.missingTranslations,
        };
      },

      update(data) {
        return Object.fromEntries(data.getKeysTransations);
      },

      result() {
        this.eventLiabilityGridUpdated.grid = this.eventLiabilityGridUpdated.grid.map((cell) => {
          cell.outcome = this.keysTranslations[cell.outcome!] || cell.outcome || '';

          return cell;
        });
      },

      skip(): boolean {
        return this.missingTranslations.length === 0;
      },
    },
  },
});
