import { getOutcome, getMarketName, Locale, MarketType } from '@cloudbet/market-helper/sports-core';
import { EventData } from '@cloudbet/market-helper';

interface Player {
  key: string;
  name: string;
}

interface MarketNameEvent {
  competitors: Array<{ key: string; name: string; }>;
  name: string;
  players: Player[];
}

interface MarketNameMarket {
  marketParams: string;
  marketKey: string;
}

interface SelectionNameMarket extends MarketNameMarket {
  selectionName: string;
}


const playersListToRecord = (players: Player[]) => {
  return players.reduce((acc, p) => {
    return {
      ...acc, [p.key]: { ...p, name: (p.name || p.key) },
    };
  }, {});
};

export const marketName = (market: MarketNameMarket, event: MarketNameEvent) => {
  const [name, error] = getMarketName(Locale.en,
    { params: market.marketParams || '' },
    (market.marketKey || '') as MarketType,
    {
      name: event?.name,
      home: event.competitors?.[0],
      away: event.competitors?.[1],
      players: playersListToRecord(event?.players || []),
    } as EventData);

  return error ? `${market.marketKey} ` : name;
};

export const selectionName =
  (market: SelectionNameMarket, event: MarketNameEvent, translations: Record<string, string> = {}) => {
    if (!market.marketKey && translations[market.selectionName]) {
      return translations[market.selectionName];
    }

    const [result, error] = getOutcome(Locale.en, {
      home: event.competitors?.[0],
      away: event.competitors?.[1],
      players: playersListToRecord(event?.players || []),
      names: translations,
    },
      market.marketKey as MarketType,
      { params: market.marketParams || '', outcome: market.selectionName || '' },
    );
    return result?.name && result.name !== 'null' ? result.name : market.selectionName || error?.message;
  };
