import gql from 'graphql-tag';
import Vue from 'vue';
import {
  VContainer,
  VCardText,
  VCard,
  VSelect,
  VRow,
  VCol,
} from 'vuetify/lib';
import './Event.less';
import { hasRoles } from '@/utils/auth';
import EntityMediaWidget from '@/components/sports/EntityMediaWidget/EntityMediaWidget';
import EventHeadline from '@/components/sports/EventHeadline';
import EventLiabilityGrid from '@/components/sports/EventLiabilityGrid';
import EventPlayersWidget from '@/components/sports/EventPlayersWidget';
import EventCloudbetOpinion from '@/components/sports/EventCloudbetOpinion';
import EventStreamsWidget from '@/components/sports/EventStreamsWidget';
import EventResultWidget from '@/components/sports/EventResults';
import PendingMarkets from '@/components/sports/PendingEventsWidget/PendingMarketsWidget';
import EventScores from '@/components/sports/EventScores';
import EventScoresWidget from '@/components/sports/PendingEventsWidget/EventScoresWidget';
import EventPositionsWidget from '@/components/sports/EventPositions';
import SportsBets from '@/components/sports/PlayerBets';

import { formatCurrency } from '@/filters';
import LiveMarkets from '@/components/sports/LiveMarkets/LiveMarkets';
import StreamWidget from '@/components/StreamWidget';
import EventMarkets from '@/components/sports/EventMarkets';
import { env } from '@/env';
import { createNamespacedHelpers } from 'vuex';
import PinnacleOverlay from '@/components/sports/PinnacleOverlay';
import OutrightsTranslations from '@/components/sports/OutrightsTranslations';
import { marketName } from '@/components/sports/markets-names';
import { Event } from '@/types/sports';
import _ from 'lodash';
import ResponsiveTabs, { Tab } from '@/components/ResponsiveTabs';
import InContextHelpGradingResults from '@/components/sports/InContextHelpGradingResults';

const { mapActions, mapState } = createNamespacedHelpers('sports/events');

export default Vue.extend({
  computed: {
    ...mapState(['currentEvent']),
    eventId(): string {
      return this.$route.params.eventId.toString();
    },
    marketOptions(): any {
      return this.$data.eventLiabilityGridUpdated?.possibleMarketKeys?.map((marketKey: string) => ({
        text: this.translateMarket(marketKey),
        value: marketKey,
      }));
    },
  },
  data() {
    return {
      subscription: null as any,
      eventLiabilityGridUpdated: null as any,
      marketKey: '' as string,
      loading: false,
    };
  },
  mounted() {
    this.loadCurrentEvent(parseInt(this.eventId, 10));
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    ...mapActions(['loadCurrentEvent']),
    translateMarket(marketKey: string) {
      if (marketKey === 'all') {
        return 'all';
      }

      if (marketKey.endsWith('.outright.v2')) {
        return 'Outright';
      }

      return marketName({ marketKey, marketParams: '' }, { ...this.currentEvent as Event });
    },
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
        this.loading = true;
      }
    },
    subscribe(marketKey: string = '') {
      if (!hasRoles(['sports:operator'])) {
        return;
      }

      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription eventLiabilityGridUpdated($eventId: String!, $marketKey: String) {
              eventLiabilityGridUpdated(eventId: $eventId, marketKey: $marketKey) {
                eventId
                eventName
                sportKey
                competitionKey
                sportName
                competitionName
                turnover
                exposure
                playersCount
                betsCount
                startsAt
                isOutright
                grid {
                  homeScore
                  awayScore
                  outcome
                  profitAndLoss
                }
                turnoverWithUntracked
                playersCountWithUntracked
                betsCountWithUntracked
                marketKey
                possibleMarketKeys
                exposures {
                  marketKey
                  exposure
                }
              }
            }
          `,
          variables: {
            eventId: this.eventId,
            marketKey,
          },
        })
        .subscribe({
          next: ({ data }: { data: { eventLiabilityGridUpdated: any } }) => {
            this.loading = false;
            this.eventLiabilityGridUpdated = data.eventLiabilityGridUpdated;
            this.marketKey = this.eventLiabilityGridUpdated.marketKey;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error: any) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },

    tabs(): Tab[] {
      return [
        {
          requiresRoles: ['sports:operator'],
          key: 'overview',
          name: 'Overview',
          content: (
            <div>
              {this.eventLiabilityGridUpdated &&
                <VCardText>
                  <div>
                    Tracked exposure:{' '}
                    {this.eventLiabilityGridUpdated.exposures.map(
                      (exposure: { marketKey: string, exposure: number }) => (
                        <div class='ml-4'>
                          {this.translateMarket(exposure.marketKey)}: {formatCurrency(exposure.exposure, 0)}
                        </div>
                      ))}
                  </div>
                  <div>
                    Players: {this.eventLiabilityGridUpdated.playersCountWithUntracked}
                    {' (' + this.eventLiabilityGridUpdated.playersCount + ' tracked)'}
                  </div>
                  <div>
                    Bets: {this.eventLiabilityGridUpdated.betsCountWithUntracked}
                    {' (' + this.eventLiabilityGridUpdated.betsCount + ' tracked)'}
                  </div>
                  <div>
                    Turnover:{' '}
                    {formatCurrency(this.eventLiabilityGridUpdated.turnoverWithUntracked, 0)}
                    {' (' + formatCurrency(this.eventLiabilityGridUpdated.turnover, 0) + ' tracked)'}
                  </div>
                </VCardText>
              }
              <div class='liability-tab'>
                <div class='market-price-liability-container'>
                  {!this.eventLiabilityGridUpdated?.isOutright && (
                    <div class='live-prices-container'>
                      <LiveMarkets eventId={this.eventId} />
                    </div>
                  )}
                  <VCard class={`liability-grid-card ${this.eventLiabilityGridUpdated?.isOutright ? '' : 'overview-event-type'}`} elevation={0}>
                    <div>
                      <div class='title'>Liability grid</div>
                      {
                        this.eventLiabilityGridUpdated?.possibleMarketKeys?.length > 0 ?
                          <VSelect class='ml-3'
                            label={'Market'}
                            items={this.marketOptions}
                            value={this.marketKey}
                            onChange={this.subscribe}></VSelect> :
                          null
                      }
                      <EventLiabilityGrid
                        eventId={this.eventId}
                        eventLiabilityGridUpdated={this.eventLiabilityGridUpdated}
                        loading={this.loading}
                      />
                    </div>
                  </VCard>
                  {this.eventLiabilityGridUpdated?.isOutright && (
                    <VCard class='sports-bet-updated-container' elevation={0}>
                      <div class='title'>Bet Update Stream</div>
                      <StreamWidget
                        stream={{
                          variant: 'terminal',
                          sourceKey: 'sportsBetUpdated',
                          filters: {
                            filters: [
                              {
                                column: 'selections.eventId',
                                operator: '==',
                                value: this.eventId,
                              },
                            ],
                            operator: 'and',
                          },
                        }}
                      />
                    </VCard>
                  )}
                </div>
                {!this.eventLiabilityGridUpdated?.isOutright ? (
                  <VCard class='sports-bet-updated-container' elevation={0}>
                    <div class='title'>Bet Update Stream</div>
                    <StreamWidget
                      stream={{
                        variant: 'terminal',
                        sourceKey: 'sportsBetUpdated',
                        filters: {
                          filters: [
                            {
                              column: 'selections.eventId',
                              operator: '==',
                              value: this.eventId,
                            },
                          ],
                          operator: 'and',
                        },
                      }}
                    />
                  </VCard>
                ) : null}
              </div>
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'top-players',
          name: 'Top players',
          content: <EventPlayersWidget eventId={this.eventId} />,
        },
        {
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'sport-bets',
          name: 'Bets',
          content: <SportsBets eventId={this.eventId} />,
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'cloudbet-opinion',
          name: 'Cloudbet opinion',
          content: (
            <EventCloudbetOpinion eventId={this.eventId} />
          ),
        },
        {
          requiresRoles: ['sports:operator', 'dataentry:operator'],
          key: 'media',
          name: 'Media',
          content: (
            <EntityMediaWidget entityType='event' entityKey={this.eventId} />
          ),
        },
        {
          requiresRoles: ['dataentry:operator'],
          key: 'streams',
          name: 'Streams',
          content: <EventStreamsWidget eventId={this.eventId} />,
        },
        {
          disabled: !env.feature.enableGrading,
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'results',
          name: 'Results',
          content: <EventResultWidget eventId={this.eventId} />,
        },
        {
          disabled: !env.feature.enableGrading || this.currentEvent?.type === 'MULTI_COMPETITOR_EVENT',
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'scores',
          name: 'Scores',
          content: (
            <div>
              {this.currentEvent &&
                <EventScoresWidget eventId={this.eventId} sportKey={this.currentEvent.sportKey} />
              }
              <EventScores eventId={this.eventId} />
            </div>
          ),
        },
        {
          disabled: !env.feature.enableGrading || this.currentEvent?.type !== 'MULTI_COMPETITOR_EVENT',
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'positions',
          name: 'Positions',
          content: <EventPositionsWidget eventId={this.eventId} />,
        },
        {
          disabled: !env.enableExchangeMarkets,
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'exchange-markets',
          name: 'Exchange',
          content: (
            <div>
              {this.currentEvent &&
                <EventMarkets eventId={this.currentEvent.id} />
              }
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'markets-to-grade',
          name: 'Markets to grade',
          content: (
            <div>
              {this.currentEvent &&
                <EventScoresWidget eventId={this.eventId} sportKey={this.currentEvent.sportKey} />
              }
              <PendingMarkets eventId={this.eventId} />
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'pinnacle-overlay',
          name: 'Pinnacle Overlay',
          content: (
            <div>
              {this.currentEvent &&
                <VRow>
                  <VCol cols='4' offset='4'>
                    <PinnacleOverlay entityId={this.currentEvent.id} entityType={'event'} />
                  </VCol>
                </VRow>
              }
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator', 'localization:operator'],
          key: 'outrights-translations',
          name: 'Translations',
          content: (
            <div>
              {this.currentEvent &&
                <OutrightsTranslations outrightId={this.currentEvent.id} />
              }
            </div>
          ),
        },
        {
          disabled: !env.feature.enableCSGrading,
          requiresRoles: ['sports:operator', 'sports:user', 'cs:operator'],
          key: 'cs-grading',
          name: 'CS Grading',
          content: (
            <div>
              {this.currentEvent &&
                <InContextHelpGradingResults eventId={this.currentEvent.id} />
              }
            </div>
          ),
        },
      ];
    },
  },

  watch: {
    eventId() {
      this.loadCurrentEvent(parseInt(this.eventId, 10));
      this.subscribe();
    },
  },

  render() {
    return (
      <VContainer fluid >
        <EventHeadline
          showRepublishEventBetsTrigger={true}
          eventId={parseInt(this.eventId, 10)}
        />

        <ResponsiveTabs tabs={this.tabs()} tabsOrder={['overview', 'top-players']} />
      </VContainer>
    );
  },
});
