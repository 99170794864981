
/* tslint:disable */
import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { gql } from 'apollo-boost';
import { Announcement } from '@/types/announcements';
interface Announcements {
  announcements: Announcement[];
}

interface DeleteAnnouncementProps {
  uuid: string;
}

const getters: GetterTree<Announcements, any> = {
  announcements(state: Announcements): Announcements {
    return state;
  },
};

const mutations: MutationTree<Announcements> = {
  UPDATE_ANNOUNCEMENTS(state, announcements) {
    state.announcements = announcements;
  },
};

const getAnnouncements = async (client: any) => {
  const response = await client.query({
    query: gql`
    query {
      getAnnouncements {
        announcements {
          uuid
          type
          name
          messages {
            key
            value
          }
          messagesUpdatedAt  {
            key
            value
          }
          enable
          scope
        }
      }
    }
    `,
  });
  return response.data.getAnnouncements.announcements;
}

const actions: ActionTree<Announcements, any> = {
  async getAnnouncements(
    { commit, rootState },
  ) {
    try {
      const client = rootState.apolloClient();
      const announcements = await getAnnouncements(client);
      commit('UPDATE_ANNOUNCEMENTS', announcements);
    } catch (err) {
      throw err;
    }
  },

  async updateAnnouncement(
    { commit, rootState },
    input: Omit<Announcement, '__typename'>,
  ) {
    try {
      const client = rootState.apolloClient();
      const res = await client.mutate({
          mutation: gql`
          mutation updateAnnouncement($input: UpdateAnnouncementInput!) {
            updateAnnouncement(input: $input) {
              UpdateTime
            }
          }
        `,
          variables: {
            input
          },
        });
      if (res.data.updateAnnouncement.UpdateTime) {
        const client = rootState.apolloClient();
        const announcements = await getAnnouncements(client);
        commit('UPDATE_ANNOUNCEMENTS', announcements);
      }
    } catch (err) {
      throw err;
    }
  },

  async createAnnouncement(
    { commit, rootState },
    input: Omit<Announcement, 'uuid' | '__typename'>,
  ) {
    try {
      const client = rootState.apolloClient();
      const res = await client.mutate({
          mutation: gql`
          mutation createAnnouncement($input: CreateAnnouncementInput!) {
            createAnnouncement(input: $input) {
              UpdateTime
              uuid
            }
          }
        `,
          variables: {
            input
          },
        });
      if (res.data.createAnnouncement.UpdateTime) {
        const client = rootState.apolloClient();
        const announcements = await getAnnouncements(client);
        commit('UPDATE_ANNOUNCEMENTS', announcements);
      }
    } catch (err) {
      throw err;
    }
  },

  async deleteAnnouncement(
    { commit, rootState },
    {
      uuid
    }: DeleteAnnouncementProps,
  ) {
    try {
      const client = rootState.apolloClient();
      const res = await client.mutate({
        mutation: gql`mutation ($uuid: String!) {
          deleteAnnouncement(uuid: $uuid) {
            UpdateTime
          }
        }`,
        variables: {
          uuid: uuid,
        },
        });
      if (res.data.deleteAnnouncement.UpdateTime) {
        const client = rootState.apolloClient();
        const announcements = await getAnnouncements(client);
        commit('UPDATE_ANNOUNCEMENTS', announcements);
      }
    } catch (err) {
      throw err;
    }
  },

  async deleteMessageItem(
    { commit, rootState },
    input: Omit<Announcement, '__typename'>,
  ) {
    try {
      const client = rootState.apolloClient();
      const res = await client.mutate({
          mutation: gql`
          mutation updateAnnouncement($input: UpdateAnnouncementInput!) {
            updateAnnouncement(input: $input) {
              UpdateTime
            }
          }
        `,
          variables: {
            input
          },
        });
      if (res.data.updateAnnouncement.UpdateTime) {
        const client = rootState.apolloClient();
        const announcements = await getAnnouncements(client);
        commit('UPDATE_ANNOUNCEMENTS', announcements);
      }
    } catch (err) {
      throw err;
    }
  },
};

const Announcements = {
  namespaced: true,
  state: { announcements: [] } as Announcements,
  getters,
  mutations,
  actions,
};

export default Announcements;
