import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardTitle,
  VCardText,
  VContainer,
  VDialog,
  VForm,
  VSelect,
  VSnackbar,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

import './ActionWithdrawalDialog.less';
import { validateRequired } from '@/utils/validate';

export default Vue.extend({
  props: {
    taskUUID: String,
    domain: String,
    currency: String,
    amount: String,
  },

  data() {
    return {
      dialog: true,
      decision: '',
      reason: '',
      showResultSnackbar: false,
      actionResult: '',
      disableSubmit: false,
    };
  },

  methods: {
    onClose() {
      this.showResultSnackbar = false;
      this.$emit('dialogClosed');
    },

    onKeydown(e: KeyboardEvent) {
      if (e.code === 'Escape') {
        this.onClose();
      }
    },

    async onSubmit() {
      try {
        if (this.decision === '') {
          throw Error('Decision is required');
        }

        if (this.decision === 'reject' && this.reason === '') {
          throw Error('Reason is required for rejection');
        }

        this.disableSubmit = true;

        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
            $domain: WithdrawalNamespace!,
            $withdrawalUUID: String!,
            $decision: WithdrawalAction!,
            $reason: String) {
             actionWithdrawal(
               domain: $domain,
               withdrawalUUID: $withdrawalUUID,
               decision: $decision,
               reason: $reason
             ) {
              result
            }
          }`,
          variables: {
            domain: this.domain,
            withdrawalUUID: this.taskUUID,
            decision: this.decision,
            reason: this.reason === '' ? null : this.reason,
          },
        });

        this.actionResult = result?.data?.actionWithdrawal?.result;
      } catch (e) {
        this.disableSubmit = false;
        this.actionResult = String(e);
      } finally {
        this.showResultSnackbar = true;
      }
    },
  },

  computed: {
    modalTitle() {
      return (
        <div id='dialog'>
          <h1>{this.domain}</h1>
          <span>human decision</span>
          <br/><br/>
          <table>
            <tr>
              <td>Task:</td>
              <td>{this.taskUUID}</td>
            </tr>
            <tr>
              <td>Currency:</td>
              <td>{this.currency}</td>
            </tr>
            <tr>
              <td>Amount:</td>
              <td>{this.amount}</td>
            </tr>
          </table>
        </div>
      );
    },

    actionResultSnackbar() {
      if (this.showResultSnackbar) {
        if (this.actionResult === 'OK') {
          const snackInterval = 3000;
          setTimeout(this.onClose, snackInterval);
          return (
            <VSnackbar
              v-model={this.showResultSnackbar}
              timeout={snackInterval}
              top
              color='green darken-1'
              elevation='24'
            >
              {`OK.`}
            </VSnackbar>
          );
        }

        return (
          <VSnackbar
            v-model={this.showResultSnackbar}
            timeout={8000}
            top
            color='red darken-1'
            elevation='24'>
            {`Failed${this.actionResult ? `: ${this.actionResult}` : '.'}`}
          </VSnackbar>
        );
      }

      return (<div></div>);
    },
  },

  render() {
    return (
      <div>
        { this.actionResultSnackbar }

        <VDialog v-model={this.dialog} max-width='400' persistent
          on={{ 'click:outside': this.onClose, 'keydown': this.onKeydown }}>
          <VCard>
            <VCardTitle class='headline'>
              {this.modalTitle}
            </VCardTitle>

            <VForm>
              <VCardText>
                <VContainer>
                  <VSelect
                    items={[
                      {text: 'approve', value: 'approve'},
                      {text: 'reject', value: 'reject'},
                    ]}
                    vModel={this.decision}
                    label='Decision'
                    rules={[validateRequired]}
                  />

                  <VTextField
                    class='mt-6'
                    dense
                    vModel={this.reason}
                    label='Reason'
                    required
                    rules={[
                      (value: string) => {
                        if (this.decision === 'reject') {
                          return !!value || 'reason is required';
                        }

                        return true;
                      },
                    ]}
                  />
                </VContainer>
              </VCardText>
            </VForm>

            <VCardActions>
              <VSpacer></VSpacer>
              <VBtn color='green darken-1' text onClick={this.onClose}>
                Cancel
              </VBtn>
              <VBtn color='green darken-1' text
                onClick={this.onSubmit}
                disabled={this.disableSubmit}
              >
                Submit
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </div>
    );
  },
});

