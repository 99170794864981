import { Route } from './types';
import TopEvents from '@/views/sports/TopEvents';
import TopPlayers from '@/views/sports/TopPlayers';
import PendingEvents from '@/views/sports/PendingEvents';
import ProvidersStatus from '@/views/sports/ProvidersStatus';
import EntityMedia from '@/views/sports/EntityMedia';
import Competitor from '@/views/sports/Competitor';
import Event from '@/views/sports/Event';
import CompetitionTranslations from '@/views/sports/CompetitionTranslations';
import CompetitorTranslations from '@/views/sports/CompetitorTranslations';
import OutrightTranslations from '@/views/sports/OutrightTranslations';
import Competition from '@/views/sports/Competition';
import CompetitionsPriorities from '@/views/sports/CompetitionsPriorities';
import LiabilitySettings from '@/views/sports/LiabilitySettings';
import BetDetails from '@/views/sports/BetDetails';
import LoyaltySchedules from '@/views/sports/LoyaltySchedules';
import EventCreator from '@/views/sports/EventCreator';
import EventsMatching from '@/views/sports/EventsMatching';
import Sport from '@/views/sports/Sport';
import InContextHelpGradingResults from '@/components/sports/InContextHelpGradingResults';

const sportsRoutes: Route[] = [
  {
    path: '/cs-grading',
    name: 'cs-grading',
    component: InContextHelpGradingResults,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user', 'cs:operator'],
    },
  },
  {
    path: '/events/matching',
    name: 'events-matching',
    component: EventsMatching,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user'],
    },
  },
  {
    path: '/events/creator',
    name: 'event-creator',
    component: EventCreator,
    meta: {
      requiresAuth: true,
      requiresRoles: ['dataentry:operator'],
    },
  },
  {
    path: '/events/:eventId',
    name: 'event',
    component: Event,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/top-events',
    name: 'top-events',
    component: TopEvents,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator'],
    },
  },
  {
    path: '/top-players',
    name: 'top-players',
    component: TopPlayers,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator'],
    },
  },
  {
    path: '/pending-events',
    name: 'pending-events',
    component: PendingEvents,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator'],
    },
  },
  {
    path: '/providers-status',
    name: 'providers-status',
    component: ProvidersStatus,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator'],
    },
  },
  {
    path: '/media/:entityType/:entityKey',
    name: 'eventMedia',
    component: EntityMedia,
    meta: {
      requiresAuth: true,
      requiresRoles: ['dataentry:operator'],
    },
  },
  {
    path: '/competitors/:competitorId',
    name: 'competitor',
    component: Competitor,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/competitions/priorities',
    name: 'competitionsPriorities',
    component: CompetitionsPriorities,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator'],
    },
  },
  {
    path: '/liability_settings',
    name: 'liabilititySettings',
    component: LiabilitySettings,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator'],
    },
  },
  {
    path: '/competitions/:competitionId',
    name: 'competition',
    component: Competition,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/translations/competition',
    name: 'competitionTranslations',
    component: CompetitionTranslations,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/translations/competitor',
    name: 'competitorTranslations',
    component: CompetitorTranslations,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/translations/outright',
    name: 'outrightTranslations',
    component: OutrightTranslations,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'dataentry:operator', 'localization:operator'],
    },
  },
  {
    path: '/sports_bets/:betId',
    name: 'sportsBetDetails',
    component: BetDetails,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user'],
    },
  },
  {
    path: '/loyalty_schedules',
    name: 'loyaltySchedules',
    component: LoyaltySchedules,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user'],
    },
  },
  {
    path: '/sports/:sportKey',
    name: 'sport',
    component: Sport,
    meta: {
      requiresAuth: true,
      requiresRoles: ['sports:operator', 'sports:user'],
    },
  },
];

export default sportsRoutes;
