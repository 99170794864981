import store from '@/store';
import { hasRoles } from '@/utils/auth';
import Vue from 'vue';
import VueRouter from 'vue-router';

import About from '@/views/About.vue';
import Home from '@/views/Home';
import Profile from '@/views/Profile';
import Signin from '@/views/Signin.vue';
import NotFound from '@/views/NotFound';
import Forbidden from '@/views/Forbidden';

import Player from '@/views/player/Player';
import Affiliate from '@/views/Affiliate/Affiliate';

import { Route } from './types';

import sportsRoutes from './sports';
import SearchResults from '@/views/sports/SearchResults';
import casinoRoutes from './casino';
import paymentsRoutes from './payments';
import { Watchlists } from '@/views/Watchlists/Watchlists';
import PendingTasks from '@/views/withdrawal-approval/PendingTasks';
import { Announcements } from '@/views/Announcements/Announcements';
import KYCReview from '@/views/KYCReview/KYCReview';

const vueWithRouter = Vue.use(VueRouter);
export const RouterLink = vueWithRouter.component('RouterLink');

const basicRoutes: Route[] = [
  {
    path: '/search',
    name: 'search',
    component: SearchResults,
  },
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: Profile,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/signout',
    name: 'signout',
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/signin',
    name: 'signin',
    component: Signin,
  },
  {
    path: '/watchlists',
    name: 'watchlists',
    component: Watchlists,
  },
  {
    path: '/withdrawal-approval',
    name: 'withdrawal-approval',
    component: PendingTasks,
  },
  {
    path: '/announcements',
    name: 'announcements',
    component: Announcements,
  },
  {
    path: '/forbidden',
    name: 'forbidden',
    component: Forbidden,
  },
  {
    path: '*',
    name: 'notfound',
    component: NotFound,
  },
  {
    path: '/players/:playerUUID',
    name: 'player',
    component: Player,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/affiliates/:affiliateUUID',
    name: 'affiliate',
    component: Affiliate,
    meta: {
      requiresAuth: true,
      requiresRoles: ['pii:operator', 'pii:user'],
    },
  },
  {
    path: '/kyc-review',
    name: 'kyc-review',
    component: KYCReview,
    meta: {
      requiresAuth: true,
      requiresRoles: ['pii:operator', 'pii:user'],
    },
  },
];

const routes = basicRoutes.concat(sportsRoutes, casinoRoutes, paymentsRoutes);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthed = store.getters['auth/isAuthenticated'];
  // don't go to signin page if authenticated
  if (isAuthed && to.name === 'signin') {
    next('/');
    return;
  }
  // dispatch sign out when go to sign out page
  if (to.name === 'signout') {
    store.dispatch('auth/signout', { router });
    next('/signin');
    return;
  }
  // Get meta data from to route
  const meta = to.meta;
  // if no authentication required, go next
  if (!meta.requiresAuth) {
    next();
    return;
  }
  // check required authenicated route and roles
  if (meta.requiresAuth) {
    // requiresRoles could be undefined here, which would implies every role can access the route
    // if logged in and has required roles, go next
    if (hasRoles(meta.requiresRoles)) {
      next();
      return;
    } else if (isAuthed) {
      next('/forbidden');
      return;
    }
  }
  // otherwise redirect to signin page
  next('/signin');
});

export default router;
