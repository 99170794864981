import Vue from 'vue';
import {
  VCard,
  VBtn,
  VRow,
  VCol,
  VToolbar,
  VSpacer,
  VToolbarTitle,
  VIcon,
  VSnackbar,
  VTextField,
  VProgressLinear,
  VSlider,
  VDataTable,
} from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import SearchDialog from './SearchDialog';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { CompetitionPriority, CompetitionPriorityGroup } from '@/types/sports';
import './CompetitionsPrioritiesWidget.less';
import EditGroupDialog from './EditGroupDialog';
import {
  backgroundColor,
  successSnackbarConfig,
  failureSnackbarConfig,
  MIN_PRIORITY,
  MAX_PRIORITY,
  countriesTextList,
} from '../competitions-priorities-commons';

const { mapGetters, mapActions, mapMutations, mapState } = createNamespacedHelpers('sports/competitionsPriorities');
const { mapState: constsState, mapActions: constsActions } = createNamespacedHelpers('sportsConsts');

interface LocalData {
  editedGroupPriorities?: CompetitionPriorityGroup;
  deletedGroup?: CompetitionPriorityGroup;
  editedGroup?: CompetitionPriorityGroup;
  copiedGroup?: CompetitionPriorityGroup;
  deletedPriority?: { group: CompetitionPriorityGroup, priority: CompetitionPriority };
  updateSuccess: boolean;
  updateFailure: boolean;
  showNewGroupDialog: boolean;
}

const groupTitle = (group: CompetitionPriorityGroup) => {
  if (group.countries.length > 0) {
    return `${group.name} (${countriesTextList(group)})`;
  } else {
    return group.name;
  }
};

export default Vue.extend({
  name: 'CompetitionsPrioritiesWidget',
  data() {
    return {
      editedGroupPriorities: undefined,
      deletedGroup: undefined,
      editedGroup: undefined,
      copiedGroup: undefined,
      deletedPriority: undefined,
      updateSuccess: false,
      updateFailure: false,
      showNewGroupDialog: false,
    } as LocalData;
  },

  computed: {
    ...mapGetters([
      'prioritiesGroups',
      'groupCompetitionsKeys',
      'dirty',
    ]),
    ...mapState(['isLoading', 'isUpdating']),
    ...constsState(['sportsKeysMap', 'categoriesMap']),

    searchDialog() {
      if (this.editedGroupPriorities !== undefined) {
        return (
          <SearchDialog
            onDialogClosed={() => this.editedGroupPriorities = undefined}
            group={this.editedGroupPriorities}
            active={false}
            includeMerged={false}
            selectedCompetitionsKeys={this.groupCompetitionsKeys(this.editedGroupPriorities)} />
        );
      }
    },

    deletePriorityDialog() {
      if (this.deletedPriority) {
        return (
          <ConfirmationDialog
            title='Delete priority'
            text={`Are you sure you want to delete the priority for ${this.deletedPriority.priority.key}?`}
            onDisagree={this.onDeletePriorityCancelled}
            onAgree={this.onDeletePriorityAccepted}
            item={this.deletedPriority} />
        );
      }
    },

    deleteGroupDialog() {
      if (this.deletedGroup) {
        return (
          <ConfirmationDialog
            title={`Delete group ${this.deletedGroup.name}`}
            text='Are you sure you want to delete the whole group?'
            onDisagree={this.onDeleteGroupCancelled}
            onAgree={this.onDeleteGroupAccepted}
            item={this.deletedGroup} />
        );
      }
    },

    newGroupDialog() {
      if (this.showNewGroupDialog) {
        return (
          <EditGroupDialog
            onDialogClosed={() => this.showNewGroupDialog = false}
            onDialogAccepted={this.onNewGroupAdded}
            mode='add' />
        );
      }
    },

    editGroupDialog() {
      if (this.editedGroup) {
        return (
          <EditGroupDialog
            editedGroup={{ ...this.editedGroup }}
            onDialogClosed={() => this.editedGroup = undefined}
            mode='edit' />
        );
      }
    },

    copyGroupDialog() {
      if (this.copiedGroup) {
        return (
          <EditGroupDialog
            editedGroup={{ ...this.copiedGroup, countries: [], name: '' }}
            onDialogClosed={() => this.copiedGroup = undefined}
            mode='copy' />
        );
      }
    },

    toolbar() {
      return (
        <VToolbar>
          <VToolbarTitle>Competitions priorities</VToolbarTitle>
          <VProgressLinear
            active={this.isUpdating}
            indeterminate={this.isUpdating}
            absolute
            bottom
            color='deep-purple accent-4'>
          </VProgressLinear>
          <VSpacer />
          <VBtn color='warning' onClick={this.onSaveChanges}
            disabled={!this.dirty}>
            Save changes
          </VBtn>
          <VBtn
            color='primary'
            class='ml-2'
            onClick={() => this.showNewGroupDialog = true}>
            New group
          </VBtn>
        </VToolbar>
      );
    },

    fabSaveButton() {
      return (
        <VBtn color='warning' fixed fab bottom right onClick={this.onSaveChanges}
          disabled={!this.dirty}>
          <VIcon>save</VIcon>
        </VBtn>
      );
    },
  },

  methods: {
    ...mapActions([
      'loadCompetitionsPriorities',
      'updateCompetitionPriorities',
    ]),
    ...constsActions(['loadSportsConsts']),
    ...mapMutations([
      'DELETE_COMPETITION_PRIORITY',
      'DELETE_COMPETITION_PRIORITY_GROUP',
    ]),

    onNewCompetitionClicked(group: CompetitionPriorityGroup) {
      this.editedGroupPriorities = group;
    },

    async onSaveChanges() {
      try {
        await this.updateCompetitionPriorities();
        this.updateSuccess = true;
      } catch (e) {
        this.updateFailure = true;
        throw e;
      }
    },

    onDeletePriorityAccepted(item: CompetitionPriority) {
      this.DELETE_COMPETITION_PRIORITY(item);
      this.deletedPriority = undefined;
    },

    onDeletePriorityCancelled() {
      this.deletedPriority = undefined;
    },

    onDeleteGroupAccepted(item: CompetitionPriorityGroup) {
      this.DELETE_COMPETITION_PRIORITY_GROUP(item);
      this.deletedGroup = undefined;
    },

    onDeleteGroupCancelled() {
      this.deletedGroup = undefined;
    },

    priorityValue(competitionPriority: any) {
      return competitionPriority.changedPriority || competitionPriority.priority;
    },

    groupPrioritiesList(group: CompetitionPriorityGroup) {
      return (
        <VDataTable
          dense
          headers={[
            { value: 'name', text: 'Competition', width: '45%' },
            { value: 'priority', text: 'Priority', sortable: false },
            { value: 'actions', width: '100px' },
          ]}
          items={group.competitionPriorities}
          itemKey='name'
          class='competitions'
          footerProps={{
            showFirstLastPage: true,
          }}
          scopedSlots={{
            'item.name': ({ item }: { item: CompetitionPriority }) => {
              return (
                <div>
                  <span style='display:block;' class='subtitle-1'>{item.name || item.key}</span>
                  <span class='subtitle-2 grey--text'>
                    {this.sportsKeysMap[item.sportKey]},&nbsp;
                    {this.categoriesMap[item.categoryId]}
                  </span>
                </div>
              );
            },
            'item.priority': ({ item }: { item: CompetitionPriority }) => {
              return (
                <VSlider
                  dense
                  vModel={item.priority}
                  min={MIN_PRIORITY}
                  max={MAX_PRIORITY}
                  validate-on-blur={true}>
                  <template slot='append'>
                    <VTextField
                      dense
                      vModel={item.priority}
                      background-color={backgroundColor(item, 'priority')}
                      class='priority'
                      type='number'
                      step='1'
                      min={MIN_PRIORITY}
                      max={MAX_PRIORITY} />
                  </template>
                </VSlider>
              );
            },
            'item.actions': ({ item }: { item: CompetitionPriority }) => {
              return (
                <VBtn icon
                  onClick={() => { this.deletedPriority = { group, priority: item }; }}>
                  <VIcon>delete</VIcon>
                </VBtn>
              );
            },
          }} />
      );
    },

    onNewGroupAdded(group: CompetitionPriorityGroup) {
      this.showNewGroupDialog = false;
      this.onNewCompetitionClicked(group);
    },
  },

  mounted() {
    this.loadSportsConsts();
    this.loadCompetitionsPriorities();
  },

  render() {
    return (
      <div class='competitions-priorities-widget'>
        {this.searchDialog}
        {this.deletePriorityDialog}
        {this.deleteGroupDialog}
        {this.newGroupDialog}
        {this.editGroupDialog}
        {this.copyGroupDialog}
        {this.toolbar}
        <VSnackbar {...successSnackbarConfig} vModel={this.updateSuccess} />
        <VSnackbar {...failureSnackbarConfig} vModel={this.updateFailure} />
        {this.fabSaveButton}
        <VCard>
          <VDataTable headers={[
            { value: 'name', text: 'Group' },
            { text: '', value: 'data-table-expand', width: 100, align: 'center' },
          ]}
            items={this.prioritiesGroups}
            itemKey='name'
            itemClass={() => 'expandable'}
            class='groups'
            disablePagination={true}
            disableSort={false}
            hideDefaultFooter={true}
            hideDefaultHeader={false}
            showExpand={true}
            singleExpand={true}
            loading={this.isLoading}
            on={{
              'click:row': (e: MouseEvent, { expand, isExpanded }:
                { expand: (value: boolean) => void, isExpanded: boolean }) => {
                expand(!isExpanded);
              },
            }}
            scopedSlots={{
              'item.name': ({ item }: { item: CompetitionPriorityGroup }) => {
                return <span class='subtitle-1'>{groupTitle(item)}</span>;
              },
              'expanded-item': ({ item }: { item: CompetitionPriorityGroup }) => {
                return (
                  <td colspan='2'>
                    <div class='mx-8 my-3'>
                      <VRow no-gutters>
                        <VCol class='text-right'>
                          <VBtn class='ml-2' color='default' onClick={() => this.copiedGroup = item}>
                            Copy group
                          </VBtn>
                          <VBtn class='ml-2' color='default' onClick={() => this.editedGroup = item}>
                            Edit group
                          </VBtn>
                          {!item.countries.includes('default') &&
                            <VBtn class='ml-2' color='secondary' onClick={() => this.deletedGroup = item}>
                              Delete group
                            </VBtn>
                          }
                          <VBtn class='ml-2' color='primary' onClick={() => this.onNewCompetitionClicked(item)}>
                            Add competition
                          </VBtn>
                        </VCol>
                      </VRow>
                      {this.groupPrioritiesList(item)}
                    </div>
                  </td>
                );
              },
            }} />
        </VCard>
      </div>
    );
  },
});
