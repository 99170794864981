import Vue from 'vue';
import { RawSelection } from '@/types/sports';
import Selection from '@/components/sports/Selection/Selection';
import { subPusherChannel } from '@/utils/subSubmarketPusher';
import { possibleOutcomeCount, MarketType } from '@cloudbet/market-helper';

export default Vue.extend({
  props: {
    selections: Array as () => RawSelection[],
    marketKey: String,
    submarket: String,
    eventId: String,
  },
  data() {
    return { rawSelections: [] as RawSelection[] };
  },
  methods: {
    updateMarket(newSelections: RawSelection[]) {
      this.rawSelections = newSelections;
    },
  },
  mounted() {
    subPusherChannel({
      pusherInstance: this.$pusher,
      eventId: this.eventId,
      marketKey: this.marketKey,
      submarketKey: this.submarket,
      updateSubmarket: this.updateMarket,
    });
  },
  render() {
    const [selectionsPerLine] = possibleOutcomeCount(this.marketKey as MarketType);
    if (!selectionsPerLine) {
      return <div>'error'</div>;
    }
    if (this.rawSelections.length > 0) {
      return (
        <div>
          {this.rawSelections.slice(0, +selectionsPerLine).map((selection) => (
            <Selection
              key={selection.outcome + selection.params}
              outcome={selection.outcome}
              params={selection.params}
              price={selection.price}
              status={selection.status}
            />
          ))}
        </div>
      );
    }
    return (
      <div>
        {this.selections.slice(0, +selectionsPerLine).map((selection) => (
          <Selection
            key={selection.outcome + selection.params}
            outcome={selection.outcome}
            params={selection.params}
            price={selection.price}
            status={selection.status}
          />
        ))}
      </div>
    );
  },
});
