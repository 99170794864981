export interface Player {
  __typename?: 'Player';
  id: string;
  status: LoyaltyStatus;
  fixedSchedule?: FixedSchedule;
  fixedScheduleHiddenToPlayer: boolean;
  telegramContact?: string;
  nextScheduledRewardAt?: string;
}

export interface PlayerInput {
  id: string;
  mode?: Mode;
  scheduleID?: string;
  tier?: number;
  fixedScheduleHiddenToPlayer?: boolean;
  telegramContact?: string;
}

export enum RewardsScheme {
  Revenue = 'revenue',
  Turnover = 'turnover',
}

export interface FixedSchedule {
  __typename?: 'FixedSchedule';
  id: string;
  name: string;
  scheme: RewardsScheme;
  percentage: string;
  scheduleInDays: number;
}

export enum Mode {
  Manual = 'manual',
  Custom = 'custom',
  Loyalty = 'loyalty',
  Vip = 'vip',
  Pro = 'professional',
}

export interface Trend {
  __typename?: 'Trend';
  tier: number;
  days: number;
}

export interface BonusParameters {
  __typename?: 'BonusParameters';
  fundsMultiplier: number;
  rolloverRequirement: number;
  minimumOddsRequirement: number;
}

export interface LoyaltyStatus {
  __typename?: 'LoyaltyStatus';
  mode: Mode;
  tier: number;
  completion?: number;
  todaysScore: number;
  personalHighScore?: number;
  todaysScoreResetsAt: string;
  trend?: Trend;
  xpMultiplier: number;
  rcMultiplier: number;
  offers: Offer[];
  telegramContact?: string;
  localRewardCashBalance?: number;
}

export enum OfferType {
  XpMultiplier = 'xp_multiplier',
  RcMultiplier = 'rc_multiplier',
}

export interface Offer {
  __typename?: 'Offer';
  id: string;
  type: OfferType;
  validUntil: string;
  multiplier?: number;
  multiplierValidHours?: number;
}

export interface OfferInput {
  type: OfferType;
  validUntil: string;
  multiplier?: string;
  multiplierValidHours?: number;
}
