import Vue from 'vue';
import {
  VBtn,
  VTextField,
  VForm,
  VSelect,
  VSnackbar,
  VRow,
  VCol,
  VLabel,
  VRadioGroup,
  VRadio,
} from 'vuetify/lib';
import { failureSnackbarConfig, successSnackbarConfig } from './helpers';
import { ExchangeOrder, ExchangeSelection, ExchangeMarket } from './types';
import { gql } from 'apollo-boost';
import { uuid4 } from '@/utils/uuid';
import { Event } from '@/types/sports';
import { mapGetters } from 'vuex';
import './OrderForm.less';
import { hasRoles } from '@/utils/auth';
import { selectionName } from '../markets-names';

type FormMode = 'copy' | 'add';

const sides = ['back', 'lay'];
const durations = [
  { value: 'gtc', text: 'Good \'til Cancelled' },
  { value: 'fok', text: 'Fill or Kill' },
];

export default Vue.extend({
  props: {
    mode: String as () => FormMode,
    market: Object as () => ExchangeMarket,
    selection: Object as () => ExchangeSelection,
    event: Object as () => Event,
    setPrice: String,
    setSide: String,
    disabled: Boolean,
  },

  data() {
    return {
      isUpdating: false,
      showFailure: false,
      showSuccess: false,
      errors: [] as string[],
      price: this.setPrice,
      size: '',
      side: this.setSide,
      duration: 'gtc',
    };
  },

  watch: {
    setPrice(value: string) {
      this.price = value;
    },

    setSide(value: string) {
      this.side = value;
    },
  },

  methods: {
    onAccepted(order: ExchangeOrder) {
      this.showSuccess = true;
      this.resetValidation();
      this.$emit('saved', order);
    },

    onCancelClick() {
      this.$emit('cancelled');
    },

    async onAcceptClick() {
      try {
        this.isUpdating = true;
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($input: SportsExchangeOrderInput) {
            sportsExchangeCreateOrder(input: $input) {
              duration
              price
              side
              size
              state
              userUUID
              uuid
            }
          }`,
          variables: {
            input: {
              eventId: this.market.eventId,
              marketKey: this.market.marketKey,
              marketParams: this.market.marketParams,
              price: this.price,
              selectionName: this.selection.selectionName,
              side: this.side,
              size: this.size,
              userUUID: this.email,
              requestUUID: uuid4(),
              duration: this.duration,
            },
          },
        });
        this.onAccepted(result.data.sportsExchangeCreateOrder);
      } catch (e) {
        this.showFailure = true;
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body?.errors) {
          this.errors = e.graphQLErrors[0].extensions.response.body.errors;
        } else {
          this.errors = [e.message];
          throw e;
        }
      } finally {
        this.isUpdating = false;
      }
    },

    fieldIsRequired(value: string) {
      return !!value || 'Field is required';
    },

    fieldIsNumber(value: number) {
      return !isNaN(value) || 'Must be a number';
    },

    resetValidation() {
      this.size = '';
      (this.$refs.form as any).resetValidation();
    },
  },

  computed: {
    ...mapGetters({ email: 'auth/email' }),

    disableAcceptButton(): boolean {
      return !this.price || !this.size || !this.side || !this.duration || this.isUpdating;
    },

    modalTitle(): string | undefined {
      if (this.mode === 'add') {
        return 'Add order';
      } else if (this.mode === 'copy') {
        return 'Copy order';
      }
    },
  },

  render() {
    return (
      <div class='order-form'>
        <VSnackbar {...failureSnackbarConfig(<div>{this.errors.map((e) => <div>{e}</div>)}</div>)}
          vModel={this.showFailure} />
        <VSnackbar {...successSnackbarConfig('Order has been added.')} vModel={this.showSuccess} />
        <VForm ref='form' disabled={this.disabled}>
          <VLabel><strong>{selectionName({
            marketParams: this.market.marketParams,
            marketKey: this.market.marketKey,
            selectionName: this.selection.selectionName,
          }, this.event, this.market.translations)}</strong></VLabel>
          <VRow dense class='mt-2'>
            <VCol cols='12'>
              <VRadioGroup vModel={this.side} row dense class='text-uppercase order-side'>
                <VRadio label={sides[0]} value={sides[0]} />
                <VRadio label={sides[1]} value={sides[1]} />
              </VRadioGroup>
            </VCol>
          </VRow>

          <VRow dense class='mt-2'>
            <VCol cols='12'>
              <VSelect
                dense
                vModel={this.duration}
                items={durations}
                rules={[this.fieldIsRequired]}
                outlined
                label='Duration'
              />
            </VCol>
          </VRow>

          <VRow dense>
            <VCol cols='6'>
              <VTextField
                dense
                vModel={this.price}
                rules={[this.fieldIsRequired, this.fieldIsNumber]}
                label='Odds'
                outlined
                step='0.001'
                v-numeric-input
              />
            </VCol>

            <VCol cols='6'>
              <VTextField
                dense
                vModel={this.size}
                rules={[this.fieldIsRequired, this.fieldIsNumber]}
                label='Stake'
                outlined
                suffix='EUR'
                step='0.01'
                v-numeric-input
              />
            </VCol>
          </VRow>

          <div class='text-right'>
            <VBtn
              onClick={this.onCancelClick}
              disabled={this.isUpdating}>
              Cancel
            </VBtn>
            {hasRoles(['sports:operator']) &&
              <VBtn color='success'
                class='ml-2'
                onClick={this.onAcceptClick}
                disabled={this.disableAcceptButton}>
                Save
              </VBtn>
            }
          </div>
        </VForm>
      </div>
    );
  },
});

