import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { CompetitionPriority, CompetitionPriorityGroup, Competition } from '@/types/sports';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import isoCountries from 'i18n-iso-countries';
import en from 'i18n-iso-countries/langs/en.json';
import { uuid4 } from '@/utils/uuid';
isoCountries.registerLocale(en);

const DEFAULT_PRIORITY = 10;

interface CompetitionPriorityExt extends CompetitionPriority {
  _priority: number;
  _delete?: boolean;
  _new?: boolean;
  _uuid: string;
}

interface CompetitionPriorityGroupExt extends CompetitionPriorityGroup {
  competitionPriorities: CompetitionPriorityExt[];
  _name: string;
  _countries: string[];
  _delete?: boolean;
  _new?: boolean;
  _uuid: string;
}

interface CompetitionsPrioritiesState {
  isLoading: boolean;
  isUpdating: boolean;
  groups: CompetitionPriorityGroupExt[];
  competition?: Competition;
}


interface UpdatePriorityInput {
  id?: number;
  priority: number;
  categoryId: number;
  key: string;
  sportKey: string;
  delete?: boolean;
}

interface UpdateGroupInput {
  id?: number;
  name: string;
  countries: string[];
  competitionPriorities: UpdatePriorityInput[];
  delete?: boolean;
}

interface UpdateGroupsInput {
  groups: UpdateGroupInput[];
  competitionKey?: string;
}

const valuesChanged = (obj: Record<string, any>, fields: string[]): boolean => {
  for (const field of fields) {
    if (obj[field].toString() !== obj['_' + field].toString()) {
      return true;
    }
  }
  return false;
};

const getters: GetterTree<CompetitionsPrioritiesState, any> = {
  prioritiesGroups(state): CompetitionPriorityGroupExt[] {
    if (!state.groups) {
      return [];
    }

    const withoutDeleted =
      state.groups.filter((group) => !group._delete).map((group) => ({ ...group }));

    for (const group of withoutDeleted) {
      group.competitionPriorities = _.orderBy(
        group.competitionPriorities.filter((priority) => !priority._delete),
        ['_priority'],
        ['desc'],
      );
    }

    return _.orderBy(withoutDeleted, ['name'], ['asc']);
  },

  groupCompetitionsKeys(state, storeGetters) {
    return (group: CompetitionPriorityGroupExt) => {
      const keys =
        storeGetters.prioritiesGroups
          ?.find((grp: CompetitionPriorityGroupExt) => grp._uuid === group._uuid)
          ?.competitionPriorities
          ?.map((priority: CompetitionPriorityExt) => priority.key);
      return keys ? new Set(keys) : new Set();
    };
  },

  assignedCountries(state, storeGetters) {
    return new Set(storeGetters.prioritiesGroups.flatMap((group: CompetitionPriorityGroupExt) => group.countries));
  },

  assignedNames(state, storeGetters) {
    return new Set(storeGetters.prioritiesGroups.map((group: CompetitionPriorityGroupExt) => group.name));
  },

  dirty(state) {
    for (const group of state.groups) {
      if (group._delete || group._new || valuesChanged(group, ['name', 'countries'])) {
        return true;
      }

      for (const priority of group.competitionPriorities) {
        if (priority._delete || priority._new || valuesChanged(priority, ['priority'])) {
          return true;
        }
      }
    }
    return false;
  },
};

const mutations: MutationTree<CompetitionsPrioritiesState> = {
  SET_IS_LOADING(state) {
    state.isLoading = true;
  },

  CLEAR_IS_LOADING(state) {
    state.isLoading = false;
  },

  SET_IS_UPDATING(state) {
    state.isUpdating = true;
  },

  CLEAR_IS_UPDATING(state) {
    state.isUpdating = false;
  },

  SET_GROUPS(state, data: { groups: CompetitionPriorityGroup[], competition: Competition }) {
    state.competition = data.competition;

    if (data.groups.length === 0) {
      state.groups = [{
        name: 'Default',
        countries: ['default'],
        competitionPriorities: [],
        _name: 'Default',
        _countries: ['default'],
        _new: true,
        _uuid: uuid4(),
      }];
    } else {
      state.groups = data.groups.map((group) => {
        return {
          ...group,
          competitionPriorities: group.competitionPriorities.map((priority) => {
            return {
              ...priority,
              _priority: priority.priority,
              _uuid: uuid4(),
            };
          }),
          _uuid: uuid4(),
          _name: group.name,
          _countries: group.countries,
        };
      });
    }
  },

  ADD_COMPETITION_PRIORITY(state, data: { group: CompetitionPriorityGroupExt, priority: CompetitionPriority }) {
    const foundGroup = state.groups.find((g) => g._uuid === data.group._uuid);
    foundGroup?.competitionPriorities?.push({
      ...data.priority,
      priority: DEFAULT_PRIORITY,
      _priority: -1,
      _new: true,
      _uuid: uuid4(),
    });
  },

  ADD_COMPETITION_PRIORITY_GROUP(state, data: CompetitionPriorityGroup) {
    state.groups.push({
      ...data,
      _new: true,
      _uuid: uuid4(),
      _name: data.name,
      _countries: data.countries,
      competitionPriorities: [],
    });
  },

  DELETE_COMPETITION_PRIORITY_GROUP(state, data: CompetitionPriorityGroupExt) {
    if (data._new) {
      state.groups = state.groups.filter((group) => {
        return group._uuid !== data._uuid || group.countries.includes('default');
      });
    } else {
      state.groups = state.groups.map((group) => {
        return group._uuid !== data._uuid || group.countries.includes('default') ?
          group : { ...group, _delete: true };
      });
    }
  },

  UPDATE_COMPETITION_PRIORITY_GROUP(state, data: CompetitionPriorityGroupExt) {
    state.groups = state.groups.map((group) => group._uuid === data._uuid ? { ...data } : group);
  },

  COPY_COMPETITION_PRIORITY_GROUP(state, data: CompetitionPriorityGroupExt) {
    const newGroup = {
      ...data,
      id: undefined,
      _new: true,
      _uuid: uuid4(),
      competitionPriorities:
        data.competitionPriorities.filter((priority) => !priority._delete).map((priority) => {
          return {
            ...priority,
            id: undefined,
            _priority: -1,
            _new: true,
            _uuid: uuid4(),
          };
        }),
    };
    state.groups.push(newGroup);
  },


  UPDATE_COMPETITION_PRIORITIES(state, data: CompetitionPriorityGroup[]) {
    const groupsByCountries = data.reduce((acc, group) => {
      acc[group.name] = group;
      return acc;
    }, {} as Record<string, CompetitionPriorityGroup>);


    state.groups = state.groups.filter((group) => !group._delete).map((group) => {
      const updatedGroup = groupsByCountries[group.name];
      if (updatedGroup) {
        return {
          ...updatedGroup,
          _name: updatedGroup.name,
          _uuid: uuid4(),
          _countries: updatedGroup.countries,
          competitionPriorities: updatedGroup.competitionPriorities.map((priority) => ({
            ...priority,
            _priority: priority.priority,
            _uuid: uuid4(),
          })),
        };
      } else {
        return group;
      }
    });
  },

  DELETE_COMPETITION_PRIORITY(state, data: { group: CompetitionPriorityGroupExt, priority: CompetitionPriorityExt }) {
    const foundGroup = state.groups.find((group) => group._uuid === data.group._uuid);
    if (!foundGroup) { return; }

    if (data.priority._new) {
      foundGroup.competitionPriorities = foundGroup.competitionPriorities.filter((priority) => {
        return priority._uuid !== data.priority._uuid;
      });
    } else {
      foundGroup.competitionPriorities = foundGroup.competitionPriorities.map((priority) => {
        if (priority._uuid === data.priority._uuid) {
          return {
            ...priority,
            _delete: true,
          };
        } else {
          return priority;
        }
      });
    }
  },
};

const actions: ActionTree<CompetitionsPrioritiesState, any> = {
  async loadCompetitionsPriorities({ commit, rootState }, competition?: Competition) {
    commit('SET_IS_LOADING');

    try {
      const client = rootState.apolloClient();
      const response = await client.query({
        query: gql`query GetPriorities($competitionKey: String) {
          competitionsPrioritiesGroups(competitionKey: $competitionKey) {
            id
            name
            countries
            competitionPriorities {
              id
              key
              name
              groupId
              categoryId
              sportKey
              priority
            }
          }
        }`,
        variables: {
          competitionKey: competition?.key,
        },
      });

      if (competition) {
        for (const group of response.data.competitionsPrioritiesGroups) {
          if (group.competitionPriorities.length === 0) {
            group.competitionPriorities = [{
              _priority: DEFAULT_PRIORITY,
              _delete: false,
              _uuid: uuid4(),
              _new: false,
              categoryId: competition.categoryId,
              groupId: group.id,
              key: competition.key,
              name: competition.name,
              priority: DEFAULT_PRIORITY,
              sportKey: competition.sportKey,
            } as CompetitionPriorityExt];
          }
        }
      }

      commit('SET_GROUPS', { groups: response.data.competitionsPrioritiesGroups, competition });
    } finally {
      commit('CLEAR_IS_LOADING');
    }
  },

  async updateCompetitionPriorities({ commit, rootState, state }) {
    commit('SET_IS_UPDATING');

    const updateGroupsInput: UpdateGroupsInput = {
      groups: [],
      competitionKey: state.competition?.key,
    };

    for (const group of state.groups) {
      const prioritiesInput = group.competitionPriorities.filter((priority) => {
        return priority._new || priority._delete || valuesChanged(priority, ['priority']);
      }).map((priority) => {
        return {
          id: priority.id,
          priority: priority.priority,
          categoryId: priority.categoryId,
          key: priority.key,
          sportKey: priority.sportKey,
          delete: priority._delete,
        };
      });

      if (prioritiesInput.length > 0 ||
        group._new ||
        group._delete ||
        valuesChanged(group, ['name', 'countries'])) {
        updateGroupsInput.groups.push({
          id: group.id,
          name: group.name,
          countries: group.countries,
          competitionPriorities: prioritiesInput,
          delete: group._delete,
        });
      }
    }

    try {
      const client = rootState.apolloClient();
      const response = await client.mutate({
        mutation: gql`mutation UpdatePriorities($input: UpdateCompetitionPrioritiesGroupsInput!) {
          updateCompetitionPrioritiesGroups(input: $input) {
            id
            name
            countries
            competitionPriorities {
              id
              key
              name
              groupId
              categoryId
              sportKey
              priority
            }
          }
        }`,
        variables: {
          input: updateGroupsInput,
        },
      });

      commit('UPDATE_COMPETITION_PRIORITIES', response.data.updateCompetitionPrioritiesGroups);
    } finally {
      commit('CLEAR_IS_UPDATING');
    }
  },
};

export default {
  namespaced: true,
  state: {
    isLoading: false,
    isUpdating: false,
    groups: [],
    competition: undefined,
  } as CompetitionsPrioritiesState,
  getters,
  mutations,
  actions,
};
