import { MutationTree, ActionTree, GetterTree, Commit } from 'vuex';
import { Event, Translations } from '@/types/sports';
import { gql, ApolloClient } from 'apollo-boost';
import { NormalizedCacheObject } from '@apollo/client/core';
import _ from 'lodash';

interface EventsState {
  currentEvent?: Event;
  currentTranslations?: Translations;
  isLoadingID?: number;
}

const getters: GetterTree<EventsState, any> = {
  isLoading(state) {
    return state.isLoadingID ? true : false;
  },

  eventName(state) {
    return state.currentEvent?.name;
  },

  competitionName(state) {
    return state.currentEvent?.competition?.name;
  },

  eventSportKey(state) {
    return state.currentEvent?.sportKey;
  },
};

const mutations: MutationTree<EventsState> = {
  SET_CURRENT_EVENT(state, data) {
    state.currentEvent = data;
  },

  SET_CURRENT_TRANSLATIONS(state, data) {
    state.currentTranslations = data;
  },

  SET_IS_LOADING(state, data) {
    state.isLoadingID = data;
  },
};

const loadEvent = async (commit: Commit, client: ApolloClient<NormalizedCacheObject>, eventId: number) => {

  commit('SET_IS_LOADING', eventId);

  try {
    const response = await client.query({
      query: gql`query Event($eventId: String!) {
          event(eventId: $eventId) {
            id
            sportKey
            startsAt
            finishesAt
            name
            status
            type
            competitors {
              id
              key
              name
            }
            competition {
              categoryId
              id
              v2Key
              name
            }
            players {
              key
              name
              position {
                key
                name
              }
              team
            }
            providersData {
              keys
              provider
            }
            providerPriceControl
            outrightKey
          }
        }`,
      variables: {
        eventId: eventId.toString(),
      },
      fetchPolicy: 'network-only',
    });

    commit('SET_CURRENT_EVENT', response.data.event);
    commit('SET_CURRENT_TRANSLATIONS', response.data.translations);
  } finally {
    commit('SET_IS_LOADING', undefined);
  }
};

const actions: ActionTree<EventsState, any> = {
  async loadCurrentEvent({ commit, state, rootState }, eventId: number | string) {
    if (_.isString(eventId)) {
      eventId = parseInt(eventId, 10);
    }

    if (state.currentEvent?.id === eventId || state.isLoadingID === eventId) {
      return;
    }

    loadEvent(commit, rootState.apolloClient(), eventId);
  },

  async refreshCurrentEvent({ commit, state, rootState }, eventId: number | string) {
    if (_.isString(eventId)) {
      eventId = parseInt(eventId, 10);
    }

    if (state.isLoadingID === eventId) {
      return;
    }

    loadEvent(commit, rootState.apolloClient(), eventId);
  },
};


export default {
  namespaced: true,
  state: {
    currentEvent: undefined,
    currentTranslations: undefined,
    isLoadingID: undefined,
  } as EventsState,
  getters,
  mutations,
  actions,
};
