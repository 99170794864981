import Vue from 'vue';
import {
  VCard,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
  VExpansionPanels,
  VBtn,
  VIcon,
  VCheckbox,
} from 'vuetify/lib';
import './Watchlist.less';
import { mapActions } from 'vuex';
import { WatchlistItem } from '../WatchlistItem/WatchlistItem';
import '../WatchlistItem/WatchlistItem.less';
import { DEFAULT_TENNANT, SPORT, CASINO } from '@/config/watchlist';
import { hasRoles } from '@/utils/auth';

export const Watchlist = Vue.extend({
  props: {
    watchlistName: String,
    users: Object as () => Record<string, Record<string, any>>,
  },
  data() {
    return {
      watchOnStream: false,
      isClicked: false,
      enableConfirmation: false,
      hasDeleteWatchlistError: false,
      product: hasRoles(['sports:operator'])
        ? SPORT
        : hasRoles(['casino:operator'])
        ? CASINO
        : '',
    };
  },
  methods: {
    ...mapActions({
      deleteWatchlist: 'watchlists/deleteWatchlist',
    }),
    onDelete() {
      this.enableConfirmation = true;
    },
    async onDeleteConfirmation() {
      try {
        this.watchOnStream = false;
        this.watchOnStreamChanged();

        await this.deleteWatchlist({
          tennant: DEFAULT_TENNANT,
          watchlistName: this.watchlistName,
          product: this.product,
        });
      } catch (err) {
        this.hasDeleteWatchlistError = true;
      }
    },
    onCancel() {
      this.enableConfirmation = false;
    },
    watchOnStreamChanged() {
      const users = this.users ? Object.keys(this.users) : [];
      this.$emit('watchOnStream', users, this.watchOnStream);
    },
  },

  watch: {
    users(newUsers, oldUsers) {
      const newUUIDS = Object.keys(newUsers);
      const oldUUIDS = Object.keys(oldUsers);

      if (this.watchOnStream) {
        const removed = oldUUIDS.filter((uuid) => newUUIDS.indexOf(uuid) < 0);

        for (const uuid of removed) {
          this.$emit('watchOnStream', [uuid], false);
        }
      }
    },
  },

  render() {
    // TODO, reorder users
    const users = this.users ? Object.keys(this.users) : [];
    const watchlistContent = users?.map((user) => {
      return (
        <WatchlistItem
          key={user}
          watchlistName={this.watchlistName}
          playerUUID={user}
          playerName={this.users[user].name}
        />
      );
    });
    return (
      <VCard class='watchlist-container'>
        <VExpansionPanels>
          <VExpansionPanel>
            <VExpansionPanelHeader>
              <div class='text-h6 watchlist-name'>{this.watchlistName}</div>
              <div>
                {watchlistContent.length} <VIcon size='small'>person</VIcon>
              </div>
              <div>
                <VCheckbox dense
                  vModel={ this.watchOnStream }
                  label='stream?'
                  onClick={ this.watchOnStreamChanged }
                  nativeOnClick={(e: Event) => { e.cancelBubble = true; }}
                />
              </div>
            </VExpansionPanelHeader>
            <VExpansionPanelContent>
              <div class='watchlist-item-grid'>
                <div class='sparkline text-caption'>last 3 months</div>
                <div class='item-nickname text-caption'>nickname</div>
                <div class='item-last-activity text-caption'>
                  last transaction
                </div>
                <div class='item-averagebetsize text-caption'>Avg bet size</div>
                <div class='item-turnover text-caption'>turnover</div>
                <div class='item-pnl text-caption'>pnl (EUR)</div>
                <div class='item-margin text-caption'>margin</div>
                <div class='item-totalbets text-caption'>totalbets</div>
              </div>
              {watchlistContent}
              {hasRoles(['sports:operator', 'casino:operator']) &&
                (!this.enableConfirmation ? (
                  <VBtn
                    color='red'
                    class='delete-button mt-4'
                    icon
                    onClick={this.onDelete}
                  >
                    <VIcon>delete</VIcon>
                  </VBtn>
                ) : this.hasDeleteWatchlistError ? (
                  <div class='insufficient-permissions'>
                    Insufficient permissions
                  </div>
                ) : (
                  <div class='delete-confirmation mt-4'>
                    <VBtn
                      color='green'
                      class='delete-confirmation mr-4'
                      onClick={this.onDeleteConfirmation}
                    >
                      confirm
                    </VBtn>
                    <VBtn onClick={this.onCancel}>cancel</VBtn>
                  </div>
                ))}
            </VExpansionPanelContent>
          </VExpansionPanel>
        </VExpansionPanels>
      </VCard>
    );
  },
});
