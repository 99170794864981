import Vue from 'vue';
import {
  VBtn,
  VBtnToggle,
  VCol,
  VContainer,
  VRow,
  VCard,
  VTextarea,
  VCardActions,
  VSpacer,
  VCardText,
  VSwitch,
} from 'vuetify/lib';
import { mapActions } from 'vuex';
import PendingEvents from '@/components/sports/PendingEventsWidget/PendingEventsWidget';
import PendingMarkets from '@/components/sports/PendingEventsWidget/PendingMarketsWidget';
import EventScores from '@/components/sports/PendingEventsWidget/EventScoresWidget';
import EventResultWidget from '@/components/sports/EventResults';
import { PendingEvent } from '@/types/sports';

export default Vue.extend({
  data() {
    return {
      eventType: 'event',
      settlementData: 'scores',
      eventComment: '',
      isTrading: false,
      pendingEvent: null as PendingEvent | null,
    };
  },
  methods: {
    ...mapActions({
      updatePendingEventComment: 'pendingEvents/updatePendingEventComment',
      getPendingEventsAction: 'pendingEvents/getPendingEvents',
    }),
    selectEvent(pendingEvent: PendingEvent) {
      this.eventComment = '';
      this.pendingEvent = pendingEvent;
    },
    async updatePendingEventCommentAndRefresh(eventId: string, comment: string) {
      await this.updatePendingEventComment({ eventId, comment });
      await this.getPendingEventsAction({ eventType: this.eventType, isTrading: this.isTrading });
      this.pendingEvent = null;
    },
  },
  render() {
    return (
      <VContainer fluid>
        <VRow class='mt-2 ml-2'>
          <VCol><h1>Pending Events</h1></VCol>
        </VRow >
        <VRow class='mt-2 ml-2 mr-2'>
          <VCol md='6'>
            <VRow>
              <VCol>
                <VBtnToggle vModel={this.eventType} mandatory={true}>
                  <VBtn value='event'>Event</VBtn>
                  <VBtn value='outright'>Outright</VBtn>
                </VBtnToggle>
              </VCol>
              <VCol>
                <VSwitch vModel={this.isTrading} label='Including Trading' inset={true} />
              </VCol>
            </VRow>
            <VRow>
              <PendingEvents
                eventType={this.eventType}
                isTrading={this.isTrading}
                selectEvent={this.selectEvent}
              />
            </VRow>
            {this.pendingEvent &&
              <VRow>
                <VContainer fluid>
                  <VCard>
                    <VCardText>
                      <VTextarea
                        v-model={this.eventComment}
                        label='Event Comment'
                      ></VTextarea>
                    </VCardText>
                    <VCardActions>
                      <VSpacer></VSpacer>
                      <VBtn
                        color='green darken-1'
                        text
                        onClick={() => {
                          this.updatePendingEventCommentAndRefresh(
                            this.pendingEvent?.eventId as string,
                            this.eventComment);
                        }}>
                        UPDATE
                      </VBtn>
                    </VCardActions>
                  </VCard>
                </VContainer>
              </VRow>}
          </VCol>

          {this.pendingEvent &&
            <VCol md='6'>
              <VContainer fluid>
                <VRow>
                  <VBtnToggle vModel={this.settlementData} mandatory={true}>
                    <VBtn value='scores'>Scores</VBtn>
                    <VBtn value='results'>Results</VBtn>
                  </VBtnToggle>
                  {
                    this.settlementData === 'scores' ?
                      <EventScores eventId={this.pendingEvent.eventId} sportKey={this.pendingEvent.sportKey} /> :
                      <EventResultWidget eventId={this.pendingEvent.eventId} />
                  }
                </VRow>
                <VRow>
                  <PendingMarkets eventName={this.pendingEvent.name} eventId={this.pendingEvent.eventId} />
                </VRow>
              </VContainer>
            </VCol>
          }
        </VRow >
      </VContainer >
    );
  },
});
