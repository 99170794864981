import Vue from 'vue';
import {
  VSimpleTable,
} from 'vuetify/lib';
import { OrderBookState } from './types';
import { formatSize } from './helpers';
import { mapGetters } from 'vuex';

export const detailsColor = (isNightMode: boolean) => {
  return isNightMode ? 'grey darken-3' : 'grey lighten-3';
};

export default Vue.extend({
  props: {
    orderBookState: Object as () => OrderBookState,
    selectionId: Number,
    side: String,
    price: String,
  },

  computed: {
    ...mapGetters({
      isNightMode: 'preferences/isNightMode',
    }),
  },

  render() {
    const usersSizes = this.side === 'back' ?
      this.orderBookState.selections[this.selectionId]?.lays?.[this.price || ''] :
      this.orderBookState.selections[this.selectionId]?.backs?.[this.price || ''];

    return (
      <div class='mt-4'>
        <VSimpleTable class={detailsColor(this.isNightMode)}>
          <tr>
            <th>User</th>
            <th>Size</th>
          </tr>
          <tbody>
            {Object.entries(usersSizes || {}).map(([user, size]) => {
              return (
                <tr>
                  <td>{user}</td>
                  <td>{formatSize(size)}</td>
                </tr>
              );
            })}
          </tbody>
        </VSimpleTable>
      </div>
    );
  },
});

