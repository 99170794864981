import gql from 'graphql-tag';
import Vue from 'vue';
import { DocumentNode } from 'graphql';
import { VIcon, VBtn, VCard, VSkeletonLoader, VChip } from 'vuetify/lib';
import PlayerEventsWidget from '@/components/sports/PlayerEventsWidget';
import { PlayerSportsLoyalty } from '@/components/sports/PlayerSportsLoyalty';
import PlayerBalances from '@/components/PlayerBalances';
import { playerPageUri, troPlayerPageUri } from '@/utils/external-uris';
import { hasRoles } from '@/utils/auth';
import './Player.less';
import { AddToWatchlist } from '@/components/AddToWatchlist/AddToWatchlist';
import { PlayerGeneralTab } from './PlayerGeneralTab/PlayerGeneralTab';
import { PlayerBetSettings } from '@/components/sports/PlayerBetSettings/PlayerBetSettings';
import { SportsBonusControl } from '@/components/sports/SportsBonusControl/SportsBonusControl';
import PlayerSportsBets from '@/components/sports/PlayerBets';
import { PlayerTags } from '@/components/PlayerTags';
import CountryFlag from 'vue-country-flag';
import { SubCustomers } from '@/components/sports/SubCustomers';
import Referral from '@/components/Referral';

// Payments
import PlayerPaymentsWallet from '@/components/payments/PlayerPaymentsWallet';
import MissingDeposit from '@/components/payments/MissingDeposit';
import Transactions from '@/components/payments/Transactions';
import DepositAddresses from '@/components/payments/DepositAddresses';

// Casino
import PlayerCasinoBonuses from '@/components/casino/PlayerCasinoBonuses';
import PlayerCasinoFreespinsOffers from '@/components/casino/PlayerCasinoFreespinsOffers';
import PlayerCasinoEvolutionVouchers from '@/components/casino/PlayerCasinoEvolutionVouchers';
import PlayerCasinoLoyalty from '@/components/casino/PlayerCasinoLoyalty';
import PlayerCasinoMarketplacePurchases from '@/components/casino/PlayerCasinoMarketplacePurchases';
import ResponsiveTabs, { Tab } from '@/components/ResponsiveTabs';
import KYCDetails from '@/components/KYC/KYCDetails';

interface Player {
  blocked: boolean;
  id: number;
  nickname: string;
  signupCountry: string;
  tags: string[];
  uuid: string;
}

export default Vue.extend({
  computed: {
    playerUUID() {
      return this.$route.params.playerUUID;
    },
  },
  data() {
    return {
      player: null as null | Player,
    };
  },
  apollo: {
    player: {
      query(): DocumentNode {
        return gql`
          query($playerUUID: String!) {
            player: getPlayer(uuid: $playerUUID) {
              blocked
              id
              nickname
              signupCountry
              tags
              uuid
            }
          }
        `;
      },
      variables(): any {
        return { playerUUID: this.playerUUID };
      },
    },
  },
  methods: {
    tabs() {
      const tabs: Tab[] = [];

      tabs.push({
        requiresRoles: [],
        key: 'player-general',
        name: 'General',
        content: (
          <PlayerGeneralTab
            playerUUID={this.playerUUID}
            playerNickname={this.player?.nickname}
          />
        ),
      });

      tabs.push({
        requiresRoles: [
          'cs:operator',
          'cs:user',
          'payments:operator',
          'payments:user',
        ],
        key: 'player-missing-deposit',
        name: 'Missing deposit',
        content: <MissingDeposit pid={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: [
          'cs:operator',
          'cs:user',
          'finance:operator',
          'finance:user',
          'payments:operator',
          'payments:user',
          'pii:operator',
          'pii:user',
        ],
        key: 'player-transactions',
        name: 'Transactions',
        content: <Transactions playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: [
          'cs:operator',
          'cs:user',
          'finance:operator',
          'finance:user',
          'payments:operator',
          'payments:user',
          'pii:operator',
          'pii:user',
        ],
        key: 'player-deposit-addresses',
        name: 'Deposit Addresses',
        content: <DepositAddresses playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: [],
        key: 'player-bet-settings',
        name: 'Betting Config',
        content: (
          <PlayerBetSettings
            playerUUID={this.playerUUID}
            playerNickname={this.player?.nickname}
          />
        ),
      });

      tabs.push({
        requiresRoles: ['finance:operator', 'finance:user'],
        requiresLimits: ['manual_credit_limit'],
        key: 'account-balances',
        name: 'account balances',
        content: (
          <PlayerBalances
            playerID={this.player?.id}
            playerUUID={this.playerUUID}
          />
        ),
      });

      tabs.push({
        requiresRoles: ['sports:operator'],
        key: 'top-events',
        name: 'Top events',
        content: <PlayerEventsWidget playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['sports:operator', 'sports:user'],
        key: 'sub-customers',
        name: 'Sub customers',
        content: <SubCustomers playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['sports:operator', 'sports:user'],
        key: 'sports-bonuses',
        name: 'Sports bonuses',
        content: (
          <SportsBonusControl playerUUID={this.playerUUID} isOpen={true} />
        ),
      });

      tabs.push({
        requiresRoles: ['sports:operator', 'sports:user'],
        key: 'sports-loyalty',
        name: 'Sports loyalty',
        content: <PlayerSportsLoyalty playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['sports:operator', 'sports:user'],
        key: 'sports-bets',
        name: 'Sports bets',
        content: <PlayerSportsBets playerUUID={this.playerUUID} />,
      });

      // Casino
      tabs.push({
        requiresRoles: ['casino:operator', 'casino:user'],
        key: 'casino-bonuses',
        name: 'Casino bonuses',
        content: <PlayerCasinoBonuses playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['casino:operator', 'casino:user'],
        key: 'casino-freespins',
        name: 'Casino freespins',
        content: <PlayerCasinoFreespinsOffers playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['casino:operator', 'casino:user'],
        key: 'casino-evolution-vouchers',
        name: 'Casino evolution vouchers',
        content: <PlayerCasinoEvolutionVouchers playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['casino:operator', 'casino:user'],
        key: 'casino-loyalty',
        name: 'Casino loyalty',
        content: <PlayerCasinoLoyalty playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['casino:operator', 'casino:user'],
        key: 'casino-marketplace',
        name: 'Casino marketplace',
        content: (
          <PlayerCasinoMarketplacePurchases playerUUID={this.playerUUID} />
        ),
      });

      tabs.push({
        requiresRoles: [
          'cs:user',
          'ops:user',
          'payments:user',
          'cs:operator',
          'ops:operator',
          'payments:operator',
        ],
        key: 'payments-wallet',
        name: 'Payments wallet',
        content: (
          <PlayerPaymentsWallet
            playerUUID={this.playerUUID}
            playerNickname={this.player?.nickname}
          />
        ),
      });

      tabs.push({
        requiresRoles: ['sports:operator', 'casino:operator'],
        key: 'referral',
        name: 'Referral',
        content: <Referral playerUUID={this.playerUUID} />,
      });

      tabs.push({
        requiresRoles: ['cs:operator', 'pii:operator'],
        key: 'player-kyc',
        name: 'KYC',
        content: <KYCDetails playerUUID={this.playerUUID} nickname={this.player?.nickname} />,
      });

      return tabs;
    },
    copyPlayerUUID() {
      navigator.clipboard.writeText(this.playerUUID);
      // Hack for resetting highlight after click.
      setInterval(() => (document.activeElement as HTMLElement).blur(), 500);
    },
  },
  render() {
    return (
      <div class='player-view'>
        <VCard elevation={4} class='ma-4 pa-2'>
          {this.player ? (
            <div>
              <div class='add-button-wrapper'>
                <div>
                  <div class='nickname'>
                    <div class='text-body-1'>
                      <span>{this.player?.nickname}</span>&nbsp;
                      <small class='grey--text'>{this.playerUUID}</small>&nbsp;
                      <VIcon
                        class='content-copy'
                        size='small'
                        onClick={this.copyPlayerUUID}
                      >
                        content_copy
                      </VIcon>
                      &nbsp;
                      <CountryFlag
                        country={this.player?.signupCountry}
                        size='small'
                        rounded={true}
                      />
                      &nbsp;
                      <span>{this.player?.signupCountry}</span>&nbsp;
                      {this.player?.blocked && (
                        <VChip color='red' text-color='white' label small>
                          <VIcon left>block</VIcon>
                          BLOCKED
                        </VChip>
                      )}
                    </div>
                  </div>
                </div>

                {hasRoles(['sports:operator', 'casino:operator']) && (
                  <div class='ml-8'>
                    <AddToWatchlist
                      playerUUID={this.playerUUID}
                      nickname={this.player?.nickname || ''}
                    />
                  </div>
                )}
                <VBtn
                  class='ml-2'
                  small
                  href={playerPageUri(this.playerUUID)}
                  target='_blank'
                >
                  <VIcon>launch</VIcon>
                  <div class='text-caption ml-2'>Flightdeck</div>
                </VBtn>
                <VBtn
                  class='ml-2'
                  small
                  href={troPlayerPageUri(this.playerUUID)}
                  target='_blank'
                >
                  <VIcon>launch</VIcon>
                  <div class='text-caption ml-2'>TRO</div>
                </VBtn>
              </div>
              <PlayerTags
                tags={this.player.tags}
                playerUUID={this.player.uuid}
              ></PlayerTags>
            </div>
          ) : (
            <VSkeletonLoader type='list-item-two-line' />
          )}
        </VCard>
        <VCard elevation={4} class='ma-4'>
          <ResponsiveTabs tabs={this.tabs()} tabsOrder={['player-general']} />
        </VCard>
      </div>
    );
  },
});
