import Vue from 'vue';
import { EventCreatorCard } from './EventCreatorCard';

export const EventCreator = Vue.extend({
  render() {
    return (
            <EventCreatorCard onEventCreated={(genericEventId: string) => {
              this.$router.push({
                name: 'event',
                params: { eventId: genericEventId },
              });
            }} />
    );
  },
});
