import firebase from 'firebase/app';
import Vue from 'vue';
import Vuex, { GetterTree } from 'vuex';
import VuexPersist from 'vuex-persist';

import auth from './modules/auth';
import preferences from './modules/preferences';
import snackbar from './modules/snackbar';
import streams from './modules/streams';
import pendingEvents from './modules/pending_events';
import sportsProvidersStatus from './modules/sports_providers_status';
import customerBetSettings from './modules/customer_bet_settings';
import dashboard from './modules/dashboard';
import sportsConsts from './modules/sports_consts';
import sports from './modules/sports';
import exchangeRates from './modules/exchange_rates';
import watchlists from './modules/watchlists';
import announcements from './modules/announcements';
import viewByRole from './modules/viewByRole';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
  from,
} from 'apollo-boost';
import { env } from '@/env';
import tokenLink from '@/apollo_links/token-link';

Vue.use(Vuex);

function apolloClient(this: any) {
  const token = this.auth.idToken;
  const httpLink = new HttpLink({
    uri: env.gateway.httpUri,
    headers: { authorization: token ? `Bearer ${token}` : null },
  });

  return new ApolloClient({
    link: from([tokenLink, httpLink]),
    cache: new InMemoryCache({
      dataIdFromObject: (o) => o.id ? `${o.__typename}-${o.id}` : null,
    }),
  });
}

export interface RootState {
  db: () => firebase.firestore.Firestore;
  appVersion: string;
  apolloClient: () => ApolloClient<NormalizedCacheObject>;
}

const state: RootState = {
  db: () => firebase.firestore(),
  appVersion:
    process.env.VUE_APP_VERSION || process.env.VUE_APP_PACKAGE_VERSION || '0',
  apolloClient,
};

const getters: GetterTree<RootState, any> = {
  appVersion({ appVersion }): string {
    return appVersion;
  },
};

const vuexLocal = new VuexPersist<RootState>({
  storage: window.localStorage,
  modules: ['auth', 'preferences', 'streams', 'viewByRole'],
});

const vuexSession = new VuexPersist<RootState>({
  storage: window.sessionStorage,
  modules: ['sportsConsts'],
});

const store = new Vuex.Store<RootState>({
  state,
  getters,
  mutations: {},
  actions: {},
  modules: {
    auth,
    preferences,
    pendingEvents,
    sportsProvidersStatus,
    customerBetSettings,
    streams,
    snackbar,
    dashboard,
    sportsConsts,
    sports,
    exchangeRates,
    viewByRole,
    watchlists,
    announcements,
  },
  plugins: [vuexLocal.plugin, vuexSession.plugin],
});

export default store;
