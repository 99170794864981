import { uuid4 } from '@/utils/uuid';
import Vue from 'vue';
import {
  VAlert,
  VBtn,
  VCard, VCardText, VCardTitle,
  VDialog,
  VForm,
  VOverlay,
  VProgressCircular, VRow, VSelect, VSpacer, VTextField,
} from 'vuetify/lib';

const KEY_PREFIX = 'bgl:competitor:';

const TYPES = ['TEAM', 'PLAYER'];

function newCompetitorKey(): string {
  return KEY_PREFIX + uuid4();
}

export const CompetitorDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    error: String,
    loading: Boolean,
    sport: Object as () => { name: string; key: string },
    competition: Object as () => { name: string; key: string },
    provider: String,
    savingTypes: Array as () => string[],
  },
  data() {
    return {
      name: '',
      abbreviation: '',
      type: TYPES[0],
      nationality: '',
    };
  },
  methods: {
    onClose() {
      this.$emit('closeDialog');
    },
    onSave(type: string) {
      this.$emit(
        'save',
        {
          abbreviation: this.abbreviation,
          competitionKey: this.competition.key,
          competitorType: this.type,
          nationality: this.nationality,
          provider: this.provider,
          providerCompetitorKeys: [newCompetitorKey()],
          providerCompetitorName: this.name,
          sportKey: this.sport.key,
        },
        type,
      );
    },
  },
  render() {
    return (
      <VDialog onInput={this.onClose} value={this.isOpen} max-width='500'>
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>
          <VCardTitle>New competition</VCardTitle>
          <VCardText>
            <VForm>
              <VSelect
                items={[this.provider]}
                vModel={this.provider}
                label='Provider'
                dense
                outlined
                disabled
              />
              <VSelect
                items={[this.sport]}
                vModel={this.sport}
                label='Sport'
                dense
                outlined
                disabled
                itemText='name'
                itemValue={(i: any) => i}
              />
              <VSelect
                items={[this.competition]}
                vModel={this.competition}
                label='Competition'
                dense
                outlined
                disabled
                itemText={(i: any) => `${i.name} (${i.key})`}
              />
              <VSelect
                items={TYPES}
                vModel={this.type}
                label='Type'
                dense
                outlined
              />
              <VTextField vModel={this.name} label='Name' dense outlined />
              <VTextField
                vModel={this.abbreviation}
                label='Abbreviation'
                dense
                outlined
                onChange={(v: string) => {
                  this.abbreviation = this.abbreviation
                    .toUpperCase()
                    .substr(0, 3);
                }}
              />
              {this.type === 'PLAYER' && <VTextField
                vModel={this.nationality}
                label='Nationality'
                dense
                outlined
              />}
            </VForm>
            <VRow class='pa-3'>
              <VBtn onClick={this.onClose}>Discard</VBtn>
              {this.savingTypes.map((type) => [
                <VSpacer />,
                <VBtn onClick={() => this.onSave(type)} color='primary'>
                  Save ({type})
                </VBtn>,
              ])}
            </VRow>
            {this.error ? <VAlert type='warning'>{this.error}</VAlert> : null}
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
