import { unixToDateTimeUTCString } from '@/utils/date';
import Vue from 'vue';
import { VDataTable, VToolbar, VToolbarTitle, VProgressLinear, VSpacer, VBtn, VContainer, VIcon } from 'vuetify/lib';
import { LoyaltyScheduleDialog } from './LoyaltyScheduleDialog';
import { hasRoles } from '@/utils/auth';
import { round } from '@/filters';
import gql from 'graphql-tag';

function formatTime(time: string): string {
  return unixToDateTimeUTCString(Date.parse(time) / 1000);
}

export enum RewardsScheme {
  Revenue = 'revenue',
  Turnover = 'turnover',
}

export interface FixedSchedule {
  __typename?: 'FixedSchedule';
  id: string;
  name: string;
  scheme: RewardsScheme;
  percentage: string;
  minPrice: string;
  rolloverRequirement: string;
  scheduleInDays: number;
  scheduleStartDate: string;
  disabled: boolean;
  createdAt: string;
  playerCount: number;
}

const allScheduleFields = `
id
name
scheme
percentage
minPrice
rolloverRequirement
scheduleInDays
disabled
createdAt
playerCount
scheduleStartDate`;

export const LoyaltySchedules = Vue.extend({
  data() {
    return {
      isLoading: false,
      scheduleDialogIsOpen: false,
      schedule: {},
      fixedSchedules: [] as FixedSchedule[],
      error: '',
    };
  },

  computed: {
    canOperate() {
      return hasRoles(['sports:operator']);
    },

    headers() {
      return [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Scheme',
          value: 'scheme',
        },
        {
          text: 'Percentage',
          value: 'percentage',
        },
        {
          text: 'Every',
          value: 'scheduleInDays',
        },
        {
          text: 'Min. price',
          value: 'minPrice',
        },
        {
          text: 'Rollover req.',
          value: 'rolloverRequirement',
        },
        {
          text: 'Start date',
          value: 'scheduleStartDate',
        },
        {
          text: 'Disabled',
          value: 'disabled',
        },
        {
          text: '#players',
          value: 'playerCount',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },

    toolbar() {
      return (
        <VToolbar>
          <VToolbarTitle>Loyalty schedules</VToolbarTitle>
          <VProgressLinear
            active={false}
            indeterminate={false}
            absolute
            bottom
            color='deep-purple accent-4'>
          </VProgressLinear>
          <VSpacer />
          { this.canOperate ? (
              <VBtn onClick={() => { this.openScheduleDialog(); }}>
                New
              </VBtn>
            ) : null}
        </VToolbar>
      );
    },

    scheduleDialog() {
      if (!this.scheduleDialogIsOpen) {
        return null;
      }

      return <LoyaltyScheduleDialog
        isOpen={this.scheduleDialogIsOpen}
        schedule={this.schedule}
        schedules={this.fixedSchedules}
        error={this.error}
        on={{
          close: () => {
            this.scheduleDialogIsOpen = false;
            this.schedule = {};
          },
          save: this.onSave,
        }}/>;
    },

    table() {
      return <VDataTable
        hideDefaultFooter
        headers={this.headers}
        items={this.fixedSchedules}
        itemKey='name'
        itemsPerPage={-1}
        disableSort={true}
        loadingText='Loading... Please wait'
        loading={this.isLoading}
        scopedSlots={{
          'item.percentage': this.percentage,
          'item.createdAt': this.createdAt,
          'item.scheduleStartDate': this.scheduleStartDate,
          'item.actions': this.actions,
        }}
      />;
    },
  },

  apollo: {
    fixedSchedules: {
      client: 'sportsLoyaltyService',
      query: gql`
        query {
          fixedSchedules {
            ${allScheduleFields}
          }
        }
      `,
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      pollInterval: 5000,
    },
  },

  methods: {
    async onSave(input: FixedSchedule) {
      try {
        const scheduleInput = {
          name: input.name,
          scheme: input.scheme,
          percentage: input.percentage,
          minPrice: input.minPrice,
          rolloverRequirement: input.rolloverRequirement,
          scheduleInDays: input.scheduleInDays,
          scheduleStartDate: (new Date(input.scheduleStartDate)).toISOString(),
          disabled: input.disabled,
        };

        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation ($schedule: FixedScheduleInput!) {
              fixedSchedule(input: $schedule) {
                ${allScheduleFields}
              }
            }
          `,
          variables: {
            schedule: scheduleInput,
          },
        });

        if (result.data.fixedSchedule) {
          const idx = this.fixedSchedules.findIndex((item) => item.id === result.data.fixedSchedule.id);
          if (idx >= 0) {
            Object.assign(this.fixedSchedules[idx], result.data.schedule);
          } else {
            this.fixedSchedules.push(result.data.fixedSchedule);
          }
        }

        this.scheduleDialogIsOpen = false;
        this.schedule = {};
      } catch (err) {
        this.error = err.message;
      }
    },

    percentage({ item }: { item: FixedSchedule }) {
      return parseFloat(item.percentage) + '%';
    },

    createdAt({ item }: { item: FixedSchedule }) {
      return formatTime(item.createdAt);
    },

    scheduleStartDate({ item }: { item: FixedSchedule }) {
      return formatTime(item.scheduleStartDate);
    },

    actions({ item }: { item: FixedSchedule }) {
      if (!this.canOperate) { return null; }

      return (
        <VBtn small icon depressed>
          <VIcon onClick={() => { this.openScheduleDialog(item); }}>edit</VIcon>
        </VBtn>
      );
    },

    openScheduleDialog(schedule: any = {}) {
      this.scheduleDialogIsOpen = true;
      this.schedule = schedule;
    },
  },

  render() {
    return (
      <VContainer fluid>
        {this.toolbar}
        {this.scheduleDialog}
        {this.table}
      </VContainer>
    );
  },
});
