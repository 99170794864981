import Vue from 'vue';
import {
  VContainer,
  VTabs,
  VTab,
  VTabItem,
  VTabsSlider,
  VToolbar,
  VToolbarTitle,
  VRow,
  VCol,
  VAutocomplete,
  VTabsItems,
} from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import PinnacleOverlay from '@/components/sports/PinnacleOverlay';
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import CompetitionsGrades from '@/components/sports/CompetitionsGrades';
const { mapActions, mapState } = createNamespacedHelpers('sportsConsts');

export default Vue.extend({
  name: 'sport',

  computed: {
    ...mapState(['sportsKeysIdsMap']),
    ...mapGetters({ sports: 'sportsConsts/sports' }),

    sportKey(): string {
      return this.$route.params.sportKey.toString();
    },

    sportId(): number {
      return this.sportsKeysIdsMap[this.sportKey];
    },

    sportName(): string {
      return getSportsName(this.sportKey, Locale.en);
    },

    toolbar() {
      return (
        <VToolbar>
          <VToolbarTitle>
            <VAutocomplete
              on={{
                change: () => {
                  const params = { sportKey: this.sport };
                  const hash = this.$route.hash;
                  this.$router.push({ name: 'sport', params, hash });
                },
              }}
              dense
              outlined
              class='pt-6'
              vModel={this.sport}
              item-value='key'
              item-text='name'
              label='Sport'
              items={this.sports} />
          </VToolbarTitle>
        </VToolbar>
      );
    },
  },

  data() {
    return {
      tab: 'competitions',
      sport: '',
    };
  },
  methods: {
    ...mapActions(['loadSportsConsts']),

    tabs() {
      return [
        {
          requiresRoles: ['sports:user', 'sports:operator'],
          key: 'competitions',
          name: 'Competitions',
          content: (
            <div>
              {this.sportKey &&
                <CompetitionsGrades sportKey={this.sportKey} />
              }
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'pinnacle-overlay',
          name: 'Pinnacle Overlay',
          content: (
            <div>
              {this.sportId &&
                <VRow>
                  <VCol md='4' offset-md='4'>
                    <PinnacleOverlay entityId={this.sportId} entityType={'sport'} />
                  </VCol>
                </VRow>
              }
            </div>
          ),
        },
      ];
    },
  },

  mounted() {
    this.loadSportsConsts();

    this.sport = this.sportKey;
  },

  render() {
    return (
      <VContainer fluid>
        {this.toolbar}
        <VTabs v-model={this.tab}>
          <VTabsSlider></VTabsSlider>
          {this.tabs()
            .filter((tab) => hasRoles(tab.requiresRoles))
            .map((tab: any) => (
              <VTab to={'#' + tab.key} href={'#' + tab.key}>
                {tab.name}
              </VTab>
            ))}
        </VTabs>
        <VTabsItems vModel={this.tab} touchless={true}>
          {this.tabs()
            .filter((tab) => hasRoles(tab.requiresRoles))
            .map((tab: any) => (
              <VTabItem value={tab.key}>{tab.content}</VTabItem>
            ))}
        </VTabsItems>
      </VContainer>
    );
  },
});
