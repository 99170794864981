import Vue, { PropType } from 'vue';
import { VChip, VCombobox } from 'vuetify/lib';
import { playerTagConfig } from '@/config/playerTagConfig';
import { gql } from 'apollo-boost';
import { hasRoles } from '@/utils/auth';
import _ from 'lodash';

const PlayerTags = Vue.extend({
  props: {
    tags: Array as PropType<string[]>,
    playerUUID: String as PropType<string>,
  },

  data() {
    return {
      tagsData: [...this.tags],
      originalTags: [...this.tags],
      allTags: [] as string[],
      updating: false,
    };
  },

  apollo: {
    allTags: {
      query: gql` query {
        getAllPlayersTags
      }`,

      update(data) {
        return data.getAllPlayersTags;
      },
    },
  },

  methods: {
    selectionSlot({ item }: { item: string }) {
      return (
        <VChip
          small
          close={this.enabled && !playerTagConfig[item.toLocaleLowerCase()]?.undeletable}
          label
          color={playerTagConfig[item.toLowerCase()]?.color}
          on={{ 'click:close': () => this.deleteTag(item) }}>
          {item}
        </VChip>
      );
    },

    async deleteTag(tag: string) {
      try {
        this.updating = true;
        const result = await this.$apollo.mutate({
          mutation: gql`
          mutation ($uuid: String!, $tag: String!) {
            deletePlayerTag(uuid: $uuid, tag: $tag)
          }
        `,
          variables: {
            uuid: this.playerUUID,
            tag,
          },
        });

        this.tagsData = result.data.deletePlayerTag;
        this.originalTags = result.data.deletePlayerTag;
      } catch (e) {
        throw e;
      } finally {
        this.updating = false;
      }
    },

    async addTag(tag: string) {
      try {
        this.updating = true;
        const result = await this.$apollo.mutate({
          mutation: gql`
          mutation ($uuid: String!, $tag: String!) {
            addPlayerTag(uuid: $uuid, tag: $tag)
          }
        `,
          variables: {
            uuid: this.playerUUID,
            tag,
          },
        });

        this.tagsData = result.data.addPlayerTag;
        this.originalTags = result.data.addPlayerTag;
      } catch (e) {
        throw e;
      } finally {
        this.updating = false;
      }
    },

    async onTagsChange() {
      const deleted = _.difference(this.originalTags, this.tagsData);
      if (deleted.length > 0) {
        this.deleteTag(deleted[0]);
      }

      const added = _.difference(this.tagsData, this.originalTags);
      if (added.length > 0) {
        this.addTag(added[0]);
      }
    },
  },

  computed: {
    enabled(): boolean {
      return hasRoles(['pii:operator', 'ops:operator']);
    },
  },

  render() {
    return (
      <VCombobox chips multiple dense
        loading={(this.$apollo.queries.allTags.loading || this.updating) && 'warning'}
        loaderHeight={1}
        vModel={this.tagsData}
        items={this.allTags}
        scopedSlots={{ selection: this.selectionSlot }}
        disabled={!this.enabled}
        on={{ change: this.onTagsChange }} />
    );
  },
});

export { PlayerTags };
