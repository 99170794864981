import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { VCheckbox, VSwitch, VSnackbar, VBtn, VChip } from 'vuetify/lib';
import { userRoles } from '../../userRoles';
import './ViewByRole.less';
export default Vue.extend({
  data() {
    return { radioGroup: 0, isViewAsOtherRolesActive: false, selected: [] };
  },
  computed: {
    ...mapGetters({
      isViewByRoleActive: 'viewByRole/isViewByRoleActive',
      pseudoRoles: 'viewByRole/pseudoRoles',
    }),
  },
  created() {
    this.$data.isViewAsOtherRolesActive = this.isViewByRoleActive;
    this.$data.selected = this.pseudoRoles;
  },
  methods: {
    ...mapActions({
      signout: 'auth/signout',
      toggleViewByRole: 'viewByRole/toggleViewByRole',
      setViewAsRole: 'viewByRole/setViewAsRole',
    }),
  },
  watch: {
    isViewAsOtherRolesActive(val) {
      this.toggleViewByRole(val);
      if (!val) {
        this.$data.selected = [];
      }
    },
    selected(val) {
      this.setViewAsRole(val);

      if (val.length > 0) {
        this.toggleViewByRole(true);
        this.$data.isViewAsOtherRolesActive = this.isViewByRoleActive;
      }
    },
  },
  render() {
    const businessDomains = Object.keys(userRoles);
    const dataTable = businessDomains.map((domain, i) => {
      const roles = Object.keys(userRoles[domain]);
      const rows = roles.map((role, index) => {
        return (
          <div class={index === 0 ? 'roles-operator-col' : 'roles-user-col'}>
            <VCheckbox
              class='roles-checkbox'
              value={userRoles[domain][role]}
              vModel={this.$data.selected}
            />
          </div>
        );
      });
      return (
        <div
          class={
            i === businessDomains.length - 1
              ? 'roles-row'
              : 'roles-row roles-row-border'
          }
        >
          <div class='roles-business-function-col'>{domain}</div>
          {rows}
        </div>
      );
    });

    return (
      <div>
        <div class='ml-4'>
          <div class='view-by-role-title-container'>
            <div class='mb-4'>
              <div class='view-by-role-title'>View application as: </div>
              <div class='font-italic'>
                * this is a permissions/role simulator tool. It will allow you
                to explore the app as the selected role, BUT, it will not grant
                you access to data if you are originally not permissioned to
                view it.
              </div>
            </div>
            <div class='chip-container'>
              {this.$data.selected.map((role: string) => (
                <VChip class='roles-chips'>{role}</VChip>
              ))}
            </div>
          </div>
          <div class='roles-table'>
            <div class='header-row'>
              <div class='roles-business-function-col'>Business Function</div>
              <div class='roles-operator-col'>operator</div>
              <div class='roles-user-col'>user</div>
            </div>
            {dataTable}
          </div>
          <div class='role-switch-container'>
            <div class='mr-4'>Use default roles</div>
            <VSwitch vModel={this.$data.isViewAsOtherRolesActive}></VSwitch>
            <div class='ml-4'>Use selected roles</div>
          </div>
        </div>
      </div>
    );
  },
});
