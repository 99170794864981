import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface ViewByRoleProps {
  isViewByRoleActive: boolean;
  pseudoRoles: string[] | null;
}

const getters: GetterTree<ViewByRoleProps, any> = {
  isViewByRoleActive(state): boolean {
    return !!state.isViewByRoleActive;
  },
  pseudoRoles(state): string[] {
    return state.pseudoRoles || [];
  },
};

const mutations: MutationTree<ViewByRoleProps> = {
  SET_VIEW_BY_ROLE(state, data) {
    state.isViewByRoleActive = data;
  },
  SET_PSEUDO_ROLE(state, data) {
    state.pseudoRoles = data;
  },
};

const actions: ActionTree<ViewByRoleProps, any> = {
  async toggleViewByRole({ commit }, isActive: boolean) {
    commit('SET_VIEW_BY_ROLE', isActive);
  },
  setViewAsRole({ commit }, pseudoRoles: string[]) {
    commit('SET_PSEUDO_ROLE', pseudoRoles);
  },
};

const ViewByRole = {
  namespaced: true,
  state: {
    pseudoRoles: null,
    isViewByRoleActive: false,
  } as ViewByRoleProps,
  getters,
  mutations,
  actions,
};

export default ViewByRole;
