import './SingleCompetitionTranslation.less';
import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VTextField, VIcon, VProgressCircular } from 'vuetify/lib';

interface CompetitionTranslation {
  translation: string;
  currentTranslation: string;
  id: number;
}
export const SingleCompetitionTranslation = Vue.extend({
  props: {
    entityType: String,
    competitionId: String,
    locale: String,
  },
  data() {
    return {
      competition: {} as CompetitionTranslation,
    };
  },
  methods: {
    editTranslation(props: any): JSX.Element {
      return <VTextField vModel={props.item.translation} />;
    },
    async onSaveClick() {
      const res = await this.$apollo.mutate({
        mutation: gql`
          mutation($input: TranslationInput!) {
            updateTranslation(input: $input) {
              success
            }
          }
        `,
        variables: {
          input: {
            entityType: 'competition',
            id: Number(this.competitionId),
            locale: this.locale,
            value: this.competition.translation,
          },
        },
        context: {
          hasUpload: true,
        },
      });
      if (res.data.updateTranslation.success) {
        this.competition.currentTranslation = this.competition.translation;
      }
    },
  },
  apollo: {
    competition: {
      query: gql`
        query($entityType: String!, $ids: [Int]!, $locales: [String]!) {
          translations(entityType: $entityType, ids: $ids, locales: $locales) {
            entities {
              id
              translations {
                locale
                value
              }
            }
          }
        }
      `,
      variables(): {
        entityType: string;
        ids: number[];
        locales: string[];
      } {
        return {
          entityType: this.entityType,
          ids: [Number(this.competitionId)],
          locales: [this.locale],
        };
      },
      update(data) {
        if (data.translations.entities.length === 0) {
          return {};
        }

        if (data.translations.entities[0].translations.length === 0) {
          return {};
        }

        return {
          id: data.translations.entities[0].id as number,
          currentTranslation: data.translations.entities[0].translations[0].value,
          translation: data.translations.entities[0].translations[0].value,
        } as CompetitionTranslation;
      },
      fetchPolicy: 'network-only',
    },
  },

  render() {
    if (this.$apollo.queries.competition.loading) {
      return (
        <div class='single-competition-translation-wrapper'>
          <VTextField
            hideDetails
            placeholder='loading translations'
            dense
            disabled
            outlined
          />
          <VProgressCircular class='ml-4' color='primary' indeterminate />
        </div>
      );
    }
    return (
      <div class='single-competition-translation-wrapper'>
        <VTextField
          class='ma-0'
          dense
          hideDetails
          outlined
          vModel={this.competition.translation}
        />
        <div class='action ml-4'>
          <VIcon
            disabled={this.competition.currentTranslation === this.competition.translation}
            onClick={this.onSaveClick}
            color='warning'
          >
            save
          </VIcon>
        </div>
      </div>
    );
  },
});
