import moment from 'moment';
import _ from 'lodash';

// Function: transform a date to a string with milliseconds
export const formatDateWithMilliseconds = (date: Date, withSpace?: boolean) => {
  // Fill the space and return the string with places for the text to make it looks better
  // 9:32:33.135 PM
  // 9:32:33.1   PM
  // 9:32:33.12  PM
  const milliseconds = () => {
    let millisec = `${date.getMilliseconds()}`;
    if (withSpace) {
      for (let i = 0; i < 3 - millisec.length; i++) {
        millisec += '\u00A0'; // space
      }
    }
    return millisec;
  };
  let dateString = date.toLocaleTimeString();
  const regex = /pm|am$/i;
  // Has am/pm on the string
  if (regex.test(dateString)) {
    dateString.replace(' ', `.${milliseconds()} `);
  } else {
    dateString += `.${milliseconds()} `;
  }
  return dateString;
};

const defaultDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
const defaultDateFormat = 'YYYY-MM-DD';

export const unixToDateTimeLocalString = (dateTime: number) => {
  return moment.unix(dateTime).format(defaultDateTimeFormat);
};

export const unixToDateTimeUTCString = (dateTime: number | string) => {
  const timestamp = _.isNumber(dateTime) ? dateTime : parseInt(dateTime, 10);
  return moment.unix(timestamp).utc().format(defaultDateTimeFormat) + ' UTC';
};

export const unixToDateUTCString = (dateTime: number | string, utcSuffix = true) => {
  const timestamp = _.isNumber(dateTime) ? dateTime : parseInt(dateTime, 10);
  return moment.unix(timestamp).utc().format(defaultDateFormat) + (utcSuffix ? ' UTC' : '');
};

export const unixToDateTimeISOString = (dateTime: string | number) => {
  const timestamp = _.isNumber(dateTime) ? dateTime : parseInt(dateTime, 10);
  return new Date(timestamp * 1000).toISOString().replace(/[.]\d+/, '');
};

export const iso8601ToDateTimeUTCString = (dateTime: string) => {
  return moment(dateTime).utc().format(defaultDateTimeFormat) + ' UTC';
};

/**
 * @deprecated Use unixToDateLocalString instead
 */
export const unixToDateTimeString = unixToDateTimeLocalString;

export const dateTimeISOStringToUnix = (dateTime: string) => {
  const date = new Date(dateTime);
  return Math.floor(date.getTime() / 1000);
};

