import Vue from 'vue';
import {
  VContainer, VRow, VCol, VSelect, VBtn, VIcon, VProgressLinear, VDataTable, VProgressCircular, VOverlay, VAlert,
} from 'vuetify/lib';
import { Line } from 'vue-chartjs';
import { hasRoles } from '@/utils/auth';
import { LoyaltyDialog } from './LoyaltyDialog';
import { OfferDialog } from './OfferDialog';
import gql from 'graphql-tag';
import { round, fixWidth, formatCurrency } from '@/filters';
import { unixToDateTimeUTCString } from '@/utils/date';
import { Player, FixedSchedule, Mode, LoyaltyStatus, PlayerInput, OfferInput, Offer, OfferType } from './interfaces';
import ConfirmationDialog from '@/components/ConfirmationDialog';


const TimelineChart = Vue.extend({
  mixins: [Line],
  mounted() {
    // @ts-ignore
    this.renderChart({ datasets: [{ label: 'xp', data: [1, 2] }]}, {});
  },
});

const allPlayerFields = `
id
status {
  mode
  tier
  completion
  personalHighScore
  todaysScore
  todaysScoreResetsAt
  trend {
    tier
    days
  }
  rcMultiplier
  xpMultiplier
  offers {
    id
    type
    validUntil
    multiplier
    multiplierValidHours
  }
  localRewardCashBalance
}
fixedSchedule {
  id
  name
  scheduleInDays
  scheme
  percentage
}
fixedScheduleHiddenToPlayer
telegramContact
nextScheduledRewardAt
`;

function daysText(value: number): string {
  return value + (value > 1 ? ` days` : ' day');
}

function formatTime(time: string): string {
  return unixToDateTimeUTCString(Date.parse(time) / 1000);
}

export const PlayerSportsLoyalty = Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      mode: Mode.Manual,
      dialogIsOpen: false,
      offerDialogIsOpen: false,
      error: '',
      player: {} as Player,
      offersLoading: false,

      refreshLoading: false,
      refreshError: '',

      resetCashLoading: false,
      resetCashError: '',
      resetCashDialogIsOpen: false,

      releaseRewardsLoading: false,
      releaseRewardsError: '',
      releaseRewardsDialogIsOpen: false,
    };
  },

  computed: {
    canOperate() {
      return hasRoles(['sports:operator']);
    },

    manualModeDetails() {
      if (this.player.status.mode !== Mode.Manual) {
        return null;
      }

      return [
        <VCol md={6} sm={12}>
          <VRow>
            <VCol>Tier</VCol>
            <VCol align='end'>{this.player.status.tier}</VCol>
          </VRow>

          {this.cashBalanceRow}
        </VCol>,
      ];
    },

    manualProDetails() {
      if (this.player.status.mode !== Mode.Pro) {
        return null;
      }

      return [
        <VCol md={6} sm={12}>
          <VRow>
            <VCol>Tier</VCol>
            <VCol align='end'>{this.player.status.tier}</VCol>
          </VRow>

          {this.cashBalanceRow}
        </VCol>,
      ];
    },

    vipModeDetails() {
      if (this.player.status.mode !== Mode.Vip && this.player.status.mode !== Mode.Custom) {
        return null;
      }

      if (!this.player.fixedSchedule) {
        return [
          <VCol md={6} sm={12}>
            <VRow>
              <VCol>
                <b>Details</b>
              </VCol>
            </VRow>

            <VRow>
              <VCol>Tier</VCol>
              <VCol align='end'>{this.player.status.tier}</VCol>
            </VRow>

            {this.cashBalanceRow}

            <VRow>
              <VCol>Schedule</VCol>
              <VCol align='end'>none</VCol>
            </VRow>
          </VCol>,

          <VCol md={6} sm={12}>
          </VCol>,
        ];
      }

      return [
        <VCol md={6} sm={12}>
          <VRow>
            <VCol>
              <b>Details</b>
            </VCol>
          </VRow>

          <VRow>
            <VCol>Tier</VCol>
            <VCol align='end'>{this.player.status.tier}</VCol>
          </VRow>

          <VRow>
            <VCol>Pending rewards</VCol>
            <VCol align='end'>{formatCurrency(this.player.status.localRewardCashBalance, 2)}</VCol>
          </VRow>

          <VRow>
            <VCol>Schedule</VCol>
            <VCol align='end'>{this.player.fixedSchedule!.name}</VCol>
          </VRow>

          <VRow>
            <VCol>Next scheduled reward at</VCol>
            <VCol align='end'>
              {
                this.player.nextScheduledRewardAt ?
                  formatTime(this.player.nextScheduledRewardAt!) :
                  'never'
              }
            </VCol>
          </VRow>

          <VRow>
            <VCol>Hidden to player</VCol>
            <VCol align='end'>{this.player.fixedScheduleHiddenToPlayer ? 'yes' : 'no'}</VCol>
          </VRow>
        </VCol>,

        <VCol md={6} sm={12}>
          <VRow>
            <VCol>
              <b>Schedule details</b>
            </VCol>
          </VRow>

          <VRow>
            <VCol>Every</VCol>
            <VCol align='end'>{this.player.fixedSchedule!.scheduleInDays} days</VCol>
          </VRow>

          <VRow>
            <VCol>Scheme</VCol>
            <VCol align='end'>{this.player.fixedSchedule!.scheme}</VCol>
          </VRow>

          <VRow>
            <VCol>Percentage</VCol>
            <VCol align='end'>{round(this.player.fixedSchedule!.percentage, 2)}%</VCol>
          </VRow>
        </VCol>,
      ];
    },

    cashBalanceRow() {
      return <VRow>
        <VCol>Pending rewards</VCol>
          <VCol align='end'>
            {this.canOperate && [
              <VBtn
                onClick={() => this.releaseRewardsDialogIsOpen = true}
                loading={this.releaseRewardsLoading} x-small class='ma-2'>
                release
              </VBtn>,
              <VBtn
                onClick={() => this.resetCashDialogIsOpen = true}
                loading={this.resetCashLoading} x-small class='ma-2'>
                zero out
              </VBtn>,
            ]}
            {formatCurrency(this.player.status.localRewardCashBalance, 2)}
          </VCol>
      </VRow>;
    },

    loyaltyModeDetails() {
      if (this.player.status.mode !== Mode.Loyalty) {
        return null;
      }

      return [
        <VCol md={6} sm={12}>
          <VRow>
            <VCol>
              <b>Details</b>
            </VCol>
          </VRow>

          <VRow>
            <VCol>Tier</VCol>
            <VCol align='end'>{this.player.status.tier}.{
              this.player.status.completion! < 10 ?
                `0${this.player.status.completion}` :
                this.player.status.completion
            }</VCol>
          </VRow>

          {this.cashBalanceRow}

          <VRow>
            <VCol>Next rewards release at</VCol>
            <VCol align='end'>
              {
                this.player.nextScheduledRewardAt ?
                  formatTime(this.player.nextScheduledRewardAt!) :
                  'immediately'
              }
            </VCol>
          </VRow>

          { this.player.status.trend ?
            <VRow>
              <VCol>Trend</VCol>
              <VCol align='end'>
                {this.player.status.trend.tier} tier in {daysText(this.player.status.trend.days)}
              </VCol>
            </VRow> : null }

          <VRow>
            <VCol>XP multiplier</VCol>
            <VCol align='end'>{this.player.status.xpMultiplier}</VCol>
          </VRow>

          <VRow>
            <VCol>RC multiplier</VCol>
            <VCol align='end'>{this.player.status.rcMultiplier}</VCol>
          </VRow>
        </VCol>,

        <VCol md={6} sm={12}>
          {/*<VRow>
            <VCol>
              <b>Scores</b>
            </VCol>
          </VRow>

          <VRow>
            <VCol>Highest</VCol>
            <VCol align='end'>{this.player.status.personalHighScore}</VCol>
          </VRow>

          <VRow>
            <VCol>Todays</VCol>
            <VCol align='end'>{this.player.status.todaysScore}</VCol>
          </VRow>

          <VRow>
            <VCol>Todays resets at</VCol>
            <VCol align='end'>{formatTime(this.player.status.todaysScoreResetsAt)}</VCol>
          </VRow>*/}
        </VCol>,
      ];
    },

    offers() {
      if (this.player.status.mode === Mode.Manual || this.player.status.mode === Mode.Pro) {
        return null;
      }

      return <VCol md={6} sm={12}>
        <VRow>
          <VCol>
            <b>Offers</b>
          </VCol>
          <VCol align='end'>
            {this.canOperate && !this.allOfferAvailable ?
              <VBtn x-small class='ma-2' onClick={this.openOfferDialog}>
                create offer
              </VBtn> : null}
          </VCol>
        </VRow>
        <VRow class='mt-0'>
          <VCol class='pt-0'>
            <VDataTable
              headers={[
                {
                  text: 'Type',
                  value: 'type',
                },
                {
                  text: 'Valid until',
                  value: 'validUntil',
                },
                {
                  text: 'Details',
                  value: 'details',
                },
                {
                  text: '',
                  value: 'actions',
                  align: 'end',
                },
              ]}
              items={this.player.status.offers}
              disablePagination={true}
              hideDefaultFooter={true}
              loading={this.offersLoading}
              scopedSlots={{
                'item.actions': ({ item }: { item: Offer }) => {
                  if (!this.canOperate) {
                    return null;
                  }

                  return (
                    <VBtn small icon depressed
                      onClick={() => this.onDeleteOffer(item) }
                      disabled={this.offersLoading}
                    >
                      <VIcon>delete</VIcon>
                    </VBtn>
                  );
                },
                'item.validUntil': ({ item }: { item: Offer }) => {
                  return formatTime(item.validUntil);
                },
                'item.details': ({item}: { item: Offer }) => {
                  return `${item.multiplier}x for ${item.multiplierValidHours} hours`;
                },
                'progress': () => {
                  return <VOverlay absolute='absolute' value={this.offersLoading}>
                    <VProgressCircular indeterminate size={64}></VProgressCircular>
                  </VOverlay>;
                },
              }}>
            </VDataTable>
          </VCol>
        </VRow>
      </VCol>;
    },

    dialog() {
      if (!this.dialogIsOpen) {
        return null;
      }

      return <LoyaltyDialog
        isOpen={this.dialogIsOpen}
        playerInput={{
          id: this.player.id,
          mode: this.player.status.mode,
          scheduleID: this.player.fixedSchedule?.id,
          fixedScheduleHiddenToPlayer: this.player.fixedScheduleHiddenToPlayer,
          telegramContact: this.player.telegramContact,
        }}
        error={this.error}
        on={{
          close: () => {
            this.dialogIsOpen = false;
          },
          save: this.onSave,
        }}/>;
    },

    offerDialog() {
      if (!this.offerDialogIsOpen) {
        return null;
      }

      return <OfferDialog
        isOpen={this.offerDialogIsOpen}
        offerInput={{}}
        offers={this.player.status.offers}
        error={this.error}
        on={{
          close: () => {
            this.offerDialogIsOpen = false;
          },
          save: this.onSaveOffer,
        }}/>;
    },

    resetCashDialog() {
      if (this.resetCashDialogIsOpen) {
        return (
          <ConfirmationDialog
            title='Zero out pending rewards'
            text={`Do you want to zero out pending rewards?`}
            onDisagree={() => this.resetCashDialogIsOpen = false}
            onAgree={this.onResetCash} />
        );
      }
    },

    releaseRewardsDialog() {
      if (this.releaseRewardsDialogIsOpen) {
        return (
          <ConfirmationDialog
            title='Release pending rewards'
            text={`Do you want to release pending rewards?`}
            onDisagree={() => this.releaseRewardsDialogIsOpen = false}
            onAgree={this.onReleaseRewards} />
        );
      }
    },

    allOfferAvailable(): boolean {
      return this.player.status.offers?.length === Object.keys(OfferType).length;
    },
  },

  methods: {
    openLoyaltyDialog() {
      this.dialogIsOpen = true;
    },

    openOfferDialog() {
      this.offerDialogIsOpen = true;
    },

    async onReleaseRewards() {
      try {
        this.releaseRewardsLoading = true;
        this.releaseRewardsError = '';

        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation($playerID: ID!) {
              releasePendingRewards(playerID: $playerID)
            }
          `,
          variables: {
            playerID: this.playerUUID,
          },
        });

      } catch (err) {
        this.releaseRewardsError = err.message;
      } finally {
        this.releaseRewardsLoading = false;
        this.releaseRewardsDialogIsOpen = false;
      }
    },

    async onResetCash() {
      try {
        this.resetCashLoading = true;
        this.resetCashError = '';

        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation($playerID: ID!) {
              resetCash(playerID: $playerID)
            }
          `,
          variables: {
            playerID: this.playerUUID,
          },
        });

      } catch (err) {
        this.resetCashError = err.message;
      } finally {
        this.resetCashLoading = false;
        this.resetCashDialogIsOpen = false;
      }
    },

    async onRefresh() {
      try {
        this.refreshLoading = true;
        this.refreshError = '';

        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation($playerID: ID!) {
              refresh(playerID: $playerID)
            }
          `,
          variables: {
            playerID: this.playerUUID,
          },
        });

      } catch (err) {
        this.refreshError = err.message;
      } finally {
        this.refreshLoading = false;
      }
    },

    async onSave(input: PlayerInput) {
      try {
        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation ($input: PlayerInput!) {
              player(input: $input) {
                ${allPlayerFields}
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.player = result.data.player;

        this.dialogIsOpen = false;
      } catch (err) {
        this.error = err.message;
      }
    },

    async onSaveOffer(input: OfferInput) {
      try {
        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation ($id: ID!, $input: OfferInput!) {
              createOffer(playerID: $id, params: $input) {
                id
                type
                validUntil
                multiplier
                multiplierValidHours
              }
            }
          `,
          variables: {
            input,
            id: this.player.id,
          },
        });

        if (this.player.status.offers.findIndex((o) => result.data.createOffer.id === o.id) === -1) {
          this.player.status.offers.push(result.data.createOffer);
        }
        this.offerDialogIsOpen = false;
      } catch (err) {
        this.error = err.message;
      }
    },

    async onDeleteOffer(input: Offer) {
      this.offersLoading = true;
      try {
        const result = await this.$apollo.mutate({
          client: 'sportsLoyaltyService',
          mutation: gql`
            mutation ($id: ID!) {
              deleteOffer(offerID: $id)
            }
          `,
          variables: {
            id: input.id,
          },
        });

        if (result.data.deleteOffer) {
          this.player.status.offers = this.player.status.offers.filter((o) => o.id !== input.id);
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.offersLoading = false;
      }
    },
  },

  apollo: {
    player: {
      client: 'sportsLoyaltyService',
      query: gql`
        query ($playerUUID: ID!) {
          player(id: $playerUUID) {
            ${allPlayerFields}
          }
        }
      `,
      variables() {
        return { playerUUID: this.playerUUID };
      },
      errorPolicy: 'all',
      error(err) {
        this.error = err.toString().includes('player not found') ?
          'not available for the player' :
          err.toString();
      },
      pollInterval: 5000,
    },
  },

  render() {
    if (this.$apollo.queries.player.loading) {
      return <VProgressLinear
        active={true}
        indeterminate={true}>
      </VProgressLinear>;
    }

    if (!this.player) {
      return <VContainer>{this.error}</VContainer>;
    }

    return (
      <VContainer>
        {this.dialog}
        {this.offerDialog}
        {this.resetCashDialog}
        {this.releaseRewardsDialog}
        {this.refreshError &&
          <VAlert type='warning' dismissible text dense>{this.refreshError}</VAlert>}
        {this.releaseRewardsError &&
          <VAlert type='warning' dismissible text dense>{this.releaseRewardsError}</VAlert>}
        {this.resetCashError &&
          <VAlert type='warning' dismissible text dense>{this.resetCashError}</VAlert>}
        <VRow align='center'>
          <VCol md={3} sm={6}>
            <b>Mode</b>
          </VCol>
          <VCol md={3} sm={6} align='end'>
            {this.player.status.mode}
          </VCol>
          {this.canOperate && <VCol align='end'>
              <VBtn onClick={this.onRefresh} x-small loading={this.refreshLoading} class='ma-2'>
                refresh
              </VBtn>
              <VBtn onClick={this.openLoyaltyDialog} x-small class='ma-2'>
                edit
              </VBtn>
          </VCol> }
        </VRow>
        <VRow>
          <VCol md={3} sm={6}>
            Telegram contact
          </VCol>
          <VCol md={3} sm={6} align='end'>
            {this.player.telegramContact || 'no'}
          </VCol>
        </VRow>
        <VRow>
          {this.manualProDetails}
          {this.manualModeDetails}
          {this.vipModeDetails}
          {this.loyaltyModeDetails}
          {this.offers}
        </VRow>
      </VContainer>
    );
  },
});
