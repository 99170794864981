import { AnnouncementType, MessageKey, Scope } from '@/types/announcements';
export const DEFAULT_TENNANT = 'cloudbet';
export const SPORT = 'sports';
export const CASINO = 'casino';


export const langKeys = Object.keys(MessageKey);
export  const typeColorMap: Record<string, string> = {
  [AnnouncementType.success]: '#097969',
  [AnnouncementType.info]: '#0096FF',
  [AnnouncementType.warn]: '#F4BB44',
  [AnnouncementType.error]: '#A52A2A',
};

export const announcementTypes = Object.keys(AnnouncementType);

export const announcementScopes = Object.keys(Scope);

export const announcementCasinoScopes = [Scope.casino];

