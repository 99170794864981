import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VDataTable, VTooltip } from 'vuetify/lib';
import { unixToDateTimeString } from '@/utils/date';

interface Withdrawal {
  amount: number;
  currency: string;
  playerId: number;
  playerUid: string;
  provider: string;
  createdAt: string;
  updatedAt: string;
  state: string;
}

export const FiatWithdrawalsWidget = Vue.extend({
  name: 'fiatWithdrawalsWidget',
  props: {
    page: Number,
    pageSize: Number,
    footerProps: Object,
    state: String,
    playerUUID: String,
  },
  data() {
    return {
      shiba: {
        withdrawals: [],
        total: 0,
      },
    };
  },
  methods: {
    onPaginationHandler(options: any) {
      this.$emit('pageUpdate', options.page, options.itemsPerPage);
    },
    onClickPlayerUUID(uuid: string) {
      this.$router.push(`/players/${uuid}`);
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Player',
          align: 'start',
          sortable: false,
          value: 'playerUid',
          width: '20',
        },
        {
          text: 'Currency',
          align: 'start',
          sortable: false,
          value: 'currency',
        },
        {
          text: 'Provider',
          align: 'start',
          sortable: false,
          value: 'provider',
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'amount',
        },
        {
          text: 'Created(UTC)',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        {
          text: 'Note',
          align: 'start',
          sortable: false,
          value: 'description',
        },
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state',
        },
      ];
    },
  },
  apollo: {
    shiba: {
      query: gql`query (
        $state: String
        $playerUUID: String
        $page: Int!
        $pageSize: Int!
        $isChrono: Boolean){
        getFiatWithdrawals(
          state: $state
          playerUUID: $playerUUID
          page: $page
          pageSize: $pageSize
          isChrono: $isChrono) {
          withdrawals {
            amount
            currency
            state
            playerId
            playerUid
            provider
            createdAt
            updatedAt
          }
          total
        }
      }`,
      variables(): {
        page: number,
        pageSize: number,
        isChrono: boolean,
        state: string,
        playerUUID: string,
      } {
        return {
          page: this.page,
          pageSize: this.pageSize,
          isChrono: true,
          state: this.state === 'all' ? '' : this.state,
          playerUUID: this.playerUUID,
        };
      },
      update(data) {
        return {
          withdrawals: data.getFiatWithdrawals.withdrawals,
          total: data.getFiatWithdrawals.total,
        };
      },
      fetchPolicy: 'network-only',
    },
  },
  render() {
    return (
      <div>
        <VDataTable
          headers={this.headers}
          items={this.shiba.withdrawals || []}
          scopedSlots={{
            'item.playerUid': ({ item }: { item: Withdrawal }) => (
              <div onclick={() => { this.onClickPlayerUUID(item.playerUid); }}>
                <VTooltip bottom
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => (
                      <span on={on}>
                        {item.playerUid}
                      </span>
                    ),
                  }}
                >
                  <span>player id: {item.playerId}</span>
                </VTooltip>
              </div>
            ),
            'item.createdAt': ({ item }: { item: Withdrawal }) => (
              <VTooltip bottom
                scopedSlots={{
                  activator: ({ on, attrs }: any) => (
                    <span on={on}>
                      {unixToDateTimeString(Number(item.createdAt))}
                    </span>
                  ),
                }}
              >
                <span>last update: {unixToDateTimeString(Number(item.updatedAt))}</span>
              </VTooltip>
            ),
          }}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.shiba.loading}
          page={this.page}
          items-per-page={this.pageSize}
          server-items-length={this.shiba.total || 0}
          onPagination={this.onPaginationHandler}
          footerProps={this.footerProps}
        />
      </div >
    );
  },
});
