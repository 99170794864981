import Vue, { PropType } from 'vue';
import {
  VRow,
  VProgressCircular,
  VBtn,
  VDialog,
  VCard,
  VCardText,
  VCardActions,
  VSpacer,
  VContainer,
  VSimpleTable,
  VCardTitle,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';

import './BetLogs.less';

export enum Provider {
  Pinnacle = 'pinnacle',
  Betradar = 'betradar',
}

export default Vue.extend({
  props: {
    betSlipId: String,
    provider: String as PropType<Provider>,
  },

  data() {
    return {
      logs: [],
    } as {
      logs: Array<{ request: string, response: string, endpoint?: string }>,
    };
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },

  computed: {
    loading(): boolean {
      return this.$apollo.queries.pinnacleBetLogs.loading ||
        this.$apollo.queries.betradarBetLogs.loading;
    },
  },

  apollo: {
    pinnacleBetLogs: {
      query: gql`query($betSlipId: String!) {
        pinnacleBetLogs(betSlipId: $betSlipId) {
          logs {
            request
            response
            endpoint
          }
        }
      }`,

      variables(): { betSlipId: string } {
        return {
          betSlipId: this.betSlipId,
        };
      },

      update(data) {
        this.logs = data.pinnacleBetLogs.logs;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return this.provider !== Provider.Pinnacle;
      },
    },

    betradarBetLogs: {
      query: gql`query($betSlipId: String!) {
        betradarBetLogs(betSlipId: $betSlipId) {
          logs {
            request
            response
          }
        }
      }`,

      variables(): { betSlipId: string } {
        return {
          betSlipId: this.betSlipId,
        };
      },

      update(data) {
        this.logs = data.betradarBetLogs.logs;
      },

      fetchPolicy: 'network-only',

      skip(): boolean {
        return this.provider !== Provider.Betradar;
      },
    },
  },

  render() {
    const logsRows = this.logs.map((log) => {
      return (
        <tr style='overflow-wrap: anywhere;'>
          <td class='py-2'>
            {log.request &&
              <p>
                <span class='text-uppercase label'>Body: </span>{log.request}
              </p>
            }
            {log.endpoint &&
              <p>
                <span class='text-uppercase label'>Endpoint: </span>{log.endpoint}
              </p>
            }
          </td>
          <td class='py-2'><span class='text-uppercase label'>Body: </span>{log.response}</td>
        </tr>
      );
    });

    const logsTable =
      <VSimpleTable fixed-header>
        <thead class='text-uppercase'>
          <tr><th width='50%'>Requests</th><th>Responses</th></tr>
        </thead>
        <tbody>{logsRows}</tbody>
      </VSimpleTable>;

    const progress =
      <VContainer fill-height fill-width>
        <VRow align='center' justify='center'>
          <VProgressCircular size='70' width='7' color='purple' indeterminate />
        </VRow>
      </VContainer>;

    return (
      <VDialog value={true} scrollable persistent width='80vw' >
        <VCard height='80vh' class='bet-details-bet-logs'>
          <VCardTitle>
            Bet slip logs
          </VCardTitle>
          <VCardText>
            {this.loading ? progress : logsTable}
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn text onClick={() => this.close()} color='green darken-1' >Close</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
