import gql from 'graphql-tag';
import Vue from 'vue';

import { VContainer, VCardText, VCardSubtitle } from 'vuetify/lib';

import EventHeadline from '@/components/sports/EventHeadline';
import EventLiabilityGrid from '@/components/sports/EventLiabilityGrid';

export default Vue.extend({
  props: {
    eventId: String,
    playerUUID: String,
    marketKey: String,
  },
  data() {
    return {
      subscription: null as any,
      playerEventLiabilityGridUpdated: null as any,
    };
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  methods: {
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription playerEventLiabilityGridUpdated(
              $eventId: String!
              $playerUUID: String!
              $marketKey: String
            ) {
              playerEventLiabilityGridUpdated(
                eventId: $eventId
                playerUUID: $playerUUID
                marketKey: $marketKey
              ) {
                eventId
                eventName
                sportKey
                competitionKey
                sportName
                competitionName
                isOutright
                grid {
                  homeScore
                  awayScore
                  outcome
                  profitAndLoss
                }
                player {
                  uuid
                  nickname
                }
              }
            }
          `,
          variables: {
            eventId: this.eventId,
            playerUUID: this.playerUUID,
            marketKey: this.marketKey,
          },
        })
        .subscribe({
          next: ({
            data,
          }: {
            data: { playerEventLiabilityGridUpdated: any };
          }) => {
            this.playerEventLiabilityGridUpdated = data.playerEventLiabilityGridUpdated;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error: any) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  render() {
    return (
      <VContainer fluid>
        <EventLiabilityGrid eventId={this.eventId} eventLiabilityGridUpdated={this.playerEventLiabilityGridUpdated} />
      </VContainer>
    );
  },
});
