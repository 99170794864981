import Vue from 'vue';
import { VContainer, VRow, VCol, VSimpleTable, VSwitch } from 'vuetify/lib';

import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  data() {
    return {
      isLive: true,
    };
  },
  methods: {
    ...mapActions({
      fetchProviders: 'sportsProvidersStatus/fetchProvidersStatus',
      updateProvider: 'sportsProvidersStatus/updateProviderStatus',
    }),
  },
  computed: {
    ...mapGetters({
      providers: 'sportsProvidersStatus/providers',
    }),
  },
  async mounted() {
    await this.fetchProviders();
  },
  render() {
    return (
      <VContainer fluid>
        <VRow class='mt-2 ml-2'>
          <VCol>
            <h1>Providers Status Control</h1>
          </VCol>
        </VRow>
        <VRow class='mt-2 ml-2'>
          <VCol md='4'>
            <VSimpleTable dense>
              <thead>
                <tr>
                  <th class='text-left'>
                    Provider Name
                  </th>
                  <th class='text-left'>
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
                {
                  this.providers.map((provider: any) => (
                    <tr key={provider.providerName}>
                      <td>{provider.providerName.toUpperCase()}</td>
                      <td>
                        <VSwitch
                          value={!provider.isDisabled}
                          input-value={!provider.isDisabled}
                          label={provider.isDisabled ? 'DISABLED' : 'ENABLED'}
                          onClick={() => {
                            this.updateProvider({
                              providerName: provider.providerName,
                              isDisabled: !provider.isDisabled,
                            });
                          }} >
                        </VSwitch>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </VSimpleTable>
          </VCol>
        </VRow>
      </VContainer >
    );
  },
});
