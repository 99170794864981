import Vue from 'vue';
// @ts-ignore
import { GridLayout, GridItem } from 'vue-grid-layout';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { Widget } from '@/store/modules/dashboard';
import WidgetForm from './WidgetForm.vue';
import { findIndex } from 'lodash';

import PlayerEventsWidget from '@/components/sports/PlayerEventsWidget';
import PlayersWidget from '@/components/sports/PlayersWidget';
import EventLiabilityWidget from '@/components/sports/EventLiabilityWidget';
import EventsWidget from '@/components/sports/EventsWidget';
import StreamWidget from '@/components/StreamWidget';
import EventPlayersWidget from '@/components/sports/EventPlayersWidget';
import EventCloudbetOpinion from '@/components/sports/EventCloudbetOpinion';

import { hasRoles } from '@/utils/auth';

interface WidgetDimensions {
  w: number;
  h: number;
  minW: number;
  minH: number;
}

const WIDGET_DEFAULT_DIMENSIONS: Record<Widget['type'], WidgetDimensions> = {
  Stream: {
    w: 4,
    h: 8,
    minW: 4,
    minH: 4,
  },
  Events: {
    w: 8,
    h: 8,
    minW: 8,
    minH: 4,
  },
  EventLiability: {
    w: 8,
    h: 20,
    minW: 4,
    minH: 4,
  },
  EventPlayers: {
    w: 4,
    h: 8,
    minW: 4,
    minH: 4,
  },
  EventCloudbetOpinion: {
    w: 4,
    h: 8,
    minW: 4,
    minH: 4,
  },
  Players: {
    w: 4,
    h: 8,
    minW: 4,
    minH: 4,
  },
  PlayerEvents: {
    w: 4,
    h: 8,
    minW: 4,
    minH: 4,
  },
};

const WIDGET_PERMISSIONS: Record<Widget['type'], string[]> = {
  Stream: ['sports:operator', 'sports:user'],
  Events: ['sports:operator'],
  EventLiability: ['sports:operator'],
  EventPlayers: ['sports:operator'],
  Players: ['sports:operator'],
  PlayerEvents: ['sports:operator'],
  EventCloudbetOpinion: ['sports:operator', 'sports:user'],
};

export default Vue.extend({
  components: {
    EventLiabilityWidget,
    EventsWidget,
    EventPlayersWidget,
    StreamWidget,
    EventCloudbetOpinion,
    PlayersWidget,
    PlayerEventsWidget,
    WidgetForm,
    GridLayout,
    GridItem,
  },
  data: () => ({
    loading: true,
    widgetDefaultDimensions: WIDGET_DEFAULT_DIMENSIONS,
    widgetPermissions: WIDGET_PERMISSIONS,
    showEditDialog: {} as Record<number, boolean>,
    showEditDialogProgress: {} as Record<number, boolean>,
    showNewWidgetDialog: false,
    allowUpdate: false,
  }),
  computed: {
    ...mapGetters({
      dashboard: 'dashboard/dashboard',
    }),
  },
  methods: {
    ...mapActions({
      getDashboard: 'dashboard/getDashboardFromDB',
      updateDashboard: 'dashboard/updateDashboard',
    }),
    ...mapMutations({
      UPDATE_WIDGET: 'dashboard/UPDATE_WIDGET',
    }),
    hasAccess(widgetType: Widget['type']) {
      return hasRoles(WIDGET_PERMISSIONS[widgetType]);
    },
    async saveLayout(layout: any) {
      if (!this.allowUpdate) {
        this.allowUpdate = true;
        return;
      }
      if (this.allowUpdate) {
        this.allowUpdate = false;
        await this.updateDashboard(this.dashboard);
      }
    },
    async addWidget(newWidget: Widget) {
      let nextIndex = 0;
      let nextY = 0;
      for (const widget of this.dashboard.widgets as Widget[]) {
        if (widget.i >= nextIndex) {
          nextIndex = widget.i + 1;
        }
        if (widget.y + widget.h >= nextY) {
          nextY = widget.y + widget.h;
        }
      }

      newWidget.i = nextIndex;
      newWidget.x = 0;
      newWidget.y = nextY;
      newWidget.w = WIDGET_DEFAULT_DIMENSIONS[newWidget.type].w;
      newWidget.h = WIDGET_DEFAULT_DIMENSIONS[newWidget.type].h;
      this.dashboard.widgets.push(newWidget);

      await this.updateDashboard(this.dashboard);

      this.showNewWidgetDialog = false;
    },
    async removeWidget(widgetToRemove: Widget) {
      const index = findIndex(this.dashboard.widgets, widgetToRemove);
      if (index >= 0) {
        this.dashboard.widgets.splice(index, 1);
        await this.updateDashboard(this.dashboard);
      }
    },
    async updateWidget(widget: Widget) {
      this.showEditDialogProgress = { ...this.showEditDialogProgress, [widget.i]: true };
      this.UPDATE_WIDGET(widget);
      await this.updateDashboard(this.dashboard);
      this.showEditDialog = { ...this.showEditDialog, [widget.i]: false };
      this.showEditDialogProgress = { ...this.showEditDialogProgress, [widget.i]: false };
    },
  },
  async created() {
    await this.getDashboard();
    this.loading = false;
  },
});
