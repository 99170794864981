import Vue from 'vue';
import moment from 'moment';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAutocomplete,
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCheckbox,
  VCol,
  VContainer,
  VDatePicker,
  VDialog,
  VDivider,
  VListItem,
  VListItemAvatar,
  VListItemContent,
  VListItemSubtitle,
  VListItemTitle,
  VMenu,
  VRadio,
  VRadioGroup,
  VRow,
  VSelect,
  VSpacer,
  VTextField,
} from 'vuetify/lib';
import { env } from '@/env';

import {
  AwardCasinoFreespinsOfferInput,
  GetGamesAvailableDistributorCurrenciesInput,
  GetGamesAvailableDistributorCurrenciesOutput,
  GetGamesNumberOfLinesPerSpinInput,
  GetGamesNumberOfLinesPerSpinOutput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { BonusType } from '@/types/casino/bonus';
import { awardableCasinoCurrencies, buildCurrenciesSelectList } from '@/utils/casino/currency';
import { FreespinsRewardType } from '@/types/casino/freespins';
import { humanizeShortStudioKey } from '@/types/casino/studio';

import algoliasearch from 'algoliasearch/lite';

const algoliaSearchClient = algoliasearch(env.casino.algoliaClient.appId, env.casino.algoliaClient.apiKey);
const algoliaSearchIndex = algoliaSearchClient.initIndex(env.casino.algoliaClient.indexNameCasinoGame);

interface AwardCasinoFreespinsOfferForm {
  casinoGameId: string;
  spinsAwarded: string;
  distributorSpinValue: string;
  totalSpinValue: string;
  casinoCurrency: string;
  distributorCurrency: string;
  promocode: string;
  expiresAt: string;
  freespinsType: string | null;
  rewardType: string;
  rewardBonusType: string;
  rolloverExpiresInDays: string;
  rolloverRequirement: string;
  depositBonusAwardPercentage?: string;
  lockDepositBonus?: boolean;

  expiresAtDatePickerMenuOpen: boolean;
}

interface AwardCasinoFreespinsOfferValidationErrors {
  playerUUID?: string[];
  casinoGameId?: string[];
  spinsAwarded?: string[];
  distributorSpinValue?: string[];
  casinoCurrency?: string[];
  distributorCurrency?: string[];
  promocode?: string[];
  expiresAt?: string[];
  freespinsType?: string[];
  rewardType?: string[];
  rewardBonusType?: string[];
  rolloverExpiresInDays?: string[];
  rolloverRequirement?: string[];
  depositBonusAwardPercentage?: string[];
  lockDepositBonus?: string[];
}

interface AlgoliaCasinoGameObject {
  id: string;
  name: {
    en: string;
  };
  providerKey: string;
  images: {
    desktop: string;
    mobile: string;
  };
  _tags: string[];
}

interface GameSelectRecord {
  text: string;
  value: AlgoliaCasinoGameObject;
}

interface GameSelection {
  algolia: {
    searchKey: string;
    searchResults: AlgoliaCasinoGameObject[];
  };
  selectedGame: GameSelectRecord | null;
  availableDistributorCurrencies: string[];
  numberOfLinesPerSpin: string;
  defaultPlayngoCoins: string | null;
  featureTriggerMultiplier: string | null;
}

interface VueData {
  awardCasinoFreespinsOfferForm: AwardCasinoFreespinsOfferForm;
  awardCasinoFreespinsOfferValidationErrors?: AwardCasinoFreespinsOfferValidationErrors;
  gameSelection: GameSelection;
  loading: boolean;
}

const defaultAwardCasinoFreespinsOfferValidationErrors = undefined;
const defaultRewardType = FreespinsRewardType.ROLLOVER_BONUS;

const styles = {
  inputField: 'mb-3',
};

export default Vue.extend({
  props: {
    playerUUID: String,
    open: Boolean,
  },
  data(): VueData {
    return {
      awardCasinoFreespinsOfferForm: {
        casinoGameId: '',
        spinsAwarded: '',
        distributorSpinValue: '',
        totalSpinValue:  '',
        casinoCurrency: '',
        distributorCurrency: '',
        promocode: '',
        expiresAt: '',
        freespinsType: null,
        rewardType: defaultRewardType,
        rewardBonusType: BonusType.CASINO_FREEBONUS,
        rolloverExpiresInDays: '',
        rolloverRequirement: '',
        depositBonusAwardPercentage: '',
        lockDepositBonus: true,

        expiresAtDatePickerMenuOpen: false,
      },
      awardCasinoFreespinsOfferValidationErrors: defaultAwardCasinoFreespinsOfferValidationErrors,
      gameSelection: {
        algolia: {
          searchKey: '',
          searchResults: [],
        },
        selectedGame: null,
        availableDistributorCurrencies: [],
        numberOfLinesPerSpin: '',
        defaultPlayngoCoins: null,
        featureTriggerMultiplier: null,
      },
      loading: false,
    };
  },
  methods: {
    clearForms() {
      this.awardCasinoFreespinsOfferForm.spinsAwarded = '';
      this.awardCasinoFreespinsOfferForm.distributorSpinValue = '';
      this.awardCasinoFreespinsOfferForm.totalSpinValue = '';
      this.awardCasinoFreespinsOfferForm.casinoCurrency = '';
      this.awardCasinoFreespinsOfferForm.distributorCurrency = '';
      this.awardCasinoFreespinsOfferForm.promocode = '';
      this.awardCasinoFreespinsOfferForm.expiresAt = '';
      this.awardCasinoFreespinsOfferForm.freespinsType = null;
      this.awardCasinoFreespinsOfferForm.rewardType = defaultRewardType;
      this.awardCasinoFreespinsOfferForm.rewardBonusType = BonusType.CASINO_FREEBONUS;
      this.awardCasinoFreespinsOfferForm.rolloverExpiresInDays = '';
      this.awardCasinoFreespinsOfferForm.rolloverRequirement = '';
      this.awardCasinoFreespinsOfferForm.depositBonusAwardPercentage = '';
      this.awardCasinoFreespinsOfferForm.lockDepositBonus = true;
      this.awardCasinoFreespinsOfferValidationErrors = defaultAwardCasinoFreespinsOfferValidationErrors;

      this.gameSelection.algolia.searchKey = '';
      this.gameSelection.selectedGame = null;
      this.gameSelection.numberOfLinesPerSpin = '';
      this.gameSelection.defaultPlayngoCoins = null;
      this.gameSelection.featureTriggerMultiplier = null;
    },
    async onAwardFreespinsOfferSubmit() {
      const expiresAt = `${this.awardCasinoFreespinsOfferForm.expiresAt}T${moment().format('HH:mm:ssZ')}`;

      if (this.awardCasinoFreespinsOfferForm.rewardType === FreespinsRewardType.REAL_MONEY) {
        try {
          this.loading = true;

          const input: AwardCasinoFreespinsOfferInput = {
            playerUUID: this.playerUUID,
            casinoGameId: this.awardCasinoFreespinsOfferForm.casinoGameId,
            spinsAwarded: this.awardCasinoFreespinsOfferForm.spinsAwarded,
            distributorSpinValue: this.awardCasinoFreespinsOfferForm.distributorSpinValue,
            casinoCurrency: this.awardCasinoFreespinsOfferForm.casinoCurrency,
            distributorCurrency: this.awardCasinoFreespinsOfferForm.distributorCurrency,
            promocode: this.awardCasinoFreespinsOfferForm.promocode,
            expiresAt,
            freespinsType: this.awardCasinoFreespinsOfferForm.freespinsType,
            rewardType: this.awardCasinoFreespinsOfferForm.rewardType,
          };

          await this.$apollo.mutate({
            mutation: gql`
              mutation ($input: AwardCasinoFreespinsOfferInput!) {
                awardCasinoFreespinsOffer(input: $input) {
                  freespinsOfferId
                }
              }
            `,
            variables: {
              input,
            },
          });

          this.clearForms();
          this.$emit('submit', null);
        } catch (e) {
          const err = e as ApolloError;

          const errResponse = err.graphQLErrors[0]?.extensions?.response;
          const errURL = errResponse?.url;
          const errStatus = errResponse?.status;
          const errBody = errResponse?.body;

          if (errBody != null) {
            const validationErrors = errBody.validation_errors;

            if (validationErrors != null) {
              this.awardCasinoFreespinsOfferValidationErrors = {
                playerUUID: validationErrors.player_uuid,
                casinoGameId: validationErrors.casino_game_id,
                spinsAwarded: validationErrors.spins_awarded,
                distributorSpinValue: validationErrors.distributor_spin_value,
                casinoCurrency: validationErrors.casino_currency,
                distributorCurrency: validationErrors.distributor_currency,
                promocode: validationErrors.promocode,
                expiresAt: validationErrors.expires_at,
                freespinsType: validationErrors.freespins_type,
                rewardType: validationErrors.reward_type,
              };
            } else {
              const apiError: ApiError = {
                url: errURL,
                status: errStatus,
                body: errBody,
              };

              this.$emit('submit', apiError);
            }
          }
        } finally {
          this.loading = false;
        }
      } else if (this.awardCasinoFreespinsOfferForm.rewardType === FreespinsRewardType.ROLLOVER_BONUS) {
        try {
          this.loading = true;

          const input: AwardCasinoFreespinsOfferInput = {
            playerUUID: this.playerUUID,
            casinoGameId: this.awardCasinoFreespinsOfferForm.casinoGameId,
            spinsAwarded: this.awardCasinoFreespinsOfferForm.spinsAwarded,
            distributorSpinValue: this.awardCasinoFreespinsOfferForm.distributorSpinValue,
            casinoCurrency: this.awardCasinoFreespinsOfferForm.casinoCurrency,
            distributorCurrency: this.awardCasinoFreespinsOfferForm.distributorCurrency,
            promocode: this.awardCasinoFreespinsOfferForm.promocode,
            expiresAt,
            freespinsType: this.awardCasinoFreespinsOfferForm.freespinsType,
            rewardType: this.awardCasinoFreespinsOfferForm.rewardType,
            rewardBonusType: this.awardCasinoFreespinsOfferForm.rewardBonusType,
            rolloverExpiresInDays: this.awardCasinoFreespinsOfferForm.rolloverExpiresInDays,
            rolloverRequirement: this.awardCasinoFreespinsOfferForm.rolloverRequirement,
            depositBonusAwardPercentage: this.awardCasinoFreespinsOfferForm.depositBonusAwardPercentage,
            lockDepositBonus: this.awardCasinoFreespinsOfferForm.lockDepositBonus,
          };

          await this.$apollo.mutate({
            mutation: gql`
              mutation ($input: AwardCasinoFreespinsOfferInput!) {
                awardCasinoFreespinsOffer(input: $input) {
                  freespinsOfferId
                }
              }
            `,
            variables: {
              input,
            },
          });

          this.clearForms();
          this.$emit('submit', null);
        } catch (e) {
          const err = e as ApolloError;

          const errResponse = err.graphQLErrors[0]?.extensions?.response;
          const errURL = errResponse?.url;
          const errStatus = errResponse?.status;
          const errBody = errResponse?.body;

          if (errBody != null) {
            const validationErrors = errBody.validation_errors;

            if (validationErrors != null) {
              this.awardCasinoFreespinsOfferValidationErrors = {
                playerUUID: validationErrors.player_uuid,
                casinoGameId: validationErrors.casino_game_id,
                spinsAwarded: validationErrors.spins_awarded,
                distributorSpinValue: validationErrors.distributor_spin_value,
                casinoCurrency: validationErrors.casino_currency,
                distributorCurrency: validationErrors.distributor_currency,
                promocode: validationErrors.promocode,
                expiresAt: validationErrors.expires_at,
                freespinsType: validationErrors.freespins_type,
                rewardType: validationErrors.reward_type,
                rewardBonusType: validationErrors.reward_bonus_type,
                rolloverExpiresInDays: validationErrors.rollover_expires_in_days,
                rolloverRequirement: validationErrors.rollover_requirement,
                depositBonusAwardPercentage: validationErrors.deposit_bonus_award_percentage,
                lockDepositBonus: validationErrors.lock_deposit_bonus,
              };
            } else {
              const apiError: ApiError = {
                url: errURL,
                status: errStatus,
                body: errBody,
              };

              this.awardCasinoFreespinsOfferValidationErrors = defaultAwardCasinoFreespinsOfferValidationErrors;
              this.$emit('submit', apiError);
            }
          }
        } finally {
          this.loading = false;
        }
      }
    },
    async loadGamesAvailableDistributorCurrencies(casinoCurrency: string, casinoGameId: string) {
      const input: GetGamesAvailableDistributorCurrenciesInput = {
        casinoCurrency,
        casinoGameId,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetGamesAvailableDistributorCurrenciesInput!) {
            getGamesAvailableDistributorCurrencies(input: $input) {
              availableDistributorCurrencies
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetGamesAvailableDistributorCurrenciesOutput = response.data.getGamesAvailableDistributorCurrencies;
      this.gameSelection.availableDistributorCurrencies = output.availableDistributorCurrencies as string[];
    },
    async getGamesNumberOfLinesPerSpin(casinoGameId: string) {
      const input: GetGamesNumberOfLinesPerSpinInput = {
        casinoGameId,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetGamesNumberOfLinesPerSpinInput!) {
            getGamesNumberOfLinesPerSpin(input: $input) {
              numberOfLinesPerSpin
              defaultPlayngoCoins
              featureTriggerMultiplier
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetGamesNumberOfLinesPerSpinOutput = response.data.getGamesNumberOfLinesPerSpin;

      this.gameSelection.numberOfLinesPerSpin = output.numberOfLinesPerSpin;
      if (output.defaultPlayngoCoins != null) {
        this.gameSelection.defaultPlayngoCoins = output.defaultPlayngoCoins;
      }
      if (output.featureTriggerMultiplier != null) {
        this.gameSelection.featureTriggerMultiplier = output.featureTriggerMultiplier;
      }
    },
    calculateTotalSpinValue(): string {
      let totalSpinValue =
        Number(this.awardCasinoFreespinsOfferForm.distributorSpinValue) *
        Number(this.gameSelection.numberOfLinesPerSpin);

      if (this.gameSelection.selectedGame?.value?.providerKey === 'png') {
        totalSpinValue *= Number(this.gameSelection.defaultPlayngoCoins || '1');
      }

      if (this.awardCasinoFreespinsOfferForm.freespinsType === 'feature_trigger') {
        totalSpinValue *= Number(this.gameSelection.featureTriggerMultiplier || '1');
      }

      return totalSpinValue.toString();
    },
    getGameTypeForAlgoliaGame(algoliaCasinoGameObject: AlgoliaCasinoGameObject | undefined): string {
      if (algoliaCasinoGameObject == null) {
        return 'Unknown';
      }

      if (algoliaCasinoGameObject._tags.includes('live_tile')) {
        return 'Live Tile';
      } else if (algoliaCasinoGameObject._tags.includes('is_live')) {
        return 'Live Payment';
      } else {
        return 'RNG';
      }
    },
  },
  watch: {
    'gameSelection.algolia.searchKey'(newVal: string, oldVal: string) {
      if (newVal === '') {
        this.gameSelection.algolia.searchResults = [];
      } else {
        if (newVal !== oldVal) {
          algoliaSearchIndex.search(newVal, {}).then(({ hits }) => {
            this.gameSelection.algolia.searchResults = hits as any[];
          });
        }
      }
    },
    'gameSelection.selectedGame'(newVal: GameSelectRecord | null, oldVal: GameSelectRecord | null) {
      if (newVal == null) {
        this.awardCasinoFreespinsOfferForm.casinoGameId = '';
        this.awardCasinoFreespinsOfferForm.distributorCurrency = '';
      } else {
        this.awardCasinoFreespinsOfferForm.casinoGameId = newVal.value.id;
        this.getGamesNumberOfLinesPerSpin(newVal.value.id);
        this.awardCasinoFreespinsOfferForm.totalSpinValue = '0';
      }

      this.awardCasinoFreespinsOfferForm.freespinsType = null;
    },
    'gameSelection.numberOfLinesPerSpin'(newVal: GameSelectRecord | null, oldVal: GameSelectRecord | null) {
      this.awardCasinoFreespinsOfferForm.totalSpinValue = this.calculateTotalSpinValue();
    },
    'awardCasinoFreespinsOfferForm.casinoCurrency'(newVal: string, oldVal: string) {
      if (newVal === '' || this.awardCasinoFreespinsOfferForm.casinoGameId === '') {
        this.gameSelection.availableDistributorCurrencies = [];
        this.awardCasinoFreespinsOfferForm.distributorCurrency = '';
      } else {
        this.loadGamesAvailableDistributorCurrencies(newVal, this.awardCasinoFreespinsOfferForm.casinoGameId);
      }
    },
    'awardCasinoFreespinsOfferForm.casinoGameId'(newVal: string, oldVal: string) {
      if (newVal === '' || this.awardCasinoFreespinsOfferForm.casinoCurrency === '') {
        this.gameSelection.availableDistributorCurrencies = [];
        this.gameSelection.numberOfLinesPerSpin = '';
        this.gameSelection.defaultPlayngoCoins = null;
        this.gameSelection.featureTriggerMultiplier = null;
        this.awardCasinoFreespinsOfferForm.distributorCurrency = '';
        this.awardCasinoFreespinsOfferForm.distributorSpinValue = '';
      } else {
        this.loadGamesAvailableDistributorCurrencies(this.awardCasinoFreespinsOfferForm.casinoCurrency, newVal);
        this.getGamesNumberOfLinesPerSpin(newVal);
        this.awardCasinoFreespinsOfferForm.totalSpinValue = this.calculateTotalSpinValue();
      }
    },
    'awardCasinoFreespinsOfferForm.distributorSpinValue'(newVal: string, oldVal: string) {
      this.awardCasinoFreespinsOfferForm.totalSpinValue = this.calculateTotalSpinValue();
    },
    'awardCasinoFreespinsOfferForm.freespinsType'(newVal: string, oldVal: string) {
      this.awardCasinoFreespinsOfferForm.totalSpinValue = this.calculateTotalSpinValue();
    },
  },
  render() {
    const gamesSelectList = () => {
      return this.gameSelection.algolia.searchResults.map((algoliaCasinoGameObject: AlgoliaCasinoGameObject) => {
        const record: GameSelectRecord =  {
          text: `${algoliaCasinoGameObject.name.en} (id: ${algoliaCasinoGameObject.id})`,
          value: algoliaCasinoGameObject,
        };

        return record;
      });
    };

    const casinoCurrenciesSelectList = () => {
      return buildCurrenciesSelectList(awardableCasinoCurrencies());
    };

    const distributorCurrenciesSelectList = () => {
      return buildCurrenciesSelectList(this.gameSelection.availableDistributorCurrencies);
    };

    const awardFreespinsOfferForm = () => {
      const bonusRewardTypeForm = () => {
        if (this.awardCasinoFreespinsOfferForm.rewardType === FreespinsRewardType.ROLLOVER_BONUS) {
          return (
            <div>
              <VTextField
                v-model={this.awardCasinoFreespinsOfferForm.rolloverExpiresInDays}
                label='Rollover Duration (Days)'
                error={this.awardCasinoFreespinsOfferValidationErrors?.rolloverExpiresInDays != null}
                errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.rolloverExpiresInDays || []}
                errorCount={5}
                messages={[
                  'Number of days for the player to play through his freespins bonus once it has been activated.',
                ]}
                outlined
                class={styles.inputField}
              />
              <VTextField
                v-model={this.awardCasinoFreespinsOfferForm.rolloverRequirement}
                label='Rollover Requirement'
                error={this.awardCasinoFreespinsOfferValidationErrors?.rolloverRequirement != null}
                errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.rolloverRequirement || []}
                errorCount={5}
                messages={[
                  'Number of times that the player needs to play through his initial freespins bonus balance before he can withdraw any winnings without being penalized.',
                ]}
                outlined
                class={styles.inputField}
              />
            {(() => {
              if (this.awardCasinoFreespinsOfferForm.rewardBonusType === BonusType.CASINO_DEPOSIT) {
                return (
                  <div>
                    <VTextField
                      v-model={this.awardCasinoFreespinsOfferForm.depositBonusAwardPercentage}
                      label='Deposit Bonus Award Percentage'
                      suffix='%'
                      error={this.awardCasinoFreespinsOfferValidationErrors?.depositBonusAwardPercentage != null}
                      errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.depositBonusAwardPercentage || []}
                      errorCount={5}
                      messages={[
                        'The percentage of the next qualifying deposit to be awarded as an active bonus.',
                        'E.g. A 50% award percentage for a 1 ETH deposit will award 0.5 ETH as a bonus.',
                      ]}
                      outlined
                      class={styles.inputField}
                    />
                    <div style={{ marginBottom: '3rem' }}>
                      <VCheckbox
                        v-model={this.awardCasinoFreespinsOfferForm.lockDepositBonus}
                        label='Lock Deposit?'
                        error={this.awardCasinoFreespinsOfferValidationErrors?.lockDepositBonus != null}
                        errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.lockDepositBonus || []}
                        errorCount={5}
                      />
                      <p>
                        <b>Lock Deposit:</b> When player activates a locked deposit bonus via depositing,
                        {' '}the deposit will be fully or partially locked and would only be released when
                        {' '}the locked deposit bonus is successfully rolled over or cancelled / expired.
                      </p>
                    </div>
                  </div>
                );
              }
            })()}
            </div>
          );
        }
      };

      const rewardTypeSpecificForm = () => {
        const rewardTypeSpecificFormHeader = () => {
          return (
            <div class='my-5'>
              <VDivider class='my-5' />
              <h2>Reward Configurations</h2>

              <VRadioGroup
                v-model={this.awardCasinoFreespinsOfferForm.rewardBonusType}
                row
                label='Reward Bonus Type'
                error={this.awardCasinoFreespinsOfferValidationErrors?.rewardBonusType != null}
                errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.rewardBonusType || []}
                errorCount={5}
                messages={[
                  'Type of bonus rewarded to player when player exhausts the freespins.',
                ]}
                class='pb-5'
              >
                <VRadio label='Non-deposit bonus' value={BonusType.CASINO_FREEBONUS} />
                <VRadio label='Deposit bonus' value={BonusType.CASINO_DEPOSIT} />
              </VRadioGroup>
            </div>
          );
        };

        if (this.awardCasinoFreespinsOfferForm.rewardType === FreespinsRewardType.ROLLOVER_BONUS) {
          return (
            <div class='my-5'>
              {rewardTypeSpecificFormHeader()}
              {bonusRewardTypeForm()}
            </div>
          );
        }
      };

      const totalSpinValueTextField = () => {
        let message: string = `Actual value of each freespin (\
          Freespin value awarded * Number of lines per spin).`;

        if (this.gameSelection.selectedGame?.value?.providerKey === 'png') {
          message = `Actual value of each freespin (\
            Freespin value awarded * Number of lines per spin * Default Playngo coins).`;
        }

        if (this.awardCasinoFreespinsOfferForm.freespinsType === 'feature_trigger') {
          message = `Actual value of each freespin (\
            Freespin value awarded * Number of lines per spin * Feature trigger multiplier).`;
        }

        return (
          <VTextField
            v-model={this.awardCasinoFreespinsOfferForm.totalSpinValue}
            label='Total Spin Value (in Distributor Currency)'
            messages={[message]}
            outlined
            class={styles.inputField}
            readonly
            disabled
          />
        );
      };

      return (
        <div>
           <VRadioGroup
            v-model={this.awardCasinoFreespinsOfferForm.rewardType}
            row
            label='Reward Type'
            error={this.awardCasinoFreespinsOfferValidationErrors?.rewardType != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.rewardType || []}
            errorCount={5}
            messages={[
              'Type of reward awarded to player when player exhausts freespins.',
            ]}
            class='pb-5'
          >
            <VRadio label='Rollover Bonus' value={FreespinsRewardType.ROLLOVER_BONUS} />
            <VRadio label='Real Money' value={FreespinsRewardType.REAL_MONEY} />
          </VRadioGroup>

          <VAutocomplete
            v-model={this.gameSelection.selectedGame}
            return-object
            on={{
              'update:search-input': (value: string) => {
                this.gameSelection.algolia.searchKey = value;
              },
            }}
            items={gamesSelectList()}
            label='Game'
            messages={[
              'Casino game to play the freespins.',
            ]}
            outlined
            single-line
            class={styles.inputField}
            scopedSlots={{
              item: ({ item, on }: { item: GameSelectRecord, on: object }) => {
                const gameImagePath = item.value.images.desktop;
                const gameId = item.value.id;
                const gameStudioName = humanizeShortStudioKey(item.value.providerKey);

                return (
                  <VListItem on={on}>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{item.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName} |
                        {' '}Type: {this.getGameTypeForAlgoliaGame(item.value)}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
              selection: () => {
                const gameImagePath = this.gameSelection.selectedGame?.value.images.desktop;
                const gameId = this.gameSelection.selectedGame?.value.id;
                const gameStudioName =
                  humanizeShortStudioKey(this.gameSelection.selectedGame?.value.providerKey as string);

                return (
                  <VListItem>
                    <VListItemAvatar>
                      <img src={`${env.casino.games.images_url}/${gameImagePath}`} alt='' />
                    </VListItemAvatar>
                    <VListItemContent>
                      <VListItemTitle>{this.gameSelection.selectedGame?.value.name.en}</VListItemTitle>
                      <VListItemSubtitle>
                        ID: {gameId} |
                        {' '}Studio: {gameStudioName} |
                        {' '}Type: {this.getGameTypeForAlgoliaGame(this.gameSelection.selectedGame?.value)}
                      </VListItemSubtitle>
                    </VListItemContent>
                  </VListItem>
                );
              },
            }}
          />
          {(() => {
            if (this.gameSelection.selectedGame?.value?.providerKey === 'relaxgaming') {
              return (
                <VSelect
                  v-model={this.awardCasinoFreespinsOfferForm.freespinsType}
                  label='Freespins Type'
                  items={[
                    {
                      text: 'Normal freespins (default)',
                      value: null,
                    },
                    {
                      text: 'Feature triggers',
                      value: 'feature_trigger',
                    },
                  ]}
                  error={this.awardCasinoFreespinsOfferValidationErrors?.freespinsType != null}
                  errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.freespinsType || []}
                  errorCount={5}
                  messages={[
                    'Type of freespins to be awarded.',
                  ]}
                  outlined
                  class={styles.inputField}
                />
              );
            }
          })()}
          <VTextField
            v-model={this.awardCasinoFreespinsOfferForm.spinsAwarded}
            label='Freespins Count'
            error={this.awardCasinoFreespinsOfferValidationErrors?.spinsAwarded != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.spinsAwarded || []}
            errorCount={5}
            messages={[
              'Number of freespins to be awarded.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.gameSelection.numberOfLinesPerSpin}
            label='Number Of Lines Per Spin'
            messages={[
              'Number Of Lines Per Spin.',
            ]}
            outlined
            class={styles.inputField}
            readonly
            disabled
          />
          {(() => {
            if (this.gameSelection.selectedGame?.value?.providerKey === 'png') {
              return (
                <VTextField
                  v-model={this.gameSelection.defaultPlayngoCoins}
                  label='Default Playngo Coins'
                  messages={[
                    'Default Playngo Coins for game (configured in Casino Hub).',
                  ]}
                  outlined
                  class={styles.inputField}
                  readonly
                  disabled
                />
              );
            }
          })()}
          {(() => {
            if (this.awardCasinoFreespinsOfferForm.freespinsType === 'feature_trigger') {
              return (
                <VTextField
                  v-model={this.gameSelection.featureTriggerMultiplier}
                  label='Feature Trigger Multiplier'
                  messages={[
                    'Feature Trigger Multiplier for game (configured in Casino Hub).',
                  ]}
                  outlined
                  class={styles.inputField}
                  readonly
                  disabled
                />
              );
            }
          })()}
          <VTextField
            v-model={this.awardCasinoFreespinsOfferForm.distributorSpinValue}
            label='Spin Value (in Distributor Currency)'
            error={this.awardCasinoFreespinsOfferValidationErrors?.distributorSpinValue != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.distributorSpinValue || []}
            errorCount={5}
            messages={[
              'Value of each freespin excluding lines/coins (in Distributor Currency).',
            ]}
            outlined
            class={styles.inputField}
          />
          {totalSpinValueTextField()}
          <VSelect
            v-model={this.awardCasinoFreespinsOfferForm.casinoCurrency}
            label='Casino Currency'
            items={casinoCurrenciesSelectList()}
            error={this.awardCasinoFreespinsOfferValidationErrors?.casinoCurrency != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.casinoCurrency || []}
            errorCount={5}
            messages={[
              'Player currency in which the freespins offer will be awarded in.',
              'This is also the reward currency.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VSelect
            v-model={this.awardCasinoFreespinsOfferForm.distributorCurrency}
            label='Distributor Currency'
            items={distributorCurrenciesSelectList()}
            error={this.awardCasinoFreespinsOfferValidationErrors?.distributorCurrency != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.distributorCurrency || []}
            errorCount={5}
            messages={[
              'Game currency in which the freespins offer will be played in.',
              'E.g. If casino currency is BTC and distributor currency is EUR, then player must select BTC as his active currency and launch the specified game in EUR in order to use the freespins.',
            ]}
            outlined
            class={styles.inputField}
          />
          <VTextField
            v-model={this.awardCasinoFreespinsOfferForm.promocode}
            label='Promo Code'
            error={this.awardCasinoFreespinsOfferValidationErrors?.promocode != null}
            errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.promocode || []}
            errorCount={5}
            messages={[
              'Used for identifying which marketing campaign this freespins offer to be awarded references.',
              'Must be unique for a player and distributor. E.g. "RelaxGaming-GameX-TuesdayDeposit-2021-10-11".',
            ]}
            outlined
            class={styles.inputField}
          />
          <VMenu
            v-model={this.awardCasinoFreespinsOfferForm.expiresAtDatePickerMenuOpen}
            closeOnContentClick={true}
            nudgeRight={150}
            transition='scale-transition'
            minWidth='auto'
            scopedSlots={{
              activator: ({ on, attrs }: any) => {
                return (
                  <VTextField
                    v-model={this.awardCasinoFreespinsOfferForm.expiresAt}
                    label='Offer Expires At'
                    readonly
                    error={this.awardCasinoFreespinsOfferValidationErrors?.expiresAt != null}
                    errorMessages={this.awardCasinoFreespinsOfferValidationErrors?.expiresAt || []}
                    errorCount={5}
                    messages={[
                      'Date by which the player has to exhaust the freespins offer.',
                    ]}
                    bind={attrs}
                    on={on}
                    outlined
                    class={styles.inputField}
                  />
                );
              },
            }}
          >
            <VDatePicker v-model={this.awardCasinoFreespinsOfferForm.expiresAt} />
          </VMenu>

          {rewardTypeSpecificForm()}
        </div>
      );
    };

    return (
      <VDialog
        v-model={this.open}
        on={{
          'click:outside': () => { this.$emit('open', false); },
        }}
        maxWidth='700'
      >
        <VCard>
          <VCardTitle>
            Award Casino Freespins Offer Form
          </VCardTitle>
          <VCardText>
            <VContainer>
              <VRow>
                <VCol>
                  {awardFreespinsOfferForm()}
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn onClick={() => { this.$emit('open', false); }}>Close</VBtn>
            <VBtn
              color='primary'
              onClick={() => { this.onAwardFreespinsOfferSubmit(); }}
              loading={this.loading}
              disabled={this.loading}
            >
              Award Freespins Offer
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
