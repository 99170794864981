import Vue from 'vue';
import { ProductSummary } from '../PlayerBetActivity/PlayerBetActivity';
import './BetActivitySummary.less';
import { VDataTable } from 'vuetify/lib';
import { env } from '@/env';

interface ProductSummaryWithName extends ProductSummary {
  name: string;
}

const reportsUrl = (nickname: string) => {
  return `${env.redashUri}/dashboards/90-sports-activity-per-player?p_Player%20Nickname=${nickname}&refresh=86400`;
};

export default Vue.extend({
  props: {
    casinoSummary: Object as () => ProductSummary,
    sportSummary: Object as () => ProductSummary,
    displayedCurrency: String,
    playerNickname: String,
  },
  data() {
    return {
      headers: [
        {
          text: 'Product',
          value: 'name',
        },
        {
          text: 'Last Transaction Date (UTC)',
          value: 'lastTransactionDate',
        },
        {
          text: 'Average bet size',
          value: 'averageBetSize',
        },
        {
          text: `Turnover ${this.displayedCurrency}`,
          value: 'turnover',
        },
        {
          text: `Pnl ${this.displayedCurrency}`,
          value: 'pnl',
        },
        {
          text: 'Margin (%)',
          value: 'margin',
        },
        {
          text: 'Total Bets',
          value: 'numberBets',
        },
        {
          text: '',
          value: 'links',
        },
      ],
    };
  },
  render() {
    return (
      <VDataTable
        headers={this.headers}
        rowsPerPageItems={[]}
        hideDefaultFooter
        items={[
          { ...this.sportSummary, name: 'Sports' },
          { ...this.casinoSummary, name: 'Casino' },
        ]}
        scopedSlots={{
          'item.links': ({ item }: { item: ProductSummaryWithName }) => {
            if (item.name === 'Sports') {
              return <a href={reportsUrl(this.playerNickname)} target='_blank'>Reports</a>;
            }
          },
        }}
      />
    );
  },
});
