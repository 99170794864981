import { Category, Sport } from '@/store/modules/sports_consts';

const isUUID = (text: string) => {
  return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(
    text,
  );
};

const isID = (text: string) => {
  return /^\d+$/.test(text);
};

interface SearchOptions {
  error?: string;
}

export interface PlayerOptions extends SearchOptions {
  entityType: 'player';
  uuid?: string;
  text?: string;
}

export interface UUIDOptions extends SearchOptions {
  uuid?: string;
  entityType: 'uuid';
}

export interface NicknameOptions extends SearchOptions {
  text?: string;
  entityType: 'nickname';
}

export interface WithSportAndCategoryOptions extends SearchOptions {
  id?: number;
  text?: string;
  sportId?: number;
  sportKey?: string;
  sportName?: string;
  categoryId?: number;
  categoryName?: string;
  entityType: 'event' | 'competition';
}

export interface WithSportOptions extends SearchOptions {
  id?: number;
  text?: string;
  sportId?: number;
  sportKey?: string;
  sportName?: string;
  entityType: 'competitor';
}

export interface AddressOptions extends SearchOptions {
  text?: string;
  entityType: 'address';
}

export interface UnknownEntity {
  error: string;
  entityType: '';
}

const parseUUID = (tokens: string[]): UUIDOptions => {
  if (tokens.length < 1) {
    return { error: 'missing parameter', entityType: 'uuid' };
  }

  if (tokens.length > 1) {
    return { error: 'too many parameters', entityType: 'uuid' };
  }

  if (isUUID(tokens[0])) {
    return { uuid: tokens[0], entityType: 'uuid' };
  }

  return { error: 'wrong parameter', entityType: 'uuid' };
};

interface FindKeywordResult {
  details?: Sport | Category;
  leftTokens: string[];
}

const findKeyword = (
  tokens: string[],
  keywords: Record<string, Sport | Category>,
): FindKeywordResult => {
  for (let startIndex = 0; startIndex < tokens.length; ++startIndex) {
    for (let index = tokens.length - 1; index >= startIndex; --index) {
      const phrase = tokens
        .slice(startIndex, index + 1)
        .join(' ')
        .toLowerCase();
      if (keywords[phrase]) {
        const leftTokens = [...tokens];
        leftTokens.splice(startIndex, index - startIndex + 1);
        return {
          details: keywords[phrase],
          leftTokens,
        };
      }
    }
  }

  return { leftTokens: tokens };
};

const parseOptionWithSportAndCategory = (
  tokens: string[],
  entityType: 'event' | 'competition',
  sports: Record<string, Sport>,
  categories: Record<string, Category>,
): WithSportAndCategoryOptions => {
  if (tokens.length < 1) {
    return { error: 'missing parameters', entityType };
  }

  if (tokens.length === 1 && isID(tokens[0])) {
    return { id: parseInt(tokens[0], 10), entityType };
  }

  const sportKeyword = findKeyword(tokens, sports);
  const sportId = sportKeyword.details?.id;
  const sportKey = sportKeyword.details?.key;
  const sportName = sportKeyword.details?.name;
  tokens = sportKeyword.leftTokens;

  const categoryToken = findKeyword(tokens, categories);
  const categoryId = categoryToken.details?.id;
  const categoryName = categoryToken.details?.name;
  tokens = categoryToken.leftTokens;

  return {
    sportId,
    sportKey,
    sportName,
    categoryId,
    categoryName,
    text: tokens.join(' '),
    entityType,
  };
};

const parseOptionWithSport = (
  tokens: string[],
  entityType: 'competitor',
  sports: Record<string, Sport>,
): WithSportOptions => {
  if (tokens.length < 1) {
    return { error: 'missing parameters', entityType };
  }

  if (tokens.length === 1 && isID(tokens[0])) {
    return { id: parseInt(tokens[0], 10), entityType };
  }

  const sportKeyword = findKeyword(tokens, sports);
  const sportId = sportKeyword.details?.id;
  const sportKey = sportKeyword.details?.key;
  const sportName = sportKeyword.details?.name;
  tokens = sportKeyword.leftTokens;

  return { sportId, sportKey, sportName, text: tokens.join(' '), entityType };
};

const parseNickname = (tokens: string[]): NicknameOptions => {
  if (tokens.length < 1) {
    return { error: 'missing parameter', entityType: 'nickname' };
  }

  const text = tokens.join(' ');
  return { text, entityType: 'nickname' };
};

const parsePlayer = (tokens: string[]): PlayerOptions => {
  if (tokens.length < 1) {
    return { error: 'missing parameter', entityType: 'player' };
  }

  if (tokens.length === 1 && isUUID(tokens[0])) {
    return { uuid: tokens[0], entityType: 'player' };
  }

  const text = tokens.join(' ');
  return { text, entityType: 'player' };
};

const parseAddress = (tokens: string[]): AddressOptions => {
  if (tokens.length < 1) {
    return { entityType: 'address', error: 'missing parameter' };
  }

  return { entityType: 'address', text: tokens.join(' ') };
};

/* The function parseInput parses the following cases:

uuid-text
uuid uuid-text

event id-text
event sport category text

competitor id-text
competitor sport text

competition id-text
competition sport category text

nickname text

player uuid-text
player text

Some parameters, except the entities types, can be omitted. For example 'event category text' is
correct.
*/
export const parseInput = (
  input: string,
  sports: Record<string, Sport>,
  categories: Record<string, Category>,
) => {
  const tokens = input
    .trim()
    .split(' ')
    .filter((i) => i);

  if (tokens.length === 1 && isUUID(tokens[0])) {
    return { entityType: 'uuid', uuid: tokens[0] } as UUIDOptions;
  }

  if (tokens.length > 0) {
    switch (tokens[0].toLowerCase()) {
      case 'uuid':
        return parseUUID(tokens.splice(1));
      case 'event':
        return parseOptionWithSportAndCategory(
          tokens.slice(1),
          'event',
          sports,
          categories,
        );
      case 'competitor':
        return parseOptionWithSport(tokens.slice(1), 'competitor', sports);
      case 'competition':
        return parseOptionWithSportAndCategory(
          tokens.slice(1),
          'competition',
          sports,
          categories,
        );
      case 'nickname':
        return parseNickname(tokens.slice(1));
      case 'player':
        return parsePlayer(tokens.slice(1));
      case 'address':
        return parseAddress(tokens.slice(1));
    }
  }

  return {
    entityType: '',
    error: 'unknown option',
    text: tokens.join(' '),
  } as UnknownEntity;
};
