import Vue from 'vue';
import {
  VCard,
  VCardTitle,
  VCardText,
  VDialog,
  VBtn,
  VCardSubtitle,
  VStepper,
  VStepperHeader,
  VDivider,
  VStepperStep,
  VStepperItems,
  VStepperContent,
} from 'vuetify/lib';

import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import { gql } from 'apollo-boost';
import { Event } from '@/types/sports';
import UnmatchedEventsDataTable from '../EventsMatching/UnmatchedEventsDataTable';
import Confirmation from '../EventsMatching/Confirmation';
import Summary from '../EventsMatching/Summary';

export default Vue.extend({
  props: {
    event: Object as () => Event,
  },

  data() {
    return {
      dialog: true,
      registry: [] as UnmatchedEvent[],
      unmatchedEvent: null as UnmatchedEvent | null,
      matchedEvent: null as UnmatchedEvent | null,
      step: 1,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },
  },

  apollo: {
    registry: {
      query: gql`query ($eventId: Int!) {
        getEventRegistry(eventId: $eventId) {
          events {
            awayName
            categoryName
            competitionName
            cutoff
            eventId
            eventName
            eventType
            homeName
            teamSwapped
            provider
            providerEventKey
            sportKey
            status
          }
        }
      }`,

      variables(): { eventId: number } {
        return {
          eventId: this.event.id,
        };
      },

      update(data) {
        return data.getEventRegistry.events;
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <VDialog vModel={this.dialog} onInput={this.onClose}>
        <VStepper vModel={this.step} >
          <VStepperHeader>
            <VStepperStep complete={this.step > 1} step='1' >
              Select a provider event
            </VStepperStep>
            <VDivider />
            <VStepperStep complete={this.step > 3} step='2'>
              Confirm
            </VStepperStep>
          </VStepperHeader>

          <VStepperItems >
            <VStepperContent step='1' >
              <VCard elevation={3} class='mt-1'>
                <VCardTitle>
                  Providers Events
                </VCardTitle>
                <VCardSubtitle>
                  Select a provider unmatched event which you want to match
                </VCardSubtitle>
                <VCardText>
                  <UnmatchedEventsDataTable
                    events={this.registry}
                    loadedPages={1}
                    totalPages={1}
                    loading={this.$apollo.queries.registry.loading}
                    onEventSelected={(event: UnmatchedEvent) => {
                      this.unmatchedEvent = event;
                      this.step = 2;
                    }}
                    onEventUnmatched={(event: UnmatchedEvent) => {
                      this.$apollo.queries.registry.refresh();
                    }}
                  />
                </VCardText>
              </VCard>
              <VBtn class='mt-2' onClick={this.onClose}>Close</VBtn>
            </VStepperContent>

            <VStepperContent step='2' >
              {this.unmatchedEvent && this.event &&
                <Confirmation
                  unmatchedEvent={this.unmatchedEvent}
                  genericEvent={this.event}
                  onConfirmed={(event: UnmatchedEvent) => {
                    this.matchedEvent = event;
                    this.step = 3;
                  }} />
              }
              <VBtn class='mt-2' onClick={() => {
                this.step = 1;
              }}>Back</VBtn>
            </VStepperContent>

            <VStepperContent step='3' >
              {
                this.matchedEvent &&
                this.event &&
                <Summary matchedEvent={this.matchedEvent} genericEvent={this.event} />
              }
              <VBtn class='mt-2' color='primary' onClick={() => {
                this.matchedEvent = null;
                this.step = 1;
                this.$apollo.queries.registry.refresh();
              }}>Continue</VBtn>
            </VStepperContent>
          </VStepperItems >
        </VStepper >
      </VDialog>
    );
  },
});
