import Vue from 'vue';

import { apolloProvider } from './apollo';
import App from './App.vue';
import { initializeDirectives } from './directives';
import { initializeFilters } from './filters';
import { initializeFirebase } from './firebase';
import DashboardLayout from './layouts/Dashboard.vue';
import SigninLayout from './layouts/Signin.vue';
import vuetify from './plugins/vuetify';
import { initializePlugins } from './plugins';
import store from './store';
import router from './router';
import './register-service-worker';
import performDomainRedirects from './router/domain-redirects';

/**
 * perform domain redirects if applicable
 * zero-ui.prod.kubershmuber.com -> zero.prod-eu.kubershmuber.com
 * zero-ui.dev.kubershmuber.com -> zero.dev-eu.kubershmuber.com
 */
performDomainRedirects();

// Initialize Firebase
initializeFirebase();

// Setup Vue Layout Components
Vue.component('signin-layout', SigninLayout);
Vue.component('dashboard-layout', DashboardLayout);

Vue.config.productionTip = false;

initializeDirectives();
initializeFilters();
initializePlugins();

Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
  vm.$rollbar.error('Unhandled error', err, { payload: { info } });
};

const zeroApp = new Vue({
  router,
  store,
  vuetify,
  apolloProvider,
  render: (h) => h(App),
}).$mount('#app');

export default zeroApp;
