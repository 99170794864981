<template>
  <td
    align="center"
    :style="eventLiabilityCellStyles({profitAndLoss,maxProfit,maxLoss,isNightMode})"
    :class="`event-liability-cell ${className}`"
  >{{ profitAndLoss | currency(0) }}</td>
</template>
<script>
import { env } from '@/env';
import { mapGetters, mapActions } from 'vuex';
export default {
  props: {
    profitAndLoss: Number,
    maxProfit: Number,
    maxLoss: Number,
  },
  data() {
    return {
      className: '',
    };
  },
  computed: {
    ...mapGetters({
      isNightMode: 'preferences/isNightMode',
    }),
  },
  methods: {
    eventLiabilityCellStyles: ({
      profitAndLoss,
      maxProfit,
      maxLoss,
      isNightMode = false,
    }) => {
      const maxColorStrength = 4; // 4 is the highest for darken for vuetify, use it for lighten as well
      const percentage = Math.floor(
        (profitAndLoss / (profitAndLoss < 0 ? maxLoss : maxProfit)) *
          maxColorStrength,
      ) || 0;
      // Invert it as we use lighter color for less percentage
      const colorStrength = isNightMode
        ? percentage
        : maxColorStrength - percentage;
      let baseColorType = 'warning';
      if (profitAndLoss > 0) {
        baseColorType = 'success';
      } else if (profitAndLoss < 0) {
        baseColorType = 'error';
      }
      const baseColorString = isNightMode
        ? `--v-${baseColorType}-darken`
        : `--v-${baseColorType}-lighten`;
      return {
        'background-color':
          colorStrength === 0
            ? `var(--v-${baseColorType}-base)`
            : `var(${baseColorString}${colorStrength})`,
      };
    },
    animatedClass: (profitAndLoss) => {
      if (profitAndLoss > 0) {
        return 'win';
      } else if (profitAndLoss < 0) {
        return 'loss';
      } else if (profitAndLoss === 0) {
        return 'warning';
      }
    },
  },
  watch: {
    profitAndLoss: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      handler(val, oldVal) {
        if (val === oldVal) {
          return;
        }
        if (val < oldVal) {
          this.className = 'loss';
          return;
        }
        if (val > oldVal) {
          this.className = 'win';
          return;
        }
      },
    },
  },
};
</script>

<style lang="less">
.event-liability-cell {
  &.win {
    animation: animated-win 1s 1 /* IE 10+, Fx 29+ */
  }
  &.loss {
    -webkit-animation: animated-loss 1s 1; /* Safari 4+ */
    -moz-animation: animated-loss 1s 1; /* Fx 5+ */
    -o-animation: animated-loss 1s 1; /* Opera 12+ */
    animation: animated-loss 1s 1; /* IE 10+, Fx 29+ */
  }
}
@keyframes animated-loss {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--v-error-lighten1);
  }
}
@keyframes animated-win {
  0% {
    background-color: transparent;
  }
  100% {
    background-color: var(--v-success-lighten1);
  }
}
</style>
