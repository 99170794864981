// views/withdrawal-approval/PendingTasks.tsx
import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VBtn,
  VCol,
  VDataTable,
  VIcon,
  VList,
  VListItem,
  VListItemTitle,
  VRow,
  VTooltip,
} from 'vuetify/lib';
import { unixToDateTimeLocalString } from '@/utils/date';
import { hasRoles } from '@/utils/auth';

import './PendingTasks.less';
import ActionWithdrawalDialog from './ActionWithdrawalDialog';

const PAGE_SIZE_OPTIONS = [10, 20, 50];

interface TaskDecision {
  action: string;
  agent: string;
  agentType: string;
  namespace: string;
  reason: string;
  createdAt: number;
  updatedAt: number;
}

interface PendingTask {
  amount: string;
  currency: string;
  customerUUID: string;
  decisions: [TaskDecision];
  state: string;
  timestamp: number;
  uuid: string;
}

export default Vue.extend({
  name: 'pendingWithdrawalTasksWidget',

  data() {
    return {
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || PAGE_SIZE_OPTIONS[0],
      withdrawalWorkflows: {
        tasks: [],
        total: 0,
      },
      pendingWithdrawalTasks: {
        tasks: [],
        total: 0,
        enableDialog: false,
      },
      actionWithdrawal: {
        enableDialog: false,
        taskUUID: '',
        domain: '',
        currency: '',
        amount: '',
        decision: '',
        reason: '',
      },
    };
  },

  methods: {
    onPaginationHandler(options: any) {
      this.$emit('pageUpdate', options.page, options.itemsPerPage);
    },

    onClickPlayerUUID(playerUUID: string) {
      this.$router.push(`/players/${playerUUID}`);
    },

    onClickDecideAction(taskUUID: string, domain: string, currency: string, amount: string) {
      this.actionWithdrawal.taskUUID = taskUUID;
      this.actionWithdrawal.domain = domain;
      this.actionWithdrawal.currency = currency;
      this.actionWithdrawal.amount = amount;
      this.actionWithdrawal.enableDialog = true;
    },

    approvalCell(
      taskUUID: string,
      domain: string,
      currency: string,
      amount: string,
      agentType: string,
      decision: string,
      reason: string,
    ) {
      // Green tick if approved, no further action.
      if (decision === 'approve') {
        return (<VIcon color='green'>check</VIcon>);
      }

      // Red cross if rejected by human, no further action.
      if (decision === 'reject' && agentType === 'human') {
        return (
          <VTooltip bottom
            scopedSlots={{
              activator: ({ on, attrs }: any) => (
                <span on={on}>
                  <VIcon color='red'>close</VIcon>
                </span>
              ),
            }}
          >
            <span>{reason}</span>
          </VTooltip>
        );
      }

      // Pending, or rejected with machine, pending for human decision.
      return (
        <VTooltip bottom
          scopedSlots={{
            activator: ({ on, attrs }: any) => (
              <span on={on}>
                {
                  hasRoles([`${domain}:operator`]) ?
                  <VBtn elevation='0' onclick={() => {
                    this.onClickDecideAction(taskUUID, domain, currency, amount);
                  }}>
                    <VIcon color='grey'>check</VIcon>/
                    <VIcon color='grey'>close</VIcon>
                  </VBtn>
                  : <VIcon color='grey'>close</VIcon>
                }
              </span>
            ),
          }}
        >
          <span>{reason}</span>
        </VTooltip>
      );
    },
  },

  apollo: {
    withdrawalWorkflows: {
      query: gql`query ($page: Int, $pageSize: Int) {
        pendingWithdrawalTasks(page: $page, pageSize: $pageSize) {
          tasks {
            amount
            currency
            customerUUID
            state
            timestamp
            uuid
            decisions {
              action
              agent
              agentType
              namespace
              reason
              createdAt
              updatedAt
            }
          }
          total
        }
      }`,
      variables(): {
        page: number,
        pageSize: number,
      } {
        return {
          page: this.page,
          pageSize: this.pageSize,
        };
      },
      update(data) {
        return {
          tasks: data.pendingWithdrawalTasks.tasks,
          total: data.pendingWithdrawalTasks.total,
        };
      },
      fetchPolicy: 'network-only',
    },
  },

  computed: {
    headers() {
      return [
        {
          text: 'Created',
          align: 'start',
          sortable: true,
          value: 'createdAt',
          width: '180',
        },
        {
          text: 'Reference',
          align: 'start',
          sortable: false,
          value: 'uuid',
          width: '180',
        },
        {
          text: 'Player',
          align: 'start',
          sortable: false,
          value: 'customerUUID',
          width: '180',
        },
        {
          text: 'Currency',
          align: 'start',
          sortable: false,
          value: 'currency',
          width: '30',
        },
        {
          text: 'Amount',
          align: 'start',
          sortable: false,
          value: 'amount',
        },
        {
          text: (
            <div>
              <span class='subheading'>Approvals</span>
              <VRow align='center' justify='space-around'>
                <VCol align-self='start'>Casino</VCol>
                <VCol align-self='start'>Sports</VCol>
                <VCol align-self='start'>Ops</VCol>
              </VRow>
            </div>
          ),
          align: 'center',
          sortable: false,
          value: 'decisions',
        },
      ];
    },
  },

  render() {
    return (
      <div>
        <VList>
          <VListItem>
            <VListItemTitle>Pending Withdrawals</VListItemTitle>
          </VListItem>
        </VList>
        <VDataTable
          headers={this.headers}
          items={this.withdrawalWorkflows.tasks || []}
          scopedSlots={{
            'item.createdAt': ({ item }: { item: PendingTask }) => (
              <span>
                {unixToDateTimeLocalString(Number(item.timestamp))}
              </span>
            ),
            'item.decisions': ({ item }: { item: PendingTask }) => {
              const uuid = item.uuid;
              const currency = item.currency;
              const amount = item.amount;

              let casinoAgentType = '';
              let casinoDecision = 'pending';
              let casinoReason = '';
              let sportsAgentType = '';
              let sportsDecision = 'pending';
              let sportsReason = '';
              let opsAgentType = '';
              let opsDecision = 'pending';
              let opsReason = '';

              if (item.decisions) {
                for (const d of item.decisions) {
                  switch (d.namespace) {
                    case 'casino':
                      casinoAgentType = d.agentType;
                      casinoDecision = d.action;
                      casinoReason = d.reason;
                      break;
                    case 'sports':
                      sportsAgentType = d.agentType;
                      sportsDecision = d.action;
                      sportsReason = d.reason;
                      break;
                    case 'ops':
                      opsAgentType = d.agentType;
                      opsDecision = d.action;
                      opsReason = d.reason;
                      break;
                  }
                }
              }
              return (
                <VRow align='center' justify='space-around'>
                  <VCol align-self='start'>
                    {
                      this.approvalCell(
                        uuid,
                        'casino',
                        currency,
                        amount,
                        casinoAgentType,
                        casinoDecision,
                        casinoReason,
                      )
                    }
                  </VCol>
                  <VCol align-self='start'>
                    {
                      this.approvalCell(
                        uuid,
                        'sports',
                        currency,
                        amount,
                        sportsAgentType,
                        sportsDecision,
                        sportsReason,
                        )
                    }
                  </VCol>
                  <VCol align-self='start'>
                    {
                      this.approvalCell(
                        uuid,
                        'ops',
                        currency,
                        amount,
                        opsAgentType,
                        opsDecision,
                        opsReason,
                      )
                    }
                  </VCol>
                </VRow>
              );
            },
            'item.customerUUID': ({ item }: { item: PendingTask }) => (
              <div class='pointer' onclick={() => { this.onClickPlayerUUID(item.customerUUID); }}>
                <a>{item.customerUUID}</a>
              </div>
            ),
          }}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.withdrawalWorkflows.loading}
          page={this.page}
          items-per-page={this.pageSize}
          server-items-length={this.withdrawalWorkflows.total || 0}
          onPagination={this.onPaginationHandler}
          footerProps={{'items-per-page-options': PAGE_SIZE_OPTIONS}}
        />

        {
          // Action dialog.
          this.actionWithdrawal.enableDialog ?
            <ActionWithdrawalDialog
              taskUUID={this.actionWithdrawal.taskUUID}
              domain={this.actionWithdrawal.domain}
              currency={this.actionWithdrawal.currency}
              amount={this.actionWithdrawal.amount}
              onDialogClosed={() => {
                this.actionWithdrawal.enableDialog = false;
                this.$apollo.queries.withdrawalWorkflows.refetch();
              }}
            />
          : <div />
        }
      </div>
    );
  },
});
