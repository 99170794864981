import { formatCurrency } from '@/filters';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VContainer, VDataTable, VDivider, VSheet, VProgressLinear, VRow } from 'vuetify/lib';
import { RouterLink } from '@/router';
import './PlayerList.less';
import CountryFlag from 'vue-country-flag';

import { BetActivitySparkLine } from '../BetActivitySparkline/BetActivitySparkline';
interface Player {
  uuid: string;
  nickname: string;
  exposure: number;
  eventsCount: number;
  countries: string[];
}

export const PlayerList = Vue.extend({
  data() {
    return {
      dataLoaded: false,
      players: [] as Player[],
      subscription: null as any,
      headers: [
        {
          text: 'Player',
          align: 'start',
          sortable: false,
          value: 'player',
        },
        {
          text: 'Revenue (Last 3 months, EUR)',
          align: 'start',
          sortable: false,
          value: 'pnl',
        },
        {
          text: 'exposure',
          align: 'start',
          sortable: false,
          value: 'exposure',
        },
        {
          text: '#events',
          align: 'start',
          sortable: false,
          value: 'eventsCount',
        },
      ],
      dialogs: {} as any,
    };
  },
  methods: {
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription {
              playersUpdated {
                uuid
                nickname
                exposure
                eventsCount
                countries
              }
            }
          `,
        })
        .subscribe({
          next: ({ data }) => {
            this.players = data.playersUpdated;
            this.dataLoaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  render() {
    return <VDataTable
      headers={this.headers}
      items={this.players}
      itemKey='reference'
      loading={!this.dataLoaded}
      hideDefaultFooter
      loadingText='Loading... Please wait'
      scopedSlots={{
        'item.player': ({ item }: { item: Player }) => {
          return <RouterLink
              class='text-decoration-none'
              to={{ name: 'player', params: { playerUUID: item.uuid } }}
            >
            <span>
              {item.nickname || item.uuid}
              {item.countries
              .map((country) => <CountryFlag country={country} size='small' rounded={true} />)}
            </span>
          </RouterLink>;
        },
        'item.pnl': ({ item }: { item: Player }) => {
          return <div class='player-list-table-sparkline'>
            <BetActivitySparkLine playerUUID={item.uuid} />
          </div>;
        },
        'item.exposure': ({ item }: { item: Player }) => {
          return formatCurrency(item.exposure, 0);
        },
      }}>
    </VDataTable>;
  },
});
