import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCol,
  VContainer,
  VDataTable,
  VRow,
} from 'vuetify/lib';
import moment from 'moment';
import ConfirmationDialog from './ConfirmationDialog';
import { unixToDateTimeISOString } from '@/utils/date';

import AwardCasinoEvolutionVoucherDialog from './AwardCasinoEvolutionVoucherDialog';
import {
  CancelCasinoEvolutionVoucherInput,
  CasinoEvolutionVoucher,
  GetPlayerCasinoEvolutionVouchersInput,
  GetPlayerCasinoEvolutionVouchersOutput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { humanizeCurrency } from '@/utils/casino/currency';

interface VueData {
  playerCasinoEvolutionVouchers: GetPlayerCasinoEvolutionVouchersOutput;
  awardVoucherDialogOpen: boolean;
  cancelVoucherDialogOpen: boolean;
  cancelVoucherID: string;
  cancelVoucherLoading: boolean;
  apiError: ApiError | null;
}

const ITEMS_PER_PAGE = 10;

const PlayerCasinoEvolutionVouchers = Vue.extend({
  props: {
    playerUUID: String,
  },
  data(): VueData {
    return {
      playerCasinoEvolutionVouchers: {
        records: [],
        pagination: {
          page: '1',
          totalRecords: '0',
          perPage: `${ITEMS_PER_PAGE}`,
        },
      },
      awardVoucherDialogOpen: false,
      cancelVoucherDialogOpen: false,
      cancelVoucherID: '',
      cancelVoucherLoading: false,
      apiError: null,
    };
  },
  created() {
    this.loadPlayerCasinoEvolutionVouchers('1');
  },
  methods: {
    async loadPlayerCasinoEvolutionVouchers(page: string) {
      const input: GetPlayerCasinoEvolutionVouchersInput = {
        playerUUID: this.playerUUID,
        page,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetPlayerCasinoEvolutionVouchersInput!) {
            getPlayerCasinoEvolutionVouchers(input: $input) {
              records {
                id
                evolutionCampaignId
                evolutionVoucherId
                casinoCurrency
                distributorCurrency
                evolutionExpirationDuration
                evolutionCurrency
                evolutionInitialBalance
                evolutionMaxWinnings
                evolutionCloseReason
                awardedAt
                awardSourceType
                awardSourceId
                cancelledAt
                cancelSourceType
                cancelSourceId
                expiredAt
                exhaustedAt
                exhaustedAmount
                gameName
              }
              pagination {
                page
                totalRecords
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetPlayerCasinoEvolutionVouchersOutput = response.data.getPlayerCasinoEvolutionVouchers;
      this.playerCasinoEvolutionVouchers = output;
    },
    onPageUpdate(page: number) {
      this.loadPlayerCasinoEvolutionVouchers(`${page}`);
    },
    onVoucherCancel(voucherID: string) {
      this.cancelVoucherID = voucherID;
      this.cancelVoucherDialogOpen = true;
    },
    async onVoucherCancelConfirm(voucherID: string) {
      try {
        this.cancelVoucherLoading = true;

        const input: CancelCasinoEvolutionVoucherInput = {
          ID: voucherID,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: CancelCasinoEvolutionVoucherInput!) {
              cancelCasinoEvolutionVoucher(input: $input) {
                ID
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.apiError = null;
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        if (errResponse != null) {
          this.apiError = {
            url: errResponse.url,
            status: errResponse.status,
            body: errResponse.body,
          };
        }
      } finally {
        await this.loadPlayerCasinoEvolutionVouchers('1');
        this.cancelVoucherLoading = false;
        this.cancelVoucherDialogOpen = false;
      }
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Game',
          value: 'gameName',
        },
        {
          text: 'Evolution campaign id',
          value: 'evolutionCampaignId',
        },
        {
          text: 'Evolution voucher id',
          value: 'evolutionVoucherId',
        },
        {
          text: 'Casino currency',
          value: 'casinoCurrency',
        },
        {
          text: 'Distributor currency',
          value: 'distributorCurrency',
        },
        {
          text: 'Evolution expiration duration',
          value: 'evolutionExpirationDuration',
        },
        {
          text: 'Evolution currency',
          value: 'evolutionCurrency',
        },
        {
          text: 'Evolution initial balance',
          value: 'evolutionInitialBalance',
        },
        {
          text: 'Evolution max winnings',
          value: 'evolutionMaxWinnings',
        },
        {
          text: 'Evolution close reason',
          value: 'evolutionCloseReason',
        },
        {
          text: 'Awarded at',
          value: 'awardedAt',
        },
        {
          text: 'Award source type',
          value: 'awardSourceType',
        },
        {
          text: 'Award source id',
          value: 'awardSourceId',
        },
        {
          text: 'Cancelled at',
          value: 'cancelledAt',
        },
        {
          text: 'Cancel source type',
          value: 'cancelSourceType',
        },
        {
          text: 'Cancel source id',
          value: 'cancelSourceId',
        },
        {
          text: 'Expires at',
          value: 'expiresAt',
        },
        {
          text: 'Expired at',
          value: 'expiredAt',
        },
        {
          text: 'Exhausted at',
          value: 'exhaustedAt',
        },
        {
          text: 'Exhausted amount',
          value: 'exhaustedAmount',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
    scopedSlots() {
      return {
        'item.casinoCurrency': ({ item }: { item: CasinoEvolutionVoucher }) => {
          return humanizeCurrency(item.casinoCurrency);
        },
        'item.distributorCurrency': ({ item }: { item: CasinoEvolutionVoucher }) => {
          return humanizeCurrency(item.distributorCurrency);
        },
        'item.evolutionExpirationDuration': ({ item }: { item: CasinoEvolutionVoucher }) => {
          const duration = moment.duration(item.evolutionExpirationDuration);

          return `${item.evolutionExpirationDuration} (${duration.humanize()})`;
        },
        'item.awardedAt': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.awardedAt == null || item.awardedAt === '') {
            return null;
          }

          return unixToDateTimeISOString(item.awardedAt);
        },
        'item.cancelledAt': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.cancelledAt == null || item.cancelledAt === '') {
            return null;
          }

          return unixToDateTimeISOString(item.cancelledAt);
        },
        'item.expiresAt': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.awardedAt == null || item.awardedAt === '') {
            return null;
          }

          const duration = moment.duration(item.evolutionExpirationDuration);
          const expiresAt = moment(unixToDateTimeISOString(item.awardedAt)).utc().add(duration).format();

          return expiresAt;
        },
        'item.expiredAt': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.expiredAt == null || item.expiredAt === '') {
            return null;
          }

          return unixToDateTimeISOString(item.expiredAt);
        },
        'item.exhaustedAt': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.exhaustedAt == null || item.exhaustedAt === '') {
            return null;
          }

          return unixToDateTimeISOString(item.exhaustedAt);
        },
        'item.exhaustedAmount': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (item.exhaustedAmount == null || item.exhaustedAmount === '') {
            return null;
          }

          return `${item.exhaustedAmount} ${humanizeCurrency(item.casinoCurrency)}`;
        },
        'item.actions': ({ item }: { item: CasinoEvolutionVoucher }) => {
          if (
            (item.cancelledAt == null || item.cancelledAt === '') &&
            (item.expiredAt == null || item.expiredAt === '') &&
            (item.exhaustedAt == null || item.exhaustedAt === '')
          ) {
            return (
              <VBtn
                depressed
                color='error'
                onClick={() => { this.onVoucherCancel(item.id); }}
              >
                Cancel
              </VBtn>
            );
          }
        },
      };
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            {apiErrorDisplay()}
            <h1>Casino Evolution Vouchers</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol align='end'>
            <VBtn
              color='primary'
              onClick={() => { this.awardVoucherDialogOpen = true; }}
            >
              Award Freebets
            </VBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VDataTable
              headers={this.headers}
              items={this.playerCasinoEvolutionVouchers.records}
              scopedSlots={this.scopedSlots}
              footerProps={{
                'items-per-page-options': [ITEMS_PER_PAGE],
              }}
              disableSort={true}
              loadingText='Loading... Please wait'
              loading={false}
              page={parseInt((this.playerCasinoEvolutionVouchers?.pagination?.page || '1'), 10)}
              itemsPerPage={ITEMS_PER_PAGE}
              serverItemsLength={parseInt((this.playerCasinoEvolutionVouchers?.pagination?.totalRecords || '0'), 10)}
              on={{
                'update:page': (page: number) => this.onPageUpdate(page),
              }}
            />
          </VCol>
        </VRow>
        <AwardCasinoEvolutionVoucherDialog
          playerUUID={this.playerUUID}
          open={this.awardVoucherDialogOpen}
          on={{
            open: (open: boolean) => { this.awardVoucherDialogOpen = open; },
            submit: (apiError: ApiError | null) => {
              this.apiError = apiError;
              this.loadPlayerCasinoEvolutionVouchers('1');
              this.awardVoucherDialogOpen = false;
            },
          }}
        />
        <ConfirmationDialog
          open={this.cancelVoucherDialogOpen}
          title='Cancel voucher confirmation'
          loading={this.cancelVoucherLoading}
          on={{
            submit: () => {
              this.onVoucherCancelConfirm(this.cancelVoucherID);
            },
            cancel: () => {
              this.cancelVoucherDialogOpen = false;
            },
          }}
        />
      </VContainer>
    );
  },
});

export default PlayerCasinoEvolutionVouchers;
