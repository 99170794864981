import { VNode} from 'vue';
import PaymentsBaseMessage from './PaymentsBaseMessage';
import { formatCurrency, currencyAlias } from '@/filters';

export default PaymentsBaseMessage.extend({
  render(): VNode {
    if (this.variant === 'card') {
      return (
        <div>
          <div class='row d-inline-flex font-weight-bold'>
            <span>{ this.message.receivedAt }</span>
            <span class={this.stateColor}>
              <span class='v-chip__content'>{ this.messageData.state }</span>
            </span>
          </div>
          <div class='mt-1'>
            <div class='col'>
              <a
                href={this.playerPageUri(this.messageData.player.uuid)}
                target='_blank'
                class='summary--info'
              >{ this.messageData.player.nickname }</a>
            </div>
          </div>
          <div>
            <div class='col text-align--left'>
              <span title={this.messageData.addressTo}>{ this.truncateText(this.messageData.addressTo, 35) }</span>
            </div>
          </div>
          <div>
            <div class='col text-align--left'>
              <a href={this.txUri} target='_blank' class='summary--info' title={this.messageData.hash}>
                { this.truncateText(this.messageData.hash, 35) }
              </a>
            </div>
          </div>
          <div>
            <div class='col text-align--right'>
              <span class='value text-align--right'
                title={
                  formatCurrency(
                    this.messageData.value, 8,
                    { name: currencyAlias(this.messageData.currency) },
                  )
                }>
                {
                  formatCurrency(this.messageData.value, 8,
                    { name: currencyAlias(this.messageData.currency) },
                  )
                }
              </span>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        <span class='time font-weight-bold'>{ this.message.receivedAt }</span>
        <span class={this.stateColor}>{ this.messageData.state }</span>
        <a href={this.playerPageUri(this.messageData.player.uuid)}
          target='_blank'
          class='player summary--info'
          title={this.messageData.player.nickname}
        >{ this.messageData.player.nickname }</a>
        <span class='address-to' title={this.messageData.addressTo}>{ this.messageData.addressTo }</span>
        <a
          href={this.txUri}
          class='hash'
          target='_blank'
          title={this.messageData.hash}
        >{ this.messageData.hash }</a>
        <span class='value text-align--right'
          title={
            formatCurrency(
              this.messageData.value, 8,
              { name: currencyAlias(this.messageData.currency) },
            )
          }>
          {
            formatCurrency(this.messageData.value, 8,
              { name: currencyAlias(this.messageData.currency) },
            )
          }
        </span>
      </div>
    );
  },
});
