
import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VContainer,
  VSnackbar,
  VSwitch,
  VTextField,
} from 'vuetify/lib';

import { env } from '@/env';
import { PaymentsWalletSettingsOutput } from '@/types/payments/generated/payments.gql';
import { hasRoles } from '@/utils/auth';
import './PlayerPaymentsWallet.less';

interface VueData {
  loading: boolean;
  newWalletEnabled: boolean;
  walletSettings: any;
  showConfirmation: boolean;
  apiError: any;
}

const PlayerPaymentsWallet = Vue.extend({
  props: {
    playerUUID: String,
    playerNickname: String,
  },
  data(): VueData {
    return {
      loading: true,
      newWalletEnabled: false,
      walletSettings: {},
      showConfirmation: false,
      apiError: null,
    };
  },
  created() {
    this.getPlayerWalletSettings();
  },
  methods: {
    async getPlayerWalletSettings() {
      const playerUUID: string = this.playerUUID;
      const response = await this.$apollo.query({
        query: gql`
          query ($playerUUID: String!) {
            paymentsWalletSettings(playerUUID: $playerUUID) {
              newWalletEnabled
              walletSettings
            }
          }
        `,
        variables: {
          playerUUID,
        },
      });

      const output: PaymentsWalletSettingsOutput = response.data.paymentsWalletSettings;
      this.newWalletEnabled = output.newWalletEnabled;
      this.walletSettings = output.walletSettings;
      this.loading = false;
    },
    async setPlayerWalletSettings() {
      try {
        this.apiError = null;
        this.loading = true;
        const playerUUID: string = this.playerUUID;
        const newWalletEnabled: boolean = this.newWalletEnabled;
        await this.$apollo.mutate({
          mutation: gql`
            mutation ($playerUUID: String!, $newWalletEnabled: Boolean!) {
              paymentsFlagWalletSettings(input: {playerUUID: $playerUUID, newWalletEnabled: $newWalletEnabled}) {
                newWalletEnabled
                walletSettings
              }
            }`,
          variables: {
            playerUUID,
            newWalletEnabled,
          },
        });
      } catch (e) {
        this.apiError = String(e);
      } finally {
        this.showConfirmation = true;
        this.loading = false;
      }
    },
  },
  render() {
    const dashboards = [{
      link: `${env.redashUri}/queries/801?fullscreen&p_player_uuid=${this.playerUUID}`,
      text: 'Fireblocks Deposits',
    }, {
      link: `${env.redashUri}/dashboards/28-customer-withdrawals?fullscreen&p_customer_uuid=${this.playerUUID}&p_mydate=d_this_month&p_state=["approved"%2C"rejected"%2C"pending"]`,
      text: 'Withdrawal Approval Status',
    }, {
      link: `${env.redashUri}/queries/693?fullscreen&p_nickname=${this.playerNickname}`,
      text: 'Cloudbet Transaction Status',
    }];

    return (
      <VContainer id='container'>
        {dashboards.map((x) => {
          return <span><a href={x.link} target='_blank'>{x.text}</a> | </span>;
        })}
        <br />
        <tb>
          <tr>
            <td class='td-header'><div>New wallet enabled:</div></td>
            <td id='td-switch'>
              <VSwitch
                inset
                disabled={this.loading || !hasRoles(['cs:operator', 'payments:operator'])}
                loading={this.loading}
                vModel={this.newWalletEnabled}
                onChange={this.setPlayerWalletSettings}
              />
            </td>
          </tr>
          <tr>
            <td class='td-header'><div>Wallet settings:</div></td>
            <td>
              <VTextField
                hide-details
                outlined
                disabled={this.loading || !hasRoles(['payments:operator'])}
                value={JSON.stringify(this.walletSettings)}
              />
            </td>
          </tr>
        </tb>

        <VSnackbar
          color={this.apiError ? 'red' : 'green'}
          vModel={this.showConfirmation}
          timeout='5000'
          top
        >
          <div class='text-center'>
            {this.apiError ? this.apiError : 'Updated'}
          </div>
        </VSnackbar>
      </VContainer>
    );
  },
});

export default PlayerPaymentsWallet;
