import './Profile.less';

import ViewByRole from '@/components/ViewByRole/ViewByRole';
import { env } from '@/env';
import { getJwt } from '@/utils/getJwt';
import Vue from 'vue';
import {
  VAvatar,
  VBtn,
  VCard,
  VChip,
  VContainer,
  VIcon,
  VImg,
  VListItemSubtitle,
  VRadio,
  VRadioGroup,
  VSnackbar,
} from 'vuetify/lib';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      radioGroup: 0,
      hasCopied: false,
      isSnackbarOpen: false,
    };
  },
  computed: {
    ...mapGetters({
      avatar: 'auth/avatar',
      name: 'auth/name',
      email: 'auth/email',
      roles: 'auth/roles',
    }),
    rolesString() {
      return this.roles.join(', ');
    },
  },

  methods: {
    ...mapActions({
      signout: 'auth/signout',
    }),
  },

  render() {
    const copy = () => {
      const element = document.getElementById('jwt-text') as HTMLElement;
      navigator.clipboard.writeText(element.innerHTML);
      this.$data.hasCopied = true;
      this.$data.isSnackbarOpen = true;
      setTimeout(() => {
        this.$data.hasCopied = false;
      }, 3000);
    };
    const jwt = getJwt();
    const jwtClipboardFormats = [
      `{"Authorization" :"Bearer ${jwt}"}`,
      jwt,
      `curl -H 'Accept: application/json' -H "Authorization: Bearer ${jwt}" https://${env.ledger.host}/admin/account/player.189179/currency/list`,
    ];
    return (
      <VContainer fluid class='profile-container-spacing'>
        <div class='profile-image-name-container'>
          <VAvatar class='ml-4 mr-3' color='grey' size='128' item>
            <VImg src={this.avatar}></VImg>
          </VAvatar>
          <div class='flex-column'>
            <div class='text-h5'>{this.name}</div>
            <VListItemSubtitle>{this.email}</VListItemSubtitle>
          </div>
          <VBtn outlined color='primary' class='ml-4' to='/signout'>
            Sign out
          </VBtn>
        </div>
        <VCard class='profile-roles-card'>
          <div class='your-roles-chips'>
            <div class='profile-roles-title'>Your roles</div>
            {this.roles.map((role: string) => (
              <VChip color='primary' class='profile-roles-checkbox'>
                {role}
              </VChip>
            ))}
          </div>
          <ViewByRole />
        </VCard>

        <VRadioGroup vModel={this.$data.radioGroup}>
          <div class='jwt-radio-container ml-4'>
            <VRadio class='jwt-radio-button mr-4' label='GraphQL Http Header' />
            <VRadio class='jwt-radio-button mr-4' label='Raw JWT token' />
            <VRadio class='jwt-radio-button' label='Curl' />
          </div>
        </VRadioGroup>
        <div class='jwt-container'>
          <div class='flex'></div>
          <pre class='jwt-text' ref='jwt' id='jwt-text'>
            {jwtClipboardFormats[this.$data.radioGroup]}
          </pre>
          <VBtn onClick={copy} icon fab class='jwt-copy jwt-copy-button'>
            <VIcon elevation='2' rounded raised>
              {this.$data.hasCopied ? 'check' : 'launch'}
            </VIcon>
          </VBtn>
          <VSnackbar
            color='green'
            timeout={3000}
            class='jwt-snackbar'
            vModel={this.$data.isSnackbarOpen}
          >
            <div class='jwt-snackbar-content'>
              <div class='align-center'>
                Copied to clipboard<VIcon class='ml-4'>check</VIcon>
              </div>
              <VBtn onClick={() => (this.$data.isSnackbarOpen = false)} small>
                close
              </VBtn>
            </div>
          </VSnackbar>
        </div>
      </VContainer>
    );
  },
});
