import CasinoRoundMessage from './messages/CasinoRoundMessage';
import SportsBetMessage from './messages/SportsBetMessage';
import TransactionMessage from './messages/TransactionMessage';
import StreamForm from '@/components/Dashboard/forms/StreamForm.vue';
import { querify } from '@/streams';
import gql from 'graphql-tag';
import { mapActions } from 'vuex';
import { formatDateWithMilliseconds } from '@/utils/date'
const STREAM_MESSAGES_LIMIT = 100;

export default {
    components: {
        StreamForm,
        SportsBetMessage,
        CasinoRoundMessage,
        TransactionMessage,
    },
    data() {
        return {
            subscription: null,
            messages: [],
            messageCounter: 0,
        };
    },
    props: {
        stream: Object,
        disableToolbar: Boolean,
    },
    mounted() {
        this.subscribe();
    },
    destroyed() {
        this.unsubscribe();
    },
    methods: {
        ...mapActions({
            removeStream: 'streams/remove',
        }),
        remove() {
            this.removeStream(this.$props.stream);
        },
        isSameFilters(oldFilters, newFilters) {
            return (
                JSON.stringify(oldFilters.filters) ===
                JSON.stringify(newFilters.filters) &&
                JSON.stringify(oldFilters.operators) ===
                JSON.stringify(newFilters.operators)
            );
        },
        unsubscribe() {
            if (this.subscription) {
                this.subscription.unsubscribe();
            }
        },
        subscribe(clearMessages = false) {
            if (clearMessages) {
                this.messages = [];
            }
            this.unsubscribe();
            this.subscription = this.$apollo
                .subscribe({
                    query: gql(querify(this.$props.stream)),
                    variables: {
                        filters: JSON.stringify(this.$props.stream.filters)
                    },
                })
                .subscribe({
                    next: ({
                        data
                    }) => {
                        this.messages.unshift({
                            index: this.messageCounter,
                            receivedAt: formatDateWithMilliseconds(new Date(), true),
                            data: data[this.$props.stream.sourceKey],
                        });
                        this.messageCounter += 1;
                        if (this.messages.length > STREAM_MESSAGES_LIMIT) {
                            this.messages.pop();
                        }
                    },
                    error: (error) => {
                        // reconnect on error
                        // without the timeout ws can freeze
                        setTimeout(this.subscribe, 1000);
                    },
                });
        },
    },
    watch: {
        stream: {
            handler(newStream, oldStream) {
                if (
                    oldStream.sourceKey !== newStream.sourceKey ||
                    !this.isSameFilters(oldStream.filters, newStream.filters)
                ) {
                    this.subscribe(true);
                }
            },
            deep: true,
        },
    },
};
