import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { gql } from 'apollo-boost';
import { CustomerBetSetting } from '@/types/sports';
import _ from 'lodash';

interface CustomerBetSettings {
  defaultSetting: CustomerBetSetting;
  perSportSettings: [CustomerBetSetting];
  updating: boolean;
  jwtRole: string;
}

const getters: GetterTree<CustomerBetSettings, any> = {
  defaultSetting(state: CustomerBetSettings): CustomerBetSetting {
    return state.defaultSetting;
  },
  perSportSettings(state: CustomerBetSettings): [CustomerBetSetting] {
    return state.perSportSettings;
  },
  updating(state: CustomerBetSettings): boolean {
    return state.updating;
  },
  jwtRole(state: CustomerBetSettings): string {
    return state.jwtRole;
  },
};
const mutations: MutationTree<CustomerBetSettings> = {
  UPDATE_CUSTOMER_BET_SETTINGS(state, betDetailSetting) {
    if (!(betDetailSetting)) {
      return;
    }

    const sportsSettings = betDetailSetting.sportsSettings.map((element: any) => {
      return { ...element.setting, ...{ sportKey: element.sportKey } };
    });

    sportsSettings.sort(
      (item1: CustomerBetSetting, item2: CustomerBetSetting) => {
        item1.sportKey.localeCompare(item2.sportKey);
      },
    );
    state.perSportSettings = sportsSettings || [];
    state.defaultSetting = betDetailSetting.defaultSetting;
  },
  UPDATE_CUSTOMER_JWT_ROLE(state, jwtRole) {
    if (!jwtRole) {
      return;
    }

    state.jwtRole = jwtRole;
  },
  UPDATING_SETTINGS(state, updating) {
    state.updating = updating;
  },
};


const actions: ActionTree<CustomerBetSettings, any> = {
  async fetchCustomerBetSettings(
    { commit, rootState },
    { playerUUID, subCustomerReference },
  ) {
    commit('UPDATING_SETTINGS', true);
    try {
      const response = await rootState.apolloClient().query({
        query: gql`
            query GetCustomerSettings($uuid: String!, $subCustomerReference: String) {
              getCustomerSettings(uuid: $uuid, subCustomerReference: $subCustomerReference) {
                betDetailSetting {
                  sportsSettings {
                    sportKey
                    setting {
                      defaultAccount
                      placeFactor
                      stakeFactor
                      updatedBy
                      updatedAt
                    }
                  }
                  defaultSetting {
                    defaultAccount
                    placeFactor
                    stakeFactor
                    updatedBy
                    updatedAt
                  }
                }
                jwtRole
              }
            }
            `,
        variables: { uuid: playerUUID, subCustomerReference },
      });

      const betDetailSetting = _.get(response, 'data.getCustomerSettings.betDetailSetting');
      commit('UPDATE_CUSTOMER_BET_SETTINGS', betDetailSetting);
      commit('UPDATE_CUSTOMER_JWT_ROLE', _.get(response, 'data.getCustomerSettings.jwtRole'));
      commit('UPDATING_SETTINGS', false);
    } catch (err) {
      throw err;
    }
  },

  async updateCustomerBetSetting(
    { commit, rootState },
    { playerUUID, subCustomerReference, accountType, placeFactor, stakeFactor, sportKey },
  ) {
    try {
      commit('UPDATING_SETTINGS', true);
      const response = await rootState.apolloClient().mutate({
        mutation: gql`
            mutation UpdateCustomerSetting($input: CustomerSettingInput) {
              updateCustomerSetting(input: $input) {
                betDetailSetting {
                  sportsSettings {
                    sportKey
                    setting {
                      defaultAccount
                      placeFactor
                      stakeFactor
                      updatedBy
                      updatedAt
                    }
                  }
                  defaultSetting {
                    defaultAccount
                    placeFactor
                    stakeFactor
                    updatedBy
                    updatedAt
                  }
                }
              }
            }
            `,
        variables: {
          input: {
            customerSetting: {
              betSetting: {
                placeFactor,
                stakeFactor,
                defaultAccount: accountType,
                sportKey: (sportKey === '') ? null : sportKey,
              },
            },
            uuid: playerUUID,
            subCustomerReference,
          },
        },
        fetchPolicy: 'no-cache',
      });


      const betDetailSetting = _.get(response, 'data.updateCustomerSetting.betDetailSetting');
      commit('UPDATE_CUSTOMER_BET_SETTINGS', betDetailSetting);
      commit('UPDATING_SETTINGS', false);
    } catch (err) {
      commit('UPDATING_SETTINGS', false);
      throw err;
    }
  },

  async upgradeCustomerToPartner({ commit, rootState }, { brand, playerUUID }) {
    try {
      await rootState.apolloClient().mutate({
        mutation: gql`
            mutation UpgradeCustomerToPartner($brand: String!, $uuid: String!) {
              upgradeCustomerToPartner(brand: $brand, uuid: $uuid)
            }
            `,
        variables: {
          brand,
          uuid: playerUUID,
        },
        fetchPolicy: 'no-cache',
      });

      commit('UPDATE_CUSTOMER_JWT_ROLE', 'partner');
    } catch (err) {
      throw err;
    }
  },
};


const CustomerBetSettingsStore = {
  namespaced: true,
  state: {
    defaultSetting: {} as CustomerBetSetting,
    updating: true,
    perSportSettings: [] as CustomerBetSetting[],
    jwtRole: '',
  } as CustomerBetSettings,
  getters,
  actions,
  mutations,
};

export default CustomerBetSettingsStore;
