import gql from 'graphql-tag';
import { findIndex, forEach, sortBy } from 'lodash';
import Vue from 'vue';
import { formatDateWithMilliseconds } from '@/utils/date';
import { EventTypeFilter } from '@/static';

import currencies from '@/config/currencies';

interface Event {
  eventId: number;
  eventName: string;
  sportKey: string;
  competitionKey: string;
  sportName: string;
  competitionName: string;
  exposure: number;
  turnover: number;
  betsCount: number;
  playersCount: number;
  isOutright: boolean;
  startsAt: string;
  opinionDiscrepancy: number;
  eventType: string;
  status: string;
  details: EventDetails;
}

interface EventDetails {
  pnl: number;
  worstCase: EventExposureByScore;
  bestCase: EventExposureByScore;
}

interface EventExposureByScore {
  homeScore: number;
  awayScore: number;
  outcome: string;
  value: number;
}

const EVENTS_LIMIT = 100;

export default Vue.extend({
  props: {
    filterable: Boolean,
    sportKey: String,
    competitionKey: String,
    eventType: String as () => keyof typeof EventTypeFilter,
  },
  data: () => ({
    dataLoaded: false,
    clearOnNewData: false,
    events: [] as Event[],
    subscription: null as any | null,
    sportKeyFilter: null as string | null,
    competitionKeyFilter: null as string | null,
    keysTranslations: {} as Record<string, string>,
    missingTranslations: [] as string[],
  }),
  computed: {
    headers() {
      return [
        {
          text: 'Status',
          align: 'start',
          sortable: false,
          value: 'status',
        },
        {
          text: 'Sport',
          align: 'start',
          sortable: false,
          value: 'sportName',
        },
        {
          text: 'Competition',
          align: 'start',
          sortable: false,
          value: 'competitionName',
        },
        { text: 'Event', align: 'start', sortable: false, value: 'eventName' },
        { text: '#players', align: 'start', sortable: false, value: 'playersCountWithUntracked' },
        { text: '#bets', align: 'start', sortable: false, value: 'betsCountWithUntracked' },
        { text: 'Turnover', align: 'end', sortable: false, value: 'turnoverWithUntracked' },
        {
          text: 'Total exposure',
          sortable: false,
          align: 'end',
          value: 'exposure',
        },
        {
          text: 'Worst case',
          sortable: false,
          align: 'end',
          value: 'details.worstCase',
        },
        {
          text: 'Best case',
          sortable: false,
          align: 'end',
          value: 'details.bestCase',
        },
        {
          text: 'P&L',
          sortable: false,
          align: 'end',
          value: 'details.pnl',
        },
      ];
    },

    precision() {
      return 0;
    },
  },
  methods: {
    reload() {
      this.dataLoaded = false;
      this.clearOnNewData = true;
      this.subscribe();
    },
    setSportKeyFilter(sportKey: string) {
      if (this.sportKeyFilter === sportKey) { return; }

      this.sportKeyFilter = sportKey;
      this.fitlersChanged();
    },
    setCompetitionKeyFilter(competitionKey: string) {
      if (this.competitionKeyFilter === competitionKey) { return; }

      this.competitionKeyFilter = competitionKey;
      this.fitlersChanged();
    },
    fitlersChanged() {
      this.reload();

      this.$emit('filtersChanged', {
        sportKey: this.sportKeyFilter,
        competitionKey: this.competitionKeyFilter,
        eventType: this.eventType,
      });
    },
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },

    processNewEvent(event: Event) {
      const oldIdx = findIndex(
        this.events,
        (e) => event.eventId === e.eventId,
      );

      if (oldIdx >= 0) {
        this.events[oldIdx] = event;
      } else {
        this.events.push(event);
      }

      this.addKeyTranslation(event.details.bestCase?.outcome);
      this.addKeyTranslation(event.details.worstCase?.outcome);
    },
    scoresText(event: Event, exposure: EventExposureByScore): string {
      if (exposure.homeScore == null) {
        if (exposure.outcome?.startsWith('outcome=')) {
          return decodeURI(exposure.outcome.replace('outcome=', ''));
        }

        return this.keysTranslations[exposure.outcome] || exposure.outcome;
      }

      return `${exposure.homeScore}-${exposure.awayScore}`;
    },
    addKeyTranslation(outcome: string) {
      if (!outcome || outcome.startsWith('outcome=') || this.keysTranslations[outcome]) {
        return;
      }

      this.missingTranslations.unshift(outcome);
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription ($sportKey: String, $competitionKey: String, $isOutright: String) {
              eventUpdated (sportKey: $sportKey, competitionKey: $competitionKey, isOutright: $isOutright) {
                eventId
                eventName
                sportKey
                competitionKey
                sportName
                competitionName
                exposure
                turnover
                betsCount
                playersCount
                isOutright
                startsAt
                opinionDiscrepancy
                turnoverWithUntracked
                betsCountWithUntracked
                playersCountWithUntracked
                eventType
                status
                details {
                  pnl
                  worstCase {
                    homeScore
                    awayScore
                    outcome
                    value
                  }
                  bestCase {
                    homeScore
                    awayScore
                    outcome
                    value
                  }
                }
              }
            }
          `,
          variables: {
            sportKey: this.sportKeyFilter,
            competitionKey: this.competitionKeyFilter,
            isOutright: EventTypeFilter[this.eventType],
          },
        })
        .subscribe({
          next: ({ data }) => {
            if (this.clearOnNewData) {
              this.events = [];
              this.clearOnNewData = false;
            }

            forEach(data.eventUpdated, this.processNewEvent);

            this.events = sortBy(this.events, (e) => -e.exposure);

            if (this.events.length > EVENTS_LIMIT) {
              this.events.pop();
            }

            this.dataLoaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
    cleanup() {
      const sixHoursAgo = new Date();
      sixHoursAgo.setHours(sixHoursAgo.getHours() - 6);

      const threeMonthsAgo = new Date();
      threeMonthsAgo.setMonth(threeMonthsAgo.getMonth() - 3);

      const toRemoveIdx = findIndex(
        this.events,
        (e) => (!e.isOutright && new Date(e.startsAt) < sixHoursAgo) ||
               (e.isOutright && new Date(e.startsAt) < threeMonthsAgo),
      );

      if (toRemoveIdx >= 0) {
        this.events.splice(toRemoveIdx, 1);
      }

      setTimeout(this.cleanup, 1000);
    },
    itemClass(item: any): string {
      if (item.status === 'RESULTED') {
        return 'grayout';
      }

      return '';
    },
  },
  mounted() {
    this.setSportKeyFilter(this.sportKey);
    this.setCompetitionKeyFilter(this.competitionKey);
    this.subscribe();
    this.cleanup();
  },
  destroyed() {
    this.unsubscribe();
  },
  watch: {
    sportKey(newValue, oldValue) {
      if (newValue === oldValue) { return; }

      this.setSportKeyFilter(this.sportKey);
    },
    competitionKey(newValue, oldValue) {
      if (newValue === oldValue) { return; }

      this.setCompetitionKeyFilter(this.competitionKey);
    },
    eventType(newValue, oldValue) {
      if (newValue === oldValue) { return; }

      this.fitlersChanged();
    },
  },
  apollo: {
    keysTranslations: {
      query: gql`query ($locale: String!, $keys: [String]!) {
        getKeysTransations(locale: $locale, keys: $keys)
      }`,

      variables(): { locale: string, keys: string[] } {
        return {
          locale: 'en',
          keys: this.missingTranslations,
        };
      },

      update(data) {
        return Object.fromEntries(data.getKeysTransations);
      },

      result() {
        this.missingTranslations = [];
      },

      skip(): boolean {
        return this.missingTranslations.length === 0 || !this.dataLoaded;
      },
    },
  },
});
