import Vue from 'vue';
import { VContainer, VAlert } from 'vuetify/lib';

export default Vue.extend({
  render() {
    return (
      <VContainer>
        <VAlert type='warning'>You don't have access to the page.</VAlert>
      </VContainer>
    );
  },
});
