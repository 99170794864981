import './style.less';
import Vue from 'vue';
import moment from 'moment';
import {
  VDataTable,
  VContainer,
  VCard,
  VTooltip,
  VIcon,
  VTextField,
  VBtn,
  VDialog,
  VCardTitle,
  VCardSubtitle,
  VCardActions,
  VSpacer,
  VCardText,
} from 'vuetify/lib';
import { RouterLink } from '@/router';
import { PendingEvent } from '@/types/sports';
import { mapActions, mapGetters } from 'vuex';
import { getSportsName, Locale } from '@cloudbet/market-helper/sports-core';

export default Vue.extend({
  name: 'pendingEventsWidget',
  props: {
    selectEvent: Function,
    eventType: String,
    isTrading: Boolean,
    // sportId: Number,
    // locale: String,
  },
  data() {
    return {
      isLoading: true,
      open: false,
      eventId: '',
      eventIdToDelete: '',
      polling: null as any,
      search: '',
      headers: [
        { text: 'Sport', value: 'sportName', width: 100, sortable: false },
        { text: 'Event', value: 'eventName', sortable: false },
        { text: 'Start Time', value: 'cutoffTime', sortable: false },
        { text: 'Pending Selections', value: 'numberOfPendingSelections', sortable: false },
        { text: 'Comment', value: 'comment', sortable: false },
        { text: '', value: 'remove', sortable: false },
      ],
    };
  },
  watch: {
    async eventType() {
      if (this.eventType === 'outright') {
        this.headers[2].text = 'Finish Time';
      } else {
        this.headers[2].text = 'Start Time';
      }
      await this.getPendingEvents({ eventType: this.eventType, isTrading: this.isTrading });
    },
    async isTrading() {
      await this.getPendingEvents({ eventType: this.eventType, isTrading: this.isTrading });
    },
  },
  async mounted() {
    await this.getPendingEvents({ eventType: this.eventType, isTrading: this.isTrading });
    this.pollData();
  },
  async beforeDestroy() {
    clearInterval(this.polling);
  },
  methods: {
    ...mapActions({
      getPendingEventsAction: 'pendingEvents/getPendingEvents',
      removePendingEvent: 'pendingEvents/removePendingEvent',
    }),
    pollData() {
      this.polling = setInterval(() => {
        this.getPendingEvents({ eventType: this.eventType, isTrading: this.isTrading });
      }, 5000);
    },
    eventName(categoryKey: string, competitionName: string, name: string, eventID: string) {
      return (
        <div>
          <VCardSubtitle>
            {categoryKey} - {competitionName}
          </VCardSubtitle>
          <VCardText>
            <RouterLink
              class='text-decoration-none'
              to={{ name: 'event', params: { eventId: eventID } }}>
              {name}
            </RouterLink>
          </VCardText>
        </div>
      );
    },
    sportName(sportKey: string) {
      return (
        <VCardText>{getSportsName(sportKey, Locale.en)}</VCardText>
      );
    },
    limitCommentLength(comment: string) {
      const limitedLength = 20;
      if (comment.length > limitedLength) {
        return comment.substring(0, limitedLength) + '...';
      }
      return comment;
    },
    async getPendingEvents({ eventType, isTrading }: { eventType: string, isTrading: boolean }) {
      this.isLoading = true;
      try {
        await this.getPendingEventsAction({ eventType, isTrading });
        this.isLoading = false;
      } catch (err) {
        this.isLoading = false;
      }
    },
    async deletePendingEvent(eventId: string) {
      await this.removePendingEvent({ eventId });
      await this.getPendingEvents({ eventType: this.eventType, isTrading: this.isTrading });
    },
    displayTime(timeString: string) {
      return (<span>{timeString} ({moment(timeString).fromNow()})</span>);
    },
  },
  computed: {
    ...mapGetters({
      events: 'pendingEvents/events',
    }),
  },
  render() {
    return (
      <VContainer fluid>
        <VDialog vModel={this.open} persistent max-width='600px'>
          <VCard>
            <VCardTitle>
              Irreversible action!
            </VCardTitle>
            <VCardSubtitle>
              Are you sure to remove this event from the pending events list?
            </VCardSubtitle>
            <VCardActions>
              <VSpacer />
              <VBtn onClick={() => {
                this.open = false;
                this.eventIdToDelete = '';
              }}>
                CANCEL
              </VBtn>
              <VBtn color='red' onClick={() => {
                this.deletePendingEvent(this.eventIdToDelete);
                this.open = false;
              }}>
                DELETE
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
        <VCard>
          <VCardTitle>
            Last 50 Items
            <VSpacer></VSpacer>
            <VTextField
              v-model={this.search}
              label='Search'
              single-line
              hide-details
            ></VTextField>
          </VCardTitle>
          <VDataTable
            items-per-page={-1}
            search={this.search}
            headers={this.headers}
            items={this.events}
            itemClass={(item: PendingEvent) => {
              return item.eventId === this.eventId ? 'selected' : '';
            }}
            on={{
              'click:row': (item: PendingEvent, row: any) => {
                this.eventId = item.eventId;
                this.selectEvent(item);
              },
            }}
            scopedSlots={{
              'item.cutoffTime': ({ item }: { item: PendingEvent }) => {
                if (this.eventType === 'outright') {
                  if (item.finishTime) {
                    return this.displayTime(item.finishTime);
                  }
                }

                return this.displayTime(item.cutoffTime);
              },
              'item.eventName': ({ item }: { item: PendingEvent }) => {
                return this.eventName(item.categoryKey, item.competitionName, item.name, item.eventId);
              },
              'item.sportName': ({ item }: { item: PendingEvent }) => {
                return this.sportName(item.sportKey);
              },
              'item.comment': ({ item }: { item: PendingEvent }) => {
                return <VTooltip
                  top
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => (
                      <span on={on}>{this.limitCommentLength(item.comment)}</span>
                    ),
                  }}
                >
                  <span>{item.comment}</span>
                </VTooltip>;
              },
              'item.remove': ({ item }: { item: PendingEvent }) => {
                return <VBtn
                  color='red'
                  onClick={() => {
                    this.open = true;
                    this.eventIdToDelete = item.eventId;
                  }}
                  icon
                >
                  <VIcon>cancel</VIcon>
                </VBtn>;
              },
            }}
            loading-text='Loading... Please wait'
            loading={this.isLoading}
          />
        </VCard >
      </VContainer>
    );
  },
});
