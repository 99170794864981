import { Observable } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ServerParseError } from '@apollo/client/link/http';
import firebase from 'firebase';
import store from '@/store';
import { googleAuthConvertor } from '@/firebase/google-auth-convertor';

// onError does not handle async function so we have to convert promisses into Observable
// Discussion: https://github.com/apollographql/apollo-link/issues/646
// Solution based on https://github.com/apollographql/apollo-link/pull/825

const promiseToObservable = <T>(promise: Promise<T>): Observable<T> => {
  return new Observable((subscriber) => {
    promise.then(
      (value) => {
        if (subscriber.closed) {
          return;
        }
        subscriber.next(value);
        subscriber.complete();
      },
      (err) => {
        subscriber.error(err);
      },
    );
  });
};

const getIdTokenResult = async (): Promise<firebase.auth.IdTokenResult | undefined> => {
  return new Promise(async (resolve, reject) => {
    const unsubscribe = firebase.auth().onIdTokenChanged(async (user) => {
      unsubscribe();
      if (user) {
        try {
          const tokenResult = await user.getIdTokenResult(true);
          resolve(tokenResult);
        } catch (e) {
          // tslint:disable-next-line no-console
          throw e;
        }
      } else {
        resolve(undefined);
      }
    }, reject);
  });
};

export const refreshToken = async () => {
  const tokenResult = await getIdTokenResult();
  if (tokenResult) {
    const formattedUser = googleAuthConvertor(tokenResult);
    store.commit('auth/UPDATE_USER', formattedUser);
  }
  return tokenResult;
};

const tokenLink = onError(({ graphQLErrors, operation, forward, networkError }) => {
  let retry = false;

  if ((networkError as ServerParseError)?.statusCode === 401) {
    retry = true;
  } else if (graphQLErrors) {
    for (const err of graphQLErrors) {
      if (err.extensions?.code === 'UNAUTHENTICATED') {
        retry = true;

        break;
      }
    }
  }

  if (retry) {
    const promise = refreshToken();

    return promiseToObservable(promise).flatMap(() => {
      // retry the request, returning the new observable
      return forward(operation);
    });
  }
});

export default tokenLink;
