export enum EventTypeFilter {
  all = '',
  event = '0',
  outright = '1',
}

export const eventTypeOptions = [
  {
    text: 'all',
    value: 'all',
  },
  {
    text: 'event',
    value: 'event',
  },
  {
    text: 'outright',
    value: 'outright',
  },
];
