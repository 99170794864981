import Vue from 'vue';
import { VDataTable } from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions, mapGetters } = createNamespacedHelpers('sports/competitions');

export default Vue.extend({
  name: 'competitionStreamsWidget',
  props: {
    competitionId: String,
  },

  mounted() {
    this.loadCurrentCompetition(this.competitionId);
  },

  data() {
    return {
      headers: [
        { text: 'Provider', value: 'provider', width: 150 },
        { text: 'Language', value: 'language', width: 150 },
        { text: 'Url', value: 'url' },
      ],
    };
  },

  methods: {
    ...mapActions(['loadCurrentCompetition']),
  },

  computed: {
    ...mapState(['currentCompetition']),
    ...mapGetters(['isLoading']),
  },

  render() {
    return (
      <VDataTable
        class='elevation-1'
        headers={this.headers}
        items={this.currentCompetition?.streams || []}
        loading-text='Loading... Please wait'
        loading={this.isLoading}
        sortBy={['provider', 'language']}>
      </VDataTable>
    );
  },
});

