import store from '@/store';
import { FinancialLimitType } from '@/store/modules/auth';

/**
 * hasRoles check a user if he/she has the role in given roles, empty array will auth every logged in user
 * @param  {string[]} roles string array of roles
 * @return {boolean}  if user has role that included in the given roles
 */
export const hasRoles = (requiredRoles: string[]): boolean => {
  const isAuthed = store.getters['auth/isAuthenticated'];
  const userRoles = store.getters['auth/roles'];
  const pseudoRoles = store.getters['viewByRole/pseudoRoles'];
  const isViewByOtherRoles = store.getters['viewByRole/isViewByRoleActive'];

  if (!isAuthed) {
    return false;
  }
  // Allow passing empty array to auth every roles
  if (!requiredRoles || !requiredRoles.length) {
    return true;
  }

  for (const requiredRole of requiredRoles) {
    const hasRole = (isViewByOtherRoles ? pseudoRoles : userRoles).includes(
      requiredRole,
    );
    if (hasRole) {
      return true;
    }
  }

  return false;
};

/**
 * hasLimits checks if the user has the limit specified for a given limit type
 * @param {FinancialLimitTypes[]} requiredLimits - array of required financial limit types
 * @returns {boolean} - true if user has the limit for the given limit type
 */
export const hasLimits = (requiredLimits?: FinancialLimitType[]): boolean => {
  const isAuthed = store.getters['auth/isAuthenticated'];
  const userLimits = store.getters['auth/limits'];

  if (!isAuthed) {
    return false;
  }

  // Allow passing empty array
  if (!requiredLimits || !requiredLimits.length) {
    return true;
  }

  // If no limit is set, return false
  if (!userLimits) {
    return false;
  }

  for (const requiredLimit of requiredLimits) {
    switch (requiredLimit) {
      case 'manual_credit_limit':
        const manualCreditLimit = userLimits?.cumulative?.['24h'] || 0;
        return manualCreditLimit > 0;
      case 'casino_rewards_limit':
        const casinoRewardsLimit = userLimits?.['casino-rewards']?.['24h'] || 0;
        return casinoRewardsLimit > 0;
      default:
        continue;
    }
  }

  return false;
};
