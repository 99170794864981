import Vue from 'vue';
import { VRow, VCol, VMenu, VTextField, VDatePicker } from 'vuetify/lib';

export default Vue.extend({
  props: {
    value: {
      type: Array as () => string[],
      default: () => [undefined, undefined],
    },

    label1: String,
    label2: String,
  },

  data() {
    return {
      dateMenu1: false,
      dateMenu2: false,
    };
  },


  render() {
    return (
      <VRow>
        <VCol cols={6}>
          <VMenu
            vModel={this.dateMenu1}
            closeOnContentClick={false}
            nudgeRight={40}
            transition='scale-transition'
            offsetY
            minWidth='auto'
            scopedSlots={{
              activator: ({ on }: any) => {
                return <VTextField
                  vModel={this.value[0]}
                  label={this.label1}
                  readonly
                  dense
                  clearable
                  on={on}
                  onInput={() => this.$emit('input', ['', this.value[1]])}
                ></VTextField>;
              },
            }}>
            <VDatePicker
              noTitle
              scrollable
              vModel={this.value[0]}
              allowedDates={(val: string) => {
                if (this.value[1]) {
                  const date1 = new Date(this.value[1]);
                  const date = new Date(val);
                  return date <= date1;
                }
                return true;
              }}
              onInput={(val: string) => {
                this.dateMenu1 = false;
                this.$emit('input', [val, this.value[1]]);
              }} />
          </VMenu>
        </VCol>
        <VCol cols={6}>
          <VMenu
            vModel={this.dateMenu2}
            closeOnContentClick={false}
            nudgeRight={40}
            transition='scale-transition'
            offsetY
            minWidth='auto'
            scopedSlots={{
              activator: ({ on }: any) => {
                return <VTextField
                  vModel={this.value[1]}
                  label={this.label2}
                  readonly
                  dense
                  clearable
                  on={on}
                  onInput={() => this.$emit('input', [this.value[0], ''])}
                ></VTextField>;
              },
            }}>
            <VDatePicker
              noTitle
              scrollable
              vModel={this.value[1]}
              allowedDates={(val: string) => {
                if (this.value[0]) {
                  const date0 = new Date(this.value[0]);
                  const date = new Date(val);
                  return date >= date0;
                }
                return true;
              }}
              onInput={(val: string) => {
                this.dateMenu2 = false;
                this.$emit('input', [this.value[0], val]);
              }} />
          </VMenu>
        </VCol>
      </VRow >
    );
  },
});
