import { env } from '@/env';

export function playerPageUri(playerUUID: string) {
  return `${env.flightdeck.uri}/players/${playerUUID}/by_uuid`;
}

export function troPlayerPageUri(playerUUID: string) {
  return `${env.tro.uri}/players/${playerUUID}`;
}

export function eventPageUri(eventId: string) {
  return `${env.flightdeck.uri}/sportbook/events/${eventId}`;
}
