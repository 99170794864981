// import './EntityMediaWidget.less';
import { gql } from 'apollo-boost';
import Vue from 'vue';
import { DocumentNode } from 'graphql';
import { VBtn, VDataTable, VIcon, VSpacer, VToolbar, VToolbarTitle } from 'vuetify/lib';
import EditDialog from './EditDialog';
import { Subscription } from '@/types/sports';


export default Vue.extend({
  name: 'socialFeedWidget',
  props: {
    entityType: String,
    entityKey: String,
  },
  data: () => ({
    mode: 'create' as string,
    subscriptions: [],
    subscription: {} as Subscription,
    open: false as boolean,
    headers: [
      {
        text: 'Source',
        value: 'source',
      },
      {
        text: 'Twitter Query',
        value: 'twitterQuery',
      },
      {
        text: 'Actions',
        value: 'actions',
        sortable: false,
      },
    ],
  }),
  computed: {
    editDialog() {
      if (this.open) {
        return (
          <EditDialog
            mode={this.mode}
            entityType={this.entityType}
            entityKey={this.entityKey}
            open={this.open}
            closeDialog={() => {
              this.open = false;
            }}
            afterSave={() => {
              this.$apollo.queries.subscriptions.refetch();
              this.open = false;
            }}
          />
        );
      }
    },
  },
  methods: {
    async unlinkSubscription(item: Subscription): Promise<void> {
      const res = await this.$apollo
        .mutate({
          mutation: gql`mutation ($entityType: String!, $entityKey: String!, $id: Int!) {
              deleteSocialSubscription(entityType: $entityType, entityKey: $entityKey, id: $id) {
                success
              }
            }`,
          variables: {
            entityType: this.entityType,
            entityKey: this.entityKey,
            id: item.id,
          },
        });
      if (res.data.deleteSocialSubscription.success) {
        this.$apollo.queries.subscriptions.refetch();
      }
    },
    actions({ item }: { item: Subscription }): JSX.Element {
      return (
        <div>
          <VIcon small onClick={() => this.unlinkSubscription(item)}>
            delete
          </VIcon>
        </div>
      );
    },
    toolbar() {
      return (
        <VToolbar flat>
          <VToolbarTitle>Social Subscriptions</VToolbarTitle>
          <VSpacer></VSpacer>
          <VBtn
            onClick={() => {
              this.mode = 'create';
              this.open = true;
            }}
            color='primary'>
            New Subscription
          </VBtn>
        </VToolbar>
      );
    },
  },
  apollo: {
    subscriptions: {
      query():
        DocumentNode {
        return gql`
          query($entityType: String!, $entityKey: String!) {
              socialSubscriptions(entityType: $entityType, entityKey: $entityKey) {
                subscriptions {
                  id
                  source
                  twitterQuery
                }
              }
          }
        `;
      },
      update(data) {
        return data.socialSubscriptions.subscriptions;
      },
      variables() {
        return { entityType: this.entityType, entityKey: this.entityKey };
      },
    },
  },
  render() {
    return (
      <div>
        {this.editDialog}
        <VDataTable
          headers={this.headers}
          items={this.subscriptions}
          sortBy={'type'}
          scopedSlots={{
            'item.actions': this.actions,
            'top': this.toolbar,
          }}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.subscriptions.loading}
        />
      </div >
    );
  },
});
