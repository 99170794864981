import base from './base';
import Vue, { VNode } from 'vue';
import { mapGetters, createNamespacedHelpers } from 'vuex';

import { VRow, VChip, VCol, VIcon } from 'vuetify/lib';
import { fixWidth, formatCurrency } from '@/filters';
import _ from 'lodash';
import { playerTagConfig } from '@/config/playerTagConfig';

const { mapState: mapSportsConstsState } = createNamespacedHelpers('sportsConsts');
const maxStakeFactor = 0.95;
const SIGNIFICANT_TAGS = ['Diamond', 'Gold', 'Silver', 'Green', 'Sharp', 'Professional'];

const twoLettersAbbreviation = (text: string): string => {
  const parts = text.split(/[_\s]/);
  if (parts.length > 1) {
    return `${parts[0][0]}${parts[1][0]}`;
  } else {
    return text.substring(0, 2);
  }
};

export default Vue.extend({
  ...base,
  computed: {
    ...mapGetters({
      roles: 'auth/roles',
    }),
    ...mapSportsConstsState({ sports: 'sportsKeysMap' }),
    bet(): any {
      return this.message.data;
    },
    firstSelection(): any {
      return this.bet.selections[0];
    },
    sportName(): string {
      return this.sports[this.firstSelection.sportKey];
    },
    competitionName(): string {
      return `${this.firstSelection.details.competitionName}`;
    },
    categoryName(): string {
      return `(${this.firstSelection.details.categoryName})`;
    },
    competitionDetails(): string {
      return `${this.firstSelection.details.sportName} - ${this.firstSelection.details.competitionName} (${this.firstSelection.details.categoryName})`;
    },
    potentialReturnInEur(): number {
      return this.bet.stakeWithBonusInEur * (this.bet.price - 1.0);
    },
    competitionGradeClass(): string | undefined {
      if (this.firstSelection.details.competitionGrade < 3) {
        return 'font-weight-bold summary--failure';
      }
    },
    marketName(): string {
      return this.firstSelection.details.marketName;
    },
    selectionName(): string {
      return this.firstSelection.details.selectionName;
    },
    isMaxStake(): boolean {
      const stake = Number(this.bet.stakeWithBonus);
      const maxStake = Number(this.bet.maxStake);
      if (stake === 0 || maxStake === 0) {
        return false;
      }

      return stake >= maxStakeFactor * maxStake;
    },
    provider(): string {
      if (this.bet.selections.every((s: any) => s.provider === 'BGL')) {
        return 'BGL';
      } else {
        return this.bet.selections.filter((s: any) => s.provider !== 'BGL')[0].provider;
      }
    },
    providerAbbreviation(): string {
      switch (this.provider) {
        case 'BETRADAR': return 'BR';
        case 'BETFAIR': return 'BF';
        default:
          return this.provider.substring(0, 2);
      }
    },
    beatClosingLine(): boolean {
      return this.bet.selections
        .filter((s: any) => !s.isLive && Number(s.closingPrice) !== 0)
        .some((s: any) => Number(s.price) > Number(s.closingPrice));
    },
    playerTags(): string[] {
      return _.intersectionBy(SIGNIFICANT_TAGS, this.bet.playerTags, (s: string) => s.toLowerCase()).slice(0, 2);
    },
    receivedAt(): string {
      return this.message.receivedAt.substring(0, 8);
    },

    singleLineView() {
      return (
        <tr class={{
          'summary--warning-row': this.bet.state === 'REJECTED',
          'summary--max-stake-row': this.isMaxStake && this.bet.state !== 'REJECTED',
        }}>
          <td class='time font-weight-bold'>
            <a href={`/sports_bets/${this.bet.uuid}`} target='_blank' title={this.message.receivedAt}>
              {this.receivedAt}
            </a>
          </td>
          <td class='cashout'>
            {this.bet.isCashoutBet &&
              <VIcon small title='Cashout'>monetization_on</VIcon>
            }
          </td>
          <td class='beat-closing-line'>
            {this.beatClosingLine &&
              <VIcon small title='Beat closing line'>bolt</VIcon>
            }
          </td>
          <td class='state text-align--center'>
            {this.bet.state === 'COMPLETED' && this.bet.result !== 'PENDING' ?
              <span class={`font-weight-bold ${this.summaryClass(this.bet)}`}
                title={this.bet.result}>
                {twoLettersAbbreviation(this.bet.result)}
              </span>
              :
              this.bet.state === 'REJECTED' ?
                <span class='summary--warning font-weight-bold'
                  title={this.bet.state}>
                  {twoLettersAbbreviation(this.bet.state)}
                </span>
                :
                <span class='font-weight-bold' title={this.bet.state}>
                  {twoLettersAbbreviation(this.bet.state)}
                </span>
            }
          </td>
          <td class='player summary--info'>
            <a href={`/players/${this.bet.player.uuid}`}
              target='_blank'
              title={this.bet.player.nickname}>
              {this.bet.player.nickname}
            </a>
          </td>
          <td class='player-tags text-align--center'>
            {this.playerTags.map((t) =>
              (<VIcon small color={playerTagConfig[t.toLowerCase()]?.color} title={t}>military_tech</VIcon>),
            )}
          </td>
          <td class='provider text-align--center'>
            <span title={this.provider}>
              {this.providerAbbreviation}
            </span>
          </td>
          <td class={`text-align--center ${this.channelClass(this.bet)}`}>
            <span title={this.bet.metadata?.channel}>
              {this.bet.metadata?.channel?.charAt(0)}
            </span>
          </td>
          <td class='sport'>
            <span title={this.sportName}>{this.sportName}</span>
          </td>
          <td class='competition'>
            <span class='competition'
              title={this.competitionName + ' ' + this.categoryName}
            >{this.competitionName} {this.categoryName}</span>
          </td>
          <td class='competition-grade'>
            <span class={this.competitionGradeClass} >
              ({this.firstSelection.details.competitionGrade})
            </span >
          </td>
          <td class='event summary--info'>
            <a href={`/events/${this.firstSelection.eventId}`}
              target='_blank'
              title={this.firstSelection.details.eventName}>
              {this.firstSelection.details.eventName}
            </a >
          </td>
          <td class='market-name'>
            <span title={this.marketName}>{this.marketName}</span>
          </td>
          <td class='selection-name'>
            <span title={this.selectionName}>{this.selectionName}</span>
          </td>
          <td class='side'>
            <span>{this.bet.side}@{fixWidth(this.bet.price, 4)}</span>
          </td>
          <td class='text-align--right stake-bonus-eur stake'>
            <span title={formatCurrency(this.bet.stakeWithBonusInEur, 2, { name: 'EUR' })}>
              {formatCurrency(this.bet.stakeWithBonusInEur, 2, { name: 'EUR' })}
            </span>
          </td>
          <td class='text-align--right stake'>
            {
              this.bet.state === 'ACCEPTED' ?
                <span
                  class='font-weight-bold'
                  title={formatCurrency(this.potentialReturnInEur, 2, { name: 'EUR' })}
                >{formatCurrency(this.potentialReturnInEur, 2, { name: 'EUR' })}</span>
                :
                this.bet.state === 'COMPLETED' && this.bet.result !== 'PENDING' ?
                  <span class={`font-weight-bold ${this.summaryClass(this.bet)}`}
                    title={formatCurrency((this.bet.returnAmountWithBonusInEur * -1), 2, { name: 'EUR' })}
                  > {formatCurrency((this.bet.returnAmountWithBonusInEur * -1), 2, { name: 'EUR' })}</span >
                  :
                  this.bet.state === 'REJECTED' ?
                    <span
                      class='summary--warning font-weight-bold'
                      title={this.bet.rejectionCode}>
                      {this.bet.rejectionCode}
                    </span >
                    :
                    this.bet.result === 'PENDING' &&
                    <span>{this.bet.result}</span >
            }
          </td>
        </tr>
      );
    },

    cardView() {
      return (
        <div class='message-card'>
          <VRow class='d-inline-flex font-weight-bold'>
            <a href={`/sports_bets/${this.bet.uuid}`} target='_blank'>{this.message.receivedAt}</a>
            <VChip
              class='bet-state font-weight-black'
              color={this.betStateColor(this.bet.state, this.bet.returnAmountWithBonusInEur, this.bet.result)}
              x-small
              label
            >{this.bet.state}</VChip>
          </VRow>
          <VRow class='mt-1'>
            <VCol class='player'>
              <a href={`/players/${this.bet.player.uuid}`}
                target='_blank'
                title={this.bet.player.nickname}
                class='summary--info'>
                {this.bet.player.nickname}
              </a>
              <span label class={`text-align--right ${this.channelClass(this.bet)}`}>
                ({this.bet.metadata.channel})
              </span>
            </VCol>
          </VRow>
          <VRow class='mt-2'>
            <span class='ellipsis-text-overflow'>{this.competitionDetails}</span>
          </VRow>

          <VRow>
            <a href={`/events/${this.firstSelection.eventId}`}
              target='_blank'
              title={this.firstSelection.details.eventName}
              class='ellipsis-text-overflow summary--info'>
              {this.firstSelection.details.eventName}
            </a >
            <span
              class={`text-align--right competitionGrade ${this.competitionGradeClass}`}
            >({this.firstSelection.details.competitionGrade})</span>
          </VRow>

          <VRow class='mt-2'>
            <VCol>
              <span class='market-name'>{this.marketName}</span> -
              <span class='selection-name'>{this.selectionName}</span>
            </VCol>
          </VRow>

          <VRow class='mt-2'>
            <VCol>{this.bet.side}@{fixWidth(this.bet.price, 4)}</VCol>
            <VCol class='text-align--right'>
              {formatCurrency(this.bet.stakeWithBonus, 8, { name: this.bet.baseCurrency })}
            </VCol>
          </VRow>

          <VRow>
            <VCol class='text-align--right'>
              {formatCurrency(this.bet.stakeWithBonusInEur, 4, { name: 'EUR' })}
            </VCol>
          </VRow>

          {this.bet.state === 'ACCEPTED' &&
            <VRow class='font-weight-bold'>
              <VCol>potential return</VCol>
              <VCol class='text-align--right'>
                {formatCurrency(this.potentialReturnInEur, 4, { name: 'EUR' })}
              </VCol>
            </VRow>
          }

          {this.bet.state === 'COMPLETED' && this.bet.result !== 'PENDING' &&
            <VRow
              class={`font-weight-bold ${this.summaryClass(this.bet)}`}>
              <VCol>{this.bet.result}</VCol>
              <VCol class='text-align--right'>
                {formatCurrency((this.bet.returnAmountWithBonusInEur * -1), 4, { name: 'EUR' })}
              </VCol>
            </VRow>
          }
          {this.bet.state === 'REJECTED' &&
            <VRow class='summary--warning font-weight-bold'>
              <VCol>{this.bet.rejectionCode}</VCol>
            </VRow>
          }
          {this.bet.result === 'PENDING' &&
            < VRow class='result' />
          }

        </div>
      );
    },
  },

  mounted() {
    this.loadSportsConsts();
  },

  render(): VNode {
    return this.variant === 'singleLine' ? this.singleLineView : this.cardView;
  },
});

