import { uuid4 } from '@/utils/uuid';
import Vue from 'vue';
import {
  VAlert,
  VBtn,
  VCard, VCardText, VCardTitle,
  VDialog,
  VForm,
  VOverlay,
  VProgressCircular, VRow, VSelect, VSpacer, VTextField,
} from 'vuetify/lib';

const KEY_PREFIX = 'bgl:competition:';

function newCompetitionKey(): string {
  return KEY_PREFIX + uuid4();
}

export const CompetitionDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    error: String,
    loading: Boolean,
    sport: Object as () => { name: string; key: string },
    category: Object as () => { name: string; key: string },
    provider: String,
  },
  data() {
    return {
      name: '',
    };
  },
  methods: {
    onClose() {
      this.$emit('closeDialog');
    },
    onSave() {
      this.$emit('save', {
        providerCompetitionName: this.name,
        providerCompetitionKeys: [newCompetitionKey()],
        sportKey: this.sport.key,
        categoryKey: this.category.key,
        provider: this.provider,
      });
    },
  },
  render() {
    return (
      <VDialog onInput={this.onClose} value={this.isOpen} max-width='500'>
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>
          <VCardTitle>New competition</VCardTitle>
          <VCardText>
            <VForm>
              <VSelect
                items={[this.provider]}
                vModel={this.provider}
                label='Provider'
                dense
                outlined
                disabled
              />
              <VSelect
                items={[this.category]}
                vModel={this.category}
                label='Category'
                dense
                outlined
                disabled
                itemText='name'
                itemValue={(i: any) => i}
              />
              <VSelect
                items={[this.sport]}
                vModel={this.sport}
                label='Sport'
                dense
                outlined
                disabled
                itemText='name'
                itemValue={(i: any) => i}
              />
              <VTextField vModel={this.name} label='Name' dense outlined />
            </VForm>
            <VRow class='pa-3'>
              <VBtn onClick={this.onClose}>Discard</VBtn>
              <VSpacer />
              <VBtn onClick={this.onSave} color='primary'>
                Save
              </VBtn>
            </VRow>
            {this.error ? <VAlert type='warning'>{this.error}</VAlert> : null}
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
