<template>
  <v-container class="dashboard-widget-subform">
    <v-text-field
      v-model="props.eventId"
      type="number"
      label="Event ID"
    ></v-text-field>
    <v-checkbox
      v-model="props.showInfo"
      label="Show additional info?"
    ></v-checkbox>
  </v-container>
</template>

<script>
export default {
  props: ['props'],
  created() {
    if (this.props.showInfo === undefined) { this.props.showInfo = true; }
  },
};
</script>
