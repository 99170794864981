import { RouterLink } from '@/router';
import _ from 'lodash';
import Vue from 'vue';
import { VBtn, VCard, VCardActions, VCardText, VCardTitle, VCol, VDialog, VRow, VSpacer } from 'vuetify/lib';
import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import { mapActions } from 'vuex';
import { GenericEvent } from './helpers';

export enum EntityValidationErrorCode {
  ErrorCompetitorMismatch = 'ERROR_COMPETITOR_MISMATCH',
  ErrorCompetitionMismatch = 'ERROR_COMPETITION_MISMATCH',
}

export interface UnmatchedEntity {
  code: EntityValidationErrorCode;
  providerEntityKey: string;
  genericMatchedEntityId: string;
  provider: string;
}

export const EntityMismatchConfirmationDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    unmatchedEvent: Object as () => UnmatchedEvent,
    unmatchedEntityList: Array as () => UnmatchedEntity[],
    genericEvent: Object as () => GenericEvent,
    teamSwapped: Boolean,
  },

  computed: {
    genericEventProviders(): string {
      return _.uniq(this.genericEvent.providersData.map((x) => x.provider)).sort().join(', ');
    },
  },

  methods: {
    ...mapActions({
      matchCompetition: 'sports/competitions/matchCompetition',
      matchCompetitor: 'sports/competitors/matchCompetitor',
    }),

    onClose() {
      this.$emit('close');
    },

    async onSave() {
      try {
        await Promise.all(
          this.unmatchedEntityList.map(async (unmatchedEntity) => {
            if (unmatchedEntity.code === EntityValidationErrorCode.ErrorCompetitorMismatch) {
              await this.matchCompetitor({
                competitorId: parseInt(unmatchedEntity.genericMatchedEntityId, 10),
                provider:   unmatchedEntity.provider,
                providerKey: unmatchedEntity.providerEntityKey,
              });
            } else if (unmatchedEntity.code === EntityValidationErrorCode.ErrorCompetitionMismatch) {
              await this.matchCompetition({
                competitionId: parseInt(unmatchedEntity.genericMatchedEntityId, 10),
                provider: unmatchedEntity.provider,
                providerKey: unmatchedEntity.providerEntityKey,
              });
            }
          },
        ));

        this.$emit('save');
      } catch (e) {
        this.$emit('error', e);
        throw e;
      }
    },

    competitionMismatchInfo(unmatchedCompetition: UnmatchedEntity) {
      return (
        <VRow class='pt-8'>
          <VCol cols='2'><strong>Competition:</strong></VCol>
          <VSpacer />
          <VCol>
            {unmatchedCompetition.providerEntityKey ?
              <RouterLink
                class='d-flex align-center justify-start'
                to={{
                  name: 'competition',
                  params: { competitionId: unmatchedCompetition.providerEntityKey },
                }}>
                {this.unmatchedEvent.competitionName}
              </RouterLink> : <VCol>{this.unmatchedEvent.competitionName}</VCol>
            }
          </VCol>
          <VCol cols='2'><strong>Competition:</strong></VCol>
          <VSpacer />
          <VCol>
            <RouterLink
              class='d-flex align-center justify-start'
              to={{
                name: 'competition',
                params: { competitionId: this.genericEvent.competition.id },
              }}>
              {this.genericEvent.competition.name}
            </RouterLink>
          </VCol>
        </VRow>
      );
    },

    competitorMismatchInfo(unmatchedCompetitor: UnmatchedEntity) {
      const entityKeyMap = {
        [this.unmatchedEvent.providerHomeKey]: {
          competitorName: this.teamSwapped ? this.unmatchedEvent.awayName : this.unmatchedEvent.homeName,
          competitorIndex: 0,
        },
        [this.unmatchedEvent.providerAwayKey]: {
          competitorName: this.teamSwapped ? this.unmatchedEvent.homeName : this.unmatchedEvent.awayName,
          competitorIndex: 1,
        },
      };

      const props = entityKeyMap[unmatchedCompetitor.providerEntityKey];
      if (props) {
        return (
          <VRow class='pt-8'>
            <VCol cols='2'><strong>Competitor:</strong></VCol>
            <VSpacer />
            <VCol>{props.competitorName}</VCol>
            <VCol cols='2'><strong>Competitor:</strong></VCol>
            <VSpacer />
            <VCol>{this.genericEvent.competitors?.[props.competitorIndex].name || ''}</VCol>
          </VRow>
        );
      }
    },
  },

  render() {
    return (
      <VDialog value={this.isOpen} maxWidth='800' >
        <VCard>
          <VCardTitle>Entity confirmation</VCardTitle>
          <VCardText>
            Are you sure you would like to match the entities?
            <br />
            This will affect matching for all events in the entities listed below.
            {this.unmatchedEntityList.map((unmatchedEntity) => {
              if (unmatchedEntity.code === EntityValidationErrorCode.ErrorCompetitionMismatch) {
                return this.competitionMismatchInfo(unmatchedEntity);
              }
              if (unmatchedEntity.code === EntityValidationErrorCode.ErrorCompetitorMismatch) {
                return this.competitorMismatchInfo(unmatchedEntity);
              }

              return null;
            })}
            <VRow>
              <VCol cols='2'><strong>Provider:</strong></VCol>
              <VSpacer />
              <VCol>{this.unmatchedEvent.provider}</VCol>
              <VCol cols='6'>
              <VSpacer/>
              </VCol>
            </VRow>
          </VCardText>
          <VCardActions class='pa-8'>
            <VRow class='justify-space-between align-center'>
              <VBtn onClick={this.onClose}>
                Cancel
              </VBtn>
              <VBtn onClick={this.onSave} color='primary'>
                Save
              </VBtn>
            </VRow>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
