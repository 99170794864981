import { env } from '@/env';
import axios from 'axios';
import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface ExchangeRates {
  rates: Record<string, number>;
}

const getters: GetterTree<ExchangeRates, any> = {};

const mutations: MutationTree<ExchangeRates> = {
  UPDATE_RATES(state, data) {
    state.rates = data;
  },
};

const actions: ActionTree<ExchangeRates, any> = {
  async loadRates({ commit, state }) {
    if (state.rates.size > 0) { return; }

    const response = await axios.get(env.exchangeRatesUri);
    const rates = {} as Record<string, number>;
    for (const key of Object.keys(response.data.rates)) {
      const floatValue = parseFloat(response.data.rates[key].value);
      rates[key] = 1 / (floatValue > 0 ? floatValue : 1);
    }
    commit('UPDATE_RATES', rates);
  },
};

export default {
  namespaced: true,
  state: {
    rates: {},
  } as ExchangeRates,
  getters,
  mutations,
  actions,
};
