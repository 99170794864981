import { RootState } from '@/store';
import { ProviderPriceControl } from '@/types/sports';
import { gql } from 'apollo-boost';
import { ActionContext, ActionTree, GetterTree, MutationTree } from 'vuex';

export interface ProviderPriceControlState {
  providerPriceControl?: ProviderPriceControl;
  eventId?: number;
  isBaseReset?: boolean;
  loading?: boolean;
}

export enum ProviderPriceControlType {
  PREMATCH = 'prematch',
  LIVE = 'live',
}

const getters: GetterTree<ProviderPriceControlState, RootState> = {
  getLoading: (state: ProviderPriceControlState) => {
    return state.loading;
  },

  getBaseProviderPriceControl: (state: ProviderPriceControlState) => {
    return {
      prematch: state.providerPriceControl?.base?.prematch,
      live: state.providerPriceControl?.base?.live,
    };
  },

  getBaseProviderPriceControlReset: (state: ProviderPriceControlState) => {
    return state.isBaseReset;
  },
};

const mutations: MutationTree<ProviderPriceControlState> = {
  SET_EVENT_ID(state: ProviderPriceControlState, data: number) {
    state.eventId = data;
  },

  SET_PROVIDER_PRICE_CONTROL(state: ProviderPriceControlState, data: ProviderPriceControl) {
    state.providerPriceControl = data;
  },

  SET_BASE_RESET(state: ProviderPriceControlState, data: boolean) {
    state.isBaseReset = data;
  },

  SET_LOADING(state: ProviderPriceControlState, data: boolean) {
    state.loading = data;
  },
};

const actions: ActionTree<ProviderPriceControlState, RootState> = {
  async getProviderPriceControl({ commit, rootState }, eventId: number) {
    commit('SET_EVENT_ID', eventId);

    try {
      commit('SET_LOADING', true);
      const response = await rootState.apolloClient().query({
        query: GET_PROVIDER_PRICE_CONTROL_QUERY,
        variables: {
          eventId,
        },
      });

      const providerPriceControl = {
        base: {
          prematch: response.data.getProviderPriceControl.base?.prematch.reverse(),
          live: response.data.getProviderPriceControl.base?.live.reverse(),
        },
        markets: response.data.getProviderPriceControl.markets,
      };

      commit('SET_PROVIDER_PRICE_CONTROL', providerPriceControl);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  async updateProviderPriceControl({ commit, state, rootState }: ActionContext<ProviderPriceControlState, RootState>) {
    try {
      commit('SET_LOADING', true);
      const priceControl = {
        base: {
          prematch: state.providerPriceControl?.base?.prematch.reverse(),
          live: state.providerPriceControl?.base?.live.reverse(),
          reset: state.isBaseReset,
        },
      };

      const response = await rootState.apolloClient().mutate({
        mutation: UPDATE_PROVIDER_PRICE_CONTROL_MUTATION,
        variables: {
          eventId: state.eventId,
          priceControl,
        },
      });

      const providerPriceControl = {
        base: {
          prematch: response.data.updateProviderPriceControlV2.base?.prematch.reverse(),
          live: response.data.updateProviderPriceControlV2.base?.live.reverse(),
        },
        markets: response.data.updateProviderPriceControlV2.markets,
      };

      commit('SET_PROVIDER_PRICE_CONTROL', providerPriceControl);
      commit('SET_BASE_RESET', false);
    } finally {
      commit('SET_LOADING', false);
    }
  },

  updateBaseProviderPriceControlByType(
    { commit, state }: ActionContext<ProviderPriceControlState, RootState>,
    { type, baseProviderPriceControl }:
      { type: ProviderPriceControlType, baseProviderPriceControl: string[] },
  ) {
    if (state.providerPriceControl === undefined ||
      state.providerPriceControl.base === undefined) {
      return;
    }

    const base = { ...state.providerPriceControl.base };
    switch (type) {
      case ProviderPriceControlType.PREMATCH:
        base.prematch = baseProviderPriceControl;
        break;
      case ProviderPriceControlType.LIVE:
        base.live = baseProviderPriceControl;
        break;
    }

    commit('SET_PROVIDER_PRICE_CONTROL', { base });
  },

  updateBaseProviderPriceControlReset({ commit }: ActionContext<ProviderPriceControlState, RootState>, reset: boolean) {
    commit('SET_BASE_RESET', reset);
  },
};


const GET_PROVIDER_PRICE_CONTROL_QUERY = gql`query getProviderPriceControl($eventId: Int!) {
    getProviderPriceControl(eventId: $eventId) {
      base {
        prematch
        live
      }
    }
  }`;

const UPDATE_PROVIDER_PRICE_CONTROL_MUTATION = gql`mutation updateProviderPriceControlV2($eventId: Int!, $priceControl: ProviderPriceControlInput!) {
    updateProviderPriceControlV2(eventId: $eventId, priceControl: $priceControl) {
      base {
        prematch
        live
      }
    }
  }`;

export default {
  namespaced: true,
  state: {
    providerPriceControl: undefined,
    isBaseReset: false,
    loading: false,
  } as ProviderPriceControlState,
  getters,
  mutations,
  actions,
};
