import Vue from 'vue';
import { VBtn, VIcon, VSelect, VTextarea, VTextField, VSnackbar } from 'vuetify/lib';
import './AnnouncementMessageItem.less';
import { mapActions } from 'vuex';
import { hasRoles } from '@/utils/auth';
import { langKeys } from '@/config/announcement';
import { Scope } from '@/types/announcements';

export const AnnouncementMessageItem = Vue.extend({
  props: {
    uuid: String,
    langKey: String,
    message: String,
    name: String,
    type: String,
    messages: Array,
    messagesUpdatedAt: Array,
    enable: Boolean,
    scope: String,
  },
  data() {
    return {
      dataError: {},
      isLoading: true,
      hasDeleteError: false,
      hasUpdateError: false,
      hasExistError: false,
      disableSave: false,
      showConfirmation: false,
    };
  },
  methods: {
    ...mapActions({
      deleteMessageItem: 'announcements/deleteMessageItem',
      updateAnnouncement: 'announcements/updateAnnouncement',
    }),
    toggleConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },
    async onDelete() {
      try {
        this.disableSave = true;
        const updatedMessages = this.messages.filter((item: any) => item.key !== this.langKey);
        updatedMessages.forEach((message: any) => {
          delete message.__typename;
        });
        const updatedFilteredMessagesUpdatedAt = this.messagesUpdatedAt.filter(
          (item: any) => item.key !== this.langKey,
        );
        updatedFilteredMessagesUpdatedAt.forEach((message: any) => {
          delete message.__typename;
        });
        await this.deleteMessageItem({
          uuid: this.uuid,
          name: this.name,
          type: this.type,
          messages: updatedMessages,
          messagesUpdatedAt: updatedFilteredMessagesUpdatedAt,
          enable: this.enable,
          scope: this.scope,
        });
        this.hasDeleteError = false;
      } catch (err) {
        this.hasDeleteError = true;
      } finally {
        this.disableSave = false;
        this.toggleConfirmation();
      }
    },
    async onUpdate() {
      try {
        this.hasExistError = false;
        this.disableSave = true;
        const newMessages = [ ...this.messages, { key: this.langKey, value: this.message }];
        newMessages.forEach((message: any) => {
          delete message.__typename;
        });
        const filtered = newMessages.filter((item: any) => item.key);
        const newMessagesUpdatedAt = [ ...this.messages, { key: this.langKey, value: new Date().toISOString() }];
        newMessagesUpdatedAt.forEach((message: any) => {
          delete message.__typename;
        });
        const filteredMessagesUpdatedAt = newMessagesUpdatedAt.filter((item: any) => item.key);
        await this.updateAnnouncement({
          uuid: this.uuid,
          name: this.name,
          type: this.type,
          messages: filtered,
          messagesUpdatedAt: filteredMessagesUpdatedAt,
          enable: this.enable,
          scope: this.scope,
        });
        this.hasUpdateError = false;
      } catch (err) {
        this.hasDeleteError = true;
      } finally {
        this.disableSave = false;
        this.toggleConfirmation();
      }
    },
    onChange() {
      this.disableSave = false;
    },
  },
  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const InputType = mobile ? VTextarea : VTextField;
    return (
      <div class={mobile ? 'announcement-item-list' : 'announcement-item-grid'}>
        <div class='mr-1 item-lang-key'>
          <VSelect
            items={langKeys}
            vModel={this.langKey}
            dense
            outlined
            placeholder='Lang Key'
            change={this.onChange}
            class='input'
            error={this.hasUpdateError}
            messages={
              this.hasUpdateError ? 'Failed to update' : null
            }
          />
        </div>
        <div class='mr-1 item-message'>
          <InputType
            vModel={this.message}
            placeholder='Message'
            dense
            outlined
            activator='#add'
            change={this.onChange}
            error={this.hasUpdateError}
            messages={
              this.hasUpdateError ? 'Failed to update' : null
            }
          />
        </div>
        <div class='item-action'>
          {(hasRoles(['ops:operator']) || (hasRoles(['casino:operator']) && this.scope === Scope.casino)) && (
            <div>
              <VBtn icon onClick={this.onUpdate} disabled={this.disableSave}>
                {
                  <VIcon color='green' size='medium'>
                    save
                  </VIcon>
                }
              </VBtn>
              {this.langKey !== 'en' && <VBtn icon onClick={this.onDelete}>
                {
                  <VIcon color='red' size='medium'>
                    delete
                  </VIcon>
                }
              </VBtn>
              }
            </div>
          )}
        </div>
        <VSnackbar
          color={this.hasDeleteError || this.hasUpdateError || this.hasExistError ? 'red' : 'green'}
          vModel={this.showConfirmation}
          timeout='1000'
          top
        >
          <div class='text-center'>
                {this.hasDeleteError ? 'Failed to delete announcement message item' : this.hasUpdateError ? 'Failed to update announcement message item' : this.hasExistError ? 'Message already exist for same language' : 'Updated successfully'}
          </div>
        </VSnackbar>
      </div>
    );
  },
});
