import Vue from 'vue';
import { createNamespacedHelpers } from 'vuex';
import {
  VDivider,
  VListItem,
  VListItemContent,
  VListItemTitle,
  VListItemSubtitle,
  VRow,
  VSlider,
  VCol,
  VList,
  VTextField,
  VCard,
  VToolbar,
  VProgressLinear,
  VSpacer,
  VBtn,
  VSnackbar,
  VIcon,
} from 'vuetify/lib';
import { Competition, CompetitionPriorityGroup } from '@/types/sports';
import {
  MIN_PRIORITY,
  MAX_PRIORITY,
  backgroundColor,
  countriesTextList,
  successSnackbarConfig,
  failureSnackbarConfig,
} from './competitions-priorities-commons';

const { mapActions, mapGetters, mapState } = createNamespacedHelpers('sports/competitionsPriorities');

export default Vue.extend({
  props: {
    competition: Object as () => Competition,
  },

  data() {
    return {
      updateSuccess: false,
      updateFailure: false,
    };
  },

  methods: {
    ...mapActions(['loadCompetitionsPriorities', 'updateCompetitionPriorities']),

    async onSaveChanges() {
      try {
        await this.updateCompetitionPriorities();
        this.updateSuccess = true;
      } catch (e) {
        this.updateFailure = true;
        throw e;
      }
    },
  },

  computed: {
    ...mapGetters(['prioritiesGroups', 'dirty']),
    ...mapState(['inProgress']),

    toolbar() {
      return (
        <VToolbar>
          <VProgressLinear
            active={this.inProgress}
            indeterminate={this.inProgress}
            absolute
            bottom
            color='deep-purple accent-4'>
          </VProgressLinear>
          <VSpacer />
          <VBtn color='warning'
            disabled={!this.dirty}
            onClick={this.onSaveChanges}>
            Save changes
          </VBtn>
        </VToolbar>
      );
    },

    fabSaveButton() {
      return (
        <VBtn color='warning' fixed fab bottom right onClick={this.onSaveChanges}
          disabled={!this.dirty}>
          <VIcon>save</VIcon>
        </VBtn>
      );
    },
  },

  mounted() {
    if (this.competition) {
      this.loadCompetitionsPriorities(this.competition);
    }
  },

  watch: {
    competition(newValue: Competition) {
      this.loadCompetitionsPriorities(newValue);
    },
  },

  render() {
    const listItems = this.prioritiesGroups.map((group: CompetitionPriorityGroup, index: number) => {
      return (
        <div key={group.name}>
          {index !== 0 && <VDivider></VDivider>}
          <VListItem>
            <VListItemContent>
              <VListItemTitle>{group.name}</VListItemTitle>
              <VListItemSubtitle>
                {countriesTextList(group)}
              </VListItemSubtitle>
            </VListItemContent>
            <VListItemContent>
              <VRow no-gutters dense>
                <VCol cols='12'>
                  <VSlider
                    dense
                    vModel={group.competitionPriorities[0].priority}
                    min={MIN_PRIORITY}
                    max={MAX_PRIORITY}
                    validate-on-blur={true}>
                    <template slot='append'>
                      <VTextField
                        dense
                        vModel={group.competitionPriorities[0].priority}
                        background-color={backgroundColor(group.competitionPriorities[0], 'priority')}
                        class='priority'
                        type='number'
                        step='1'
                        min={MIN_PRIORITY}
                        max={MAX_PRIORITY}
                      />
                    </template>
                  </VSlider>
                </VCol>
              </VRow>
            </VListItemContent>
          </VListItem>
        </div>
      );
    });

    return (
      <div>
        {this.toolbar}
        {this.fabSaveButton}
        <VSnackbar {...successSnackbarConfig} vModel={this.updateSuccess} />
        <VSnackbar {...failureSnackbarConfig} vModel={this.updateFailure} />
        <VCard>
          <VList two-line>{listItems}</VList>
        </VCard>
      </div>
    );
  },
});
