import gql from 'graphql-tag';
import Vue from 'vue';

import EventHeadline from '@/components/sports/EventHeadline';
import EventLiabilityGrid from '@/components/sports/EventLiabilityGrid';

export default Vue.extend({
  components: {
    EventHeadline,
    EventLiabilityGrid,
  },
  props: {
    eventId: String,
    showInfo: Boolean,
    showHeadline: Boolean,
    showRepublishEventBetsTrigger: Boolean,
  },
  data() {
    return {
      subscription: null as any | null,
      eventLiabilityGridUpdated: null as any | null,
    };
  },
  methods: {
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription eventLiabilityGridUpdated(
              $eventId: String!
            ) {
              eventLiabilityGridUpdated(
                eventId: $eventId
              ) {
                eventId
                eventName
                sportKey
                competitionKey
                sportName
                competitionName
                turnover
                exposure
                playersCount
                betsCount
                startsAt
                isOutright
                grid {
                  homeScore
                  awayScore
                  outcome
                  profitAndLoss
                }
                turnoverWithUntracked
                playersCountWithUntracked
                betsCountWithUntracked
                marketKey
                possibleMarketKeys
                exposures {
                  marketKey
                  exposure
                }
              }
            }
          `,
          variables: {
            eventId: this.eventId,
          },
        })
        .subscribe({
          next: ({
            data,
          }: {
            data: { eventLiabilityGridUpdated: any };
          }) => {
            this.eventLiabilityGridUpdated = data.eventLiabilityGridUpdated;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error: any) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
});
