import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VCard, VDataTable, VRow } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import { unixToDateTimeUTCString } from '@/utils/date';
import ApproveDialog from '@/components/KYC/ApproveKYC';
import RejectDialog from '@/components/KYC/RejectKYC';
import OkSnackbar from '@/components/OkSnackbar';
import FailSnackbar from '@/components/FailSnackbar';

interface PlayerDetails {
  playerUUID: string;
  nickname: string;
  addressSubmittedAt: string;
  kycPortalLink: string;
}

export default Vue.extend({
  data() {
    return {
      page: 1,
      pageSize: 20,
      isLoading: true,
      isLoadFailed: false,
      loadError: '',
      data: {
        playerDetails: [] as PlayerDetails[],
        total: 0,
      },
      playerUUID: '',
      nickname: '',
      verified: false,
      showApprove: false,
      showReject: false,
      isOK: false,
    };
  },

  computed: {
    headers() {
      return [
        { text: 'Date Submitted', align: 'start', sortable: false, value: 'addressSubmittedAt' },
        { text: 'Nickname', align: 'start', sortable: false, value: 'nickname' },
        { text: 'Player UUID', align: 'start', sortable: false, value: 'playerUUID' },
        { text: 'Document Link', align: 'start', sortable: false, value: 'kycPortalLink' },
        { text: 'Review', align: 'start', sortable: false, value: 'review' },
      ];
    },
  },

  async mounted() {
    this.fetchPlayersKYC();
  },

  methods: {
    async fetchPlayersKYC(): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($result: String!, $page: Int, $pageSize: Int) {
              playersKYC(result: $result, page: $page, pageSize: $pageSize) {
                total
                playerDetails {
                  addressSubmittedAt
                  playerUUID
                  nickname
                  kycPortalLink
                }
              }
            }`,
          variables: {
            result: 'for_manual_review',
            page: this.page,
            pageSize: this.pageSize,
          },
          fetchPolicy: 'network-only',
        });
        this.data.total = response.data.playersKYC.total;
        this.data.playerDetails = response.data.playersKYC.playerDetails;
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
      window.scrollTo(0, 0);
    },

    handlePagination(options: any) {
      if (this.page === options.page && this.pageSize === options.itemsPerPage) {
        return;
      }

      this.page = options.page;
      this.pageSize = options.itemsPerPage;
      this.fetchPlayersKYC();
    },

    formatTime(time: string): string {
      const str = unixToDateTimeUTCString(Date.parse(time) / 1000);
      return str.substring(0, str.length - 4);
    },

    async verifyKYC(reason: string, expiry: string): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation($playerUUID: String!, $verified: Boolean!, $reason: String, $expiry: String) {
              verifyKYC(playerUUID: $playerUUID, verified: $verified, reason: $reason, expiry: $expiry)
            }`,
          variables: {
            playerUUID: this.playerUUID,
            verified: this.verified,
            reason,
            expiry,
          },
        });
        this.isOK = true;
        this.fetchPlayersKYC();
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
      window.scrollTo(0, 0);
    },

    openDialog(item: PlayerDetails, verified: boolean) {
      this.playerUUID = item.playerUUID;
      this.nickname = item.nickname;
      this.verified = verified;
      this.isOK = false;
      this.isLoadFailed = false;
      if (verified) {
        this.showApprove = true;
      } else {
        this.showReject = true;
      }
    },

    onSubmit(reason: string, expiry: string) {
      this.verifyKYC(reason, expiry);
      this.closeDialog();
    },

    closeDialog() {
      this.showApprove = false;
      this.showReject = false;
    },
  },

  render() {
    if (!hasRoles(
      ['cs:operator', 'pii:operator'])) {
      return (
        <div class='container'>Unauthorized</div>
      );
    }
    return (
      <div class='ma-4'>
        <VCard elevation={4} class='pa-2'>
          <VDataTable
            headers={this.headers}
            items={this.data.playerDetails}
            scopedSlots={{
              'item.addressSubmittedAt': ({ item }: { item: PlayerDetails }) => (
                this.formatTime(item.addressSubmittedAt)
              ),
              'item.playerUUID': ({ item }: { item: PlayerDetails }) => (
                <a href={'/players/' + item.playerUUID} target='_blank'>{item.playerUUID} </a>
              ),
              'item.kycPortalLink': ({ item }: { item: PlayerDetails }) => (
                <a href={item.kycPortalLink} target='_blank'>Jumio KYC link</a>
              ),
              'item.review': ({ item }: { item: PlayerDetails }) => (
                <VRow style='margin: 0rem'>
                  <VBtn color='green' onClick={() => this.openDialog(item, true)} style='margin: 0.5rem'>
                    Approve
                  </VBtn>
                  <VBtn color='red' onClick={() => this.openDialog(item, false)} style='margin: 0.5rem'>
                    Reject
                  </VBtn>
                </VRow>
              ),
            }}
            loading={this.isLoading}
            loading-text='Loading... Please wait'
            page={this.page}
            items-per-page={this.pageSize}
            server-items-length={this.data.total || 0}
            onPagination={this.handlePagination}
            footerProps={{'items-per-page-options': [20, 50]}}
          />

          <FailSnackbar show={this.isLoadFailed} error={this.loadError} />
          <OkSnackbar show={this.isOK} />

          <ApproveDialog show={this.showApprove} nickname={this.nickname} playerUUID={this.playerUUID}
            onApprove={(expiry: string, reason: string) => this.onSubmit(reason, expiry)}
            onCancel={this.closeDialog}
          />
          <RejectDialog show={this.showReject} nickname={this.nickname} playerUUID={this.playerUUID}
            onReject={(reason: string) => this.onSubmit(reason, '')}
            onCancel={this.closeDialog}
          />
        </VCard>
      </div>
    );
  },
});
