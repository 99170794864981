import Vue from 'vue';
import { gql } from 'apollo-boost';
import moment from 'moment';
import SearchDataTable from './SearchDataTable';
import _ from 'lodash';
import { VCard } from 'vuetify/lib';

export default Vue.extend({
  props: {
    searchText: String,
    sportId: Number,
    categoryId: Number,
  },

  data() {
    return {
      hasError: false,
      events: [],
      headers: [
        { text: 'Id', value: 'id', width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Sport', value: 'sportName' },
        { text: 'Competition', value: 'competition.name' },
        { text: 'Category', value: 'categoryName' },
        { text: 'Starts At', value: 'startsAtFormated' },
        { text: 'Type', value: 'type', width: 100 },
      ],
    };
  },

  methods: {
    routerLinkTo(item: { id: number }) {
      return { name: 'event', params: { eventId: item.id } };
    },
  },

  computed: {
    sportsKeysMap() {
      return this.$store.state.sportsConsts.sportsKeysMap;
    },

    categoriesMap() {
      return this.$store.state.sportsConsts.categoriesMap;
    },
  },

  apollo: {
    events: {
      query: gql`query ($name: String!, $sportId: Int, $categoryId: Int) {
        searchEvents(name: $name, sportId: $sportId, categoryId: $categoryId) {
          events {
            id
            name
            type
            sportKey
            startsAt
            competition {
              name
              categoryId
            }
          }
        }
      }`,
      variables(): { name: string, sportId: number, categoryId: number } {
        return { name: this.searchText, sportId: this.sportId, categoryId: this.categoryId };
      },
      update(data) {
        const events = data.searchEvents.events.map((event: any) => {
          event.sportName = this.sportsKeysMap[event.sportKey];
          event.categoryName = this.categoriesMap[event.competition?.categoryId];
          event.startsAtFormated = moment.unix(event.startsAt).format('YYYY-MM-DD HH:mm');
          return event;
        });
        return _.orderBy(events, ['startsAt'], ['desc']);
      },
      error(error) {

        this.hasError = true;
      },
      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },

  render() {
    return (
      <div>
      <SearchDataTable
        items={this.events || []}
        headers={this.headers}
        loading={this.$apollo.queries.events.loading}
        routerLinkTo={this.routerLinkTo}>
      </SearchDataTable>
      {
        this.hasError && <VCard class='pa-4'>
          <div>Missing search param. Event search must have at least a sport defined.</div>
        </VCard>
      }
      </div>
    );
  },
});
