import Vue from 'vue';
import { mapGetters, mapMutations } from 'vuex';
import './NavigationDrawer.less';
import {
  VNavigationDrawer,
  VList,
  VListGroup,
  VListItemIcon,
  VListItemContent,
  VListItem,
  VListItemAction,
  VDivider,
  VIcon,
  VListItemTitle,
  VTooltip,
} from 'vuetify/lib';
import ThemeSwitch from './ThemeSwitch';
import { SideMenuItem } from '@/types/common';

export default Vue.extend({
  props: {
    items: Array as () => SideMenuItem[],
    drawer: Boolean,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'auth/isAuthenticated',
      avatar: 'auth/avatar',
      name: 'auth/name',
      appVersion: 'appVersion',
    }),
    open: {
      // getter
      get() {
        return this.drawer;
      },
      // setter, just avoid warning here
      set() {
        return;
      },
    },
  },
  render() {
    return (
      <VNavigationDrawer
        vModel={this.open}
        clipped={this.$vuetify.breakpoint.lgAndUp}
        app
        id='navigation-drawer'
        class='navigation-drawer'
      >
        <VList dense>
          {this.items.map((item) => {
            if (item.children) {
              return (
                <VListGroup
                  key={item.text}
                  class='drawer-item'
                  active-class='drawer-active-item'
                >
                  <template slot='activator'>
                    {item.icon && (
                      <VListItemIcon>
                        <VIcon>{item.icon}</VIcon>
                      </VListItemIcon>
                    )}
                    <VListItemContent>
                      <VListItemTitle>{item.heading}</VListItemTitle>
                    </VListItemContent>
                  </template>
                  {item.children.map((child, i) => {
                    const linkProps = child.link.startsWith('http') ?
                      { target: '_blank', href: child.link } :
                      { to: child.link };

                    return (
                      <VListItem
                        props={linkProps}
                        key={i}
                        link
                        class='drawer-item drawer-item-child'
                        active-class='drawer-active-item'
                        dense
                      >
                        {child.icon && (
                          <VListItemAction>
                            <VIcon>{child.icon}</VIcon>
                          </VListItemAction>
                        )}
                        <VTooltip top scopedSlots={{
                          activator: ({ on }: any) => (
                            <VListItemContent active-class='drawer-active-item'>
                              <VListItemTitle on={on}>{child.text}</VListItemTitle>
                            </VListItemContent>
                          ),
                        }}>
                          <span>{child.text}</span>
                        </VTooltip>
                      </VListItem>
                    );
                  })}
                </VListGroup>
              );
            }
            return (
              <VListItem
                to={item.link}
                key={item.text}
                link
                class='drawer-item'
                active-class='drawer-active-item'
              >
                <VListItemAction>
                  <VIcon>{item.icon}</VIcon>
                </VListItemAction>
                <VListItemContent active-class='drawer-active-item'>
                  <VListItemTitle>{item.text}</VListItemTitle>
                </VListItemContent>
              </VListItem>
            );
          })}
          <VDivider />
          <VListItem>
            <ThemeSwitch />
          </VListItem>
          <VListItem>
            <VListItemContent>
              <VListItemContent>Version {this.appVersion}</VListItemContent>
            </VListItemContent>
          </VListItem>
        </VList>
      </VNavigationDrawer>
    );
  },
});
