import Vue, { VueConstructor } from 'vue';
import {
  VDataTable, VToolbar, VToolbarTitle, VBtn, VSpacer, VSelect, VRow, VCol, VIcon, VTooltip, VChip,
} from 'vuetify/lib';

import errorHandlerMixin from '../errorHandlerMixin';

import {
  GradingResult,
  GradingResultReview,
  GradingState,
  GradingReviewAction,
  gradingStates,
  pageSize,
} from '@/store/modules/sports/in_context_help_grading_results';
import { marketName, selectionName } from '../markets-names';
import { RouterLink } from '@/router';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import ResultDialog from './ResultDialog';
import { hasRoles } from '@/utils/auth';
import { DataTableHeader } from 'vuetify';
import { mapGetters, mapActions } from 'vuex';
import { iso8601ToDateTimeUTCString } from '@/utils/date';

const stateColors = (state: string): { color: string, textColor: string } => {
  switch (state) {
    case 'PENDING':
      return { color: 'yellow darken-1', textColor: 'black' };
    case 'REJECTED':
      return { color: 'error', textColor: 'white' };
    case 'APPROVED':
      return { color: 'success', textColor: 'white' };
  }
  return { color: 'default', textColor: 'default' };
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],

  props: {
    eventId: Number,
  },

  data() {
    return {
      gradingResultState: null as GradingState | null,
      currentResult: null as { action: GradingReviewAction, result: GradingResult } | null,
      currentPage: 1,
    };
  },

  watch: {
    loadedPages(newValue, oldValue) {
      if (newValue > oldValue) {
        this.currentPage = newValue;
      }
    },

    gradingResultState() {
      this.loadNextPage(true);
    },
  },

  methods: {
    ...mapActions({ loadGradingResults: 'sports/inContextHelpGradingResults/loadGradingResults' }),

    onReviewAdded(resultReview: GradingResultReview) {
      this.currentResult?.result?.reviews?.push(resultReview);
      this.currentResult = null;
      this.showSuccessMessage('The review has been added');
    },

    loadNextPage(clearStoreFirst: boolean = false) {
      this.loadGradingResults({ eventId: this.eventId, state: this.gradingResultState, clearStoreFirst });
    },
  },

  computed: {
    ...mapGetters({
      userEmail: 'auth/email',
      items: 'sports/inContextHelpGradingResults/gradingResults',
      translations: 'sports/inContextHelpGradingResults/translations',
      loading: 'sports/inContextHelpGradingResults/loading',
      totalPages: 'sports/inContextHelpGradingResults/totalPages',
      loadedPages: 'sports/inContextHelpGradingResults/loadedPages',
    }),

    reviewDialog() {
      if (this.currentResult) {
        return <ResultDialog
          result={this.currentResult.result}
          action={this.currentResult.action}
          onCancelled={() => { this.currentResult = null; }}
          onReviewAdded={this.onReviewAdded} />;
      }
    },

    displayActionsButtons(): boolean {
      return hasRoles(['cs:operator', 'sports:operator']) &&
        (!this.gradingResultState || this.gradingResultState === 'GRADING_STATE_PENDING');
    },
  },

  mounted() {
    this.loadNextPage(true);
  },

  render() {
    const headers: DataTableHeader[] = [
      { value: 'state', text: 'State', width: '150px' },
      { value: 'event', text: 'Event' },
      { value: 'market', text: 'Market' },
      { value: 'selection', text: 'Selection' },
      { value: 'result', text: 'Result' },
      { value: 'requestedBy', text: 'Requested By' }];

    if (this.displayActionsButtons) {
      headers.push({ value: 'action', text: 'Action', width: '220px', align: 'center' });
    }

    return (
      <div>
        {this.reviewDialog}
        {this.successSnackbar}
        <VToolbar dense>
          <VToolbarTitle>CS Grading</VToolbarTitle>
        </VToolbar>
        <VSpacer />
        <VRow>
          <VCol md='11' cols='9'>
            <VSelect dense outlined class='my-2 mx-2'
              vModel={this.gradingResultState}
              items={[
                { value: null, text: 'ALL' },
                ...gradingStates.map((gs) => ({ value: gs, text: gs.replace('GRADING_STATE_', '') })),
              ]}
              hideDetails='auto' />
          </VCol>
          <VCol md='1' cols='3'>
            <VBtn class='mt-3 mx-2' disabled={this.loading} onClick={() => {
              this.loadNextPage(true);
            }}>
              <VIcon>refresh</VIcon>
            </VBtn>
          </VCol>
        </VRow>
        <VDataTable
          showExpand={true}
          singleExpand={true}
          headers={headers}
          items={this.items}
          loading={this.loading}
          itemsPerPage={pageSize}
          hideDefaultFooter={true}
          page={this.currentPage}
          scopedSlots={{
            'item.event': ({ item }: { item: GradingResult }) => {
              const eventName = item.outright?.name || item.event?.name;
              return (
                <div>
                  <div>
                    <small class='grey--text'>
                      {getSportsName(item.outright?.sportKey || item.event?.sportKey || '', Locale.en)} -&nbsp;
                      {item.event?.competition?.name || item.outright?.competition?.name}
                    </small>
                  </div>
                  <div>
                    <RouterLink
                      class='text-decoration-none'
                      to={{ name: 'event', params: { eventId: item.eventId } }}>
                      {eventName}
                    </RouterLink>
                  </div>
                </div>
              );
            },
            'item.market': ({ item }: { item: GradingResult }) => {
              return marketName({ marketParams: item.params, marketKey: item.marketKey }, item.event || item.outright);
            },
            'item.selection': ({ item }: { item: GradingResult }) => {
              return selectionName({
                marketParams: item.params,
                marketKey: item.marketKey,
                selectionName: item.outcome,
              },
                item.event || item.outright,
                this.translations,
              );
            },
            'item.result': ({ item }: { item: GradingResult }) => {
              return item.result.replace('RESULT_TYPE_', '');
            },
            'item.requestedBy': ({ item }: { item: GradingResult }) => {
              return (
                <div>
                  <div>{item.requestedBy}</div>
                  <div>
                    <small class='grey--text'>{iso8601ToDateTimeUTCString(item.createTime)}</small>
                  </div>
                </div>
              );
            },
            'item.state': ({ item }: { item: GradingResult }) => {
              const state = item.state.replace('GRADING_STATE_', '');
              const { color, textColor } = stateColors(state);

              return (
                <div>
                  <VChip pill small color={color} text-color={textColor}>{state}</VChip>&nbsp;
                  {item.comment &&
                    <VTooltip right color='black' scopedSlots={{
                      activator: ({ on }: { on: any }) => {
                        return <VIcon {...{ on }} dense>info</VIcon>;
                      },
                    }}>
                      <span>{item.comment}</span>
                    </VTooltip>
                  }
                </div>
              );
            },
            'item.action': ({ item }: { item: GradingResult }) => {
              if (item.state === 'GRADING_STATE_PENDING') {
                return (
                  <span>
                    {this.userEmail !== item.requestedBy &&
                      <VBtn small color='success' onClick={() => {
                        this.currentResult = { action: 'REVIEW_ACTION_APPROVE', result: item };
                      }}>
                        Approve
                      </VBtn>
                    }
                    <VBtn class='ml-2' small color='error' onClick={() => {
                      this.currentResult = { action: 'REVIEW_ACTION_REJECT', result: item };
                    }}>
                      Reject
                    </VBtn>
                  </span>
                );
              }
            },
            'expanded-item': ({ item }: { item: GradingResult }) => {
              return (
                <td colspan='10'>
                  <div class='mx-10 my-5'>
                    <VDataTable
                      headers={[
                        { value: 'action', text: 'Action', width: '100px' },
                        { value: 'comment', text: 'Comment', width: '66%' },
                        { value: 'links', text: 'Links', width: '33%' },
                        { value: 'reviewedBy', text: 'Reviewed By', width: '200px' }]}
                      items={item.reviews}
                      hideDefaultFooter={true}
                      disablePagination={true}
                      scopedSlots={{
                        'item.action': ({ item: reviewItem }: { item: GradingResultReview }) => {
                          return reviewItem.action.replace('REVIEW_ACTION_', '');
                        },
                        'item.links': ({ item: reviewItem }: { item: GradingResultReview }) => {
                          return reviewItem.links.map((link) => {
                            const href = link.includes('http') ? link : `//${link}`;
                            return (
                              <div><a href={href} target='_blank'>{link}</a></div>
                            );
                          });
                        },
                        'item.reviewedBy': ({ item: reviewItem }: { item: GradingResultReview }) => {
                          return (
                            <div>
                              <div>{reviewItem.reviewedBy}</div>
                              <div>
                                <small class='grey--text'>{iso8601ToDateTimeUTCString(reviewItem.updateTime)}</small>
                              </div>
                            </div>
                          );
                        },
                      }} />
                  </div>
                </td>
              );
            },
            'footer': () => {
              return (
                <div class='v-data-footer py-2'>
                  <div class='v-data-footer__select'></div>
                  <div class='v-data-footer__pagination'>{this.currentPage} page of {this.totalPages || '...'}</div>
                  <div class='v-data-footer__icons-before'>
                    <VBtn icon onClick={() => { this.currentPage--; }} disabled={this.currentPage <= 1}>
                      <VIcon>chevron_left</VIcon>
                    </VBtn>
                  </div>
                  <div class='v-data-footer__icons-after'>
                    <VBtn icon onClick={() => {
                      if (this.currentPage >= this.loadedPages) {
                        this.loadNextPage();
                      } else {
                        this.currentPage++;
                      }
                    }}
                      disabled={!!this.totalPages && this.currentPage >= this.totalPages}>
                      <VIcon>chevron_right</VIcon>
                    </VBtn>
                  </div>
                </div>
              );
            },
          }} />
      </div>
    );
  },
});
