import store from '@/store';
import Vue from 'vue';

import { hasRoles } from '@/utils/auth';

const checkRoles = (rolesString: string, vnode: any) => {
  const requiredRoles = rolesString.split(',');

  const authed = hasRoles(requiredRoles);

  if (vnode.elm) {
    vnode.elm.style.display = authed ? 'block' : 'none';
  }
};

export const hasRolesDirective = (el: any, binding: any, vnode: any) => {
  // Being used when only the binding value is changed
  checkRoles(binding.value, vnode);
  // Watch store
  store.watch(
    (state, getters) => {
      return {
        roles: getters['auth/roles'],
      };
    },
    (val) => {
      checkRoles(binding.value, vnode);
    },
  );
};
