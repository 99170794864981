import Vue from 'vue';
import { VBtn, VCard, VCardActions, VCardText, VCardTitle, VDialog, VSpacer } from 'vuetify/lib';

export default Vue.extend({
  props: {
    title: String,
    text: String,
    item: Object,
  },

  methods: {
    onAgree() {
      this.dialog = false;
      this.$emit('agree', this.item);
    },

    onDisagree() {
      this.dialog = false;
      this.$emit('disagree');
    },
  },

  data() {
    return {
      dialog: true,
    };
  },

  render() {
    return (
      <VDialog vModel={this.dialog} max-width='500' persistent>
        <VCard>
          <VCardTitle class='headline'>{this.title}</VCardTitle>
          <VCardText>{this.text}</VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='green darken-1' text onClick={this.onDisagree}>
              Cancel
            </VBtn>
            <VBtn color='green darken-1' text onClick={this.onAgree}>
              Ok
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
