import Vue from 'vue';
import './SportsBonusControl.less';

import { gql } from 'apollo-boost';

import { VBtn } from 'vuetify/lib';
import { SportsBonusTable } from '../SportsBonusTable/SportsBonusTable';
import { DirectBonusForm } from '@/components/sports/DirectBonusForm/DirectBonusForm';
import { DepositBonusForm } from '../DepositBonusForm/DepositBonusForm';
import { hasRoles } from '@/utils/auth';

export interface SportsBonus {
  uuid: string;
  playerUUID: string;
  state: string;
  minimumBetPrice: number;
  rolloverRequirement: number;
  rolloverDaysDuration: number;
  offerExpiresAt: string;
  acceptedAt?: string;
  activatedAt?: string;
  rolledOverAt?: string;
  cancelledAt?: string;
  type: string;
  amount?: number;
  activationExchangeRate?: number;
  payoutExchangeRate?: number;
  currency: string;
  awardPercentage?: number;
  awardMaximum?: number;
  awardMinimum?: number;
  depositAmount?: number;
  depositTurnover?: number;
  pendingDepositTurnover?: number;
  playthrough?: number;
  pendingPlaythrough?: number;
  serialID: number;
  awardedBy: string;
  cancelledBy?: string;
  rolloverExpiresAt?: string;
  awardedAt: string;
  depositMaximum?: number;
  depositMinimum?: number;
  version: number;
  payoutAmount?: number;
  payoutCurrency?: string;
  exhaustedAt?: string;
  expiredAt?: string;
  turnover: number;
  affiliateDebitedAmount?: number;
  affiliateScheme?: string;
  affiliateCommission?: number;
  affiliateCurrency?: string;
  affiliateExchangeRate?: number;
  description?: string;
}

interface SportsBonusForm {
  minimumBetPrice: string;
  rolloverRequirement: string;
  rolloverDaysDuration: string;
  offerExpiresAt: string;
  type: string;
  amount: string;
  currency: string;
  awardPercentage: string;
  awardMaximum: string;
  awardMinimum: string;
  description: string;
}

const sportsBonusAttributes = `
  uuid
  playerUUID
  state
  minimumBetPrice
  rolloverRequirement
  rolloverDaysDuration
  offerExpiresAt
  acceptedAt
  activatedAt
  rolledOverAt
  cancelledAt
  type
  amount
  activationExchangeRate
  payoutExchangeRate
  currency
  awardPercentage
  awardMaximum
  awardMinimum
  depositAmount
  depositTurnover
  pendingDepositTurnover
  playthrough
  pendingPlaythrough
  serialID
  awardedBy
  cancelledBy
  rolloverExpiresAt
  awardedAt
  depositMaximum
  depositMinimum
  version
  payoutAmount
  payoutCurrency
  exhaustedAt
  expiredAt
  turnover
  affiliateDebitedAmount
  affiliateScheme
  affiliateCommission
  affiliateCurrency
  affiliateExchangeRate
  description
`;

const operationRoles = ['sports:operator', 'cs:operator'];

export const SportsBonusControl = Vue.extend({
  props: {
    playerUUID: String,
  },
  data() {
    return {
      playerSportsBonuses: [] as SportsBonus[],
      sportsBonus: null as SportsBonus | null,
      showBonusSettings: false,
      isDepositBonusFormOpen: false,
      isDirectBonusFormOpen: false,
      isLoading: false,
      dialogLoading: false,
      formError: null as string | null,
    };
  },
  computed: {
    hasActiveBonus() {
      return !!this.$data.playerSportsBonuses.find((bonus: SportsBonus) => {
        return ['ACTIVE', 'PENDING_DEPOSIT_TURNOVER', 'AVAILABLE'].includes(bonus.state);
      });
    },
    canOperate() {
      return hasRoles(operationRoles);
    },
  },
  methods: {
    openDepositBonusForm() {
      this.isDirectBonusFormOpen = false;
      this.isDepositBonusFormOpen = true;
    },
    openDirectBonusForm() {
      this.isDepositBonusFormOpen = false;
      this.isDirectBonusFormOpen = true;
    },
    onCloseDialog() {
      this.isDepositBonusFormOpen = false;
      this.isDirectBonusFormOpen = false;
      this.sportsBonus = null;
    },
    async updateBonus(input: SportsBonusForm): Promise<{ success: boolean, error?: string }> {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($bonusUUID: String!, $input: UpdateSportsBonusInput!) {
              updateSportsBonus(bonusUUID: $bonusUUID, input: $input) {
                ${sportsBonusAttributes}
              }
            }
          `,
          variables: {
            bonusUUID: this.sportsBonus!.uuid,
            input: {
              minimumBetPrice: parseFloat(input.minimumBetPrice),
              rolloverRequirement: parseFloat(input.rolloverRequirement),
              rolloverDaysDuration: parseInt(input.rolloverDaysDuration, 10),
              offerExpiresAt: new Date(input.offerExpiresAt).toISOString(),
              description: input.description,
            },
          },
        });

        if (result.data.updateSportsBonus) {
          const idx = this.playerSportsBonuses.findIndex((item) => item.uuid === result.data.updateSportsBonus.uuid);
          if (idx >= 0) {
            this.playerSportsBonuses[idx] = result.data.updateSportsBonus;
            this.playerSportsBonuses = [ ...this.playerSportsBonuses ];
          }
        }
      } catch (err) {
        return { success: false, error: err?.graphQLErrors[0]?.extensions?.response?.body?.error || err.message };
      }

      return { success: true };
    },
    async createBonus(input: SportsBonusForm): Promise<{ success: boolean, error?: string }> {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: CreateSportsBonusInput!) {
              createSportsBonus(input: $input) {
                ${sportsBonusAttributes}
              }
            }
          `,
          variables: {
            input: {
              playerUUID: this.playerUUID,
              minimumBetPrice: parseFloat(input.minimumBetPrice),
              rolloverRequirement: parseFloat(input.rolloverRequirement),
              rolloverDaysDuration: parseInt(input.rolloverDaysDuration, 10),
              offerExpiresAt: new Date(input.offerExpiresAt).toISOString(),
              type: input.type,
              amount: parseFloat(input.amount),
              currency: input.currency,
              awardPercentage: parseFloat(input.awardPercentage),
              awardMaximum: parseFloat(input.awardMaximum),
              awardMinimum: parseFloat(input.awardMinimum),
              description: input.description,
            },
          },
        });
        if (result.data.createSportsBonus) {
          this.playerSportsBonuses.unshift(result.data.createSportsBonus);
        }
      } catch (err) {
        return { success: false, error: err?.graphQLErrors[0]?.extensions?.response?.body?.error || err.message };
      }

      return { success: true };
    },
    async cancelBonus(): Promise<{ success: boolean, error?: string }> {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($bonusUUID: String!) {
              cancelSportsBonus(bonusUUID: $bonusUUID) {
                ${sportsBonusAttributes}
              }
            }
          `,
          variables: {
            bonusUUID: this.sportsBonus!.uuid,
          },
        });
        if (result.data.cancelSportsBonus) {
          const idx = this.playerSportsBonuses.findIndex((item) => item.uuid === result.data.cancelSportsBonus.uuid);
          if (idx >= 0) {
            this.playerSportsBonuses[idx] = result.data.cancelSportsBonus;
            this.playerSportsBonuses = [ ...this.playerSportsBonuses ];
          }
        }
      } catch (err) {
        return { success: false, error: err?.graphQLErrors[0]?.extensions?.response?.body?.error || err.message };
      }

      return { success: true };
    },
    async onSaveBonus(input: SportsBonusForm) {
      this.dialogLoading = true;
      const result = await (this.sportsBonus ? this.updateBonus(input) : this.createBonus(input));
      this.afterFormResult(result);
    },
    async onCancelBonus() {
      this.dialogLoading = true;
      const result = await this.cancelBonus();
      this.afterFormResult(result);
    },
    afterFormResult(result: { success: boolean, error?: string }) {
      if (result.success) {
        this.formError = null;
        this.onCloseDialog();
      } else {
        this.formError = result.error!;
      }

      this.dialogLoading = false;
    },
  },
  apollo: {
    playerSportsBonuses: {
      query: gql`
        query($playerUUID: String!) {
          playerSportsBonuses(playerUUID: $playerUUID) {
            ${sportsBonusAttributes}
          }
        }
      `,
      update(data: { playerSportsBonuses: SportsBonus[] }) {
        return data.playerSportsBonuses.sort((a, b) => b.serialID - a.serialID);
      },
      variables(): { playerUUID: string } {
        return { playerUUID: this.playerUUID };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      pollInterval: 5000,
    },
  },
  render() {
    return (
      <div>
        { !this.isLoading && !this.hasActiveBonus && this.canOperate ?
            <div class='bonus-award-button-container'>
              <VBtn
                small
                class='ma-4'
                onClick={this.openDepositBonusForm}
              >
                Award deposit Bonus
              </VBtn>
              <VBtn
                small
                onClick={this.openDirectBonusForm}
              >
                Award direct Bonus
              </VBtn>
            </div> : null }
        <SportsBonusTable
          isLoading={this.isLoading}
          sportsBonuses={this.playerSportsBonuses}
          canOperate={this.canOperate}
          on={{
            edit: (bonus: SportsBonus) => {
              this.sportsBonus = { ...bonus };
              this.formError = null;
              if (bonus.type === 'DEPOSIT') {
                this.openDepositBonusForm();
              }
              if (bonus.type === 'DIRECT') {
                this.openDirectBonusForm();
              }
            },
          }}
        />
        <div class='bonus-settings-form-container'>
          <DepositBonusForm
            on={{
              closeDialog: this.onCloseDialog,
              save: this.onSaveBonus,
              cancel: this.onCancelBonus,
            }}
            error={this.formError}
            sportsBonus={this.sportsBonus}
            isOpen={this.isDepositBonusFormOpen}
            loading={this.dialogLoading}
          />
          <DirectBonusForm
            on={{
              closeDialog: this.onCloseDialog,
              save: this.onSaveBonus,
              cancel: this.onCancelBonus,
            }}
            error={this.formError}
            sportsBonus={this.sportsBonus}
            isOpen={this.isDirectBonusFormOpen}
            loading={this.dialogLoading}
          />
        </div>
      </div>
    );
  },
});
