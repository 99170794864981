import Vue from 'vue';
import LiabilitySettings from '@/components/sports/LiabilitySettings/LiabilitySettings';

export default Vue.extend({
  name: 'LiabilitySettings',
  render() {
    return (
      <LiabilitySettings></LiabilitySettings>
    );
  },
});
