<template>
  <v-container class="dashboard-widget-subform">
    <v-text-field
      v-model="props.eventId"
      type="number"
      label="Event ID"
    ></v-text-field>
  </v-container>
</template>

<script>
export default {
  props: ['props'],
};
</script>
