export const STUDIO_KEY_SHORT_TO_HUMANIZED: { [key: string]: string } = {
  adoptit:               'AdoptIt',
  ag:                    'Asia Gaming',
  ags:                   'AGS',
  ainsworth:             'Ainsworth Game Technology',
  alchemygaming:         'Alchemy Gaming',
  allfouronestudios:     'All41 Studios',
  amaticindustries:      'Amatic Industries',
  apollogames:           'Apollo Games',
  arcadem:               'ARCADEM',
  authenticgaming:       'Authentic Gaming',
  beefee:                'Beefee',
  betgamestv:            'BetGames.TV',
  bethehouse:            'Be The House',
  betinvest:             'Betinvest',
  bigtimegaming:         'Big Time Gaming',
  blablablastudios:      'Bla Bla Bla Studios',
  blackpuddinggames:     'Black Pudding Games',
  blubit:                'BluBit',
  blueprint:             'Blueprint',
  boomerangstudios:      'Boomerang Studios',
  booming:               'Booming Games',
  booongo:               'Booongo Games',
  bsg:                   'Betsoft',
  candlebets:            'Candle Bets',
  colossusbets:          'Colossus Bets',
  concept:               'Concept Gaming',
  crazytoothstudio:      'Crazy Tooth Studio',
  ctgaming:              'CT Gaming',
  dicelab:               'DiceLab',
  dtech:                 'D-Tech',
  ebet:                  'eBET',
  edict:                 'edict',
  egtinteractive:        'EGT Interactive',
  electricelephant:      'Electric Elephant',
  elk:                   'ELK',
  endorphina:            'Endorphina',
  espresso:              'Espresso Games',
  everi:                 'Everi',
  evo:                   'Evolution Gaming',
  evoplay:               'Evoplay Entertainment',
  eyecon:                'Eyecon',
  ez:                    'Ezugi',
  fantasmagames:         'Fantasma Games',
  fazi:                  'Fazi',
  felt:                  'Felt',
  firebird:              'Firebird Entertainment',
  fortunefactorystudios: 'Fortune Factory Studios',
  fourtheplayer:         '4ThePlayer',
  foxium:                'Foxium',
  fugagaming:            'FUGA Gaming',
  gamanza:               'Gamanza',
  gameart:               'GameArt',
  gameburgerstudios:     'Gameburger Studios',
  gameplay:              'GamePlay',
  gamesinc:              'Games Inc',
  gameslab:              'GamesLab',
  gamesys:               'Gamesys',
  gamevy:                'Gamevy',
  gaming1:               'GAMING1',
  gamomat:               'Gamomat',
  ganapati:              'Ganapati',
  genesis:               'Genesis',
  ggames:                'G.Games',
  givme:                 'Givme Games',
  goldcoinstudios:       'Gold Coin Studios',
  goldenhero:            'Golden Hero Games',
  goldenrace:            'Golden Race',
  goldenrockstudios:     'Golden Rock Studios',
  greenjadegames:        'Green Jade Games',
  greentube:             'Greentube',
  habanero:              'Habanero',
  hacksawgaming:         'Hacksaw Gaming',
  halfpixelstudio:       'Half Pixel Studio',
  high5games:            'High 5 Games',
  igt:                   'IGT',
  intervision:           'Intervision',
  irondog:               'Iron Dog Studio',
  isoftbet:              'iSoftBet',
  jftw:                  'JFTW',
  kajot:                 'KAJOT',
  kalambagames:          'Kalamba Games',
  kiron:                 'Kiron Interactive',
  konami:                'Konami',
  leander:               'Leander',
  leap:                  'Leap Gaming',
  lightningbox:          'Lightning Box',
  livegtwofour:          'LiveG24',
  lottoinstantwin:       'Lotto Instant Win',
  lottopop:              'LottoPOP',
  lotus:                 'Lotus Gaming',
  maxwingaming:          'Max Win Gaming',
  mga:                   'Grupo MGA',
  mgs:                   'Microgaming',
  mikado:                'Mikado',
  millenniumbet:         'Millenniumbet',
  multislot:             'MultiSlot',
  neonvalleystudios:     'Neon Valley Studios',
  netent:                'NetEnt',
  netgaming:             'NetGaming',
  nextgen:               'Next Gen',
  nolimit:               'Nolimit City',
  northernlightsgaming:  'Northern Lights Gaming',
  nsoft:                 'Nsoft',
  nucleus:               'Nucleus Gaming',
  nyxgaminggroup:        'NYX Gaming Group',
  oldskoolstudios:       'Old Skool Studios',
  omigaming:             'OMI Gaming',
  onetouch:              'One Touch',
  onextwo:               '1X2Gaming',
  originalspirit:        'Original Spirit',
  ortiz:                 'Ortiz',
  oryx:                  'Oryx Gaming',
  oryxvirtuallottery:    'Oryx Virtual Lottery',
  pariplay:              'Pariplay',
  pearfiction:           'Pear Fiction',
  peterandsons:          'Peter & Sons',
  pgsoft:                'PG Soft',
  piratesgold:           'Pirates Gold Studios',
  plankgaming:           'Plank Gaming',
  platipus:              'Platipus Gaming',
  playson:               'Playson',
  playzido:              'Playzido',
  png:                   'Play\'n GO',
  pragmaticplay:         'PragmaticPlay',
  presentcreative:       'Present Creative',
  probabilityjones:      'Probability Jones',
  pulseeightstudios:     'Pulse 8 Studios',
  push:                  'Push Gaming',
  quickspin:             'Quickspin',
  quikgaming:            'Quik Gaming',
  rabcat:                'Rabcat',
  rakki:                 'Rakki',
  realistic:             'Realistic',
  redrake:               'Red Rake',
  redtiger:              'Red Tiger',
  reelplay:              'Reel Play',
  relaxgaming:           'Relax Gaming',
  revolvergaming:        'Revolver Gaming',
  roxorgaming:           'Roxor Gaming',
  rtgasia:               'RTG Asia',
  rubyplay:              'Ruby Play',
  sagaming:              'SA Gaming',
  sapphiregaming:        'Sapphire Gaming',
  sgdigital:             'SG digital',
  sigmagaming:           'Sigma Gaming',
  silverbackgaming:      'Silverback Gaming',
  skillzz:               'Skillzz',
  skywindgroup:          'Skywind Group',
  slingooriginals:       'Slingo Originals',
  slingshotstudios:      'Slingshot Studios',
  snowborngames:         'Snowborn Games',
  spadegaming:           'Spadegaming',
  spearheadstudios:      'Spearhead Studios',
  spike:                 'Spike Games',
  spinplaygames:         'Spin Play Games',
  spml:                  'Spinomenal',
  spribe:                'Spribe',
  stakelogic:            'StakeLogic',
  sthlmgaming:           'Sthlm Gaming',
  stormcraftstudios:     'Stormcraft Studios',
  stormgaming:           'Storm Gaming',
  switchstudios:         'Switch Studios',
  synot:                 'SYNOT',
  tangente:              'Tangente',
  th:                    'Tom Horn',
  threeradical:          '3radical',
  thunderkick:           'Thunderkick',
  thunderspin:           'Thunderspin',
  tripleedgestudios:     'Triple Edge Studios',
  triplepg:              'Triple PG',
  twobytwogaming:        '2by2 Gaming',
  vi:                    'Vivo',
  wagermill:             'WagerMill',
  wazdan:                'Wazdan',
  yggdrasil:             'Yggdrasil Gaming',
  zitro:                 'ZITRO',
  zonelock:              'Zonelock',
};

export const humanizeShortStudioKey = (shortStudioKey: string) => {
  return STUDIO_KEY_SHORT_TO_HUMANIZED[shortStudioKey] || shortStudioKey;
};
