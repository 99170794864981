import Vue, { VueConstructor } from 'vue';
import { gql } from 'apollo-boost';
import { VDataTable, VBtn, VAutocomplete, VRow, VCol, VTextField, VIcon, VChip } from 'vuetify/lib';
import errorHandlerMixin from '../errorHandlerMixin';
import { createNamespacedHelpers } from 'vuex';
import { Event } from '@/types/sports';


const { mapActions, mapState } = createNamespacedHelpers('sports/events');

interface ViewData {
  id?: number;
  entityType: string;
  key: string;
  value: string;
  originalValue: string;
  enValue: string;
}

const typesColors = {
  outright: { color: 'primary' },
  competitor: { color: 'green', textColor: 'white' },
  competition: { color: 'lime', textColor: 'black' },
  player: { color: 'default' },
  selection: { color: 'secondary' },
} as Record<string, { color: string, textColor?: string }>;

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  name: 'OutrightsTranslations',
  mixins: [errorHandlerMixin],
  props: {
    outrightId: Number,
  },

  data() {
    return {
      selectionsKeys: [] as string[],
      translations: {} as Record<string, string>,
      enTranslations: {} as Record<string, string>,
      locale: 'en',
      search: '',
    };
  },

  computed: {
    ...mapState(['currentEvent']),

    viewData(): ViewData[] {
      const selections = this.selectionsKeys.map((key) => {
        return {
          entityType: 'selection',
          key,
          value: this.translations[key],
          originalValue: this.translations[key],
          enValue: this.enTranslations[key],
        };
      });

      const outrights = this.outrights.map((o) => {
        return {
          entityType: 'outright',
          id: o.id,
          key: o.key,
          value: this.translations[o.key],
          originalValue: this.translations[o.key],
          enValue: this.enTranslations[o.key],
        };
      });

      const competitors = this.competitors.map((c) => {
        return {
          id: c.id,
          entityType: 'competitor',
          key: c.key,
          value: this.translations[c.key],
          originalValue: this.translations[c.key],
          enValue: this.enTranslations[c.key],
        };
      });

      const competitions = this.competitions.map((c) => {
        return {
          id: c.id,
          entityType: 'competition',
          key: c.key,
          value: this.translations[c.key],
          originalValue: this.translations[c.key],
          enValue: this.enTranslations[c.key],
        };
      });

      const players = this.playersKeys.map((key) => {
        return {
          entityType: 'player',
          key,
          value: this.translations[key],
          originalValue: this.translations[key],
          enValue: this.enTranslations[key],
        };
      });


      return [...selections, ...outrights, ...competitors, ...competitions, ...players];
    },

    outrights(): Array<{ key: string, id: number }> {
      if (this.currentEvent.outrightKey) {
        return [{ key: this.currentEvent.outrightKey, id: this.currentEvent.id }];
      } else {
        return [];
      }
    },

    competitors(): Array<{ key: string, id: number }> {
      return (this.currentEvent as Event).competitors.map((c) => ({ key: c.key, id: c.id }));
    },

    competitions(): Array<{ key: string, id: number }> {
      const competition = (this.currentEvent as Event).competition;
      if (competition) {
        return [{ key: competition.v2Key, id: competition.id }];
      } else {
        return [];
      }
    },

    playersKeys(): string[] {
      return (this.currentEvent as Event).players.map((p) => p.key);
    },

    translationsKeys(): string[] {
      return [
        ...this.selectionsKeys,
        ...this.outrights.map((o) => o.key),
        ...this.competitors.map((c) => c.key),
        ...this.competitions.map((c) => c.key),
        ...this.playersKeys,
      ];
    },
  },

  methods: {
    ...mapActions(['loadCurrentEvent', 'refreshCurrentEvent']),

    toolbar() {
      return (
        <div class='my-1'>
          <VRow dense>
            <VCol colls='6' offset-md='8' md='2'
              class={{
                'text-right': this.$vuetify.breakpoint.mdAndUp,
                'text-center': this.$vuetify.breakpoint.smAndDown,
              }}>
            </VCol>
            <VCol cols='6' md='2'>
              <VAutocomplete
                class='pr-2'
                vModel={this.locale}
                items={this.$store.state.sportsConsts.locales}
                dense
                outlined
                label='Locale' />
            </VCol>
          </VRow>
          <div>
            <VTextField
              vModel={this.search}
              appendIcon='search'
              label='Search'
              clearable
              dense />
          </div>
        </div>
      );
    },

    valueColumn({ item }: { item: ViewData }) {
      return (
        <VTextField dense vModel={item.value}>
          <template slot='append'>
            <VBtn small icon tabindex={-1}
              disabled={item.value === item.originalValue}
              onClick={async () => {
                if (item.entityType === 'selection' || item.entityType === 'player') {
                  await this.updateKeyTranslation(item.key, item.value);
                } else {
                  await this.updateEntityTranslation(item);
                }
              }}>
              <VIcon color='warning'>save</VIcon>
            </VBtn>
          </template>
          <template slot='prepend'>
            <VBtn small icon tabindex={-1}
              disabled={item.value === item.originalValue}
              onClick={() => { item.value = item.originalValue; }}>
              <VIcon color='error'>change_circle</VIcon>
            </VBtn>
          </template>
        </VTextField>
      );
    },

    typeColumn({ item }: { item: ViewData }) {
      return (
        <VChip label small class='text-uppercase'
          color={typesColors[item.entityType].color}
          textColor={typesColors[item.entityType].textColor} >
          {item.entityType}
        </VChip>
      );
    },

    async updateKeyTranslation(key: string, value: string) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($locale: String!, $translations: [[String]]!) {
            createKeysTranslations(locale: $locale, translations: $translations)
          }`,
          variables: {
            locale: this.locale,
            translations: [[key, value]],
          },
        });

        const updates = Object.fromEntries(result.data.createKeysTranslations);
        this.translations = { ...this.translations, ...updates };
        if (this.locale === 'en') {
          this.enTranslations = { ...this.enTranslations, ...updates };
        }
        this.showSuccessMessage('Translations updated');
      } catch (e) {
        this.showFailureMessage(['Translations update failure:', e.message]);
        throw e;
      }
    },

    async updateEntityTranslation(viewData: ViewData) {
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`mutation ($input: TranslationInput!) {
            updateTranslation(input: $input) {
              success
            }
          }`,
          variables: {
            input: {
              entityType: viewData.entityType,
              id: viewData.id,
              locale: this.locale,
              value: viewData.value,
            },
          },
        });
        if (res.data.updateTranslation.success) {
          this.translations[viewData.key] = viewData.value;
          if (this.locale === 'en') {
            this.enTranslations[viewData.key] = viewData.value;
            if (['outright', 'competitor', 'competition'].includes(viewData.entityType)) {
              this.refreshCurrentEvent(this.outrightId);
            }
          }
        }
        this.showSuccessMessage('Translations updated');
      } catch (e) {
        this.showFailureMessage(['Translations update failure:', e.message]);
        throw e;
      }
    },
  },

  mounted() {
    this.loadCurrentEvent(this.outrightId);
  },

  apollo: {
    selectionsKeys: {
      query: gql`query ($outrightId: Int!) {
        getPublicApiOutright(outrightId: $outrightId) {
          names
        }
      }`,

      variables(): { outrightId: number } {
        return {
          outrightId: this.outrightId,
        };
      },

      update(data) {
        return data.getPublicApiOutright.names.map(([key, value]: [string, string]) => key);
      },

      fetchPolicy: 'network-only',
    },

    translations: {
      query: gql`query ($locale: String!, $keys: [String]!) {
        getKeysTransations(locale: $locale, keys: $keys)
      }`,

      variables(): { locale: string, keys: string[] } {
        return {
          locale: this.locale,
          keys: this.translationsKeys,
        };
      },

      update(data) {
        return Object.fromEntries(data.getKeysTransations);
      },

      result({ data }) {
        const translations = Object.fromEntries(data.getKeysTransations);
        if (this.locale === 'en') {
          this.enTranslations = translations;
        }
      },

      skip(): boolean {
        return this.translationsKeys.length === 0;
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <div class='sports-outrights-translations ma-4' >
        {this.successSnackbar}
        {this.failureSnackbar}
        <VDataTable
          dense
          class='elevation-1'
          items={this.viewData}
          loading={this.$apollo.queries.selectionsKeys.loading || this.$apollo.queries.translations.loading}
          top={this.toolbar}
          search={this.search}
          sortBy={['entityType', 'key']}
          itemKey='key'
          footerProps={{
            showFirstLastPage: true,
          }}
          headers={
            [
              { value: 'entityType', text: 'Type', width: '155px', align: 'center' },
              { value: 'key', text: 'Key', width: '33%' },
              { value: 'enValue', text: 'English', width: '33%' },
              { value: 'value', text: 'Translation', width: '33%' }]}
          scopedSlots={{
            'top': this.toolbar,
            'item.value': this.valueColumn,
            'item.entityType': this.typeColumn,
          }}
        />
      </div>
    );
  },
});
