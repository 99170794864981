import Vue from 'vue';
import {
  VTextField,
  VIcon,
  VBtn,
  VCard,
  VChip,
  VProgressLinear,
} from 'vuetify/lib';
import './Watchlists.less';
import { Watchlist } from '@/components/Watchlist/Watchlist';
import { mapActions, mapGetters } from 'vuex';
import { hasRoles } from '@/utils/auth';
import { DEFAULT_TENNANT, SPORT, CASINO } from '@/config/watchlist';
import StreamWidget from '@/components/StreamWidget';


export const Watchlists = Vue.extend({
  data() {
    return {
      newWatchlistName: '',
      watchlistProduct: '', // watchlist belonging to sport or casino
      hasAddWatchlistError: false,
      isLoading: true,
      hasViewPermissionError: false,
      product: hasRoles(['sports:operator']) ? SPORT : hasRoles(['casino:operator']) ? CASINO : '',
      watchedPlayerUUIDs: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      watchlists: 'watchlists/watchlists',
    }),
  },

  methods: {
    ...mapActions({
      getWatchlists: 'watchlists/getWatchlists',
      addUserToWatchlist: 'watchlists/addUserToWatchlist',
      addNewWatchlist: 'watchlists/addNewWatchlist',
    }),
    async handleAdd() {
      try {
        await this.addNewWatchlist({
          tennant: DEFAULT_TENNANT,
          watchlistName: this.newWatchlistName,
          product: this.product,
        });
        this.newWatchlistName = '';
      } catch (err) {
        this.hasAddWatchlistError = true;
      }
    },
    watchOnStream(uuids: string[], enabled: boolean) {
      for (const uuid of uuids) {
        if (enabled) {
          this.watchedPlayerUUIDs.push(uuid);

          continue;
        }

        const index = this.watchedPlayerUUIDs.indexOf(uuid, 0);
        if (index > -1) {
          this.watchedPlayerUUIDs.splice(index, 1);
        }
      }
    },
  },
  async mounted() {
    try {
      await this.getWatchlists({
        tennant: DEFAULT_TENNANT,
        product: this.product,
      });
      this.isLoading = false;
    } catch (err) {
      this.hasViewPermissionError = true;
      this.isLoading = false;
    }
  },
  render() {
    const noWatchlistMessage = (
      <VCard class='pa-4'>
        No watchlists. Only <VChip>sports:operator</VChip> or <VChip>casino:operator</VChip>   can create
        watchlists.
      </VCard>
    );
    if (this.hasViewPermissionError) {
      return (
        <VCard color='orange' class='ma-4 pa-4 text-center'>
          Need permissions to view watchlists
        </VCard>
      );
    }
    const watchlistInputForm = (
      <div class='watchlist-input-container'>
        <VTextField
          vModel={this.newWatchlistName}
          placeholder='Create new watchlist'
          class='input'
          dense
          outlined
          error={this.hasAddWatchlistError}
          messages={
            this.hasAddWatchlistError ? 'Insufficient permissions' : null
          }
        />
        <VBtn
          onClick={this.handleAdd}
          icon
          disabled={!this.newWatchlistName.length}
        >
          <VIcon>add</VIcon>
        </VBtn>
      </div>
    );
    const sportWatchlists = this.watchlists.watchlists?.map(
      (watchlist: any) => {
        return (
          <Watchlist
            key={watchlist.watchlistName}
            watchlistName={watchlist.watchlistName}
            users={watchlist.users}
            on={{
              watchOnStream: this.watchOnStream,
            }}
          />
        );
      },
    );
    return (
      <div class='ma-4'>
        {hasRoles(['sports:operator', 'casino:operator']) && watchlistInputForm}
        {this.isLoading ? (
          <VProgressLinear indeterminate />
        ) : !this.watchlists.watchlists.length ? (
          noWatchlistMessage
        ) : [
          sportWatchlists,
          <div class='title'>Bet Update Stream - {this.watchedPlayerUUIDs.length} players</div>,
          this.watchedPlayerUUIDs.length ? <StreamWidget
            stream={{
              variant: 'terminal',
              sourceKey: 'sportsBetUpdated',
              filters: {
                filters: this.watchedPlayerUUIDs.map((uuid) => (   {
                  column: 'player.uuid',
                  operator: '==',
                  value: uuid,
                })),
                operator: 'or',
              },
            }}
          /> : null,
        ]}
      </div>
    );
  },
});
