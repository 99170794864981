import { MutationTree, ActionTree, GetterTree } from 'vuex';
import { LiabilitySettings } from '@/types/sports';
import { gql } from 'apollo-boost';
import _ from 'lodash';
import { valuesChanged, cloneOriginalValues } from '@/utils/object-values-tracker';

interface LiabilitySettingsState {
  inProgress: boolean;
  liabilitySettings?: LiabilitySettings;
}

const getters: GetterTree<LiabilitySettingsState, any> = {
  dataChanged(state) {
    if (!state.liabilitySettings) {
      return false;
    }
    return valuesChanged(state.liabilitySettings);
  },
};

const mutations: MutationTree<LiabilitySettingsState> = {
  SET_IN_PROGRESS(state) {
    state.inProgress = true;
  },

  CLEAR_IN_PROGRESS(state) {
    state.inProgress = false;
  },

  SET_LIABILITY_SETTINGS(state, data: LiabilitySettings) {
    if (!data.betPartitionsPerCategory.find((x) => x.key === 'virtual')) {
      data.betPartitionsPerCategory =
        [...data.betPartitionsPerCategory, { key: 'virtual', value: data.betPartitionsVirtual }];
    }

    if (!data.eventPlayerCapWithMultiplierPerCategory.find((x) => x.key === 'virtual')) {
      data.eventPlayerCapWithMultiplierPerCategory =
        [...data.eventPlayerCapWithMultiplierPerCategory, { key: 'virtual', value: data.virtualEventCap }];
    }

    if (!data.betPartitionsPerCategory.find((x) => x.key === 'simulated_reality_leagues')) {
      data.betPartitionsPerCategory =
        [...data.betPartitionsPerCategory, { key: 'simulated_reality_leagues', value: 0 }];
    }

    if (!data.eventPlayerCapWithMultiplierPerCategory.find((x) => x.key === 'simulated_reality_leagues')) {
      data.eventPlayerCapWithMultiplierPerCategory =
        [...data.eventPlayerCapWithMultiplierPerCategory, { key: 'simulated_reality_leagues', value: 0 }];
    }

    const orderedLiabilitySettings = {
      ...data,
      timecapsByGrade: data.timecapsByGrade.map((tbg) => {
        return {
          timeCaps: _.orderBy(tbg.timeCaps, (tc) => parseInt(tc.key, 10)),
        };
      }),
    };

    state.liabilitySettings = cloneOriginalValues(orderedLiabilitySettings) as LiabilitySettings;
  },
};

const actions: ActionTree<LiabilitySettingsState, any> = {
  async loadLiabilitySettings({ commit, rootState }) {
    commit('SET_IN_PROGRESS');

    try {
      const client = rootState.apolloClient();
      const response = await client.query({
        query: gql`
          query GetLiabilitySettings {
            liabilitySettings {
              updateTime
              betPartitionsPrematch
              betPartitionsLive
              eventPlayerCapWithMultiplier
              timecapsByGrade {
                timeCaps {
                  key
                  value
                }
              }
              outrightCaps {
                value
              }
              betPartitionsVirtual
              virtualEventCap
              betPartitionsPerCategory {
                key
                value
              }
              eventPlayerCapWithMultiplierPerCategory {
                key
                value
              }
            }
          }
        `,
      });

      commit('SET_LIABILITY_SETTINGS', response.data.liabilitySettings);
    } finally {
      commit('CLEAR_IN_PROGRESS');
    }
  },

  async updateLiabilitySettings({ commit, rootState, state }) {
    commit('SET_IN_PROGRESS');

    if (!state.liabilitySettings) {
      throw new Error('Can\'t update an empty state.');
    }

    const input = {
      betPartitionsPrematch:
        typeof state.liabilitySettings.betPartitionsPrematch === 'string'
          ? parseFloat(state.liabilitySettings.betPartitionsPrematch)
          : state.liabilitySettings.betPartitionsPrematch,
      betPartitionsLive:
        typeof state.liabilitySettings.betPartitionsLive === 'string'
          ? parseFloat(state.liabilitySettings.betPartitionsLive)
          : state.liabilitySettings.betPartitionsLive,
      betPartitionsVirtual:
        typeof state.liabilitySettings.betPartitionsVirtual === 'string'
          ? parseFloat(state.liabilitySettings.betPartitionsVirtual)
          : state.liabilitySettings.betPartitionsVirtual,
      eventPlayerCapWithMultiplier:
        typeof state.liabilitySettings.eventPlayerCapWithMultiplier === 'string'
          ? parseFloat(state.liabilitySettings.eventPlayerCapWithMultiplier)
          : state.liabilitySettings.eventPlayerCapWithMultiplier,
      virtualEventCap:
        typeof state.liabilitySettings.virtualEventCap === 'string'
          ? parseFloat(state.liabilitySettings.virtualEventCap)
          : state.liabilitySettings.virtualEventCap,
      timecapsByGrade: state.liabilitySettings.timecapsByGrade.map((tbg) => {
        return {
          timeCaps: tbg.timeCaps.map((tc) => ({
            key: tc.key,
            value:
              typeof tc.value === 'string' ? parseFloat(tc.value) : tc.value,
          })),
        };
      }),
      outrightCaps: state.liabilitySettings.outrightCaps.map((oc) => ({
        value:
          typeof oc.value === 'string' ? parseFloat(oc.value) : oc.value,
      })),
      betPartitionsPerCategory: state.liabilitySettings.betPartitionsPerCategory.map((partition) => {
        return {
          key: partition.key,
          value:
            typeof partition.value === 'string' ? parseFloat(partition.value) : partition.value,
        };
      }),
      eventPlayerCapWithMultiplierPerCategory:
        state.liabilitySettings.eventPlayerCapWithMultiplierPerCategory.map((cap) => {
          return {
            key: cap.key,
            value:
              typeof cap.value === 'string' ? parseFloat(cap.value) : cap.value,
          };
        }),
    };

    try {
      const client = rootState.apolloClient();
      const response = await client.mutate({
        mutation: gql`
          mutation UpdateLiabilitySettings($input: LiabilitySettingsInput!) {
            updateLiabilitySettings(input: $input) {
              updateTime
              betPartitionsPrematch
              betPartitionsLive
              eventPlayerCapWithMultiplier
              timecapsByGrade {
                timeCaps {
                  key
                  value
                }
              }
              outrightCaps {
                value
              }
              betPartitionsVirtual
              virtualEventCap
              betPartitionsPerCategory {
                key
                value
              }
              eventPlayerCapWithMultiplierPerCategory {
                key
                value
              }
            }
          }
        `,
        variables: {
          input,
        },
      });

      commit('SET_LIABILITY_SETTINGS', response.data.updateLiabilitySettings);
    } finally {
      commit('CLEAR_IN_PROGRESS');
    }
  },
};

export default {
  namespaced: true,
  state: {
    inProgress: false,
    liabilitySettings: undefined,
  } as LiabilitySettingsState,
  getters,
  mutations,
  actions,
};
