import Vue from 'vue';
import slugify from 'slugify';
import {
  VContainer,
  VTabs,
  VTab,
  VTabItem,
  VTabsSlider,
  VRow,
  VCol,
} from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import CompetitionStreamsWidget from '@/components/sports/CompetitionStreamsWidget';
import SocialFeedWidget from '@/components/sports/SocialFeedWidget';
import { createNamespacedHelpers } from 'vuex';
import { CompetitorTranslationTab } from './CompetitorTranslationTab/CompetitorTranslationTab';
import './Competition.less';
import { SingleCompetitionPrioritiesWidget } from '@/components/sports/CompetitionsPriorities';
import PinnacleOverlay from '@/components/sports/PinnacleOverlay';
import CompetitionHeadline from '@/components/sports/CompetitionHeadline';
import CompetitionsGrades from '@/components/sports/CompetitionsGrades';
import CompetitionProvidersLinking from '@/components/sports/CompetitionProvidersLinking';
import OutrightsPriorities from '@/components/sports/OutrightsPriorities';
const { mapState, mapActions } = createNamespacedHelpers('sports/competitions');

export default Vue.extend({
  name: 'competition',

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
    this.loadCurrentCompetition(this.competitionId);
  },

  computed: {
    ...mapState({
      currentCompetition: 'currentCompetition',
    }),

    competitionId(): string {
      return this.$route.params.competitionId.toString();
    },

    fullSEOKey(): string | undefined {
      const categoryMap = this.$store.state.sportsConsts.categoriesMap;
      if (categoryMap && this.currentCompetition) {
        const categoryKey = slugify(categoryMap[this.currentCompetition?.categoryId]).toLowerCase();
        const sportKey = this.currentCompetition.sportKey;
        const seoKey = this.currentCompetition.seoKey;
        return `${sportKey}-${categoryKey}-${seoKey}`.replaceAll('_', '-');
      }
    },
  },

  data() {
    return {
      tab: 'providersLinking',
    };
  },
  methods: {
    ...mapActions({
      loadCurrentCompetition: 'loadCurrentCompetition',
    }),
    tabs() {
      return [
        {
          requiresRoles: ['dataentry:operator', 'sports:operator'],
          key: 'providersLinking',
          name: 'Providers linking',
          content: (
            this.currentCompetition &&
            <div class='mt-4'>
              <CompetitionProvidersLinking
                competition={this.currentCompetition}
                competitionId={parseInt(this.competitionId, 10)}
              />
            </div>
          ),
        },
        {
          requiresRoles: ['dataentry:operator'],
          key: 'streams',
          name: 'Streams',
          content: (
            <CompetitionStreamsWidget competitionId={this.competitionId} />
          ),
        },
        {
          requiresRoles: ['dataentry:operator', 'localization:operator'],
          key: 'competitor-translations',
          name: 'Competitor Translations',
          content: (
            <CompetitorTranslationTab competitionId={Number(this.competitionId)} />
          ),
        },
        {
          requiresRoles: ['sports:operator', 'dataentry:operator'],
          key: 'priorities',
          name: 'Priorities',
          content: (
            <SingleCompetitionPrioritiesWidget competition={this.currentCompetition} />
          ),
        },
        {
          requiresRoles: ['dataentry:operator'],
          key: 'social-feeds',
          name: 'Social Feeds',
          content: (
            <SocialFeedWidget entityType='competition' entityKey={this.fullSEOKey} />
          ),
        },
        {
          requiresRoles: ['sports:operator'],
          key: 'pinnacle-overlay',
          name: 'Pinnacle Overlay',
          content: (
            <div>
              {this.currentCompetition &&
                <VRow>
                  <VCol cols='4' offset='4'>
                    <PinnacleOverlay entityId={this.currentCompetition.id} entityType={'competition'} />
                  </VCol>
                </VRow>
              }
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'grades',
          name: 'Grades',
          content: (
            <div>
              {this.currentCompetition &&
                <VRow>
                  <VCol md='8'>
                    <CompetitionsGrades competitionKey={this.currentCompetition.key} />
                  </VCol>
                </VRow>
              }
            </div>
          ),
        },
        {
          requiresRoles: ['sports:operator', 'sports:user'],
          key: 'outrights',
          name: 'Outrights',
          content: (
            <div>
              {this.currentCompetition &&
                <OutrightsPriorities competitionKey={this.currentCompetition.key} />
              }
            </div>
          ),
        },
      ];
    },
  },

  watch: {
    competitionId() {
      this.loadCurrentCompetition(this.competitionId);
    },
  },

  render() {
    return (
      <VContainer fluid>
        <CompetitionHeadline competitionId={parseInt(this.competitionId, 10)} />
        <VTabs v-model={this.tab}>
          <VTabsSlider></VTabsSlider>
          {this.tabs()
            .filter((tab) => hasRoles(tab.requiresRoles))
            .map((tab: any) => (
              <VTab to={'#' + tab.key} href={'#' + tab.key}>
                {tab.name}
              </VTab>
            ))}
          {this.tabs()
            .filter((tab) => hasRoles(tab.requiresRoles))
            .map((tab: any) => (
              <VTabItem value={tab.key}>{tab.content}</VTabItem>
            ))}
        </VTabs>
      </VContainer>
    );
  },
});
