import './SearchOverlay.less';

import Vue from 'vue';
import { VBtn, VCol, VIcon, VOverlay, VRow } from 'vuetify/lib';

import GlobalSearch from '../GlobalSearch';
import GlobalSearchNew from '../GlobalSearchTextOnly/GlobalSearchNew';
import { SearchInstructions } from '../SearchInstructions/SearchInstructions';

export const inputRegex = /^(?:input|textarea|select|button)$/i;

export default Vue.extend({
  data() {
    return {
      isOverlayActive: false,
      locale: '',
      enableFormSearch: false,
      isEscBlocked: false,
    };
  },
  mounted() {
    window.addEventListener('keydown', (event) => {
      if (event.key === '/') {
        const element = event.target as HTMLElement;
        if (element.id === 'search-bar' || !inputRegex.test(element.tagName)) {
          this.toggleOverlay();
        }
      }
      if (event.key === 'Escape') {
        if (this.isEscBlocked) {
          return;
        }
        if (this.isOverlayActive) {
          this.toggleOverlay();
        }
      }
    });
  },
  methods: {
    toggleOverlay() {
      this.isOverlayActive = !this.isOverlayActive;
    },
    blockEsc() {
      this.isEscBlocked = true;
    },
    unBlockEsc() {
      this.isEscBlocked = false;
    },
    setLocale(val: string) {
      this.locale = val;
    },
    fireBackslash() {
      const el = document.getElementById('overlay-for-search');
      const event = new KeyboardEvent('keydown', {
        key: '/',
        bubbles: true,
      });
      el?.dispatchEvent(event);
    },
  },
  render() {
    return (
      <VOverlay
        class='search-overlay overlay-container'
        value={this.isOverlayActive}
        opacity={1}
      >
        <VRow>
          <VCol cols='2' md='1' class='text-center left-column'>
            <VBtn fab small onClick={this.toggleOverlay}>
              <VIcon>close</VIcon>
            </VBtn>
          </VCol>
          <VCol cols='10' md='11'>
            <GlobalSearchNew
              enableEscOverride={this.blockEsc}
              unBlockEsc={this.unBlockEsc}
            />
            <SearchInstructions />
            <VBtn
              class='mt-4 mb-4'
              onClick={() => (this.enableFormSearch = !this.enableFormSearch)}
            >
              {this.enableFormSearch
                ? 'Hide form search'
                : 'Enable form search'}
            </VBtn>
            {this.enableFormSearch && (
              <div>
                <GlobalSearch />
              </div>
            )}
          </VCol>
        </VRow>
      </VOverlay>
    );
  },
});
