/** @file define module for preferences */
import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface Preferences {
  nightMode: boolean;
  terminalModeEnabled: boolean;
}

const getters: GetterTree<Preferences, any> = {
  isNightMode(state): boolean {
    if (state.nightMode) {
      return true;
    }
    return false;
  },
  terminalModeEnabled(state): boolean {
    if (state.terminalModeEnabled) {
      return true;
    }
    return false;
  },
};

const mutations: MutationTree<Preferences> = {
  SET_NIGHT_MODE(state, nightMode) {
    if (nightMode) {
      state.nightMode = true;
      return;
    }
    state.nightMode = false;
  },
  SET_TERMINAL_MODE_ENABLED(state, terminalMode) {
    if (terminalMode) {
      state.terminalModeEnabled = true;
      return;
    }
    state.terminalModeEnabled = false;
  },
};

const actions: ActionTree<Preferences, any> = {
  async setNightMode({ commit }, nightMode) {
    commit('SET_NIGHT_MODE', nightMode);
  },
  async setTerminalModeEnabled({ commit }, terminalModeEnabled) {
    commit('SET_TERMINAL_MODE_ENABLED', terminalModeEnabled);
  },
};

const Preferences = {
  namespaced: true,
  state: { nightMode: false, terminalModeEnabled: true } as Preferences,
  getters,
  mutations,
  actions,
};

export default Preferences;
