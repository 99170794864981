import Vue from 'vue';
import { createNamespacedHelpers, mapGetters } from 'vuex';
import {
  VBtn,
  VToolbar,
  VSpacer,
  VSnackbar,
  VRow,
  VCol,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { successSnackbarConfig, failureSnackbarConfig } from './helpers';
import _ from 'lodash';
import './EventMarkets.less';
import MarketTable from './MarketTable';
import { Socket } from 'phoenix';
import { env } from '@/env';
import MarketCreator from './MarketCreator';
import { ExchangeMarket } from './types';
import { refreshToken } from '@/apollo_links/token-link';
import { hasRoles } from '@/utils/auth';
import { camelise } from '@/utils/objects';
import { marketName } from '../markets-names';

const { mapActions, mapState } = createNamespacedHelpers('sports/events');

export default Vue.extend({
  props: {
    eventId: Number,
  },

  data() {
    return {
      successMessage: '',
      successSnackbar: false,
      failureMessage: null as JSX.Element | null,
      failureSnackbar: false,
      socket: null as Socket | null,
      markets: [] as ExchangeMarket[],
      displayMarketCreator: false,
    };
  },

  methods: {
    ...mapActions(['loadCurrentEvent']),

    showFailure(message: string | string[], error: Error) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;

      this.$rollbar.error(message, error, { email: this.email });
    },

    showSuccess(message: string) {
      this.successMessage = message;
      this.successSnackbar = true;
    },

    onMarketCreated() {
      this.displayMarketCreator = false;
      this.showSuccess('Market has been created.');
      this.$apollo.queries.markets.refresh();
    },

    initSocket(initTries = 0) {
      this.socket = new Socket(env.sportsExchange.wsUri, { params: { token: this.token } });
      this.socket.onError(async (reason) => {
        // Try refreshing a token a few times.
        // If it doesn't help then stop trying (it must be sth different than an expired token).
        if (initTries < 3) {
          this.socket?.disconnect();
          this.socket = null;
          await refreshToken();
          setTimeout(() => { this.initSocket(initTries + 1); }, 1000);
        } else {
          this.showFailure('Websocket connection error', reason);
        }
      });

      this.socket.connect();

      this.socket.onOpen(() => {
        initTries = 0;

        const eventChannel = this.socket?.channel(`event:${this.eventId}`, { token: this.token });
        eventChannel?.onError((reason) => {
          this.showFailure(`Event channel error: ${reason}`, reason);
        });
        eventChannel?.on('market-added', ({ data }) => {
          this.markets = [...this.markets, camelise(data)];
        });

        eventChannel?.join()
          .receive('ok', (msg) => {
            this.showSuccess('Joined the event channel');
          })
          .receive('error', ({ reason }) => {
            this.showFailure(`Joining event channel error: ${reason}`, reason);
          })
          .receive('timeout', ({ reason }) => {
            this.showFailure('Joining even channel timeout. Still waiting...', reason);
          });
      });
    },
  },

  computed: {
    ...mapState(['currentEvent']),
    ...mapGetters({ token: 'auth/idToken', email: 'auth/email' }),

    toolbar() {
      return (
        <VToolbar dense>
          <VSpacer />
          {hasRoles(['sports:operator']) &&
            <div>
              <VBtn
                color='default'
                small
                class='ml-2'
                onClick={() => { this.displayMarketCreator = !this.displayMarketCreator; }}>
                Market creator
              </VBtn>
            </div>}
        </VToolbar>
      );
    },
  },

  mounted() {
    this.loadCurrentEvent(Number(this.eventId));

    this.initSocket();
  },

  apollo: {
    markets: {
      query: gql`query($eventId: Int!) {
          sportsExchangeEventMarkets(eventId: $eventId) {
          effectiveTo
          eventId
          marketKey
          marketParams
          state
        }
      }`,

      variables(): { eventId: number } {
        return {
          eventId: this.eventId,
        };
      },

      update(data) {
        return data.sportsExchangeEventMarkets;
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <div class='event-markets'>
        <VRow>
          <VCol>
            {this.toolbar}
          </VCol>
        </VRow>
        <VSnackbar {...failureSnackbarConfig(<div>{this.failureMessage}</div>)} vModel={this.failureSnackbar} />
        <VSnackbar {...successSnackbarConfig(this.successMessage)} vModel={this.successSnackbar} />
        {this.displayMarketCreator &&
          <MarketCreator
            event={this.currentEvent}
            onSaved={this.onMarketCreated}
            onCancelled={() => this.displayMarketCreator = false} />
        }
        <VExpansionPanels value={this.markets.length === 1 ? 0 : undefined}>
          {this.markets.map((m) => {
            return (
              <VExpansionPanel>
                <VExpansionPanelHeader>
                  {marketName({ marketParams: m.marketParams, marketKey: m.marketKey }, this.currentEvent)}
                </VExpansionPanelHeader>
                <VExpansionPanelContent>
                  <MarketTable
                    marketKey={m.marketKey}
                    marketParams={m.marketParams}
                    effectiveTo={m.effectiveTo}
                    event={this.currentEvent}
                    socket={this.socket} />
                </VExpansionPanelContent>
              </VExpansionPanel>
            );
          })}
        </VExpansionPanels>
      </div >
    );
  },
});
