import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VDataTable } from 'vuetify/lib';
import { RouterLink } from '@/router';

interface DepositAddress {
  address: string;
  player_uuid: string;
}

export default Vue.extend({
  props: {
    address: String,
  },

  data() {
    return {
      isLoading: true,
      depositAddress: [] as DepositAddress[],
      headers: [
        { text: 'Deposit address', value: 'address' },
        { text: 'Player UUID', value: 'player_uuid' },
      ],
    };
  },

  async mounted() {
    await this.fetchDepositAddress();
  },

  methods: {
    async fetchDepositAddress(): Promise<any> {
      const response = await this.$apollo.query({
        query: gql`
          query($address: String!) {
            depositAddress(address: $address) {
              player_uuid
              address
            }
          }`,
        variables: { address: this.address },
        fetchPolicy: 'network-only',
      });
      if (response.data.depositAddress) {
        this.depositAddress = [response.data.depositAddress];
      }
      this.isLoading = false;
    },
  },

  render() {
    return (
      <div>
        <VDataTable
          class='elevation-1'
          headers={this.headers}
          items={this.depositAddress}
          loading={this.isLoading}
          loading-text='Searching... Please wait'
          hide-default-footer='true'
          scopedSlots={{
            'item.player_uuid': ({ item }: { item: DepositAddress }) => (
              <RouterLink
                class='text-decoration-none'
                to={{ name: 'player', params: { playerUUID: item.player_uuid }, hash: '#player-transactions' }}
              >
                {item.player_uuid}
              </RouterLink>
            ),
          }}
        />
      </div>
    );
  },
});
