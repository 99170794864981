import Pusher from 'pusher-js';
import { VueConstructor } from 'vue/types/umd';
import { env } from '@/env';


/** @description Create plugin for global instance of pusher */
export default {
  install: (Vue: VueConstructor) => {
    const pusher = new Pusher(`${env.pusher.apiKey}`, {
      cluster: `${env.pusher.cluster}`,
    });
    Vue.prototype.$pusher = pusher;
  },
};
