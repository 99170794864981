import { ActionTree, GetterTree, MutationTree } from 'vuex';
import firebase from 'firebase';

interface Watchlists {
  watchlists: Watchlist[];
}

interface User {
  name: string;
}

interface Watchlist {
  watchlistName: string;
  users: Record<string, User>;
}

interface AddUserToWatchlistArgProps {
  uuid: string;
  nickname: string;
  tennant: string;
  watchlistName: string;
  product: string;
}

interface GetWatchlistContentProps {
  tennant: string;
  watchlistName: string;
  product: string;
}

interface DeleteUserFromWatchlistProps {
  tennant: string;
  watchlistName: string;
  product: string;
  playerUUID: string;
}

const getters: GetterTree<Watchlists, any> = {
  watchlists(state: Watchlists): Watchlists {
    return state;
  },
};

const mutations: MutationTree<Watchlists> = {
  UPDATE_WATCHLIST(state, watchlists) {
    state.watchlists = watchlists;
  },
};

const actions: ActionTree<Watchlists, any> = {
  async addNewWatchlist(
    { commit, rootState },
    { tennant, watchlistName, product },
  ) {
    const db: firebase.firestore.Firestore = rootState.db();
    try {
      await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .doc(watchlistName)
        .set({}, { merge: true });

      const ref = await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .get();

      const watchlists: Watchlist[] = [];
      for (const doc of ref.docs) {
        const refData = doc.data();
        watchlists.push({
          watchlistName: doc.id,
          users: refData,
        });
      }
      commit('UPDATE_WATCHLIST', watchlists);
    } catch (err) {
      throw err;
    }
  },

  async deleteWatchlist(
    { commit, rootState },
    { tennant, watchlistName, product },
  ) {
    const db: firebase.firestore.Firestore = rootState.db();
    try {
      await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .doc(watchlistName)
        .delete();

      const ref = await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .get();

      const watchlists: Watchlist[] = [];
      for (const doc of ref.docs) {
        const refData = doc.data();
        watchlists.push({
          watchlistName: doc.id,
          users: refData,
        });
      }
      commit('UPDATE_WATCHLIST', watchlists);
    } catch (err) {
      throw err;
    }
  },

  async getWatchlists(
    { commit, rootState },
    { tennant, product }: GetWatchlistContentProps,
  ) {
    const db: firebase.firestore.Firestore = rootState.db();
    try {
      const ref = await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .get();

      const watchlists: Watchlist[] = [];
      for (const doc of ref.docs) {
        const refData = doc.data();
        watchlists.push({
          watchlistName: doc.id,
          users: refData,
        });
      }
      commit('UPDATE_WATCHLIST', watchlists);
    } catch (err) {
      throw err;
    }
  },

  async deleteUserFromWatchlist(
    { commit, rootState },
    {
      watchlistName,
      tennant,
      product,
      playerUUID,
    }: DeleteUserFromWatchlistProps,
  ) {
    const db: firebase.firestore.Firestore = rootState.db();
    try {
      await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .doc(watchlistName)
        .update({
          [playerUUID]: firebase.firestore.FieldValue.delete(),
        });

      const ref = await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .get();

      const watchlists: Watchlist[] = [];
      for (const doc of ref.docs) {
        const refData = doc.data();
        watchlists.push({
          watchlistName: doc.id,
          users: refData,
        });
      }
      commit('UPDATE_WATCHLIST', watchlists);
    } catch (err) {
      throw err;
    }
  },

  async addUserToWatchlist(
    { commit, rootState },
    {
      uuid,
      tennant,
      watchlistName,
      nickname,
      product,
    }: AddUserToWatchlistArgProps,
  ) {
    const db: firebase.firestore.Firestore = rootState.db();

    try {
      await db
        .collection('watchlists')
        .doc(`${tennant}`)
        .collection(product)
        .doc(watchlistName)
        .set(
        {
          [uuid]: { name: nickname },
        },
          { merge: true },
        );
      const ref = await db
        .collection('watchlists')
        .doc(tennant)
        .collection(product)
        .get();

      const watchlists: Watchlist[] = [];
      for (const doc of ref.docs) {
        const refData = doc.data();
        watchlists.push({
          watchlistName: doc.id,
          users: refData,
        });
      }
      commit('UPDATE_WATCHLIST', watchlists);
    } catch (err) {
      throw err;
    }
  },
};

const Watchlists = {
  namespaced: true,
  state: { watchlists: [] } as Watchlists,
  getters,
  mutations,
  actions,
};

export default Watchlists;
