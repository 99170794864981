import Vue, { VueConstructor } from 'vue';
import {
  VRow,
  VCol,
  VCard,
  VCardText,
  VCardTitle,
  VChip,
  VSkeletonLoader,
  VBtn,
  VDataTable,
  VSelect,
  VTextarea,
  VContainer,
  VTextField,
  VIcon,
} from 'vuetify/lib';
import { unixToDateTimeUTCString } from '@/utils/date';
import { RouterLink } from '@/router';
import { BET_SELECTIONS_QUERY, SportsBetSelection, SportsBetSelections } from './queries';
import { gql } from 'apollo-boost';
import { hasRoles } from '@/utils/auth';
import { env } from '@/env';
import DoubleConfirmWithCommentBtn from '@/components/generic/DoubleConfirmWithCommentBtn';
import { marketName, selectionName } from '../markets-names';
import { DataTableHeader } from 'vuetify';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import ResultDialog, { Result } from '../InContextHelpGradingResults/ResultDialog';
import errorHandlerMixin from '../errorHandlerMixin';


interface MetaValue {
  value: string;
  className: string;
}

interface SportsBetSelectionsExt extends SportsBetSelections {
  selections: SportsBetSelectionExt[];
}

interface SportsBetSelectionExt extends SportsBetSelection {
  _result: string;
  _payoutsNumber: number;
  _tieWinnersNumber: number;
}

const parseMetadata = (metastring: string, eventId: number) => {
  const jsonMeta: Record<string, string> = JSON.parse(metastring);
  jsonMeta.bgl_event_id = eventId.toString();

  const meta: Record<string, MetaValue> =
    Object.fromEntries(Object.entries(jsonMeta)
      .map(([k, v]) => ([k, { value: v, className: `meta-${k}` }])));

  if (meta.overround?.value === '1.0') {
    meta.overround.className += ' zero-margin';
  }
  return meta;
};

const storeOriginalValues = (obj: Record<string, any>, fields: string[]): Record<string, any> => {
  obj = { ...obj };
  for (const field of fields) {
    obj['_' + field] = obj[field];
  }
  return obj;
};

const extendSportsBet = (sportsBet: SportsBetSelections): SportsBetSelectionsExt => {
  return {
    ...sportsBet,
    selections: sportsBet.selections?.map((s) => {
      const extSelection = storeOriginalValues(s, ['result']) as SportsBetSelectionExt;
      extSelection._payoutsNumber = 1;
      extSelection._tieWinnersNumber = 1;
      return extSelection;
    }),
  };
};

export const resultsList = ['win', 'half_win', 'push', 'half_loss', 'loss'];
const UNGRADE_OPTION = 'ungrade';
const DOUBLE_CHANCE_MARKET = '.double_chance';

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],

  props: {
    betUUID: String, // can be id or uuid
    returnBetFields: String,
    translations: {
      type: Object as () => Record<string, string>,
      default: () => ({}),
    },
  },

  data() {
    return {
      sportsBet: {} as SportsBetSelectionsExt,
      comment: '',
      isUpdating: false,
      selectionToRegrade: null as SportsBetSelection | null,
    };
  },

  watch: {
    betUUID() {
      this.sportsBet = {} as SportsBetSelectionsExt;
    },
  },

  methods: {
    async ungradeEvent(eventId: string, comment: string) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation Mutation($eventId: String!, $comment: String!) {
              ungradeEvent(eventID: $eventId, comment: $comment) {
                success
              }
            }
          `,
          variables: {
            eventId,
            comment,
          },
        });
        return result.data.ungradeEvent.success;
      } catch (e) {
        throw e;
      }
    },
    async ungradeEventMarket(eventId: string, marketKey: string, comment: string) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation UngradeEventMarket($eventId: String!, $marketKey: String!, $comment: String!) {
              ungradeEventMarket(eventID: $eventId, marketKey: $marketKey, comment: $comment) {
                success
              }
            }
          `,
          variables: {
            eventId,
            marketKey,
            comment,
          },
        });
        return result.data.ungradeEventMarket.success;
      } catch (e) {
        throw e;
      }
    },
    async ungradeEventMarketSelection(
      eventId: string, marketKey: string, params: string, outcome: string, comment: string,
    ) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation SubmitEventResults($eventId: String!, $input: EventResultsList) {
              submitEventResults(eventID: $eventId, input: $input) {
                success
              }
            }
          `,
          variables: {
            eventId,
            input: {
              results: [
                {
                  result: 'PENDING',
                  marketKey,
                  params,
                  outcome,
                  comment,
                },
              ],
            },
          },
        });

        return result.data.submitEventResults.success;
      } catch (e) {
        throw e;
      }
    },

    sportName(selection: SportsBetSelection) {
      return getSportsName(selection.sportKey, Locale.en);
    },

    competitionName(selection: SportsBetSelection) {
      return selection.event?.competition?.name ||
        selection.outright?.competition?.name;
    },

    eventName(selection: SportsBetSelection) {
      return selection.outright?.name || selection.event?.name;
    },

    eventNameWithLink(selection: SportsBetSelection) {
      const name = this.eventName(selection);
      return (
        <div>
          <small class='grey--text'>
            {this.sportName(selection)} - {this.competitionName(selection)}
          </small>
          <div>
            <RouterLink
              class='text-decoration-none'
              to={{ name: 'event', params: { eventId: selection.eventId } }}>
              {name}
            </RouterLink>
          </div>
          <div>
            {selection.event ?
              <small class='grey--text'>
                Starts at: {unixToDateTimeUTCString(selection.event?.startsAt)}
              </small>
              :
              <small class='grey--text'>
                Finishes at: {unixToDateTimeUTCString(selection.outright?.finishesAt)}
              </small>
            }
          </div>
        </div>
      );
    },

    marketName(selection: SportsBetSelection) {
      return marketName(
        { marketParams: selection.specialBetValue, marketKey: selection.marketKey },
        selection.event || selection.outright);
    },

    selectionName(selection: SportsBetSelection) {
      return selectionName({
        marketParams: selection.specialBetValue,
        marketKey: selection.marketKey,
        selectionName: selection.name,
      }, selection.event || selection.outright, this.translations);
    },

    async saveResults() {
      try {
        this.isUpdating = true;
        const res = await this.$apollo.mutate({
          mutation: gql`mutation ($uuid: String!, $comment: String!, $selectionsResults: [SportsBetSelectionsResults!]!) {
            regradeSportsBetSelections(uuid: $uuid, comment: $comment, selectionsResults: $selectionsResults) {
              ${this.returnBetFields}
            }
          }`,
          variables: {
            uuid: this.sportsBet.uuid,
            comment: this.comment,
            selectionsResults: this.selectionsResults(),
          },
          fetchPolicy: 'no-cache',
        });
        this.$emit('changed', res.data.regradeSportsBetSelections);
        this.$apollo.queries.sportsBet.refresh();
      } catch (e) {
        throw e;
      } finally {
        this.isUpdating = false;
      }
    },

    cancelChanges() {
      this.sportsBet.selections = this.sportsBet.selections.map((s) => ({ ...s, result: s._result }));
    },

    selectionsResults() {
      return this.sportsBet.selections
        .filter((s: SportsBetSelectionExt) => s._result !== s.result)
        .map((s) => ({
          selectionId: s.id,
          result: s.result,
          payoutsNumber: Number(s._payoutsNumber),
          tieWinnersNumber: Number(s._tieWinnersNumber),
        }));
    },

    async ungradeSelection(selectionId: number, comment: string) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($uuid: String!, $selectionId: Int!, $comment: String!) {
              ungradeSportsBetSelection(uuid: $uuid, selectionId: $selectionId, comment: $comment) {
                ${this.returnBetFields}
              }
            }
          `,
          variables: {
            uuid: this.sportsBet.uuid,
            selectionId,
            comment,
          },
        });

        this.$emit('changed', result.data.ungradeSportsBetSelection);
        this.$apollo.queries.sportsBet.refresh();
        return true;
      } catch (e) {
        throw e;
      }
    },
  },

  computed: {
    selections() {
      const headers: DataTableHeader[] = [
        { text: '', value: 'data-table-expand', width: 50, align: 'center' },
        { text: 'Event', value: 'event' },
        { text: 'Live', value: 'live' },
        { text: 'Provider', value: 'provider', align: 'center' },
        { text: 'Provider id', value: 'externalId' },
        { text: 'Market', value: 'market' },
        { text: 'Name', value: 'name' },
        { text: 'SBV', value: 'specialBetValue' },
        { text: 'Odds', value: 'odds' },
        { text: 'Closing price', value: 'closingPrice' },
        { text: 'Result', value: 'result', width: '160px' }];

      return (
        <VCard elevation={3} class='mb-1'>
          <VCardTitle>Selections</VCardTitle>
          <VCardText>
            <VDataTable
              class='elevation-1'
              headers={headers}
              items={this.sportsBet.selections || []}
              itemKey='id'
              loading-text='Loading... Please wait'
              loading={this.$apollo.queries.sportsBet.loading}
              disablePagination={true}
              disableSort={true}
              hideDefaultFooter={true}
              showExpand={true}
              singleExpand={false}
              scopedSlots={{
                'item.event': ({ item }: { item: SportsBetSelection }) => {
                  return this.eventNameWithLink(item);
                },
                'item.live': ({ item }: { item: SportsBetSelection }) => {
                  return item.isLive ? <VChip label x-small color='success'>live</VChip> : '';
                },
                'item.provider': ({ item }: { item: SportsBetSelection }) => {
                  return (
                    <div>
                      <div>
                        {item.externalProvider}
                      </div>
                      <small class='grey--text'>
                        {JSON.parse(item.hedgingProviders)[0]?.provider}
                      </small>
                    </div>
                  );
                },
                'item.market': ({ item }: { item: SportsBetSelection }) => {
                  return this.marketName(item);
                },
                'item.name': ({ item }: { item: SportsBetSelection }) => {
                  return this.selectionName(item);
                },
                'item.closingPrice': ({ item }: { item: SportsBetSelection }) => {
                  if (item.isLive || item.marketKey.includes(DOUBLE_CHANCE_MARKET) || item.closingPrice === 0) {
                    return '';
                  }
                  return (
                    <span class={{ 'red--text': item.closingPrice < item.odds }}>
                      {item.closingPrice}
                    </span>
                  );
                },
                'item.odds': ({ item }: { item: SportsBetSelection }) => {
                  return (
                    <div>
                      <span>{item.odds}</span><br />
                      {item.adjustedPlacedOdds !== 0 &&
                        item.odds !== item.adjustedPlacedOdds &&
                        <span class='warning--text'>{item.adjustedPlacedOdds}</span>
                      }
                    </div>
                  );
                },
                'item.result': ({ item }: { item: SportsBetSelectionExt }) => {
                  if (this.canBeRegraded) {
                    const backColor = item._result === item.result ? 'default' : 'warning';
                    const displayParams = item.result !== item._result && item.result === 'win';

                    return (
                      <div>
                        <VSelect
                          class={{ 'mt-6': displayParams, 'mb-6': displayParams }}
                          items={this.resultsList}
                          dense
                          solo
                          background-color={backColor}
                          style='height: 40px;'
                          vModel={item.result} />
                        {displayParams &&
                          <div>
                            <VTextField
                              label='Payouts'
                              v-numeric-input
                              dense
                              outlined
                              step='1'
                              vModel={item._payoutsNumber} />
                            <VTextField
                              label='Tie winners'
                              v-numeric-input
                              dense
                              outlined
                              step='1'
                              vModel={item._tieWinnersNumber} />
                          </div>
                        }
                      </div>
                    );
                  } else {
                    return item.result;
                  }

                },
                'expanded-item': ({ item }: { item: SportsBetSelection }) => {
                  const meta =
                    Object.entries(parseMetadata(item.metadata, item.eventId))
                      .map(([k, v]) => {
                        return (<span class={v.className}>{k}: {v.value}</span>);
                      });

                  return (
                    <td class='px-4 py-2' colspan='100'>
                      <VContainer fluid>
                        <VRow>
                          <VCol>
                            <span><strong>Meta: </strong></span>
                            {meta.reduce((prev, curr): any => [prev, ', ', curr])}
                          </VCol>
                        </VRow>
                        {
                          hasRoles(['sports:operator']) &&
                          <VRow>
                            <VCol>
                              <strong>
                                Ungrade all bets selections with same:
                              </strong>
                            </VCol>
                            <VCol class='text-center'>
                              <DoubleConfirmWithCommentBtn
                                btnColor='primary'
                                btnMsg={'Selection'}
                                warningMsg={`This will insert admin pending results for ${this.eventName(item)} - ${this.marketName(item)} - ${this.selectionName(item)}`}
                                confirmAction={(comment: string) => {
                                  return this.ungradeEventMarketSelection(
                                    item.eventId.toString(),
                                    item.marketKey,
                                    item.specialBetValue,
                                    item.name,
                                    comment);
                                }}
                              />
                            </VCol>
                            <VCol class='text-center'>
                              <DoubleConfirmWithCommentBtn
                                btnColor='orange'
                                btnMsg={'Market'}
                                warningMsg={`This will insert admin pending results for ${this.eventName(item)} - ${this.marketName(item)}`}
                                confirmAction={(comment: string) => {
                                  return this.ungradeEventMarket(
                                    item.eventId.toString(),
                                    item.marketKey,
                                    comment);
                                }}
                              />
                            </VCol>
                            <VCol class='text-center'>
                              <DoubleConfirmWithCommentBtn
                                btnColor='red'
                                btnMsg={'Event'}
                                warningMsg={`This will insert admin pending results for ${this.eventName(item)}`}
                                confirmAction={(comment: string) => {
                                  return this.ungradeEvent(item.eventId.toString(), comment);
                                }}
                              />
                            </VCol>
                          </VRow>
                        }
                        {hasRoles(['cs:operator', 'sports:operator']) && env.feature.enableCSGrading &&
                          <VRow>
                            <VCol cols='3'>
                              <strong>
                                Suggest a result and ask for a review:
                              </strong>
                            </VCol>
                            <VCol cols='3' class='text-center'>
                              <VBtn color='primary' onClick={() => { this.selectionToRegrade = item; }}>
                                Add result
                              </VBtn>
                            </VCol>
                          </VRow>
                        }
                      </VContainer>
                    </td>
                  );
                },
              }}>
            </VDataTable>
            {this.resultsChanged &&
              <div>
                <VRow class='text-right mt-3'>
                  <VCol>
                    <VTextarea
                      vModel={this.comment}
                      label='Comment'
                      outlined
                      rows={3}
                      placeholder='Add a comment'
                      persistent-placeholder={true} />
                  </VCol>
                </VRow>

                <VRow class='text-right'>
                  <VCol>
                    <VBtn onClick={this.cancelChanges}>Cancel changes</VBtn>
                    <VBtn color='warning'
                      class='ml-2'
                      onClick={this.saveResults}
                      disabled={!this.changesCanBeSaved}
                      loading={this.isUpdating}>
                      Save changes
                    </VBtn>
                  </VCol>
                </VRow>
              </div>
            }
          </VCardText>
        </VCard>
      );
    },

    resultsChanged(): boolean {
      return this.sportsBet.selections?.
        some((s: SportsBetSelectionExt) => s._result !== s.result && s.result !== UNGRADE_OPTION);
    },

    selectionToUngrade(): SportsBetSelectionExt | undefined {
      return this.sportsBet.selections?.
        find((s: SportsBetSelectionExt) => s._result !== s.result && s.result === UNGRADE_OPTION);
    },

    canBeRegraded(): boolean {
      return hasRoles(['sports:operator']) &&
        this.sportsBet.manuallyRegradable &&
        this.sportsBet.state !== 'rejected';
    },

    changesCanBeSaved(): boolean {
      return this.comment !== '';
    },

    resultsList() {
      const results = resultsList.map((result) => ({ value: result, text: result, divider: false }));
      if (this.sportsBet.state === 'completed') {
        results.push(
          { value: '', text: '', divider: true },
          { value: UNGRADE_OPTION, text: UNGRADE_OPTION, divider: false });
      }
      return results;
    },

    ungradeSelectionDialog() {
      const selection = this.selectionToUngrade;
      if (selection) {
        return (
          <DoubleConfirmWithCommentBtn
            warningMsg={`This will ungrade the selection
                                  ${this.eventName(selection)} -
                                  ${this.marketName(selection)} -
                                  ${this.selectionName(selection)}`}
            confirmAction={(comment: string) => {
              return this.ungradeSelection(selection.id, comment);
            }}
            btnMsg={'Ungrade selection'}
            autoDisplay={true}
            onCancel={() => { selection.result = selection._result; }}>
            <span></span>
          </DoubleConfirmWithCommentBtn>
        );
      }
    },

    resultDialog() {
      if (this.selectionToRegrade) {
        const result: Result = {
          eventId: this.selectionToRegrade.eventId,
          marketKey: this.selectionToRegrade.marketKey,
          outcome: this.selectionToRegrade.name,
          params: this.selectionToRegrade.specialBetValue,
        };
        return (
          <ResultDialog result={result}
            onCancelled={() => { this.selectionToRegrade = null; }}
            onResultAdded={() => {
              this.showSuccessMessage(
                <div>
                  <p>
                    The result has been created and forwarded to a review.
                  </p>
                  <RouterLink class='text-decoration-none'
                    to={{ name: 'event', params: { eventId: this.selectionToRegrade?.eventId }, hash: '#cs-grading' }}>
                    Open the grading page
                  </RouterLink>
                </div>);
              this.selectionToRegrade = null;
            }} />
        );
      }
    },
  },

  apollo: {
    sportsBet: {
      query: BET_SELECTIONS_QUERY,

      variables(): { uuid: string } {
        return {
          uuid: this.betUUID,
        };
      },

      update(data) {
        return extendSportsBet(data.sportsBet);
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    // Don't display skeleton for refresh
    if (this.sportsBet.selections === undefined && this.$apollo.queries.sportsBet.loading) {
      return (
        <VSkeletonLoader
          type='card-heading, image, card-heading, table-row-divider@2, card-heading, table-row-divider@2' />
      );
    }

    return (
      <div class='bet-details'>
        {this.successSnackbarWithConfig({ timeout: -1, color: '' })}
        {this.resultDialog}
        {this.ungradeSelectionDialog}
        {this.selections}
      </div>
    );
  },
});
