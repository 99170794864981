import scoreTypes from '@/assets/sports-core/config/en-score-types.json';

interface Score {
  Period: string;
  Name: string;
  Description: string;
}


interface ScoreTypes {
  [key: string]: Score[] | undefined;
}

const sportScoreTypes = (sportKey: string) => {
  const scores = scoreTypes as ScoreTypes;
  if (!scores[sportKey]) {
    return [];
  }
  return scores[sportKey]!;
};

export { sportScoreTypes };
