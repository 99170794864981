import BigNumber from 'bignumber.js';

export interface FormatCurrencyOptions {
  name?: string;
  adjustPrecision?: boolean;
  prependName?: boolean;
}

const adjustPrecisionToValue = (value: number, precision: number) => {
  if (Number(value) === 0) { return precision; }
  const factor = 1 / (Math.abs(value) * Math.pow(10, precision));
  if (factor > 1) {
    const roundedFactor = Math.round(factor);
    const correction = factor === roundedFactor ? 1 : 0;
    return precision + roundedFactor.toString().length - correction;
  } else {
    return precision;
  }
};

export function formatCurrency(value: any, precision: number | null, options: FormatCurrencyOptions = {}): string {
  value = Number.parseFloat(value);
  if (options.adjustPrecision && precision) {
    precision = adjustPrecisionToValue(value, precision);
  }
  const isNegative = value < 0;

  if (isNegative) {
    value = -value;
  }

  if (precision === null) {
    value = new BigNumber(value).toNumber().toLocaleString('us-EN');
  } else {
    value = value.toFixed(precision);
    value = new BigNumber(value).toFormat(precision || 0, {
      decimalSeparator: '.',
      groupSeparator: ',',
      groupSize: 3,
    });
  }

  value = `${value}`;

  if (isNegative) {
    value = `(${value})`;
  }

  if (options.name) {
    if (options.prependName) {
      return `${options.name} ${value}`;
    } else {
      return `${value} ${options.name}`;
    }
  }

  return value;
}
