import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VRow, VCol, VBtn, VIcon, VSnackbar, VProgressLinear, VLabel } from 'vuetify/lib';
import { mapGetters } from 'vuex';
import { formatCurrency } from '@/filters';
import currenciesConfig from '@/config/currencies';
import { failureSnackbarConfig, successSnackbarConfig } from './helpers';
import { Event } from '@/types/sports';

import './Orders.less';
import { iso8601ToDateTimeUTCString } from '@/utils/date';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import _ from 'lodash';
import { ExchangeSelection, ExchangeMarket } from './types';
import { selectionName, marketName } from '../markets-names';

interface Order {
  createdAt: string;
  unmatchedSize: string;
  marketKey: string;
  marketParams: string;
  price: string;
  selectionName: string;
  side: string;
  size: string;
  uuid: string;
}

const formatAmount = (amount: number) => {
  return formatCurrency(
    amount,
    currenciesConfig.EUR?.simple?.dp || 4,
    { adjustPrecision: true });
};

export default Vue.extend({
  props: {
    event: Object as () => Event,
    market: Object as () => ExchangeMarket,
    selection: Object as () => ExchangeSelection,
  },

  data() {
    return {
      successMessage: '',
      successSnackbar: false,
      failureMessage: null as JSX.Element | null,
      failureSnackbar: false,
      cancelledOrder: null as Order | null,
      orders: [] as Order[],
    };
  },

  methods: {
    formatOutcome(order: Order) {
      return (
        <div>
          <small><strong>{selectionName(order, this.event, this.market.translations)}</strong></small>
        </div>
      );
    },

    formatPrice(order: Order) {
      return (
        <div>
          <small><strong>{order.price}</strong></small>
        </div>
      );
    },

    formatSize(order: Order) {
      return (
        <div>
          <small><strong>{formatAmount(Number(order.unmatchedSize))}</strong></small>
          &nbsp;
          <small class='grey--text'>{formatAmount(Number(order.size))}</small>
        </div>
      );
    },

    formatCancel(order: Order) {
      return (
        <VIcon small light={true} onClick={() => { this.cancelledOrder = order; }}>
          cancel
        </VIcon>
      );
    },


    orderItem(order: Order) {
      return (
        <div class={`${order.side} mt-2`}>
          <VRow no-gutters class='pl-1'>
            <VCol><small>{marketName(order, this.event)}</small></VCol>
            <VCol cols='2'><small>Odds</small></VCol>
            <VCol cols='4'><small>Stake</small></VCol>
            <VCol cols='1'></VCol>
          </VRow>
          <VRow no-gutters class='pl-1'>
            <VCol>{this.formatOutcome(order)}</VCol>
            <VCol cols='2'>{this.formatPrice(order)}</VCol>
            <VCol cols='4'>{this.formatSize(order)}</VCol>
            <VCol cols='1' class='text-center'>{this.formatCancel(order)}</VCol>
          </VRow>
          <VRow no-gutters class='pl-1'>
            <VCol>
              <div><small><strong>Ref:</strong> {order.uuid}</small></div>
              <small><strong>Created:</strong> {iso8601ToDateTimeUTCString(order.createdAt)}</small>
            </VCol>
          </VRow>
        </div >
      );
    },

    async cancelOrder(order: Order) {
      this.cancelledOrder = null;
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($uuid: String!) {
            sportsExchangeCancelOrder(uuid: $uuid) {
              createdAt
              unmatchedSize
              marketKey
              marketParams
              price
              selectionName
              side
              size
              uuid
            }
          }`,
          variables: {
            uuid: order.uuid,
          },
        });
        this.onCancelled(result.data.sportsExchangeCreateOrder);
      } catch (e) {
        if (e.graphQLErrors?.length > 0 && e.graphQLErrors[0].extensions?.response?.body?.errors) {
          this.showFailure(e.graphQLErrors[0].extensions.response.body.errors);
        } else {
          this.showFailure(e.message);
          throw e;
        }
      }
    },

    onCancelled(order: Order) {
      this.showSuccess('The order has been cancelled');
      this.$apollo.queries.orders.refresh();
    },

    showFailure(message: string | string[]) {
      if (_.isArray(message)) {
        this.failureMessage = <div>{message.map((m) => (<div>{m}</div>))}</div>;
      } else {
        this.failureMessage = (<div>{message}</div>);
      }
      this.failureSnackbar = true;
    },

    showSuccess(message: string) {
      this.successMessage = message;
      this.successSnackbar = true;
    },

  },

  computed: {
    ...mapGetters({ email: 'auth/email' }),

    cancelDialog() {
      if (this.cancelledOrder) {
        return (
          <ConfirmationDialog
            title='Cancel order'
            text={`Are you sure you want to cancel an order?`}
            onDisagree={() => this.cancelledOrder = null}
            onAgree={this.cancelOrder}
            item={this.cancelledOrder} />
        );
      }
    },

    progressBar() {
      if (this.$apollo.queries.orders.loading) {
        return (
          <VRow>
            <VCol>
              <VProgressLinear indeterminate></VProgressLinear>
            </VCol>
          </VRow>
        );
      }
    },
  },

  apollo: {
    orders: {
      query: gql`query($userUUID: String!, $eventId: Int, $selectionId: Int) {
        sportsExchangeUserOrders(userUUID: $userUUID, state: "open", eventId: $eventId, selectionId: $selectionId) {
          createdAt
          unmatchedSize
          marketKey
          marketParams
          price
          selectionName
          side
          size
          uuid
        }
      }`,

      variables(): { userUUID: string, eventId: number, selectionId: number } {
        return {
          userUUID: this.email,
          eventId: this.event.id,
          selectionId: this.selection.id,
        };
      },

      update(data) {
        return data.sportsExchangeUserOrders;
      },

      fetchPolicy: 'network-only',
    },
  },

  render() {
    return (
      <div class='orders'>
        {this.cancelDialog}
        <VSnackbar {...failureSnackbarConfig(<div>{this.failureMessage}</div>)} vModel={this.failureSnackbar} />
        <VSnackbar {...successSnackbarConfig(this.successMessage)} vModel={this.successSnackbar} />
        <VRow>
          <VCol class='text-left'>
            <VLabel><strong>{selectionName({
              marketKey: this.market.marketKey,
              marketParams: this.market.marketParams,
              selectionName: this.selection.selectionName,
            }, this.event, this.market.translations)}</strong></VLabel>
          </VCol>
          <VCol class='text-right'>
            <VBtn color='default' onClick={() => this.$apollo.queries.orders.refresh()} >
              <VIcon>refresh</VIcon>
              &nbsp;
              Refresh
            </VBtn>
          </VCol>
        </VRow>
        {this.progressBar}
        {this.orders.map((o) => this.orderItem(o))}
      </div>
    );
  },
});
