import { DirectiveBinding } from 'vue/types/options';

const decimalSeparator = () => {
  const n = 1.1;
  return n.toLocaleString().substring(1, 2);
};

const separator = decimalSeparator();

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  if (target) {
    const value = target.value;
    if (isNaN(value as any)) {
      target.value = '';
      target.dispatchEvent(new CustomEvent('input', { bubbles: true }));
    }
  }
};

const onKeyPress = (event: KeyboardEvent) => {
  const keyCode = (event.keyCode ? event.keyCode : event.which);
  if ((keyCode < 48 || keyCode > 57) && event.key !== separator) {
    event.preventDefault();
  }

  const value = (event.target as HTMLInputElement).value;
  if (event.key === separator && value.includes(separator)) {
    event.preventDefault();
  }
};

export const numericInput = {
  bind(el: HTMLElement, binding: DirectiveBinding) {
    const input = el.querySelector('input');
    if (input) {
      input.style.textAlign = 'left';
      input.type = 'number';
    }

    el.addEventListener('input', onInput);
    el.addEventListener('keypress', onKeyPress);
  },

  unbind(el: HTMLElement) {
    el.removeEventListener('input', onInput);
    el.removeEventListener('keypress', onKeyPress);
  },
};
