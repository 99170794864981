import { getPusherChannel, decodeMessage } from './pusherParser';
import Pusher from 'pusher-js';
import { RawSelection } from '@/types/sports';

/** @description subscribes to submarket level pusher */
interface SubSubmarketPusherProps {
  eventId: string | number;
  marketKey: string;
  submarketKey: string;
  pusherInstance: Pusher;
  updateSubmarket: (selections: RawSelection[]) => void;
}

export const subPusherChannel = ({
  eventId,
  marketKey,
  submarketKey,
  updateSubmarket,
  pusherInstance,
}: SubSubmarketPusherProps) => {
  const channelName = `event_v6_${eventId}_${marketKey}_${submarketKey}`;
  const hashedChannel = getPusherChannel(channelName);
  const channel = pusherInstance.subscribe(hashedChannel);
  channel.bind('update', (data: any) => {
    decodeMessage((updatedData) => {
      const submarket = updatedData as { selections: RawSelection[] };
      updateSubmarket(submarket.selections);
    })(data);
  });
};
