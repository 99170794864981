import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardTitle,
  VContainer,
  VDialog,
  VForm,
  VSnackbar,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

export default Vue.extend({
  props: ['reference'],

  data() {
    return {
      loading: false,
      dialog: true,
      reason: '',
      showResultSnackbar: false,
      result: '',
    };
  },

  methods: {
    onClose() {
      this.showResultSnackbar = false;
      this.$emit('dialogClosed');
    },

    onKeydown(e: KeyboardEvent) {
      if (e.code === 'Escape') {
        this.onClose();
      }
    },

    async onSubmit() {
      try {
        if (this.reason === '') {
          throw Error('Reason is required');
        }

        this.loading = true;
        const result = await this.$apollo.mutate({
          mutation: gql`mutation (
            $reference: String!,
            $reason: String!) {
              paymentsRejectWithdrawal(
                reference: $reference,
                reason: $reason
              ) {
                result
              }
            }`,
          variables: {
            reference: this.reference,
            reason: this.reason,
          },
        });

        this.result = result?.data?.paymentsRejectWithdrawal?.result;
      } catch (e) {
        this.result = String(e);
        this.loading = false;
      } finally {
        this.showResultSnackbar = true;
      }
    },
  },

  computed: {
    resultSnackbar() {
      if (this.showResultSnackbar) {
        if (this.result === 'OK') {
          const snackInterval = 3000;
          setTimeout(this.onClose, snackInterval);
          return (
            <VSnackbar
              v-model={this.showResultSnackbar}
              timeout={snackInterval}
              top
              color='green darken-1'
              elevation='24'
            >
              {`OK`}
            </VSnackbar>
          );
        }

        return (
          <VSnackbar
            v-model={this.showResultSnackbar}
            timeout={8000}
            top
            color='red darken-1'
            elevation='24'>
            {this.result}
          </VSnackbar>
        );
      }

      return (<div></div>);
    },
  },

  render() {
    return (
      <div>
        { this.resultSnackbar }

        <VDialog v-model={this.dialog} max-width='400' persistent
          on={{ 'click:outside': this.onClose, 'keydown': this.onKeydown }}>
          <VCard>
            <VCardTitle class='headline'>
              {this.reference}
            </VCardTitle>

            <VForm>
              <VContainer>
                <VTextField
                  class='mt-6'
                  dense
                  vModel={this.reason}
                  label='Reason'
                  required
                  rules={[
                    (value: string) => {
                      return !!value || 'reason is required';
                    },
                  ]}
                />
              </VContainer>
            </VForm>

            <VCardActions>
              <VSpacer></VSpacer>
              <VBtn color='green darken-1' text
                onClick={this.onClose}
                disabled={this.loading}
              >
                Cancel
              </VBtn>
              <VBtn color='green darken-1' text
                onClick={this.onSubmit}
                disabled={this.loading}
              >
                Reject
              </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </div>
    );
  },
});
