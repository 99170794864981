import Vue from 'vue';
import {
  VSelect,
} from 'vuetify/lib';
import './CompetitionTranslationPanel.less';
import { SingleCompetitionTranslation } from '../SingleCompetitionTranslation/SingleCompetitionTranslation';
export const CompetitionTranslationModal = Vue.extend({
  props: {
    competitionId: String,
    entityType: String,
  },
  data() {
    return {
      locale: 'en',
      locales: this.$store.state.sportsConsts.locales,
    };
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },
  render() {
    return (
      <div class='competition-translation-panel-select-table-wrapper'>
        <VSelect
          v-model={this.locale}
          items={this.locales}
          outlined
          dense
          label='Locale'
          class='selector mr-4'
          hideDetails
        />
        <SingleCompetitionTranslation
          entityType={this.entityType}
          locale={this.locale}
          competitionId={this.competitionId}
        />
      </div>
    );
  },
});
