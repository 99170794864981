const { host, pathname, search, hash } = window.location;
const { VUE_APP_CANONICAL_DOMAIN } = process.env;

export default function performDomainRedirects() {
  // don't do anything if this var is not set
  if (!VUE_APP_CANONICAL_DOMAIN) {
    return;
  }
  // redirect to this canonical domain if current host is not the same
  if (host !== VUE_APP_CANONICAL_DOMAIN) {
    // reconstruct the URL using the canonical domain name
    const redirectUrl = `https://${VUE_APP_CANONICAL_DOMAIN}${pathname}${search}${hash}`;
    // replaces the request and browser history, so you can't go back to the old domain
    // sort-of like a 301 permanent redirect
    window.location.replace(redirectUrl);
  }
}
