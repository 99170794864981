import Vue from 'vue';
import { gql } from 'apollo-boost';
import SearchDataTable from './SearchDataTable';
import { unixToDateTimeString } from '@/utils/date';
import { VCard } from 'vuetify/lib';

export default Vue.extend({
  props: {
    searchText: String,
    sportId: Number,
    categoryId: Number,
    actionsColumn: Object,
    actionsSlot: Function,
    active: { type: Boolean, default: false },
    includeMerged: { type: Boolean, default: false },
  },

  data() {
    return {
      competitions: [],
      hasError: false,
      headers: [
        { text: 'Id', value: 'id', width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Sport', value: 'sportName' },
        { text: 'Category', value: 'categoryName' },
        { text: 'Created At', value: 'createdAtFormated' },
      ],
    };
  },

  computed: {
    sportsKeysMap() {
      return this.$store.state.sportsConsts.sportsKeysMap;
    },

    categoriesMap() {
      return this.$store.state.sportsConsts.categoriesMap;
    },
  },

  methods: {
    routerLinkTo(item: { id: number }) {
      return { name: 'competition', params: { competitionId: item.id } };
    },
  },

  apollo: {
    competitions: {
      query: gql`
        query(
          $name: String!
          $sportId: Int
          $categoryId: Int
          $active: Boolean
          $includeMerged: Boolean
        ) {
          searchCompetitions(
            name: $name
            sportId: $sportId
            categoryId: $categoryId
            active: $active
            includeMerged: $includeMerged
          ) {
            competitions {
              id
              key
              name
              sportKey
              categoryId
              createdAt
            }
          }
        }
      `,
      variables(): {
        name: string;
        sportId: number;
        categoryId: number;
        active: boolean;
        includeMerged: boolean;
      } {
        return {
          name: this.searchText,
          sportId: this.sportId,
          categoryId: this.categoryId,
          active: this.active,
          includeMerged: this.includeMerged,
        };
      },
      error(error) {
        this.hasError = true;
      },
      update(data) {
        return data.searchCompetitions.competitions.map((c: any) => {
          c.createdAtFormated = unixToDateTimeString(c.createdAt);
          c.sportName = this.sportsKeysMap[c.sportKey];
          c.categoryName = this.categoriesMap[c.categoryId];
          return c;
        });
      },
      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },

  render() {
    return (
      <div>
        <SearchDataTable
          items={this.competitions || []}
          headers={this.headers}
          loading={this.$apollo.queries.competitions.loading}
          routerLinkTo={this.routerLinkTo}
          actionsColumn={this.actionsColumn}
          actionsSlot={this.actionsSlot}
        ></SearchDataTable>
        {this.hasError && (
          <VCard class='pa-4'>
            <div>
              Missing search param. Event search must have at least a sport
              defined.
            </div>
          </VCard>
        )}
      </div>
    );
  },
});
