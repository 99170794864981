import Vue from 'vue';
import {
  VSelect,
  VBtn,
  VTextField,
  VCard,
  VListItem,
  VCardTitle,
  VContainer,
  VCardText,
  VChipGroup,
  VChip,
  VCardSubtitle,
  VForm,
  VDialog,
  VIcon,
} from 'vuetify/lib';
import {
  Locale,
  getSportsName,
} from '@cloudbet/market-helper/sports-core';
import { validateRequired } from '@/utils/validate';
import {
  betSettingsPresetKeys,
  betPresets,
  BetSettingsProps,
} from './presetsConfig';
import { mapActions } from 'vuex';
import LiabilitySettings from '@/components/sports/LiabilitySettings/LiabilitySettings';
import { calculatePlaceFactor } from '@/utils/betSettings';

const PLAYER_ACCOUNTS = ['UNASSIGNED', 'NORMAL', 'SHARP'];

const INITIAL_PLAYER_ACCOUNT = 'UNASSIGNED';

const SPORTS_KEYS = [
  'american_football',
  'archery',
  'athletics',
  'aussie_rules',
  'badminton',
  'bandy',
  'baseball',
  'basketball',
  'beach_soccer',
  'beach_volleyball',
  'bowls',
  'boxing',
  'call_of_duty',
  'chess',
  'counter_strike',
  'cricket',
  'crossfire',
  'curling',
  'cycling',
  'darts',
  'dota_2',
  'entertainment',
  'esport_aoe',
  'esport_arena_of_valor',
  'esport_bga',
  'esport_brawl_stars',
  'esport_fifa',
  'esport_free_fire',
  'esport_king_of_glory',
  'esport_madden',
  'esport_nba2k',
  'esport_valorant',
  'esport_warcraft',
  'field_hockey',
  'floorball',
  'formula_1',
  'formula_e',
  'futsal',
  'golf',
  'greyhounds',
  'handball',
  'hearthstone',
  'heroes_of_the_storm',
  'horse_racing',
  'ice_hockey',
  'kabaddi',
  'league_of_legends',
  'mma',
  'motorsport',
  'olympics',
  'overwatch',
  'pesapallo',
  'politics',
  'rainbow_six',
  'rocket_league',
  'rugby_league',
  'rugby_union',
  'sailing',
  'snooker',
  'soccer',
  'specials',
  'squash',
  'starcraft',
  'street_fighter_v',
  'sumo',
  'swimming',
  'table_tennis',
  'tennis',
  'volleyball',
  'waterpolo',
  'wild_rift',
  'winter_sports',
  'world_lottery',
];


export const BetSetting = Vue.extend({
  props: {
    accountType: String!,
    placeFactor: String!,
    stakeFactor: String!,
    playerUUID: String!,
    subCustomerReference: String,
    updatedBy: String,
    updatedAt: String,
    isDefault: Boolean,
  },
  data() {
    let stakeFactor: string = this.stakeFactor;
    if (Number.isInteger(parseFloat(this.stakeFactor))) {
      stakeFactor = parseFloat(this.stakeFactor).toFixed(1);
    }

    return {
      oldStakeFactor: stakeFactor,
      oldPlaceFactor: this.placeFactor,
      oldAccountType: this.accountType,
      oldSportKey: '',
      latestStakeFactor: stakeFactor,
      latestAccountType: this.accountType,
      latestSportKey: '',
      selectedPresetIndex: -1,
      selectedPresetKey: '',
      appliedBetSetting: false,
    };
  },
  watch: {
    selectedPresetIndex() {
      if (this.selectedPresetIndex < 0) {
        return;
      }
      this.selectedPresetKey = betSettingsPresetKeys[this.selectedPresetIndex];
      this.applyBetSetting(betPresets[this.selectedPresetKey].settings);
    },
  },
  computed: {
    isOriginalInput(): boolean {
      return this.oldStakeFactor === this.latestStakeFactor &&
        this.oldAccountType === this.latestAccountType &&
        this.oldSportKey === this.latestSportKey &&
        !this.appliedBetSetting;
    },

    defaultCardTitle() {
      return <VCardTitle>
        Default
        <VDialog
          scopedSlots={{
            activator: ({ on, attrs }: any) => {
              return <VBtn icon='mdi-preview' on={on}>
                <VIcon>preview</VIcon>
              </VBtn>;
            },
          }}
        >
          <VCard>
            <LiabilitySettings stakeFactorPreview={this.latestStakeFactor} description='default'></LiabilitySettings>
          </VCard>
        </VDialog>
      </VCardTitle>;
    },

    accountTypeItems() {
      return PLAYER_ACCOUNTS.reduce((items, accountType) => {
        if (this.latestAccountType !== INITIAL_PLAYER_ACCOUNT && accountType === INITIAL_PLAYER_ACCOUNT) {
          return items;
        }

        items.push({
          text: accountType,
          value: accountType,
          disabled: accountType === INITIAL_PLAYER_ACCOUNT,
        });

        return items;
      }, Array<any>());
    },

    latestPlaceFactor(): string {
      if (this.isOriginalInput) {
        return this.oldPlaceFactor;
      }
      return calculatePlaceFactor(parseFloat(this.latestStakeFactor));
    },
  },
  methods: {
    ...mapActions({
      updateCustomerBetSetting: 'customerBetSettings/updateCustomerBetSetting',
    }),

    async saveSetting() {
      // @ts-ignore
      if (!this.$refs.form.validate()) {
        return;
      }

      await this.updateCustomerBetSetting({
        playerUUID: this.playerUUID,
        subCustomerReference: this.subCustomerReference,
        accountType: this.latestAccountType,
        placeFactor: this.latestPlaceFactor,
        stakeFactor: this.latestStakeFactor,
        sportKey: this.latestSportKey,
      });
    },
    resetInput() {
      this.latestStakeFactor = this.oldStakeFactor;
      this.latestAccountType = this.oldAccountType;
      this.latestSportKey = this.oldSportKey;
      this.updatePresetIndex();
    },
    applyBetSetting(settings: BetSettingsProps) {
      this.latestAccountType = settings?.accountType;
      this.latestStakeFactor = settings?.stakeFactor;
      this.appliedBetSetting = true;
    },
    updatePresetIndex() {
      for (let key = 0; key < betSettingsPresetKeys.length; key++) {
        const isAccountTypeMatch =
          betPresets[betSettingsPresetKeys[key]].settings.accountType ===
          this.accountType;

        const isStakeFactorMatch =
          parseFloat(
            betPresets[betSettingsPresetKeys[key]].settings.stakeFactor,
          ) === parseFloat(this.stakeFactor);

        const isPlaceFactorMatch =
        this.stakeFactor ===
        calculatePlaceFactor(parseFloat(betPresets[betSettingsPresetKeys[key]].settings.stakeFactor));

        if (isAccountTypeMatch && isStakeFactorMatch && isPlaceFactorMatch) {
          this.selectedPresetIndex = key;
          return;
        } else {
          this.selectedPresetIndex = -1;
        }
      }
    },
  },
  mounted() {
    if (this.oldPlaceFactor !== calculatePlaceFactor(parseFloat(this.oldStakeFactor))) {
      // do not assign preset if place factor is not correct,
      // this can only happen when db stores the data before we migrate place factor
      // to depend on stake factor
      return;
    }
    this.updatePresetIndex();
  },


  render() {
    return (
      <VCard width='350'>
        {this.isDefault ? this.defaultCardTitle :
          <VCardTitle>New</VCardTitle>
        }
        {this.isDefault && this.updatedBy &&
          <VCardSubtitle>{this.updatedBy} last updated at {this.updatedAt} </VCardSubtitle>
        }

        <VListItem >
          <VContainer fluid>
            <VForm ref='form'>
              {
                !this.isDefault && <VSelect
                  label='Sport'
                  items={SPORTS_KEYS.map((value) => ({ value, text: getSportsName(value, Locale.en) }))}
                  v-model={this.latestSportKey}
                  value={this.latestSportKey}
                  required
                  outlined
                  dense
                  filled
                  rules={[validateRequired]}
                ></VSelect>
              }


              <VSelect
                label='Rating'
                items={this.accountTypeItems}
                v-model={this.latestAccountType}
                value={this.latestAccountType}
                outlined
                dense
                filled
                required
                rules={[validateRequired]}
              ></VSelect>
              <VTextField
                v-model={this.latestStakeFactor}
                value={this.latestStakeFactor}
                label='Stake Factor'
                required
                outlined
                dense
                type='number'
                min='0'
                rules={[validateRequired]}
              ></VTextField>

              <VTextField
                v-model={this.latestPlaceFactor}
                value={this.latestPlaceFactor}
                label='Place Factor'
                required
                outlined
                dense
                type='number'
                disabled
                min='0'
                rules={[validateRequired]}
              ></VTextField>
              <VBtn onClick={() => { this.resetInput(); }} disabled={this.isOriginalInput} class='mb-2'>
                RESET
              </VBtn>
              <VBtn onClick={() => { this.saveSetting(); }} disabled={this.isOriginalInput} color='green' class='ml-2'>
                SAVE
              </VBtn>
            </VForm>
          </VContainer>
        </VListItem>
        <VCardText>
          <VChipGroup
            v-model={this.selectedPresetIndex}
            column
            active-class='deep-purple accent-4 white--text'
          >
            {
              betSettingsPresetKeys.map((key) => {
                const name = betPresets[key]?.name;
                return (
                  <VChip>{name}</VChip>
                );
              })
            }

          </VChipGroup>
        </VCardText>
      </VCard >
    );
  },
});
