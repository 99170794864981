import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VOverlay, VProgressCircular, VDivider, VContainer, VRow } from 'vuetify/lib';

import { hasRoles } from '@/utils/auth';
import './FailedWithdrawalsWidget.less';
import { WithdrawalItemWidget } from './WithdrawalItemWidget';

export const FailedWithdrawalsWidget = Vue.extend({
  name: 'FailedWithdrawalsWidget',
  async mounted() {
    this.inProgress = true;
    try {
      const response = await this.$apollo.query({
        query: gql`query {
          paymentsFailedWithdrawals{
            userUUID
            userNickname
            reference
            updatedAt
            currency
            blockchain
            address
            tagOrMemo
            amount
            exchangeRate
            state
            description
          }
        }`,
        fetchPolicy: 'no-cache',
      });
      if (response.data === null) {
        this.serviceAvailable = false;
      } else {
        this.list = response.data.paymentsFailedWithdrawals;
        this.serviceAvailable = true;
      }
    } catch (err) {
      this.serviceAvailable = false;
    } finally {
      this.inProgress = false;
    }
  },
  data() {
    return {
      list: [],
      inProgress: true,
      serviceAvailable: true,
    };
  },
  apollo: {
    failedWithdrawals: {
      query: gql`query {
        paymentsFailedWithdrawals{
          userUUID
          userNickname
          reference
          updatedAt
          currency
          blockchain
          address
          tagOrMemo
          amount
          exchangeRate
          state
          description
        }
      }`,
      fetchPolicy: 'no-cache',
      update(data) {
        this.list = data.paymentsFailedWithdrawals;
      },
    },
  },
  render() {
    if (!hasRoles(['payments:operator', 'payments:user'])) {
      return <div><p>You are not authorized to view this page.</p></div>;
    }

    return (
      <VContainer>
        <VOverlay value={this.inProgress}>
          <VProgressCircular
            size='70'
            width='7'
            indeterminate
            color='amber' />
        </VOverlay>
        <h1>Failed Withdrawals</h1>
        <span style='color:red'>
          Please check and ensure the transactions haven't been sent to the blockchain yet.
          If in doubt, please contact <a href='https://ct-hk.slack.com/archives/C015J49TVK3'>#payments-support</a>.
          <br/>Do NOT retry withdrawals in <span style='font-weight:bold'>failed_unknown</span> state
           until it's verified not sent on an explorer.
        </span>
        <VDivider></VDivider>
        <br />
        {
          this.serviceAvailable ?
            <VRow no-gutter>
              {
                this.list.map((item: any) => (
                  <WithdrawalItemWidget
                    item={item}
                    refetchAll={async () => {
                      this.inProgress = true;
                      await this.$apollo.queries.failedWithdrawals.refetch();
                      this.inProgress = false;
                    }}
                    key={item.reference}
                  />
                ))
              }
            </VRow>
            :
            <div><p>Service is unavailable.</p></div>
        }
      </VContainer>);
  },
});
