export default
  `bonusReturnAmount
  bonusStake
  cancelledAt
  cashouts {
    exhaustedPotentialReturnAmount
    uuid
  }
  channel
  comment
  country
  completedAt
  createdAt
  currency
  exchangeRate
  exhaustedPotentialReturnAmount
  id
  isCashoutBet
  isMultiple
  isRegraded
  potentialReturnAmount
  regradedByAdmin
  regradedByAdminEmail
  regradedByAdminId
  result
  returnAmount
  selections {
    adjustedPlacedOdds
    closingPrice
    competitionIntegrity
    event {
      competition {
        key
        name
      }
      competitors {
        key
        name
      }
      name
      players {
        key
        name
        position {
          key
          name
        }
        team
      }
      startsAt
    }
    eventId
    externalProvider
    id
    isLive
    marketId
    marketKey
    metadata
    name
    odds
    outright {
      competition {
        key
        name
      }
      finishesAt
      name
      players {
        key
        name
        position {
          key
          name
        }
        team
      }
    }
    specialBetValue
    sportKey
  }
  stake
  state
  transitions {
    mayCancel
    mayConfirm
  }
  translations
  uuid`;

export interface SportsBetSelection {
  adjustedPlacedOdds?: number;
  closingPrice: number;
  competitionIntegrity: number;
  event: {
    competition: {
      key: string;
      name: string;
    }
    competitors: Array<{
      key: string;
      name: string;
    }>,
    name: string;
    players: Array<{
      key: string;
      name: string;
      position: {
        key: string;
        name: string;
      }
      team: string;
    }>,
    startsAt: number;
  };
  eventId: number;
  externalProvider: string;
  id: number;
  isLive: boolean;
  marketId: number;
  marketKey: string;
  metadata: string;
  name: string;
  odds: number;
  outright: {
    competition: {
      key: string;
      name: string;
    };
    finishesAt: number;
    name: string;
    players: Array<{
      key: string;
      name: string;
      position: {
        key: string;
        name: string;
      };
      team: string;
    }>
  };
  specialBetValue: string;
  sportKey: string;
}

interface CashoutRef {
  exhaustedPotentialReturnAmount: number;
  uuid: string;
}


export interface SportsBet {
  _isPrevBetMultiple: any;
  bonusReturnAmount: number;
  bonusStake: number;
  cancelledAt: number;
  cashouts: CashoutRef[];
  channel: string;
  comment: string;
  country: string;
  completedAt: number;
  createdAt: number;
  currency: string;
  exchangeRate: number;
  exhaustedPotentialReturnAmount: number;
  id: number;
  isCashoutBet: boolean;
  isMultiple: boolean;
  isRegraded?: boolean;
  potentialReturnAmount: number;
  regradedByAdmin?: boolean;
  regradedByAdminEmail?: string;
  regradedByAdminId?: string;
  result?: string;
  returnAmount: number;
  selections: SportsBetSelection[];
  stake: number;
  state: string;
  transitions: {
    mayCancel: boolean;
    mayConfirm: boolean;
  };
  translations: Record<string, string>;
  uuid: string;
}
