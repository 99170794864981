import Vue from 'vue';
import {
  VContainer,
  VToolbar,
  VToolbarTitle,
  VBtn,
  VStepperHeader,
  VStepper,
  VStepperContent,
  VStepperItems,
  VStepperStep,
  VDivider,
} from 'vuetify/lib';
import UnmatchedEventsFilter from './UnmatchedEventsFilter';
import GenericEvents from './GenericEvents';
import Confirmation from './Confirmation';
import { GenericEvent } from './helpers';
import Summary from './Summary';
import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import UnmatchedEventsDataTable from './UnmatchedEventsDataTable';
import { mapGetters } from 'vuex';

const pageSize = 25;

export default Vue.extend({
  data() {
    return {
      step: 1,
      unmatchedEvent: null as null | UnmatchedEvent,
      genericEvent: null as null | GenericEvent,
      matchedEvent: null as null | UnmatchedEvent,
    };
  },

  computed: {
    ...mapGetters({
      unmatchedEventsLoading: 'sports/unmatchedEvents/loading',
      unmatchedEvents: 'sports/unmatchedEvents/events',
      unmatchedEventsLoadedPages: 'sports/unmatchedEvents/loadedPages',
      unmatchedEventsTotalPages: 'sports/unmatchedEvents/totalPages',
    }),

    toolbar() {
      return (
        <VToolbar dense >
          <VToolbarTitle>Events Matching</VToolbarTitle>
        </VToolbar>
      );
    },

    stepper(): JSX.Element {
      return (
        <VStepper vModel={this.step} >
          <VStepperHeader>
            <VStepperStep complete={this.step > 1} step='1' >
              Select a provider event
            </VStepperStep>

            <VDivider > </VDivider>

            <VStepperStep complete={this.step > 2} step='2' >
              Select/Create a generic event
            </VStepperStep>

            <VDivider > </VDivider>

            <VStepperStep complete={this.step > 3} step='3' > Confirm </VStepperStep>
          </VStepperHeader>

          <VStepperItems >
            <VStepperContent step='1' >
              <UnmatchedEventsFilter />
              <UnmatchedEventsDataTable
              events={this.unmatchedEvents}
              loadedPages={this.unmatchedEventsLoadedPages}
              totalPages={this.unmatchedEventsTotalPages}
              loading={this.unmatchedEventsLoading}
                pageSize={pageSize}
                onEventSelected={(event: UnmatchedEvent) => {
                  this.unmatchedEvent = event;
                  this.step = 2;
                }}
              />
            </VStepperContent>

            <VStepperContent step='2' >
              {this.unmatchedEvent &&
              <div>
                <GenericEvents
                  unmatchedEvent={this.unmatchedEvent}
                  onEventSelected={(event: GenericEvent) => {
                    this.genericEvent = event;
                    this.step = 3;
                  }}
                  onEventCreated={(event: GenericEvent) => {
                    this.genericEvent = event;
                    this.step = 3;
                  }} />
                  </div>
              }
              <VBtn class='mt-2' onClick={() => {
                this.step = 1;
                this.unmatchedEvent = null;
              }}>Back</VBtn>
            </VStepperContent>

            <VStepperContent step='3' >
              {this.unmatchedEvent && this.genericEvent &&
                <Confirmation
                  unmatchedEvent={this.unmatchedEvent}
                  genericEvent={this.genericEvent}
                  onConfirmed={(event: UnmatchedEvent) => {
                    this.matchedEvent = event;
                    this.step = 4;
                  }} />
              }
              <VBtn class='mt-2' onClick={() => {
                this.step = 2;
                this.genericEvent = null;
              }}>Back</VBtn>
            </VStepperContent>

            <VStepperContent step='4' >
              {
                this.matchedEvent &&
                this.genericEvent &&
                <Summary matchedEvent={this.matchedEvent} genericEvent={this.genericEvent} />
              }
              <VBtn class='mt-2' color='primary' onClick={() => {
                this.matchedEvent = null;
                this.unmatchedEvent = null;
                this.step = 1;
              }}>Continue</VBtn>
            </VStepperContent>
          </VStepperItems >
        </VStepper >
      );
    },
  },

  render() {
    return (
      <VContainer fluid >
        {this.toolbar}
        {this.stepper}
      </VContainer>
    );
  },
});
