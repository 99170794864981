import { formatCurrency } from '@/filters';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VContainer, VDataTable } from 'vuetify/lib';
import { eventPageUri } from '@/utils/external-uris';
import { RouterLink } from '@/router';

interface Event {
  eventId: string;
  eventName: string;
  sportKey: string;
  competitionKey: string;
  sportName: string;
  competitionName: string;
  isOutright: boolean;
  startsAt: string;
  playerExposure: number;
}

function eventType(event: Event): string {
  return new Date(event.startsAt) > new Date() ? 'prematch' : 'started';
}

export default Vue.extend({
  props: {
    playerUUID: String,
  },
  data() {
    return {
      dataLoaded: false,
      events: [] as Event[],
      subscription: null as any,
      headers: [
        {
          text: 'Type',
          align: 'start',
          sortable: false,
          value: 'type',
        },
        {
          text: 'Sport',
          align: 'start',
          sortable: false,
          value: 'sportName',
        },
        {
          text: 'Competition',
          align: 'start',
          sortable: false,
          value: 'competitionName',
        },
        {
          text: 'Event',
          align: 'start',
          sortable: false,
          value: 'eventName',
        },
        {
          text: 'Exposure',
          sortable: false,
          align: 'end',
          value: 'playerExposure',
        },
      ],
    };
  },
  methods: {
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription ($playerUUID: String!) {
              playerEventsUpdated (playerUUID: $playerUUID) {
                eventId
                eventName
                sportKey
                competitionKey
                sportName
                competitionName
                isOutright
                startsAt
                playerExposure
              }
            }
          `,
          variables: {
            playerUUID: this.playerUUID,
          },
        })
        .subscribe({
          next: ({ data }) => {
            this.events = data.playerEventsUpdated;
            this.dataLoaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  render() {
    return (
      <VContainer class='pa-0'>
        <VDataTable
          headers={this.headers}
          items={this.events}
          disablePagination={true}
          hideDefaultFooter={true}
          loading={!this.dataLoaded}
          scopedSlots={{
            'item.type': ({ item }: { item: Event }) => (
              <span>{eventType(item)}</span>
            ),
            'item.eventName': ({ item }: { item: Event }) => (
              <RouterLink class='text-decoration-none'
                          to={{ name: 'event', params: { eventId: item.eventId } }}>
                { item.eventName }
              </RouterLink>
            ),
            'item.playerExposure': ({ item }: { item: Event }) => (
              <span>{formatCurrency(item.playerExposure, 0)}</span>
            ),
          }}>
        </VDataTable>
      </VContainer>
    );
  },
});
