import Vue from 'vue';
import { revertMarketDataShape } from './mockMarkteData';
import './LiveMarkets.less';
import { VCard, VSkeletonLoader } from 'vuetify/lib';
import { Market } from '@/types/sports';
import { gql } from 'apollo-boost';
import Submarket from '../Submarkets/Submarkets';
import { isPrimaryMarket, MarketType } from '@cloudbet/market-helper';
import { marketName } from '@/components/sports/markets-names';
import { createNamespacedHelpers } from 'vuex';
import { Event } from '@/types/sports';

const { mapState, mapActions } = createNamespacedHelpers('sports/events');

export default Vue.extend({
  props: {
    eventId: String,
  },
  data() {
    return {
      markets: {} as Record<string, Market>,
      hasError: false,
    };
  },

  computed: {
    ...mapState(['currentEvent']),
  },

  methods: {
    ...mapActions(['loadCurrentEvent']),
  },

  apollo: {
    markets: {
      query: gql`
        query($eventId: String!) {
          getEventMarkets(eventId: $eventId) {
            marketKey
            submarkets {
              submarketKey
              blank
              sequence
              selections {
                outcome
                params
                price
                minStake
                probability
                status
                side
                maxStake
              }
            }
          }
        }
      `,
      variables(): { eventId: string } {
        return { eventId: this.eventId };
      },
      update(data) {
        if (data.getEventMarkets) {
          return revertMarketDataShape(data.getEventMarkets);
        }
      },
      error(error) {
        this.hasError = true;
      },
      result({ errors }) {
        if (errors) {
          this.hasError = true;
        }
      },
    },
  },

  mounted() {
    this.loadCurrentEvent(this.eventId);
  },

  render() {
    if (!this.markets) {
      return <div class='no-market-text'>no markets found</div>;
    }
    const allMarketKeys = Object.keys(this.markets);

    const primaryMarketKeys = allMarketKeys.filter((marketKey) =>
      isPrimaryMarket(marketKey as MarketType),
    );


    const marketViews = primaryMarketKeys.map((marketKey) => {
      const event = this.currentEvent as Event;
      const name = marketName({ marketKey, marketParams: '' }, { ...event });
      return (
        <VCard class='market-wrapper' elevation={0}>
          <div class='market-label'>
            <div class='market-text'>Market:</div> {name}
          </div>
          <Submarket
            submarkets={this.markets[marketKey].submarkets}
            marketKey={marketKey}
            eventId={this.eventId}
          />
        </VCard>
      );
    });

    if (this.hasError) {
      return <VCard class='live-market-prices-container' elevation={0}>
        <div class='title'>Prices</div>
        <div class='no-market-text'>no markets found</div>
      </VCard>;
    }
    return (
      <VCard class='live-market-prices-container' elevation={0}>
        <div class='title'>Prices</div>
        {this.$apollo.queries.markets.loading && (
          <VSkeletonLoader type='table-tbody' maxWidth='250' />
        )}

        {!this.$apollo.queries.markets.loading && !primaryMarketKeys.length && (
          <div class='no-market-text'>no markets found</div>
        )}
        {!this.$apollo.queries.markets.loading && primaryMarketKeys.length > 0 && marketViews}
      </VCard>
    );
  },
});
