import Vue from 'vue';
import {
  VTextField, VBtn, VMenu, VListItem, VListItemTitle, VList, VToolbar,
  VSpacer, VCard, VCardActions, VSelect,
} from 'vuetify/lib';
import SearchEvent from './SearchEvent';
import SearchCompetition from './SearchCompetition';
import SearchCompetitor from './SearchCompetitor';
import _ from 'lodash';

function searchById(text: string) {
  return /^\d+$/.test(text);
}

const DefaultItem = { id: 0, name: '' };

export default Vue.extend({
  data() {
    return {
      showMenu: false,
      entityType: 'event',
      searchText: '',
      acceptedSearchText: '',
      sport: DefaultItem,
      category: DefaultItem,
      acceptedSport: DefaultItem,
      acceptedCategory: DefaultItem,
    };
  },

  methods: {
    menuHandler(type: string, sport = DefaultItem, category = DefaultItem) {
      return () => {
        this.showMenu = false;
        this.searchText = '';
        this.entityType = type;
        this.acceptedSport = sport;
        this.acceptedCategory = category;
        (this.$refs.searchText as any).focus();
      };
    },

    keyDownOnSearchText(e: KeyboardEvent) {
      if (e.key === 'Enter') {
        this.searchEntity();
      }
    },

    searchEntity() {
      if (searchById(this.searchText)) {
        this.redirectToEntityPage();
      } else {
        this.acceptedSearchText = this.searchText;
      }
    },

    searchPrefix() {
      const params = [];
      if (this.acceptedSport.id > 0) {
        params.push(this.acceptedSport.name);
      }

      if (this.acceptedCategory.id > 0) {
        params.push(this.acceptedCategory.name);
      }

      const details = params.length > 0 ? ` (${params.join(', ')})` : '';

      return _.capitalize(this.entityType) + details;
    },

    redirectToEntityPage() {
      switch (this.entityType) {
        case 'competition':
          this.$router.push({ name: 'competition', params: { competitionId: this.searchText } });
          break;
        case 'competitor':
          this.$router.push({ name: 'competitor', params: { competitorId: this.searchText } });
          break;
        case 'event':
          this.$router.push({ name: 'event', params: { eventId: this.searchText } });
          break;
        case 'player':
          return;
      }
    },
  },

  computed: {
    sports() {
      return [{ id: 0, name: '' }, ...this.$store.state.sportsConsts.sports];
    },

    categories() {
      return [{ id: 0, name: '' }, ...this.$store.state.sportsConsts.categories];
    },

    entitiesMenu() {
      if (this.showMenu) {
        const sportsSelect =
          <VSelect items={this.sports} item-value='id' item-text='name' hint='Sport'
            dense persistent-hint={true} return-object={true} v-model={this.sport} >
          </VSelect>;

        const categoriesSelect =
          <VSelect items={this.categories} item-value='id' item-text='name' hint='Category'
            dense persistent-hint={true} return-object={true} v-model={this.category} class='ml-10'>
          </VSelect>;

        return (
          <VMenu value={true} close-on-content-click={false}
            nudge-width='200' offset-y activator='#search-entity-type-menu'>
            <VCard>
              <VList>
                <VListItem>
                  <VListItemTitle>Events</VListItemTitle>
                  {sportsSelect}
                  {categoriesSelect}
                  <VBtn color='primary' text onClick={this.menuHandler('event', this.sport, this.category)}>
                    Select
                  </VBtn>
                </VListItem>
                <VListItem>
                  <VListItemTitle>Competitor</VListItemTitle>
                  {sportsSelect}
                  <VBtn color='primary' text onClick={this.menuHandler('competitor', this.sport)}>
                    Select
                  </VBtn>
                </VListItem>
                <VListItem>
                  <VListItemTitle>Competition</VListItemTitle>
                  {sportsSelect}
                  {categoriesSelect}
                  <VBtn color='primary' text onClick={this.menuHandler('competition', this.sport, this.category)}>
                    Select
                  </VBtn>
                </VListItem>
              </VList>
              <VCardActions>
                <VSpacer></VSpacer>
                <VBtn text onClick={() => this.showMenu = false}>Cancel</VBtn>
              </VCardActions>
            </VCard>
          </VMenu>
        );
      }
    },

    searchPanel() {
      if (this.acceptedSearchText !== '') {
        switch (this.entityType) {
          case 'competition':
            return (
            <SearchCompetition searchText={this.acceptedSearchText} activator='#global-search-toolbar'
              sportId={this.acceptedSport.id} categoryId={this.acceptedCategory.id} />
            );
          case 'competitor':
            return (
            <SearchCompetitor searchText={this.acceptedSearchText} activator='#global-search-toolbar'
              sportId={this.acceptedSport.id} />
            );
          case 'event':
            return (
            <SearchEvent searchText={this.acceptedSearchText} activator='#global-search-toolbar'
              sportId={this.acceptedSport.id} categoryId={this.acceptedCategory.id} />
            );
          case 'player':
            return;
          default:
            return;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },

  watch: {
    searchText(value: string) {
      if (!value) {
        this.acceptedSearchText = '';
      }
    },
  },

  render() {
    return (
      <div flat color='primary' id='global-search-toolbar'>
        <VTextField
          ref='searchText'
          vModel={this.searchText}
          prefix={this.searchPrefix()}
          onKeydown={this.keyDownOnSearchText}
          flat
          solo-inverted
          hide-details
          clearable
          class='hidden-sm-and-down'
          append-icon='search'
          on={{ 'click:append': this.searchEntity }}>
          <template slot='prepend-inner'>
            <VBtn block class='blue darken-3' id='search-entity-type-menu'
              onClick={() => this.showMenu = true}>
              Search for
            </VBtn>
          </template>
        </VTextField>
        {this.entitiesMenu}
        {this.searchPanel}
      </div>
    );
  },
});
