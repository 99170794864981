import Vue from 'vue';
import { BetSetting } from '../BetSettings/BetSetting';
import {
  VContainer,
  VCol,
  VBtn,
  VSkeletonLoader,
  VTooltip,
  VDialog,
  VIcon,
  VSimpleTable,
  VRow,
  VCard,
  VCardText,
} from 'vuetify/lib';
import {
  mapGetters,
  mapActions,
} from 'vuex';
import {
  Locale,
  getSportsName,
} from '@cloudbet/market-helper/sports-core';
import { hasRoles } from '@/utils/auth';
import { env } from '@/env';
import PlayerJwtRole from './PlayerJwtRole';
import { gql } from 'apollo-boost';
import LiabilitySettings from '@/components/sports/LiabilitySettings/LiabilitySettings';
import { calculatePlaceFactor } from '@/utils/betSettings';

interface Player {
  id: number;
  nickname: string;
  brandName: string;
}

export const PlayerBetSettings = Vue.extend({
  props: {
    playerUUID: String,
    subCustomerReference: String,
  },
  data() {
    return {
      addNew: false,
      player: null as null | Player,
    };
  },
  methods: {
    ...mapActions({
      fetchCustomerBetSettings: 'customerBetSettings/fetchCustomerBetSettings',
    }),
    redashLink() {
      return `${env.redashUri}/dashboards/78-fd-dashboards---sports-analysis?p_mydate=d_last_12_months&p_nickname=${this.player?.nickname}`;
    },
    previewDialog(description: string, stakeFactor: number) {
      return <VDialog
        scopedSlots={{
          activator: ({ on, attrs }: any) => {
            return <VBtn icon='mdi-preview' on={on}>
              <VIcon>preview</VIcon>
            </VBtn>;
          },
        }}
      >
        <VCard>
          <LiabilitySettings stakeFactorPreview={stakeFactor} description={description}></LiabilitySettings>
        </VCard>
      </VDialog>;
    },
  },
  computed: {
    ...mapGetters({
      defaultSetting: 'customerBetSettings/defaultSetting',
      perSportSettings: 'customerBetSettings/perSportSettings',
      jwtRole: 'customerBetSettings/jwtRole',
      updating: 'customerBetSettings/updating',
    }),
  },
  watch: {
    updating() {
      this.addNew = false;
    },
  },
  async mounted() {
    await this.fetchCustomerBetSettings({
      playerUUID: this.playerUUID,
      subCustomerReference: this.subCustomerReference,
    });
  },

  apollo: {
    player: {
      query: gql`query($playerUUID: String!) {
          player: getPlayer(uuid: $playerUUID) {
            id
            nickname
            brandName
          }
        }`,
      variables(): { playerUUID: string } {
        return { playerUUID: this.playerUUID };
      },
    },
  },

  render() {
    return (
      <VContainer fluid>

        {
          env.enablePlayerBetSettings && hasRoles(['sports:operator']) && (
            <VRow>
              <VContainer fluid>
                {!this.updating ?
                  <VRow>
                    <VCol md='2'>
                      <VRow>
                        <VCol>
                          {this.player &&
                            <PlayerJwtRole
                              playerUUID={this.playerUUID}
                              brand={this.player.brandName}
                              jwtRole={this.jwtRole} />
                          }
                        </VCol>
                      </VRow>
                      <VRow>
                        <VCol>
                          <BetSetting
                            playerUUID={this.playerUUID}
                            subCustomerReference={this.subCustomerReference}
                            accountType={this.defaultSetting.defaultAccount}
                            stakeFactor={this.defaultSetting.stakeFactor}
                            placeFactor={calculatePlaceFactor(parseFloat(this.defaultSetting.stakeFactor))}
                            updatedBy={this.defaultSetting.updatedBy}
                            updatedAt={this.defaultSetting.updatedAt}
                            isDefault={true}
                          />
                        </VCol>
                      </VRow>
                    </VCol>

                    <VCol>
                      <VContainer>
                        <VRow>
                          <VCol>
                            <h1>
                              Per Sport Bet Settings
                            </h1>
                            <VTooltip
                              right
                              scopedSlots={{
                                activator: ({ on, attrs }: any) => (
                                  <span on={on}>
                                    <VBtn icon href={this.redashLink()} target='_blank'>
                                      <VIcon>
                                        event_note
                                      </VIcon>
                                    </VBtn>
                                  </span>

                                ),
                              }}>
                              <span>Open Redash Sports Analysis</span>
                            </VTooltip>
                          </VCol>
                          <VCol>
                            <VBtn color='orange' onClick={() => { this.addNew = true; }}>
                              UPDATE
                            </VBtn>
                          </VCol>
                        </VRow>
                        <VSimpleTable>
                          <thead>
                            <tr>
                              <th>Sport</th>
                              <th>Rating</th>
                              <th>Stake Factor</th>
                              <th>Place Factor</th>
                              <th>Updated By</th>
                              <th>Updated At</th>
                              <th>Preview</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.perSportSettings.map((setting: any, index: number) => (
                              <tr key={setting.sportKey + index}>
                                <td>{getSportsName(setting.sportKey, Locale.en)}</td>
                                <td>{setting.defaultAccount}</td>
                                <td>{setting.stakeFactor}</td>
                                <td>{calculatePlaceFactor(parseFloat(setting.stakeFactor))}</td>
                                <td>{setting.updatedBy}</td>
                                <td>{setting.updatedAt}</td>
                                <td>
                                  {this.previewDialog(getSportsName(setting.sportKey, Locale.en), setting.stakeFactor)}
                                </td>
                              </tr>
                              ))}
                          </tbody>
                        </VSimpleTable>
                        <VDialog vModel={this.addNew} max-width='350'>
                          <BetSetting
                            playerUUID={this.playerUUID}
                            subCustomerReference={this.subCustomerReference}
                            accountType={''}
                            placeFactor={''}
                            stakeFactor={''}
                          />
                        </VDialog>
                      </VContainer>
                    </VCol>
                  </VRow>
                  :
                  <div class='ma-2 bet-settings-skeleton'>
                    <VSkeletonLoader type='image, list-item@3' minWidth='250' />
                  </div>
                }
              </VContainer >
            </VRow>
          )
        }
      </VContainer >
    );
  },
});
