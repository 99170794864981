import Vue from 'vue';
import {
  VRow,
  VCol,
  VAutocomplete,
  VSelect,
  VBtn,
  VCard,
  VCardTitle,
  VCardText,
  VCardSubtitle,
  VSnackbar,
} from 'vuetify/lib';
import { failureSnackbarConfig } from './helpers';
import DateTimePicker from '../EventHeadline/DateTimePicker';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { eventTypeText } from './UnmatchedEventsDataTable';
import { objectToQueryString } from '@/utils/objects';
import { UnmatchedEventsFilters, requiresMatchingStatus } from '@/store/modules/sports/unmatched_events';

export const providersList = [
  'BBOOK',
  'BETFAIR',
  'BETRADAR',
  'BGL',
  'IMGGAMING',
  'MOLLY',
  'PINNACLE',
  'PYTHIA',
];

const types = [
  { value: 'EVENT_TYPE_EVENT', text: eventTypeText('EVENT_TYPE_EVENT') },
  { value: 'EVENT_TYPE_MULTI_COMPETITOR_EVENT', text: eventTypeText('EVENT_TYPE_MULTI_COMPETITOR_EVENT') },
  { value: 'EVENT_TYPE_OUTRIGHT', text: eventTypeText('EVENT_TYPE_OUTRIGHT') },
];

const defaultDateTimeRangeInDays = 7;

export default Vue.extend({
  data() {
    const params = this.$route.query;

    const fromTs = params.fromTs ?
      Number(decodeURIComponent(params.fromTs as string)) :
      Math.floor(new Date().valueOf() / 1000);

    const endDate = new Date(fromTs * 1000);
    const toTs = params.toTs ?
      Number(decodeURIComponent(params.toTs as string)) :
      Math.floor(endDate.setDate(endDate.getDate() + defaultDateTimeRangeInDays).valueOf() / 1000);

    const providers: string[] = params.providers ?
      decodeURIComponent(params.providers as string).split(',') :
      [];

    const sports: string[] = params.sports ?
      decodeURIComponent(params.sports as string).split(',') :
      [];

    const type = params.type ?
      decodeURIComponent(params.type as string) :
      undefined;

    return {
      showFailure: false,
      failureMessage: '',
      filters: {
        providers,
        sports,
        type,
        fromTs: fromTs.toString(),
        toTs: toTs.toString(),
        status: requiresMatchingStatus,
      } as Omit<UnmatchedEventsFilters, 'fromTs' | 'toTs'> & { fromTs: string, toTs: string },
    };
  },

  methods: {
    ...mapActions({
      loadSportsConsts: 'sportsConsts/loadSportsConsts',
      loadEvents: 'sports/unmatchedEvents/loadEvents',
    }),
    ...mapMutations({ CLEAR_EVENTS: 'sports/unmatchedEvents/CLEAR_EVENTS', UPDATE_FILTERS: 'sports/unmatchedEvents/UPDATE_FILTERS' }),

    applyFilters() {
      this.UPDATE_FILTERS({
        ...this.filters,
        toTs: parseInt(this.filters.toTs, 10),
        fromTs: parseInt(this.filters.fromTs, 10),
      });
      this.CLEAR_EVENTS();
      this.loadEvents();
      this.addParamsToUrl();
    },

    addParamsToUrl() {
      const queryString = objectToQueryString({
        providers: this.unmatchedEventFilters.providers.join(','),
        sports: this.unmatchedEventFilters.sports.join(','),
        type: this.unmatchedEventFilters.type || '',
        fromTs: this.unmatchedEventFilters.fromTs,
        toTs: this.unmatchedEventFilters.toTs,
      });

      history.pushState({}, '', `${this.$route.path}${queryString}${this.$route.hash}`);
    },
  },

  computed: {
    ...mapGetters({
      sportsList: 'sportsConsts/sports',
      unmatchedEventFilters: 'sports/unmatchedEvents/unmatchedEventFilters',
    }),
  },

  mounted() {
    this.loadSportsConsts();
    this.applyFilters();
  },

  render() {
    return (
      <VCard elevation={3} class='mt-1'>
        <VSnackbar {...failureSnackbarConfig(this.failureMessage)} vModel={this.showFailure} />
        <VCardTitle>
          Unmatched Provider Events
        </VCardTitle>
        <VCardSubtitle>
          Select a provider unmatched event which you want to match
        </VCardSubtitle>
        <VCardText>
          <div class='pa-2'>
            <VRow dense>
              <VCol>
                <VAutocomplete outlined dense multiple clearable chips small-chips deletable-chips
                  label='Provider'
                  items={providersList}
                  vModel={this.filters.providers} />
              </VCol>
              <VCol>
                <VAutocomplete outlined dense multiple clearable chips small-chips deletable-chips
                  item-text='name'
                  item-value='key'
                  label='Sport'
                  items={this.sportsList}
                  vModel={this.filters.sports} />
              </VCol>
              <VCol>
                <VSelect outlined dense clearable chips small-chips deletable-chips
                  label='Type'
                  items={types}
                  vModel={this.filters.type} />
              </VCol>
            </VRow>
            <VRow dense>
              <VCol>
                <DateTimePicker dateFormat='unix' vModel={this.filters.fromTs} clearable={false} required={true} />
              </VCol>
              <VCol>
                <DateTimePicker dateFormat='unix' vModel={this.filters.toTs} />
              </VCol>
            </VRow>
            <VRow dense>
              <VCol class='text-right'>
                <VBtn color='primary' onClick={this.applyFilters}>Search</VBtn>
              </VCol>
            </VRow>
          </div>
        </VCardText>
      </VCard>
    );
  },
});
