import Vue from 'vue';
import Dashboard from '@/components/Dashboard';
import { VContainer } from 'vuetify/lib';

export default Vue.extend({
  name: 'home',
  render() {
    return (
      <VContainer fluid fill-height>
        <Dashboard />
      </VContainer>
    );
  },
});

