import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VChip, VCol, VDataTable, VIcon, VRow, VSelect } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import { fiatCurrencies } from '@/static';
import FailSnackbar from '@/components/FailSnackbar';

interface Transaction {
  date: string;
  type: string;
  amount: string;
  currency: string;
  state: string;
  reference: string;
  account: string;
}

export default Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      currency: this.$route.query.currency || '',
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || 20,
      isLoading: true,
      isLoadFailed: false,
      loadError: '',
      paymentsBambora: {
        transactions: [] as Transaction[],
        total: 0,
      },
    };
  },

  computed: {
    currencies() {
      fiatCurrencies.unshift({text: 'All', value: ''});
      return fiatCurrencies;
    },

    headers() {
      return [
        { text: 'Date', align: 'start', sortable: false, value: 'date', width: '170px' },
        { text: 'State', align: 'start', sortable: false, value: 'state', width: '170px'  },
        { text: 'Type', align: 'start', sortable: false, value: 'type', width: '170px'  },
        { text: 'Amount', align: 'end', sortable: false, value: 'amount' },
        { text: 'Currency', align: 'start', sortable: false, value: 'currency' },
        { text: 'Reference', align: 'start', sortable: false, value: 'reference' },
        { text: 'Account', align: 'start', sortable: false, value: 'account' },
      ];
    },
  },

  async mounted() {
    this.fetchTransactions();
  },

  methods: {
    async fetchTransactions(): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($playerUUID: String!, $currency: String, $page: Int, $pageSize: Int) {
              fiatTransactions(playerUUID: $playerUUID, currency: $currency, page: $page, pageSize: $pageSize) {
                total
                transactions {
                  date
                  type
                  amount
                  currency
                  state
                  reference
                  account
                }
              }
            }`,
          variables: {
            playerUUID: this.playerUUID,
            currency: this.currency,
            page: this.page,
            pageSize: this.pageSize,
          },
          fetchPolicy: 'network-only',
        });
        this.paymentsBambora.total = response.data.fiatTransactions.total;
        this.paymentsBambora.transactions = response.data.fiatTransactions.transactions;
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
      window.scrollTo(0, 0);
    },

    handleChange() {
      this.page = 1;
      this.fetchTransactions();
    },

    handlePagination(options: any) {
      if (this.page === options.page && this.pageSize === options.itemsPerPage) {
        return;
      }

      this.page = options.page;
      this.pageSize = options.itemsPerPage;
      this.fetchTransactions();
    },

    getColor(word: string): string {
      switch (word) {
        case 'DEPOSIT': return 'blue';
        case 'WITHDRAWAL': return 'purple';
        case 'CONFIRMED': return 'green';
        case 'CANCELLED': return 'black';
        case 'PENDING': return 'blue';
        case 'PENDING_EMAIL_CONFIRMATION': return 'dark-blue';
        default: return 'red';
      }
    },
  },

  render() {
    if (!hasRoles(
      ['cs:operator', 'cs:user', 'finance:operator', 'finance:user', 'payments:operator', 'payments:user', 'pii:operator', 'pii:user'])) {
      return (
        <div class='container'>
          Unauthorized
        </div>
      );
    }
    return (
      <div class='container'>
        <FailSnackbar show={this.isLoadFailed} error={this.loadError} />
        <VRow>
          <VCol class='currency-col'>
            <VSelect
              class='payments-dropdown'
              label='Currency'
              vModel={this.currency}
              items={this.currencies}
              dense
              outlined
              onChange={this.handleChange}
            />
          </VCol>
          <VCol>
            <VBtn color='blue' onClick={this.fetchTransactions}>
              <VIcon>autorenew</VIcon>
            </VBtn>
          </VCol>
        </VRow>
        <VDataTable
          headers={this.headers}
          items={this.paymentsBambora.transactions}
          scopedSlots={{
            'item.type': ({ item }: { item: Transaction }) => (
              <VChip color={this.getColor(item.type)} text-color='white' small>{item.type}</VChip>
            ),
            'item.state': ({ item }: { item: Transaction }) => (
              <VChip color={this.getColor(item.state)} text-color='white' small>{item.state}</VChip>
            ),
          }}
          loading={this.isLoading}
          loading-text='Loading... Please wait'
          page={this.page}
          items-per-page={this.pageSize}
          server-items-length={this.paymentsBambora.total || 0}
          onPagination={this.handlePagination}
          footerProps={{'items-per-page-options': [10, 20, 50, 100, 200, 1000]}}
        />
      </div>
    );
  },
});
