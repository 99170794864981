import {
  FIAT_INTERNAL_CURRENCY_CODES,
  LOYALTY_INTERNAL_CURRENCY_CODES,
  SPORTS_INTERNAL_CURRENCY_CODES,
} from '@/types/casino/currency';
import {
  CASINO_CURRENCIES,
  HUMANIZED_CURRENCIES,
} from '@/types/casino/generated/currency';

export const humanizeCurrency = (currency: string) => {
  return HUMANIZED_CURRENCIES[currency] || currency;
};

export const awardableCasinoCurrencies = () => {
  const casinoCurrenciesSet = new Set(CASINO_CURRENCIES);
  const filterCurrenciesSet = new Set(
    FIAT_INTERNAL_CURRENCY_CODES.concat(
      LOYALTY_INTERNAL_CURRENCY_CODES,
      SPORTS_INTERNAL_CURRENCY_CODES,
    ),
  );

  const result = new Set([...casinoCurrenciesSet].filter((x) => !filterCurrenciesSet.has(x)));
  return [...result];
};

export const buildCurrenciesSelectList = (currencies: string[]) => {
  return currencies.map((currency) => {
    return {
      text: `${humanizeCurrency(currency)} (${currency})`,
      value: currency,
    };
  }).sort((e1, e2) => e1.text > e2.text ? 1 : -1);
};

type DistributorCurrenciesType = () => string[];
export const DISTRIBUTOR_CURRENCIES: DistributorCurrenciesType = () => {
  return CASINO_CURRENCIES.concat('EUR', 'USD');
};
