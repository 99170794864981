import Vue from 'vue';
import './Selection.less';
import { VBtn, VIcon } from 'vuetify/lib';

export default Vue.extend({
  props: {
    outcome: String,
    params: String,
    price: Number,
    status: String,
  },
  data() {
    return {
      priceChangeDirection: '',
    };
  },
  watch: {
    price(newPrice, oldPrice) {
      if (newPrice > oldPrice) {
        this.priceChangeDirection = 'increased';
        return;
      }
      if (newPrice < oldPrice) {
        this.priceChangeDirection = 'decreased';
        return;
      }
      this.priceChangeDirection = 'noChange';
    },
  },
  render() {
    if (this.price === 0 || this.status === 'SELECTION_DISABLED') {
      return (
        <VBtn
          class={`selection ${this.priceChangeDirection}`}
          elevation='1'
          tile
        >
          <div>
            <VIcon>lock</VIcon>
          </div>
        </VBtn>
      );
    }
    return (
      <div>
        <VBtn
          elevation={0}
          tile
          class={`selection ${this.priceChangeDirection}`}
        >
          <div class='selection-button-content'>
            <div class='selection-outcome-text'>{this.outcome}</div>
            <div class='selection-param'>{this.params}</div>
            <div class={` price ${this.priceChangeDirection}`}>
              {this.price}
            </div>
          </div>
        </VBtn>
      </div>
    );
  },
});
