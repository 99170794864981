import Vue from 'vue';
import {
  VRow,
  VCol,
  VCard,
  VCardTitle,
  VCardText,
  VCardSubtitle,
} from 'vuetify/lib';
import { iso8601ToDateTimeUTCString } from '@/utils/date';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import { GenericEvent } from './helpers';

export default Vue.extend({
  props: {
    matchedEvent: Object as () => UnmatchedEvent,
    genericEvent: Object as () => GenericEvent,
  },

  render() {
    return (
      <VCard elevation={3} class='mt-1'>
        <VCardTitle>
          Summary
        </VCardTitle>
        <VCardSubtitle>
          The provider event has been matched
        </VCardSubtitle>
        <VCardText>
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>To Generic Event:</strong></VCol>
            <VCol>
              <a href={`/events/${this.genericEvent.id}`}
                target='_blank'
                title={this.genericEvent.name}>
                {this.genericEvent.name}
              </a >
            </VCol>
          </VRow>
          {this.matchedEvent.eventType === 'EVENT_TYPE_EVENT' ?
            <div>
              <VRow dense>
                <VCol offset='3' cols='2' class='text-right'><strong>Home:</strong></VCol>
                <VCol>{this.matchedEvent.homeName}</VCol>
              </VRow>
              <VRow dense>
                <VCol offset='3' cols='2' class='text-right'><strong>Away:</strong></VCol>
                <VCol>{this.matchedEvent.awayName}</VCol>
              </VRow>
            </div>
            :
            <VRow dense>
              <VCol offset='3' cols='2' class='text-right'><strong>Name:</strong></VCol>
              <VCol>{this.matchedEvent.eventName}</VCol>
            </VRow>
          }
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>Competition:</strong></VCol>
            <VCol>{this.matchedEvent.competitionName}</VCol>
          </VRow>
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>Category:</strong></VCol>
            <VCol>{this.matchedEvent.categoryName}</VCol>
          </VRow>
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>Start Time:</strong></VCol>
            <VCol>{iso8601ToDateTimeUTCString(this.matchedEvent.cutoff)}</VCol>
          </VRow>
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>Provider:</strong></VCol>
            <VCol>{this.matchedEvent.provider}</VCol>
          </VRow>
          <VRow dense>
            <VCol offset='3' cols='2' class='text-right'><strong>Sport:</strong></VCol>
            <VCol>{getSportsName(this.matchedEvent.sportKey, Locale.en)}</VCol>
          </VRow>
        </VCardText >
      </VCard >
    );
  },
});
