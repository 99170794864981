import Vue from 'vue';
import { VSnackbar } from 'vuetify/lib';

export default Vue.extend({
  props: {
    show: Boolean,
  },

  render() {
    return (
      <VSnackbar timeout={2000} v-model={this.show} top color='green' elevation='24'>
        <div class='text-center'>OK</div>
      </VSnackbar>
    );
  },
});
