import Vue from 'vue';
import { VRow, VCol, VMenu, VDatePicker, VAutocomplete, VLabel, VTextField } from 'vuetify/lib';
import { range, partial } from 'lodash';
import { unixToDateTimeISOString, dateTimeISOStringToUnix } from '@/utils/date';

const fieldIsRequired = (required = true) => {
  if (required) {
    return (value: string) => {
      return !!value || 'Field is required';
    };
  } else {
    return () => {
      return true;
    };
  }
};

const numberToFixWidthString = (maxLen: number, val: number) => {
  return val.toString().padStart(maxLen, '0');
};

const unixTimestamp = (date: string, hour: string, minute: string) => {
  if (!!date && !!hour && !!minute) {
    return `${dateTimeISOStringToUnix(`${date}T${hour}:${minute}Z`)}`;
  }
  return '';
};

const dateToInputs = (date: string,
                      defaults: { date: string, hour: string, minute: string },
                      format: DateFormat): { date: string, hour: string, minute: string } => {
  const { date: defaultDate, hour: defaultHour, minute: defaultMinute } = defaults;
  const dateTime = format === 'unix' ? unixToDateTimeISOString(date) : date;
  return date ?
    { date: dateTime.substr(0, 10), hour: dateTime.substr(11, 2), minute: dateTime.substr(14, 2) }
    : { date: defaultDate, hour: defaultHour, minute: defaultMinute };
};

type DateFormat = 'unix';

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: () => '',
    },
    dateFormat: String as () => DateFormat,
    required: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      default: () => 'Date',
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    clearable: {
      type: Boolean,
      default: () => true,
    },
  },

  data() {
    const defaultDateTime = { date: '', hour: '00', minute: '00' };
    const { date, hour, minute } = dateToInputs(this.value, defaultDateTime, this.dateFormat);

    return {
      dateMenu: false,
      defaultDateTime,
      date,
      hour,
      minute,
    };
  },

  watch: {
    value(newValue) {
      const { date, hour, minute } = dateToInputs(newValue, this.defaultDateTime, this.dateFormat);
      this.date = date;
      this.hour = hour;
      this.minute = minute;
    },
  },

  render() {
    return (
      <VRow>
        <VCol cols='4'>
          <VMenu
            vModel={this.dateMenu}
            closeOnContentClick={true}
            nudgeRight={40}
            transition='scale-transition'
            offsetY
            minWidth='auto'
            scopedSlots={{
              activator: ({ on }: any) => {
                return (
                  <VTextField
                    vModel={this.date}
                    label={this.label}
                    readonly
                    dense
                    outlined
                    clearable={this.clearable}
                    on={on}
                    rules={[fieldIsRequired(this.required)]}
                    onInput={() => this.$emit('input', '')}
                    disabled={this.disabled}
                  />
                );
              },
            }}>
            <VDatePicker
              noTitle
              scrollable
              vModel={this.date}
              onInput={(val: string) => {
                this.dateMenu = false;
                if (this.dateFormat === 'unix') {
                  this.$emit('input', unixTimestamp(val, this.hour, this.minute));
                }
              }} />
          </VMenu>
        </VCol>
        <VCol cols='3'>
          <VAutocomplete dense outlined
            items={range(0, 24).map(partial(numberToFixWidthString, 2))}
            label='Hour'
            vModel={this.hour}
            rules={[fieldIsRequired(this.required)]}
            onInput={(val: string) => {
              this.dateMenu = false;
              if (this.dateFormat === 'unix') {
                this.$emit('input', unixTimestamp(this.date, val, this.minute));
              }
            }}
            disabled={this.disabled} />
        </VCol>
        <VCol cols='3'>
          <VAutocomplete dense outlined
            items={range(0, 60).map(partial(numberToFixWidthString, 2))}
            label='Minute'
            vModel={this.minute}
            rules={[fieldIsRequired(this.required)]}
            onInput={(val: string) => {
              this.dateMenu = false;
              if (this.dateFormat === 'unix') {
                this.$emit('input', unixTimestamp(this.date, this.hour, val));
              }
            }}
            disabled={this.disabled} />
        </VCol>
        <VCol cols='2' class='pt-5'>
          <VLabel>UTC</VLabel>
        </VCol>
      </VRow >
    );
  },
});
