import Vue from 'vue';
import { VCard, VRow } from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { hasRoles } from '@/utils/auth';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapState } = createNamespacedHelpers('exchangeRates');

export default Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      totalCryptoDeposit: 0,
      totalCryptoWithdrawal: 0,
      totalFiatDeposit: 0,
      totalFiatWithdrawal: 0,
      currency: 'EUR',
    };
  },

  computed: {
    ...mapState(['rates']),
  },

  methods: {
    ...mapActions(['loadRates']),
  },

  mounted() {
    this.loadRates();
  },

  apollo: {
    cryptoTotalTransaction: {
      query: gql`
        query($playerUUID: String!) {
          cryptoTotalTransaction(playerUUID: $playerUUID, currency: "") {
            totalDeposit
            totalWithdrawal
            currency
          }
        }`,
      variables(): { playerUUID: string } {
        return { playerUUID: this.playerUUID };
      },
      update(data) {
        this.totalCryptoDeposit = parseFloat(data.cryptoTotalTransaction.totalDeposit);
        this.totalCryptoWithdrawal = parseFloat(data.cryptoTotalTransaction.totalWithdrawal);
      },
    },

    fiatTotalTransaction: {
      query: gql`
        query($playerUUID: String!) {
          fiatTotalTransaction(playerUUID: $playerUUID) {
            totalDeposit
            totalWithdrawal
            currency
          }
        }`,
      variables(): { playerUUID: string } {
        return { playerUUID: this.playerUUID };
      },
      update(data) {
        const exchangeRate = this.rates[data.fiatTotalTransaction.currency];
        this.totalFiatDeposit = parseFloat(data.fiatTotalTransaction.totalDeposit) * exchangeRate;
        this.totalFiatWithdrawal = parseFloat(data.fiatTotalTransaction.totalWithdrawal) * exchangeRate;
      },
    },
  },

  render() {
    if (!hasRoles(['cs:operator', 'cs:user'])) {
      return <div></div>;
    }

    return (
      <VRow class='ma-2'>
        <div>
          <VCard class='card pa-2 mr-4' elevation='3' outlined>
            Total deposit: {Math.floor(this.totalCryptoDeposit + this.totalFiatDeposit)} {this.currency}
          </VCard>
        </div>
        <div>
          <VCard class='card pa-2' elevation='3' outlined>
            Total withdrawal: {Math.floor(this.totalCryptoWithdrawal + this.totalFiatWithdrawal)} {this.currency}
          </VCard>
        </div>
      </VRow>
    );
  },
});
