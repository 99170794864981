import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VCol, VIcon, VRow } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import ApproveDialog from '../KYC/ApproveKYC';
import RejectDialog from '../KYC/RejectKYC';
import OkSnackbar from '../OkSnackbar';
import FailSnackbar from '../FailSnackbar';
import LoadingSnackbar from '../LoadingSnackbar';

interface PlayerDetails {
  idVerificationResult: string;
  addressVerificationResult: string;
  kycPortalLink: string;
}

export default Vue.extend({
  props: {
    playerUUID: String,
    nickname: String,
  },

  data() {
    return {
      isLoading: false,
      isLoadFailed: false,
      loadError: '',
      playerDetails: {} as PlayerDetails,
      verified: false,
      showApprove: false,
      showReject: false,
      isOK: false,
    };
  },

  computed: {
    needReview(): boolean {
      return this.playerDetails.addressVerificationResult === 'for manual review';
    },
  },

  async mounted() {
    this.fetchKYCStatus();
  },

  methods: {
    async fetchKYCStatus(): Promise<any> {
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($playerUUID: String!) {
              playerKYC(playerUUID: $playerUUID) {
                idVerificationResult
                addressVerificationResult
                kycPortalLink
              }
            }`,
          variables: { playerUUID: this.playerUUID },
          fetchPolicy: 'network-only',
        });
        this.playerDetails = {
          idVerificationResult: response.data.playerKYC.idVerificationResult.replaceAll('_', ' '),
          addressVerificationResult: response.data.playerKYC.addressVerificationResult.replaceAll('_', ' '),
          kycPortalLink: response.data.playerKYC.kycPortalLink,
        };
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      }
      window.scrollTo(0, 0);
    },

    async verifyKYC(reason: string, expiry: string): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation($playerUUID: String!, $verified: Boolean!, $reason: String, $expiry: String) {
              verifyKYC(playerUUID: $playerUUID, verified: $verified, reason: $reason, expiry: $expiry)
            }`,
          variables: {
            playerUUID: this.playerUUID,
            verified: this.verified,
            reason,
            expiry,
          },
        });
        this.isLoading = false;
        this.isOK = true;
        // sleep 1 second to wait for the server to update the database
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.isOK = false;
        this.fetchKYCStatus();
      } catch (err) {
        this.isLoading = false;
        this.isLoadFailed = true;
        this.loadError = String(err);
      }
      window.scrollTo(0, 0);
    },

    openDialog(verified: boolean) {
      this.verified = verified;
      this.isOK = false;
      this.isLoadFailed = false;
      if (verified) {
        this.showApprove = true;
      } else {
        this.showReject = true;
      }
    },

    onSubmit(reason: string, expiry: string) {
      this.verifyKYC(reason, expiry);
      this.closeDialog();
    },

    closeDialog() {
      this.showApprove = false;
      this.showReject = false;
    },

    renderIcon(status: string) {
      switch (status) {
        case 'for manual review':
          return <VIcon color='warning' large>report_problem</VIcon>;
        case 'passed':
          return <VIcon color='success' large>done</VIcon>;
        case 'failed':
          return <VIcon color='error' large>close</VIcon>;
      }
    },
  },

  render() {
    if (!hasRoles(
      ['cs:operator', 'pii:operator'])) {
      return (
        <div class='container'>Unauthorized</div>
      );
    }
    return (
      <div class='container ma-4'>
        <VRow><VCol><h2>KYC Status</h2></VCol></VRow>

        <VRow>
          <VCol md={3}>ID Verification Status:</VCol>
          <VCol>
            { this.playerDetails.idVerificationResult }
            { this.renderIcon(this.playerDetails.idVerificationResult) }
          </VCol>
        </VRow>

        <VRow>
          <VCol md={3}>Address Verification Status:</VCol>
          <VCol>
            { this.playerDetails.addressVerificationResult }
            { this.renderIcon(this.playerDetails.addressVerificationResult) }
          </VCol>
        </VRow>

        {
          this.needReview && (
            <VRow>
              <VCol md={3}>KYC Portal Link:</VCol>
              <VCol><a href={this.playerDetails.kycPortalLink} target='_blank'>KYC Jumio Link</a></VCol>
            </VRow>
          )
        }

        {
          this.needReview && (
            <VRow>
              <VCol offset-md={3}>
                <VBtn color='green' onClick={() => this.openDialog(true)} style='margin: 0.5rem'>
                  Approve
                </VBtn>
                <VBtn color='red' onClick={() => this.openDialog(false)} style='margin: 0.5rem'>
                  Reject
                </VBtn>
              </VCol>
            </VRow>
          )
        }

        <FailSnackbar show={this.isLoadFailed} error={this.loadError} />
        <OkSnackbar show={this.isOK} />
        <LoadingSnackbar show={this.isLoading} />

        <ApproveDialog show={this.showApprove} nickname={this.nickname} playerUUID={this.playerUUID}
          onApprove={(expiry: string, reason: string) => this.onSubmit(reason, expiry)}
          onCancel={this.closeDialog}
        />
        <RejectDialog show={this.showReject} nickname={this.nickname} playerUUID={this.playerUUID}
          onReject={(reason: string) => this.onSubmit(reason, '')}
          onCancel={this.closeDialog}
        />
      </div>
    );
  },
});
