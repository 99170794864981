import _ from 'lodash';

export const camelise = <T extends object>(obj: T) => {
  return _.mapKeys(obj, (v, k) => _.camelCase(k)) as T;
};

export const objectToQueryString = (params: Record<string, string>) => {
  let queryString =
    Object.keys(params)
      .filter((key) => params[key])
      .map((key) => (`${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`))
      .join('&');

  if (queryString !== '') {
    queryString = `?${queryString}`;
  }

  return queryString;
};
