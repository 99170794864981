import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Vue from 'vue';
import {
  VDataTable,
  VBtn,
  VAlert,
  VToolbar,
  VToolbarTitle,
  VSpacer,
} from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import { hasLimits } from '@/utils/auth';

const { mapActions, mapState } = createNamespacedHelpers('exchangeRates');

import { formatCurrency, currencyAlias } from '@/filters';
import currenciesConfig from '@/config/currencies';

import { TransferFundsDialog } from './TransferFundsDialog';
import { ZeroPayments } from '@/components/sports/ZeroPayments';

const DEFAULT_PRECISION = 18;
const DEFAULT_EUR_PRECISION = 2;

interface Balance {
  currency: string;
  amount: string;
  bonusAmount: string;
  eurAmount: number;
  eurBonusAmount: number;
}

export default Vue.extend({
  props: {
    playerUUID: String,
    playerID: Number,
  },

  computed: {
    ...mapState(['rates']),
    transferFundsDialog() {
      if (this.transferFundsDialogIsOpen) {
        return (
          <TransferFundsDialog
            isOpen={true}
            uuid={this.playerUUID}
            type='player'
            presetCurrency={this.transferCurrency}
            presetAmount={this.transferAmount}
            on={{
              success: (message: string) => {
                this.message = message;
                this.$apollo.queries.playerBalances.refetch();
                this.transferFundsDialogIsOpen = false;
                this.transferCurrency = '';
                this.transferAmount = '';
              },
              close: () => {
                this.transferFundsDialogIsOpen = false;
                this.transferCurrency = '';
                this.transferAmount = '';
              },
            }}
          />
        );
      }
    },
    canTransferFunds(): boolean {
      return hasLimits(['manual_credit_limit']);
    },
  },

  methods: {
    ...mapActions(['loadRates']),
    divWithOptionalInfo(
      text: string | JSX.Element,
      additionalInfo: string | false,
    ): JSX.Element {
      return (
        <div>
          {text}
          {(additionalInfo && (
            <div>
              <small class='grey--text'>{additionalInfo}</small>
            </div>
          )) ||
            ''}
        </div>
      );
    },
  },

  data() {
    return {
      message: '',
      transferFundsDialogIsOpen: false,
      playerBalances: [] as Balance[],
      headers: [
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end',
        },
        {
          text: 'Amount (EUR)',
          value: 'eurAmount',
          align: 'end',
        },
        {
          value: 'actions',
          align: 'end',
        },
      ],
      transferCurrency: '',
      transferAmount: '',
    };
  },

  mounted() {
    this.loadRates();
  },

  apollo: {
    playerBalances: {
      query(): DocumentNode {
        return gql`
          query($id: Int!) {
            playerBalances: playerBalances(id: $id) {
              currency
              amount
              bonusAmount
            }
          }
        `;
      },
      update(data) {
        return data.playerBalances.map((balance: Balance) => {
          const exchangeRate = this.rates[balance.currency];
          balance.eurAmount = parseFloat(balance.amount) * exchangeRate;
          balance.eurBonusAmount = parseFloat(balance.bonusAmount) * exchangeRate;
          return balance;
        });
      },
      variables(): any {
        return { id: this.playerID };
      },
      skip(): any {
        return !this.playerID;
      },
    },
  },

  render() {
    return (
      <div>
        {this.transferFundsDialog}
        {this.message ? <VAlert type='warning'>{this.message}</VAlert> : null}
        <VToolbar dense>
          <VToolbarTitle>Balances</VToolbarTitle>
          <VSpacer />
          {this.canTransferFunds && (
            <VBtn
              color='primary'
              onClick={() => {
                this.transferFundsDialogIsOpen = true;
              }}
            >
              transfer funds
            </VBtn>
          )}
        </VToolbar>
        <VDataTable
          headers={this.headers}
          items={this.playerBalances}
          sortBy={'type'}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.playerBalances.loading}
          hideDefaultFooter
          disablePagination
          dense
          scopedSlots={{
            'item.currency': ({ item }: { item: Balance }) => {
              return this.divWithOptionalInfo(
                currencyAlias(item.currency),
                parseFloat(item.bonusAmount) !== 0 &&
                  `bonus ${currencyAlias(item.currency)}`,
              );
            },
            'item.amount': ({ item }: { item: Balance }) => {
              const currencyPrecision =
                currenciesConfig[item.currency]?.precise?.dp ||
                DEFAULT_PRECISION;

              return this.divWithOptionalInfo(
                item.amount,
                parseFloat(item.bonusAmount) !== 0 &&
                  item.bonusAmount,
              );
            },
            'item.eurAmount': ({ item }: { item: Balance }) => {
              return this.divWithOptionalInfo(
                formatCurrency(item.eurAmount, DEFAULT_EUR_PRECISION),
                parseFloat(item.bonusAmount) !== 0 &&
                  formatCurrency(item.eurBonusAmount, DEFAULT_EUR_PRECISION),
              );
            },
            'item.actions': ({ item }: { item: Balance }) => {
              if (this.canTransferFunds && parseFloat(item.amount) < 0) {
                return (
                  <VBtn
                    small
                    class='my-2'
                    onClick={() => {
                      this.transferCurrency = item.currency;
                      this.transferAmount = String(-item.amount);
                      this.transferFundsDialogIsOpen = true;
                    }}
                  >
                    Zero
                  </VBtn>
                );
              }
            },
          }}
        />
        <ZeroPayments
          class='mt-8'
          refetch={this.$apollo.queries.playerBalances.loading}
          accountType={'player'}
          accountUUID={this.playerUUID}
          on={{
            update: () => this.$apollo.queries.playerBalances.refetch(),
          }}
        />
      </div>
    );
  },
});
