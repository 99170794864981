import { gunzip } from 'zlib';
import md5 from 'md5';

type Callback = (data: unknown) => void;
export const decodeMessage = (callback: Callback) => (message: string) => {
  gunzip(Buffer.from(message, 'base64'), (err, buffer) => {
    if (buffer) {
      const bufferString = buffer.toString('utf8');
      callback(JSON.parse(bufferString));
    }
  });
};

export const getPusherChannel = (channelName: string) => {
  const playerUuid = '00000000-0000-0000-0000-000000000000'; // logged out users on frontend
  const hashedChannel = md5(channelName);
  return `${playerUuid}_api_${hashedChannel}`;
};
