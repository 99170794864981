import Vue from 'vue';
import { VBtn, VCard, VCardActions, VCardText, VCardTitle, VDialog, VTextarea, VTextField, VSpacer } from 'vuetify/lib';

export default Vue.extend({
  props: {
    show: Boolean,
    id: String,
  },

  methods: {
    onReject() {
      this.$emit('reject', this.reason.trim());
    },

    onCancel() {
      this.$emit('cancel');
    },
  },

  data() {
    return {
      reason: '',
    };
  },

  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const InputType = mobile ? VTextarea : VTextField;
    return (
      <VDialog vModel={this.show} max-width='500' persistent>
        <VCard>
          <VCardTitle class='headline'>Cancel withdrawal ID {this.id}</VCardTitle>
          <VCardText>
            <InputType
              vModel={this.reason}
              placeholder='reason'
              error={this.reason.trim() === ''}
              errorMessages={this.reason.trim() === '' ? 'Reason cannot be empty.' : ''}
              dense
              outlined
            />
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='red' onClick={this.onReject}>Reject</VBtn>
            <VBtn onClick={this.onCancel}>Cancel</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
