import Vue from 'vue';
import {
  VRow,
  VCol,
  VBtn,
  VCard,
  VCardTitle,
  VCardText,
  VCardSubtitle,
  VCheckbox,
  VSnackbar,
} from 'vuetify/lib';
import {
  GenericEvent,
  failureSnackbarConfig,
} from './helpers';
import { iso8601ToDateTimeUTCString, unixToDateTimeUTCString } from '@/utils/date';
import _ from 'lodash';
import { getSportsName, Locale } from '@cloudbet/market-helper';
import { mapActions, mapGetters } from 'vuex';
import { UnmatchedEvent } from '@/store/modules/sports/unmatched_events';
import { hasRoles } from '@/utils/auth';
import { GraphQLError } from 'graphql';
import { EntityMismatchConfirmationDialog, EntityValidationErrorCode, UnmatchedEntity } from './EntityMismatchConfirmationDialog';

export default Vue.extend({
  props: {
    unmatchedEvent: Object as () => UnmatchedEvent,
    genericEvent: Object as () => GenericEvent,
  },

  data() {
    return {
      teamSwapped: false,
      failureMessage: '',
      showFailure: false,
      updating: false,
      unmatchedEntityList: [] as UnmatchedEntity[],
    };
  },

  methods: {
    ...mapActions({
      matchEvents: 'sports/unmatchedEvents/matchEvents',
    }),

    isEntityValidationError(e: GraphQLError): boolean {
      const status = e.extensions?.response?.status;
      const message = e.extensions?.response?.body?.message;

      if (status !== 400) {
        return false;
      }

      if (/competition unexpected status/.test(message)) {
        return true;
      }

      try {
        const errorsObj = JSON.parse(message);
        for (const error of errorsObj?.errors) {
          if (error.code === EntityValidationErrorCode.ErrorCompetitionMismatch ||
            error.code === EntityValidationErrorCode.ErrorCompetitorMismatch) {
            return true;
          }
        }

        return false;
      } catch (e) {
        return false;
      }
    },

    extractUnmatchedEntityList(message: string): UnmatchedEntity[] {
      try {
        const unmatchedEntityList: UnmatchedEntity[] = [];
        const errorsObj = JSON.parse(message);
        for (const error of errorsObj.errors) {
          if (error.code === EntityValidationErrorCode.ErrorCompetitionMismatch) {
            unmatchedEntityList.push({
              code: EntityValidationErrorCode.ErrorCompetitionMismatch,
              providerEntityKey: error.provider_entity_key,
              genericMatchedEntityId: error.generic_matched_entity_id,
              provider: error.provider,
            });
          }

          if (error.code === EntityValidationErrorCode.ErrorCompetitorMismatch) {
            unmatchedEntityList.push({
              code: EntityValidationErrorCode.ErrorCompetitorMismatch,
              providerEntityKey: error.provider_entity_key,
              genericMatchedEntityId: error.generic_matched_entity_id,
              provider: error.provider,
            });
          }
        }

        return unmatchedEntityList;
      } catch (e) {
        return [];
      }
    },

    async onConfirm() {
      try {
        this.updating = true;
        await this.matchEvents({
          eventId: this.genericEvent.id,
          provider: this.unmatchedEvent.provider,
          providerEventKey: this.unmatchedEvent.providerEventKey,
          teamSwapped: this.teamSwapped,
        });

        this.$emit('confirmed', this.unmatchedEvent);
      } catch (e) {
        if (this.isEntityValidationError(e.graphQLErrors[0])) {
          this.unmatchedEntityList = this.extractUnmatchedEntityList(
            e.graphQLErrors[0].extensions.response.body.message);
          return;
        }

        this.failureMessage = e.message;
        this.showFailure = true;
        throw e;
      } finally {
        this.updating = false;
      }
    },
  },

  computed: {
    ...mapGetters({
      categoriesMap: 'sportsConsts/categoriesMap',
    }),

    displayConfirmationButton(): boolean {
      return hasRoles(['sports:operator']);
    },

    genericEventProviders(): string {
      return _.uniq(this.genericEvent.providersData.map((x) => x.provider)).sort().join(', ');
    },

    confirmCompetitionDialog(): JSX.Element {
      return (<EntityMismatchConfirmationDialog
        isOpen={this.unmatchedEntityList.length > 0}
        unmatchedEntityList={this.unmatchedEntityList}
        unmatchedEvent={this.unmatchedEvent}
        genericEvent={this.genericEvent}
        teamSwapped={this.teamSwapped}
        onSave={async () => {
          this.unmatchedEntityList = [];

          await this.matchEvents({
            eventId: this.genericEvent.id,
            provider: this.unmatchedEvent.provider,
            providerEventKey: this.unmatchedEvent.providerEventKey,
            teamSwapped: this.teamSwapped,
          });

          this.$emit('confirmed', this.unmatchedEvent);
        }}
        onClose={() => this.unmatchedEntityList = []}
        onError={(e: Error) => {
          this.failureMessage = e.message;
          this.showFailure = true;
        }}
      />);
    },
  },

  render() {
    return (
      <div>
        {this.confirmCompetitionDialog}
        <VCard elevation={3} class='mt-1'>
          <VSnackbar {...failureSnackbarConfig(this.failureMessage)} vModel={this.showFailure} />
          <VCardTitle>
            Confirmation
          </VCardTitle>
          <VCardSubtitle>
            Check and confirm matching
          </VCardSubtitle>
          <VCardText>
            <VRow dense>
              <VCol offset='6' cols='2' class='text-right warning--text'><strong>ID:</strong></VCol>
              <VCol class='warning--text'>{this.genericEvent.id}</VCol>
            </VRow>
            {this.unmatchedEvent.eventType === 'EVENT_TYPE_EVENT' ?
              <div>
                <VRow dense>
                  <VCol cols='2' class='text-right'><strong>Home:</strong></VCol>
                  <VCol>{this.teamSwapped ? this.unmatchedEvent.awayName : this.unmatchedEvent.homeName}</VCol>
                  <VCol cols='2' class='text-right'><strong>Home:</strong></VCol>
                  <VCol>{this.genericEvent.competitors?.[0]?.name}</VCol>
                </VRow>
                <VRow dense>
                  <VCol cols='2' class='text-right'><strong>Away:</strong></VCol>
                  <VCol>{this.teamSwapped ? this.unmatchedEvent.homeName : this.unmatchedEvent.awayName}</VCol>
                  <VCol cols='2' class='text-right'><strong>Away:</strong></VCol>
                  <VCol>{this.genericEvent.competitors?.[1]?.name}</VCol>
                </VRow>
              </div>
              :
              <VRow dense>
                <VCol cols='2' class='text-right'><strong>Name:</strong></VCol>
                <VCol>{this.unmatchedEvent.eventName}</VCol>
                <VCol cols='2' class='text-right'><strong>Name:</strong></VCol>
                <VCol>{this.genericEvent.name}</VCol>
              </VRow>
            }
            < VRow dense >
              <VCol cols='2' class='text-right'><strong>Competition:</strong></VCol>
              <VCol>{this.unmatchedEvent.competitionName}</VCol>
              <VCol cols='2' class='text-right'><strong>Competition:</strong></VCol>
              <VCol>{this.genericEvent.competition.name}</VCol>
            </VRow >
            <VRow dense>
              <VCol cols='2' class='text-right'><strong>Category:</strong></VCol>
              <VCol>{this.unmatchedEvent.categoryName}</VCol>
              <VCol cols='2' class='text-right'><strong>Category:</strong></VCol>
              <VCol>{this.categoriesMap[this.genericEvent.competition.categoryId]}</VCol>
            </VRow>
            <VRow dense>
              <VCol cols='2' class='text-right'><strong>Start Time:</strong></VCol>
              <VCol>{iso8601ToDateTimeUTCString(this.unmatchedEvent.cutoff)}</VCol>
              <VCol cols='2' class='text-right'><strong>Start Time:</strong></VCol>
              <VCol>{unixToDateTimeUTCString(this.genericEvent.startsAt)}</VCol>
            </VRow>
            <VRow dense>
              <VCol cols='2' class='text-right'><strong>Provider:</strong></VCol>
              <VCol>{this.unmatchedEvent.provider}</VCol>
              <VCol cols='2' class='text-right'><strong>Provider:</strong></VCol>
              <VCol>{this.genericEventProviders}</VCol>
            </VRow>
            <VRow dense>
              <VCol cols='2' class='text-right'><strong>Sport:</strong></VCol>
              <VCol>{getSportsName(this.unmatchedEvent.sportKey, Locale.en)}</VCol>
              <VCol cols='2' class='text-right'><strong>Sport:</strong></VCol>
              <VCol>{getSportsName(this.genericEvent.sportKey, Locale.en)}</VCol>
            </VRow>
            <VRow dense>
              <VCol cols='2' class='text-right'><strong>Type:</strong></VCol>
              <VCol>{this.unmatchedEvent.eventType.replace('EVENT_TYPE_', '')}</VCol>
              <VCol cols='2' class='text-right'><strong>Type:</strong></VCol>
              <VCol>{this.genericEvent.type}</VCol>
            </VRow>
            {this.unmatchedEvent.eventType === 'EVENT_TYPE_EVENT' &&
              <VRow>
                <VCol offset={5}><VCheckbox vModel={this.teamSwapped} label='Team Swapped' /></VCol>
              </VRow>
            }
            {this.displayConfirmationButton &&
              <VRow dense>
                <VCol offset={5}>
                  <VBtn color='primary'
                    onClick={this.onConfirm}
                    loading={this.updating}
                    disabled={this.updating}>
                    Confirm
                  </VBtn>
                </VCol>
              </VRow>
            }
          </VCardText >
        </VCard >
      </div>
    );
  },
});
