import { playerPageUri } from '@/utils/external-uris';
import { createNamespacedHelpers } from 'vuex';
const { mapActions: mapSportsConstsActions } = createNamespacedHelpers('sportsConsts');

// shared method for messages components
export default {
  props: {
    message: Object,
    variant: {
      type: String,
      default: 'card',
      validator(value: string) {
        // The value must match one of these strings
        return ['card', 'singleLine'].indexOf(value) !== -1;
      },
    },
  },
  methods: {
    playerPageUri,
    ...mapSportsConstsActions(['loadSportsConsts']),
    summaryClass(entity: any) {
      const returnAmountWithBonusInEur = entity.returnAmountWithBonusInEur * -1;
      if (returnAmountWithBonusInEur > 0) {
        return 'summary--success';
      } else if (returnAmountWithBonusInEur < 0) {
        return 'summary--failure';
      } else if (returnAmountWithBonusInEur === 0) {
        return 'summary--warning';
      }
    },
    channelClass(entity: any) {
      return 'channel channel-' + entity.metadata.channel;
    },
    betStateColor(
      betState: string,
      returnAmountWithBonusInEur = 0,
      betResult?: string, // For sport bet result handling
    ) {
      const returnAmount = returnAmountWithBonusInEur * -1;
      if (betState === 'REJECTED') { return 'error'; }
      if (
        (betState === 'COMPLETED' && betResult !== 'PENDING') ||
        betState === 'ENDED'
      ) {
        if (returnAmount === 0) { return 'warning'; }
        if (returnAmount < 0) { return 'error'; }
        if (returnAmount > 0) { return 'success'; }
        return 'info';
      }
      return 'default';
    },
    truncateText(text: string, len: number) {
      if (text.length <= len) {
        return text;
      }
      return text.substring(0, len - 3) + '...';
    },
  },
};
