import { formatCurrency } from '@/filters';
import { RouterLink } from '@/router';
import { unixToDateTimeUTCString } from '@/utils/date';
import { gql } from 'apollo-boost';
import Vue from 'vue';
import { VBtn, VCard, VCardSubtitle, VCardText, VCardTitle, VDataTable, VDialog } from 'vuetify/lib';
import { SportsBonus } from '../SportsBonusControl/SportsBonusControl';


interface EventPlaythrough {
  eventID: string;
  amount: number;
  betsCount: number;
  completed: boolean;
  rule: string;
  updatedAt: string;
}

interface BonusPlaythrough {
  bonusUUID: string;
  eventPlaythroughs: EventPlaythrough[];
}

export const PlaythroughDialog = Vue.extend({
  props: {
    isLoading: Boolean,
    sportsBonus: Object as () => SportsBonus,
  },
  data: {
    sportsBonusPlaythrough: null as BonusPlaythrough | null,
  },
  apollo: {
    sportsBonusPlaythrough: {
      query: gql`
        query($bonusUUID: String!) {
          sportsBonusPlaythrough(bonusUUID: $bonusUUID) {
            bonusUUID
            eventPlaythroughs {
              eventID
              amount
              rule
              betsCount
              completed
              updatedAt
            }
          }
        }
      `,
      variables(): { bonusUUID: string } {
        return { bonusUUID: this.sportsBonus.uuid };
      },
      watchLoading(isLoading) {
        this.isLoading = isLoading;
      },
      pollInterval: 5000,
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'Event ID',
          value: 'eventID',
        },
        {
          text: '#bets',
          value: 'betsCount',
        },
        {
          text: 'Completed',
          value: 'completed',
        },
        {
          text: 'Rule',
          value: 'rule',
        },
        {
          text: 'Playthrough (EUR)',
          value: 'amount',
          align: 'end',
        },
        {
          text: 'Updated at',
          value: 'updatedAt',
        },
      ];
    },
  },
  methods: {
    eventID({ item }: { item: EventPlaythrough }) {
      return (
        <RouterLink
          class='text-decoration-none'
          to={{ name: 'event', params: { eventId: item.eventID } }}>
          {item.eventID}
        </RouterLink>
      );
    },
    amount({ item }: { item: EventPlaythrough }) {
      return formatCurrency(item.amount || 0, 2);
    },
    completed({ item }: { item: EventPlaythrough }) {
      if (item.completed) {
        return '+';
      } else {
        return '-';
      }
    },
    updatedAt({ item }: { item: EventPlaythrough }) {
      return unixToDateTimeUTCString(Date.parse(item.updatedAt) / 1000);
    },
  },
  render() {
    return (
      <VDialog
        width='unset'
        scopedSlots={{
          activator: ({ on, attrs }: any) => (
            <VBtn
              color='primary'
              x-small
              onClick={on.click}>
              playthrough by events
            </VBtn>
          ),
        }}>
        <VCard>
          <VCardTitle>Bonus playthrough</VCardTitle>
          <VCardSubtitle>
            <div>{this.sportsBonus.uuid}</div>
            <div>State: {this.sportsBonus.state}</div>
            <div>
              Playthrough: {formatCurrency(this.sportsBonus.playthrough || 0, 2, { name: 'EUR' })}
            </div>
            <div>
              Pending playthrough: {formatCurrency(this.sportsBonus.pendingPlaythrough || 0, 2, { name: 'EUR' })}
            </div>
          </VCardSubtitle>
          <VCardText>
            <VDataTable
              hideDefaultFooter
              headers={this.headers}
              items={this.sportsBonusPlaythrough?.eventPlaythroughs}
              itemKey='uuid'
              disableSort={true}
              loadingText='Loading... Please wait'
              loading={this.isLoading}
              class='pa-2'
              itemsPerPage={-1}
              scopedSlots={{
                'item.eventID': this.eventID,
                'item.amount': this.amount,
                'item.completed': this.completed,
                'item.updatedAt': this.updatedAt,
              }}
            />
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
