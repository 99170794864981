import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.blue.darken3,
      },
      dark: {
        primary: colors.blue.darken3,
        anchor: colors.blue.lighten1,
      },
    },
  },
});
