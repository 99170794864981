<template>
  <v-dialog v-model="dialog" persistent max-width="300">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="gray"
        x-small
        dark
        v-bind="attrs"
        v-on="on"
      >
        Republish bets
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="headline">Republish bets?</v-card-title>
      <v-card-text v-if="!triggered">You're going to push bets again to the message bus. Are you sure?</v-card-text>
      <v-card-text v-else-if="success">
        <v-alert type="success">Success!</v-alert>
      </v-card-text>
      <v-card-text v-else>
        <v-alert type="error">{{ error.message }}</v-alert>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div v-if="triggered">
          <v-btn text @click="dialog = false; triggered = false;">Done</v-btn>
        </div>
        <div v-else>
          <v-btn text @click="dialog = false" :disabled="loading">No</v-btn>
          <v-btn text @click="republishEventBets()" :loading="loading":disabled="loading">Yes</v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import gql from 'graphql-tag';
import Vue from 'vue';

export default Vue.extend({
  props: ['eventId'],
  data: () => {
    return {
      dialog: false,
      loading: false,
      triggered: false,
      error: null,
    };
  },
  methods: {
    republishEventBets() {
      this.loading = true;

      this.$apollo.mutate({
        mutation: gql`mutation ($eventId: Int!) {
          republishEventBets(eventId: $eventId) {
            success
          }
        }`,
        variables: {
          eventId: parseInt(this.eventId, 10),
        },
      }).then((data) => {
        this.success = true;
        this.loading = false;
        this.triggered = true;
      }).catch((error) => {
        this.success = false;
        this.loading = false;
        this.triggered = true;
        this.error = error;
      });
    },
  },
});
</script>
