interface Env {
  gateway: {
    wsUri: string,
    httpUri: string,
  };
  sportsLoyaltyService: {
    uri: string,
  };
  firebase: {
    apiKey: string,
    authDomain: string,
    databaseURL: string,
    projectId: string,
    storageBucket: string,
    messagingSenderId: string,
    appId: string,
  };
  flightdeck: {
    uri: string,
  };
  tro: {
    uri: string,
  };
  coinExplorers: Record<string, string>;
  sportsWithLiabilityGrid: string[];
  sportsCoreBucketUri: string;
  exchangeRatesUri: string;
  redashUri: string;
  enableCompetitionPriorities: boolean;
  enablePlayerBetSettings: boolean;
  pusher: {
    apiKey: string;
    cluster: string;
  };
  ledger: {
    host: string;
  };
  sportsExchange: {
    wsUri: string,
  };
  enableExchangeMarkets: boolean;
  casino: {
    algoliaClient: {
      appId: string;
      apiKey: string;
      indexNameCasinoGame: string;
    },
    evolution: {
      backoffice_url: string;
    },
    games: {
      images_url: string;
    },
    hub_game_url: string;
  };
  feature: {
    enableCSGrading: boolean;
    enableGrading: boolean;
    enableEventsMatching: boolean;
    enableWithdrawalApproval: boolean;
  };
  rollbar: {
    accessToken: string;
    environment: string;
  };
}

export const env: Env = {
  gateway: {
    wsUri: process.env.VUE_APP_GATEWAY_WS_URI || '',
    httpUri: process.env.VUE_APP_GATEWAY_HTTP_URI || '',
  },
  sportsLoyaltyService: {
    uri: process.env.VUE_APP_SPORTS_LOYALTY_URI || '',
  },
  firebase: {
    apiKey: process.env.VUE_APP_FIREBASE_API_KEY || '',
    authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL || '',
    projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID || '',
    storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET || '',
    messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    appId: process.env.VUE_APP_FIREBASE_APP_ID || '',
  },
  flightdeck: {
    uri: process.env.VUE_APP_FLIGHTDECK_URI || '',
  },
  tro: {
    uri: process.env.VUE_APP_TRO_APP_URI || '',
  },
  coinExplorers: {
    BCH: 'https://www.blockchain.com/bch/tx',
    BNB: 'https://bscscan.com/tx',
    BTC: 'https://www.blockchain.com/btc/tx',
    BUSD: 'https://bscscan.com/tx',
    ETH: 'https://etherscan.io/tx',
    GUSD: 'https://etherscan.io/tx',
    MAT: 'https://polygonscan.com/tx',
    MATIC: 'https://polygonscan.com/tx',
    PAX: 'https://etherscan.io/tx',
    TUSD: 'https://etherscan.io/tx',
    US2: 'https://etherscan.io/tx',
    US3: 'https://etherscan.io/tx',
    US4: 'https://etherscan.io/tx',
    US5: 'https://etherscan.io/tx',
    US6: 'https://etherscan.io/tx',
    US9: 'https://bscscan.com/tx',
    USDC: 'https://etherscan.io/tx',
    USDT: 'https://etherscan.io/tx',
  },
  sportsWithLiabilityGrid: [
    'american_football',
    'aussie_rules',
    'badminton',
    'bandy',
    'baseball',
    'basketball',
    'beach_soccer',
    'beach_volleyball',
    'boxing',
    'esport_fifa',
    'esport_nba2k',
    'floorball',
    'futsal',
    'handball',
    'ice_hockey',
    'kabaddi',
    'mma',
    'rugby_league',
    'rugby_union',
    'snooker',
    'soccer',
    'sumo',
    'table_tennis',
    'tennis',
    'volleyball',
    'waterpolo',
  ],
  sportsCoreBucketUri: process.env.VUE_APP_SPORTS_CORE_BUCKET_URI || '',
  exchangeRatesUri: process.env.VUE_APP_EXCHANGE_RATE_URI ||
    'https://storage.googleapis.com/kubershmuber-dev-sports-eu/currency-service/v1/exchange-rate.json',
  redashUri: process.env.VUE_APP_REDASH_URI || '',
  enableCompetitionPriorities: process.env.VUE_APP_ENABLE_COMPETITION_PRIORITIES === 'true',
  enablePlayerBetSettings: process.env.VUE_APP_ENABLE_PLAYER_BET_SETTINGS === 'true',
  pusher: {
    apiKey: process.env.VUE_APP_PUSHER_KEY || '0f312ee896c4ce4def43', // stg key
    cluster: process.env.VUE_APP_PUSHER_CLUSTER || 'eu', // stg cluster
  },
  ledger: {
    host: process.env.VUE_APP_LEDGER_HOST || 'ledger.chipzeus.com',
  },
  sportsExchange: {
    wsUri: process.env.VUE_APP_SPORTS_EXCHANGE_WS_URI || '',
  },
  enableExchangeMarkets: process.env.VUE_APP_ENABLE_EXCHANGE_MARKETS === 'true',
  casino: {
    algoliaClient: {
      appId: process.env.VUE_APP_CASINO_ALGOLIA_CLIENT_APP_ID || '',
      apiKey: process.env.VUE_APP_CASINO_ALGOLIA_CLIENT_API_KEY || '',
      indexNameCasinoGame: process.env.VUE_APP_CASINO_ALGOLIA_INDEX_NAME_CASINO_GAME || '',
    },
    evolution: {
      backoffice_url: process.env.VUE_APP_CASINO_EVOLUTION_BACKOFFICE_URL || '',
    },
    games: {
      images_url: process.env.VUE_APP_CASINO_GAMES_IMAGES_URL || '',
    },
    hub_game_url: process.env.CASINO_HUB_GAME_URL || 'https://casino-hub.chipzeus.com/admin/content/games',
  },
  feature: {
    enableCSGrading: process.env.VUE_APP_ENABLE_CS_GRADING === 'true',
    enableGrading: process.env.VUE_APP_ENABLE_GRADING === 'true',
    enableEventsMatching: process.env.VUE_APP_ENABLE_EVENTS_MATCHING === 'true',
    enableWithdrawalApproval: process.env.VUE_APP_ENABLE_WITHDAWAL_APPROVAL === 'true',
  },
  rollbar: {
    accessToken: process.env.VUE_APP_ROLLBAR_ACCESS_TOKEN || '',
    environment: process.env.VUE_APP_ROLLBAR_ENVIRONMENT || 'dev',
  },
};

