import Vue from 'vue';
import { VBtn, VCol, VProgressCircular, VTextarea, VTextField, VSnackbar, VSelect } from 'vuetify/lib';
import { gql } from 'apollo-boost';

import { hasRoles } from '@/utils/auth';
import { cryptoCurrencies } from '@/static';
import './MissingDeposit.less';

const MissingDeposit =  Vue.extend({
  props: {
    pid: String,
  },
  data() {
    return {
      playerUUID: this.pid,
      currency: '',
      hash: '',
      address: '',
      eurLimitOverride: 0,
      resyncError: '',
      showConfirmation: false,
      disableSubmit: true,
      loading: false,
    };
  },
  methods: {
    toggleConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },
    onChange(e: KeyboardEvent) {
      this.disableSubmit = (this.playerUUID && this.address && this.currency && this.hash) ? false : true;
      if (!this.disableSubmit && (e.key === 'Enter' || e.code === 'Enter')) {
        this.onSubmitMissing();
      }
    },
    async onSubmitMissing() {
      this.disableSubmit = true;
      this.loading = true;
      const result = await this.$apollo.mutate({
        mutation: gql`
          mutation ($playerUUID: String!, $address: String!, $currency: String!, $hash: String!, $eurLimit: Float) {
            paymentsResyncDeposit(playerUUID: $playerUUID, address: $address, currency: $currency, hash: $hash, eurLimit: $eurLimit) {
              result
            }
          }
        `,
        variables: {
          playerUUID: this.playerUUID,
          address: this.address,
          currency: this.currency,
          hash: this.hash,
          eurLimit: Number(this.eurLimitOverride),
        },
      });

      if (result.data.paymentsResyncDeposit.result === 'OK') {
        this.resyncError = '';
      } else {
        this.resyncError = result.data.paymentsResyncDeposit.result;
      }

      this.disableSubmit = false;
      this.loading = false;
      this.toggleConfirmation();
    },
  },
  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const InputType = mobile ? VTextarea : VTextField;
    return (
      <div>
        <VCol class='resync-form'>
          <div class='mr-1 payments-dropdown'>
            <VSelect
              label='Currency'
              vModel={this.currency}
              items={cryptoCurrencies}
              dense
              outlined
              onChange={this.onChange}
            />
          </div>

          <div class='mr-1 item-hash'>
            <InputType
              label='Transaction Hash'
              vModel={this.hash}
              placeholder='0x6f211678c784e3edd4a192affbdfc32e78d22442c27f3546f1024f1525041889'
              dense
              outlined
              onKeyup={this.onChange}
            />
          </div>

          {
            !this.pid ?
              <div class='mr-1 item-hash'>
                <InputType
                  label='Player UUID'
                  vModel={this.playerUUID}
                  placeholder='00000000-0000-0000-0000-000000000000'
                  dense
                  outlined
                  onKeyup={this.onChange}
                />
              </div>
              : <div/>
          }

          <div class='mr-1 item-hash'>
            <InputType
              label='Address'
              vModel={this.address}
              placeholder='0x733a264389f6833150c2ce6e8375f8b703dd27fd'
              dense
              outlined
              onKeyup={this.onChange}
            />
          </div>

          <div class='text-caption item-limit' id='eur-limit-note'>
            Note: This max amount is the upper bound of the transaction amount, a safe guard for 3rd party fetcher data.
            0 for system default. For large amount resync, first verify the transaction amount on a blockchain explorer,
            and put the upper bound here. e.g. 500 for a 400EUR transaction.
          </div>
          <div class='mr-1 item-hash'>
            <InputType
              label='Maximum credit amount (EUR)'
              type='number'
              vModel={this.eurLimitOverride}
              placeholder='200'
              dense
              outlined
              onKeyup={this.onChange}
            />
          </div>

          <div class='item-action'>
            {hasRoles(['cs:operator', 'cs:user', 'payments:operator', 'payments:user']) && (
              <div>
                <VBtn onClick={this.onSubmitMissing} disabled={this.disableSubmit}>
                  {this.loading ? <VProgressCircular indeterminate size={12} /> : 'Resync'}
                </VBtn>
              </div>
            )}
          </div>
        </VCol>
        <VSnackbar
          color={this.resyncError ? 'red' : 'green'}
          vModel={this.showConfirmation}
          timeout='5000'
          top
        >
          <div class='text-center'>
            {this.resyncError ? this.resyncError : 'OK'}
          </div>
        </VSnackbar>
      </div >
    );
  },
});

export default MissingDeposit;
