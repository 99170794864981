import { hasRoles } from '@/utils/auth';
import { CashoutRef } from './queries';

interface Cancellable {
  state: string;
  transitions: {
    mayCancel: boolean;
  };
}

export const cancelActionPermitted = (bet: Cancellable): boolean => {
  return hasRoles(['sports:operator']) &&
    bet.state !== 'cancelled' &&
    bet.transitions.mayCancel;
};

interface Reconfirmable {
  cashouts: CashoutRef[];
  isCashoutBet: boolean;
  state: string;
  transitions: {
    mayConfirm: boolean;
  };
}

export const reconfirmActionPermitted = (bet: Reconfirmable): boolean => {
  return hasRoles(['sports:operator']) &&
    bet.state === 'cancelled' &&
    (bet.transitions.mayConfirm || true) &&
    bet.cashouts.length === 0 &&
    !bet.isCashoutBet;
};
