import { env } from '@/env';
import store from '@/store';
import * as firebase from 'firebase/app';
import { googleAuthConvertor } from './google-auth-convertor';

export { googleSignIn } from './google-sign-in';
export { firebaseSignOut } from './firebase-sign-out';

/** @description a helper to initialize firebase */
export const initializeFirebase = () => {
  let unsubscribeMetadataObserver: any = null;
  let refreshTime: any = null;

  firebase.initializeApp(env.firebase);
  firebase.auth().onIdTokenChanged(async (user) => {
    // Remove previous listener.
    if (unsubscribeMetadataObserver) {
      unsubscribeMetadataObserver();
    }
    if (user) {
      const commitUserUpdate = async () => {
        const formattedUser = googleAuthConvertor(
          await user.getIdTokenResult(),
        );
        store.commit('auth/UPDATE_USER', formattedUser);
      };
      // Check if refresh is required.
      unsubscribeMetadataObserver = firebase
        .firestore()
        .collection('metadata')
        .doc(user.uid)
        .onSnapshot(async (snapshot: any) => {
          const newRefreshTime = snapshot.data().refreshTime;

          if (refreshTime && newRefreshTime !== refreshTime) {
            // Force refresh to pick up the latest custom claims changes.
            await user.getIdToken(true);
            commitUserUpdate();
          }

          refreshTime = newRefreshTime;
        });

      commitUserUpdate();
    } else {
      store.commit('auth/CLEAR_USER');
    }
  });
};
