import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VChip, VCol, VDataTable, VIcon, VRow, VSelect } from 'vuetify/lib';
import { hasRoles } from '@/utils/auth';
import { blockchains, cryptoCurrencies } from '@/static';
import FailSnackbar from '@/components/FailSnackbar';
import OkSnackbar from '@/components/OkSnackbar';
import CancelWithdrawal from './CancelWithdrawal';

interface Transaction {
  date: string;
  type: string;
  amount: string;
  fee: string;
  currency: string;
  state: string;
  blockcahin: string;
  address: string;
  hash: string;
  reference: string;
  description: string;
}

const types = [
  {text: 'All', value: ''},
  {text: 'Deposit', value: 'deposit'},
  {text: 'Withdrawal', value: 'withdrawal'},
];

const states = [
  {text: 'All', value: ''},
  {text: 'Pending', value: 'pending'},
  {text: 'Confirmed', value: 'confirmed'},
  {text: 'Failed', value: 'failed'},
  {text: 'Rejected/Cancelled', value: 'rejected'},
  {text: 'Pending email confirmation', value: 'pending_email_confirmation'},
  {text: 'Processing', value: 'processing'},
];

const cancellableStates = ['pending', 'pending_email_confirmation', 'processing'];

export default Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      currency: '',
      blockchain: '',
      type: '',
      state: '',
      page: 1,
      pageSize: 20,
      isLoading: true,
      isLoadFailed: false,
      loadError: '',
      labrador: {
        transactions: [] as Transaction[],
        total: 0,
      },
      isOK: false,
      showCancel: false,
      reference: '',
    };
  },

  computed: {
    currencies() {
      cryptoCurrencies.unshift({text: 'All', value: ''});
      return cryptoCurrencies;
    },

    blockchains() {
      blockchains.unshift({text: 'All', value: ''});
      return blockchains;
    },

    headers() {
      return [
        { text: 'Date', align: 'start', sortable: false, value: 'date', width: '170px' },
        { text: 'Reference', align: 'start', sortable: false, value: 'reference' },
        { text: 'State', align: 'start', sortable: false, value: 'state' },
        { text: 'Type', align: 'start', sortable: false, value: 'type' },
        { text: 'Amount', align: 'end', sortable: false, value: 'amount' },
        { text: 'Currency', align: 'start', sortable: false, value: 'currency' },
        { text: 'Blockchain', align: 'start', sortable: false, value: 'blockchain' },
        { text: '', align: 'start', sortable: false, value: 'action' },
        { text: 'Description', sortable: false, align: 'start', value: 'description', width: '200px' },
        { text: 'Transaction', sortable: false, align: 'start', value: 'hash' },
        { text: 'Address', align: 'start', sortable: false, value: 'address' },
        { text: 'Fee', align: 'end', sortable: false, value: 'fee' },
      ];
    },
  },

  async mounted() {
    this.fetchTransactions();
  },

  methods: {
    async fetchTransactions(): Promise<any> {
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($playerUUID: String!, $currency: String, $blockchain: String, $type: String, $state: String, $page: Int, $pageSize: Int) {
              cryptoTransactions(playerUUID: $playerUUID, currency: $currency, blockchain: $blockchain, type: $type, state: $state, page: $page, pageSize: $pageSize) {
                total
                transactions {
                  date
                  type
                  amount
                  fee
                  currency
                  state
                  blockchain
                  address
                  hash
                  reference
                  description
                }
              }
            }`,
          variables: {
            playerUUID: this.playerUUID,
            currency: this.currency,
            blockchain: this.blockchain,
            type: this.type,
            state: this.state,
            page: this.page,
            pageSize: this.pageSize,
          },
          fetchPolicy: 'network-only',
        });
        this.labrador.total = response.data.cryptoTransactions.total;
        this.labrador.transactions = response.data.cryptoTransactions.transactions;
        this.labrador.transactions.forEach((transaction, i, transactions) => {
          if (transaction.fee === null) {
            transactions[i].fee = 'N/A';
          }
        });
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
      window.scrollTo(0, 0);
    },

    handleChange() {
      this.page = 1;
      this.fetchTransactions();
    },

    handlePagination(options: any) {
      if (this.page === options.page && this.pageSize === options.itemsPerPage) {
        return;
      }

      this.page = options.page;
      this.pageSize = options.itemsPerPage;
      this.fetchTransactions();
    },

    getColor(word: string): string {
      switch (word) {
        case 'DEPOSIT': return 'blue';
        case 'WITHDRAWAL': return 'purple';
        case 'CONFIRMED': return 'green';
        case 'CANCELLED': return 'black';
        case 'PENDING': return 'blue';
        case 'SENDING': return 'cyan';
        case 'PENDING_EMAIL_CONFIRMATION': return 'dark-blue';
        default: return 'red';
      }
    },

    openCancelDialog(reference: string) {
      this.reference = reference;
      this.showCancel = true;
    },

    async cancelWithdrawal(reason: string): Promise<any> {
      this.showCancel = false;
      this.isLoading = true;
      this.isLoadFailed = false;
      this.loadError = '';
      this.isOK = false;
      try {
        await this.$apollo.mutate({
          mutation: gql`
            mutation($reference: Int!, $reason: String!) {
              cancelPlayerWithdrawal(reference: $reference, reason: $reason)
            }`,
          variables: {
            reference: parseInt(this.reference, 10),
            reason,
          },
        });
        this.isOK = true;
        // sleep 1 second to wait for the server to update the database
        await new Promise((resolve) => setTimeout(resolve, 1000));
        this.isOK = false;
        this.fetchTransactions();
      } catch (err) {
        this.isLoadFailed = true;
        this.isLoading = false;
        this.loadError = String(err);
      }
      window.scrollTo(0, 0);
    },
  },

  render() {
    if (!hasRoles(
      ['cs:operator', 'cs:user', 'finance:operator', 'finance:user', 'payments:operator', 'payments:user', 'pii:operator', 'pii:user'])) {
      return (
        <div class='container'>
          Unauthorized
        </div>
      );
    }
    return (
      <div class='container'>
        <FailSnackbar show={this.isLoadFailed} error={this.loadError} />
        <OkSnackbar show={this.isOK} />
        <VCol>
          <VRow>
            <VSelect
              class='payments-dropdown'
              label='Currency'
              vModel={this.currency}
              items={this.currencies}
              dense
              outlined
              onChange={this.handleChange}
            />
            <VSelect
              class='payments-dropdown'
              label='Blockchain'
              vModel={this.blockchain}
              items={this.blockchains}
              dense
              outlined
              onChange={this.handleChange}
            />
            <VSelect
              class='payments-dropdown'
              label='Type'
              vModel={this.type}
              items={types}
              dense
              outlined
              onChange={this.handleChange}
            />
            <VSelect
              class='payments-dropdown'
              label='State'
              vModel={this.state}
              items={states}
              dense
              outlined
              onChange={this.handleChange}
            />
            <VBtn color='blue' onClick={this.fetchTransactions}>
              <VIcon>autorenew</VIcon>
            </VBtn>
          </VRow>
        </VCol>
        <VDataTable
          headers={this.headers}
          items={this.labrador.transactions}
          scopedSlots={{
            'item.type': ({ item }: { item: Transaction }) => (
              <VChip color={this.getColor(item.type)} text-color='white' small>{item.type}</VChip>
            ),
            'item.state': ({ item }: { item: Transaction }) => (
              <VChip color={this.getColor(item.state)} text-color='white' small>{item.state}</VChip>
            ),
            'item.address': ({ item }: { item: Transaction }) => (
              <p class='text-truncate'>{item.address}</p>
            ),
            'item.hash': ({ item }: { item: Transaction }) => (
              <p class='text-truncate'>{item.hash}</p>
            ),
            'item.action': ({ item }: { item: Transaction }) => (
              hasRoles(['cs:operator', 'finance:operator', 'payments:operator']) &&
                item.type.toLowerCase() === 'withdrawal' &&
                cancellableStates.includes(item.state.toLowerCase()) ?
                <VBtn color='red' onClick={() => this.openCancelDialog(item.reference)} disabled={this.isLoading}>
                  Reject
                </VBtn> : ''
            ),
          }}
          loading={this.isLoading}
          loading-text='Loading... Please wait'
          page={this.page}
          items-per-page={this.pageSize}
          server-items-length={this.labrador.total || 0}
          onPagination={this.handlePagination}
          footerProps={{'items-per-page-options': [10, 20, 50, 100, 200, 1000]}}
        />
        <CancelWithdrawal show={this.showCancel} id={this.reference}
          onReject={this.cancelWithdrawal}
          onCancel={() => this.showCancel = false}
        />
      </div>
    );
  },
});
