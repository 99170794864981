import { RouterLink } from '@/router';
import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VCard, VCardTitle, VCardSubtitle,
  VCardText, VIcon, VSpacer, VCardActions, VBtn, VSnackbar,
  VChip, VDivider, VOverlay, VProgressCircular, VCol,
} from 'vuetify/lib';

import { hasRoles } from '@/utils/auth';
import RejectWithdrawalDialog from './RejectWithdrawalDialog';

export const WithdrawalItemWidget = Vue.extend({
  props: ['item', 'refetchAll', 'setInProgress'],
  data() {
    return {
      show: true,
      loading: false,
      snackbar: {
        show: false,
        isError: false,
        message: '',
      },
      enableRejectDialog: false,
    };
  },
  methods: {
    userLink() {
      if (this.item.reference.startsWith('Player')) {
        return <RouterLink target='_blank' to={`/players/${this.item.userUUID}`}>{this.item.userNickname}</RouterLink>;
      }
      return <RouterLink target='_blank' to={`/affiliates/${this.item.userUUID}`}>{this.item.userNickname}</RouterLink>;
    },
    truncateCopyAddress() {
      const len = this.item.address.length;
      if (len > 10) {
        return <span>{this.item.address.substring(0, 10)}...{this.item.address.substring(len - 10, len)}</span>;
      }
      return <span>{this.item.address}</span>;
    },
    copyAddress() {
      navigator.clipboard.writeText(this.item.address);
      this.snackbar.isError = false;
      this.snackbar.message = `The address has been copied to your clipboard.`;
      this.snackbar.show = true;
    },
    hide() {
      this.show = false;
    },
    showMessage(message: string, isError: boolean) {
      this.snackbar.isError = isError;
      this.snackbar.message = message;
      this.snackbar.show = true;
    },
    async retry() {
      const reference = this.item.reference;
      this.loading = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation($reference: String!) {
            paymentsRetryWithdrawal(reference: $reference){
              result
            }
          }`,
          fetchPolicy: 'no-cache',
          variables: {
            reference,
          },
        });
        if (result.data.paymentsRetryWithdrawal.result === 'OK') {
          this.showMessage(`${reference} has been successfully retried.`, false);
        } else {
          this.showMessage(`${reference} retry failed.`, true);
        }
      } catch (err) {
        this.showMessage(`${reference} ~ ${(err as any)?.message}`, true);
      } finally {
        await this.refetchAll();
        this.loading = false;
      }
    },
    async reject() {
      this.enableRejectDialog = true;
    },
  },
  render() {
    if (!hasRoles([
      'payments:operator',
      'payments:user',
      'cs:operator',
      'cs:user',
      'ops:operator',
      'ops:user',
      'finance:operator',
      'finance:user',
    ]) || !this.show) {
      return <template></template>;
    }

    return (
      <VCol cols='12' xs='12' sm='6' md='4' lg='3' class='withdrawal-item' ref='self'>

        <VOverlay value={this.loading}>
          <VProgressCircular
            size='70'
            width='7'
            indeterminate
            color='amber' />
        </VOverlay>

        {
          this.enableRejectDialog ?
            <RejectWithdrawalDialog
              reference={this.item.reference}
              onDialogClosed={async () => {
                this.enableRejectDialog = false;
                await this.refetchAll();
                this.loading = false;
              }}
            />
            : <div />
        }

        <VCard elevation='1'>
          <VCardTitle class='d-flex justify-space-between'>
            <span>{this.item.reference}</span>
            <VBtn onClick={this.hide}>Hide</VBtn>
          </VCardTitle>
          <VDivider></VDivider>

          <VCardSubtitle class='py-2'>
            <span class={
              this.item.state === 'failed_unknown'
                ? 'red--text font-weight-bold'
                : ''
            }>{this.item.state}</span>
            <div>
              {
                this.item.description.length > 50
                  ? `${this.item.description.substring(0, 30)}...`
                  : this.item.description
              }
            </div>
          </VCardSubtitle>

          <VCardSubtitle>
            {this.userLink()}
          </VCardSubtitle>
          <VCardText class='py-0'>
            {this.truncateCopyAddress()}&nbsp;
            <VIcon class='content-copy' onClick={this.copyAddress}>content_copy</VIcon>
            <br />
            {this.item.tagOrMemo && <span>{this.item.tagOrMemo}</span>}
          </VCardText>

          <VCardText>
            <VChip color='green'>{this.item.currency} {this.item.amount}</VChip>
            <br/>
            {this.item.blockchain && <VChip color='orange'>{this.item.blockchain}</VChip>}
          </VCardText>
          <VCardText>
            <VChip color='primary' outlined pilled>{this.item.updatedAt}</VChip>
          </VCardText>

          <VDivider></VDivider>
          {
            hasRoles(['payments:operator', 'finance:operator']) ?
              (
                <VCardActions align='right'>
                  <VBtn color='red' onClick={this.reject}>Reject</VBtn>
                  <VSpacer></VSpacer>
                  <VBtn color='primary' onClick={this.retry}>Retry</VBtn>
                </VCardActions>
              ) : <div></div>
          }
        </VCard>
        <VSnackbar
          color={this.snackbar.isError ? 'red' : 'green'}
          vModel={this.snackbar.show}
          timeout='5000'
          top
        >
          <div class='text-center'>
            {this.snackbar.message}
            <VBtn text onClick={() => this.snackbar.show = false}>
              Close
            </VBtn>
          </div>
        </VSnackbar>
      </VCol>
    );
  },
});
