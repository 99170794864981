import { validateOptionalNumber, validateNumber, validateRequired, validateInteger } from '@/utils/validate';
import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VCol,
  VDialog,
  VForm,
  VRow,
  VSelect,
  VTextField,
  VAlert,
  VSwitch,
  VOverlay,
  VProgressCircular,
} from 'vuetify/lib';
import { Mode, PlayerInput, FixedSchedule } from './interfaces';
import { required, numeric } from 'vuelidate/lib/validators';
import gql from 'graphql-tag';

export const LoyaltyDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    error: String,
    playerInput: Object as () => PlayerInput,
  },

  data() {
    return {
      input: {} as PlayerInput,
      fixedSchedules: [] as FixedSchedule[],
      loading: false,
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSave() {
      if (!this.input.tier) {
        this.input.tier = undefined;
      }

      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit('save', this.input);
      }
    },
  },

  computed: {
    form() {
      return <VForm ref='form'>
        <VSelect
          items={Object.values(Mode)}
          label='Mode'
          dense
          outlined
          vModel={this.input.mode}
        />

        <VTextField
          vModel={this.input.tier}
          label='Sports tier'
          dense
          outlined
          rules={[validateOptionalNumber]}
        />

        { this.input.mode === Mode.Vip &&
          <VSelect
            items={this.fixedSchedules.map((s) => ({ value: s.id, text: s.name }))}
            label='Schedule'
            dense
            outlined
            vModel={this.input.scheduleID}
            clearable
          /> }

        { this.input.mode !== Mode.Custom && <VTextField
          vModel={this.input.telegramContact}
          label='Telegram contact'
          dense
          outlined
        /> }

        { this.input.mode === Mode.Vip &&
          <VSwitch
            label='Schedule hidden to player'
            dense
            outlined
            rules={[required]}
            vModel={this.input.fixedScheduleHiddenToPlayer}
          /> }

        { this.error && <VRow class='pa-4'><VAlert type='warning'>{this.error}</VAlert></VRow> }
        { this.input.mode === Mode.Custom &&
          <VRow class='pa-4'><VAlert type='warning'>Custom mode is legacy. Use VIP instead.</VAlert></VRow> }
        <VRow>
          <VCol align='end'>
            <VBtn disabled={this.input.mode === Mode.Custom} onClick={this.onSave} color='primary'>
              Save
            </VBtn>
          </VCol>
        </VRow>
      </VForm>;
    },
  },

  created() {
    this.input = JSON.parse(JSON.stringify(this.playerInput)); // deep copy
  },

  watch: {
    error() {
      this.loading = false;
    },
  },

  apollo: {
    fixedSchedules: {
      client: 'sportsLoyaltyService',
      query: gql`
        query {
          fixedSchedules {
            id
            name
          }
        }
      `,
      pollInterval: 5000,
    },
  },

  render() {
    return (
      <VDialog
        width='unset'
        value={this.isOpen}
        onInput={this.onClose}>
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>

          <VCardTitle>
            Player loyalty
          </VCardTitle>

          <VCardText>
            {this.form}
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
