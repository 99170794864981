import Vue from 'vue';
import {
  VCard,
  VIcon,
  VBtn,
  VProgressLinear,
  VSnackbar,
  VTextField,
  VSelect,
  VTextarea,
} from 'vuetify/lib';
import './Announcements.less';
import { Announcement } from '@/components/Announcement/Announcement';
import { mapActions, mapGetters } from 'vuex';
import { hasRoles } from '@/utils/auth';
import { announcementTypes, announcementScopes, announcementCasinoScopes } from '@/config/announcement';
import { MessageKey } from '@/types/announcements';

export const Announcements = Vue.extend({
  data() {
    return {
      newAnnouncementType: '',
      newAnnouncementScope: '',
      newAnnouncementEnMessage: '',
      hasAddAnnouncementError: false,
      isLoading: true,
      hasViewPermissionError: false,
      showConfirmation: false,
    };
  },
  computed: {
    ...mapGetters({
      announcements: 'announcements/announcements',
    }),
  },

  methods: {
    ...mapActions({
      getAnnouncements: 'announcements/getAnnouncements',
      createAnnouncement: 'announcements/createAnnouncement',
    }),
    toggleConfirmation() {
      this.showConfirmation = !this.showConfirmation;
    },
    getScopesForUser() {
      if (hasRoles(['ops:operator'])) {
        return announcementScopes;
      }
      if (hasRoles(['casino:operator'])) {
        return announcementCasinoScopes;
      }
      return [];
    },
    async handleAdd() {
      try {
        await this.createAnnouncement({
          name: this.newAnnouncementEnMessage,
          type: this.newAnnouncementType,
          scope: this.newAnnouncementScope,
          enable: false,
          messages: [{
            key: MessageKey.en, value: this.newAnnouncementEnMessage,
          }],
          messagesUpdatedAt: [{
            key: MessageKey.en, value: new Date().toISOString(),
          }],
        });
        this.newAnnouncementEnMessage = '';
        this.newAnnouncementType = '';
        this.hasAddAnnouncementError = false;
      } catch (err) {
        this.hasAddAnnouncementError = true;
      } finally {
        this.toggleConfirmation();
      }
    },
  },
  async mounted() {
    try {
      await this.getAnnouncements({});
      this.isLoading = false;
    } catch (err) {
      if (err?.graphQLErrors[0]?.extensions?.response?.status === 401) {
        this.hasViewPermissionError = true;
      }
      this.isLoading = false;
    }
  },
  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const noAnnouncementMessage = (
      <VCard class='pa-4'>
        No announcements.
      </VCard>
    );
    if (this.hasViewPermissionError) {
      return (
        <VCard color='orange' class='ma-4 pa-4 text-center'>
          Need permissions to view announcements
        </VCard>
      );
    }
    const InputType = mobile ? VTextarea : VTextField;
    const announcementInputForm = (
      <div class={mobile ? 'announcement-input-container-mobile' : 'announcement-input-container'}>
        <InputType
          vModel={this.newAnnouncementEnMessage}
          label='Announcement message in English'
          dense
          outlined
          class='announcement-name'
        />
        <VSelect
          items={announcementTypes}
          vModel={this.newAnnouncementType}
          label='type'
          dense
          outlined
          placeholder='Create new announcement'
          class='announcement-type-select'
        />
        <VSelect
          items={this.getScopesForUser()}
          vModel={this.newAnnouncementScope}
          label='scope'
          dense
          outlined
          placeholder='Create new announcement'
          class='announcement-scope-select'
        />
        <VBtn
          onClick={this.handleAdd}
          class={mobile ? 'announcement-add-button' : ''}
          icon
          disabled={
            !this.newAnnouncementType.length ||
            !this.newAnnouncementEnMessage.length ||
            !this.newAnnouncementScope.length
          }
        >
          <VIcon>add</VIcon>
        </VBtn>
      </div>
    );
    const announcementsList = this.announcements.announcements?.map(
      (announcement: any) => {
        return (
          <Announcement
            key={announcement.uuid}
            uuid={announcement.uuid}
            type={announcement.type}
            name={announcement.name}
            messages={announcement.messages}
            enable={announcement.enable}
            messagesUpdatedAt={announcement.messagesUpdatedAt}
            scope={announcement.scope}
          />
        );
      },
    );
    return (
      <div class='ma-4'>
         {(hasRoles(['ops:operator']) || hasRoles(['casino:operator'])) && announcementInputForm}
        <h1>Announcements</h1>
        <div class={mobile ? 'announcements-header announcements-header-mobile' : 'announcements-header'}>
          <div class='announcements-header-type'>Type</div>
          <div class='announcements-header-scope'>Scope</div>
          <div class='announcements-header-message'>Message</div>
          <div>Status</div>
        </div>
        {this.isLoading ? (
          <VProgressLinear indeterminate />
        ) : !this.announcements.announcements.length ? (
          noAnnouncementMessage
        ) : (
          announcementsList
        )}
        <VSnackbar
          color={this.hasAddAnnouncementError ? 'red' : 'green'}
          vModel={this.showConfirmation}
          timeout='1000'
          top
        >
          <div class='text-center'>
            {this.hasAddAnnouncementError ? 'Failed to create announcement' : 'Successfully to created announcement'}
          </div>
        </VSnackbar>
      </div>
    );
  },
});
