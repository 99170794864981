import './style.less';
import Vue from 'vue';
import {
  VBtn,
  VBtnToggle,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCol,
  VContainer,
  VDialog,
  VDivider,
  VRow,
  VSpacer,
  VSheet,
  VTextField,
} from 'vuetify/lib';
import { PendingLine, PendingOutcome } from '@/types/sports';
import {
  getMarketName,
  getOutcome,
  Locale,
  MarketType,
} from '@cloudbet/market-helper/sports-core';
import { mapActions, mapGetters } from 'vuex';

export default Vue.extend({
  name: 'gradeMarketWidget',
  props: {
    market: Object,
    open: Boolean,
    closeDialog: Function,
    eventId: String,
    eventName: String,
  },
  data() {
    return {
      result: {} as any,
    };
  },
  watch: {
    market() {
      this.result = {};
      (this.market.pendingLines || []).map((pendingLine: PendingLine) => {
        this.result[pendingLine.params] = {};
        pendingLine.pendingOutcomes.map((pendingOutcome: PendingOutcome) => {
          this.result[pendingLine.params][pendingOutcome.outcome] = { result: 'PENDING' };
        });
      });
    },
  },
  methods: {
    ...mapActions({
      submitEventResults: 'pendingEvents/submitEventResults',
    }),
  },
  computed: {
    ...mapGetters({
      submittingResult: 'pendingEvents/submittingResult',
    }),
  },
  render() {
    let marketName;
    if (this.open) {
      const marketParser = getMarketName(
        Locale.en,
        { params: this.market.pendingLines[0].params },
        this.market.marketKey as MarketType,
        {
          home: {
            name: 'Home',
            key: 'home',
            abbreviation: 'home',
          },
          away: {
            name: 'away',
            key: 'away',
            abbreviation: 'away',
          },
        } as any,
      );
      marketName = marketParser[0];
    }
    return (
      <VDialog vModel={this.open} persistent max-width='600px'>
        <VCard>
          <VCardTitle>
            <h1 class='mb-2'>{this.eventName}</h1>
            <h2>{marketName}</h2>
          </VCardTitle>
          <VDivider />
          <VCardText>
            <VContainer>
              {(this.market.pendingLines || []).map((pendingLine: PendingLine) => {
                return (
                  <div>
                    {pendingLine.pendingOutcomes.map((pendingOutcome: PendingOutcome) => {
                      const result = getOutcome(Locale.en,
                        {
                          home: {
                            name: 'Home',
                            key: 'home',
                            abbreviation: 'home',
                          },
                          away: {
                            name: 'Away',
                            key: 'away',
                            abbreviation: 'away',
                          },
                        },
                        this.market.marketKey as MarketType,
                        {
                          params: pendingLine.params,
                          outcome: pendingOutcome.outcome,
                        });
                      const outcome = result[0]?.name || pendingOutcome.outcome;
                      return (
                        <VCol>
                          <VRow>
                            <VSheet key={outcome} elevation='1'>
                              <VCol>
                                <h2>Outcome: {outcome}</h2>
                                <strong>Pending Selections: {pendingOutcome.numberOfPendingSelections}</strong>
                              </VCol>
                              <VCol>
                                <VBtnToggle
                                  vModel={this.result[pendingLine.params][pendingOutcome.outcome].result}
                                  mandatory={true}>
                                  <VBtn value='WIN'>WIN</VBtn>
                                  <VBtn value='LOSS'>LOSS</VBtn>
                                  <VBtn value='PUSH'>PUSH</VBtn>
                                  <VBtn value='HALF_WIN'>HALF WIN</VBtn>
                                  <VBtn value='HALF_LOSS'>HALF LOSS</VBtn>
                                  <VBtn value='PENDING'>PENDING</VBtn>
                                </VBtnToggle>
                                <VTextField
                                  vModel={this.result[pendingLine.params][pendingOutcome.outcome].comment}
                                  label='Comment'
                                >
                                </VTextField>
                              </VCol>
                            </VSheet>
                          </VRow>
                          <VRow>
                            <VDivider />
                          </VRow>
                        </VCol>
                      );
                    })}
                  </div>
                );
              })}
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer />
            <VBtn
              loading={this.submittingResult}
              text
              color='primary'
              onClick={async () => {
                const eventResults = [] as any[];
                Object.keys(this.result).forEach((params) => {
                  Object.keys(this.result[params]).forEach((outcome) => {
                    eventResults.push({
                      marketKey: this.market.marketKey,
                      params,
                      outcome,
                      result: this.result[params][outcome].result,
                      comment: this.result[params][outcome].comment,
                    });
                  });
                });
                await this.submitEventResults({
                  eventId: this.eventId,
                  results: eventResults,
                });
                this.closeDialog();
              }}
            >
              Save
            </VBtn>
            <VBtn text onClick={this.closeDialog}>
              Close
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
