import Vue from 'vue';
import { VBtn, VIcon, VDataTable, VToolbar, VSpacer } from 'vuetify/lib';
import './EventStreamsWidget.less';
import EditDialog from './EditDialog';
import { Event, Stream } from '@/types/sports';
import { gql } from 'apollo-boost';
import ConfirmationDialog from '@/components/ConfirmationDialog';

export default Vue.extend({
  name: 'eventStreamsWidget',
  props: {
    eventId: String,
  },

  data() {
    return {
      event: {} as Event,
      showEditDialog: false,
      showDeleteConfirmationDialog: false,
      editedStream: {} as Stream,
      editedStreamIndex: -1,
      defaultStream: {
        provider: '',
        language: 'en',
        url: '',
      } as Stream,
      headers: [
        { text: 'Provider', value: 'provider', width: 150 },
        { text: 'Language', value: 'language', width: 150 },
        { text: 'Url', value: 'url' },
        { text: 'Actions', value: 'actions', sortable: false, width: 100, align: 'center' },
      ],
      validationErrors: null,
    };
  },

  methods: {
    newStream() {
      this.editedStreamIndex = -1;
      this.editedStream = Object.assign({}, this.defaultStream);
      this.showEditDialog = true;
    },

    editStream(stream: Stream) {
      this.editedStreamIndex = this.event.streams.indexOf(stream);
      this.editedStream = Object.assign({}, stream);
      this.showEditDialog = true;
    },

    deleteStreamWithConfirmation(stream: Stream) {
      this.editedStreamIndex = this.event.streams.indexOf(stream);
      this.editedStream = stream;
      this.showDeleteConfirmationDialog = true;
    },

    deleteStream(stream: Stream) {
      this.showDeleteConfirmationDialog = false;
      const newStreams = [...this.event.streams || []];
      Object.assign(newStreams[this.editedStreamIndex], stream, { url: '' });
      this.updateStreams(newStreams);
    },

    onDialogClosed() {
      this.validationErrors = null;
      this.showEditDialog = false;
    },

    onDialogSaved() {
      const newStreams = [...this.event.streams || []]; // this.event.streams can be `null` if no streams
      if (this.editedStreamIndex > -1) {
        Object.assign(newStreams[this.editedStreamIndex], this.editedStream);
      } else {
        newStreams.push(this.editedStream);
      }

      this.updateStreams(newStreams);
    },

    actions(props: any) {
      return (
        <div>
          <VIcon class='mr-2' onClick={() => this.editStream(props.item)}>edit</VIcon>
          <VIcon onClick={() => this.deleteStreamWithConfirmation(props.item)}>clear</VIcon>
        </div>
      );
    },

    toolbar(/*props*/) {
      return (
        <VToolbar dense flat>
          <VSpacer></VSpacer>
          <VBtn color='primary' dark class='mb-2' onClick={this.newStream} >New Stream</VBtn>
          {this.editDialog}
          {this.deleteStreamConfirmationDialog}
        </VToolbar>
      );
    },

    async updateStreams(newStreams: Stream[]) {
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`mutation ($feed: FeedInput!) {
            updateEventStreams(feed: $feed) {
              streams {
                language
                priority
                provider
                url
              }
            }
          }`,
          variables: {
            feed: {
              eventId: parseInt(this.eventId, 10),
              streams: newStreams.map((s) => {
                return {
                  language: s.language,
                  priority: s.priority,
                  provider: s.provider,
                  url: s.url,
                };
              }),
            },
          },
        });
        this.event.streams = result.data.updateEventStreams.streams;
        this.showEditDialog = false;
      } catch (e) {
        if (e.graphQLErrors.length > 0 && e.graphQLErrors[0].extensions.code === 'BAD_USER_INPUT') {
          this.validationErrors = e.graphQLErrors[0];
        } else {
          throw e;
        }
      }
    },
  },

  computed: {
    editDialog() {
      if (this.showEditDialog) {
        return (
          <EditDialog
            stream={this.editedStream}
            onClose={this.onDialogClosed}
            onSave={this.onDialogSaved}
            validationErrors={this.validationErrors}>
          </EditDialog>
        );
      }
    },

    deleteStreamConfirmationDialog() {
      if (this.showDeleteConfirmationDialog) {
        return (
          <ConfirmationDialog
            title={`Clear this ${this.editedStream.provider} stream url?`}
            text={`This will clear the ${this.editedStream.provider} url. The entry will not be deleted.`}
            item={this.editedStream}
            onAgree={this.deleteStream}
            onDisagree={() => { this.showDeleteConfirmationDialog = false; }} />
        );
      }
    },
  },

  apollo: {
    event: {
      query: gql`query ($eventId: String!) {
        event: event(eventId: $eventId) {
          streams {
            language
            priority
            provider
            url
          }
        }
      }`,
      variables() {
        return { eventId: this.eventId };
      },
    },
  },

  render() {
    return (
      <VDataTable
        class='elevation-1'
        headers={this.headers}
        items={this.event.streams || []}
        scopedSlots={{ 'item.actions': this.actions, 'top': this.toolbar }}
        loading-text='Loading... Please wait'
        loading={this.$apollo.queries.event.loading}
        sortBy={['provider', 'language']}>
      </VDataTable>
    );
  },
});

