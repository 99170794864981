var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"events-widget pa-0",attrs:{"fluid":""}},[_c('v-data-table',{staticClass:"events-table",attrs:{"items":_vm.events,"headers":_vm.headers,"disable-pagination":true,"hide-default-footer":true,"loading":!_vm.dataLoaded,"item-class":_vm.itemClass},scopedSlots:_vm._u([{key:"header.sportName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.sportKeyFilter)?_c('span',[_vm._v(" ("+_vm._s(_vm.sportKeyFilter)+") "),(_vm.filterable)?_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.setSportKeyFilter()}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("remove_circle_outlined")])],1):_vm._e()]):_vm._e()]}},{key:"header.competitionName",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),(_vm.competitionKeyFilter)?_c('span',[_vm._v(" ("+_vm._s(_vm.competitionKeyFilter)+") "),(_vm.filterable)?_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.setCompetitionKeyFilter()}}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("remove_circle_outlined")])],1):_vm._e()]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.status)+" ")]}},{key:"item.sportName",fn:function(ref){
var item = ref.item;
return [(_vm.filterable)?_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.setSportKeyFilter(item.sportKey)}}},[_vm._v(_vm._s(item.sportName))]):_c('span',[_vm._v(_vm._s(item.sportName))])]}},{key:"item.competitionName",fn:function(ref){
var item = ref.item;
return [(_vm.filterable)?_c('span',{staticClass:"clickable",on:{"click":function($event){return _vm.setCompetitionKeyFilter(item.competitionKey)}}},[_vm._v(_vm._s(item.competitionName))]):_c('span',[_vm._v(_vm._s(item.competitionName))])]}},{key:"item.eventName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'event', params: { eventId: item.eventId }}}},[_vm._v(_vm._s(item.eventName)+" "),(item.isOutright)?_c('VChip',{attrs:{"label":"","x-small":"","color":"warning"}},[_vm._v("outright")]):_vm._e()],1)]}},{key:"item.turnoverWithUntracked",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("currency")(item.turnoverWithUntracked,_vm.precision)))])]}},{key:"item.exposure",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"error--text font-weight-bold"},[_vm._v(" "+_vm._s(_vm._f("currency")(-1 * item.exposure,_vm.precision))+" ")])]}},{key:"item.details.worstCase",fn:function(ref){
var item = ref.item;
return [(item.details.worstCase && item.details.worstCase.value != item.details.bestCase.value)?_c('span',{staticClass:"error--text font-weight-bold"},[_c('div',[_vm._v(_vm._s(_vm._f("currency")(-1 * item.details.worstCase.value,_vm.precision)))]),_c('div',[_vm._v(_vm._s(_vm.scoresText(item, item.details.worstCase)))])]):_vm._e()]}},{key:"item.details.bestCase",fn:function(ref){
var item = ref.item;
return [(item.details.bestCase && item.details.worstCase.value != item.details.bestCase.value)?_c('span',{staticClass:"success--text"},[_c('div',[_vm._v(_vm._s(_vm._f("currency")(-1 * item.details.bestCase.value,_vm.precision)))]),_c('div',[_vm._v(_vm._s(_vm.scoresText(item, item.details.bestCase)))])]):_vm._e()]}},{key:"item.details.pnl",fn:function(ref){
var item = ref.item;
return [(item.details.pnl)?_c('span',[_vm._v(_vm._s(_vm._f("currency")(-1 * item.details.pnl,_vm.precision)))]):_vm._e()]}},{key:"item.opinionDiscrepancy",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm._f("round")((item.opinionDiscrepancy * 100),0))+"%")])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }