import Vue from 'vue';
import { VContainer, VTextField, VSelect } from 'vuetify/lib';
import { eventTypeOptions, EventTypeFilter } from '@/static';

interface WidgetData {
  sportKey?: string;
  competitionKey?: string;
  type?: keyof typeof EventTypeFilter;
}

export default Vue.extend({
  props: {
    value: Object as () => WidgetData,
  },
  data() {
    return {
      widgetData: {} as WidgetData,
    };
  },
  methods: {
    emitChanges() {
      this.$emit('input', JSON.parse(JSON.stringify(this.widgetData)));
    },
  },
  created() {
    this.widgetData = JSON.parse(JSON.stringify(this.value));
    if (!this.widgetData.type) { this.widgetData.type = 'all'; }
  },
  render() {
    return (
      <VContainer class='dashboard-widget-subform'>
        <VSelect
          items={eventTypeOptions}
          label='Type'
          v-model={this.widgetData.type}
          onInput={this.emitChanges}
        ></VSelect>
        <VTextField v-model={this.widgetData.sportKey} label='Sport key' />
        <VTextField v-model={this.widgetData.competitionKey} label='Competition key' />
      </VContainer>
    );
  },
  watch: {
    widgetData: {
      deep: true,
      handler() {
        this.emitChanges();
      },
    },
  },
});
