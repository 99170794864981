// views/payments/CryptoDepositResync.tsx
import Vue from 'vue';


import { FailedWithdrawalsWidget } from '@/components/payments/FailedWithdrawalsWidget';
import { hasRoles } from '@/utils/auth';

export default Vue.extend({
  name: 'FailedWithdrawalsWidget',
  render() {
    return (
      <div class='ma-4'>
        {hasRoles([
          'payments:operator',
          'payments:user',
          'cs:operator',
          'cs:user',
          'ops:operator',
          'ops:user',
          'finance:operator',
          'finance:user',
        ])}
        <div class='withdrawal-retry-container'>
          <FailedWithdrawalsWidget />
        </div>
      </div>
    );
  },
});
