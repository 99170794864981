import Vue from 'vue';
import {
  VTextField,
  VList,
  VSelect,
  VListItem,
  VListItemTitle,
} from 'vuetify/lib';
import OutrightTranslationWidget from '@/components/sports/TranslationsWidget/OutrightTranslationWidget';

const DefaultItem = { id: 0, name: '' };

export default Vue.extend({
  name: 'outrightTranslationsWidget',
  data() {
    return {
      sport: DefaultItem,
      name: '',
      locale: 'en',
      locales: this.$store.state.sportsConsts.locales,
    };
  },
  computed: {
    sports() {
      return [{ id: 0, name: '' }, ...this.$store.state.sportsConsts.sports];
    },
    sportsSelect() {
      return (
        <VSelect
          items={this.sports}
          item-value='id'
          item-text='name'
          label='Sport'
          return-object={true}
          v-model={this.sport}
        ></VSelect>
      );
    },
    localesSelect() {
      return (
        <VSelect
          items={this.locales}
          label='Locale'
          return-object={true}
          v-model={this.locale}
          class='ml-10'
        ></VSelect>
      );
    },
  },
  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },
  render() {
    return (
      <div>
        <VList>
          <VListItem>
            <VListItemTitle>Outright Translations</VListItemTitle>
            {this.sportsSelect}
            {this.localesSelect}
            <VTextField
              persistentHint
              hint='required, at least 3 characters'
              class='ml-8'
              label='Name'
              onchange={(val: any) => {
                this.name = val;
              }}
            />
          </VListItem>
        </VList>
        <OutrightTranslationWidget
          name={this.name}
          sportId={this.sport.id}
          locale={this.locale}
        />
      </div>
    );
  },
});
