import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VCol,
  VContainer,
  VDialog,
  VRow,
  VSelect,
  VSpacer,
  VTextField,
} from 'vuetify/lib';

import { AddOrRemoveCasinoLoyaltyPointsInput } from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { LoyaltyPointType } from '@/types/casino/loyalty';

interface AddOrRemoveLoyaltyPointsForm {
  casinoLoyaltyPointType: string;
  loyaltyPoints: string;
  reason: string;
}

interface AddOrRemoveLoyaltyPointsValidationErrors {
  playerUUID?: string;
  casinoLoyaltyPointType?: string;
  loyaltyPoints?: string;
  reason?: string;
}

interface VueData {
  addOrRemoveLoyaltyPointsForm: AddOrRemoveLoyaltyPointsForm;
  addOrRemoveLoyaltyPointsValidationErrors?: AddOrRemoveLoyaltyPointsValidationErrors;
  loading: boolean;
}

const defaultAddOrRemoveLoyaltyPointsValidationErrors = undefined;

export default Vue.extend({
  props: {
    playerUUID: String,
    open: Boolean,
  },
  data(): VueData {
    return {
      addOrRemoveLoyaltyPointsForm: {
        casinoLoyaltyPointType: LoyaltyPointType.LEVEL,
        loyaltyPoints: '',
        reason: '',
      },
      addOrRemoveLoyaltyPointsValidationErrors: defaultAddOrRemoveLoyaltyPointsValidationErrors,
      loading: false,
    };
  },
  methods: {
    clearForms() {
      this.addOrRemoveLoyaltyPointsForm.loyaltyPoints = '';
      this.addOrRemoveLoyaltyPointsForm.reason = '';
      this.addOrRemoveLoyaltyPointsValidationErrors = defaultAddOrRemoveLoyaltyPointsValidationErrors;
    },
    onDialogClose() {
      this.$emit('open', false);
      this.clearForms();
    },
    async onAddOrRemoveLoyaltyPointsSubmit() {
      try {
        this.loading = true;

        const input: AddOrRemoveCasinoLoyaltyPointsInput = {
          playerUUID: this.playerUUID,
          casinoLoyaltyPointType: this.addOrRemoveLoyaltyPointsForm.casinoLoyaltyPointType,
          loyaltyPoints: this.addOrRemoveLoyaltyPointsForm.loyaltyPoints,
          reason: this.addOrRemoveLoyaltyPointsForm.reason,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: AddOrRemoveCasinoLoyaltyPointsInput!) {
              addOrRemoveCasinoLoyaltyPoints(input: $input) {
                casinoLoyaltyPointTransactionId
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.clearForms();
        this.$emit('submit', null);
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        const errURL = errResponse?.url;
        const errStatus = errResponse?.status;
        const errBody = errResponse?.body;

        if (errBody != null) {
          const validationErrors = errBody.validation_errors;

          if (validationErrors != null) {
            this.addOrRemoveLoyaltyPointsValidationErrors = {
              playerUUID: validationErrors.player_uuid,
              casinoLoyaltyPointType: validationErrors.casino_loyalty_point_type,
              loyaltyPoints: validationErrors.loyalty_points,
              reason: validationErrors.reason,
            };
          } else {
            const apiError: ApiError = {
              url: errURL,
              status: errStatus,
              body: errBody,
            };

            this.$emit('submit', apiError);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  render() {
    const addOrRemoveLoyaltyPointsForm = () => {
      return (
        <div>
          <VSelect
            v-model={this.addOrRemoveLoyaltyPointsForm.casinoLoyaltyPointType}
            label='Loyalty Point Type'
            items={[
              {
                text: 'Level',
                value: LoyaltyPointType.LEVEL,
              },
              {
                text: 'Spending',
                value: LoyaltyPointType.SPENDING,
              },
            ]}
            outlined
            dense
            value={this.addOrRemoveLoyaltyPointsForm.casinoLoyaltyPointType}
            error={this.addOrRemoveLoyaltyPointsValidationErrors?.casinoLoyaltyPointType != null}
            errorMessages={this.addOrRemoveLoyaltyPointsValidationErrors?.casinoLoyaltyPointType || []}
            errorCount={5}
          />
          <VTextField
            v-model={this.addOrRemoveLoyaltyPointsForm.loyaltyPoints}
            label='Loyalty Points'
            messages={[
              'Amount of loyalty points to be added or removed.',
              'Use a negative value to remove loyalty points (e.g. -123.45)',
            ]}
            placeholder='123.45'
            clearable
            outlined
            dense
            error={this.addOrRemoveLoyaltyPointsValidationErrors?.loyaltyPoints != null}
            errorMessages={this.addOrRemoveLoyaltyPointsValidationErrors?.loyaltyPoints || []}
            errorCount={5}
          />
          <VTextField
            v-model={this.addOrRemoveLoyaltyPointsForm.reason}
            label='Reason'
            messages={[
              'Reason for adding or removing loyalty points.',
              'For auditing purposes.',
            ]}
            clearable
            outlined
            dense
            error={this.addOrRemoveLoyaltyPointsValidationErrors?.reason != null}
            errorMessages={this.addOrRemoveLoyaltyPointsValidationErrors?.reason || []}
            errorCount={5}
          />
        </div>
      );
    };

    return (
      <VDialog
        v-model={this.open}
        on={{
          'click:outside': () => { this.onDialogClose(); },
        }}
        maxWidth='700'
      >
        <VCard>
          <VCardTitle>
            Add or Remove Casino Loyalty Points Form
          </VCardTitle>
          <VCardText>
            <VContainer>
              <VRow>
                <VCol>
                  {addOrRemoveLoyaltyPointsForm()}
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn onClick={() => { this.onDialogClose(); }}>Close</VBtn>
            <VBtn
              color='primary'
              onClick={() => { this.onAddOrRemoveLoyaltyPointsSubmit(); }}
              loading={this.loading}
              disabled={this.loading}
            >
              Add / Remove Loyalty Points
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
