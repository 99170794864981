import { validateNumber, validateRequired, validateInteger } from '@/utils/validate';
import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VCol,
  VDialog,
  VForm,
  VRow,
  VSelect,
  VTextField,
  VAlert,
  VSwitch,
  VOverlay,
  VProgressCircular,
  VDatePicker,
  VTimePicker,
  VMenu,
} from 'vuetify/lib';
import { OfferType, OfferInput, Offer } from './interfaces';
import { required, numeric } from 'vuelidate/lib/validators';
import gql from 'graphql-tag';

export const OfferDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    error: String,
    offerInput: Object as () => OfferInput,
    offers: Array as () => Offer[],
  },

  data() {
    return {
      input: {} as OfferInput,
      loading: false,
      validUntilDateIsOpen: false,
      validUntilDate: new Date((new Date()).setDate((new Date()).getDate() + 1)).toISOString().substr(0, 10),
      validUntilTimeIsOpen: false,
      validUntilTime: new Date().toISOString().substr(11, 5),
    };
  },

  methods: {
    onClose() {
      this.$emit('close');
    },

    onSave() {
      this.input.validUntil = `${this.validUntilDate}T${this.validUntilTime}:00.00Z`;
      // @ts-ignore
      if (this.$refs.form.validate()) {
        this.loading = true;
        this.$emit('save', this.input);
      }
    },

    typeAdded(t: string) {
      return this.offers.findIndex((o) => o.type === t) > -1;
    },
  },

  computed: {
    form() {
      return <VForm ref='form'>
        <VSelect
          items={Object.values(OfferType).filter((t) => !this.typeAdded(t))}
          label='Type'
          dense
          outlined
          vModel={this.input.type}
          rules={[validateRequired]}
          onChange={() => {
            if (this.input.type === OfferType.RcMultiplier) {
              this.input.multiplier = '1.05';
              this.input.multiplierValidHours = 24;
            } else if (this.input.type === OfferType.XpMultiplier) {
              this.input.multiplier = '2.0';
              this.input.multiplierValidHours = 24;
            }
          }}
        />

        <VRow>
          <VCol>
            <VMenu
              vModel={ this.validUntilDateIsOpen }
              closeOnContentClick={ false }
              nudgeRight={ 40 }
              transition='scale-transition'
              offsetY
              minWidth='auto'
              scopedSlots={{
                activator: ({ on, attrs }: any) => {
                  return <VTextField
                    vModel={ this.validUntilDate }
                    label='Valid until(date)'
                    readonly
                    dense
                    outlined
                    bind={ attrs }
                    on={ on }
                  ></VTextField>;
                },
              }}
            >
              <VDatePicker
                vModel={ this.validUntilDate }
                onInput={ () => this.validUntilDateIsOpen = false }
              ></VDatePicker>
            </VMenu>
          </VCol>
          <VCol>
            <VMenu
              vModel={ this.validUntilTimeIsOpen }
              closeOnContentClick={ false }
              nudgeRight={ 40 }
              transition='scale-transition'
              offsetY
              minWidth='auto'
              scopedSlots={{
                activator: ({ on, attrs }: any) => {
                  return <VTextField
                    vModel={ this.validUntilTime }
                    label='Valid until(time)'
                    readonly
                    dense
                    outlined
                    bind={ attrs }
                    on={ on }
                  ></VTextField>;
                },
              }}
            >
              <VTimePicker
                vModel={ this.validUntilTime }
              ></VTimePicker>
            </VMenu>
          </VCol>
        </VRow>

        <VTextField
          label='Multiplier'
          dense
          outlined
          rules={[validateNumber]}
          vModel={this.input.multiplier}
        />

        <VTextField
          label='Valid hours'
          suffix={'hours'}
          dense
          outlined
          rules={[validateInteger]}
          vModel={this.input.multiplierValidHours}
        />

        { this.error ? <VRow class='pa-4'><VAlert type='warning'>{this.error}</VAlert></VRow> : null }
        <VRow>
          <VCol align='end'>
            <VBtn onClick={this.onSave} color='primary'>
              Create
            </VBtn>
          </VCol>
        </VRow>
      </VForm>;
    },
  },

  created() {
    this.input = JSON.parse(JSON.stringify(this.offerInput)); // deep copy
  },

  watch: {
    error() {
      this.loading = false;
    },
  },

  render() {
    return (
      <VDialog
        width='300'
        value={this.isOpen}
        onInput={this.onClose}>
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>

          <VCardTitle>
            Offer
          </VCardTitle>

          <VCardText>
            {this.form}
          </VCardText>
        </VCard>
      </VDialog>
    );
  },
});
