export const validateNumber = (value: string) =>
  /^(\-)?[0-9]\d*(\.\d+)?$/.test(value) || 'not a number';

export const validateOptionalNumber = (value: string) =>
   !value || validateNumber(value);

export const validateRequired = (value: string) => !!value || 'required';

export const validateInteger = (value: string) =>
  /^(\-)?[0-9]\d*$/.test(value) || 'not an integer';

export const validatePercentage = (value: string) =>
  /^(\-)?[0-9]\d*$/.test(value) && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 100 || 'not valid percentage';
