import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import { VSwitch } from 'vuetify/lib';

export default Vue.extend({
  computed: {
    ...mapGetters({
      isNightMode: 'preferences/isNightMode',
    }),
  },
  methods: {
    ...mapActions({
      setNightMode: 'preferences/setNightMode',
    }),
  },
  created() {
    this.$vuetify.theme.dark = this.isNightMode;
  },
  watch: {
    '$vuetify.theme.dark'(newValue) {
      this.setNightMode(newValue);
    },
  },
  render() {
    return (
      <VSwitch
        vModel={this.$vuetify.theme.dark}
        hideDetails
        label='Night Mode'
      />
    );
  },
});
