import './utility.less';
import Vue from 'vue';
import {
  VSimpleTable,
  VDataTable,
  VBtn,
  VTextarea,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('sports/events');
import { hasRoles } from '@/utils/auth';

export default Vue.extend({
  name: 'eventPositionWidget',
  props: {
    eventId: String,
  },
  data() {
    return {
      positions: [] as any,
    };
  },
  computed: {
    ...mapState(['currentEvent']),
    competitorNamesMap(): any {
      if (!this.currentEvent) {
        return {};
      }

      return Object.keys(this.currentEvent.players).reduce((nameMap: any, key) => {
        nameMap[this.currentEvent.players[key].key] = this.currentEvent.players[key].name;
        return nameMap;
      }, {});
    },
  },
  methods: {
    source({ item }: any): any {
      return (<span>{item.adminId || item.provider}</span>);
    },
    runnerName({ item }: any): any {
      return (<span>{this.competitorNamesMap[item.competitorKey]}</span>);
    },
  },
  apollo: {
    positions: {
      query: gql`query ($eventId: String!) {
        positions: eventPositions(eventId: $eventId) {
          positions {
            timestamp
            competitorKey
            position
            provider
            effective
            disabled
          }
        }
      }`,
      update(data) {
        return data.positions.positions;
      },
      variables(): any {
        return { eventId: this.eventId };
      },
    },
  },
  render() {
    return (
      <div>
        <VDataTable
          class='elevation-1'
          itemClass={(item: any) => {
            if (item.disabled && item.effective) {
              return 'bg-red';
            }
            if (!item.effective) {
              return 'bg-gray';
            }
            return '';
          }}
          headers={[
            { text: 'Time', value: 'timestamp' },
            { text: 'Runner', value: 'runnerName', sortable: false },
            { text: 'Position', value: 'position', sortable: true },
            { text: 'Source', value: 'source', sortable: false },
          ]}
          scopedSlots={{ 'item.source': this.source, 'item.runnerName': this.runnerName }}
          items={this.positions || []}
          sortBy={['position']}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.positions.loading}
        />
      </div >
    );
  },
});
