import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VBtn, VCard, VCardText, VDataTable } from 'vuetify/lib';
import { RouterLink } from '@/router';
import { iso8601ToDateTimeUTCString } from '@/utils/date';
import { round } from '@/filters';

interface Referral {
  enabled: boolean;
}

interface ReferredPlayer {
  uuid: string;
  commission: number;
  createdAt: string;
  nickname: string;
}

export default Vue.extend({
  props: {
    playerUUID: String,
  },

  data() {
    return {
      referral: {} as Referral,
      referredPlayers: [] as ReferredPlayer[],
      load: false,
    };
  },

  methods: {
    async activate() {
      try {
        this.load = true;
        const result = await this.$apollo.mutate({
          mutation: gql`
          mutation ($playerUUID: String!, $referral: ReferralInput!) {
            updateReferral(playerUUID: $playerUUID, referral: $referral) {
              enabled
            }
          }
        `,
          variables: {
            playerUUID: this.playerUUID,
            referral: { enabled: true },
          },
        });

        this.referral = result.data.updateReferral;
      } catch (e) {
        throw e;
      } finally {
        this.load = false;
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: 'nickname',
          align: 'start',
          sortable: true,
          value: 'nickname',
        },
        {
          text: 'sign in time',
          align: 'start',
          sortable: false,
          value: 'createdAt',
        },
        {
          text: 'commission',
          align: 'end',
          sortable: true,
          value: 'commission',
        },
      ];
    },
  },

  apollo: {
    referral: {
      query():
        DocumentNode {
        return gql`
          query($playerUUID: String!) {
            referral: referral(playerUUID: $playerUUID) {
              enabled
            }
          }
        `;
      },
      variables(): any {
        return { playerUUID: this.playerUUID };
      },
    },
    referredPlayers: {
      query(): DocumentNode {
        return gql`
          query($playerUUID: String!) {
            referredPlayers: referredPlayers(playerUUID: $playerUUID) {
              uuid
              commission
              createdAt
              nickname
            }
          }
        `;
      },
      variables(): any {
        return { playerUUID: this.playerUUID };
      },
    },
  },

  render() {
    return (
      <VCard>
        <VCardText>
          {
            !this.referral.enabled ? <VBtn
              class='mb-2'
              color={this.referral.enabled ? 'success' : 'warning'}
              disabled={this.$apollo.queries.referral.loading || this.load || this.referral.enabled}
              loading={this.$apollo.queries.referral.loading || this.load}
              onClick={() => this.activate()}>
              {this.referral.enabled ? 'already active' : 'click to activate'}
            </VBtn> : <span>
              <h2 class='mb-2'>Referred players</h2>
              <VDataTable
                headers={this.headers}
                items={this.referredPlayers || []}
                loading-text='Loading... Please wait'
                loading={this.$apollo.queries.referredPlayers.loading}
                scopedSlots={{
                  'item.nickname': ({ item }: {item: ReferredPlayer}) =>
                      <RouterLink
                        class='text-decoration-none'
                        to={{ name: 'player', params: { playerUUID: item.uuid } }}>
                                {item.nickname || 'N/A'}
                      </RouterLink>,
                  'item.createdAt': ({ item }: {item: ReferredPlayer}) => iso8601ToDateTimeUTCString(item.createdAt),
                  'item.commission': ({ item }: {item: ReferredPlayer}) => round(item.commission, 4),
                }}>
              </VDataTable>
            </span>
          }
        </VCardText>
      </VCard>
    );
  },
});
