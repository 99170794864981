import { gql } from 'apollo-boost';

interface GenericEvent {
  id: number;
  name: string;
  type: string;
  sportKey: string;
  startsAt: string;
  competition: { id: string; name: string; categoryId: number; };
  competitors: Array<{ id: string, name: string; }>;
  providersData: Array<{ provider: string; }>;
  status: string;
}

interface GenericCompetition {
  id: number;
  key: string;
  name: string;
}

interface GenericCompetitor {
  id: number;
  key: string;
  name: string;
}

const EVENT_QUERY = gql`query ($eventId: String!) {
  event(eventId: $eventId) {
    id
    name
    type
    sportKey
    startsAt
    status
    competition {
      id
      name
      categoryId
    }
    competitors {
      id
      name
    }
    providersData {
      provider
    }
  }
}`;

const SEARCH_EVENTS_QUERY = gql`query ($name: String!, $sportKey: String, $categoryId: Int, $fromTs: Int, $toTs: Int) {
  searchEvents(name: $name, sportKey: $sportKey, categoryId: $categoryId, fromTs: $fromTs, toTs: $toTs) {
    events {
      id
      name
      type
      sportKey
      startsAt
      status
      competition {
        id
        name
        categoryId
      }
      competitors {
        id
        name
      }
      providersData {
        provider
      }
    }
  }
}`;

const COMPETIITON_BY_PROVIDER_PROVIDER_KEY = gql`query getCompetitionByProviderProviderKey($provider: String!, $providerKey: String!) {
  getCompetitionByProviderProviderKey(provider: $provider, providerKey: $providerKey) {
    id
    key
    name
  }
}`;

const COMPETITOR_BY_PROVIDER_PROVIDER_KEY = gql`query getCompetitorByProviderProviderKey($provider: String!, $providerKey: String!) {
  getCompetitorByProviderProviderKey(provider: $provider, providerKey: $providerKey) {
    id
    key
    name
  }
}`;

const failureSnackbarConfig = (content: JSX.Element | string) => {
  return {
    props: {
      timeout: '5000',
      top: true,
      color: 'red darken-1',
      elevation: '24',
    },
    scopedSlots: {
      default: () => content,
    },
  };
};

export {
  GenericEvent,
  GenericCompetition,
  GenericCompetitor,
  SEARCH_EVENTS_QUERY,
  EVENT_QUERY,
  COMPETITOR_BY_PROVIDER_PROVIDER_KEY,
  COMPETIITON_BY_PROVIDER_PROVIDER_KEY,
  failureSnackbarConfig,
};
