import Vue from 'vue';
import {
  VRow,
  VCol,
  VTextField,
  VToolbar,
  VToolbarTitle,
  VProgressLinear,
  VSpacer,
  VBtn,
  VCard,
  VSnackbar,
} from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import { LiabilitySettings } from '@/types/sports';
import _ from 'lodash';
import './LiabilitySettings.less';

const { mapActions, mapState, mapGetters } = createNamespacedHelpers('sports/liabilitySettings');

const EARLY_MARKET = '-2147483648';
const TWELVE_HOURS = '-720';
const TWO_HOURS = '-120';
const LIVE_FIRST_10_MINS = '0';
const LIVE_AFTERWARDS = '10';

const timecapName = (timeCap: string) => {
  switch (timeCap) {
    case EARLY_MARKET:
      return 'early market';
    case TWELVE_HOURS:
      return '12h';
    case TWO_HOURS:
      return '2h';
    case LIVE_FIRST_10_MINS:
      return 'live first 10mins';
    case LIVE_AFTERWARDS:
      return 'live afterwards';
    default:
      return timeCap;
  }
};

export default Vue.extend({
  props: {
    description: String,
    stakeFactorPreview: Number, // disables editing and shows preview.
  },

  data() {
    return {
      updateSuccess: false,
      updateFailure: false,
    };
  },

  computed: {
    ...mapState(['inProgress', 'liabilitySettings']),
    ...mapGetters(['dataChanged']),

    settings(): LiabilitySettings {
      return this.liabilitySettings;
    },

    spreadsheet() {
      if (!this.settings) {
        return;
      }
      return (
        <div>
          <VRow class='text-right'>
            <VCol cols='1'></VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Prematch #bets to reach limit:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(this.settings, 'betPartitionsPrematch')}
            </VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Event Cap:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(this.settings, 'eventPlayerCapWithMultiplier')}
            </VCol>
          </VRow>
          <VRow class='text-right'>
            <VCol cols='1'></VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Live #bets to reach limit:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(this.settings, 'betPartitionsLive')}
            </VCol>
          </VRow>
          <VRow class='text-right'>
            <VCol cols='1'></VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Virtual #bets to reach limit:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(
                this.settings.betPartitionsPerCategory.
                  filter((x) => x.key === 'virtual')[0],
                'value')}
            </VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Virtual Event Cap:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(
                this.settings.eventPlayerCapWithMultiplierPerCategory
                  .filter((x) => x.key === 'virtual')[0],
                'value')}
            </VCol>
          </VRow>
          <VRow class='text-right'>
            <VCol cols='1'></VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Simulated reality leagues #bets to reach limit:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(
                this.settings.betPartitionsPerCategory.
                  filter((x) => x.key === 'simulated_reality_leagues')[0],
                'value')}
            </VCol>
            <VCol cols='3' class='font-weight-bold mt-3'>Simulated reality leagues Event Cap:</VCol>
            <VCol cols='1'>
              {this.spreadsheetInput(
                this.settings.eventPlayerCapWithMultiplierPerCategory
                  .filter((x) => x.key === 'simulated_reality_leagues')[0],
                'value')}
            </VCol>
          </VRow>
          <VRow class='text-right font-weight-bold'>
            <VCol cols='2'>Time / Grade</VCol>
            {_.range(1, 11).map((grade) => <VCol cols='1' class='text-center'>{grade}</VCol>)}
          </VRow>
          {this.settings.timecapsByGrade[0].timeCaps.map((tc, index) => {
            return (
              <VRow class='text-right'>
                <VCol cols='2' class='font-weight-bold mt-3'>
                  {timecapName(tc.key)}
                </VCol>
                {this.settings.timecapsByGrade.map((tcbg) => {
                  return (
                    <VCol cols='1'>
                      {this.spreadsheetInput(tcbg.timeCaps[index], 'value', true)}
                    </VCol>
                  );
                })}
              </VRow>
            );
          })}
          <VRow class='mt-5'>
            <VCol cols='2' class='text-right font-weight-bold mt-3'>
              outright limits
            </VCol>
            {this.settings.outrightCaps.map((cap) => {
              return (
                <VCol cols='1'>
                  {this.spreadsheetInput(cap, 'value', true)}
                </VCol>
              );
            })}
          </VRow>
        </div>
      );
    },

    toolbar() {
      return (
        <VToolbar>
          <VToolbarTitle>Liability settings{this.description && <span> - {this.description}</span>}</VToolbarTitle>
          <VProgressLinear
            active={this.inProgress}
            indeterminate={this.inProgress}
            absolute
            bottom
            color='deep-purple accent-4'>
          </VProgressLinear>
          <VSpacer />
          {!this.stakeFactorPreview && <VBtn color='warning' onClick={this.onSaveChanges} disabled={!this.dataChanged} >
            Save changes
          </VBtn>}
        </VToolbar>
      );
    },

    updateSuccessWidget() {
      return (
        <VSnackbar
          timeout='1000'
          v-model={this.updateSuccess}
          absolute
          top
          color='green darken-1'
          elevation='24'>
          Liability settings have been updated.
        </VSnackbar>
      );
    },

    updateFailureWidget() {
      return (
        <VSnackbar
          timeout='2000'
          v-model={this.updateFailure}
          absolute
          top
          color='red darken-1'
          elevation='24'>
          Liability settings have not been updated.
        </VSnackbar>
      );
    },
  },

  methods: {
    ...mapActions(['loadLiabilitySettings', 'updateLiabilitySettings']),

    async onSaveChanges() {
      try {
        await this.updateLiabilitySettings();
        this.updateSuccess = true;
      } catch (e) {
        this.updateFailure = true;
        throw e;
      }
    },

    spreadsheetInput(obj: Record<string, any>, fieldName: string, applyStakeFactorPreview?: boolean) {
      if (this.stakeFactorPreview) {
        let value = obj[fieldName];

        if (applyStakeFactorPreview) {
          value *= this.stakeFactorPreview;
        }

        if (value > this.settings.eventPlayerCapWithMultiplier) {
          value = this.settings.eventPlayerCapWithMultiplier;
        }

        return (
          <VTextField dense single-line type='number' class='right-input'
            disabled
            vModel={value}
            backgroundColor={this.backgroundColor(obj, fieldName)} />
        );
      }

      return (
        <VTextField dense single-line type='number' class='right-input'
          vModel={obj[fieldName]}
          backgroundColor={this.backgroundColor(obj, fieldName)} />
      );
    },

    backgroundColor(obj: Record<string, any>, fieldName: string) {
      if (obj[fieldName].toString() !== obj[`${fieldName}Original`].toString()) {
        return 'warning';
      }
    },
  },

  mounted() {
    this.loadLiabilitySettings();
  },

  render() {
    return (
      <VCard>
        {this.toolbar}
        {this.spreadsheet}
        {this.updateSuccessWidget}
        {this.updateFailureWidget}
      </VCard>
    );
  },
});
