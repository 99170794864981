import Vue, { VueConstructor } from 'vue';
import {
  VDataTable,
  VBadge,
  VChip,
  VBtn,
  VRow,
  VCol,
  VSelect,
  VAutocomplete,
  VCard,
  VCardTitle,
  VCardText,
  VTextField,
  VCardSubtitle,
  VIcon,
  VTooltip,
} from 'vuetify/lib';
import { Intersect } from 'vuetify/lib/directives';
import { gql } from 'apollo-boost';
import { unixToDateTimeUTCString, unixToDateUTCString } from '@/utils/date';
import { financeValueClass } from '@/utils/financeValueClass';
import { formatCurrency, currencyAlias, FormatCurrencyOptions } from '@/filters';
import currenciesConfig from '@/config/currencies';
import {
  Locale,
  getMarketDefinitions,
  MarketType,
  getSportsName,
} from '@cloudbet/market-helper/sports-core';
import { RouterLink } from '@/router';
import CountryFlag from 'vue-country-flag';
import CancelDialog from './CancelDialog';
import returnBetFields, { SportsBet, SportsBetSelection } from './return-fields';

import './PlayerBets.less';
import { hasRoles } from '@/utils/auth';
import { currencies } from '@/static/currencies';
import DateRangePicker from '@/components/DateRangePicker';
import { marketName, selectionName } from '../markets-names';
import errorHandlerMixin from '../errorHandlerMixin';
import CancelPlayerBetsDialog from './CancelPlayerBetsDialog';
import {
  BetSelections, cancelActionPermitted, resultsList, reconfirmActionPermitted,
} from '../BetDetails';
import { objectToQueryString } from '@/utils/objects';
import ReconfirmDialog from './ReconfirmDialog';
const PAGE_SIZE = 50;

const formatAmount = (amount: number, currency: string, name?: string) => {
  let options = { adjustPrecision: true } as FormatCurrencyOptions;
  if (name) {
    options = { ...options, prependName: true, name };
  }
  return formatCurrency(amount, currenciesConfig[currency]?.simple?.dp || 4, options);
};

const gradeColor = (grade: number) => {
  if (grade <= 4) { return 'error'; }
  if (grade === 5) { return 'warning'; }
  return 'secondary';
};

const betsStates = {
  cancelled: 'Cancelled',
  completed: 'Completed',
  pending: 'Pending',
  pending_acceptance: 'Pending Acceptance',
  placing: 'Placing',
  rejected: 'Rejected',
  unconfirmed: 'Unconfirmed',
} as Record<string, string>;

const betsStatesList = Object.entries(betsStates).map(([k, v]) => ({ value: k, text: v }));

const marketDefinitions = getMarketDefinitions(Locale.en);

const sports = {} as Record<string, string>;

const marketTypesList = Object.values(MarketType).map((marketKey) => {
  const sportKey = marketKey.split('.')[0];
  const sportName = sports[sportKey] = sports[sportKey] || getSportsName(sportKey, Locale.en);

  return {
    value: marketKey,
    text: `${sportName} - ${marketDefinitions[marketKey]?.Name}`,
    sportKey,
  };
});

const sportsList = Object.entries(sports).map(([k, v]) => ({ value: k, text: v }));

const sportsMarketTypes = (sportKeys?: string[]) => {
  if (!sportKeys || sportKeys.length === 0) {
    return marketTypesList;
  }
  return marketTypesList.filter((mt) => sportKeys.includes(mt.sportKey));
};


const arraysEqual = (a: any[], b: any[]) => a.length === b.length && a.every((e, i) => e === b[i]);

const filteringCurrencies = [{ text: 'BE1 (Bonus)', value: 'BE1' }, ...currencies];

interface FlattenedSportsBet extends SportsBet {
  selection: SportsBetSelection;
}

const displayedCurrency = 'EUR';

const addDays = (date: Date, days: number) => {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

const dateStringToTimestamp = (text?: string, addDay = false): number | undefined => {
  if (text) {
    if (addDay) {
      const date = addDays(new Date(text), 1);
      return Math.floor(date.getTime() / 1000);
    } else {
      return Math.floor(Date.parse(text) / 1000);
    }
  }

  return undefined;
};

type Regrading = 'notRegraded' | 'regraded' | 'machineRegraded' | 'adminRegraded';

const paramsToRegrading = (isRegraded?: string, regradedByAdmin?: string): Regrading | '' => {
  if (isRegraded === 'true') {
    if (regradedByAdmin === 'true') {
      return 'adminRegraded';
    } else if (regradedByAdmin === 'false') {
      return 'machineRegraded';
    } else {
      return 'regraded';
    }
  } else if (isRegraded === 'false') {
    return 'notRegraded';
  } else {
    return '';
  }
};

const regradingToParams = (regrading: Regrading) => {
  switch (regrading) {
    case 'notRegraded': return [false, undefined];
    case 'regraded': return [true, undefined];
    case 'machineRegraded': return [true, false];
    case 'adminRegraded': return [true, true];
  }
};

const regradingToString = (regrading: Regrading) => {
  switch (regrading) {
    case 'notRegraded': return 'Not regraded';
    case 'regraded': return 'Regraded (admin or machine)';
    case 'machineRegraded': return 'Machine regraded';
    case 'adminRegraded': return 'Admin regraded';
  }
};

const regradingOptions = [
  { text: regradingToString('adminRegraded'), value: 'adminRegraded' },
  { text: regradingToString('machineRegraded'), value: 'machineRegraded' },
  { text: regradingToString('regraded'), value: 'regraded' },
  { text: regradingToString('notRegraded'), value: 'notRegraded' },
];

const cashoutsOptions = [
  { text: 'Just cashouts', value: 'true' },
  { text: 'Just bets', value: 'false' },
];

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  name: 'PlayerBets',
  mixins: [errorHandlerMixin],
  props: {
    playerUUID: String,
    eventId: String,
  },

  data() {
    return {
      flattenedSportsBets: [] as FlattenedSportsBet[],
      lastQueriedId: undefined as number | undefined,
      lastId: undefined as number | undefined,
      allItemsLoaded: true,

      appliedStates: [] as string[],
      appliedMarketKeys: [] as string[],
      appliedSportKeys: [] as string[],
      appliedEventIds: [] as number[],
      appliedCurrency: '',
      appliedCreatedAt: ['', ''] as [string, string],
      appliedCompletedAt: ['', ''] as [string, string],
      appliedRegrading: '',
      appliedIsCashoutBet: '',
      appliedResults: [] as string[],

      filtersVisible: false,
      isFooterIntersecting: false,
      states: [] as string[],
      marketKeys: [] as string[],
      sportKeys: [] as string[],
      eventsString: '',
      currency: '',

      createdAt: ['', ''] as [string, string],
      completedAt: ['', ''] as [string, string],

      regrading: '',
      isCashoutBet: '',
      results: [] as string[],

      showCancelDialog: false,
      showConfirmDialog: false,
      cancellableBets: [] as SportsBet[],
      confirmableBets: [] as SportsBet[],

      player: null as { blocked: boolean, id: number } | null,

      showCancelPlayerBetsDialog: false,
      actionsVisible: false,

      headers: [
        { text: '', value: 'data-table-expand', align: 'center', width: 50 },
        { text: '#', value: 'id', width: 230 },
        { text: '', value: 'info', width: 60, align: 'center' },
        { text: 'Event', value: 'event', width: 200 },
        { text: 'Market', value: 'market', width2: 150 },
        { text: 'Selection', value: 'selection', width2: 150 },
        { text: 'Stake', value: 'stake', width: 70 },
        { text: 'Odds', value: 'selection.odds', width: 120 },
        { text: 'Potential Return Amount', value: 'returnAmount', width: 70 },
        { text: 'Revenue', value: 'revenue', width: 70 },
        { text: 'Bet Currency', value: 'currency', width: 50, align: 'center' },
      ],
    };
  },

  methods: {
    onScrolledToFooter(entries: any, observer: any, isIntersecting: boolean) {
      this.isFooterIntersecting = isIntersecting;
      // if footer is intersecting fetch the remnant data
      if (this.isFooterIntersecting) {
        this.lastQueriedId = this.lastId;
      }
    },

    footer() {
      return (
        <div v-intersect={{
          handler: this.onScrolledToFooter,
          options: { rootMargin: '0% 0% 100% 0%' },
        }}>
        </div>
      );
    },

    formatID({ item }: { item: FlattenedSportsBet }) {
      if (this.highlightMultiples && this.isParalayContinuation(item)) {
        return;
      }

      return (
        <div>
          <div>
            <RouterLink
              class='text-decoration-none'
              to={{ name: 'sportsBetDetails', params: { betId: item.id } }}>
              {item.id}
            </RouterLink>&nbsp;
            {item.comment &&
              <VTooltip right color='black' scopedSlots={{
                activator: ({ on }: { on: any }) => {
                  return <VIcon {...{ on }} dense>info</VIcon>;
                },
              }}>
                <span>{item.comment}</span>
              </VTooltip>
            }
            {item.isCashoutBet &&
              <VTooltip right color='black' scopedSlots={{
                activator: ({ on }: { on: any }) => {
                  return <VIcon {...{ on }} dense>monetization_on</VIcon>;
                },
              }}>
                <span>Cashout</span>
              </VTooltip>}
          </div>
          <div>
            {item?.selection?.externalProvider}&nbsp;
            <VChip label x-small>{item.state}</VChip>&nbsp;
            {item.result && <VChip label x-small>{item.result}</VChip>}&nbsp;
            {item.state === 'completed' && item.isRegraded && (
              item.regradedByAdmin ?
                <VTooltip right color='black'
                  scopedSlots={{
                    activator: ({ on }: { on: any }) => {
                      return <VIcon {...{ on }} dense>person</VIcon>;
                    },
                  }}>
                  <span>{`Regraded by ${item.regradedByAdminEmail || item.regradedByAdminId}`}</span>
                </VTooltip>
                :
                <VTooltip right color='black'
                  scopedSlots={{
                    activator: ({ on }: { on: any }) => {
                      return <VIcon {...{ on }} dense>precision_manufacturing</VIcon>;
                    },
                  }}>
                  <span>Regraded by machine</span>
                </VTooltip>
            )}
          </div>
          <div>
            <small class='grey--text'>Created: {unixToDateTimeUTCString(item.createdAt)}</small>
          </div>
          {
            item.completedAt !== 0 &&
            <div>
              <small class='grey--text'>Completed: {unixToDateTimeUTCString(item.completedAt)}</small>
            </div>
          }
          {
            item.cancelledAt !== 0 &&
            <div>
              <small class='grey--text'>Cancelled: {unixToDateTimeUTCString(item.cancelledAt)}</small>
            </div>
          }
        </div>
      );
    },

    formatInfo({ item }: { item: FlattenedSportsBet }) {
      if (this.highlightMultiples && this.isParalayContinuation(item)) {
        return;
      }

      return (
        <div>
          {item.selection?.isLive && <VChip label x-small color='success'>live</VChip>}
          {item.isMultiple && <VChip label x-small color='secondary'>multiple</VChip>}
          <div>
            <CountryFlag country={item.country} size='small' rounded={true} /> <span>{item.country}</span>
          </div>
          <VChip label x-small>{item.channel}</VChip>
        </div>
      );
    },

    formatRevenue({ item }: { item: FlattenedSportsBet }) {
      const revenue = 0 - item.returnAmount;
      if (revenue !== 0) {
        const className = financeValueClass(revenue);
        return (
          <span class={className}>
            {formatAmount(revenue * item.exchangeRate, displayedCurrency, '\u20AC')}
          </span>
        );
      }
    },

    formatStake({ item }: { item: FlattenedSportsBet }) {
      if (item.stake !== 0) {
        return (
          <div>
            <div>{formatAmount(item.stake * item.exchangeRate, displayedCurrency, '\u20AC')}</div>
            <small class='grey--text'>
              {formatAmount(item.stake, item.currency)}
            </small>
          </div>
        );
      }
    },

    formatReturnAmount({ item }: { item: FlattenedSportsBet }) {
      if (item.potentialReturnAmount !== 0) {
        return (
          <div>
            <div>{formatAmount(item.potentialReturnAmount * item.exchangeRate, displayedCurrency, '\u20AC')}</div>
            <small class='grey--text'>
              {formatAmount(item.potentialReturnAmount, item.currency)}
            </small>
          </div>
        );
      }
    },

    formatMarket({ item }: { item: FlattenedSportsBet }) {
      return marketName({
        marketParams: item.selection?.specialBetValue,
        marketKey: item.selection?.marketKey,
      },
        item.selection?.event || item.selection?.outright,
      );
    },

    formatSelection({ item }: { item: FlattenedSportsBet }) {
      return selectionName({
        marketParams: item.selection?.specialBetValue,
        marketKey: item.selection?.marketKey,
        selectionName: item.selection?.name,
      },
        item.selection?.event || item.selection?.outright,
        item.translations,
      );
    },

    formatOdds({ item }: { item: FlattenedSportsBet }) {
      return (
        <div>
          <span>{item.selection?.odds}</span><br />
          {item.selection?.adjustedPlacedOdds !== 0 &&
            item.selection?.odds !== item.selection?.adjustedPlacedOdds &&
            <div>
              <span class='warning--text'>{item.selection?.adjustedPlacedOdds}</span>
              &nbsp;<small class='grey--text'>adjusted</small>
            </div>
          }
          {item.selection?.closingPrice !== 0 &&
            item.selection?.isLive !== true &&
            <div>
              <span class={{ 'red--text': item.selection.closingPrice < item.selection.odds }}>
                {item.selection.closingPrice}
              </span>
              &nbsp;<small class='grey--text'>closing</small>
            </div>
          }
        </div>
      );
    },

    formatEvent({ item }: { item: FlattenedSportsBet }) {
      const eventName = item.selection?.outright?.name || item.selection?.event?.name;
      const metadata = item.selection?.metadata ? JSON.parse(item.selection.metadata) : {};
      const competitionGrade = metadata.competition_grade || item.selection?.competitionIntegrity * 10;
      return (
        <div>
          <div>
            <small class='grey--text'>
              {getSportsName(item.selection?.sportKey || '', Locale.en)} -&nbsp;
              {item.selection?.event?.competition?.name || item.selection?.outright?.competition?.name}
            </small>
            <VBadge inline content={competitionGrade}
              color={gradeColor(competitionGrade)}></VBadge>
          </div>
          <div>
            <RouterLink
              class='text-decoration-none'
              to={{ name: 'event', params: { eventId: item.selection?.eventId } }}>
              {eventName}
            </RouterLink>
            &nbsp;
            <VIcon dense onClick={() => this.addEventFilter(item.selection?.eventId)}>
              ads_click
            </VIcon>
          </div>
          <div>
            {item.selection?.event ?
              <small class='grey--text'>
                Starts at: {unixToDateTimeUTCString(item.selection?.event?.startsAt)}
              </small>
              :
              <small class='grey--text'>
                Finishes at: {unixToDateTimeUTCString(item.selection?.outright?.finishesAt)}
              </small>
            }
          </div>
        </div>
      );
    },

    formatCurrency({ item }: { item: FlattenedSportsBet }) {
      return currencyAlias(item.currency);
    },

    expandedItem({ item, headers }: { item: FlattenedSportsBet, headers: any }) {
      return (
        <td colspan={headers.length}>
          <VCard elevation={3} class='mt-3 mb-1'>
            <VCardTitle>Sports Bet</VCardTitle>
            <VCardText>
              <VRow>
                <VCol cols='12' md='6' class='text-left'>
                  <span>{item.comment}</span>
                </VCol>
                {cancelActionPermitted(item) &&
                  <VCol cols='12' md='6' class={{ 'text-right': this.$vuetify.breakpoint.smAndUp }}>
                    <span>
                      <VBtn color='warning' onClick={() => {
                        this.cancellableBets = [item];
                        this.showCancelDialog = true;
                      }}>
                        Cancel
                      </VBtn>
                    </span>
                  </VCol>
                }
                {reconfirmActionPermitted(item) &&
                  <VCol cols='12' md='6' class={{ 'text-right': this.$vuetify.breakpoint.smAndUp }}>
                    <span>
                      <VBtn color='warning' onClick={() => {
                        this.confirmableBets = [item];
                        this.showConfirmDialog = true;
                      }}>
                        Reconfirm
                      </VBtn>
                    </span>
                  </VCol>
                }
              </VRow>
            </VCardText>
          </VCard>
          <BetSelections class='mb-3'
            betUUID={item.uuid}
            returnBetFields={returnBetFields}
            translations={item.translations}
            onChanged={(bet: SportsBet) => this.onBetsChanged([bet])} />
        </td>
      );
    },

    itemClass(item: FlattenedSportsBet) {
      let className = `state-${item.state}`;
      if (this.highlightMultiples && item.isMultiple) {
        let multipleClass;
        if (item.selection.id === item.selections[0].id) {
          multipleClass = !item._isPrevBetMultiple ? 'first after-single' : 'first';
        } else if (item.selection.id === item.selections[item.selections.length - 1].id) {
          multipleClass = 'last';
        } else {
          multipleClass = 'middle';
        }

        className = `${className} multiple ${multipleClass}`;
      }

      return className;
    },

    cancelDialog() {
      if (this.showCancelDialog) {
        return (
          <CancelDialog
            bets={this.cancellableBets}
            returnBetFields={returnBetFields}
            onDisagree={() => {
              this.showCancelDialog = false;
              if (this.cancellableBets.length === 1) {
                this.cancellableBets = [];
              }
            }}
            onAgree={(cancelResult: Array<[SportsBet | null, Error | null]>) => {
              this.showCancelDialog = false;
              this.onBetsCancelled(cancelResult);
            }}>
          </CancelDialog>
        );
      }
    },

    confirmDialog() {
      if (this.showConfirmDialog) {
        return (
          <ReconfirmDialog
            bets={this.confirmableBets}
            returnBetFields={returnBetFields}
            onDisagree={() => {
              this.showConfirmDialog = false;
              if (this.confirmableBets.length === 1) {
                this.confirmableBets = [];
              }
            }}
            onAgree={(confirmResult: Array<[SportsBet | null, Error | null]>) => {
              this.showConfirmDialog = false;
              this.onBetsConfirmed(confirmResult);
            }}>
          </ReconfirmDialog>
        );
      }
    },

    onBetsCancelled(cancelResult: Array<[SportsBet | null, Error | null]>) {
      const successes = cancelResult.filter(([bet, err]) => bet).map(([bet, err]) => bet) as SportsBet[];
      const failures = cancelResult.filter(([bet, err]) => err).map(([bet, err]) => err) as Error[];

      this.onBetsChanged(successes);

      if (failures.length > 0) {
        const failureMessage = [
          'Some bets have not been cancelled:',
          ...failures.map((e) => e.message),
        ];
        this.showFailureMessage(failureMessage);
      }
    },

    onBetsConfirmed(confirmResult: Array<[SportsBet | null, Error | null]>) {
      const successes = confirmResult.filter(([bet, err]) => bet).map(([bet, err]) => bet) as SportsBet[];
      const failures = confirmResult.filter(([bet, err]) => err).map(([bet, err]) => err) as Error[];

      this.onBetsChanged(successes);

      if (failures.length > 0) {
        const failureMessage = [
          'Some bets have not been reconfirmed:',
          ...failures.map((e) => e.message),
        ];
        this.showFailureMessage(failureMessage);
      }
    },

    onBetsChanged(bets: SportsBet[]) {
      // Deleting the changed bets from the list of selected bets
      const betsIds = bets.map((bet) => bet.id);
      this.cancellableBets = this.cancellableBets.filter((bet) => {
        return !betsIds.includes(bet.id);
      });
      this.confirmableBets = this.confirmableBets.filter((bet) => {
        return !betsIds.includes(bet.id);
      });

      const changedSelections = bets.flatMap((bet) => {
        return bet.selections.map((s) => {
          return { ...bet, selection: { ...s } };
        });
      }).reduce((acc, s) => ({ ...acc, [s.selection.id]: s }), {} as Record<number, FlattenedSportsBet>);

      this.flattenedSportsBets = this.flattenedSportsBets.map((sb) => {
        const changed = changedSelections[sb.selection.id];
        if (changed) {
          return { ...changed, _isPrevBetMultiple: sb._isPrevBetMultiple };
        } else {
          return sb;
        }
      });
    },

    toolbar() {
      const filtersLinkText = this.filtersVisible ? 'Hide filters' : 'Show filters';
      const actionsLinkText = this.actionsVisible ? 'Hide actions' : 'Show actions';
      return (
        <VCard>
          <VCardTitle>
            <VRow>
              <VCol class='text-left'>
                <a class='filters-toggle' onClick={() => this.filtersVisible = !this.filtersVisible}>
                  {filtersLinkText}
                </a>
                {this.player?.blocked && hasRoles(['sports:operator']) &&
                  <span>
                    <span> | </span>
                    <a class='filters-toggle' onClick={() => this.actionsVisible = !this.actionsVisible}>
                      {actionsLinkText}
                    </a>
                  </span>
                }
              </VCol>
              <VCol class='text-right'>
                {!this.filtersApplied &&
                  <VBtn
                    color='warning'
                    onClick={this.applyFilters}>
                    Apply changes
                  </VBtn>
                }

                {this.filtersSelected && this.filtersApplied && <VBtn
                  color='primary'
                  onClick={this.clearFilters}>
                  Clear filters
                </VBtn>
                }
                {this.cancellableBets.length !== 0 &&
                  <VBtn
                    class='ml-2'
                    color='warning'
                    onClick={() => this.showCancelDialog = true}>
                    Cancel selected ({this.cancellableBets.length})
                  </VBtn>
                }
                {this.confirmableBets.length !== 0 &&
                  <VBtn
                    class='ml-2'
                    color='warning'
                    onClick={() => this.showConfirmDialog = true}>
                    Reconfim selected ({this.confirmableBets.length})
                  </VBtn>
                }
                {this.actionsVisible && this.player?.blocked && hasRoles(['sports:operator']) &&
                  <VBtn
                    class='ml-2'
                    color='warning'
                    onClick={() => this.showCancelPlayerBetsDialog = true}>
                    Cancel all bets
                  </VBtn>
                }
              </VCol>
            </VRow>
          </VCardTitle>
          {this.filtersSelected && !this.filtersVisible &&
            <VCardSubtitle>{this.filtersText}</VCardSubtitle>}
          {this.filtersVisible &&
            <VCardText>
              <VRow>
                <VCol cols={6}>
                  <VSelect
                    chips
                    multiple
                    dense
                    items={betsStatesList}
                    vModel={this.states}
                    label='States'
                    clearable
                    deletable-chips>
                  </VSelect>
                </VCol>
                {!this.displaySingleEventSelections &&
                  <VCol cols={6}>
                    <VAutocomplete
                      chips
                      multiple
                      dense
                      items={sportsList}
                      vModel={this.sportKeys}
                      label='Sports'
                      clearable
                      deletable-chips>
                    </VAutocomplete>
                  </VCol>
                }
                <VCol cols={6}>
                  <VAutocomplete
                    chips
                    multiple
                    dense
                    items={sportsMarketTypes(this.sportKeys)}
                    vModel={this.marketKeys}
                    filter={this.filterMarketKeys}
                    label='Markets'
                    clearable
                    deletable-chips>
                  </VAutocomplete>
                </VCol>
                {!this.displaySingleEventSelections &&
                  <VCol cols={6}>
                    <VTextField class='event-ids' vModel={this.eventsString} label='Events'></VTextField>
                  </VCol>
                }
                <VCol cols={3}>
                  <VAutocomplete
                    chips
                    dense
                    items={filteringCurrencies}
                    vModel={this.currency}
                    label='Currency'
                    clearable
                    deletable-chips>
                  </VAutocomplete>
                </VCol>
                <VCol cols={3}>
                  <VAutocomplete
                    chips
                    dense
                    items={regradingOptions}
                    vModel={this.regrading}
                    label='Regrading'
                    clearable
                    deletable-chips />
                </VCol>
                <VCol cols={3}>
                  <VAutocomplete
                    chips
                    dense
                    items={cashoutsOptions}
                    vModel={this.isCashoutBet}
                    label='Cashouts'
                    clearable
                    deletable-chips />
                </VCol>
                <VCol cols={3}>
                  <VAutocomplete
                    chips
                    multiple
                    dense
                    items={resultsList}
                    vModel={this.results}
                    label='Selections results'
                    clearable
                    deletable-chips />
                </VCol>
                <VCol cols={6} class='pt-6'>
                  <DateRangePicker label1='Created at start' label2='Created at end' vModel={this.createdAt} />
                </VCol>
                <VCol cols={6} class='pt-6'>
                  <DateRangePicker label1='Completed at start' label2='Completed at end' vModel={this.completedAt} />
                </VCol>
              </VRow>
            </VCardText>
          }
        </VCard>
      );
    },

    applyFilters() {
      this.flattenedSportsBets = [];
      this.lastQueriedId = undefined;
      this.lastId = undefined;
      this.allItemsLoaded = false;
      this.appliedStates = [...this.states];
      this.appliedMarketKeys = [...this.marketKeys];
      this.appliedSportKeys = [...this.sportKeys];
      this.appliedEventIds = [...this.eventIds];
      this.appliedCurrency = this.currency;
      this.appliedCreatedAt = [...this.createdAt];
      this.appliedCompletedAt = [...this.completedAt];
      this.appliedRegrading = this.regrading;
      this.appliedIsCashoutBet = this.isCashoutBet;
      this.appliedResults = this.results;

      this.filtersVisible = false;

      this.cancellableBets = [];
      this.confirmableBets = [];

      this.addParamsToUrl();
    },

    clearFilters() {
      this.flattenedSportsBets = [];
      this.lastQueriedId = undefined;
      this.lastId = undefined;
      this.allItemsLoaded = false;
      this.appliedStates = [];
      this.appliedMarketKeys = [];
      this.appliedSportKeys = [];
      this.appliedEventIds = [];
      this.appliedCurrency = '';
      this.appliedCreatedAt = ['', ''];
      this.appliedCompletedAt = ['', ''];
      this.appliedRegrading = '';
      this.appliedIsCashoutBet = '';
      this.appliedResults = [];
      this.results = [];
      this.states = [];
      this.marketKeys = [];
      this.sportKeys = [];
      this.currency = '';
      this.createdAt = ['', ''];
      this.completedAt = ['', ''];
      this.regrading = '';
      this.eventsString = '';
      this.isCashoutBet = '';
      this.results = [];

      this.cancellableBets = [];
      this.confirmableBets = [];

      this.addParamsToUrl();
    },

    filterMarketKeys(item: FlattenedSportsBet, queryText: string, itemText: string) {
      queryText = queryText.toLowerCase();
      itemText = itemText.toLowerCase();

      const words = queryText.split(' ');
      return words.every((s) => itemText.includes(s));
    },

    itemDoubleClicked(e: MouseEvent, { expand, isExpanded, item }:
      { expand: (value: boolean) => void, isExpanded: boolean, item: FlattenedSportsBet }) {
      if (this.highlightMultiples && this.isParalayContinuation(item)) {
        return;
      }

      expand(!isExpanded);
    },

    addEventFilter(eventId: number) {
      if (this.eventsString === '') {
        this.eventsString = `${eventId}`;
      } else {
        this.eventsString = `${this.eventsString},${eventId}`;
      }
    },

    addParamsToUrl() {
      const [isRegraded, regradedByAdmin] = this.regrading ?
        regradingToParams(this.regrading as Regrading).map((x) => x == null ? '' : x.toString()) :
        ['', ''];

      const queryString = objectToQueryString({
        states: this.states.join(','),
        sportKeys: this.sportKeys.join(','),
        marketKeys: this.marketKeys.join(','),
        eventIds: this.eventIds.join(','),
        currency: this.currency,
        createdAtStart: this.createdAt[0] && (Date.parse(this.createdAt[0]) / 1000).toString(),
        createdAtEnd: this.createdAt[1] && (Date.parse(this.createdAt[1]) / 1000).toString(),
        completedAtStart: this.completedAt[0] && (Date.parse(this.completedAt[0]) / 1000).toString(),
        completedAtEnd: this.completedAt[1] && (Date.parse(this.completedAt[1]) / 1000).toString(),
        isRegraded,
        regradedByAdmin,
        isCashoutBet: this.isCashoutBet,
        results: this.results.join(','),
      });

      history.pushState({}, '', `${this.$route.path}${queryString}${this.$route.hash}`);
    },

    expandIcon({ item, isExpanded, expand }:
      { item: FlattenedSportsBet, isExpanded: boolean, expand: (v: boolean) => void }) {
      if (this.highlightMultiples && this.isParalayContinuation(item)) {
        return;
      }

      return (
        <div>
          <div>
            <VIcon onClick={() => expand(!isExpanded)} class={[
              'v-data-table__expand-icon',
              { 'v-data-table__expand-icon--active': isExpanded },
            ]}>$expand</VIcon>
          </div>
          {cancelActionPermitted(item) && this.confirmableBets.length === 0 &&
            <div class='mt-4'>
              <VBtn icon x-small onClick={() => {
                if (this.cancellableBets.includes(item)) {
                  const index = this.cancellableBets.indexOf(item);
                  if (index > -1) {
                    this.cancellableBets.splice(index, 1);
                  }
                } else {
                  this.cancellableBets.push(item);
                }
              }}>
                <VIcon>
                  {this.cancellableBets.includes(item) ? 'check_box' : 'check_box_outline_blank'}
                </VIcon>
              </VBtn>
            </div>
          }
          {reconfirmActionPermitted(item) && this.cancellableBets.length === 0 &&
            <div class='mt-4'>
              <VBtn icon x-small onClick={() => {
                if (this.confirmableBets.includes(item)) {
                  const index = this.confirmableBets.indexOf(item);
                  if (index > -1) {
                    this.confirmableBets.splice(index, 1);
                  }
                } else {
                  this.confirmableBets.push(item);
                }
              }}>
                <VIcon>
                  {this.confirmableBets.includes(item) ? 'check_box' : 'check_box_outline_blank'}
                </VIcon>
              </VBtn>
            </div>
          }
        </div>
      );
    },

    isParalayContinuation(item: FlattenedSportsBet): boolean {
      if (!item.isMultiple) {
        return false;
      }

      return item.selection.id !== item.selections[0].id;
    },
  },

  computed: {
    filtersSelected(): boolean {
      return this.states.length > 0 ||
        this.marketKeys.length > 0 ||
        this.sportKeys.length > 0 ||
        this.eventIds.length > 0 ||
        (this.currency || '') !== '' ||
        this.createdAt[0] !== '' ||
        this.createdAt[1] !== '' ||
        this.completedAt[0] !== '' ||
        this.completedAt[1] !== '' ||
        (this.regrading || '') !== '' ||
        (this.isCashoutBet || '') !== '' ||
        this.results.length > 0;
    },

    eventIds(): number[] {
      return this.eventsString
        .split(',')
        .filter((id) => id.match(/\d+/))
        .map((id) => parseInt(id, 10));
    },

    filtersApplied(): boolean {
      return arraysEqual(this.states, this.appliedStates) &&
        arraysEqual(this.marketKeys, this.appliedMarketKeys) &&
        arraysEqual(this.sportKeys, this.appliedSportKeys) &&
        arraysEqual(this.eventIds, this.appliedEventIds) &&
        (this.currency || '') === (this.appliedCurrency || '') &&
        arraysEqual(this.createdAt, this.appliedCreatedAt) &&
        arraysEqual(this.completedAt, this.appliedCompletedAt) &&
        (this.regrading || '') === (this.appliedRegrading || '') &&
        (this.isCashoutBet || '') === (this.appliedIsCashoutBet || '') &&
        arraysEqual(this.results, this.appliedResults);
    },

    filtersText(): JSX.Element[][] {
      const filters = [];
      if (this.states.length > 0) {
        filters.push(['State', ...this.states.map((s) => (betsStates[s]))]);
      }

      if (this.sportKeys.length > 0) {
        filters.push(['Sport', ...this.sportKeys.map((sk) => (sports[sk]))]);
      }

      if (this.marketKeys.length > 0) {
        filters.push(['Market', ...this.marketKeys.map((mk) => {
          const sportKey = mk.split('.')[0];
          return `${sports[sportKey]} - ${marketDefinitions[mk]?.Name}`;
        })]);
      }

      if (this.eventIds.length > 0) {
        filters.push(['Event', ...this.eventIds]);
      }

      if (this.currency) {
        const currency = filteringCurrencies.find((c) => c.value === this.currency);
        filters.push(['Currency', currency?.text || '']);
      }

      if (this.createdAt[0] || this.createdAt[1]) {
        let range = '';
        if (this.createdAt[0]) { range += `from ${this.createdAt[0]}`; }
        if (range) { range += ' '; }
        if (this.createdAt[1]) { range += `to ${this.createdAt[1]}`; }
        filters.push(['Created at', range]);
      }

      if (this.completedAt[0] || this.completedAt[1]) {
        let range = '';
        if (this.completedAt[0]) { range += `from ${this.completedAt[0]}`; }
        if (range) { range += ' '; }
        if (this.completedAt[1]) { range += `to ${this.completedAt[1]}`; }
        filters.push(['Completed at', range]);
      }

      if (this.regrading) {
        filters.push(['Regrading', regradingToString(this.regrading as Regrading)]);
      }

      const cashoutFilter = cashoutsOptions.find((x) => x.value === this.isCashoutBet);
      if (cashoutFilter) {
        filters.push(['Cashouts', cashoutFilter.text]);
      }

      if (this.results.length > 0) {
        filters.push(['Selections results', ...this.results]);
      }

      return filters.map((filter) => {
        return filter.map((f, i, a) => {
          if (i === 0) {
            return <span> <VChip label x-small color='success'>{f}</VChip></span>;
          } else {
            if (i === a.length - 1) {
              return <span> {f}</span>;
            } else {
              return <span> {f},</span>;
            }
          }
        });
      });
    },

    displaySingleEventSelections(): boolean {
      return this.eventId !== undefined;
    },

    highlightMultiples(): boolean {
      return this.eventId === undefined;
    },

    cancelPlayerBetsDialog() {
      if (this.showCancelPlayerBetsDialog) {
        return (
          <CancelPlayerBetsDialog
            playerUUID={this.playerUUID}
            onDisagree={() => this.showCancelPlayerBetsDialog = false}
            onAgree={() => {
              this.showCancelPlayerBetsDialog = false;
              this.showSuccessMessage('The player bets will be cancelled.');
            }}>
          </CancelPlayerBetsDialog>
        );
      }
    },
  },

  directives: {
    Intersect,
  },

  apollo: {
    sportsBetsQuery: {
      query: gql`query (
          $playerUUID: String,
          $lastId: Int,
          $limit: Int,
          $states: [String!],
          $marketKeys: [String!],
          $sportKeys: [String!],
          $eventIds: [Int!],
          $currency: String,
          $createdAtStart: Int,
          $createdAtEnd: Int,
          $completedAtStart: Int,
          $completedAtEnd: Int,
          $isRegraded: Boolean,
          $regradedByAdmin: Boolean,
          $isCashoutBet: Boolean,
          $results: [String!]) {
        sportsBets(
          playerUUID: $playerUUID,
          lastId: $lastId,
          limit: $limit,
          states: $states,
          marketKeys: $marketKeys,
          sportKeys: $sportKeys,
          eventIds: $eventIds,
          currency: $currency,
          createdAtStart: $createdAtStart,
          createdAtEnd: $createdAtEnd,
          completedAtStart: $completedAtStart,
          completedAtEnd: $completedAtEnd,
          isRegraded: $isRegraded,
          regradedByAdmin: $regradedByAdmin,
          isCashoutBet: $isCashoutBet,
          results: $results) {
          ${returnBetFields}
        }
      }`,

      variables(): {
        playerUUID: string,
        lastId?: number,
        limit: number,
        states: string[],
        marketKeys: string[],
        sportKeys: string[],
        eventIds: number[],
        currency: string,
        createdAtStart?: number,
        createdAtEnd?: number,
        completedAtStart?: number,
        completedAtEnd?: number,
        isRegraded?: boolean,
        regradedByAdmin?: boolean,
        isCashoutBet?: boolean,
        results: string[],
      } {
        let eventIds = this.appliedEventIds;
        if (this.displaySingleEventSelections) {
          eventIds = [parseInt(this.eventId, 10)];
        }

        const [isRegraded, regradedByAdmin] = this.appliedRegrading ?
          regradingToParams(this.appliedRegrading as Regrading) : [undefined, undefined];

        return {
          eventIds,
          playerUUID: this.playerUUID,
          lastId: this.lastQueriedId,
          limit: PAGE_SIZE,
          states: this.appliedStates,
          marketKeys: this.appliedMarketKeys,
          sportKeys: this.appliedSportKeys,
          currency: this.appliedCurrency,
          createdAtStart: dateStringToTimestamp(this.appliedCreatedAt[0]),
          createdAtEnd: dateStringToTimestamp(this.appliedCreatedAt[1], true),
          completedAtStart: dateStringToTimestamp(this.appliedCompletedAt[0]),
          completedAtEnd: dateStringToTimestamp(this.appliedCompletedAt[1], true),
          isRegraded,
          regradedByAdmin,
          isCashoutBet: this.appliedIsCashoutBet ? JSON.parse(this.appliedIsCashoutBet) : undefined,
          results: this.appliedResults,
        };
      },

      update(data) {
        const bets: SportsBet[] =
          data.sportsBets.map((b: any) => ({ ...b, translations: Object.fromEntries(b.translations) }));
        if (bets.length > 0) {
          this.lastId = bets[bets.length - 1].id;
        }

        if (bets.length < PAGE_SIZE) {
          this.allItemsLoaded = true;
        }

        if (bets.length > 0 && this.flattenedSportsBets.length > 0) {
          const lastLoadedBet = bets[bets.length - 1];
          const lastFlattenedBet = this.flattenedSportsBets[this.flattenedSportsBets.length - 1];

          // If the bets have been already loaded, it doesn't make sense to flatten them again.
          if (lastLoadedBet.id === lastFlattenedBet.id) {
            return bets;
          }
        }

        let isPrevBetMultiple = this.flattenedSportsBets.length === 0 ?
          false : this.flattenedSportsBets[this.flattenedSportsBets.length - 1].isMultiple;

        const flattened = bets.flatMap((bet) => {
          bet._isPrevBetMultiple = isPrevBetMultiple;
          isPrevBetMultiple = bet.isMultiple;

          if (this.displaySingleEventSelections) {
            bet.selections = bet.selections.filter((s) => s.eventId.toString() === this.eventId);
          }

          return bet.selections.map((s) => {
            return { ...bet, selection: { ...s } };
          });
        });
        this.flattenedSportsBets = [...this.flattenedSportsBets, ...flattened];
        return bets;
      },

      skip(): boolean {
        return this.allItemsLoaded;
      },

      // couldn't use no-cache' since it causes that the loading indicator doesn't work correctly
      fetchPolicy: 'network-only',
    },

    player: {
      query: gql`query($playerUUID: String!) {
        player: getPlayer(uuid: $playerUUID) {
          blocked
          id
        }
      }`,

      update(data) {
        return data.player;
      },

      variables(): any {
        return { playerUUID: this.playerUUID };
      },

      skip(): boolean {
        return !this.playerUUID;
      },

      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    const params = this.$route.query;
    if (params.states) {
      this.states = decodeURIComponent(params.states as string).split(',');
    }

    if (params.sportKeys) {
      this.sportKeys = decodeURIComponent(params.sportKeys as string).split(',');
    }

    if (params.marketKeys) {
      this.marketKeys = decodeURIComponent(params.marketKeys as string).split(',');
    }

    if (params.eventIds) {
      this.eventsString = decodeURIComponent(params.eventIds as string);
    }

    if (params.currency) {
      this.currency = decodeURIComponent(params.currency as string);
    }

    if (params.createdAtStart) {
      const createdAtStart =
        unixToDateUTCString(Number(decodeURIComponent(params.createdAtStart as string)), false);
      this.createdAt = [createdAtStart, this.createdAt[1]];
    }

    if (params.createdAtEnd) {
      const createdAtEnd =
        unixToDateUTCString(Number(decodeURIComponent(params.createdAtEnd as string)), false);
      this.createdAt = [this.createdAt[0], createdAtEnd];
    }

    if (params.completedAtStart) {
      const completedAtStart =
        unixToDateUTCString(Number(decodeURIComponent(params.completedAtStart as string)), false);
      this.completedAt = [completedAtStart, this.completedAt[1]];
    }

    if (params.completedAtEnd) {
      const completedAtEnd =
        unixToDateUTCString(Number(decodeURIComponent(params.completedAtEnd as string)), false);
      this.completedAt = [this.completedAt[0], completedAtEnd];
    }

    this.regrading = paramsToRegrading(params.isRegraded as string, params.regradedByAdmin as string);

    if (params.isCashoutBet) {
      this.isCashoutBet = decodeURIComponent(params.isCashoutBet as string);
    }

    if (params.results) {
      this.results = decodeURIComponent(params.results as string).split(',');
    }

    this.applyFilters();
  },

  render() {
    return (
      <div class='player-bets'>
        {this.failureSnackbar}
        {this.successSnackbar}
        {this.cancelDialog()}
        {this.confirmDialog()}
        {this.cancelPlayerBetsDialog}
        <VDataTable
          class='elevation-1'
          headers={this.headers}
          items={this.flattenedSportsBets || []}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.sportsBetsQuery.loading}
          sortBy={['provider', 'language']}
          disablePagination={true}
          disableSort={true}
          dense={true}
          hideDefaultFooter={true}
          itemKey='selection.id'
          showExpand
          singleExpand={true}
          itemClass={this.itemClass}
          on={{ 'dblclick:row': this.itemDoubleClicked }}
          scopedSlots={{
            'footer': this.footer,
            'item.id': this.formatID,
            'item.info': this.formatInfo,
            'item.revenue': this.formatRevenue,
            'item.returnAmount': this.formatReturnAmount,
            'item.stake': this.formatStake,
            'item.market': this.formatMarket,
            'item.selection': this.formatSelection,
            'item.event': this.formatEvent,
            'item.currency': this.formatCurrency,
            'item.selection.odds': this.formatOdds,
            'expanded-item': this.expandedItem,
            'top': this.toolbar,
            'item.data-table-expand': this.expandIcon,
          }}>
        </VDataTable>
      </div>
    );
  },
});
