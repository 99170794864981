import Vue from 'vue';
import { VChip } from 'vuetify/lib';
import './SearchNavigationInstructions.less';
export const SearchNavigationInstructions = Vue.extend({
  render() {
    return (
      <div class='suggestion-navigation-container'>
        <div class='navigation-instructions-text'>Navigation Instructions</div>
        <div>
          {' '}
          <VChip label>Shift</VChip> <VChip label>J</VChip>
          {' / '}
          <VChip label>K</VChip> to move up or down.
        </div>
        <div class='mt-4'>
          <VChip label>Tab</VChip> to select suggestion
        </div>
        <div class='mt-4'>
          <VChip label color='red'>
            ESC
          </VChip>{' '}
          to hide this panel
        </div>
      </div>
    );
  },
});
