const cloneOriginalValues = (o: object): object => {
  return Object.entries(o).reduce((acc, [k, v]) => {
    if (Array.isArray(v)) { // arrays
      return {
        ...acc,
        [k]: v.map(cloneOriginalValues),
      };
    } else if (typeof v === 'object' && v !== null) { // objects
      return {
        ...acc,
        [k]: cloneOriginalValues(v),
      };
    } else {
      return { // simple fields
        ...acc,
        [k]: v,
        [k + 'Original']: v,
      };
    }
  }, {});
};

const valuesChanged = (obj: Record<string, any>): boolean => {
  return Object.entries(obj).filter(([k]) => !k.endsWith('Original')).some(([k, v]) => {
    if (Array.isArray(v)) { // arrays
      return v.some(valuesChanged);
    } else if (typeof v === 'object' && v !== null) { // objects
      return valuesChanged(v);
    } else {
      let value = obj[k];
      let original = obj[`${k}Original`];
      if (value === null || value === undefined) {
        value = '';
      }
      if (original === null || original === undefined) {
        original = '';
      }
      return value.toString() !== original.toString();
    }
  });
};

export { cloneOriginalValues, valuesChanged };
