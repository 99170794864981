import Vue from 'vue';
import { VContainer } from 'vuetify/lib';
import BetDetails from '@/components/sports/BetDetails';

export default Vue.extend({
  name: 'BetDetails',
  computed: {
    betId() {
      return this.$route.params.betId;
    },
  },

  render() {
    return (
      <VContainer fluid>
        <BetDetails betUUID={`${this.betId}`} />
      </VContainer>
    );
  },
});
