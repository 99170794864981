import AffiliateBalances from '@/components/AffiliateBalances';
import { hasRoles } from '@/utils/auth';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Vue from 'vue';
import { formatCurrency } from '@/filters';
import { unixToDateTimeUTCString } from '@/utils/date';
import {
  VCard,
  VContainer,
  VSkeletonLoader,
  VTab,
  VTabItem,
  VTabs,
  VCol,
  VRow,
} from 'vuetify/lib';
import { Tab } from '@/components/ResponsiveTabs';

export interface Affiliate {
  id: number;
  uuid: string;
  currency: string;
  sportsCommissionPercentage: string;
  sportsCommissionScheme: string;
  noNegativeCarryOverEnabled: boolean;
  casinoCommissionScheme: string;
  casinoCommissionPercentage: string;
  afToken: string;
  blocked: boolean;
  createdAt: string;
  updatedAt: string;
  lastSignInAt: string;
  totalPlayers: number;
  acquiredPlayers: number;
  acquiredPlayersBySource: Array<{ source: string; players: number }>;
  activePlayers: number;
  eurDeposits: string;
  eurCommission: string;
  email: string;
}

function formatTime(time: string): string {
  return unixToDateTimeUTCString(Date.parse(time) / 1000);
}

export default Vue.extend({
  computed: {
    affiliateUUID() {
      return this.$route.params.affiliateUUID;
    },
  },
  data() {
    return {
      tab: 'affiliate-general',
    } as {
      tab: string;
      affiliate?: Affiliate;
    };
  },
  apollo: {
    affiliate: {
      query(): DocumentNode {
        return gql`
          query($affiliateUUID: String!) {
            affiliate: affiliate(uuid: $affiliateUUID) {
              id
              uuid
              currency
              sportsCommissionPercentage
              sportsCommissionScheme
              noNegativeCarryOverEnabled
              casinoCommissionScheme
              casinoCommissionPercentage
              afToken
              blocked
              createdAt
              updatedAt
              lastSignInAt
              totalPlayers
              acquiredPlayers
              acquiredPlayersBySource {
                source
                players
              }
              activePlayers
              eurDeposits
              eurCommission
              email
            }
          }
        `;
      },
      variables(): any {
        return { affiliateUUID: this.affiliateUUID };
      },
    },
  },
  methods: {
    tabs() {
      const tabs: Tab[] = [];

      tabs.push({
        requiresRoles: [],
        key: 'affiliate-general',
        name: 'General',
        content: this.$apollo.queries.affiliate.loading ? (
          <VCol md={6} sm={12}>
            <VSkeletonLoader type='table-tbody' />
          </VCol>
        ) : (
          <VContainer>
            <VRow>
              <VCol md={6} sm={12}>
                <VRow>
                  <VCol>
                    <b>Info</b>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Email</VCol>
                  <VCol align='end'>{this.affiliate?.email}</VCol>
                </VRow>

                <VRow>
                  <VCol>Token</VCol>
                  <VCol align='end'>{this.affiliate?.afToken}</VCol>
                </VRow>

                <VRow>
                  <VCol>Blocked</VCol>
                  <VCol align='end'>
                    {this.affiliate?.blocked ? 'yes' : 'no'}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>No Negative Carry-Over</VCol>
                  <VCol align='end'>
                    {this.affiliate?.noNegativeCarryOverEnabled ? 'yes' : 'no'}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>
                    <b>Dates</b>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Last sign in at</VCol>
                  <VCol align='end'>
                    {formatTime(this.affiliate?.lastSignInAt!)}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Created at</VCol>
                  <VCol align='end'>
                    {formatTime(this.affiliate?.createdAt!)}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Updated at</VCol>
                  <VCol align='end'>
                    {formatTime(this.affiliate?.updatedAt!)}
                  </VCol>
                </VRow>
              </VCol>

              <VCol md={6} sm={12}>
                <VRow>
                  <VCol>
                    <b>Accounting</b>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Currency</VCol>
                  <VCol align='end'>{this.affiliate?.currency}</VCol>
                </VRow>

                <VRow>
                  <VCol>Sports scheme</VCol>
                  <VCol align='end'>
                    {this.affiliate?.sportsCommissionPercentage}% of{' '}
                    {this.affiliate?.sportsCommissionScheme || 'unknown'}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Casino scheme</VCol>
                  <VCol align='end'>
                    {this.affiliate?.casinoCommissionPercentage}% of{' '}
                    {this.affiliate?.casinoCommissionScheme || 'unknown'}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Deposits</VCol>
                  <VCol align='end'>
                    {formatCurrency(this.affiliate?.eurDeposits, 2, {
                      name: 'EUR',
                    })}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Commission</VCol>
                  <VCol align='end'>
                    {formatCurrency(this.affiliate?.eurCommission, 2, {
                      name: 'EUR',
                    })}
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>
                    <b>Players</b>
                  </VCol>
                </VRow>

                <VRow>
                  <VCol>Total</VCol>
                  <VCol align='end'>{this.affiliate?.totalPlayers}</VCol>
                </VRow>

                <VRow>
                  <VCol>Acquired</VCol>
                  <VCol align='end'>{this.affiliate?.acquiredPlayers}</VCol>
                </VRow>

                <VRow>
                  <VCol>Active</VCol>
                  <VCol align='end'>{this.affiliate?.activePlayers}</VCol>
                </VRow>
              </VCol>
            </VRow>
          </VContainer>
        ),
      });

      tabs.push({
        requiresRoles: ['finance:operator', 'finance:user'],
        key: 'account-balances',
        name: 'account balances',
        content: (
          <AffiliateBalances
            affiliateID={this.affiliate?.id}
            affiliateUUID={this.affiliateUUID}
            currencies={[this.affiliate?.currency]}
          />
        ),
      });

      return tabs;
    },
  },
  render() {
    return (
      <div>
        <VCard elevation={4} class='ma-4 pa-2'>
          {this.affiliate ? (
            <div>
              Affiliate ({this.affiliate?.id}){' '}
              <small class='grey--text'>{this.affiliateUUID}</small>
            </div>
          ) : (
            <VSkeletonLoader type='list-item' />
          )}
        </VCard>
        <VCard elevation={4} class='ma-4'>
          <VTabs v-model={this.tab}>
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTab to={'#' + tab.key} href={'#' + tab.key}>
                  {tab.name}
                </VTab>
              ))}
            {this.tabs()
              .filter((tab) => hasRoles(tab.requiresRoles))
              .map((tab) => (
                <VTabItem value={tab.key}>{tab.content}</VTabItem>
              ))}
          </VTabs>
        </VCard>
      </div>
    );
  },
});
