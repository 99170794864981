import { Route } from './types';
// import Example from '@/views/casino/Example';
import LoyaltyTiers from '@/views/casino/LoyaltyTiers';

const casinoRoutes: Route[] = [
  // {
  //   path: '/casino-example',
  //   name: 'casino-example',
  //   component: Example,
  //   meta: {
  //     requiresAuth: true,
  //     requiresRoles: ['casino:operator', 'casino:user'],
  //   },
  // },
  {
    path: '/casino-loyalty-tiers',
    name: 'casino-loyalty-tiers',
    component: LoyaltyTiers,
    meta: {
      requiresAuth: true,
      requiresRoles: ['casino:operator', 'casino:user'],
    },
  },
];

export default casinoRoutes;
