import { currencies as allCurrencies } from '@/static';
import { validateNumber } from '@/utils/validate';
import { gql } from 'apollo-boost';
import Vue from 'vue';
import {
  VAlert,
  VBtn,
  VCard,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCol,
  VDialog,
  VForm,
  VOverlay,
  VProgressCircular,
  VRow,
  VSelect,
  VTextField,
  VSpacer,
  VCardActions,
  VSwitch,
} from 'vuetify/lib';
import { formatCurrency } from '@/filters';
import { createNamespacedHelpers } from 'vuex';
import { PaymentTypes, VipPaymentTypes } from '@/types/zero-payments';
const { mapActions, mapState } = createNamespacedHelpers('exchangeRates');

interface TransferFundsInput {
  uuid: string;
  amount: string;
  currency: string;
  description: string;
  vip_payment_type: boolean;
  payment_type: PaymentTypes;
}

export const TransferFundsDialog = Vue.extend({
  props: {
    isOpen: Boolean,
    uuid: String,
    type: String,
    currencies: Array as () => string[],
    presetAmount: String,
    presetCurrency: String,
  },

  computed: {
    ...mapState(['rates']),
    endpoint() {
      switch (this.type) {
        case 'player':
          return 'transferFunds';
        case 'affiliate':
          return 'affiliateTransferFunds';
        default:
          throw new Error('unknown type');
      }
    },
  },

  data() {
    return {
      input: {
        amount: '0',
        currency: '',
        description: '',
        vip_payment_type: false,
        payment_type: PaymentTypes.MANUAL_CREDIT,
      } as TransferFundsInput,
      loading: false,
      error: '',
    };
  },

  methods: {
    ...mapActions(['loadRates']),
    toEur(value: string) {
      if (!this.input.currency) {
        return 0;
      }

      return parseFloat(value) * this.rates[this.input.currency];
    },
    eurHint(value: string): string {
      return '~ ' + formatCurrency(this.toEur(value), 2, { name: 'EUR' });
    },
    isValid() {
      return this.input.amount && this.input.currency && this.input.description;
    },
    cleanup() {
      this.input = {
        amount: '0',
        currency: '',
        description: '',
        vip_payment_type: false,
      } as TransferFundsInput;
    },
    async onSave() {
      try {
        this.error = '';
        this.loading = true;

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: TransferFundsInput!) {
              ${this.endpoint}(input: $input) {
                success
                code
              }
            }
          `,
          variables: {
            input: {
              ...this.input,
              vip_payment_type: this.input.vip_payment_type ? VipPaymentTypes.VIP : VipPaymentTypes.ADMIN,
              uuid: this.uuid,
            },
          },
        });

        this.$emit('success');
        this.cleanup();
      } catch (err) {
        const code = err?.graphQLErrors[0]?.extensions?.response?.body?.code;
        if (code === 'APPROVAL_REQUIRED') {
          this.$emit('success', 'TRANSFER APPROVAL REQUIRED');
          this.cleanup();
          return;
        }

        this.error = code || err.message;
      } finally {
        this.loading = false;
      }
    },
    onClose() {
      this.$emit('close');
      this.cleanup();
    },
    currenciesOptions() {
      if (!this.currencies) {
        return allCurrencies;
      }

      const options = allCurrencies.reduce((map: any, obj) => {
        map[obj.value] = obj;
        return map;
      }, {});

      return this.currencies.map((k) => options[k]);
    },
  },

  created() {
    this.loadRates();

    if (this.presetCurrency) {
      this.input.currency = this.presetCurrency;
    }

    if (this.presetAmount) {
      this.input.amount = this.presetAmount;
    }
  },

  render() {
    return (
      <VDialog
        onInput={this.onClose}
        value={this.isOpen}
        width='unset'
        max-width='500'
      >
        <VCard>
          <VOverlay absolute='absolute' value={this.loading}>
            <VProgressCircular indeterminate size={64}></VProgressCircular>
          </VOverlay>
          <VCardTitle>Transfer funds</VCardTitle>
          <VCardSubtitle>
            <div>
              to {this.type} {this.uuid}
            </div>
          </VCardSubtitle>
          <VCardText>
            <VForm>
              <VRow>
                <VCol cols={8}>
                  <VTextField
                    class='input-field'
                    vModel={this.input.amount}
                    label='Amount'
                    dense
                    outlined
                    rules={[validateNumber]}
                    persistent-hint
                    hint={this.eurHint(this.input.amount)}
                    disabled={!!this.presetAmount}
                  />
                </VCol>
                <VCol cols={4}>
                  <VSelect
                    class='input-field'
                    items={this.currenciesOptions()}
                    vModel={this.input.currency}
                    label='Currency'
                    dense
                    outlined
                    disabled={!!this.presetCurrency}
                  />
                </VCol>
              </VRow>
              <VTextField
                vModel={this.input.description}
                label='Description'
                dense
                outlined
                class='input-field'
              />
              <VSwitch
                vModel={this.input.vip_payment_type}
                label='VIP cost'/>
            </VForm>
            {this.error ? <VAlert type='warning'>{this.error}</VAlert> : null}
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn
              onClick={this.onSave}
              class='button'
              color='primary'
              disabled={!this.isValid()}
            >
              Transfer
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
