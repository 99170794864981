import Vue from 'vue';
import { hasRoles } from '@/utils/auth';
import { VContainer, VRow } from 'vuetify/lib';
import PendingDepositsWidget from './PendingDepositsWidget';


export const PendingDepositsTab = Vue.extend({
  data() {
    const dateNow = new Date();
    dateNow.setHours(23, 59, 59, 999);
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(dateNow.getMonth() - 1);
    oneMonthAgo.setHours(0, 0, 0, 0);
    return {
      page: Number(this.$route.query.page) || 1,
      pageSize: Number(this.$route.query.pageSize) || 20,
      from: Number(this.$route.query.from) || oneMonthAgo.getTime() / 1000,
      to: Number(this.$route.query.to) || Math.floor(dateNow.getTime() / 1000),
    } as {
      page: number,
      pageSize: number,
      from: number,
      to: number,
    };
  },
  methods: {
    handlePendingDepositsPagination(page: number, pageSize: number) {
      if (this.$route.query.page !== String(page) || this.$route.query.pageSize !== String(pageSize)) {
        this.$router.push({
          query: {
            page: page.toString(),
            pageSize: pageSize.toString(),
            from: this.from.toString(),
            to: this.to.toString(),
          },
        });
        this.page = page;
        this.pageSize = pageSize;
        window.scrollTo(0, 0);
      }
    },
    handleDateRangeUpdate(from: number, to: number) {
      this.$router.push({
        query: {
          page: this.page.toString(),
          pageSize: this.pageSize.toString(),
          from: from.toString(),
          to: to.toString(),
        },
      });
      this.from = from;
      this.to = to;
    },
  },
  render() {
    return (
      <VContainer fluid>
        {
          hasRoles(['cs:operator', 'cs:user', 'payments:operator', 'payments:user']) && (
            <VRow>
              <PendingDepositsWidget
                page = {this.page}
                pageSize = {this.pageSize}
                from = {this.from}
                to = {this.to}
                onPageUpdate = {this.handlePendingDepositsPagination}
                onDateRangeUpdate = {this.handleDateRangeUpdate}
              />
            </VRow>
          )
        }
      </VContainer >
    );
  },
});
