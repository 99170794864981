interface TagConfig {
  color: string;
  undeletable?: boolean;
}

export const playerTagConfig: Record<string, TagConfig> = {
  'cloudbet': {
    color: 'gray',
  },
  'vip': {
    color: 'purple',
  },
  'diamond': {
    color: 'blue',
  },
  'gold': {
    color: 'orange',
  },
  'green': {
    color: 'green',
  },
  'multi account': {
    color: 'warning',
    undeletable: true,
  },
};
