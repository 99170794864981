import Vue, { VueConstructor } from 'vue';
import {
  VSelect,
  VBtn,
  VCard,
  VListItem,
  VCardTitle,
  VContainer,
  VForm,
} from 'vuetify/lib';
import { validateRequired } from '@/utils/validate';
import { mapActions } from 'vuex';
import errorHandlerMixin from '../errorHandlerMixin';

const partnerRole = 'partner';

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],
  props: {
    playerUUID: String,
    brand: String,
    jwtRole: String,
  },

  data() {
    return {
      selectedRole: this.jwtRole,
      updating: false,
    };
  },

  methods: {
    ...mapActions({
      upgradeCustomerToPartner: 'customerBetSettings/upgradeCustomerToPartner',
    }),

    async saveChanges() {
      try {
        this.updating = true;
        await this.upgradeCustomerToPartner({ brand: this.brand, playerUUID: this.playerUUID });
        this.showSuccessMessage('A role has been upgraded');
      } catch (e) {
        this.showFailureMessage(['Couldn\'t upgrade a role', e.message]);
        throw e;
      } finally {
        this.updating = false;
      }
    },
  },

  computed: {
    changedRole(): boolean {
      return this.selectedRole !== this.jwtRole;
    },

    isPartner(): boolean {
      return this.jwtRole === partnerRole;
    },

    selectionItems(): string[] {
      if (this.jwtRole === partnerRole) {
        return [this.jwtRole];
      } else {
        return [this.jwtRole, partnerRole];
      }
    },
  },

  render() {
    return (
      <VCard width='350'>
        {this.failureSnackbar}
        {this.successSnackbar}
        <VCardTitle>API Betting Role</VCardTitle>
        <VListItem >
          <VContainer fluid>
            <VForm ref='form'>
              <VSelect
                label='API Betting Role'
                items={this.selectionItems}
                v-model={this.selectedRole}
                outlined
                dense
                filled
                required
                rules={[validateRequired]}
                disabled={this.isPartner} />
              <VBtn onClick={() => { this.selectedRole = this.jwtRole; }} disabled={!this.changedRole} class='mb-2'>
                RESET
              </VBtn>
              <VBtn onClick={this.saveChanges}
                disabled={!this.changedRole}
                color='green'
                class='ml-2'
                loading={this.updating}>
                SAVE
              </VBtn>
            </VForm>
          </VContainer>
        </VListItem>
      </VCard >
    );
  },
});
