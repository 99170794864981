import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VDataTable, VBtn, VAlert, VToolbarTitle, VToolbar, VSpacer } from 'vuetify/lib';
import { createNamespacedHelpers } from 'vuex';
import { hasRoles } from '@/utils/auth';

const { mapActions, mapState } = createNamespacedHelpers('exchangeRates');

import { formatCurrency, currencyAlias } from '@/filters';
import currenciesConfig from '@/config/currencies';

import { TransferFundsDialog } from './TransferFundsDialog';
import { ZeroPayments } from '@/components/sports/ZeroPayments';


interface Balance {
  currency: string;
  amount: number;
  eurAmount: number;
}

export default Vue.extend({
  props: {
    affiliateUUID: String,
    affiliateID: Number,
    currencies: Array as () => string[],
  },

  computed: {
    ...mapState(['rates']),
    transferFundsDialog() {
      return (
        <TransferFundsDialog
          isOpen={this.transferFundsDialogIsOpen}
          uuid={this.affiliateUUID}
          type='affiliate'
          currencies={this.currencies}
          on={{
            success: (message: string) => {
              this.message = message;
              this.$apollo.queries.affiliateBalances.refetch();
              this.transferFundsDialogIsOpen = false;
            },
            close: () => {
              this.transferFundsDialogIsOpen = false;
            },
          }}
        />
      );
    },
    canTransferFunds(): boolean {
      return hasRoles(['finance:operator']);
    },
  },

  methods: {
    ...mapActions(['loadRates']),
  },

  data() {
    return {
      message: '',
      transferFundsDialogIsOpen: false,
      affiliateBalances: [] as Balance[],
      headers: [
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Amount',
          value: 'amount',
          align: 'end',
        },
        {
          text: 'Amount (EUR)',
          value: 'eurAmount',
          align: 'end',
        },
      ],
    };
  },

  mounted() {
    this.loadRates();
  },

  apollo: {
    affiliateBalances: {
      query():
        DocumentNode {
        return gql`
          query($id: Int!) {
            affiliateBalances: affiliateBalances(id: $id) {
              currency
              amount
            }
          }
        `;
      },
      update(data) {
        return data.affiliateBalances.map((balance: Balance) => {
          const exchangeRate = this.rates[balance.currency];
          balance.eurAmount = exchangeRate ? balance.amount * exchangeRate : 0;
          return balance;
        });
      },
      variables(): any {
        return { id: this.affiliateID };
      },
      skip(): any {
        return !this.affiliateID;
      },
    },
  },

  render() {
    return (
      <div>
        {this.transferFundsDialog}
        {this.message ? <VAlert type='warning'>{this.message}</VAlert> : null}
        <VToolbar dense>
          <VToolbarTitle>Balances</VToolbarTitle>
          <VSpacer />
          {this.canTransferFunds &&
            <VBtn
              color='primary'
              onClick={() => { this.transferFundsDialogIsOpen = true; }}>
              transfer funds
            </VBtn>
          }
        </VToolbar>
        <VDataTable
          headers={this.headers}
          items={this.affiliateBalances}
          sortBy={'type'}
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.affiliateBalances.loading}
          hideDefaultFooter
          disablePagination
          dense
          scopedSlots={{
            'item.currency': ({ item }: { item: Balance }) => {
              return currencyAlias(item.currency);
            },
            'item.amount': ({ item }: { item: Balance }) => {
              return formatCurrency(item.amount, currenciesConfig[item.currency]?.precise?.dp || 18);
            },
            'item.eurAmount': ({ item }: { item: Balance }) => {
              return formatCurrency(item.eurAmount, 2);
            },
          }}
        />
        {this.affiliateBalances.length > 0 &&
          <ZeroPayments class='mt-8' refetch={this.$apollo.queries.affiliateBalances.loading}
            accountType={'affiliate'} accountUUID={this.affiliateUUID} on={{
              update: () => this.$apollo.queries.affiliateBalances.refetch(),
            }} />}
      </div>
    );
  },
});
