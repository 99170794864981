<template>
  <div id="app">
    <v-theme-provider root>
      <component :is="layout"></component>
    </v-theme-provider>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';

export default Vue.extend({
  computed: {
    ...mapGetters({
      isAuthed: 'auth/isAuthenticated',
    }),
    layout() {
      return this.isAuthed ? 'dashboard-layout' : 'signin-layout';
    },
  },
});
</script>
