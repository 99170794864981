import PlayerEventLiability from '@/components/sports/PlayerEventLiability';
import { formatCurrency } from '@/filters';
import { RouterLink } from '@/router';
import { getMarketName, Locale, MarketType } from '@cloudbet/market-helper/sports-core';
import gql from 'graphql-tag';
import Vue from 'vue';
import { VCard, VCardSubtitle, VCardText, VCardTitle, VContainer, VDataTable, VDialog, VIcon } from 'vuetify/lib';
import CountryFlag from 'vue-country-flag';

interface Player {
  uuid: string;
  nickname: string;
  exposure: number;
  betsCount: number;
  marketKey: string;
  countries: string[];
}

export default Vue.extend({
  props: {
    eventId: String,
    outcome: String,
    marketKey: String,
  },
  data() {
    return {
      dataLoaded: false,
      players: [] as Player[],
      subscription: null as any,
      headers: [
        { text: 'Player', value: 'player', sortable: false },
        { text: 'Market', value: 'marketKey', sortable: false },
        { text: '#bets', value: 'betsCount', sortable: false },
        { text: 'Exposure', value: 'exposure', sortable: false },
      ],
      dialogs: {} as any,
    };
  },
  methods: {
    translateMarket(marketKey: string) {
      if (marketKey === 'all') {
        return 'all';
      }

      if (marketKey.endsWith('.outright.v2')) {
        return 'Outright';
      }

      const translation = getMarketName(Locale.en, { params: '' }, marketKey as MarketType);
      if (translation[0] !== '') {
        return translation[0];
      }

      return marketKey;
    },
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription eventPlayersUpdated(
              $eventId: String!
              $outcome: String
              $marketKey: String
            ) {
              eventPlayersUpdated(
                eventId: $eventId,
                outcome: $outcome,
                marketKey: $marketKey
              ) {
                uuid
                nickname
                exposure
                betsCount
                marketKey
                countries
              }
            }
          `,
          variables: {
            eventId: this.eventId,
            outcome: this.outcome,
            marketKey: this.marketKey,
          },
        })
        .subscribe({
          next: ({ data }) => {
            this.players = data.eventPlayersUpdated;
            this.dataLoaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  render() {
    return (
      <VContainer class='pa-0'>
        <VDataTable
          headers={this.headers}
          items={this.players}
          loading={!this.dataLoaded}
          disablePagination={true}
          hideDefaultFooter={true}
          scopedSlots={{
            'item.player': ({ item }: { item: Player }) => (
              <VDialog
                v-model={this.dialogs[item.uuid + item.marketKey]}
                width='unset'
                scopedSlots={{
                  activator: ({ on, attrs }: any) => (
                    <a onClick={on.click}>
                      {item.nickname || item.uuid}
                      {item.countries && item.countries
                        .map((country) => <CountryFlag country={country} size='small' rounded={true} />)}
                    </a>
                  ),
                }}>
                <VCard>
                  <VCardTitle>
                    {item.nickname || item.uuid}
                    <RouterLink
                      class='text-decoration-none'
                      to={{ name: 'player', params: { playerUUID: item.uuid } }}
                    >
                      <VIcon>link</VIcon>
                    </RouterLink>
                  </VCardTitle>
                  <VCardSubtitle>Placed {item.betsCount} bet(s)</VCardSubtitle>
                  <VCardText>
                    <PlayerEventLiability
                      eventId={this.eventId}
                      playerUUID={item.uuid}
                      marketKey={item.marketKey}>
                    </PlayerEventLiability>
                  </VCardText>
                </VCard>
              </VDialog>
            ),
            'item.exposure': ({ item }: { item: Player }) => (
              <span>{formatCurrency(item.exposure, 0)}</span>
            ),
            'item.marketKey': ({ item }: { item: Player }) => (
              <span>{this.translateMarket(item.marketKey || 'all')}</span>
            ),
          }}>
        </VDataTable>
      </VContainer>
    );
  },
});
