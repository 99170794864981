<template>
  <div class="conection-icons">
    <v-icon class="connection-icon status--success" :class="{active: status === 'CONNECTED'}">sync</v-icon>
    <v-icon
      class="connection-icon status--failure"
      :class="{active: status === 'DISCONNECTED'}"
    >sync_disabled</v-icon>
    <v-icon
      class="connection-icon status--warning"
      :class="{active: status === 'RECONNECTING'}"
    >sync_problem</v-icon>
  </div>
</template>

<script>
import { SOURCES } from '@/streams';
import { mapActions } from 'vuex';
import { gatewayWSClient } from '@/apollo';
export default {
  data: () => ({
    status: 'DISCONNECTED',
  }),
  computed: {},
  created() {
    gatewayWSClient.onConnected(() => {
      this.status = 'CONNECTED';
    });
    gatewayWSClient.onReconnected(() => {
      // Delay to make it more good, and also the flashing effect
      setTimeout(() => {
        this.status = 'CONNECTED';
      }, 3000);
    });
    gatewayWSClient.onDisconnected(() => {
      this.status = 'DISCONNECTED';
    });
    gatewayWSClient.onReconnecting(() => {
      this.status = 'RECONNECTING';
    });
  },
};
</script>

<style lang="less">
.conection-icons {
  display: inline-flex;
  align-items: center;
  position: relative;
}
.connection-icon {
  position: absolute !important;
  right: 0;
  transition: opacity 0.3s !important;
  opacity: 0;
  animation: none;
  &.active {
    opacity: 1;
  }
  &.status--success {
    color: var(--v-success-lighten1) !important;
  }
  &.status--failure {
    color: var(--v-error-lighten1) !important;
  }
  &.status--warning {
    color: var(--v-warning-lighten1) !important;
  }
  &.status--warning.active {
    animation: flashOpacity 1s infinite;
  }
}
@keyframes flashOpacity {
  0% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
</style>
