<template>
  <!-- Single Line View -->
  <div v-if="variant === 'singleLine'">
    <span class="time font-weight-bold">{{ message.receivedAt }}</span>
    <span class="round-state font-weight-bold text-align--right">ROUND ENDED</span>
    <a
      :href="`/players/${round.player.uuid}`"
      target="_blank"
      class="player summary--info"
      :title="round.player.nickname"
    >{{ round.player.nickname }}</a>
    <span class="studioName" :title="studioDisplayName">{{ studioDisplayName }}</span>
    <div class="gameRow">
      <span
        class="gameName ellipsis-text-overflow"
        :title="round.details.gameName"
      >{{ round.details.gameName }}</span>
      <span class="gameType" :title="round.details.gameType">({{ round.details.gameType }})</span>
    </div>
    <span v-if="round.isLive" class="side">live</span>
    <span v-else class="side">rtp @{{ round.rtp | fixWidth(3) }}</span>
    <span
      class="stake-bonus text-align--right"
      :title="round.stakeWithBonus | currency(8, { name: round.baseCurrency })"
    >{{ round.stakeWithBonus | currency(8, { name: round.baseCurrency }) }}</span>
    <span class="price">
      <span
        class="text-align--right stake-bonus-eur"
        :title="round.stakeWithBonusInEur | currency(4, { name: 'EUR' })"
      >{{ round.stakeWithBonusInEur | currency(4, { name: 'EUR' }) }}</span>
      <span
        class="font-weight-bold text-align--right result"
        :class="summaryClass(round)"
        :title="(round.returnAmountWithBonusInEur * -1) | currency(4, { name: 'EUR' })"
      >{{ (round.returnAmountWithBonusInEur * -1) | currency(4, { name: 'EUR' }) }}</span>
    </span>
  </div>
  <!-- Card View -->
  <div v-else>
    <v-row class="d-inline-flex font-weight-bold">
      <span>{{ message.receivedAt }}</span>
      <v-chip
        class="round-state font-weight-black"
        :color="betStateColor('ENDED', round.returnAmountWithBonusInEur)"
        x-small
        label
      >ROUND ENDED</v-chip>
    </v-row>
    <v-row class="mt-1">
      <v-col>
        <a
          :href="playerPageUri(round.player.uuid)"
          target="_blank"
          class="summary--info"
        >{{ round.player.nickname }}</a>
      </v-col>
    </v-row>

    <v-row class="mt-2">
      <v-col>{{ studioDisplayName }}</v-col>
    </v-row>

    <v-row>
      <v-col>{{ round.details.gameName }} ({{ round.details.gameType }})</v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col v-if="round.isLive">live</v-col>
      <v-col v-else>rtp @{{ round.rtp | fixWidth(3) }}</v-col>
      <v-col
        class="text-align--right"
      >{{ round.stakeWithBonus | currency(8, { name: round.baseCurrency }) }}</v-col>
    </v-row>
    <v-row>
      <v-col class="text-align--right">{{ round.stakeWithBonusInEur | currency(4, { name: 'EUR' }) }}</v-col>
    </v-row>
    <v-row :class="summaryClass(round)" class="font-weight-bold">
      <v-col class="text-align--right">{{ (round.returnAmountWithBonusInEur * -1) | currency(4, { name: 'EUR' }) }}</v-col>
    </v-row>
  </div>
</template>

<script>
import base from './base';

export default {
  ...base,
  computed: {
    round() {
      return this.message.data;
    },
    studioDisplayName() {
      // Only show studio name if its live as they are the same
      if (this.round.isLive) {
        return this.round.details.studioName;
      }
      return `${this.round.details.studioName} - ${this.round.details.distributorName}`;
    },
  },
};
</script>
