import Vue from 'vue';
import { VDataTable } from 'vuetify/lib';
import { RouterLink } from '@/router';

export default Vue.extend({
  props: {
    headers: Array,
    items: Array,
    loading: Boolean,
    routerLinkTo: Function,
    actionsColumn: Object,
    actionsSlot: Function,
  },

  methods: {
    links(props: { item: { id: number } }): JSX.Element {
      return (
        <RouterLink
          class='text-decoration-none'
          to={this.routerLinkTo(props.item)}>
          {props.item.id}
        </RouterLink>
      );
    },
  },

  computed: {
    effectiveHeaders() {
      if (this.actionsColumn) {
        return [...this.headers, { ...this.actionsColumn, value: 'actions' }];
      }
      return this.headers;
    },

    effectiveSlots(): object {
      const slots = { 'item.id': this.links };
      if (this.actionsSlot) {
        return {
          'item.actions': this.actionsSlot,
          ...slots,
        };
      }
      return slots;
    },
  },

  render() {
    return (
      <VDataTable
        class='elevation-1'
        items={this.items || []}
        headers={this.effectiveHeaders}
        loading-text='Searching... Please wait'
        loading={this.loading}
        scopedSlots={this.effectiveSlots}>
      </VDataTable>
    );
  },
});
