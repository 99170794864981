import Vue from 'vue';
import Chart from 'chart.js';
import { generateChart } from 'vue-chartjs';

interface DataSet {
  data: number[];
  backgroundColor?: string[];
}

export interface ChartData {
  labels: string[];
  datasets: DataSet[];
}


// @ts-ignore
// https://stackoverflow.com/questions/36916867/chart-js-line-different-fill-color-for-negative-point
Chart.defaults.NegativeTransparentLine = Chart.defaults.line;

Chart.controllers.NegativeTransparentLine = Chart.controllers.line.extend({
  // @ts-ignore
  update(reset) {
    // get the min and max values
    const min = Math.min.apply(null, this.chart.data.datasets[0].data);
    const max = Math.max.apply(null, this.chart.data.datasets[0].data);
    const yScale = this.getScaleForId(this.getDataset().yAxisID);

    // figure out the pixels for these and the value 0
    const top = yScale.getPixelForValue(max);
    const zero = yScale.getPixelForValue(0);
    const bottom = yScale.getPixelForValue(min);
    // build a gradient that switches color at the 0 point
    const ctx = this.chart.chart.ctx;
    const gradient = ctx.createLinearGradient(0, top, 0, bottom);
    let ratio = Math.min((zero - top) / (bottom - top), 1);
    if (ratio < 0) {
      gradient.addColorStop(1, 'rgba(255,0,0,1)');
      ratio = 0;
    } else if (ratio === 1) {
      gradient.addColorStop(1, 'rgba(0,255,0,1)');
    } else {
      gradient.addColorStop(0, 'rgba(0,255,0,1)');
      gradient.addColorStop(ratio, 'rgba(0,255,0,1)');
      gradient.addColorStop(ratio, 'rgba(255,0,0,1)');
      gradient.addColorStop(1, 'rgba(255,0,0,1)');
    }
    this.chart.data.datasets[0].backgroundColor = gradient;
    return Chart.controllers.line.prototype.update.apply(this, arguments);
  },

});
const CustomLine = generateChart('custom-line', 'NegativeTransparentLine');

export const SparklineCustom = Vue.extend({
  extends: CustomLine,
  props: {
    chartData: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    options: {
      tooltips: {
        enabled: false,
      },
      scales: {
        xAxes: [
          {
            ticks: {
              display: false,
            },
            gridLines: {
              display: false,
              drawBorder: false,
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              display: false,
            },
            gridLines: {
              color: 'transparent',
              drawBorder: false,
              zeroLineWidth: 1,
              zeroLineColor: 'white',
              zeroLineBorderDash: [5, 4],
            },
          },
        ],
      },
      responsive: true,
      // maintainAspectRatio: true,
      legend: {
        display: false,
      },
      elements: {
        point: {
          radius: 0,
          borderWidth: 0,
          display: false,
          pointHitRadius: 0,
        },
      },
    },
  }),
  mounted() {
    // @ts-ignore
    this.renderChart(this.chartData, this.options);
  },
});
