import { ActionTree } from 'vuex';
import { gql } from 'apollo-boost';

const actions: ActionTree<{}, any> = {
  async matchCompetitor(
        { rootState },
        { competitorId, provider, providerKey }) {
    const client = rootState.apolloClient();

    await client.mutate({
      mutation: gql`mutation MatchProviderCompetitor($input: GenericEntityToProviderEntityIdentifierInput!) {
              matchProviderCompetitor(input: $input)
            }`,
      variables: {
        input: {
          genericEntityId: competitorId,
          provider,
          providerKey,
        },
      },
    });
  },
};

export default {
  namespaced: true,
  actions,
};

