// this module is a single global snackbar to show message (error for now)
import { ActionTree, GetterTree, MutationTree } from 'vuex';

export interface Snackbar {
  error: '';
}

const getters: GetterTree<Snackbar, any> = {
  error(state): string {
    return state.error;
  },
};

const mutations: MutationTree<Snackbar> = {
  UPDATE_ERROR(state, error) {
    state.error = error;
  },
  CLEAR_ERROR(state) {
    state.error = '';
  },
};

const actions: ActionTree<Snackbar, any> = {
  async showErrorSnackbar({ commit }, error) {
    commit('UPDATE_ERROR', error);
  },
  async clearErrorSnackbar({ commit }) {
    commit('CLEAR_ERROR');
  },
};

const Snackbar = {
  namespaced: true,
  state: { error: '' } as Snackbar,
  getters,
  mutations,
  actions,
};

export default Snackbar;
