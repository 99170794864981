import Vue, { VueConstructor } from 'vue';
import {
  VBtn,
  VDialog,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VCard,
  VTextarea,
  VProgressLinear,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import errorHandlerMixin from '../errorHandlerMixin';

export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],

  props: {
    playerUUID: String,
  },

  data() {
    return {
      dialog: true,
      comment: '',
      showProgress: false,
    };
  },

  methods: {
    async onAgree() {
      this.showProgress = true;
      try {
        await this.cancelBets();
        this.$emit('agree');
      } catch (e) {
        this.showFailureMessage(['Couldn\'t trigger the cancell player bets background job.', e.message]);
        throw e;
      } finally {
        this.showProgress = false;
      }
    },

    onDisagree() {
      this.$emit('disagree');
    },

    async cancelBets() {

      return await this.$apollo.mutate({
        mutation: gql`mutation ($playerUUID: String!, $comment: String!) {
            cancelPlayerSportsBets(playerUUID: $playerUUID, comment: $comment)
          }`,
        variables: {
          playerUUID: this.playerUUID,
          comment: this.comment,
        },
        fetchPolicy: 'no-cache',
      });
    },
  },

  computed: {
    disableAgreeButton(): boolean {
      return this.comment === '';
    },
  },

  render() {
    return (
      <div>
        {this.failureSnackbar}
        <VDialog vModel={this.dialog} max-width='500' persistent>
          <VCard>
            <VCardTitle class='headline'>
              Player bets cancelling
          </VCardTitle>
            <VCardText>
              You are going to cancel all player's pending bets.<br />
            Add a comment below:
            <VTextarea vModel={this.comment} outlined></VTextarea>
              <VProgressLinear
                indeterminate={this.showProgress}
                active={this.showProgress}
                striped
                height={10} />
            </VCardText>
            <VCardActions>
              <VSpacer></VSpacer>
              <VBtn color='green darken-1' text onClick={this.onDisagree}>
                Disagree
              </VBtn>
              <VBtn color='green darken-1' text
                onClick={this.onAgree}
                disabled={this.disableAgreeButton}
                loading={this.showProgress}>
                Agree
            </VBtn>
            </VCardActions>
          </VCard>
        </VDialog>
      </div>
    );
  },
});
