import { Route } from './types';
import CryptoDepositResync from '@/views/payments/CryptoDepositResync';
import FailedWithdrawalsWidget from '@/views/payments/FailedWithdrawalsWidget';
import FiatWithdrawals from '@/views/payments/FiatWithdrawals';
import FiatDeposits from '@/views/payments/FiatDeposits';

const paymentsRoutes: Route[] = [
  {
    path: '/fiat-withdrawals',
    name: 'fiatWithdrawals',
    component: FiatWithdrawals,
    meta: {
      requiresAuth: true,
      requiresRoles: ['payments:operator', 'payments:user', 'cs:operator', 'cs:user'],
    },
  },
  {
    path: '/fiat-deposits',
    name: 'fiat-deposits',
    component: FiatDeposits,
    meta: {
      requiresAuth: true,
      requiresRoles: ['payments:operator', 'payments:user', 'cs:operator', 'cs:user'],
    },
  },
  {
    path: '/crypto-deposit-resync',
    name: 'crypto-deposit-resync',
    component: CryptoDepositResync,
    meta: {
      requiresAuth: true,
      requiresRoles: ['cs:operator', 'cs:user', 'payments:operator', 'payments:user'],
    },
  },
  {
    path: '/failed-withdrawals-ops',
    name: 'failed-withdrawals-ops',
    component: FailedWithdrawalsWidget,
    meta: {
      requiresAuth: true,
      requiresRoles: ['payments:operator', 'payments:user'],
    },
  },
];

export default paymentsRoutes;
