import Vue from 'vue';
import {
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VBtn,
  VTextField,
  VContainer,
  VRow,
  VCol,
  VSnackbar,
  VAutocomplete,
} from 'vuetify/lib';
import SearchCompetitionDataTable from '@/components/GlobalSearch/SearchCompetitionDataTable';
import { createNamespacedHelpers } from 'vuex';
import { CompetitionPriorityGroup } from '@/types/sports';
const { mapState, mapActions } = createNamespacedHelpers('sportsConsts');
const { mapMutations: mapPriorityMutations } = createNamespacedHelpers('sports/competitionsPriorities');

interface SearchParams {
  sport: number;
  category: number;
  text: string;
}

export default Vue.extend({
  props: {
    group: Object as () => CompetitionPriorityGroup,
    selectedCompetitionsKeys: Set,
    active: Boolean,
    includeMerged: Boolean,
  },

  data() {
    return {
      dialog: true,
      successSnackbar: false,
      failureSnackbar: false,
      failureMessage: undefined as string | undefined,
      sport: 0,
      category: 0,
      searchText: '',
      searchParams: undefined as SearchParams | undefined,
    };
  },

  computed: {
    ...mapState(['sports', 'categories']),

    successSnackbarWidget() {
      return (
        <VSnackbar
          timeout='1000'
          v-model={this.successSnackbar}
          absolute
          centered
          color='green darken-1'
          elevation='24'>
          The competition has been added to the list.
        </VSnackbar>
      );
    },

    failureSnackbarWidget() {
      return (
        <VSnackbar
          timeout='2000'
          v-model={this.failureSnackbar}
          absolute
          centered
          color='red darken-1'
          elevation='24'>
          The competition has not been added to the list.
          <div>{this.failureMessage}</div>
        </VSnackbar>
      );
    },
  },

  methods: {
    ...mapActions(['loadSportsConsts']),
    ...mapPriorityMutations(['ADD_COMPETITION_PRIORITY']),

    onClose() {
      this.$emit('dialogClosed');
    },

    onKeydown(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        this.onClose();
      }
    },

    onSearchClick() {
      this.searchParams = {
        sport: this.sport,
        category: this.category,
        text: this.searchText,
      };
    },

    async onAddClick(item: any) {
      try {
        this.ADD_COMPETITION_PRIORITY({
          group: this.group,
          priority: {
            categoryId: item.categoryId,
            key: item.key,
            name: item.name,
            sportKey: item.sportKey,
          },
        });
        this.successSnackbar = true;
      } catch (e) {
        this.failureSnackbar = true;
        this.failureMessage = e.message;
      } finally {
        (this.$refs.searchButton as any).$el.focus();
      }
    },

    addLinkAction({ item }: { item: any }) {
      if (this.selectedCompetitionsKeys.has(item.key)) {
        return (<VBtn text disabled>Add</VBtn>);
      } else {
        return (<VBtn text color='primary' onClick={() => this.onAddClick(item)}>Add</VBtn>);
      }
    },
  },

  mounted() {
    this.loadSportsConsts();
  },

  render() {
    return (
      <VDialog v-model={this.dialog} max-width='1000' persistent
        on={{ 'click:outside': this.onClose, 'keydown': this.onKeydown }}>
        {this.successSnackbarWidget}
        {this.failureSnackbarWidget}
        <VCard>
          <VCardTitle class='headline'>
          </VCardTitle>

          <VCardText>
            <VContainer>
              <VRow>
                <VCol cols='3'>
                  <VAutocomplete items={this.sports} item-value='id' item-text='name' label='Sport'
                    v-model={this.sport} >
                  </VAutocomplete>
                </VCol>
                <VCol cols='3'>
                  <VAutocomplete items={this.categories} item-value='id' item-text='name' label='Category'
                    v-model={this.category} >
                  </VAutocomplete>
                </VCol>
                <VCol cols='3'>
                  <VTextField v-model={this.searchText} autofocus label='Search for' >
                  </VTextField>
                </VCol>
                <VCol cols='3' class='text-right'>
                  <VBtn color='primary' onClick={this.onSearchClick} ref='searchButton'>
                    Search
                  </VBtn>
                </VCol>
              </VRow>
            </VContainer>
          </VCardText>

          {this.searchParams &&
            <VCardText>
              <SearchCompetitionDataTable
                searchText={this.searchParams.text}
                sportId={this.searchParams.sport}
                categoryId={this.searchParams.category}
                actionsColumn={{ text: 'Actions' }}
                active={this.active}
                includeMerged={this.includeMerged}
                actionsSlot={this.addLinkAction} />
            </VCardText>}

          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='green darken-1' text onClick={this.onClose}>
              Close
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});

