interface Settings {
  dp: number;
}

interface View {
  precise: Settings;
  simple: Settings;
}

export default {
  BTC: {
    precise: {
      dp: 8,
    },
    simple: {
      dp: 4,
    },
  },
  BCH: {
    precise: {
      dp: 8,
    },
    simple: {
      dp: 4,
    },
  },
  ETH: {
    precise: {
      dp: 18,
    },
    simple: {
      dp: 4,
    },
  },
  EUR: {
    precise: {
      dp: 4,
    },
    simple: {
      dp: 2,
    },
  },
  PE1: {
    precise: {
      dp: 2,
    },
    simple: {
      dp: 2,
    },
  },
  USD: {
    precise: {
      dp: 4,
    },
    simple: {
      dp: 2,
    },
  },
} as Record<string, View>;
