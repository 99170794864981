import Vue from 'vue';
import { VCard, VChip } from 'vuetify/lib';

export const SearchInstructions = Vue.extend({
  render() {
    return (
      <VCard class='pa-4 mt-2'>
        <div class='subtitle-1'>
          <b>Search For a Player</b>
        </div>
        <div class='mb-4'>
          Type the word 'player', followed by the nickname or uuid you want to
          search.
        </div>
        <div class='subtitle-1'>
          <b>Search For Nickname (deprecated)</b>
        </div>
        <div class='mb-4'>
          Type the word 'nickname', followed by the nickname you want to
          search.
        </div>
        <div class='subtitle-1'>
          <b>Search For Event</b>
        </div>
        <div>
          Type <VChip label>event</VChip> <VChip label>soccer</VChip>{' '}
          <VChip label>germany</VChip>
          <VChip color='green' label>
            Enter
          </VChip>{' '}
          to find a soccer event with category Germany.
        </div>
        <div class='mt-2'>
          Or search a team name / keywords <VChip label>kansas city</VChip> and{' '}
          <VChip label>competitor</VChip> to find team information.
        </div>
        <div class='subtitle-1 mt-4'>
          <b>Search By ID</b>
        </div>
        <div>
          Copy and paste Event Id number and hit{' '}
          <VChip label color='green'>
            Enter
          </VChip>
        </div>
        <div>
          Type <VChip label>competitor</VChip> or{' '}
          <VChip label>competition</VChip> and paste the ID and hit{' '}
          <VChip label color='green'>
            Enter
          </VChip>
        </div>
        <div class='mt-4 subtitle-1'>
          <b>Search Competitor</b>
        </div>
        <div>
          Type <VChip label>competitor</VChip> <VChip label>basketball</VChip>{' '}
          <VChip label>Boca</VChip>
          and hit enter to search for Boca Juniors. "Boca" is the free-text
          argument.
        </div>
        <div class='mt-4 subtitle-1'>
          <b>Search Player By Deposit Address</b>
        </div>
        <div>
          Type <VChip label>address</VChip> and paste the deposit address, then hit{' '}
          <VChip label color='green'>
            Enter
          </VChip>
        </div>
      </VCard>
    );
  },
});
