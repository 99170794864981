export const FIAT_INTERNAL_CURRENCY_CODES = [
  'AUD',
  'BRL',
  'CAD',
  'CNY',
  'EUR',
  'GBP',
  'HKD',
  'IDR',
  'INR',
  'JPY',
  'KRW',
  'MYR',
  'PHP',
  'PTS',
  'TRY',
  'USD',
];

export const LOYALTY_INTERNAL_CURRENCY_CODES = [
  'RC1',
  'SP1',
];

export const SPORTS_INTERNAL_CURRENCY_CODES = [
  'BE1',
  'PE1',
];
