import { formatCurrency, round } from '@/filters';
import gql from 'graphql-tag';
import Vue from 'vue';
import {
  VBtn,
  VDataTable,
  VToolbar,
  VChip,
  VToolbarTitle,
  VAlert,
} from 'vuetify/lib';
import { unixToDateTimeUTCString } from '@/utils/date';
import ConfirmationDialog from '@/components/ConfirmationDialog';
import { hasRoles } from '@/utils/auth';
import { mapGetters } from 'vuex';

function formatTime(time: string): string {
  if (time === 'now') { return 'now'; }

  return unixToDateTimeUTCString(Date.parse(time) / 1000);
}

interface Payment {
  uuid: string;
  currency: string;
  amount: string;
  exchangeRate: string;
  accountUUID: string;
  accountType: string;
  callerType: string;
  callerID: string;
  description: string;
  state: 'pending' | 'rejected' | 'completed';
  approvingAdminID?: string;
  approvedAt?: string;
  createdAt: string;
}

function formatCash(value: string) {
  return formatCurrency(value, 2);
}

function stateChipColour(state: string) {
  switch (state) {
    case 'pending':
      return 'warning';
    case 'rejected':
      return 'error';
    case 'completed':
      return 'success';
  }
}

const LIMIT = 1024;

export const ZeroPayments = Vue.extend({
  props: {
    accountUUID: String,
    accountType: String,
    refetch: Boolean,
  },
  data() {
    return {
      error: '',
      actionLoading: false,
      allLoaded: false,
      reload: false,
      payments: [] as Payment[],
      headers: [
        {
          text: '#',
          sortable: false,
          align: 'start',
          value: 'uuid',
        },
        {
          text: 'Admin',
          sortable: false,
          align: 'start',
          value: 'callerID',
        },
        {
          text: 'Amount',
          sortable: false,
          align: 'start',
          value: 'amount',
        },
        {
          text: 'Description',
          sortable: false,
          align: 'start',
          value: 'description',
        },
        {
          text: 'Actions',
          sortable: false,
          align: 'start',
          value: 'actions',
        },
      ],
      confirmationDialogOpen: false,
      confirmationPayment: null as Payment | null,
      confirmationAction: 'reject' as 'reject' | 'approve',
    };
  },
  apollo: {
    paymentsQuery: {
      query: gql`
        query(
          $limit: Int!
          $offset: Int
          $accountType: String!
          $accountUUID: String!
        ) {
          payments(
            limit: $limit
            offset: $offset
            accountType: $accountType
            accountUUID: $accountUUID
          ) {
              uuid
              currency
              amount
              exchangeRate
              accountUUID
              accountType
              callerType
              callerID
              description
              state
              approvingAdminID
              approvedAt
              createdAt
          }
        }
      `,
      update(data: { payments: Payment[] }) {
        if (this.reload) {
          this.payments = data.payments || [];
          this.reload = false;

          return;
        }

        if (!data.payments) {
          return;
        }

        if (data.payments.length < LIMIT) {
          this.allLoaded = true;
        }

        this.payments = [...this.payments, ...data.payments];
      },
      variables(): {
        limit: number;
        offset: number;
        accountType: string;
        accountUUID: string;
      } {
        return {
          limit: LIMIT,
          offset: this.reload ? 0 : this.payments.length,
          accountType: this.accountType,
          accountUUID: this.accountUUID,
        };
      },
      skip(): boolean {
        return this.allLoaded;
      },
      debounce: 500,
      fetchPolicy: 'network-only',
    },
  },
  methods: {
    async onConfirmed(payment: Payment, action: 'reject' | 'approve') {
      this.confirmationPayment = null;
      this.confirmationDialogOpen = false;

      let endpoint = '';
      let resultState = '' as 'rejected' | 'completed';

      switch (action) {
        case 'reject':
          endpoint = 'rejectPayment';
          resultState = 'rejected';
          break;
        case 'approve':
          endpoint = 'approvePayment';
          resultState = 'completed';
          break;
      }

      try {
        this.error = '';
        this.actionLoading = true;

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($uuid: String!) {
              ${endpoint}(uuid: $uuid) {
                success
                code
              }
            }
          `,
          variables: {
            uuid: payment.uuid,
          },
        }) as { success: boolean, code?: string };

        payment.state = resultState;
        payment.approvingAdminID = 'you';
        payment.approvedAt = 'now';
        this.$emit('update');
      } catch (err) {
        this.error = err.graphQLErrors[0]?.extensions?.response?.body?.code || err.message;
      } finally {
        this.actionLoading = false;
      }
    },

    openConfirmationDialog(payment: Payment, action: 'reject' | 'approve') {
      this.confirmationPayment = payment;
      this.confirmationAction = action;
      this.confirmationDialogOpen = true;
    },

    accountLink(item: Payment) {
      const params = {} as { affiliateUUID?: string, playerUUID?: string };
      params[`${item.accountType}UUID` as 'affiliateUUID' | 'playerUUID'] = item.accountUUID;

      return { name: item.accountType, params };
    },

    uuid({ item }: { item: Payment }) {
      return <div>
        <span class='mr-2'>{formatTime(item.createdAt)}</span>
        <VChip label x-small color={stateChipColour(item.state)}>{item.state}</VChip>
        <div><small class='grey--text'>{item.uuid}</small></div>
      </div>;
    },

    amount({ item }: { item: Payment }) {
      return <div>
        <span class='mr-2'>
          {formatCurrency(parseFloat(item.amount) * parseFloat(item.exchangeRate), 2)} EUR
        </span>
        <div>
          <small class='grey--text'>
            {item.amount} {item.currency} with {item.exchangeRate}@{item.currency}EUR
          </small>
        </div>
      </div>;
    },

    actions({ item }: { item: Payment }) {
      if (item.state === 'pending') {
        return [
          item.callerID !== this.adminEmail && this.canApprove && <VBtn
            class='ma-1'
            disabled={this.actionLoading} small
            onClick={() => this.openConfirmationDialog(item, 'approve')} color='success'>approve
          </VBtn>,
          (item.callerID === this.adminEmail || this.canReject) && <VBtn
            class='ma-1'
            disabled={this.actionLoading} small
            onClick={() => this.openConfirmationDialog(item, 'reject')} color='error'>reject
          </VBtn>,
        ];
      }

      if (item.approvingAdminID) {
        return <div>
          {item.approvingAdminID}
          <div><small class='grey--text'>At: {formatTime(item.approvedAt!)}</small></div>
        </div>;
      }
    },
  },
  computed: {
    ...mapGetters({ adminEmail: 'auth/email' }),

    confirmationDialog() {
      if (!this.confirmationDialogOpen) { return; }

      return <ConfirmationDialog
        open={this.confirmationDialogOpen}
        title={`Confirmation`}
        text={`Are you sure to ${this.confirmationAction} the payment?`}
        onDisagree={() => this.confirmationDialogOpen = false}
        onAgree={() => this.onConfirmed(this.confirmationPayment!, this.confirmationAction)}
      />;
    },

    alert() {
      if (!this.error) { return; }

      return <VAlert type='error' dismissible text dense>{this.error}</VAlert>;
    },

    canApprove(): boolean {
      return hasRoles(['finance:operator']);
    },

    canReject(): boolean {
      return hasRoles(['finance:operator']);
    },
  },
  watch: {
    refetch(value: boolean) {
      if (value) {
        this.allLoaded = false;
        this.reload = true;
        this.$apollo.queries.paymentsQuery.refetch();
      }
    },
  },
  render() {
    return (
      <div class='pa-0'>
        {this.confirmationDialog}
        {this.alert}
        <VToolbar dense><VToolbarTitle>Transfers History</VToolbarTitle></VToolbar>
        <VDataTable
          headers={this.headers}
          items={this.payments}
          itemKey='uuid'
          loading={this.$apollo.queries.paymentsQuery.loading || this.actionLoading}
          hideDefaultFooter
          disablePagination
          loadingText='Loading... Please wait'
          scopedSlots={{
            'item.uuid': this.uuid,
            'item.amount': this.amount,
            'item.actions': this.actions,
          }}
        ></VDataTable>
      </div>
    );
  },
});
