import { formatCurrency } from '@/filters';

export const successSnackbarConfig = (text: string) => {
  return {
    props: {
      timeout: '2000',
      top: true,
      color: 'green darken-1',
      elevation: '24',
    },
    scopedSlots: {
      default: () => text,
    },
  };
};

export const failureSnackbarConfig = (content: JSX.Element | null) => {
  return {
    props: {
      timeout: '5000',
      top: true,
      color: 'red darken-1',
      elevation: '24',
    },
    scopedSlots: {
      default: () => content,
    },
  };
};

export const formatSize = (price?: number | string) => {
  if (price) {
    return formatCurrency(price, 2, { name: '\u20AC', prependName: true });
  }
  return;
};
