import Vue from 'vue';
import Vuelidate from 'vuelidate';
import PusherPlugin from './pusher';
import Rollbar from './rollbar';
import { env } from '@/env';

export function initializePlugins() {
  Vue.use(Vuelidate);
  Vue.use(PusherPlugin);
  Vue.use(Rollbar, {
    accessToken: env.rollbar.accessToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: env.rollbar.environment,
  });
}
