import _ from 'lodash';
import Vue, { VueConstructor } from 'vue';
import {
  VBtn, VCard,
  VCardActions, VCardText, VCardTitle,
  VCol, VDialog,
  VIcon,
  VRow,
  VChip,
  VChipGroup,
  VDataTable,
  VSpacer,
} from 'vuetify/lib';
import { providersList } from '../EventsMatching/UnmatchedEventsFilter';
import errorHandlerMixin from '../errorHandlerMixin';
import { mapActions, mapGetters } from 'vuex';
import { BaseProviderPriceControl } from '@/types/sports';
import { ProviderPriceControlType } from '@/store/modules/sports/provider_price_control';


const BaseProviderPriceControlTab = Vue.extend({
  props: {
    name: String,
    type: String as () => ProviderPriceControlType,
    providers: Array as () => string[] | undefined,
  },

  data() {
    return {
      providersData: this.providers || [] as string[],
      unselectedProviders: providersList.filter((p) => !this.providers?.includes(p)),
      selectedProvider: '',
      tabs: [],
    };
  },

  methods: {
    ...mapActions({
      updateBaseProviderPriceControlByType: 'sports/providerPriceControl/updateBaseProviderPriceControlByType',
    }),

    onProviderClick(provider: string) {
      this.selectedProvider = provider;
    },
    onItemUp(event: Event, provider: string, index: number) {
      event.stopPropagation();
      if (!this.providersData) {
        return;
      }

      const newProviders = [...this.providersData];
      const currentIndex = index;
      const previousIndex = index - 1;

      const currentProvider = newProviders[currentIndex];
      const previousProvider = newProviders[previousIndex];

      newProviders[currentIndex] = previousProvider;
      newProviders[previousIndex] = currentProvider;

      this.providersData = newProviders;
      this.selectedProvider = provider;

      this.updateBaseProviderPriceControlByType({ type: this.type, baseProviderPriceControl: this.providersData });
    },
    onItemDown(event: Event, provider: string, index: number) {
      event.stopPropagation();
      if (!this.providersData) {
        return;
      }

      const newProviders = [...this.providersData];
      const currentIndex = index;
      const nextIndex = index + 1;

      const currentProvider = newProviders[currentIndex];
      const nextProvider = newProviders[nextIndex];

      newProviders[currentIndex] = nextProvider;
      newProviders[nextIndex] = currentProvider;

      this.providersData = newProviders;
      this.selectedProvider = provider;

      this.updateBaseProviderPriceControlByType({ type: this.type, baseProviderPriceControl: this.providersData });
    },
    onItemDelete(event: Event, provider: string) {
      event.stopPropagation();
      this.unselectedProviders = [...this.unselectedProviders, provider];
      this.providersData = this.providersData?.filter((p) => p !== provider) || null;

      this.updateBaseProviderPriceControlByType({ type: this.type, baseProviderPriceControl: this.providersData });
    },
    onItemAdd(provider: string) {
      this.providersData = [...this.providersData, provider];
      this.unselectedProviders = this.unselectedProviders.filter((p) => p !== provider);
      this.selectedProvider = provider;

      this.updateBaseProviderPriceControlByType({ type: this.type, baseProviderPriceControl: this.providersData });
    },
    onDeleteAll() {
      if (this.providersData) {
        this.unselectedProviders = [...this.unselectedProviders, ...this.providersData];
      }

      this.providersData = [];

      this.updateBaseProviderPriceControlByType({ type: this.type, baseProviderPriceControl: this.providersData });
    },
  },

  render() {
    if (this.providers === undefined) {
      return <div />;
    }

    return (
      <VCol cols='6'>
        <VRow class='flex-column'>
          <VCol class='text-subtitle-1 pb-0'>{this.name}</VCol>
          <VCol>
            <VBtn small onClick={() => this.onDeleteAll()}>
              Delete all
              <VIcon right>remove_circle</VIcon>
            </VBtn>
          </VCol>
          <VCol class='pt-0'>
            <VDataTable
              dense
              items={this.providersData.map((provider) => ({ provider }))}
              headers={[
                { value: 'provider' },
                { value: 'up', width: '40px' },
                { value: 'down', width: '40px' },
                { value: 'delete', width: '40px' }]}
              itemClass={({ provider }: { provider: string }) => {
                return provider === this.selectedProvider && 'primary lighten-1';
              }}
              hideDefaultFooter
              disableSort
              on={{
                'click:row': ({ provider }: { provider: string }) => {
                  this.onProviderClick(provider);
                },
              }}
              scopedSlots={{
                'item.provider': ({ item }: { item: { provider: string }, index: number }) => {
                  return (
                    <VChip label small>{item.provider}</VChip>
                  );
                },
                'item.up': ({ item, index }: { item: { provider: string }, index: number }) => {
                  if (index === 0) {
                    return null;
                  }

                  return (
                    <VBtn icon dense small onClick={(event: Event) => this.onItemUp(event, item.provider, index)}>
                      <VIcon>arrow_upward</VIcon>
                    </VBtn>
                  );
                },
                'item.down': ({ item, index }: { item: { provider: string }, index: number }) => {
                  if (index >= (this.providersData?.length || 0) - 1) {
                    return null;
                  }

                  return (
                    <VBtn icon dense small onClick={(event: Event) => this.onItemDown(event, item.provider, index)}>
                      <VIcon>arrow_downward</VIcon>
                    </VBtn>
                  );
                },
                'item.delete': ({ item }: { item: { provider: string }, index: number }) => {
                  return (
                    <VBtn icon dense small onClick={(event: Event) => this.onItemDelete(event, item.provider)}>
                      <VIcon>remove_circle</VIcon>
                    </VBtn>
                  );
                },
              }} />
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VChipGroup column>
              {this.unselectedProviders.map((provider) => {
                return (
                  <VChip key={provider} class='ma-2' label small close close-icon='add' on={{
                    'click:close': () => this.onItemAdd(provider),
                  }}>{provider}</VChip>
                );
              })}
            </VChipGroup>
          </VCol>
        </VRow>
      </VCol>
    );
  },
});


export default (Vue as VueConstructor<Vue & InstanceType<typeof errorHandlerMixin>>).extend({
  mixins: [errorHandlerMixin],
  props: {
    eventId: Number,
  },

  data() {
    return {
      dialog: true,
    };
  },

  methods: {
    ...mapGetters({
      getBaseProviderPriceControl: 'sports/providerPriceControl/getBaseProviderPriceControl',
      getLoading: 'sports/providerPriceControl/getLoading',
      getReset: 'sports/providerPriceControl/getBaseProviderPriceControlReset',
    }) as {
      getBaseProviderPriceControl: () => BaseProviderPriceControl,
      getLoading: () => boolean;
      getReset: () => boolean;
    },

    ...mapActions({
      getProviderPriceControl: 'sports/providerPriceControl/getProviderPriceControl',
      updateProviderPriceControl: 'sports/providerPriceControl/updateProviderPriceControl',
      updateBaseProviderPriceControlReset: 'sports/providerPriceControl/updateBaseProviderPriceControlReset',
    }),

    handleError(e: any) {
      if (e.graphQLErrors.length > 0 && (e as any).graphQLErrors[0].extensions?.response?.body?.errors) {
        this.showFailureMessage(e.graphQLErrors[0].extensions.response.body.errors);
      } else {
        this.showFailureMessage(e.message);
        throw e;
      }
    },

    onCancel() {
      this.$emit('cancelled');
    },

    async onSave() {
      try {
        await this.updateProviderPriceControl();
        this.$emit('saved');
      } catch (e) {
        this.handleError(e);
      }
    },

    onReset() {
      const reset = this.getReset();
      this.updateBaseProviderPriceControlReset(!reset);
    },

    views() {
      if (!this.getBaseProviderPriceControl()) {
        return [];
      }


      return [
        {
          key: 'prematch',
          name: 'Prematch',
          content: <BaseProviderPriceControlTab
            name='Prematch'
            type={ProviderPriceControlType.PREMATCH}
            providers={this.getBaseProviderPriceControl()?.prematch} />,
        },
        {
          key: 'live',
          name: 'Live',
          content: <BaseProviderPriceControlTab
            name='Live'
            type={ProviderPriceControlType.LIVE}
            providers={this.getBaseProviderPriceControl()?.live} />,
        },
      ];
    },
  },

  mounted() {
    this.getProviderPriceControl(this.eventId);
  },

  render() {
    return (
      <VDialog vModel={this.dialog} onInput={this.onCancel} max-width='1400'>
        {this.failureSnackbar}
        <VCard elevation={3}>
          <VCardTitle>
            <VRow>
              <VCol >
                Provider Offering Control
              </VCol>
              <VCol cols='1' class='d-flex justify-end'>
                <VBtn elevation='0' icon onClick={this.onCancel} disabled={this.getLoading()}>
                  <VIcon small>
                    close
                  </VIcon>
                </VBtn>
              </VCol>
            </VRow>
          </VCardTitle>
          <VCardText>
            {this.getLoading() ?
              (<div>Loading...</div>) :
              (<VRow
                class='d-flex flex-row'>
                {
                  this.views().map((tab) => (tab.content))
                }
              </VRow>)
            }
          </VCardText>
          <VCardActions>
            <VBtn
              color='success'
              disabled={this.getLoading()}
              onClick={this.onSave}
              loading={this.getLoading()}>
              Save
            </VBtn>
            <VBtn color={this.getReset() ? 'red' : 'default'} onClick={() => this.onReset()}>
              Reset
              <VIcon right>
                highlight_off
              </VIcon>
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog >
    );
  },
});
