// import './EditDialog.less';
import Vue from 'vue';
import {
  VBtn,
  VDialog,
  VCard,
  VCardTitle,
  VCardText,
  VCardActions,
  VSpacer,
  VContainer,
  VDivider,
  VTextField,
  VSelect,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';

interface SubscriptionRequest {
  entityType: string;
  entityKey: string;
  source: string;
  twitterQuery: string;
}

export default Vue.extend({
  name: 'socialFeedEditDialog',
  props: {
    mode: String,
    open: Boolean,
    entityType: String,
    entityKey: String,
    closeDialog: Function,
    afterSave: Function,
  },
  data() {
    return {
      source: 'twitter' as string,
      twitterQuery: '' as string,
      loading: false,
    };
  },
  methods: {
    async addSubscription() {
      this.loading = true;
      const input: SubscriptionRequest = {
        entityType: this.entityType,
        entityKey: this.entityKey,
        source: this.source,
        twitterQuery: this.twitterQuery,
      };
      try {
        const res = await this.$apollo
          .mutate({
            mutation: gql`mutation ($input: SocialSubscriptionInput!) {
              addSocialSubscription(input: $input) {
                success
              }
            }`,
            variables: {
              input,
            },
          });
        this.loading = false;
        if (res.data.addSocialSubscription.success) {
          this.afterSave();
        } else {
          // TODO: show some error message by snackbar or similar
          this.closeDialog();
        }
      } catch (e) {
        this.loading = false;
        this.closeDialog();
      }
    },
  },
  computed: {
    sourceSelection() {
      return <VSelect
        vModel={this.source}
        items={[
          {
            text: 'Twitter',
            value: 'twitter',
          },
        ]}
        label='Source'
      />;
    },
  },
  render() {
    return (
      <VDialog vModel={this.open} persistent max-width='600px'>
        <VCard>
          <VCardTitle>
            <span class='headline'>Social Subscription</span>
          </VCardTitle>
          <VDivider />
          <VCardText>
            <VContainer>
              {this.sourceSelection}
              <VTextField vModel={this.twitterQuery} placeholder='Twitter Query (e.g from:username, #tag)' />
              <a class='text-decoration-none' target='_blank' href={`https://twitter.com/search?q=${encodeURIComponent(this.twitterQuery)}`}>
                Preview Tweets
              </a>
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer />
            <VBtn disabled={this.twitterQuery === ''} onClick={this.addSubscription} loading={this.loading}>Save</VBtn>
            <VBtn disabled={this.loading} text onClick={this.closeDialog}>Close</VBtn>
          </VCardActions>
        </VCard>
      </VDialog >
    );
  },
});
