import Vue from 'vue';
import { gql } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCard,
  VCardText,
  VCardTitle,
  VCol,
  VContainer,
  VDataTable,
  VDatePicker,
  VDivider,
  VIcon,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VMenu,
  VProgressLinear,
  VRow,
  VSelect,
  VSimpleTable,
  VTextField,
  VTooltip,
} from 'vuetify/lib';
import moment from 'moment';
import AddOrRemoveLoyaltyPointsDialog from './AddOrRemoveLoyaltyPointsDialog';
import { CsvDataService } from '@/csv-data-service';
import {
  CasinoLoyaltyTransaction,
  GetCasinoLoyaltyProgressInput,
  GetCasinoLoyaltyProgressOutput,
  GetCasinoLoyaltyTiersOutput,
  GetCasinoLoyaltyTransactionsInput,
  GetCasinoLoyaltyTransactionsOutput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { formatCurrency } from '@/filters';

const PAGE_SIZE_OPTIONS = [10, 50, 100, 500, 1000, 5000];

interface VueData {
  loyaltyTiers: GetCasinoLoyaltyTiersOutput;
  loyaltyProgress: GetCasinoLoyaltyProgressOutput;
  loyaltyTransactions: GetCasinoLoyaltyTransactionsOutput;
  addOrRemoveLoyaltyPointsDialogOpen: boolean;
  hideExportTransactions: boolean;
  page: number;
  perPage: number;
  sortBy: string;
  sortDesc: boolean;
  dateFilterMenuOpen: boolean;
  dateFilterRange: string[];
  dateFilterRangeText: string;
  selectedSourceTypes: string[];
  selectedLoyaltyPointTypes: string[];
  apiError: ApiError | null;
}

const PlayerCasinoLoyalty = Vue.extend({
  props: {
    playerUUID: String,
  },
  data(): VueData {
    return {
      loyaltyTiers: {
        loyaltyTiers: [],
      },
      loyaltyProgress: {
        currentTier: {
          id: 0,
          levels: 0,
          pointsPerLevel: 0,
          createdAt: '',
          updatedAt: '',
        },
        playerTierProgress: {
          tierID: 0,
          level: 0,
          levelBalance: '',
          tierBalance: '',
          levelProgressPercent: '',
          tierProgressPercent: '',
          totalLevelPoints: '',
          totalSpendingPoints: '',
        },
      },
      loyaltyTransactions: {
        records: [],
        pagination: {
          page: '1',
          perPage: PAGE_SIZE_OPTIONS[0].toString(),
          sortBy: '',
          sortDesc: true,
          totalRecords: '0',
        },
      },
      addOrRemoveLoyaltyPointsDialogOpen: false,
      hideExportTransactions: true,
      page: 1,
      perPage: 10,
      sortBy: '',
      sortDesc: true,
      dateFilterMenuOpen: false,
      dateFilterRange: ['', ''],
      dateFilterRangeText: '',
      selectedSourceTypes: [],
      selectedLoyaltyPointTypes: [],
      apiError: null,
    };
  },
  created() {
    this.loadLoyaltyTiers();
    this.loadLoyaltyProgress();
    this.setDefaultDateRange();
    this.loadLoyaltyTransactions();
  },
  methods: {
    setDefaultDateRange() {
      const startDate = moment().utc().subtract(30, 'd').format('YYYY-MM-DD'); // 30 days ago
      const endDate = moment.utc().format('YYYY-MM-DD'); // today
      this.dateFilterRange[0] = startDate;
      this.dateFilterRange[1] = endDate;
    },
    async loadLoyaltyTiers() {
      const response = await this.$apollo.query({
        query: gql`
          query {
            getCasinoLoyaltyTiers {
              loyaltyTiers {
                id
                levels
                pointsPerLevel
              }
            }
          }
        `,
        fetchPolicy: 'no-cache',
      });

      const output: GetCasinoLoyaltyTiersOutput = response.data.getCasinoLoyaltyTiers;
      this.loyaltyTiers = output;
    },
    async loadLoyaltyProgress() {
      const input: GetCasinoLoyaltyProgressInput = {
        playerUUID: this.playerUUID,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetCasinoLoyaltyProgressInput!) {
            getCasinoLoyaltyProgress(input: $input) {
              currentTier {
                id
                levels
                pointsPerLevel
                createdAt
                updatedAt
              }
              playerTierProgress {
                tierID
                level
                levelBalance
                tierBalance
                levelProgressPercent
                tierProgressPercent
                totalLevelPoints
                totalSpendingPoints
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetCasinoLoyaltyProgressOutput = response.data.getCasinoLoyaltyProgress;
      this.loyaltyProgress = output;
    },
    async loadLoyaltyTransactions() {
      const input: GetCasinoLoyaltyTransactionsInput = {
        playerUUID: this.playerUUID,
        page: this.page.toString(),
        perPage: this.perPage.toString(),
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
        fromDate: this.dateFilterRange[0],
        toDate: this.dateFilterRange[1],
        selectedSourceTypes: this.selectedSourceTypes,
        selectedLoyaltyPointTypes: this.selectedLoyaltyPointTypes,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetCasinoLoyaltyTransactionsInput!) {
            getCasinoLoyaltyTransactions(input: $input) {
              availableFilters {
                casinoLoyaltyPointTypes
                casinoLoyaltySourceTypes
              }
              records {
                id
                casinoLoyaltyPointType
                amount
                balance
                parentId
                sourceType
                sourceId
                description
                idempotencyKey
                createdAt
              }
              pagination {
                page
                perPage
                sortBy
                sortDesc
                totalRecords
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetCasinoLoyaltyTransactionsOutput = response.data.getCasinoLoyaltyTransactions;

      this.loyaltyTransactions = output;
      if (parseInt((this.loyaltyTransactions?.pagination?.totalRecords || '0'), 10) > 0) {
        this.hideExportTransactions = false;
        if (this.loyaltyTransactions?.pagination?.sortBy !== '') {
          this.sortBy = this.loyaltyTransactions?.pagination?.sortBy || '';
        }
        this.dateFilterRangeText = `${this.dateFilterRange[0]} ~ ${this.dateFilterRange[1]}`;
      }
    },
    handlePageUpdate(options: any): any {
      if (this.page === options.page && this.perPage === options.itemsPerPage) {
        return;
      }
      this.page = options.page;
      this.perPage = options.itemsPerPage;
      this.loadLoyaltyTransactions();
    },
    onSortOrderChangeUpdate(sortBy: string, sortDesc: boolean) {
      this.sortBy = sortBy;
      this.sortDesc = sortDesc;
      this.page = 1;
      this.loadLoyaltyTransactions();
    },
    onDateFilterUpdate() {
      this.dateFilterMenuOpen = false;
      if (this.dateFilterRange[0] > this.dateFilterRange[1]) {
        [this.dateFilterRange[0], this.dateFilterRange[1]] =
          [this.dateFilterRange[1], this.dateFilterRange[0]];
      }

      this.page = 1;
      this.loadLoyaltyTransactions();
    },
    typeFilterIcon(filterName: string) {
      let selectedTypesLength = 0;
      let availableTypesLength = 0;
      if (filterName === 'casinoLoyaltySourceTypes') {
        selectedTypesLength = this.selectedSourceTypes.length;
        availableTypesLength = (this.loyaltyTransactions.availableFilters?.casinoLoyaltySourceTypes as string[]).length;
      } else if (filterName === 'casinoLoyaltyPointTypes') {
        selectedTypesLength = this.selectedLoyaltyPointTypes.length;
        availableTypesLength = (this.loyaltyTransactions.availableFilters?.casinoLoyaltyPointTypes as string[]).length;
      }

      if (selectedTypesLength === availableTypesLength) {
        return 'check_box';
      } else {
        return 'check_box_outline_blank';
      }
    },
    toggleSourceFilterSelectAllOption() {
      const sourceTypes = this.loyaltyTransactions?.availableFilters?.casinoLoyaltySourceTypes as string[];
      if (this.selectedSourceTypes.length === sourceTypes.length) {
        this.selectedSourceTypes = [];
      } else {
        this.selectedSourceTypes = sourceTypes.slice();
      }
    },
    togglePointFilterSelectAllOption() {
      const loyaltyPointTypes = this.loyaltyTransactions?.availableFilters?.casinoLoyaltyPointTypes as string[];
      if (this.selectedLoyaltyPointTypes.length === loyaltyPointTypes.length) {
        this.selectedLoyaltyPointTypes = [];
      } else {
        this.selectedLoyaltyPointTypes = loyaltyPointTypes.slice();
      }
    },
    onClickTypeFilter(filterName: string) {
      this.page = 1;
      this.loadLoyaltyTransactions();
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Loyalty Point Type',
          value: 'casinoLoyaltyPointType',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Balance',
          value: 'balance',
        },
        {
          text: 'Parent ID',
          value: 'parentId',
        },
        {
          text: 'Source Type',
          value: 'sourceType',
        },
        {
          text: 'Source ID',
          value: 'sourceId',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Idempotency Key',
          value: 'idempotencyKey',
        },
        {
          text: 'Created At',
          value: 'createdAt',
        },
      ];
    },
    scopedSlots() {
      return {
        'item.amount': ({ item }: { item: CasinoLoyaltyTransaction }) => {
          return formatCurrency(item.amount, 2);
        },
        'item.balance': ({ item }: { item: CasinoLoyaltyTransaction }) => {
          if (item.casinoLoyaltyPointType === 'spending') {
            return '';
          }
          return formatCurrency(item.balance, 2);
        },
        'item.createdAt': ({ item }: { item: CasinoLoyaltyTransaction }) => {
          return moment.unix(parseInt(item.createdAt, 10)).utc().format('DD-MM-YYYY HH:mm:ss') + ' UTC';
        },
      };
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    const playerLoyaltyProgressDisplay = () => {
      let totalPoints = 0;
      return (
        <VCard>
          <VCardTitle>Loyalty Progress</VCardTitle>
          <VCardText>
            <VRow>
              <VCol cols='4'>
                <h3>Current tier level</h3>
                <p>
                  Tier {this.loyaltyProgress.currentTier.id}:{' '}
                  Level {this.loyaltyProgress.playerTierProgress.level} / {this.loyaltyProgress.currentTier.levels}
                </p>
                <VSimpleTable dense>
                  <template slot='default'>
                    <thead>
                      <tr>
                        <th>Tier</th>
                        <th>Levels</th>
                        <th>Points Per Level</th>
                        <th>Points In Tier</th>
                        <th>Total Points</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                      this.loyaltyTiers?.loyaltyTiers?.map((loyaltyTier) => {
                        if (loyaltyTier != null) {
                          let tableRowStyles = {};
                          if (loyaltyTier.id === this.loyaltyProgress.currentTier.id) {
                            tableRowStyles = {
                              backgroundColor: '#FFCA28',
                            };
                          }
                          totalPoints += loyaltyTier.levels * loyaltyTier.pointsPerLevel;

                          return (
                            <tr style={tableRowStyles}>
                              <td>{loyaltyTier.id}</td>
                              <td>{loyaltyTier.levels}</td>
                              <td>{loyaltyTier.pointsPerLevel}</td>
                              <td>{loyaltyTier.levels * loyaltyTier.pointsPerLevel}</td>
                              <td>{totalPoints}</td>
                            </tr>
                          );
                        }
                      })}
                    </tbody>
                  </template>
                </VSimpleTable>
              </VCol>
              <VCol col='5'>
                <div>
                  <VTooltip
                    top
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => (
                        <h3 on={on}>Tier progress</h3>
                      ),
                    }}
                  >
                    <span>Progress percentage until completion of current tier</span>
                  </VTooltip>
                  <VProgressLinear
                    color='amber'
                    height='25'
                    value={this.loyaltyProgress.playerTierProgress.tierProgressPercent}
                  >
                    <template slot='default'>
                      <strong>{this.loyaltyProgress.playerTierProgress.tierProgressPercent}%</strong>
                    </template>
                  </VProgressLinear>
                  </div>
                <div class='mt-4'>
                  <VTooltip
                    top
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => (
                        <h3 on={on}>Level progress</h3>
                      ),
                    }}
                  >
                    <span>Progress percentage until completion of current level in current tier</span>
                  </VTooltip>
                  <VProgressLinear
                    color='light-blue'
                    height='25'
                    value={this.loyaltyProgress.playerTierProgress.levelProgressPercent}
                  >
                    <template slot='default'>
                      <strong>{this.loyaltyProgress.playerTierProgress.levelProgressPercent}%</strong>
                    </template>
                  </VProgressLinear>
                </div>
              </VCol>
              <VCol cols='3'>
                <VTooltip
                    top
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => (
                        <h3 on={on}>Tier balance</h3>
                      ),
                    }}
                  >
                    <span>Tier progress equivalent in level points</span>
                  </VTooltip>
                <p>{formatCurrency(this.loyaltyProgress.playerTierProgress.tierBalance, 2)}</p>
                <VTooltip
                    top
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => (
                        <h3 on={on}>Level balance</h3>
                      ),
                    }}
                  >
                    <span>Level progress equivalent in level points</span>
                  </VTooltip>
                <p>{formatCurrency(this.loyaltyProgress.playerTierProgress.levelBalance, 2)}</p>
                <VDivider class='my-8' />
                <h3>Total level points</h3>
                <p>{formatCurrency(this.loyaltyProgress.playerTierProgress.totalLevelPoints, 2)}</p>
                <h3>Total spending points</h3>
                <p>{formatCurrency(this.loyaltyProgress.playerTierProgress.totalSpendingPoints, 2)}</p>
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      );
    };

    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            {apiErrorDisplay()}
            <h1>Casino Loyalty Transactions</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            {playerLoyaltyProgressDisplay()}
          </VCol>
        </VRow>
        <VRow>
          <VCol align='begin'>
            <VBtn
              disabled={this.hideExportTransactions}
              color='primary'
              onClick={() => {
                const loyaltyTransactions: CasinoLoyaltyTransaction[] = [];
                this.loyaltyTransactions?.records?.map((txn) => {
                  if (txn != null) {
                    const formattedCreatedAt = {
                      createdAt: moment.unix(parseInt(txn.createdAt, 10)).utc().format('DD-MM-YYYY HH:mm:ss') + ' UTC',
                    };
                    delete txn.__typename;
                    const loyaltyTxn = Object.assign({}, txn, formattedCreatedAt);
                    loyaltyTransactions.push(loyaltyTxn);
                  }
                });
                CsvDataService.exportToCsv('loyalty_transactions_' + this.playerUUID + '_' + new Date().toISOString() + '.csv', loyaltyTransactions);
              }}
            >
              EXPORT TRANSACTIONS TO CSV
            </VBtn>
          </VCol>
          <VCol align='end'>
            <VBtn
              color='primary'
              onClick={() => { this.addOrRemoveLoyaltyPointsDialogOpen = true; }}
            >
              Add / Remove Loyalty Points
            </VBtn>
          </VCol>
        </VRow>
        <VRow>
          <VCol align='begin' style={{ maxWidth: '500px' }}>
            <VSelect
              v-model={this.selectedLoyaltyPointTypes}
              label='Loyalty Point Type Filter'
              multiple
              items={this.loyaltyTransactions?.availableFilters?.casinoLoyaltyPointTypes}
              chips
              on={{
                blur: () => { this.onClickTypeFilter('casinoLoyaltyPointTypes'); },
              }}
            >
              <template slot='prepend-item'>
                <VListItem
                  ripple
                  onMousedown={ (e: MouseEvent) => e.preventDefault() }
                  onChange={this.togglePointFilterSelectAllOption}
                >
                  <VListItemAction>
                    <VIcon color='primary'>
                      {this.typeFilterIcon('casinoLoyaltyPointTypes')}
                    </VIcon>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle> Select All </VListItemTitle>
                  </VListItemContent>
                </VListItem>
                <VDivider class='mt-2'></VDivider>
              </template>
            </VSelect>
          </VCol>
          <VCol align='center' style={{ maxWidth: '500px' }}>
            <VSelect
              v-model={this.selectedSourceTypes}
              label='Source Type Filter'
              multiple
              items={this.loyaltyTransactions?.availableFilters?.casinoLoyaltySourceTypes}
              chips
              on={{
                blur: () => { this.onClickTypeFilter('casinoLoyaltySourceTypes'); },
              }}
            >
              <template slot='prepend-item'>
                <VListItem
                  ripple
                  onMousedown={ (e: MouseEvent) => e.preventDefault() }
                  onChange={this.toggleSourceFilterSelectAllOption}
                >
                  <VListItemAction>
                    <VIcon color='primary'>
                      {this.typeFilterIcon('casinoLoyaltySourceTypes')}
                    </VIcon>
                  </VListItemAction>
                  <VListItemContent>
                    <VListItemTitle> Select All </VListItemTitle>
                  </VListItemContent>
                </VListItem>
                <VDivider class='mt-2'></VDivider>
              </template>
            </VSelect>
          </VCol>
          <VCol align='end' style={{ maxWidth: '500px' }}>
            <VMenu
              v-model={this.dateFilterMenuOpen}
              transition='scale-transition'
              minWidth='auto'
              closeOnContentClick={false}
              scopedSlots={{
                activator: ({ on, attrs }: any) => {
                  return (
                    <VTextField
                      v-model={this.dateFilterRangeText}
                      label='Transactions Date Range'
                      readonly
                      bind={attrs}
                      on={on}
                      outlined
                    />
                  );
                },
              }}
            >
              <VDatePicker
                v-model={this.dateFilterRange}
                range
                nudgeRight={150}
                onInput={() => {
                  if (this.dateFilterRange.length === 2) {
                    this.onDateFilterUpdate();
                  }
                }}
              />
            </VMenu>
          </VCol>
        </VRow>
        <VRow>
          <VCol>
            <VDataTable
                headers={this.headers}
                items={this.loyaltyTransactions.records}
                scopedSlots={this.scopedSlots}
                footerProps={{
                  'items-per-page-options': PAGE_SIZE_OPTIONS,
                }}
                sortBy={this.sortBy}
                sortDesc={this.sortDesc}
                loadingText='Loading... Please wait'
                loading={false}
                page={this.page}
                itemsPerPage={this.perPage}
                serverItemsLength={parseInt((this.loyaltyTransactions?.pagination?.totalRecords || '0'), 10)}
                onPagination={this.handlePageUpdate}
                on={{
                  'update:sort-desc': (sortDesc: boolean) => {
                    if (sortDesc !== undefined && sortDesc !== this.sortDesc) {
                      this.onSortOrderChangeUpdate(this.sortBy || '', sortDesc);
                    }
                  },
                  'update:sort-by': (sortBy: string) => {
                    if (sortBy !== undefined && sortBy !== this.sortBy) {
                      this.onSortOrderChangeUpdate(sortBy, false);
                    } else {
                      this.onSortOrderChangeUpdate('', true);
                    }
                  },
                }}
              />
          </VCol>
        </VRow>
        <AddOrRemoveLoyaltyPointsDialog
          playerUUID={this.playerUUID}
          open={this.addOrRemoveLoyaltyPointsDialogOpen}
          on={{
            open: (open: boolean) => { this.addOrRemoveLoyaltyPointsDialogOpen = open; },
            submit: (apiError: ApiError | null) => {
              this.apiError = apiError;
              this.loadLoyaltyProgress();
              this.loadLoyaltyTransactions();
              this.addOrRemoveLoyaltyPointsDialogOpen = false;
            },
          }}
        />
      </VContainer>
    );
  },
});

export default PlayerCasinoLoyalty;
