import Vue from 'vue';
import { VImg } from 'vuetify/lib';

interface EntityMediaPreviewProps {
  src: string;
}
const widths = [300, 750, 1000];
const height = 98;
const getRootSportsPageImageUrl = (url: string, width: number) =>
  `https://res.cloudinary.com/betengine/image/fetch/w_${width},h_${height},o_90,b_rgb:000000,c_fill,g_auto/${url}`;
export const EntityMediaPreview = Vue.extend<EntityMediaPreviewProps>({
  name: 'EntityMediaPreview',
  functional: true,
  render(_, { props }) {
    if (!props.src) {
      return [];
    }
    const imageUrls = widths.map((width) =>
      getRootSportsPageImageUrl(props.src, width),
    );
    const imageElements = imageUrls.map((url) => (
      <VImg src={url} maxWidth='100%' />
    ));
    return <div>{imageElements}</div>;
  },
});
