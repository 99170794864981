import Vue from 'vue';
import { Submarkets } from '@/types/sports';

import Selections from '../Selections/Selections';

export default Vue.extend({
  props: {
    submarkets: Object as () => Submarkets,
    marketKey: String,
    eventId: String,
  },
  render() {
    return (
      <div>
        {Object.keys(this.submarkets).map((submarket) => {
          return (
            <div key={submarket}>
              <div>{submarket}</div>
              <div class='line-wrapper'>
                <Selections
                  selections={this.submarkets[submarket].selections}
                  marketKey={this.marketKey}
                  submarket={submarket}
                  eventId={this.eventId}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  },
});
