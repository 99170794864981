import Vue from 'vue';
import { VBtn, VCard, VCardActions, VCardText, VCardTitle, VDialog, VTextarea, VTextField, VSpacer } from 'vuetify/lib';

export default Vue.extend({
  props: {
    show: Boolean,
    nickname: String,
    playerUUID: String,
  },

  methods: {
    onApprove() {
      this.$emit('approve', this.expiryDate, this.reason);
    },

    onCancel() {
      this.$emit('cancel');
    },
  },

  data() {
    return {
      expiryDate: '',
      reason: '',
    };
  },

  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    const InputType = mobile ? VTextarea : VTextField;
    return (
      <VDialog vModel={this.show} max-width='500' persistent>
        <VCard>
          <VCardTitle class='headline'>Approve KYC for {this.nickname}</VCardTitle>
          <VCardText>
            <p>Player UUID: {this.playerUUID}</p>
            <p>Enter expiry date in YYYY-MM-DD format:</p>
            <InputType
              vModel={this.expiryDate}
              placeholder='expiry date'
              error={this.expiryDate.trim() === ''}
              errorMessages={this.expiryDate.trim() === '' ? 'Expiry date cannot be empty.' : ''}
              dense
              outlined
            />
          </VCardText>
          <VCardText>
            <InputType
              vModel={this.reason}
              placeholder='reason (optional)'
              dense
              outlined
            />
          </VCardText>
          <VCardActions>
            <VSpacer></VSpacer>
            <VBtn color='green' onClick={this.onApprove}>Approve</VBtn>
            <VBtn color='red' onClick={this.onCancel}>Cancel</VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
