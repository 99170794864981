import Vue from 'vue';
import {
  VBtn,
  VDataTable,
} from 'vuetify/lib';

import { CompetitionWithProviders, Provider } from '@/types/sports';
import { unixToDateTimeUTCString } from '@/utils/date';
import { createNamespacedHelpers } from 'vuex';
import { CompetitionMatchingStatus } from '@/store/modules/sports/competitions';
import { hasRoles } from '@/utils/auth';
const { mapActions } = createNamespacedHelpers('sports/competitions');

export default Vue.extend({
  props: {
    competition: Object as () => CompetitionWithProviders,
    competitionId: Number,
  },

  data() {
    return {
      providers: this.competition.providers.map((value, index) => ({ ...value, id: index + 1 })),
    };
  },
  methods: {
    ...mapActions({
      matchCompetition: 'matchCompetition',
      unmatchCompetition: 'unmatchCompetition',
      loadCurrentCompetition: 'loadCurrentCompetition',
    }),
  },

  render() {
    return (
      <VDataTable
        headers={[
          { value: 'provider', text: 'Provider' },
          { value: 'keys', text: 'Keys' },
          { value: 'providerName', text: 'Name' },
          { value: 'matchedAt', text: 'Matched At' },
          { value: 'status', text: 'Status' },
        ]}
        items={this.providers}
        scopedSlots={{
          'item.keys': ({ item }: { item: Provider }) => {
            return item.keys.join(', ');
          },
          'item.matchedAt': ({ item }: { item: Provider }) => {
            return unixToDateTimeUTCString(item.matchedAt);
          },
          'item.status': ({ item }: { item: Provider }) => {
            const showUnmatchAction = [
              CompetitionMatchingStatus.manualMatched.toString(),
              CompetitionMatchingStatus.autoMatched.toString(),
              CompetitionMatchingStatus.autoCreated.toString(),
            ].includes(item.matchingStatus.toUpperCase());

            return <div class='d-flex flex-column align-start justify-center py-4'>
              {
                hasRoles(['sports:operator']) ?
                  showUnmatchAction ?
                    <VBtn small color='error'
                      onClick={async () => {
                        await this.unmatchCompetition({
                          competitionId: this.competitionId,
                          provider: item.provider,
                          providerKey: item.keys[0],
                        });
                        this.loadCurrentCompetition(this.competitionId);
                      }}>
                      Unmatch
                    </VBtn>
                    :
                    <VBtn small color='primary'
                      onClick={async () => {
                        await this.matchCompetition({
                          competitionId: this.competitionId,
                          provider: item.provider,
                          providerKey: item.keys[0],
                        });
                        this.loadCurrentCompetition(this.competitionId);
                      }}>
                      match
                    </VBtn>
                  :
                  <div></div>
              }
              <div><small class='grey--text'>{item.matchingStatus.toUpperCase()}</small></div>
            </div>;
          },
        }}
      />
    );
  },
});
