import Vue from 'vue';
import gql from 'graphql-tag';
import { VContainer, VAlert, VProgressLinear, VCardText, VCard, VCol, VRow } from 'vuetify/lib';
import { env } from '@/env';
import { getMarketName, Locale, getOutcome, MarketType } from '@cloudbet/market-helper/sports-core';

import { PieChart, ChartData } from '@/components/PieChart';
import PlayerEventLiability from '@/components/sports/PlayerEventLiability';

interface ChartDataWithMeta extends ChartData {
  playersCount: number;
  marketKey: string;
}

interface EventOutcomeProbability {
  outcome: string;
  probability: number;
  marketKey: string;
}

interface Opinion {
  marketKey: string;
  playersCount: number;
  opinion: EventOutcomeProbability[];
}

interface EventCloudbetOpinionsUpdated {
  eventCloudbetOpinionsUpdated: Opinion[];
}

function translateOutrightOutcome(outcomeProbability: EventOutcomeProbability) {
  if (!outcomeProbability.outcome.startsWith('outcome=')) { return; }

  for (const queryVar of outcomeProbability.outcome.split('&')) {
    const pair = queryVar.split('=', 2);
    if (pair[0] !== 'outcome' || !pair[1]) { continue; }

    outcomeProbability.outcome = decodeURIComponent(pair[1]);
    break;
  }
}

export default Vue.extend({
  props: {
    eventId: String,
  },
  data: () => ({
    playersCount: 0,
    chartData: [] as ChartDataWithMeta[],
    subscription: null as any,
    loaded: false,
  }),
  methods: {
    translateMarket(marketKey: string) {
      if (marketKey === 'all') {
        return 'all';
      }

      if (marketKey.endsWith('.outright.v2')) {
        return 'Outright';
      }

      const translation = getMarketName(Locale.en, { params: '' }, marketKey as MarketType);
      if (translation[0] !== '') {
        return translation[0];
      }

      return marketKey;
    },
    clearChartData() {
      this.chartData = [];
    },
    unsubscribe() {
      if (this.subscription) {
        this.subscription.unsubscribe();
      }
    },
    subscribe() {
      this.unsubscribe();
      this.subscription = this.$apollo
        .subscribe({
          query: gql`
            subscription eventCloudbetOpinionsUpdated(
              $eventId: String!
            ) {
              eventCloudbetOpinionsUpdated(
                eventId: $eventId
              ) {
                marketKey
                playersCount
                opinion {
                  outcome
                  probability
                  marketKey
                }
              }
            }
          `,
          variables: {
            eventId: this.eventId,
          },
        })
        .subscribe({
          next: ({ data }: { data: EventCloudbetOpinionsUpdated }) => {
            const opinions = data.eventCloudbetOpinionsUpdated;

            this.clearChartData();

            for (const opinion of opinions) {
              opinion.opinion = opinion.opinion.sort((a, b) => b.probability - a.probability);

              const chartData: ChartDataWithMeta = {
                labels: [],
                datasets: [{
                  data: [],
                }],
                playersCount: opinion.playersCount,
                marketKey: opinion.marketKey,
              };

              opinion.opinion.forEach((eventOutcomeProbability) => {
                translateOutrightOutcome(eventOutcomeProbability);
                chartData.labels.push(eventOutcomeProbability.outcome);
                chartData.datasets[0].data.push(Math.round(eventOutcomeProbability.probability * 10000) / 100);
              });

              this.chartData.push(chartData);
            }

            this.loaded = true;
          },
          complete: () => {
            setTimeout(this.subscribe, 1000);
          },
          error: (error) => {
            // reconnect on error
            // without the timeout ws can freeze
            setTimeout(this.subscribe, 1000);
          },
        });
    },
  },
  mounted() {
    this.subscribe();
  },
  destroyed() {
    this.unsubscribe();
  },
  watch: {
    eventId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.clearChartData();
        this.subscribe();
      }
    },
  },
  render() {
    return (
      <VContainer class='ma-2'>
        { this.loaded ?
          (
            <VRow class='pa-0'>
              {this.chartData.map((data) => {
                return <VCol lg={4} md={6} sm={10}>
                    <VCard>
                      <VCardText>
                        <b>
                          {this.translateMarket(data.marketKey)}
                        </b> market opinion based on {data.playersCount} players.
                        <PieChart chartData={data} />
                      </VCardText>
                    </VCard>
                  </VCol>;
              })}
            </VRow>
          ) :
            <VProgressLinear
              color='primary'
              indeterminate
            />
        }
      </VContainer>
    );
  },
});
