import Vue from 'vue';
import { VMenu, VCard, VCardText } from 'vuetify/lib';

export default Vue.extend({
  props: {
    activator: String,
  },

  render() {
    return (
      <VMenu activator={this.activator} value={true} offset-y
        close-on-content-click={false} close-on-click={false} open-on-click={false}>
        <VCard>
          <VCardText>
            { this.$slots.default }
          </VCardText>
        </VCard>
      </VMenu>
    );
  },
});
