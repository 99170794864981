import Vue from 'vue';
import { VTextField, VList, VSelect, VListItem, VListItemTitle } from 'vuetify/lib';
import CompetitionTranslationWidget from '@/components/sports/TranslationsWidget/CompetitionTranslationsWidget';

const DefaultItem = { id: 0, name: '' };

export default Vue.extend({
  name: 'translationsWidget',
  data() {
    return {
      sport: DefaultItem,
      category: DefaultItem,
      name: '',
      locale: 'en',
      locales: this.$store.state.sportsConsts.locales,
    };
  },
  computed: {
    sports() {
      return [{ id: 0, name: '' }, ...this.$store.state.sportsConsts.sports];
    },
    categories() {
      return [{ id: 0, name: '' }, ...this.$store.state.sportsConsts.categories];
    },
    sportsSelect() {
      return (
        <VSelect items={this.sports} item-value='id' item-text='name' label='Sport'
          return-object={true} v-model={this.sport} >
        </VSelect>
      );
    },
    categoriesSelect() {
      return (
        <VSelect items={this.categories} item-value='id' item-text='name' label='Category'
          return-object={true} v-model={this.category} class='ml-10'>
        </VSelect>
      );
    },
    localesSelect() {
      return (
        <VSelect items={this.locales} label='Locale'
          return-object={true} v-model={this.locale} class='ml-10'>
        </VSelect>
      );
    },
  },
  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },
  render() {
    return (
      <div>
        <VList>
          <VListItem>
            <VListItemTitle>Competition Translations</VListItemTitle>
            {this.sportsSelect}
            {this.categoriesSelect}
            {this.localesSelect}
            <VTextField class='ml-8' label='Name' onchange={(val: any) => {
              this.name = val;
            }} />
          </VListItem>
        </VList>
        <CompetitionTranslationWidget
          name={this.name || ''}
          sportId={this.sport.id}
          categoryId={this.category.id}
          locale={this.locale}
        />
      </div>
    );
  },
});
