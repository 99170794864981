import { ActionTree, GetterTree, MutationTree } from 'vuex';
import { gql } from 'apollo-boost';
import { PendingEvent, EventScore, PendingMarket } from '@/types/sports';
import { sportScoreTypes } from '@/utils/sports-core-config';

interface ChangeResult {
  id: number;
  success: boolean;
}
interface PendingEventStore {
  eventType: string;
  isTrading: boolean;
  events: PendingEvent[];
  scores: { [key: string]: EventScore[] };
  markets: { [key: string]: PendingMarket[] };
  loadingScore: boolean;
  loadingMarket: boolean;
  submittingScore: boolean;
  submittingResult: boolean;
  changeScoreResult: ChangeResult;
  changeResultResult: ChangeResult;
}

const getters: GetterTree<PendingEventStore, any> = {
  events(state: PendingEventStore): PendingEvent[] {
    return state.events;
  },
  scores(state: PendingEventStore): { [key: string]: EventScore[] } {
    return state.scores;
  },
  markets(state: PendingEventStore): { [key: string]: PendingMarket[] } {
    return state.markets;
  },
  loadingScore(state: PendingEventStore): boolean {
    return state.loadingScore;
  },
  loadingMarket(state: PendingEventStore): boolean {
    return state.loadingMarket;
  },
  submittingScore(state: PendingEventStore): boolean {
    return state.submittingScore;
  },
  submittingResult(state: PendingEventStore): boolean {
    return state.submittingResult;
  },
  changeScoreResult(state: PendingEventStore): ChangeResult {
    return state.changeScoreResult;
  },
  changeResultResult(state: PendingEventStore): ChangeResult {
    return state.changeResultResult;
  },
};

const mutations: MutationTree<PendingEventStore> = {
  UPDATE_EVENT_STATE(state, eventType) {
    state.eventType = eventType;
  },
  UPDATE_IS_TRADING(state, isTrading) {
    state.isTrading = isTrading;
  },
  UPDATE_PENDING_EVENTS(state, events) {
    state.events = events;
  },
  UPDATE_SCORES(state, data) {
    let scores: EventScore[] = [];
    const scoreTypes = sportScoreTypes(data.sportKey);
    if (data.scores) {
      // C means cancellation indicator we made
      scores = data.scores
        .filter((value: EventScore) => (value.effective || value.type === 'C'))
        .map((es: EventScore) => {
          const period = es.type.replace('period=', '');
          const name = scoreTypes.find((s) => s.Period === period)?.Name;
          return { ...es, name };
        });
    }

    // Adding the scores which aren't established yet
    scoreTypes.forEach((scoreType) => {
      const eventScore = scores.find((es) => {
        return es.type === `period=${scoreType.Period}`;
      });

      if (!eventScore) {
        scores.push({
          eventId: data.id,
          sportKey: data.sportKey,
          type: `period=${scoreType.Period}`,
          name: scoreType.Name,
          // The both below values are omitted on purpose
          // home: 0,
          // away: 0,
          effective: true,
          disabled: false,
        } as EventScore);
      }
    });

    state.scores = { ...state.scores, [data.id]: scores };
  },
  UPDATE_MARKETS(state, data) {
    state.markets = { ...state.markets, [data.id]: data.markets };
  },
  UPDATE_LOADING_SCORE(state, loadingScore) {
    state.loadingScore = loadingScore;
  },
  UPDATE_LOADING_MARKET(state, loadingMarket) {
    if (loadingMarket && state.markets !== {}) {
      // if we already have markets, dont have to show the loading anymore
      return;
    }

    state.loadingMarket = loadingMarket;
  },
  UPDATE_SUBMITTING_SCORE(state, submittingScore) {
    state.submittingScore = submittingScore;
  },
  UPDATE_SUBMITTING_RESULT(state, submittingResult) {
    state.submittingResult = submittingResult;
  },
  UPDATE_CHANGE_SCORE_RESULT(state, success) {
    state.changeScoreResult = { ...{ id: state.changeScoreResult.id + 1 }, ...{ success } };
  },
  UPDATE_CHANGE_RESULT_RESULT(state, success) {
    state.changeResultResult = { ...{ id: state.changeResultResult.id + 1 }, ...{ success } };
  },
};

async function getPendingEvent(client: any, eventType: string, isTrading: boolean) {
  return await client.query({
    query: gql`
        query($eventType: String!, $isTrading: Boolean!) {
          pendingEvents(eventType: $eventType, isTrading: $isTrading) {
            bets {
              sportKey
              categoryKey
              competitionName
              name
              eventId
              cutoffTime
              finishTime
              numberOfPendingSelections
              comment
            }
          }
        }
        `,
    variables: {
      eventType,
      isTrading,
    },
  });
}
const actions: ActionTree<PendingEventStore, any> = {
  async submitEventResults(
    { commit, rootState, state },
    { eventId, results },
  ) {
    try {
      const client = rootState.apolloClient();
      commit('UPDATE_SUBMITTING_RESULT', true);
      await client.mutate({
        mutation: gql`
        mutation SubmitEventResults($eventId: String!, $input: EventResultsList) {
          submitEventResults(eventID: $eventId, input: $input) {
            success
          }
        }
        `,
        variables: {
          eventId,
          input: { results },
        },
      });
      const response = await getPendingEvent(rootState.apolloClient(), state.eventType, state.isTrading);
      commit('UPDATE_PENDING_EVENTS', response.data.pendingEvents.bets);
      commit('UPDATE_CHANGE_RESULT_RESULT', true);
    } catch (err) {
      commit('UPDATE_CHANGE_RESULT_RESULT', false);
      throw err;
    }
    commit('UPDATE_SUBMITTING_RESULT', false);
  },
  async updatePendingEventComment(
    { rootState },
    { eventId, comment },
  ) {
    try {
      const client = rootState.apolloClient();
      await client.mutate({
        mutation: gql`
          mutation UpdatePendingEventComment($eventId: String!, $comment: String!) {
            updatePendingEventComment(eventID: $eventId, comment: $comment) {
              success
            }
          }
        `,
        variables: {
          eventId,
          comment,
        },
      });
    } catch (err) {
      throw err;
    }
  },
  async removePendingEvent(
    { rootState },
    { eventId },
  ) {
    try {
      const client = rootState.apolloClient();
      await client.mutate({
        mutation: gql`
        mutation DeletePendingEvent($eventId: String!) {
          deletePendingEvent(eventID: $eventId) {
            success
          }
        }
        `,
        variables: {
          eventId,
        },
      });
    } catch (err) {
      throw err;
    }
  },
  async submitEventScores(
    { commit, rootState },
    { eventId, scores },
  ) {
    try {
      const client = rootState.apolloClient();
      commit('UPDATE_SUBMITTING_SCORE', true);
      await client.mutate({
        mutation: gql`
        mutation SubmitEventScores($eventId: String!, $input: EventScoreSubmissionList) {
          submitEventScores(eventID: $eventId, input: $input) {
            success
          }
        }
        `,
        variables: {
          eventId,
          input: { scores },
        },
      });
      commit('UPDATE_CHANGE_SCORE_RESULT', true);
    } catch (err) {
      commit('UPDATE_CHANGE_SCORE_RESULT', false);
      throw err;
    }
    commit('UPDATE_SUBMITTING_SCORE', false);
  },
  async getPendingEvents(
    { commit, rootState },
    { eventType, isTrading },
  ) {
    try {
      const response = await getPendingEvent(rootState.apolloClient(), eventType, isTrading);
      commit('UPDATE_PENDING_EVENTS', response.data.pendingEvents.bets);
      commit('UPDATE_EVENT_STATE', eventType);
      commit('UPDATE_IS_TRADING', isTrading);
    } catch (err) {
      throw err;
    }
  },
  async getEventScores(
    { commit, rootState },
    { eventId, sportKey },
  ) {
    try {
      const client = rootState.apolloClient();
      commit('UPDATE_LOADING_SCORE', true);
      const response = await client.query({
        query: gql`
        query($eventId: String!) {
          eventScores(eventId: $eventId) {
            scores {
              type
              home
              away
              effective
              disabled
            }
          }
        }
        `,
        variables: {
          eventId,
        },
      });
      commit('UPDATE_SCORES', { id: eventId, sportKey, scores: response.data.eventScores.scores });
    } catch (err) {
      throw err;
    }
    commit('UPDATE_LOADING_SCORE', false);
  },
  async getPendingMarkets(
    { commit, rootState },
    { eventId },
  ) {
    try {
      const client = rootState.apolloClient();
      commit('UPDATE_LOADING_MARKET', true);
      const response = await client.query({
        query: gql`
        query($eventId: String!) {
          pendingMarkets(eventId: $eventId) {
            pendingMarkets {
              marketKey
              totalNumberOfPendingSelections
              pendingLines {
                params
                pendingOutcomes {
                  outcome
                  numberOfPendingSelections
                }
              }
            }
          }
        }
        `,
        variables: {
          eventId,
        },
      });
      commit('UPDATE_MARKETS', { id: eventId, markets: response.data.pendingMarkets.pendingMarkets });
    } catch (err) {
      throw err;
    }
    commit('UPDATE_LOADING_MARKET', false);
  },
};


const PendingEventsStore = {
  namespaced: true,
  state: {
    eventType: 'event',
    isTrading: false,
    events: [],
    scores: {},
    markets: {},
    loadingScore: false,
    loadingMarket: false,
    submittingScore: false,
    submittingResult: false,
    changeScoreResult: { id: 0, success: false },
    changeResultResult: { id: 0, success: false },
  } as PendingEventStore,
  getters,
  actions,
  mutations,
};

export default PendingEventsStore;
