import Vue from 'vue';
import { gql, ApolloError } from 'apollo-boost';
import {
  VAlert,
  VBtn,
  VCol,
  VContainer,
  VDataTable,
  VDatePicker,
  VDivider,
  VIcon,
  VListItem,
  VListItemAction,
  VListItemContent,
  VListItemTitle,
  VMenu,
  VRow,
  VSelect,
  VTextField,
} from 'vuetify/lib';
import AwardCasinoBonusDialog from './AwardCasinoBonusDialog';
import ConfirmationDialog from './ConfirmationDialog';

import {
  CancelCasinoBonusInput,
  CasinoBonus,
  GetPlayerCasinoBonusesInput,
  GetPlayerCasinoBonusesOutput,
} from '@/types/casino/generated/casino.gql';
import { ApiError } from '@/types/casino/apiError';
import { BonusType } from '@/types/casino/bonus';

interface VueData {
  playerCasinoBonuses: GetPlayerCasinoBonusesOutput;
  awardBonusDialogOpen: boolean;
  cancelBonusDialogOpen: boolean;
  cancelBonusID: string;
  cancelBonusLoading: boolean;
  filtersVisible: boolean;
  awardedAtFilterOpen: boolean;
  awardedAtDateRange: string[];
  awardedAtDateRangeText: string;
  expiresAtFilterOpen: boolean;
  expiresAtDateRange: string[];
  expiresAtDateRangeText: string;
  optedInAtFilterOpen: boolean;
  optedInAtDateRange: string[];
  optedInAtDateRangeText: string;
  activatedAtFilterOpen: boolean;
  activatedAtDateRange: string[];
  activatedAtDateRangeText: string;
  cancelledAtFilterOpen: boolean;
  cancelledAtDateRange: string[];
  cancelledAtDateRangeText: string;
  expiredAtFilterOpen: boolean;
  expiredAtDateRange: string[];
  expiredAtDateRangeText: string;
  exhaustedAtFilterOpen: boolean;
  exhaustedAtDateRange: string[];
  exhaustedAtDateRangeText: string;
  selectedBonusTypes: string[];
  selectedStates: string[];
  apiError: ApiError | null;
}

const ITEMS_PER_PAGE = 10;

const PlayerCasinoBonuses = Vue.extend({
  props: {
    playerUUID: String,
  },
  data(): VueData {
    return {
      playerCasinoBonuses: {
        records: [],
        pagination: {
          page: '1',
          totalRecords: '0',
          perPage: `${ITEMS_PER_PAGE}`,
        },
      },
      awardBonusDialogOpen: false,
      cancelBonusDialogOpen: false,
      cancelBonusID: '',
      cancelBonusLoading: false,
      filtersVisible: false,
      awardedAtFilterOpen: false,
      awardedAtDateRange: ['', ''],
      awardedAtDateRangeText: '',
      expiresAtFilterOpen: false,
      expiresAtDateRange: ['', ''],
      expiresAtDateRangeText: '',
      optedInAtFilterOpen: false,
      optedInAtDateRange: ['', ''],
      optedInAtDateRangeText: '',
      activatedAtFilterOpen: false,
      activatedAtDateRange: ['', ''],
      activatedAtDateRangeText: '',
      cancelledAtFilterOpen: false,
      cancelledAtDateRange: ['', ''],
      cancelledAtDateRangeText: '',
      expiredAtFilterOpen: false,
      expiredAtDateRange: ['', ''],
      expiredAtDateRangeText: '',
      exhaustedAtFilterOpen: false,
      exhaustedAtDateRange: ['', ''],
      exhaustedAtDateRangeText: '',
      selectedBonusTypes: [],
      selectedStates: [],
      apiError: null,
    };
  },
  created() {
    this.loadPlayerCasinoBonuses('1');
  },
  methods: {
    async loadPlayerCasinoBonuses(page: string) {
      const input: GetPlayerCasinoBonusesInput = {
        playerUUID: this.playerUUID,
        page,
        selectedBonusTypes: this.selectedBonusTypes,
        selectedStates: this.selectedStates,
        awardedAtDateRange: this.awardedAtDateRange,
        expiresAtDateRange: this.expiresAtDateRange,
        optedInAtDateRange: this.optedInAtDateRange,
        activatedAtDateRange: this.activatedAtDateRange,
        cancelledAtDateRange: this.cancelledAtDateRange,
        expiredAtDateRange: this.expiredAtDateRange,
        exhaustedAtDateRange: this.exhaustedAtDateRange,
      };

      const response = await this.$apollo.query({
        query: gql`
          query ($input: GetPlayerCasinoBonusesInput!) {
            getPlayerCasinoBonuses(input: $input) {
              availableFilters {
                casinoBonusStates
              }
              records {
                id
                bonusType
                lockDeposit
                bonusUsageStrategy
                state
                casinoCampaignCode
                playerIdempotencyKey
                currency
                amount
                initialLockedRealAmount
                bonusBucketLevel
                lockedRealBucketLevel
                pendingWinningsBucketLevel
                rolloverDaysDuration
                rolloverRequirement
                createdAt
                awardedByAdmin
                expiresAt
                awardPercentage
                awardMinimum
                awardMaximum
                activatedAt
                cancelledAt
                exhaustedAt
                expiredAt
                optedInAt
                finalBonusBalance
                totalPayout
                cancelledByAdmin
                currentPlaythroughAmount
                remainingPlaythroughAmount
                totalPlaythroughAmount
                playthroughProgressPercentage
                sumStake
              }
              pagination {
                page
                totalRecords
              }
            }
          }
        `,
        variables: {
          input,
        },
        fetchPolicy: 'no-cache',
      });

      const output: GetPlayerCasinoBonusesOutput = response.data.getPlayerCasinoBonuses;
      this.playerCasinoBonuses = output;
      this.setDateRangeText();
    },
    setDateRangeText() {
      if (this.awardedAtDateRange[0] !== '') {
        this.awardedAtDateRangeText = `${this.awardedAtDateRange[0]} ~ ${this.awardedAtDateRange[1]}`;
      }

      if (this.expiresAtDateRange[0] !== '') {
        this.expiresAtDateRangeText = `${this.expiresAtDateRange[0]} ~ ${this.expiresAtDateRange[1]}`;
      }

      if (this.optedInAtDateRange[0] !== '') {
        this.optedInAtDateRangeText = `${this.optedInAtDateRange[0]} ~ ${this.optedInAtDateRange[1]}`;
      }

      if (this.activatedAtDateRange[0] !== '') {
        this.activatedAtDateRangeText = `${this.activatedAtDateRange[0]} ~ ${this.activatedAtDateRange[1]}`;
      }

      if (this.cancelledAtDateRange[0] !== '') {
        this.cancelledAtDateRangeText = `${this.cancelledAtDateRange[0]} ~ ${this.cancelledAtDateRange[1]}`;
      }

      if (this.expiredAtDateRange[0] !== '') {
        this.expiredAtDateRangeText = `${this.expiredAtDateRange[0]} ~ ${this.expiredAtDateRange[1]}`;
      }

      if (this.exhaustedAtDateRange[0] !== '') {
        this.exhaustedAtDateRangeText = `${this.exhaustedAtDateRange[0]} ~ ${this.exhaustedAtDateRange[1]}`;
      }
    },
    onPageUpdate(page: number) {
      this.loadPlayerCasinoBonuses(`${page}`);
    },
    onBonusCancel(bonusID: string) {
      this.cancelBonusID = bonusID;
      this.cancelBonusDialogOpen = true;
    },
    async onBonusCancelConfirm(bonusID: string) {
      try {
        this.cancelBonusLoading = true;

        const input: CancelCasinoBonusInput = {
          bonusID,
        };

        await this.$apollo.mutate({
          mutation: gql`
            mutation ($input: CancelCasinoBonusInput!) {
              cancelCasinoBonus(input: $input) {
                bonusID
              }
            }
          `,
          variables: {
            input,
          },
        });

        this.apiError = null;
      } catch (e) {
        const err = e as ApolloError;

        const errResponse = err.graphQLErrors[0]?.extensions?.response;
        if (errResponse != null) {
          this.apiError = {
            url: errResponse.url,
            status: errResponse.status,
            body: errResponse.body,
          };
        }
      } finally {
        await this.loadPlayerCasinoBonuses('1');
        this.cancelBonusLoading = false;
        this.cancelBonusDialogOpen = false;
      }
    },
    typeFilterIcon(filterName: string) {
      let selectedTypesLength = 0;
      let availableTypesLength = 0;
      if (filterName === 'bonusTypes') {
        selectedTypesLength = this.selectedBonusTypes.length;
        availableTypesLength = Object.keys(BonusType).length;
      } else if (filterName === 'states') {
        selectedTypesLength = this.selectedStates.length;
        availableTypesLength = (this.playerCasinoBonuses.availableFilters?.casinoBonusStates as string[]).length;
      }

      if (selectedTypesLength === availableTypesLength) {
        return 'check_box';
      } else {
        return 'check_box_outline_blank';
      }
    },
    toggleTypeFilterSelectAllOption() {
      if (this.selectedBonusTypes.length === Object.keys(BonusType).length) {
        this.selectedBonusTypes = [];
      } else {
        this.selectedBonusTypes = Object.values(BonusType);
      }
    },
    toggleStateFilterSelectAllOption() {
      const states = this.playerCasinoBonuses?.availableFilters?.casinoBonusStates as string[];
      if (this.selectedStates.length === states.length) {
        this.selectedStates = [];
      } else {
        this.selectedStates = states.slice();
      }
    },
    onDateFilterUpdate(filterName: string) {
      switch (filterName) {
        case 'awardedAt':
          this.awardedAtFilterOpen = false;
          if (this.awardedAtDateRange[0] > this.awardedAtDateRange[1]) {
            [this.awardedAtDateRange[0], this.awardedAtDateRange[1]] =
            [this.awardedAtDateRange[1], this.awardedAtDateRange[0]];
          }
        case 'expiresAt':
          this.expiresAtFilterOpen = false;
          if (this.expiresAtDateRange[0] > this.expiresAtDateRange[1]) {
            [this.expiresAtDateRange[0], this.expiresAtDateRange[1]] =
            [this.expiresAtDateRange[1], this.expiresAtDateRange[0]];
          }
        case 'optedInAt':
          this.optedInAtFilterOpen = false;
          if (this.optedInAtDateRange[0] > this.optedInAtDateRange[1]) {
            [this.optedInAtDateRange[0], this.optedInAtDateRange[1]] =
            [this.optedInAtDateRange[1], this.optedInAtDateRange[0]];
          }
        case 'activatedAt':
          this.activatedAtFilterOpen = false;
          if (this.activatedAtDateRange[0] > this.activatedAtDateRange[1]) {
            [this.activatedAtDateRange[0], this.activatedAtDateRange[1]] =
            [this.activatedAtDateRange[1], this.activatedAtDateRange[0]];
          }
        case 'cancelledAt':
          this.cancelledAtFilterOpen = false;
          if (this.cancelledAtDateRange[0] > this.cancelledAtDateRange[1]) {
            [this.cancelledAtDateRange[0], this.cancelledAtDateRange[1]] =
            [this.cancelledAtDateRange[1], this.cancelledAtDateRange[0]];
          }
        case 'expiredAt':
          this.expiredAtFilterOpen = false;
          if (this.expiredAtDateRange[0] > this.expiredAtDateRange[1]) {
            [this.expiredAtDateRange[0], this.expiredAtDateRange[1]] =
            [this.expiredAtDateRange[1], this.expiredAtDateRange[0]];
          }
        case 'exhaustedAt':
          this.exhaustedAtFilterOpen = false;
          if (this.exhaustedAtDateRange[0] > this.exhaustedAtDateRange[1]) {
            [this.exhaustedAtDateRange[0], this.exhaustedAtDateRange[1]] =
            [this.exhaustedAtDateRange[1], this.exhaustedAtDateRange[0]];
          }
      }
      this.loadPlayerCasinoBonuses('1');
    },
    clearDateFilter(filterName: string) {
      switch (filterName) {
        case 'awardedAt':
          this.awardedAtDateRange = ['', ''];
        case 'expiresAt':
          this.expiresAtDateRange = ['', ''];
        case 'optedInAt':
          this.optedInAtDateRange = ['', ''];
        case 'activatedAt':
          this.activatedAtDateRange = ['', ''];
        case 'cancelledAt':
          this.cancelledAtDateRange = ['', ''];
        case 'expiredAt':
          this.expiredAtDateRange = ['', ''];
        case 'exhaustedAt':
          this.exhaustedAtDateRange = ['', ''];
      }
      this.loadPlayerCasinoBonuses('1');
    },
  },
  computed: {
    headers() {
      return [
        {
          text: 'ID',
          value: 'id',
        },
        {
          text: 'Bonus Type',
          value: 'bonusType',
        },
        {
          text: 'Lock Deposit',
          value: 'lockDeposit',
        },
        {
          text: 'Bonus Usage Strategy',
          value: 'bonusUsageStrategy',
        },
        {
          text: 'Campaign Code',
          value: 'casinoCampaignCode',
        },
        {
          text: 'Player Idempotency Key',
          value: 'playerIdempotencyKey',
        },
        {
          text: 'Currency',
          value: 'currency',
        },
        {
          text: 'Amount',
          value: 'amount',
        },
        {
          text: 'Sum Stake',
          value: 'sumStake',
        },
        {
          text: 'Current Playthrough Amount',
          value: 'currentPlaythroughAmount',
        },
        {
          text: 'Total Playthrough Amount',
          value: 'totalPlaythroughAmount',
        },
        {
          text: 'Remaining Playthrough Amount',
          value: 'remainingPlaythroughAmount',
        },
        {
          text: 'Playthrough Progress (%)',
          value: 'playthroughProgressPercentage',
        },
        {
          text: 'Initial Locked Real Amount',
          value: 'initialLockedRealAmount',
        },
        {
          text: 'Bonus (Bucket Level)',
          value: 'bonusBucketLevel',
        },
        {
          text: 'Locked Real (Bucket Level)',
          value: 'lockedRealBucketLevel',
        },
        {
          text: 'Pending Winnings (Bucket Level)',
          value: 'pendingWinningsBucketLevel',
        },
        {
          text: 'Award Percentage',
          value: 'awardPercentage',
        },
        {
          text: 'Award Minimum',
          value: 'awardMinimum',
        },
        {
          text: 'Award Maximum',
          value: 'awardMaximum',
        },
        {
          text: 'State',
          value: 'state',
        },
        {
          text: 'Rollover Requirement',
          value: 'rolloverRequirement',
        },
        {
          text: 'Awarded At',
          value: 'createdAt',
        },
        {
          text: 'Awarded By Admin',
          value: 'awardedByAdmin',
        },
        {
          text: 'Expires At',
          value: 'expiresAt',
        },
        {
          text: 'Opted In At',
          value: 'optedInAt',
        },
        {
          text: 'Activated At',
          value: 'activatedAt',
        },
        {
          text: 'Cancelled At',
          value: 'cancelledAt',
        },
        {
          text: 'Expired At',
          value: 'expiredAt',
        },
        {
          text: 'Exhausted At',
          value: 'exhaustedAt',
        },
        {
          text: 'Final Bonus Balance',
          value: 'finalBonusBalance',
        },
        {
          text: 'Total Payout',
          value: 'totalPayout',
        },
        {
          text: 'Cancelled By Admin',
          value: 'cancelledByAdmin',
        },
        {
          text: 'Actions',
          value: 'actions',
        },
      ];
    },
    scopedSlots() {
      return {
        'item.bonusType': ({ item }: { item: CasinoBonus }) => {
          switch (item.bonusType) {
            case BonusType.CASINO_DEPOSIT:
              return 'Deposit';
            case BonusType.CASINO_FREEBONUS:
              return 'Free Bonus';
            default:
              return 'Unknown';
          }
        },
        'item.awardPercentage': ({ item }: { item: CasinoBonus }) => {
          if (item.awardPercentage == null) {
            return null;
          }

          return `${item.awardPercentage}%`;
        },
        'item.remainingPlaythroughAmount': ({ item }: { item: CasinoBonus }) => {
          if (item.remainingPlaythroughAmount == null) {
            return 'N/A';
          }

          return item.remainingPlaythroughAmount;
        },
        'item.playthroughProgressPercentage': ({ item }: { item: CasinoBonus }) => {
          if (item.playthroughProgressPercentage == null) {
            return 'N/A';
          }

          return `${item.playthroughProgressPercentage}%`;
        },
        'item.actions': ({ item }: { item: CasinoBonus }) => {
          if (item.state === 'awarded' || item.state === 'available' || item.state === 'active') {
            return (
              <VBtn
                depressed
                color='error'
                onClick={() => { this.onBonusCancel(item.id); }}
              >
                Cancel
              </VBtn>
            );
          }
        },
        'item.createdAt': ({ item }: { item: CasinoBonus }) => {
          return new Date(parseInt(item.createdAt, 10) * 1000).toISOString();
        },
        'item.activatedAt': ({ item }: { item: CasinoBonus }) => {
          if (item.activatedAt == null) {
            return null;
          }

          return new Date(parseInt(item.activatedAt, 10) * 1000).toISOString();
        },
        'item.optedInAt': ({ item }: { item: CasinoBonus }) => {
          if (item.optedInAt == null) {
            return null;
          }

          return new Date(parseInt(item.optedInAt, 10) * 1000).toISOString();
        },
        'item.expiresAt': ({ item }: { item: CasinoBonus }) => {
          return new Date(parseInt(item.expiresAt, 10) * 1000).toISOString();
        },
        'item.expiredAt': ({ item }: { item: CasinoBonus }) => {
          if (item.expiredAt == null) {
            return null;
          }

          return new Date(parseInt(item.expiredAt, 10) * 1000).toISOString();
        },
        'item.cancelledAt': ({ item }: { item: CasinoBonus }) => {
          if (item.cancelledAt == null) {
            return null;
          }

          return new Date(parseInt(item.cancelledAt, 10) * 1000).toISOString();
        },
        'item.exhaustedAt': ({ item }: { item: CasinoBonus }) => {
          if (item.exhaustedAt == null) {
            return null;
          }

          return new Date(parseInt(item.exhaustedAt, 10) * 1000).toISOString();
        },
      };
    },
  },
  render() {
    const apiErrorDisplay = () => {
      if (this.apiError != null) {
        return (
          <VAlert
            dense
            outlined
            type='error'
          >
            API error:
            <pre
              style={{
                'white-space': 'pre-wrap',
              }}
            >
              {JSON.stringify(this.apiError, null, 2)}
            </pre>
          </VAlert>
        );
      }
    };

    const filtersLinkText = this.filtersVisible ? 'Hide filters' : 'Show filters';
    return (
      <VContainer fluid>
        <VRow>
          <VCol>
            {apiErrorDisplay()}
            <h1>Casino Bonuses</h1>
          </VCol>
        </VRow>
        <VRow>
          <VCol class='text-left'>
            <a class='filters-toggle' onClick={() => this.filtersVisible = !this.filtersVisible}>
              {filtersLinkText}
            </a>
          </VCol>
          <VCol align='end'>
            <VBtn
              color='primary'
              onClick={() => { this.awardBonusDialogOpen = true; }}
            >
              Award Bonus
            </VBtn>
          </VCol>
        </VRow>
        {this.filtersVisible &&
          <VCol>
            <VRow>
              <VCol style={{ maxWidth: '450px' }}>
                <VSelect
                  v-model={this.selectedBonusTypes}
                  label='Bonus Type Filter'
                  multiple
                  items={[
                    {
                      text: 'Deposit',
                      value: BonusType.CASINO_DEPOSIT,
                    },
                    {
                      text: 'Free Bonus',
                      value: BonusType.CASINO_FREEBONUS,
                    },
                  ]}
                  chips
                  on={{
                    blur: () => {
                      this.loadPlayerCasinoBonuses('1');
                    },
                  }}
                >
                  <template slot='prepend-item'>
                    <VListItem
                      ripple
                      onMousedown={(e: MouseEvent) => e.preventDefault()}
                      onChange={this.toggleTypeFilterSelectAllOption}
                    >
                      <VListItemAction>
                        <VIcon color='primary'>
                          {this.typeFilterIcon('bonusTypes')}
                        </VIcon>
                      </VListItemAction>
                      <VListItemContent>
                        <VListItemTitle> Select All </VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                    <VDivider class='mt-2'></VDivider>
                  </template>
                </VSelect>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VSelect
                  v-model={this.selectedStates}
                  label='States Filter'
                  multiple
                  items={this.playerCasinoBonuses?.availableFilters?.casinoBonusStates}
                  chips
                  on={{
                    blur: () => {
                      this.loadPlayerCasinoBonuses('1');
                    },
                  }}
                >
                  <template slot='prepend-item'>
                    <VListItem
                      ripple
                      onMousedown={(e: MouseEvent) => e.preventDefault()}
                      onChange={this.toggleStateFilterSelectAllOption}
                    >
                      <VListItemAction>
                        <VIcon color='primary'>
                          {this.typeFilterIcon('states')}
                        </VIcon>
                      </VListItemAction>
                      <VListItemContent>
                        <VListItemTitle> Select All </VListItemTitle>
                      </VListItemContent>
                    </VListItem>
                    <VDivider class='mt-2'></VDivider>
                  </template>
                </VSelect>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.awardedAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.awardedAtDateRangeText}
                          label='Awarded At Date Range'
                          readonly
                          clearable
                          on={on}
                          onInput={() => this.clearDateFilter('awardedAt')}
                          bind={attrs}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.awardedAtDateRange}
                    range
                    onInput={() => {
                      if (this.awardedAtDateRange.length === 2) {
                        this.onDateFilterUpdate('awardedAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
            </VRow>
            <VRow>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.expiresAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.expiresAtDateRangeText}
                          label='Expires At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('expiresAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.expiresAtDateRange}
                    range
                    onInput={() => {
                      if (this.expiresAtDateRange.length === 2) {
                        this.onDateFilterUpdate('expiresAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.optedInAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.optedInAtDateRangeText}
                          label='OptedIn At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('optedInAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.optedInAtDateRange}
                    range
                    onInput={() => {
                      if (this.optedInAtDateRange.length === 2) {
                        this.onDateFilterUpdate('optedInAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.activatedAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.activatedAtDateRangeText}
                          label='Activated At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('activatedAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.activatedAtDateRange}
                    range
                    onInput={() => {
                      if (this.activatedAtDateRange.length === 2) {
                        this.onDateFilterUpdate('activatedAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
            </VRow>
            <VRow>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.cancelledAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.cancelledAtDateRangeText}
                          label='Cancelled At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('cancelledAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.cancelledAtDateRange}
                    range
                    onInput={() => {
                      if (this.cancelledAtDateRange.length === 2) {
                        this.onDateFilterUpdate('cancelledAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.expiredAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.expiredAtDateRangeText}
                          label='Expired At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('expiredAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.expiredAtDateRange}
                    range
                    onInput={() => {
                      if (this.expiredAtDateRange.length === 2) {
                        this.onDateFilterUpdate('expiredAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
              <VCol style={{ maxWidth: '450px' }}>
                <VMenu
                  v-model={this.exhaustedAtFilterOpen}
                  minWidth='auto'
                  closeOnContentClick={false}
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => {
                      return (
                        <VTextField
                          v-model={this.exhaustedAtDateRangeText}
                          label='Exhausted At Date Range'
                          readonly
                          clearable
                          bind={attrs}
                          on={on}
                          onInput={() => this.clearDateFilter('exhaustedAt')}
                          outlined
                        />
                      );
                    },
                  }}
                >
                  <VDatePicker
                    v-model={this.exhaustedAtDateRange}
                    range
                    onInput={() => {
                      if (this.exhaustedAtDateRange.length === 2) {
                        this.onDateFilterUpdate('exhaustedAt');
                      }
                    }}
                  />
                </VMenu>
              </VCol>
            </VRow>
          </VCol>
        }
        <VRow>
          <VCol>
            <VDataTable
                headers={this.headers}
                items={this.playerCasinoBonuses.records}
                scopedSlots={this.scopedSlots}
                footerProps={{
                  'items-per-page-options': [ITEMS_PER_PAGE],
                }}
                disableSort={true}
                loadingText='Loading... Please wait'
                loading={false}
                page={parseInt((this.playerCasinoBonuses?.pagination?.page || '1'), 10)}
                itemsPerPage={ITEMS_PER_PAGE}
                serverItemsLength={parseInt((this.playerCasinoBonuses?.pagination?.totalRecords || '0'), 10)}
                on={{
                  'update:page': (page: number) => this.onPageUpdate(page),
                }}
              />
          </VCol>
        </VRow>
        <AwardCasinoBonusDialog
          playerUUID={this.playerUUID}
          open={this.awardBonusDialogOpen}
          on={{
            open: (open: boolean) => { this.awardBonusDialogOpen = open; },
            submit: (apiError: ApiError | null) => {
              this.apiError = apiError;
              this.loadPlayerCasinoBonuses('1');
              this.awardBonusDialogOpen = false;
            },
          }}
        />
        <ConfirmationDialog
          open={this.cancelBonusDialogOpen}
          title='Cancel bonus confirmation'
          loading={this.cancelBonusLoading}
          on={{
            submit: () => {
              this.onBonusCancelConfirm(this.cancelBonusID);
            },
            cancel: () => {
              this.cancelBonusDialogOpen = false;
            },
          }}
        />
      </VContainer>
    );
  },
});

export default PlayerCasinoBonuses;
