import './utility.less';
import Vue from 'vue';
import {
  VSimpleTable,
  VDataTable,
  VBtn,
  VTextarea,
} from 'vuetify/lib';
import { gql } from 'apollo-boost';
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('sports/events');
import { hasRoles } from '@/utils/auth';
import { iso8601ToDateTimeUTCString } from '@/utils/date';

export default Vue.extend({
  name: 'eventScoreWidget',
  props: {
    eventId: String,
  },
  data() {
    return {
      scores: [] as any,
      disabling: '',
    };
  },
  computed: {
    ...mapState(['currentEvent']),
  },
  methods: {
    action({ item }: any): any {
      if (!hasRoles(['sports:operator'])) {
        return <div></div>;
      }

      return (<VBtn loading={this.disabling === item.type} onClick={async () => {
        this.disabling = item.type;
        const result = await this.$apollo.mutate({
          mutation: gql`mutation DisableEventScore($eventId: String!, $period: String!, $comment: String) {
              disableEventScore(eventID: $eventId, period: $period, comment: $comment) {
                success
              }
            }`,
          variables: {
            eventId: this.eventId,
            comment: item.comment,
            period: item.type.replace('period=', ''),
          },
        });
        this.$apollo.queries.scores.refetch();
        this.disabling = '';
      }} disabled={item.disabled || item.type === 'C'} color='red'>Disable</VBtn>);
    },
    expandedItem({ headers, item }: any) {
      return (
        <td colSpan={headers.length}>
          <VSimpleTable>
            <tbody>
              {item.items.map((child: any) => {
                let className = '';
                if (child.disabled && child.effective) {
                  className = 'bg-red';
                }
                if (!child.effective) {
                  className = 'bg-gray';
                }
                return (
                  <tr class={className}>
                    <td></td>
                    <td>{child.timestamp}</td>
                    <td>{child.type}</td>
                    <td>{child.home}</td>
                    <td>{child.away}</td>
                    <td>{child.adminId || child.provider}</td>
                    <td>{child.comment}</td>
                    <td></td>
                  </tr>
                );
              })}
            </tbody>
          </VSimpleTable>
        </td >
      );
    },
    source({ item }: any): any {
      return (<span>{item.adminId || item.provider}</span>);
    },
    comment({ item }: any): any {
      return (<VTextarea value={item.comment} disabled={item.disabled || !hasRoles(['sports:operator'])} />);
    },
    timestamp({ item }: { item: { timestamp: string } }) {
      return iso8601ToDateTimeUTCString(item.timestamp);
    },
  },
  apollo: {
    scores: {
      query: gql`query ($eventId: String!, $includeHistory: Boolean) {
        scores: eventScores(eventId: $eventId, includeHistory: $includeHistory) {
          scores {
            timestamp
            away
            home
            type
            provider
            adminId
            effective
            disabled
            comment
          }
        }
      }`,
      update(data) {
        const groupByKey = {} as any;
        data.scores.scores.forEach((score: any) => {
          if (!groupByKey[score.type]) {
            score.items = [];
            groupByKey[score.type] = score;
          } else {
            groupByKey[score.type].items.push(score);
          }
        });
        const finalObjects = [];
        for (const key of Object.keys(groupByKey)) {
          groupByKey[key].items.sort((a: any, b: any) => b.timestamp.localeCompare(a.timestamp));
          finalObjects.push(groupByKey[key]);
        }
        return finalObjects;
      },
      variables(): any {
        return { eventId: this.eventId, includeHistory: true };
      },
    },
  },
  render() {
    return (
      <div>
        <VDataTable
          showExpand
          itemKey='type'
          singleExpand={true}
          class='elevation-1'
          itemClass={(item: any) => {
            if (item.disabled && item.effective) {
              return 'bg-red';
            }
            if (!item.effective) {
              return 'bg-gray';
            }
            return '';
          }}
          headers={[
            { text: 'Time', value: 'timestamp' },
            { text: 'Period', value: 'type' },
            { text: 'Home', value: 'home', sortable: false },
            { text: 'Away', value: 'away', sortable: false },
            { text: 'Source', value: 'source', sortable: false },
            { text: 'Comment', value: 'comment', sortable: false },
            { text: 'Action', value: 'action', sortable: false },
          ]}
          scopedSlots={{
            'expanded-item': this.expandedItem,
            'item.source': this.source,
            'item.action': this.action,
            'item.comment': this.comment,
            'item.timestamp': this.timestamp,
          }}
          items={this.scores || []}
          multiSort
          loading-text='Loading... Please wait'
          loading={this.$apollo.queries.scores.loading}
        />
      </div>
    );
  },
});
