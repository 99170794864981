import { unixToDateTimeString } from '@/utils/date';
import { uuid4 } from '@/utils/uuid';
import { gql } from 'apollo-boost';
import { range } from 'lodash';
import Vue from 'vue';
import {
  VAlert, VBtn,
  VCard,
  VCardActions,
  VCardSubtitle,
  VCardText,
  VCardTitle,
  VCol,
  VDataTable,
  VDatePicker,
  VMenu,
  VProgressCircular, VProgressLinear, VRow,
  VSelect,
  VSlideYReverseTransition,
  VSpacer, VSwitch, VTextField,
} from 'vuetify/lib';
import { CompetitionDialog } from './CompetitionDialog';
import { CompetitorDialog } from './CompetitorDialog';

const PROVIDER = 'BGL'; // BGL
const EVENT_KEY_PREFIX = 'bgl:match:';

// 0 => 00, 10 => 10
function fixedWidth(i: number) {
  return `${i < 10 ? '0' : ''}${i}`;
}

function newEventKey(): string {
  return EVENT_KEY_PREFIX + uuid4();
}

interface Selectable {
  id: number;
  key: string;
  name: string;
}

interface CreateCompetitionInput {
  categoryKey: string;
  provider: string;
  providerCompetitionKeys: string[];
  providerCompetitionName: string;
  sportKey: string;
}

interface CreateCompetitorInput {
  abbreviation: string;
  competitionKey: string;
  competitorType: string;
  nationality: string;
  provider: string;
  providerCompetitorKeys: string[];
  providerCompetitorName: string;
  sportKey: string;
}

enum EVENT_TYPES {
  EVENT_TYPE_EVENT = 'EVENT_TYPE_EVENT',
  EVENT_TYPE_OUTRIGHT = 'EVENT_TYPE_OUTRIGHT',
}

export const EventCreatorCard = Vue.extend({
  props: {
    initialSport: {
      type: Object as () => Selectable | undefined,
      default: undefined,
    },
    initialCategory: {
      type: Object as () => Selectable | undefined,
      default: undefined,
    },
    initialCompetition: {
      type: Object as () => any | undefined,
      default: undefined,
    },
    initialHomeCompetitor: {
      type: Object as () => Selectable | undefined,
      default: undefined,
    },
    initialAwayCompetitor: {
      type: Object as () => Selectable | undefined,
      default: undefined,
    },
    initialPlayers: {
      type: Array as () => Selectable[] | undefined,
      default: undefined,
    },
  },

  data() {
    return {
      provider: PROVIDER,

      sport: this.initialSport,
      category: this.initialCategory,

      competitionSearchByName: '',
      competitions: [],
      competitionsVisible: false,
      competition: this.initialCompetition,

      competitorsSearchByName: '',
      competitors: [],
      competitorsVisible: false,
      homeCompetitor: this.initialHomeCompetitor,
      awayCompetitor: this.initialAwayCompetitor,
      players: this.initialPlayers,

      setStartsAt: false,
      setEndsAt: false,
      setFinishesAt: false,

      startsAtDate: new Date().toISOString().substr(0, 10),
      startsAtDateIsOpen: false,
      startsAtHour: fixedWidth(new Date().getUTCHours()),
      startsAtMinute: fixedWidth(new Date().getUTCMinutes()),

      endsAtDate: new Date().toISOString().substr(0, 10),
      endsAtDateIsOpen: false,
      endsAtHour: fixedWidth(new Date().getUTCHours()),
      endsAtMinute: fixedWidth(new Date().getUTCMinutes()),

      finishesAtDate: new Date().toISOString().substr(0, 10),
      finishesAtDateIsOpen: false,
      finishesAtHour: fixedWidth(new Date().getUTCHours()),
      finishesAtMinute: fixedWidth(new Date().getUTCMinutes()),

      eventType: 'EVENT_TYPE_EVENT',
      eventStatus: 'TRADING',
      eventName: '',

      saveLoading: false,
      error: '',

      dialogLoading: false,
      dialogError: '',

      newCompetitionDialogIsOpen: false,
      newCompetitorDialogIsOpen: false,
    } as unknown as {
      provider: string;

      sport?: Selectable;
      category?: Selectable;

      competitionSearchByName: string;
      competitions: any[];
      competitionsVisible: boolean;
      competition?: any;

      competitorsSearchByName: string;
      competitors: any[];
      competitorsVisible: boolean;
      homeCompetitor?: Selectable;
      awayCompetitor?: Selectable;
      players?: Selectable[],

      setStartsAt: boolean,
      setEndsAt: boolean,
      setFinishesAt: boolean,

      startsAtDate: string;
      startsAtDateIsOpen: boolean;
      startsAtHour: string;
      startsAtMinute: string;

      endsAtDate: string;
      endsAtDateIsOpen: boolean;
      endsAtHour: string;
      endsAtMinute: string;

      finishesAtDate: string;
      finishesAtDateIsOpen: boolean;
      finishesAtHour: string;
      finishesAtMinute: string;

      eventType: EVENT_TYPES;
      eventStatus: string;
      eventName: string;

      saveLoading: boolean;
      error: string;

      dialogLoading: boolean;
      dialogError: string;

      newCompetitionDialogIsOpen: boolean;
      newCompetitorDialogIsOpen: boolean;
    };
  },

  watch: {
    homeCompetitor(newValue?: Selectable) {
      if (this.awayCompetitor?.key === newValue?.key) {
        this.awayCompetitor = undefined;
      }
    },

    awayCompetitor(newValue?: Selectable) {
      if (this.homeCompetitor?.key === newValue?.key) {
        this.homeCompetitor = undefined;
      }
    },
  },

  computed: {
    progress(): number {
      const steps = [
        this.sport,
        this.category,
        this.competition,
        this.eventType !== EVENT_TYPES.EVENT_TYPE_EVENT || this.homeCompetitor,
        this.eventType !== EVENT_TYPES.EVENT_TYPE_EVENT || this.awayCompetitor,
        this.eventType === EVENT_TYPES.EVENT_TYPE_EVENT || this.eventName,
      ];

      return (steps.filter((s) => !!s).length / steps.length) * 100;
    },

    alert() {
      if (!this.error) {
        return;
      }

      return <VAlert color='red' type='error'>{this.error}</VAlert>;
    },

    commonSectionEnabled(): boolean {
      return this.eventType === EVENT_TYPES.EVENT_TYPE_EVENT || !!this.eventName;
    },

    commonSection() {
      return (
        <VCard vShow={this.commonSectionEnabled} outlined class='ma-2' disabled={this.saveLoading}>
          <VCardSubtitle>Common</VCardSubtitle>
          <VCardText>
            <VRow>
              <VCol>
                <VSelect
                  items={[PROVIDER]}
                  vModel={PROVIDER}
                  label='Provider'
                  dense
                  outlined
                  disabled
                />
              </VCol>
              <VCol>
                <VSelect
                  items={this.$store.state.sportsConsts.categories}
                  vModel={this.category}
                  label='Category'
                  dense
                  outlined
                  itemText='name'
                  itemValue={(i: Selectable) => i}
                  onChange={this.resetCompetitionSection}
                />
              </VCol>
              <VCol>
                <VSelect
                  items={this.$store.state.sportsConsts.sports}
                  vModel={this.sport}
                  label='Sport'
                  dense
                  outlined
                  itemText='name'
                  itemValue={(i: Selectable) => i}
                  onChange={this.resetCompetitionSection}
                />
              </VCol>
            </VRow>
          </VCardText>
        </VCard>
      );
    },

    competitionSectionEnabled(): boolean {
      return this.initialCompetition || (!!this.sport && !!this.category);
    },

    competitionSection() {
      if (!this.competitionSectionEnabled) {
        this.resetCompetitionSection();
        this.resetCompetititorsAndPlayers();
      }

      const onKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
          this.$apollo.queries.competitions.skip = false;
        }
      };

      const headers = [
        { text: 'Key', value: 'key', Width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Created At', value: 'createdAtFormated' },
        { value: 'actions' },
      ];

      return (
        <VSlideYReverseTransition>
          <VCard
            outlined
            class='ma-2'
            vShow={this.competitionSectionEnabled}
            disabled={this.saveLoading}
          >
            <VCardSubtitle>Competition</VCardSubtitle>
            <VCardText>
              {this.competition ? (
                <VRow>
                  <VCol>
                    <VSelect
                      items={[this.competition]}
                      dense
                      outlined
                      vModel={this.competition}
                      itemText={(i: any) => `${i.name} (${i.key})`}
                      clearable
                    />
                  </VCol>
                </VRow>
              ) : (
                <VRow>
                  <VCol>
                    <VRow>
                      <VCol>
                        <VTextField
                          vModel={this.competitionSearchByName}
                          label='Search by name (or press enter to get all)'
                          dense
                          outlined
                          onKeydown={onKeydown}
                        />
                      </VCol>
                      <VCol>
                        <VBtn block onClick={this.openNewCompetitionDialog}>
                          create new
                        </VBtn>
                      </VCol>
                    </VRow>
                    <VRow>
                      <VCol>
                        <VSlideYReverseTransition>
                          <VDataTable
                            dense
                            vShow={this.competitionsVisible}
                            loading={this.$apollo.queries.competitions.loading}
                            headers={headers}
                            items={this.competitions}
                            scopedSlots={{
                              'item.actions': ({ on, attrs, item }: any) => {
                                return (
                                  <VBtn
                                    x-small
                                    onClick={() => (this.competition = item)}
                                  >
                                    select
                                  </VBtn>
                                );
                              },
                            }}
                          />
                        </VSlideYReverseTransition>
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              )}
            </VCardText>
          </VCard>
        </VSlideYReverseTransition>
      );
    },

    competitorsSectionEnabled(): boolean {
      return (!!this.initialHomeCompetitor || !!this.initialAwayCompetitor) ||
        (this.competition && this.eventType === EVENT_TYPES.EVENT_TYPE_EVENT);
    },

    competitorsOrPlayersSection(): JSX.Element | null {
      switch (this.eventType) {
        case EVENT_TYPES.EVENT_TYPE_EVENT:
          this.setStartsAt = true;
          this.setFinishesAt = false;
          this.setEndsAt = false;

          return this.competitorsSection;
        case EVENT_TYPES.EVENT_TYPE_OUTRIGHT:
          this.setStartsAt = false;
          this.setFinishesAt = false;
          this.setEndsAt = true;

          // return this.playersSection; disabled for now
          return null;
      }
    },

    competitorsSection() {
      const onKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
          this.$apollo.queries.competitors.skip = false;
        }
      };

      const headers = [
        { text: 'Id', value: 'id', Width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Created At', value: 'createdAtFormated' },
        { value: 'actions' },
      ];

      return (
        <VSlideYReverseTransition>
          <VCard
            outlined
            class='ma-2'
            vShow={this.competitorsSectionEnabled}
            disabled={this.saveLoading}
          >
            <VCardSubtitle>Competitors</VCardSubtitle>
            <VCardText>
              <VRow>
                <VCol>
                  <VSelect
                    items={this.homeCompetitor ? [this.homeCompetitor] : []}
                    label='Home'
                    dense
                    outlined
                    vModel={this.homeCompetitor}
                    itemText='name'
                    itemValue={(c: any) => c}
                    clearable
                    disabled={!this.homeCompetitor}
                  />
                </VCol>
                <VCol>
                  <VSelect
                    items={this.awayCompetitor ? [this.awayCompetitor] : []}
                    label='Away'
                    dense
                    outlined
                    vModel={this.awayCompetitor}
                    itemText='name'
                    itemValue={(c: any) => c}
                    clearable
                    disabled={!this.awayCompetitor}
                  />
                </VCol>
              </VRow>
              {(!this.homeCompetitor || !this.awayCompetitor) && (
                <VRow>
                  <VCol>
                    <VRow>
                      <VCol>
                        <VTextField
                          vModel={this.competitorsSearchByName}
                          label='Search by name (or press enter to get all)'
                          dense
                          outlined
                          onKeydown={onKeydown}
                        />
                      </VCol>
                      <VCol>
                        <VBtn block onClick={this.openNewCompetitorDialog}>
                          create new
                        </VBtn>
                      </VCol>
                    </VRow>
                    <VRow>
                      <VCol>
                        <VSlideYReverseTransition>
                          <VDataTable
                            dense
                            vShow={this.competitorsVisible}
                            loading={this.$apollo.queries.competitors.loading}
                            headers={headers}
                            items={this.competitors}
                            scopedSlots={{
                              'item.actions': ({ on, attrs, item }: any) => {
                                return [
                                  <VBtn
                                    x-small
                                    onClick={() => (this.homeCompetitor = item)}
                                  >
                                    home
                                  </VBtn>,
                                  <VBtn
                                    x-small
                                    onClick={() => (this.awayCompetitor = item)}
                                  >
                                    away
                                  </VBtn>,
                                ];
                              },
                            }}
                          />
                        </VSlideYReverseTransition>
                      </VCol>
                    </VRow>
                  </VCol>
                </VRow>
              )}
            </VCardText>
          </VCard>
        </VSlideYReverseTransition>
      );
    },

    playersSectionEnabled(): boolean {
      return this.competition && this.eventType === EVENT_TYPES.EVENT_TYPE_OUTRIGHT;
    },

    playersSection() {
      const onKeydown = (e: KeyboardEvent) => {
        if (e.key === 'Enter' || e.code === 'Enter') {
          this.$apollo.queries.competitors.skip = false;
        }
      };

      const playerHeaders = [
        { text: 'Key', value: 'key', Width: 100 },
        { text: 'Name', value: 'name' },
        { value: 'actions' },
      ];

      const searchHeaders = [
        { text: 'Key', value: 'key', Width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Created At', value: 'createdAtFormated' },
        { value: 'actions' },
      ];

      return (
        <VSlideYReverseTransition>
          <VCard
            outlined
            class='ma-2'
            vShow={this.playersSectionEnabled}
            disabled={this.saveLoading}
          >
            <VCardSubtitle>Players</VCardSubtitle>
            <VCardText>
              <VRow>
                <VCol>
                  <VDataTable
                    dense
                    headers={playerHeaders}
                    items={this.players || []}
                    scopedSlots={{
                      'item.actions': ({ on, attrs, item }: any) => {
                        return <VBtn
                          x-small
                          onClick={() => this.removePlayer(item)}
                        >
                          remove
                        </VBtn>;
                      },
                    }}
                  />
                </VCol>
              </VRow>
              <VRow>
                <VCol>
                  <VRow>
                    <VCol>
                      <VTextField
                        vModel={this.competitorsSearchByName}
                        label='Search by name (or press enter to get all)'
                        dense
                        outlined
                        onKeydown={onKeydown}
                      />
                    </VCol>
                    <VCol>
                      <VBtn block onClick={this.openNewCompetitorDialog}>
                        create new
                      </VBtn>
                    </VCol>
                  </VRow>
                  <VRow>
                    <VCol>
                      <VSlideYReverseTransition>
                        <VDataTable
                          dense
                          vShow={this.competitorsVisible}
                          loading={this.$apollo.queries.competitors.loading}
                          headers={searchHeaders}
                          items={this.competitors}
                          scopedSlots={{
                            'item.actions': ({ on, attrs, item }: any) => {
                              return <VBtn
                                x-small
                                onClick={() => (this.addPlayer(item))}
                              >
                                add
                              </VBtn>;
                            },
                          }}
                        />
                      </VSlideYReverseTransition>
                    </VCol>
                  </VRow>
                </VCol>
              </VRow>
            </VCardText>
          </VCard>
        </VSlideYReverseTransition>
      );
    },

    eventSection() {
      return (
        <VSlideYReverseTransition>
          <VCard
            outlined
            class='ma-2'
            disabled={this.saveLoading}
          >
            <VCardSubtitle>Event</VCardSubtitle>
            <VCardText>
              <VRow>
                <VCol>
                  <VSelect
                    items={Object.keys(EVENT_TYPES).filter((i) => isNaN(parseInt(i, 10)))}
                    label='Type'
                    dense
                    outlined
                    vModel={this.eventType}
                    onChange={this.resetCompetititorsAndPlayers}
                  />
                </VCol>
              </VRow>
              {this.eventType === EVENT_TYPES.EVENT_TYPE_OUTRIGHT && <VRow>
                <VCol>
                  <VTextField
                    vModel={this.eventName}
                    label='Name'
                    dense
                    outlined
                  />
                </VCol>
              </VRow>}
              {this.setStartsAt && <VRow>
                <VCol cols={2} md={1}>
                  <VSwitch
                    inset dense class='mt-1 ml-4' vModel={this.setStartsAt}
                    disabled={this.eventType === EVENT_TYPES.EVENT_TYPE_EVENT} />
                </VCol>
                <VCol cols={4} md={5}>
                  <VMenu
                    vModel={this.startsAtDateIsOpen}
                    closeOnContentClick={false}
                    nudgeRight={40}
                    transition='scale-transition'
                    offsetY
                    minWidth='auto'
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => {
                        return (
                          <VTextField
                            disabled={!this.setStartsAt}
                            vModel={this.startsAtDate}
                            label='Start date'
                            readonly
                            dense
                            outlined
                            bind={attrs}
                            on={on}
                          ></VTextField>
                        );
                      },
                    }}
                  >
                    <VDatePicker
                      vModel={this.startsAtDate}
                      onInput={() => (this.startsAtDateIsOpen = false)}
                    ></VDatePicker>
                  </VMenu>
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setStartsAt}
                    items={range(0, 24).map(fixedWidth)}
                    label='Hour'
                    dense
                    outlined
                    vModel={this.startsAtHour}
                  />
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setStartsAt}
                    items={range(0, 60).map((i) => `${i < 10 ? '0' : ''}${i}`)}
                    label='Minute'
                    dense
                    outlined
                    vModel={this.startsAtMinute}
                  />
                </VCol>
              </VRow>}
              {this.setEndsAt && <VRow>
                <VCol cols={2} md={1}><VSwitch inset dense class='mt-1 ml-4' vModel={this.setEndsAt} disabled /></VCol>
                <VCol cols={4} md={5}>
                  <VMenu
                    vModel={this.endsAtDateIsOpen}
                    closeOnContentClick={false}
                    nudgeRight={40}
                    transition='scale-transition'
                    offsetY
                    minWidth='auto'
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => {
                        return (
                          <VTextField
                            disabled={!this.setEndsAt}
                            vModel={this.endsAtDate}
                            label='End date'
                            readonly
                            dense
                            outlined
                            bind={attrs}
                            on={on}
                          ></VTextField>
                        );
                      },
                    }}
                  >
                    <VDatePicker
                      vModel={this.endsAtDate}
                      onInput={() => (this.endsAtDateIsOpen = false)}
                    ></VDatePicker>
                  </VMenu>
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setEndsAt}
                    items={range(0, 24).map(fixedWidth)}
                    label='Hour'
                    dense
                    outlined
                    vModel={this.endsAtHour}
                  />
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setEndsAt}
                    items={range(0, 60).map((i) => `${i < 10 ? '0' : ''}${i}`)}
                    label='Minute'
                    dense
                    outlined
                    vModel={this.endsAtMinute}
                  />
                </VCol>
              </VRow>}
              {this.setFinishesAt && <VRow>
                <VCol cols={2} md={1}>
                  <VSwitch inset dense class='mt-1 ml-4' vModel={this.setFinishesAt} disabled />
                </VCol>
                <VCol cols={4} md={5}>
                  <VMenu
                    disabled={!this.setFinishesAt}
                    vModel={this.finishesAtDateIsOpen}
                    closeOnContentClick={false}
                    nudgeRight={40}
                    transition='scale-transition'
                    offsetY
                    minWidth='auto'
                    scopedSlots={{
                      activator: ({ on, attrs }: any) => {
                        return (
                          <VTextField
                            disabled={!this.setFinishesAt}
                            vModel={this.finishesAtDate}
                            label='Finish date'
                            readonly
                            dense
                            outlined
                            bind={attrs}
                            on={on}
                          ></VTextField>
                        );
                      },
                    }}
                  >
                    <VDatePicker
                      vModel={this.finishesAtDate}
                      onInput={() => (this.finishesAtDateIsOpen = false)}
                    ></VDatePicker>
                  </VMenu>
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setFinishesAt}
                    items={range(0, 24).map(fixedWidth)}
                    label='Hour'
                    dense
                    outlined
                    vModel={this.finishesAtHour}
                  />
                </VCol>
                <VCol cols={3}>
                  <VSelect
                    disabled={!this.setFinishesAt}
                    items={range(0, 60).map((i) => `${i < 10 ? '0' : ''}${i}`)}
                    label='Minute'
                    dense
                    outlined
                    vModel={this.finishesAtMinute}
                  />
                </VCol>
              </VRow>}
            </VCardText>
          </VCard>
        </VSlideYReverseTransition>
      );
    },

    newCompetitionDialog() {
      if (!this.newCompetitionDialogIsOpen) {
        return null;
      }

      return <CompetitionDialog
        on={{
          closeDialog: () => this.newCompetitionDialogIsOpen = false,
          save: this.onCreateCompetition,
        }}
        error={this.dialogError}
        sport={this.sport}
        category={this.category}
        provider={this.provider}
        isOpen={this.newCompetitionDialogIsOpen}
        loading={this.dialogLoading}
      />;
    },

    newCompetitorDialog() {
      if (!this.newCompetitorDialogIsOpen) {
        return null;
      }

      return <CompetitorDialog
        on={{
          closeDialog: () => this.newCompetitorDialogIsOpen = false,
          save: this.onCreateCompetitor,
        }}
        error={this.dialogError}
        sport={this.sport}
        competition={this.competition}
        provider={this.provider}
        isOpen={this.newCompetitorDialogIsOpen}
        loading={this.dialogLoading}
        savingTypes={this.eventType === EVENT_TYPES.EVENT_TYPE_EVENT ? ['home', 'away'] : ['player']}
      />;
    },
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },

  apollo: {
    competitions: {
      query: gql`
        query(
          $name: String!
          $sportId: Int
          $categoryId: Int
          $active: Boolean
          $includeMerged: Boolean
        ) {
          searchCompetitions(
            name: $name
            sportId: $sportId
            categoryId: $categoryId
            active: $active
            includeMerged: $includeMerged
          ) {
            competitions {
              id
              key
              name
              sportKey
              categoryId
              createdAt
            }
          }
        }
      `,
      variables(): {
        name: string;
        sportId: number;
        categoryId: number;
        active: boolean;
        includeMerged: boolean;
      } {
        this.competitionsVisible = true;
        this.$apollo.queries.competitions.skip = true;

        return {
          name: this.competitionSearchByName,
          sportId: this.sport!.id,
          categoryId: this.category!.id,
          active: false,
          includeMerged: false,
        };
      },
      fetchPolicy: 'network-only',
      skip() {
        return true;
      },
      update(data) {
        return data.searchCompetitions.competitions.map((c: any) => {
          c.createdAtFormated = unixToDateTimeString(c.createdAt);
          return c;
        });
      },
    },

    competitors: {
      query: gql`
        query(
          $name: String!
          $sportId: Int
          $competitionId: Int
          $active: Boolean
          $includeMerged: Boolean
        ) {
          searchCompetitors(
            name: $name
            sportId: $sportId
            competitionId: $competitionId
            active: $active
            includeMerged: $includeMerged
            locale: "en"
          ) {
            competitors {
              id
              name
              key
              sportId
              createdAt
            }
          }
        }
      `,
      error(error) {
        this.error = error.message;
      },
      variables(): {
        name: string;
        sportId: number;
        competitionId: number;
        active: boolean;
        includeMerged: boolean;
      } {
        this.competitorsVisible = true;
        this.$apollo.queries.competitors.skip = true;

        return {
          name: this.competitorsSearchByName,
          sportId: this.sport!.id,
          competitionId: this.competition!.id,
          active: false,
          includeMerged: false,
        };
      },
      update(data) {
        return data.searchCompetitors.competitors.map((c: any) => {
          c.createdAtFormated = unixToDateTimeString(c.createdAt);
          return c;
        });
      },
      skip() {
        return true;
      },
      fetchPolicy: 'network-only',
    },
  },

  methods: {
    resetCompetitionSection() {
      this.competitions = [];
      this.competition = this.initialCompetition;
      this.competitionSearchByName = '';
      this.competitionsVisible = false;
    },

    resetCompetititorsAndPlayers() {
      this.competitors = [];
      this.homeCompetitor = this.initialHomeCompetitor;
      this.awayCompetitor = this.initialAwayCompetitor;
      this.players = this.initialPlayers;
      this.competitorsSearchByName = '';
      this.competitorsVisible = false;
    },

    addPlayer(player: Selectable) {
      if (this.players === undefined) {
        this.players = [];
      }

      if (this.players.filter((p) => p.key === player.key).length > 0) {
        return;
      }

      this.players.push(player);
    },

    removePlayer(player: Selectable) {
      this.players = this.players!.filter((p) => p.key !== player.key);

      if (this.players!.length === 0) {
        this.players = undefined;
      }
    },

    async onCreate() {
      try {
        this.saveLoading = true;
        this.error = '';

        let input = {
          competitionKey: this.competition!.key,
          provider: this.provider,
          providerEventKeys: [newEventKey()],
          sportKey: this.sport!.key,
          status: this.eventStatus,
          type: this.eventType,
        } as any;

        switch (this.eventType) {
          case EVENT_TYPES.EVENT_TYPE_EVENT:
            input = {
              ...input,
              homeKey: this.homeCompetitor!.key,
              awayKey: this.awayCompetitor!.key,
            };

            break;
          case EVENT_TYPES.EVENT_TYPE_OUTRIGHT:
            input = {
              ...input,
              players: this.players?.map((p) => p.key),
              eventName: this.eventName,
            };
        }

        if (this.setStartsAt) {
          input.startsAt = `${this.startsAtDate.toString()}T${this.startsAtHour}:${this.startsAtMinute
            }:00Z`;
        }

        if (this.setEndsAt) {
          input.endsAt = `${this.endsAtDate.toString()}T${this.endsAtHour}:${this.endsAtMinute
            }:00Z`;
        }

        if (this.setFinishesAt) {
          input.finishesAt = `${this.finishesAtDate.toString()}T${this.finishesAtHour}:${this.finishesAtMinute
            }:00Z`;
        }

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($input: CreateEventInput!) {
              createEvent(input: $input) {
                result
                id
              }
            }
          `,
          variables: { input },
        });

        if (result.data.createEvent.result === 'SUCCESS') {
          this.$emit('eventCreated', result.data.createEvent.id);
        } else {
          throw new Error(result.data.createEvent.result);
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.saveLoading = false;
      }
    },

    async onCreateCompetition(input: CreateCompetitionInput) {
      try {
        this.dialogLoading = true;
        this.dialogError = '';

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($input: CreateCompetitionInput!) {
              createCompetition(input: $input) {
                result
                key
              }
            }
          `,
          variables: { input },
        });

        if (result.data.createCompetition.result === 'SUCCESS') {
          this.competition = {
            name: input.providerCompetitionName,
            key: result.data.createCompetition.key,
          };

          this.newCompetitionDialogIsOpen = false;
        } else {
          throw new Error(result.data.createCompetition.result);
        }
      } catch (err) {
        this.dialogError = err.message;
      } finally {
        this.dialogLoading = false;
      }
    },

    openNewCompetitionDialog() {
      this.dialogError = '';
      this.newCompetitionDialogIsOpen = true;
    },

    async onCreateCompetitor(input: CreateCompetitorInput, type: string) {
      try {
        this.dialogLoading = true;
        this.dialogError = '';

        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation($input: CreateCompetitorInput!) {
              createCompetitor(input: $input) {
                result
                key
              }
            }
          `,
          variables: { input },
        });

        if (result.data.createCompetitor.result === 'SUCCESS') {
          const competitor = {
            name: input.providerCompetitorName,
            key: result.data.createCompetitor.key,
          } as Selectable;

          switch (type) {
            case 'away':
              this.awayCompetitor = competitor;

              break;
            case 'home':
              this.homeCompetitor = competitor;

              break;
            case 'player':
              this.addPlayer(competitor);

              break;
          }

          this.newCompetitorDialogIsOpen = false;
        } else {
          throw new Error(result.data.createCompetitor.result);
        }
      } catch (err) {
        this.dialogError = err.message;
      } finally {
        this.dialogLoading = false;
      }
    },

    openNewCompetitorDialog() {
      this.dialogError = '';
      this.newCompetitorDialogIsOpen = true;
    },
  },

  render() {
    return (
      <VCard loading={this.saveLoading} elevation={3} class='mt-1'>
        <VCardTitle class='d-flex justify-space-between align-center'>
          Event Creator
          <VSpacer />
          <VProgressCircular class='ma-2' value={this.progress} />
          <VProgressLinear
            active={this.saveLoading}
            indeterminate={this.saveLoading}
            absolute
            bottom>
          </VProgressLinear>
        </VCardTitle>
        <VCardSubtitle>
          Create a generic event:
        </VCardSubtitle>
        <VCardText>
          {this.alert}
          {this.eventSection}
          {this.commonSection}
          {this.competitionSection}
          {this.competitorsOrPlayersSection}
          {this.newCompetitionDialog}
          {this.newCompetitorDialog}
        </VCardText>
        <VCardActions>
          <VBtn
            color='primary'
            disabled={this.progress !== 100 || this.saveLoading}
            onClick={this.onCreate}
          >
            Create
          </VBtn>
        </VCardActions>
      </VCard>
    );
  },
});
