import Vue from 'vue';
import { gql } from 'apollo-boost';
import SearchDataTable from './SearchDataTable';
import { unixToDateTimeString } from '@/utils/date';
import { VCard } from 'vuetify/lib';

export default Vue.extend({
  props: {
    searchText: String,
    sportId: Number,
  },

  data() {
    return {
      hasError: false,
      competitors: [],
      headers: [
        { text: 'Id', value: 'id', width: 100 },
        { text: 'Name', value: 'name' },
        { text: 'Sport', value: 'sportName' },
        { text: 'Competition', value: 'competitionName' },
        { text: 'Category', value: 'categoryName' },
        { text: 'Created At', value: 'createdAtFormated' },
      ],
    };
  },

  computed: {
    sportsIdsMap() {
      return this.$store.state.sportsConsts.sportsIdsMap;
    },

    categoriesMap() {
      return this.$store.state.sportsConsts.categoriesMap;
    },
  },

  methods: {
    routerLinkTo(item: { id: number }) {
      return { name: 'competitor', params: { competitorId: item.id } };
    },
  },

  apollo: {
    competitors: {
      query: gql`
        query($name: String!, $sportId: Int, $active: Boolean, $includeMerged:Boolean) {
          searchCompetitors(name: $name, sportId: $sportId, active: $active, includeMerged: $includeMerged) {
            competitors {
              id
              name
              sportId
              createdAt
              competitions {
                name
                categoryId
              }
            }
          }
        }
      `,
      error(error) {
        this.hasError = true;
      },
      variables(): { name: string; sportId: number; includeMerged: boolean } {
        return { name: this.searchText || '', sportId: this.sportId || 0, includeMerged: false };
      },
      update(data) {
        return data.searchCompetitors.competitors.map((c: any) => {
          c.sportName = this.sportsIdsMap[c.sportId];
          c.createdAtFormated = unixToDateTimeString(c.createdAt);
          if (c.competitions?.length > 0) {
            c.competitionName = c.competitions[0].name;
            c.categoryName = this.categoriesMap[c.competitions[0].categoryId];
          }
          return c;
        });
      },
      fetchPolicy: 'network-only',
    },
  },

  mounted() {
    this.$store.dispatch('sportsConsts/loadSportsConsts');
  },

  render() {
    return (
      <div>
        <SearchDataTable
          items={this.competitors || []}
          headers={this.headers}
          loading={this.$apollo.queries.competitors.loading}
          routerLinkTo={this.routerLinkTo}
        ></SearchDataTable>
        {this.hasError && (
          <VCard class='pa-4'>
            <div>
              Missing search param. Event search must have at least a sport
              defined.
            </div>
          </VCard>
        )}
      </div>
    );
  },
});
