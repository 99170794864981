import { User } from '@/store/modules/auth';
import * as firebase from 'firebase';

/** @description a helper to convert google auth user to other user interface */
export const googleAuthConvertor = (
  idTokenResult: firebase.auth.IdTokenResult,
): User => {
  const {
    name,
    picture,
    photoURL: defaultPhotoURL,
    email,
    user_id,
    roles,
    limits,
    tenants,
  } = idTokenResult.claims;

  return {
    username: name,
    photoUrl: picture || defaultPhotoURL,
    userId: user_id,
    email,
    idToken: idTokenResult.token,
    roles,
    limits,
    tenants,
  };
};
