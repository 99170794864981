import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import Vue from 'vue';
import { VBtn, VDataTable, VSnackbar, VTooltip } from 'vuetify/lib';

import { unixToDateTimeISOString } from '@/utils/date';
import DateRangePicker from '@/components/DateRangePicker';

import './PendingDepositsWidget.less';

interface Deposit {
  id: number;
  playerNickname: string;
  playerUUID: string;
  playerID: number;
  currency: string;
  hash: string;
  state: string;
  outputs: number[];
  createdAt: string;
  updatedAt: string;
}

export default Vue.extend({
  props: {
    page: Number,
    pageSize: Number,
    from: Number,
    to: Number,
  },
  data() {
    const fromDate = new Date(this.from * 1000);
    const toDate = new Date(this.to * 1000);
    return {
      isResyncing: false,
      isResyncCompleted: false,
      isResyncSuccess: false,
      isLoading: true,
      isLoadFailed: false,
      loadError: '',
      resyncError: '',
      labrador: {
        deposits: [] as Deposit[],
        total: 0,
      },
      footerProps: {'items-per-page-options': [this.pageSize]},
      dateRange: [`${fromDate.getFullYear()}-${('0' + (fromDate.getMonth() + 1)).slice(-2)}-${('0' + fromDate.getDate()).slice(-2)}`, `${toDate.getFullYear()}-${('0' + (toDate.getMonth() + 1)).slice(-2)}-${('0' + toDate.getDate()).slice(-2)}`] as [string, string],
    };
  },
  computed: {
    headers() {
      return [
        {
          text: 'Last Update',
          align: 'start',
          sortable: false,
          value: 'updatedAt',
        },
        {
          text: 'Player',
          align: 'start',
          sortable: false,
          value: 'playerNickname',
        },
        {
          text: 'Currency',
          align: 'start',
          sortable: false,
          value: 'currency',
        },
        {
          text: 'Hash',
          align: 'start',
          sortable: false,
          value: 'hash',
        },
        {
          text: 'State',
          align: 'start',
          sortable: false,
          value: 'state',
        },
        {
          text: 'Outputs',
          sortable: false,
          align: 'start',
          value: 'outputs',
        },
        {
          text: 'Action',
          sortable: false,
          align: 'center',
          value: 'action',
        },
      ];
    },
    loadFailureSnackbar() {
      if (this.isLoadFailed) {
        return (
          <VSnackbar
            timeout={2000}
            v-model={this.isLoadFailed}
            top
            color='red darken-1'
            elevation='24'>
            {`Load failed${this.loadError ? `: ${this.loadError}` : '.'}`}
          </VSnackbar>
        );
      }
    },
    resyncResultSnackbar() {
      if (this.isResyncCompleted) {
        let toastDisplayTime = 2000;
        if (this.isResyncSuccess) {
          toastDisplayTime = 1000;
          setTimeout(this.fetchDeposits, toastDisplayTime);
          return (
            <VSnackbar
              timeout={toastDisplayTime}
              v-model={this.isResyncCompleted}
              top
              color='green darken-1'
              elevation='24'>
              {`Resync successful.`}
            </VSnackbar>
          );
        }

        setTimeout(this.fetchDeposits, toastDisplayTime);
        return (
          <VSnackbar
            timeout={toastDisplayTime}
            v-model={this.isResyncCompleted}
            top
            color='red darken-1'
            elevation='24'>
            {`Resync failed${this.resyncError ? `: ${this.resyncError}` : '.'}`}
          </VSnackbar>
        );
      }
    },
  },
  async mounted() {
    await this.fetchDeposits();
  },
  methods: {
    onPaginationHandler(options: any) {
      this.$emit('pageUpdate', options.page, options.itemsPerPage);
    },
    onClickPlayerNickname(uuid: string) {
      this.$router.push(`/players/${uuid}`);
    },
    async fetchDeposits(): Promise<any> {
      this.isLoadFailed = false;
      this.loadError = '';
      this.isResyncing = false;
      this.isResyncCompleted = false;
      this.isResyncSuccess = false;
      this.resyncError = '';
      try {
        const response = await this.$apollo.query({
          query: gql`
            query($page: Int!, $pageSize: Int!, $from: Int, $to: Int) {
              paymentsUnconfirmedDeposits(page: $page, pageSize: $pageSize, from: $from, to: $to) {
                total
                deposits {
                  currency
                  hash
                  state
                  outputs
                  createdAt
                  updatedAt
                  address
                  playerNickname
                  playerUUID
                  id
                  playerID
                }
              }
            }`,
          variables: {
            page: this.page,
            pageSize: this.pageSize,
            from: this.from,
            to: this.to,
          },
          fetchPolicy: 'network-only',
        });
        this.labrador.total = response.data.paymentsUnconfirmedDeposits.total;
        this.labrador.deposits = response.data.paymentsUnconfirmedDeposits.deposits;
      } catch (err) {
        this.isLoadFailed = true;
        this.loadError = String(err);
      } finally {
        this.isLoading = false;
      }
      window.scrollTo(0, 0);
    },
    resyncAction(props: any): JSX.Element {
      return (
        <VBtn
          disabled={this.isLoading || this.isResyncing}
          onClick={() => { this.onResync(props.item); }}
        >Resync</VBtn>
      );
    },
    async onResync({playerUUID, address, currency, hash}: {
      playerUUID: string,
      address: string,
      currency: string,
      hash: string,
    }) {
      this.isResyncing = true;
      try {
        const result = await this.$apollo.mutate({
          mutation: gql`
            mutation ($playerUUID: String!, $address: String!, $currency: String!, $hash: String!) {
              paymentsResyncDeposit(playerUUID: $playerUUID, address: $address, currency: $currency, hash: $hash) {
                result
              }
            }
          `,
          variables: {
            playerUUID,
            address,
            currency,
            hash,
          },
        });
        this.isResyncSuccess = result.data.paymentsResyncDeposit.result === 'OK';
      } catch (e) {
        this.isResyncSuccess = false;
        const apolloResp = (e as ApolloError).graphQLErrors[0];
        this.resyncError = apolloResp.message || String(e);
      } finally {
        this.isResyncCompleted = true;
      }
    },
  },
  watch: {
    dateRange(range) {
      if (range[0] !== '' && range[1] !== '') {
        const newFrom = Math.floor((new Date(range[0])).getTime() / 1000);
        const newTo = Math.floor((new Date(range[1])).getTime() / 1000);
        if (this.$route.query.from !== newFrom.toString() || this.$route.query.to !== newTo.toString()) {
          this.$emit('dateRangeUpdate', newFrom, newTo);
        }
      }
    },
  },
  render() {
    const mobile = this.$vuetify.breakpoint.smAndDown;
    return (
      <div id='PendingDepositsWidgetContainer'>
        { this.loadFailureSnackbar }
        { this.resyncResultSnackbar }
        <DateRangePicker
          label1={'From (earlier)'}
          label2={'To (later)'}
          vModel={this.dateRange}
        />
        <VDataTable
          headers={this.headers}
          items={this.labrador.deposits}
          scopedSlots={{
            'item.action': this.resyncAction,
            'item.updatedAt': ({ item }: { item: Deposit }) => (
              unixToDateTimeISOString(item.updatedAt)
            ),
            'item.playerNickname': ({ item }: { item: Deposit }) => (
              <div onclick={() => { this.onClickPlayerNickname(item.playerUUID); }}>
                <VTooltip bottom
                  scopedSlots={{
                    activator: ({ on, attrs }: any) => (
                      <span on={on}>
                        {item.playerNickname}
                      </span>
                    ),
                  }}
                >
                  <span>{item.playerUUID}</span>
                </VTooltip>
              </div>
            ),
          }}
          loading={this.isLoading || this.isResyncing}
          loading-text='Loading... Please wait'
          page={this.page}
          items-per-page={this.pageSize}
          server-items-length={this.labrador.total || 0}
          onPagination={this.onPaginationHandler}
          footerProps={this.footerProps}
        >
        </VDataTable>
      </div>
    );
  },
});
