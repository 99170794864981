import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { gql } from 'apollo-boost';

export const gradingStates = [
  'GRADING_STATE_PENDING',
  'GRADING_STATE_APPROVED',
  'GRADING_STATE_REJECTED'] as const;
export type GradingState = typeof gradingStates[number];

export const gradingResultTypes = [
  'RESULT_TYPE_WIN',
  'RESULT_TYPE_HALF_WIN',
  'RESULT_TYPE_PUSH',
  'RESULT_TYPE_HALF_LOSS',
  'RESULT_TYPE_LOSS'] as const;
export type GradingResultType = typeof gradingResultTypes[number];

export const gradingReviewActions = [
  'REVIEW_ACTION_REJECT',
  'REVIEW_ACTION_APPROVE'] as const;
export type GradingReviewAction = typeof gradingReviewActions[number];


export interface GradingResultReview {
  action: GradingReviewAction;
  comment: string;
  createTime: string;
  id: number;
  links: string[];
  reviewedBy: string;
  updateTime: string;
}

export interface GradingResult {
  comment: string;
  createTime: string;
  event: {
    competition: {
      name: string;
    };
    competitors: Array<{
      key: string;
      name: string;
    }>,
    name: string;
    players: Array<{
      key: string;
      name: string;
    }>;
    sportKey: string;
  };
  eventId: string;
  id: number;
  marketKey: string;
  numberOfPayouts: number;
  numberOfTieWinners: number;
  outcome: string;
  outright: {
    competition: {
      name: string;
    };
    name: string;
    players: Array<{
      key: string;
      name: string;
    }>;
    sportKey: string;
  };
  params: string;
  requestedBy: string;
  result: GradingResultType;
  reviews: GradingResultReview[];
  state: GradingState;
  updateTime: string;
}

export interface GradingResults {
  gradingResults: GradingResult[];
  translations: Record<string, string>;
}


interface InContextHelpGradingResultsStore {
  gradingResults: GradingResult[];
  translations: Record<string, string>;
  loadedPages: number;
  loading: boolean;
  offset: number;
  totalPages: null | number;
}

interface LoadGradingResultsParams {
  state: GradingState;
  eventId?: number;
  clearStoreFirst?: boolean;
}

interface CreateGradingResultReviewInput {
  action: GradingReviewAction;
  comment: string;
  links: string[];
}

interface AddReviewParams {
  resultId: number;
  input: CreateGradingResultReviewInput;
}

interface CreateGradingResultInput {
  comment: string;
  eventId: number;
  links: string[];
  marketKey: string;
  numberOfPayouts: number;
  numberOfTieWinners: number;
  outcome: string;
  params: string;
  requestId?: string;
  result: GradingResultType;
}

export const pageSize = 15;

const getters: GetterTree<InContextHelpGradingResultsStore, any> = {
  gradingResults(state): GradingResult[] {
    return state.gradingResults;
  },

  translations(state): Record<string, string> {
    return state.translations;
  },

  loading(state): boolean {
    return state.loading;
  },

  totalPages(state): number | null {
    return state.totalPages;
  },

  loadedPages(state): number {
    return state.loadedPages;
  },
};

const mutations: MutationTree<InContextHelpGradingResultsStore> = {
  LOADING(state, loading: boolean) {
    state.loading = loading;
  },

  CLEAR_GRADING_RESULTS(state) {
    state.gradingResults = [];
    state.translations = {};
    state.offset = 0;
    state.loading = false;
    state.loadedPages = 0;
    state.totalPages = 0;
  },

  ADD_GRADING_RESULTS(state, gradingResults: GradingResults) {
    state.gradingResults = [...state.gradingResults, ...gradingResults.gradingResults];
    state.translations = { ...state.translations, ...gradingResults.translations };

    state.loadedPages++;
    if (gradingResults.gradingResults.length < pageSize) {
      state.totalPages = state.loadedPages;
    }
    state.offset = state.gradingResults.length;
  },

  ADD_REVIEW(state, { resultId, review }: { resultId: number; review: GradingResultReview }) {
    state.gradingResults = state.gradingResults.map((gr) => {
      if (gr.id === resultId) {
        if (gr.reviews.find((r) => r.id === review.id)) {
          // Update a review
          return { ...gr, reviews: gr.reviews.map((r) => r.id === review.id ? review : r) };
        } else {
          // Add a new review
          return { ...gr, reviews: [...gr.reviews, review] };
        }
      } else {
        return gr;
      }
    });
  },
};

const actions: ActionTree<InContextHelpGradingResultsStore, any> = {
  async loadGradingResults({ commit, rootState, state }, params: LoadGradingResultsParams) {
    if (params.clearStoreFirst) {
      commit('CLEAR_GRADING_RESULTS');
    }
    commit('LOADING', true);

    try {
      const client = rootState.apolloClient();
      const response = await client.query({
        query: gql`query ($eventId: Int, $limit: Int, $offset: Int, $state: GradingState) {
          getGradingResults(eventId: $eventId, limit: $limit, offset: $offset, state: $state) {
            gradingResults {
              comment
              createTime
              event {
                competition {
                  name
                }
                competitors {
                  key
                  name
                }
                name
                players {
                  key
                  name
                }
                sportKey
              }
              eventId
              id
              marketKey
              numberOfPayouts
              numberOfTieWinners
              outcome
              outright {
                competition {
                  name
                }
                name
                players {
                  key
                  name
                }
                sportKey
              }
              params
              requestedBy
              result
              reviews {
                action
                comment
                createTime
                id
                links
                reviewedBy
                updateTime
              }
              state
              updateTime
            }
            translations
          }
        }`,
        variables: {
          ...params,
          limit: pageSize,
          offset: state.offset,
        },
      });

      const result = response.data.getGradingResults;
      result.translations = Object.fromEntries(result.translations);

      commit('ADD_GRADING_RESULTS', result);
    } catch (e) {
      throw e;
    } finally {
      commit('LOADING', false);
    }
  },

  async addResult({ commit, rootState }, params: CreateGradingResultInput) {
    try {
      const client = rootState.apolloClient();
      const response = await client.mutate({
        mutation: gql`mutation ($input: CreateGradingResultInput!) {
          createGradingResult(input: $input) {
            gradingResults {
              comment
              createTime
              event {
                competition {
                  name
                }
                competitors {
                  key
                  name
                }
                name
                players {
                  key
                  name
                }
                sportKey
              }
              eventId
              id
              marketKey
              numberOfPayouts
              numberOfTieWinners
              outcome
              outright {
                competition {
                  name
                }
                name
                players {
                  key
                  name
                }
                sportKey
              }
              params
              requestedBy
              result
              reviews {
                action
                comment
                createTime
                id
                links
                reviewedBy
                updateTime
              }
              state
              updateTime
            }
            translations
          }
        }`,
        variables: {
          input: params,
        },
      });

      commit('ADD_GRADING_RESULTS', response.data.createGradingResult);
    } catch (e) {
      throw e;
    }
  },

  async addReview({ commit, rootState }, params: AddReviewParams) {
    try {
      const client = rootState.apolloClient();
      const response = await client.mutate({
        mutation: gql`mutation ($resultId: Int!, $input: CreateGradingResultReviewInput!) {
            createGradingResultReview(resultId: $resultId, input: $input) {
              action
              comment
              createTime
              id
              links
              reviewedBy
              updateTime
            }
          }`,
        variables: {
          resultId: params.resultId,
          input: params.input,
        },
      });

      commit('ADD_REVIEW', { resultId: params.resultId, review: response.data.createGradingResultReview });
    } catch (e) {
      throw e;
    }
  },
};

export default {
  namespaced: true,
  state: {
    gradingResults: [],
    translations: {},
    loadedPages: 0,
    loading: false,
    offset: 0,
    totalPages: null,
  } as InContextHelpGradingResultsStore,
  getters,
  actions,
  mutations,
};
