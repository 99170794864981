import { googleAuthConvertor } from './google-auth-convertor';
import * as firebase from 'firebase';

const provider = new firebase.auth.GoogleAuthProvider();
/** @description a function to pop sign in for login with google */
// ref from https://firebase.google.com/docs/auth/web/google-signin
export const googleSignIn = async () => {
  try {
    const result = await firebase.auth().signInWithPopup(provider);
    if (result && result.user) {
      // User is signed in.
      return {
        user: googleAuthConvertor(await result.user.getIdTokenResult()),
      };
    }
    return {};
  } catch (e) {
    return {
      error: e.message,
    };
  }
};
