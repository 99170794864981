import './EditDialog.less';

import { gql } from 'apollo-boost';
import Vue from 'vue';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VContainer,
  VDialog,
  VDivider,
  VSelect,
  VSpacer,
} from 'vuetify/lib';

import { EntityMediaPreview } from './EntityMediaPreview';

interface EntityMediaInput {
  entityType: string;
  entityKey: string;
  mediaType: string;
  mediaUrl: string;
  file?: any;
}

const OPTIONS_BY_TYPE: Record<string, any[]> = {
  competitor: [
    {
      text: 'LOGO',
      value: 'LOGO',
    },
    {
      text: 'IMAGE',
      value: 'IMAGE',
    },
  ],
  event: [
    {
      text: 'IMAGE',
      value: 'IMAGE',
    },
  ],
};

export default Vue.extend({
  name: 'entityMediaWidget',
  props: {
    mode: String,
    entityType: String,
    entityKey: String,
    open: Boolean,
    media: Object,
    closeDialog: Function,
    afterSave: Function,
  },
  data() {
    return {
      fileName: '' as string,
      isDragging: false,
      file: undefined,
      preview: '',
      mediaType: this.media.type || OPTIONS_BY_TYPE[this.entityType][0].value,
      mediaUrl: this.media.url || '',
      loading: false,
    };
  },
  methods: {
    onDragging(state: boolean) {
      return (event: any) => {
        event.preventDefault();
        this.isDragging = state;
      };
    },
    onDrop(event: any) {
      this.isDragging = false;
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file) {
        this.setFile(file);
      }
    },
    onSelectFile(event: any) {
      const file = event.target.files[0];
      if (file) {
        this.setFile(file);
      }
    },
    setFile(file: any) {
      if (!file.type.startsWith('image')) {
        return;
      }

      this.file = file;
      this.fileName = file.name as string;
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target) {
          this.preview = e.target.result as string;
        }
      };
      reader.readAsDataURL(file);
    },
    async updateMedia() {
      this.loading = true;
      const input: EntityMediaInput = {
        entityType: this.entityType,
        entityKey: this.entityKey,
        mediaType: this.mediaType,
        mediaUrl: this.mediaUrl,
      };
      if (this.file) {
        input.file = this.file;
      }
      try {
        const res = await this.$apollo.mutate({
          mutation: gql`
            mutation($input: EntityMediaInput!) {
              updateEntityMedia(input: $input) {
                success
              }
            }
          `,
          variables: {
            input,
          },
          context: {
            hasUpload: true,
          },
        });
        this.loading = false;
        if (res.data.updateEntityMedia.success) {
          this.afterSave();
        } else {
          // TODO: show some error message by snackbar or similar
          this.closeDialog();
        }
      } catch (e) {
        this.loading = false;
        this.closeDialog();
      }
    },
  },
  computed: {
    uploadText() {
      if (this.fileName) {
        return (
          <div>
            You have selected <strong>{this.fileName}</strong>
          </div>
        );
      }
    },
    labelClass() {
      let className = 'dnd-area';
      if (this.isDragging) {
        className += ' dragging';
      }
      return className;
    },
    previewArea() {
      if (this.preview.length > 0) {
        return <EntityMediaPreview src={this.preview} />;
      } else if (this.mode !== 'create' && this.media.url) {
        return <EntityMediaPreview src={this.media.url} />;
      }
    },
    mediaTypeSelection() {
      if (this.mode === 'create') {
        return (
          <VSelect
            vModel={this.mediaType}
            items={OPTIONS_BY_TYPE[this.entityType]}
            label='Media Type'
          />
        );
      }
      return <span>Media Type: {this.mediaType}</span>;
    },
  },
  render() {
    return (
      <VDialog vModel={this.open} persistent max-width='600px'>
        <VCard>
          <VCardTitle>
            <span class='headline'>Entity Media</span>
          </VCardTitle>
          <VDivider />
          <VCardText>
            <VContainer>
              {this.mediaTypeSelection}
              <label
                ondragover={this.onDragging(true)}
                ondragleave={this.onDragging(false)}
                ondrop={this.onDrop}
                class={this.labelClass}
                for='file'
              >
                <div class='dnd-wording'>
                  {this.uploadText}
                  <div>Drop an image here or click here to select</div>
                </div>
              </label>
              <input
                accept='image/*'
                id='file'
                class='file-upload'
                type='file'
                onChange={this.onSelectFile}
              />
              {this.previewArea}
            </VContainer>
          </VCardText>
          <VCardActions>
            <VSpacer />
            <VBtn
              loading={this.loading}
              disabled={(this.mode === 'create' && !this.file) || this.loading}
              text
              color='primary'
              onClick={this.updateMedia}
            >
              Save
            </VBtn>
            <VBtn disabled={this.loading} text onClick={this.closeDialog}>
              Close
            </VBtn>
          </VCardActions>
        </VCard>
      </VDialog>
    );
  },
});
