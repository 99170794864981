import Vue from 'vue';
import { gql } from 'apollo-boost';
import { VDataTable } from 'vuetify/lib';
import { RouterLink } from '@/router';

type EntityType = 'Player' | 'Sports Bet' | 'Affiliate';

interface SearchResult {
  id: number;
  uuid: string;
  entityType: EntityType;
}

export default Vue.extend({
  props: {
    searchText: String,
  },

  data(): { hasError: boolean, searchResults: SearchResult[], headers: any[] } {
    return {
      hasError: false,
      searchResults: [] as SearchResult[],
      headers: [
        { text: 'Id', value: 'id', width: 100 },
        { text: 'UUID', value: 'uuid' },
        { text: 'Entity type', value: 'entityType' },
      ],
    };
  },

  apollo: {
    searchResults: {
      query: gql`
        query ($uuid: String!) {
          getPlayer(uuid: $uuid) {
            id
            uuid
          }
          sportsBet(uuid: $uuid) {
            id
            uuid
          }
          sportsBets(betSlipIds: [$uuid]) {
            id
            uuid
          }
          affiliate(uuid: $uuid) {
            id
            uuid
          }
        }
      `,

      error() {
        this.hasError = true;
      },

      variables(): { uuid: string } {
        return { uuid: this.searchText };
      },

      update(data) {
        const entities = [] as SearchResult[];

        if (data.getPlayer?.uuid) {
          entities.push({
            id: data.getPlayer.id,
            uuid: data.getPlayer.uuid,
            entityType: 'Player',
          });
        }
        if (data.sportsBet?.id) {
          entities.push({
            id: data.sportsBet.id,
            uuid: data.sportsBet.uuid,
            entityType: 'Sports Bet',
          });
        }
        if (data.sportsBets) {
          entities.push(...data.sportsBets.map((sb: { id: number, uuid: string }) => {
            return {
              id: sb.id,
              uuid: sb.uuid,
              entityType: 'Sports Bet',
            };
          }));
        }
        if (data.affiliate?.uuid) {
          entities.push({
            id: data.affiliate.id,
            uuid: data.affiliate.uuid,
            entityType: 'Affiliate',
          });
        }
        return entities;
      },

      skip(): boolean {
        return !this.searchText;
      },

      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
  },

  render() {
    return (
      <div>
        <VDataTable
          class='elevation-1'
          items={this.searchResults || []}
          headers={this.headers}
          loading-text='Searching... Please wait'
          loading={this.$apollo.queries.searchResults.loading}
          scopedSlots={{
            'item.uuid': ({ item }: { item: SearchResult }) => {
              if (item.entityType === 'Player') {
                return (
                  <RouterLink
                    class='text-decoration-none'
                    to={{ name: 'player', params: { playerUUID: item.uuid } }}>
                    {item.uuid}
                  </RouterLink>
                );
              } else if (item.entityType === 'Sports Bet') {
                return (
                  <RouterLink
                    class='text-decoration-none'
                    to={{ name: 'sportsBetDetails', params: { betId: item.id } }}>
                    {item.uuid}
                  </RouterLink>
                );
              } else if (item.entityType === 'Affiliate') {
                return (
                  <RouterLink
                    class='text-decoration-none'
                    to={{ name: 'affiliate', params: { affiliateUUID: item.uuid } }}>
                    {item.uuid}
                  </RouterLink>
                );
              }
              return item.uuid;
            },
          }}
        />
      </div>
    );
  },
});
